import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { SplitButton } from 'primereact/splitbutton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useEffect, useRef, useState } from 'react';
import Can, { check } from '../Can';
import strings from '../localization';
import rules from '../rbac-rules';
// import PaymentService from '../service/PaymentService';
import UserService from '../service/UserService';
import VendorService from '../service/VendorService';
import  PaymentForm  from './PaymentForm';
import { formatDateStringLocal } from './InvoiceDates';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import { AutoComplete } from 'primereact/autocomplete';
// import PaymentTypeService from '../service/PaymentTypeService';
import PaymentService from '../service/PaymentService';
import { TableFilters } from './TableFilters';
import { defaultTableConfiguration } from './TableConfiguration';
import { useHistory } from 'react-router-dom';
import InvoiceService from '../service/InvoiceService';
import SupplierPaymentService from '../service/SupplierPaymentService';
import PurchasePaymentForm from './PurchasePaymentForm';

export const PurchasePayments = ({context}) => {
    const [paymentDialog, setPaymentDialog] = useState(false);
    const [deletePaymentDialog, setDeletePaymentDialog] = useState(false);
    const [payments, setPayments] = useState(null);
    const [payment, setPayment] = useState(null);
    const [remainingAmount, setRemainingAmount] = useState(0);
    const history = useHistory();
    const [preFilters, setPreFilters] = useState({});
    const [filters, setFilters] = useState({});
    const [totalRecords, setTotalRecords] = useState(0);
    const [vendors, setVendors] = useState(null);
    const [paymentTypes, setPaymentTypes] = useState(null);
    const [error, setError] = useState(null);
    const [showRelatedInvoices, setShowRelatedInvoices] = useState(false);
    const [showReconcilePayment, setShowReconcilePayment] = useState(false);
    const [canEdit, setCanEdit] = useState(true);
    const [relatedInvoices, setRelatedInvoices] = useState([]);
    const [invoicesPendingPayment, setInvoicesPendingPayment] = useState({data:[], count:0});
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [typingTimeout2, setTypingTimeout2] = useState(null);

    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [importStatus, setImportStatus] = useState(null);
    const [importError, setImportError] = useState(null);
    const [vendorId, setVendorId] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    var {vendor, showCompany: full,role} = context;


    useEffect(() => {
        onPage({ rows, page: 0,vendorID:vendor?.uuid})
    }, [localStorage.getItem('subscription')]);

    useEffect(() => {
        if(vendor != null)
        {
            setVendorId(vendor.uuid);
        }
    }, []);

    const openNew = () => {
        setPaymentDialog(true);
    }

    const hideDialog = () => {

        setPaymentDialog(false);

        setPayment(null);
    }

    const hideDeletePaymentDialog = () => {
        setDeletePaymentDialog(false);
        setPayment(null);
    }


    const edit = (obj) => {
        setPayment({
            ...obj,
            // paymentType: obj.paymentType,
            uuid: obj.uuid
        })

        setCanEdit(false);

        setPaymentDialog(true);
    }

    const openReconcileDialog = (obj) => {

        setRemainingAmount(obj.amount - obj.reconciledAmount);

        new InvoiceService().GetInvoicesPendingPayment(100, 0, {
            vendorId: obj.vendor.uuid,
            customer: obj.customer.uuid
        }).then((data) => {
            setInvoicesPendingPayment(data);
            setPayment({
                ...obj,
                // paymentType: obj.paymentType,
                uuid: obj.uuid
            })
        })

        setShowReconcilePayment(true);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Can yes={() => <Button label={strings.New} icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />}
                    perform={'payment:create'}
                    silent={true}
                    role={role} />
            </React.Fragment>
        )
    }



    const paymentNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{strings.referenceNumber}</span>
                {rowData.referenceNumber}
            </>
        );
    }




    const vendorBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{strings.Vendor}</span>
                {rowData.vendor?.name}
            </>
        );
    }
    const customerBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{strings.supplier}</span>
                {rowData.supplier?.name}
            </>
        );
    }

    const barcodeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {rowData.amount} {rowData.currency}
            </>
        );
    }
    const reconciledBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">{strings.reconciledAmount}</span>
                <span style={{ width: '100%' }} className={`product-badge status-${rowData.reconciledAmount < rowData.amount ? '1' : '3'}`}>{rowData.reconciledAmount} {rowData.currency}</span>
            </>
        );
    }

    const deletePayment = () => {
        const paymentService = new PaymentService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        paymentService.DeletePayment(payment.uuid).then((data) => {
            if (data.errorCode) {
                toast.current.show({ severity: 'error', summary: 'Cannot Delete Payment', detail: data.message, life: 3000 });
                setLoading(false);
                hideDeletePaymentDialog();
                return;
            }
            toast.current.show({ severity: 'success', summary: 'Delete Payment', detail: `Deleted ${payment.name} successfully`, life: 3000 });
            paymentService.GetPayments(rows, (page) * rows).then(({ data, count }) => {
                setLoading(false);
                setPayments(data);
                setTotalRecords(count);
            });
        }).finally(() => {
            hideDeletePaymentDialog();
        }).catch(() => setLoading(false));
        setPayment(null);
    }
    const deletePaymentDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePaymentDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deletePayment} />
        </>
    );

    const creationDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date</span>
                {formatDateStringLocal(rowData.paymentDate)}
            </>
        );
    }
    const paymentTypeBodyTemplate = (rowData) => {
        if (rowData.companyAccount) {
            return (
                <>
                    <span className="p-column-title">{strings.Account}</span>
                    {rowData.paymentType?.name} ({rowData.companyAccount?.name})
                </>
            );
        }
        return (
            <>
                <span className="p-column-title">{strings.paymentType}</span>
                {rowData.paymentType?.name}
            </>
        );
    }

    const confirmDeletePayment = (payment) => {

        setDeletePaymentDialog(true);
        setPayment(payment);
    }

    const actionBodyTemplate = (rowData) => {
        const items = []

        var userService = new UserService();
        var role = userService.GetRole();

        if (check(rules, role, 'payment:update') && rowData.reconciledAmount < rowData.amount) {
            items.push({ label: strings.reconcilePayment, icon: `pi pi-check`, command: () => openReconcileDialog(rowData) });
        }

        if (check(rules, role, 'payment:update')) {
            items.push({ label: strings.details, icon: `pi pi-arrow-circle-${strings.getLanguage() == 'ar' ? 'left' : 'right'}`, command: () => edit(rowData) });
        }


        if (check(rules, role, 'payment:delete')) {
            items.push({ label: strings.deletePayment, icon: 'pi pi-trash', command: () => confirmDeletePayment(rowData) });
        }

        items.push({ label: strings.copyId, icon: 'pi pi-copy', command: () => navigator.clipboard.writeText(rowData.uuid) });
        if(role=='super_admin') {
            items.push({ label: "Copy SID", icon: 'pi pi-copy', command:(e)=>  navigator.clipboard.writeText(rowData.sid) });
        }

        let first = items.shift();

        return first ? <SplitButton style={{width:'100%'}} label={first.label} icon={first.icon} onClick={() => first.command()} model={items}></SplitButton> : ''

        // return (
        //     <div className="actions">
        //         <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => edit(rowData)} />
        //         <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeletePayment(rowData)} />
        //         {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeletePayment(rowData)} /> */}
        //     </div>
        // );
    }

    function changeVendorSearchBar (e) {
        if (typingTimeout2) {
            clearTimeout(typingTimeout2);
        }

        setTypingTimeout2(setTimeout(()=> {

            var vendorService = new VendorService();
            vendorService.GetVendors(100, 0, {simple:true, name:e.query}).then((data) => {
                if (!data.data) return [];
                return data.data.map(v => {
                    return { code: v.uuid, name: v.name, ...v }
                });
            }).then(data => {
                setVendors(
                    [{ code: null, name: '-' }, ...data]);
            });
        }, 200))

    }

    const onPage = ({ first,
        rows,
        page }) => {
        const paymentService = new SupplierPaymentService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        paymentService.getPayments({limit:rows, offset:(page) * rows, vendorId: vendor?.uuid??''}).then(({ data, count }) => {
            setLoading(false);
            setPayments(data);
            setTotalRecords(count);
        });

        setPayment(null);

    }

    const onFiltersChange = (e) => {
        let filters = { ...preFilters };
        filters[e.target.id] = e.target.value;
        setPreFilters(filters);
    }

    function formatDate(date) {
        if (!date) return null;
        return new Date(moment(date).format('yyyy-MM-DD HH:mm:ss'))
    }

    useEffect(() => {
        if(vendors == null)
        {
            var vendorService = new VendorService();
            if (full) {
                vendorService.GetVendors(500).then((data) => {
                    if (!data.data) {
                        return [{ code: null, name: '-' }]
                    }
                    return data.data.map(v => {
                        return { code: v.uuid, name: v.name, ...v }
                    });
                }).then(data => {
                    setVendors(
                        [{ code: null, name: '-' }, ...data]);
                });
            }
        }
    }, []);

    // useEffect(() => {
    //     if (paymentTypes == null) {
    //         var paymentTypeService = new PaymentTypeService();
    //         paymentTypeService.GetPaymentTypes(500).then((data) => {
    //             if (!data.data) {
    //                 return [{ code: null, name: '-' }]
    //             }
    //             return data.data.map(v => {
    //                 return { code: v.uuid, name: v.label, ...v }
    //             });
    //         }).then(data => {
    //             setPaymentTypes(
    //                 [{ code: null, name: '-' }, ...data]);
    //         });
    //     }
    // }, []);

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            const paymentService = new SupplierPaymentService();
            setLoading(true);
            paymentService.getPayments({limit:rows, offset:0, ...filters}).then(({ data, count }) => {
                setTotalRecords(count);
                setLoading(false);
                setPayments(data)
            });
        }
    }, [filters]);

    const header = (

        <div className="p-grid">

            <div className="p-col">

                <div className="table-header">

                    <h5 className="p-m-0">{strings.ManagePayments}</h5>
                </div>

            </div>

            <div className="p-col-12">

                <TableFilters>

                    <div className="p-fluid p-grid">

                        {full && <div className="p-field p-col-4" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.Company}</label>
                            <AutoComplete dropdown placeholder={strings.filterSearchPlaceHolder} style={{ width: "100%" }} id="vendor" value={preFilters.vendor} suggestions={vendors} field="name" completeMethod={changeVendorSearchBar} onChange={(onFiltersChange)} />
                            {/* <InputText id="companyName" value={preFilters.companyName} onChange={onFiltersChange} /> */}
                        </div>}

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.referenceNumber}</label>

                            <InputText id="referenceNumber" value={preFilters.referenceNumber} onChange={onFiltersChange} />

                        </div>
                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.Name}</label>

                            <InputText id="name" value={preFilters.name} onChange={onFiltersChange} />

                        </div>

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.amountFrom}</label>
                            <InputText type="number" id="amountGreaterThan" value={preFilters.amountGreaterThan} onChange={onFiltersChange} />
                        </div>

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.amountTo}</label>
                            <InputText type="number" id="amountLessThan" value={preFilters.amountLessThan} onChange={onFiltersChange} />
                        </div>


                        {<div className="p-field p-col-3" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.paymentType}</label>
                            <Dropdown style={{ width: '100%' }} id="paymentType" value={preFilters.paymentType} onChange={onFiltersChange} options={paymentTypes} optionLabel="name" ></Dropdown>
                            {/* <InputText id="companyName" value={preFilters.companyName} onChange={onFiltersChange} /> */}
                        </div>}

                        <div className={`p-field p-col-3`} style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.dateFrom}</label>
                            <Calendar id="paymentDateAfter" showTime={true} showSeconds={true} showIcon showButtonBar value={formatDate(preFilters.paymentDateAfter)} onChange={onFiltersChange} />
                        </div>

                        <div className={`p-field p-col-3`} style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.dateTo}</label>
                            <Calendar id="paymentDateBefore" showTime={true} showSeconds={true} showIcon showButtonBar value={formatDate(preFilters.paymentDateBefore)} onChange={onFiltersChange} />
                        </div>

                        <div className="p-field p-col" style={{ margin: 'auto' }}>
                            <hr />

                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Filter} icon="pi pi-search" className="p-button-success p-mr-1 p-mb-1" onClick={() => {
                                let f = { ...preFilters }
                                // 2021-09-21T21:50:37.000Z
                                setFilters({
                                    paymentDateAfter: f.paymentDateAfter ? moment(f.paymentDateAfter).utc().format() : null,
                                    paymentDateBefore: f.paymentDateBefore ? moment(f.paymentDateBefore).utc().format() : null,
                                    referenceNumber: f.referenceNumber,
                                    amountGreaterThan: f.amountGreaterThan,
                                    amountLessThan: f.amountLessThan,
                                    paymentTypeId: f.paymentType?.code,
                                    vendorId: f.vendor?.code
                                });
                            }} />
                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Clear} icon="pi pi-times" className="p-button-warning p-mr-1 p-mb-1" onClick={() => {
                                setPreFilters({});
                            }} />
                        </div>
                    </div>
                </TableFilters>
            </div>


        </div>
    );



    const paymentDialogFooter = (
        <>
            <Button label={strings.Cancel} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );
    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} ></Toolbar>
                    {importStatus != null && <> {importStatus != 4 && <ProgressSpinner />} <Message severity={importStatus == 4 ? "success" : "info"} content={importStatus == 1 ? strings.PaymentsBulkUploadInit : (importStatus == 2 ? strings.PaymentsBulkUploadInProgress : strings.PaymentsBulkUploadSuccessfull)} /></>}
                    {importError && <Message severity="error" content={importError}></Message>}

                    <DataTable
                        // ref={dt}
                        value={payments}
                        totalRecords={totalRecords}
                        first={first}
                        loading={loading}
                        onPage={onPage}
                        rows={rows}
                        header={header}
                        {...defaultTableConfiguration}>
                        <Column field="referenceNumber" header={strings.referenceNumber} body={paymentNumberBodyTemplate}></Column>

                        {/* <Column field="peppol" header={strings.PEPPOL} body={skuBodyTemplate}></Column> */}
                        <Column field="companyAccount" header={strings.Account} body={paymentTypeBodyTemplate}></Column>
                        {full && <Column field="vendor" header={strings.Vendor} body={vendorBodyTemplate}></Column>}
                        {full && <Column field="supplier" header={strings.supplier} body={customerBodyTemplate}></Column>}
                        {full && <Column field="paymentType" header={strings.paymentType} body={paymentTypeBodyTemplate}></Column>}
                        <Column field="amount" header={strings.amount} body={barcodeBodyTemplate}></Column>
                        {/* <Column field="reconciledAmount" header={strings.reconciledAmount} body={reconciledBodyTemplate}></Column> */}
                        <Column field="paymentDate" header={strings.date} body={creationDateBodyTemplate}></Column>
                        {/* <Column field="relatedInvoices"  body={(rowData) => <Button style={{width:'100%'}} icon="pi pi-search"   onClick={() => {
                            setShowRelatedInvoices(true);
                            setRelatedInvoices(rowData.invoicePayments);
                        }}>{strings.relatedInvoices}</Button>}></Column> */}
                         {/* <Column field="reconcilePayment"  body={(rowData) => rowData.reconciledAmount < rowData.amount ? <Button icon="pi pi-check" onClick={() => {
                            setShowReconcilePayment(true);
                            setPayment(rowData);
                        }}>{strings.reconcilePayment}</Button> : ''}></Column> */}
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={paymentDialog} style={{ width: '700px' }} header="Payments Details" modal className="p-fluid" footer={paymentDialogFooter} onHide={hideDialog}>
                        <PurchasePaymentForm context={context} OnHideDialog={hideDialog} canEdit={canEdit} payment={payment} FillData={() => onPage({ rows, page: 0, vendorID: vendor?.uuid })} />
                    </Dialog>
                    <Dialog visible={showRelatedInvoices} style={{ width: '700px' }} header={strings.relatedInvoices} modal onHide={()=>setShowRelatedInvoices(false)}>
                        <DataTable
                            // ref={dt}
                            {...defaultTableConfiguration}
                            value={relatedInvoices}
                            totalRecords={relatedInvoices?.length}
                            first={first}
                            loading={loading}
                            lazy={true}
                            scrollable
                            rows={100}
                            // header={header}
                            >
                            <Column field="invoiceNumber" header={strings.InvoiceNumber} body={(rowData)=>rowData.invoice.invoiceNumber}></Column>
                            <Column field="total" header={strings.payment} body={(rowData)=>rowData.amount + " " + rowData.invoice.currencyCode}></Column>
                            <Column field="total" header={strings.invoiceTotalAmount} body={(rowData)=>rowData.invoice.payableAmount + " "+rowData.invoice.currencyCode}></Column>
                            <Column field="action"  body={(rowData)=><Button onClick={()=>history.push(`/profile/invoices/${rowData.invoice.uuid}`)}>{strings.details}</Button>}></Column>
                        </DataTable>
                    </Dialog>
                    <Dialog visible={showReconcilePayment} style={{ width: '700px' }} header={strings.reconcilePayment} modal onHide={()=>setShowReconcilePayment(false)}>
                        <div>

                        <h4>
                            {strings.pickInvoices}
                        </h4>

                        <h6>
                            {strings.remaining}: {remainingAmount} {payment?.currencyCode}
                        </h6>

                        <DataTable
                            // ref={dt}
                            {...defaultTableConfiguration}
                            value={invoicesPendingPayment.data}
                            totalRecords={invoicesPendingPayment.count}
                            first={first}
                            loading={loading}
                            selection={selectedInvoices}
                            footer={<Button onClick={()=>{
                                    new PaymentService().ReconcilePayment({
                                        invoices: selectedInvoices.map(i=>i.uuid),
                                        vendorId: payment.vendor.uuid,
                                        customerId: payment.customer.uuid,
                                        uuid: payment.uuid
                                    }).then(()=>{
                                        toast.current.show({ severity: 'success', summary: 'Reconcile Payment', detail: `Reconciled payment successfully`, life: 3000 });
                                        setShowReconcilePayment(false);
                                        setSelectedInvoices([]);
                                        onPage({ rows, page: 0,vendorID:vendor?.uuid})
                                        setPayment({})
                                    }).catch((e)=>{
                                        toast.current.show({ severity: 'error', summary: 'Reconcile Payment', detail: `Reconcile payment failed`, life: 3000 });
                                        alert(JSON.stringify(e.response.data))
                                    })
                                }}>
                                    {strings.continue}
                                </Button>
                            }
                            lazy={false}
                            scrollable
                            rows={100}
                            onSelectionChange={(e => {
                                // setRemainingAmount()
                                var remaining = payment?.amount
                                e.value.forEach(i=>{
                                    remaining -= Math.min(i.payableAmount, remaining);
                                });

                                setRemainingAmount(remaining);

                                setSelectedInvoices(e.value)
                            })}
                            // header={header}
                            >
                            <Column selectionMode="multiple"  headerStyle={{ width: '3rem' }}></Column>
                            <Column field="invoiceNumber" header={strings.InvoiceNumber} body={(rowData)=>rowData.invoiceNumber}></Column>
                            <Column field="payment" header={strings.payment} body={(rowData)=>rowData.paidAmount + " " + rowData.currencyCode}></Column>
                            <Column field="total" header={strings.invoiceTotalAmount} body={(rowData)=>rowData.payableAmount + " "+rowData.currencyCode}></Column>
                            <Column field="action"  body={(rowData)=><Button onClick={()=>history.push(`/profile/invoices/${rowData.invoice.uuid}`)}>{strings.details}</Button>}></Column>
                        </DataTable>
                        </div>

                    </Dialog>

                    <Dialog visible={deletePaymentDialog} style={{ width: '450px' }} header={strings.deletePayment} modal footer={deletePaymentDialogFooter} onHide={hideDeletePaymentDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {<span>Are you sure you want to delete <b>{payment?.name}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
