import {createSlice} from "@reduxjs/toolkit"
import VendorService from '../service/VendorService';
import SubscriptionService from "../service/SubscriptionService";
import {getItem, setItem, id} from '../service/helpers'
const SP = new SubscriptionService()

const vendors = createSlice({
    name: 'vendors',
    initialState: {count: 0, vendors: [], Vendors: getItem('Vendors') || [], activeVendor: {}},
    reducers: {
        addVendors(state, action){
            return {...state, count: action.payload.count, vendors: action.payload.data}
        },
        addVendor(state, action) {
            setItem('Vendors', [...state.Vendors, action.payload])
            return {...state, Vendors: [...state.Vendors, action.payload]}
        },
        // addVendors(state, action) {
        //     return {...state, Vendors: action.payload}
        // },
        updateVendor(state, action){
            let newState = state.Vendors.map(vendor => {
                if (vendor.uuid === action.payload.uuid) return action.payload
                return vendor
            })
            setItem('Vendors', newState)
            return {...state, Vendors: newState}
        },
        deleteVendor(state, action){
            let newState = state.Vendors.filter(vendor => vendor.uuid !== action.payload.uuid)
            setItem('Vendors', newState)
            return {...state, Vendors: newState}
        },
        setActiveVendor(state, action) {
            return {...state, activeVendor: action.payload}
        }
    }
})

export const getVendors = () => async (dispatch) => {
try {

    let {count,data} = await new VendorService().GetVendors()
    console.log("🚀 ~ file: vendors.js ~ line 44 ~ getVendors ~ data", data)
    dispatch(addVendors({count: count, data: data}))
    return {errorCode: null, errors: null}
} catch (error) {
console.log("🚀 ~ file: vendors.js ~ line 18 ~ getVendors ~ error", error)
    return {errorCode: error.errorCode, errors: error.errors}
}

}

export const addVendorHandler =  payload => async (dispatch) =>{
    try {
        dispatch(addVendor({...payload, uuid: id(), name: payload.nameen}))
    } catch (error) {
    console.log("🚀 ~ file: vendors.js ~ line 31 ~ addVendor ~ error", error)

    }
}

export const addVendorsHandler = (payload) => async dispatch =>{
    try {
       dispatch(addVendors(getItem('Vendors')))
    } catch (error) {
    console.log("🚀 ~ file: vendors.js ~ line 46 ~ error", error)

    }
}

export const updateVendorsHandler = payload => async (dispatch) =>{
    try {
        dispatch(updateVendor(payload));
    } catch (error) {
    console.log("🚀 ~ file: vendors.js ~ line 58 ~ updateVendorsHandler ~ error", error)

    }
}

export const deleteVendorsHandler = payload => async (dispatch) =>{
    try {
        dispatch(deleteVendor(payload))
    } catch (error) {
    console.log("🚀 ~ file: vendors.js ~ line 74 ~ deleteVendorsHandler ~ error", error)

    }
}

export const getActiveVendor = () =>  async (dispatch) => {
    try {
        let vendor = await SP.GetActiveCompany()
        dispatch(setActiveVendor(vendor))
    } catch (error) {
        console.log("🚀 ~ file: vendors.js ~ line 89 ~ getActiveVendor ~ error", error)

    }
}


const {addVendors,addVendor, updateVendor ,deleteVendor,setActiveVendor} = vendors.actions

export default vendors.reducer

