import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import Styled from "styled-components";

import '../assets/assets/css/magnific-popup.css'
import '../assets/assets/css/slick.css'
import '../assets/assets/css/LineIcons.css'
import '../assets/assets/css/bootstrap.min.css'
import '../assets/assets/css/default.css'
import '../assets/assets/css/style.css'
import { useParams } from "react-router-dom";
import UserService from '../service/UserService';
import strings from '../localization';

export const ResetPassword = () => {

    const history = useHistory();
    const location = useLocation();
    var rtt = new URLSearchParams(location.search).get("rtt")
    const [isMenuActive, setMenuActive] = useState(false);
    const [loading, setLoading] = React.useState(true);
    const [invalid, setInvalid] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [done, setDone] = React.useState(false);
    const [style, setStyle] = React.useState(true);
    const [password, setPassword] = React.useState(null);
    const [confirmPassword, setConfirmPassword] = React.useState(null);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
        if(rtt == null) {
            setInvalid(true);
        }
    }, [])


    const GoToSubscription = (id) => {
        history.push(`/subscription?id=${id}`)
    }

    return (
        <div className="bootstrap-root">
            <div>
                <div className={`preloader ${loading ? '' : 'hide-loader'}`}>
                    <div className="loader">
                        <div className="ytp-spinner">
                            <div className="ytp-spinner-container">
                                <div className="ytp-spinner-rotator">
                                    <div className="ytp-spinner-left">
                                        <div className="ytp-spinner-circle"></div>
                                    </div>
                                    <div className="ytp-spinner-right">
                                        <div className="ytp-spinner-circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="navbar-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <nav className="navbar navbar-expand-lg">

                                    <a className="navbar-brand" href="#">
                                        <img src="/assets/layout/images/logo-white.svg" style={{ height: "4.25rem" }} alt="Logo" />
                                    </a>

                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTwo" aria-controls="navbarTwo" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                    </button>

                                    <div className="collapse navbar-collapse sub-menu-bar" id="navbarTwo">
                                        <ul className="navbar-nav m-auto">
                                        </ul>
                                    </div>

                                    <div className="navbar-btn d-none d-sm-inline-block">
                                        <ul>
                                            <li><Link to={'/login'} className="solid" href="#">Login</Link></li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="home" className="slider_area">
                    <div id="carouselThree" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="slider-content">
                                                {done ? <h1>Password was changed successfully!</h1> : !invalid ? (<><h1 className="title">Change Password</h1>
                                                    <p style={{ color: "white" }}>Type your new password:</p>
                                                    <form>
                                                        <div class="form-group">
                                                            <label style={{ color: 'white' }} for="exampleInputEmail1">Password</label>
                                                            <input onChange={(e) => setPassword(e.target.value)} type="password" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter password" />
                                                        </div>
                                                        <div class="form-group">
                                                            <label style={{ color: 'white' }} for="exampleInputPassword1">Confirm Password</label>
                                                            <input onChange={(e) => setConfirmPassword(e.target.value)} type="password" class="form-control" id="exampleInputPassword1" placeholder="Confirm password" />
                                                        </div>
                                                        <br />
                                                        {error && <div class="alert alert-danger" role="alert">
                                                            {error}
                                                        </div>
                                                        }
                                                        <button onClick={async () => {
                                                            var userService = new UserService();
                                                            if (password != confirmPassword) {
                                                                setError("Passwords does match");
                                                            } else {
                                                                try {
                                                                    await userService.ResetPassword({
                                                                        password,
                                                                        token: rtt
                                                                    });
                                                                    setDone(true);
                                                                } catch {
                                                                    setInvalid(true);
                                                                }
                                                            }
                                                        }} type="button" class="btn btn-primary">Submit</button>
                                                    </form></>) : <h1 className="title" style={{ color: 'black' }}>Internal Server Error</h1>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </section>


                <section className="footer-area footer-dark">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="footer-logo text-center">
                                    <a className="mt-30" href="index.html"><img src="/assets/layout/images/logo-white.svg" style={{ height: "4.25rem" }} alt="Logo" /></a>
                                </div>
                                <ul className="social text-center mt-60">
                                </ul>
                                <div className="copyright text-center mt-35">
                                    {/* <p className="text">Designed by <a href="https://uideck.com" rel="nofollow">UIdeck</a> and Built-with <a rel="nofollow" href="https://ayroui.com">Ayro UI</a> </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <a href="#" className="back-to-top"><i className="lni lni-chevron-up"></i></a>


            </div>
        </div>


    )
}

