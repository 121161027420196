import { createSlice } from "@reduxjs/toolkit"



const issuer = createSlice({
    name: 'issuer',
    initialState: { issuers: { data: [], count: 0 }, msg: '', issuer:{} },
    reducers: {
        addIssuer(state, action) {
            return { ...state, issuers: { data: [...state.issuers.data, action.payload], count: Number(state.issuers.count) + 1 } }
        },
        addIssuers(state, action) {
            return { ...state, issuers: action.payload }
        },
        deleteIssuer(state, action) {
            return { ...state, issuers: { data: [...action.payload], count: Number(state.issuers.count) - 1 } }
        },
        addMsg(state, action) {
            return { ...state, msg: action.payload }
        },
        updateIssuer(state, action){
            return { ...state, issuers: {...state.issuers, data: action.payload}}
        },
        getIssuer(state, action){
            return { ...state,issuer: action.payload}
        }

    },
})


export default issuer.reducer

export const {addIssuer,addMsg, addIssuers, deleteIssuer,updateIssuer,getIssuer} = issuer.actions