import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import PaymentService from '../service/PaymentService';
import { ProgressSpinner } from 'primereact/progressspinner';
import VendorService from '../service/VendorService';
import { Message } from 'primereact/message';
import strings from '../localization';
import Can from '../Can';
// import PaymentTypeService from '../service/PaymentTypeService';
import { InputTextarea } from 'primereact/inputtextarea';
import { formatDate, formatDateString } from './InvoiceDates';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { CurrencyRates } from './CurrencyRates';
import CurrencyRateService from '../service/CurrencyRateService';
import PaymentTypeService from '../service/PaymentTypeService';
import { AutoComplete } from 'primereact/autocomplete';
import CustomerService from '../service/CustomerService';
import CustomerDropdown from './CustomerDropdown';
import CompanyAccountService from '../service/CompanyAccountService';
import AddAccountDialog from './invoicesPayment/components/AddAccountDialog';
import {getCurrency} from '../service/helpers'


const currencyCodes = [
    { code: getCurrency(), name: getCurrency() },
    { code: 'USD', name: 'USD' },
    { code: 'EUR', name: 'EUR' },
    { code: 'AED', name: 'AED' },
    { code: 'KWD', name: 'KWD' },
]
export default class PaymentForm extends Component {

    constructor(props) {
        super(props);

        let emptyPayment = {
            amount: 0,
            quantity: "",
            unitCode: "",
            currency: currencyCodes[0],
            taxCategories: [],
            nameen: "",
            namear: "",
            paymentType: {},
            decriptionen: "",
            peppol: "",
            decriptionar: "",
        }

        this.state = {
            payment: emptyPayment,
            paymentTypes: [],
            taxCategories: [],
            rates: [
                {
                    code: getCurrency(),
                    name: getCurrency(),
                    targetCurrency: getCurrency(),
                    baseCurrency: getCurrency(),
                    rate: 1.0,
                }
            ],
            Selected: [],
            errors: [],
            vendor: [],
            loading: false,
            pageLoading: false,
        };
        this.toast = React.createRef();
    }

    PopulateVendors = async () => {
        let vendorService = new VendorService();
        this.setState({ pageLoading: true });
        var vendors = await vendorService.GetVendors(500);
        this.setState({ vendors: vendors.data, pageLoading: false })
    }
    PopulateCustomers = async () => {
        let customerService = new CustomerService();
        // this.setState({ pageLoading: true });
        var customers = await customerService.GetCustomers(500,0,this.state.payment.vendor?.uuid);
        this.setState({ customers: customers.data.map(c=>{return {
            code: c.uuid,
            name: c.registrationName
        }}), pageLoading: false })
    }

    PopulateCompanyAccounts = async () => {
        let companyAccountService = new CompanyAccountService();
        // this.setState({ pageLoading: true });
        var companyAccounts = await companyAccountService.getCompanyAccounts(500,0,{
            vendorId: this.state.payment.vendor?.uuid
        });
        this.setState({ companyAccounts: companyAccounts.data.map(c=>{return {
            code: c.uuid,
            name: c.name
        }}), pageLoading: false })
    }
    PopulatePaymentTypes = async () => {
        let paymentTypeService = new PaymentTypeService();
        this.setState({ pageLoading: true });
        var paymentTypes = await paymentTypeService.GetPaymentTypes(500);
        this.setState({ paymentTypes: paymentTypes.data, pageLoading: false })
    }



    PopulateBranches = async () => {
        let { branches, showBranch } = this.props.context ?? {};
        if (showBranch) {
            this.setState({ branches: this.state.payment['vendor']?.branches })
        }
        else {
            this.setState({ branches });
        }
    }

    componentDidMount() {

        if (this.props.context?.showCompany) this.PopulateVendors();

        this.PopulatePaymentTypes();
        this.PopulateBranches();
        if (this.props.payment != null) {
            // Fill Data
            this.PopulatePayment()
        }
        else {
            this.setState({
                payment: {
                    vendor: {
                        code: this.props.context?.vendor?.uuid,
                        name: this.props.context?.vendor?.name,
                    },
                    paymentType: {
                        code: this.props.context?.paymentType?.uuid,
                        name: this.props.context?.paymentType?.name,
                    },
                    branch: {
                        code: this.props.context?.branch?.uuid,
                        name: this.props.context?.branch?.name,
                    },
                    currency: currencyCodes[0]
                }
            })
        }

        new CurrencyRateService().GetCurrencyRates().then(({ data }) => {
            if (data.filter(d => d.targetCurrency == getCurrency()).length == 0) {
                data.push({
                    code: getCurrency(),
                    name: getCurrency(),
                    targetCurrency: getCurrency(),
                    baseCurrency: getCurrency(),
                    rate: 1.0,
                });
            }

            data = data.reverse();

            this.setState({
                rates: data.map(d => {
                    return {
                        code: d.targetCurrency,
                        name: d.targetCurrency,
                        ...d
                    }
                })
            });
        });
    }


    PopulatePayment() {
        this.setState({
            payment: {
                ...this.props.payment,
                vendor: {
                    code: this.props.payment.vendor?.uuid,
                    name: this.props.payment.vendor?.name,
                },
                paymentType: {
                    code: this.props.payment.paymentType?.uuid,
                    name: this.props.payment.paymentType?.name,
                },
                currency: {
                    code: this.props.payment.currency,
                    name: this.props.payment.currency
                },
                currencyRate: {
                    rate: this.props.payment.currencyRate
                },

            }
        })
    }

    onInputChange = (e, name) => {
        let val = (e.target && e.target.value) || '';
        let _payment = { ...this.state.payment };

        if (name == 'discountPercentage') {
            if (val > 100) {
                val = 100;
            }

            if (val < 0) {
                val = 0;
            }
        }
        _payment[`${name}`] = val;

        this.setState({ payment: _payment });
    }

    submitForm = async () => {
        this.setState({ loading: true });

        let { payment } = this.state;

        let data = {
            ...payment,
            "vendorId": this.props.context?.vendor?.uuid ?? (payment.vendor?.code),
            "companyAccountId": payment.companyAccount?.code,
            "branchId": this.props.context?.branch?.uuid ?? (payment.branch?.code),
            "paymentTypeId": payment.paymentType?.code,
            "customerId": payment.customer?.code,
            "currency": payment.currency?.code,
            "currencyRate": payment.currencyRate?.rate ?? payment.currencyRate,
            "isActive": true
        }

        var paymentService = new PaymentService();

        if (this.props.payment == null) {
            let res = await paymentService.CreatePayment(data);
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors });
            } else {
                this.toast.current.show({ severity: 'success', summary: 'Successful', detail: strings.paymentCreatedSuccessfully, life: 3000 });
                this.props.OnHideDialog();
                this.props.FillData();
                this.setState({
                    payment: {
                        vendorId: "",
                        referenceNumber: "",
                        amount: 0,
                    },
                    errors: [],
                    Selected: []
                });
            }
        }
        else {
            data.uuid = this.props.payment.uuid;
            let res = await paymentService.UpdatePayment(data);
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors });
            } else {
                this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'payment Updated', life: 3000 });
                this.props.OnHideDialog();
                this.props.FillData();
            }
        }
        this.setState({ loading: false });
    }


    vendorDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { this.setState({ addVendor: false }) }} />
        </>
    );

    render() {
        if (this.state.pageLoading) {
            return <div style={{ textAlign: 'center' }}><ProgressSpinner /></div>
        }

        let { showCompany, role } = this.props.context ?? {};
        let { canEdit } = this.props ?? {};
        let { payment, customers } = this.state;
        const isRTL = localStorage.getItem('lang') == 'ar' ? 'rtl' : 'ltr';

        return (

            <div className="p-grid crud-demo" style={{ direction: isRTL }}>
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={this.toast} />

                        {this.state.payment.vendor && this.state.payment.vendor.code && canEdit?
                            <div className="p-field">
                                <Message severity={"warn"} content={this.state.payment.vendor.automaticReconciliation ? strings.automaticReconciliationWarning : strings.manualReconciliationWarning}>
                                </Message>
                            </div>
                            : ''}

                        <div className="p-field">
                            {showCompany ? <label htmlFor="name">{strings.Vendor}*</label> : ''}
                            {showCompany && canEdit ? <Dropdown id="vendor" value={this.state.payment?.vendor} onChange={(e) => {
                                let payment = { ...this.state.payment }
                                payment['vendor'] = e.value;
                                this.setState({ payment }, () => {
                                    this.PopulateBranches()
                                    this.PopulateCustomers()
                                });
                            }} options={this.state.vendors?.map((v) => {
                                return {
                                    ...v,
                                    code: v.uuid,
                                    name: v.name
                                }
                            })} optionLabel="name" placeholder={strings.Select}></Dropdown> : <p>{this.state.payment?.vendor?.name}</p>}
                        </div>

                        <div className="p-field">
                            <CustomerDropdown customer={payment.customer} onChange={(e)=>this.onInputChange(e,'customer')} canEdit={canEdit} vendor={payment?.vendor?.code}/>
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">{strings.paymentType}*</label>
                            {canEdit ? <Dropdown id="paymentType" value={this.state.payment?.paymentType} onChange={(e) => {
                                let payment = { ...this.state.payment }
                                payment['paymentType'] = e.value;
                                this.setState({ payment }, () => e.value.showAllOptions && this.PopulateCompanyAccounts());
                            }} options={this.state.paymentTypes?.map((v) => {
                                return {
                                    code: v.uuid,
                                    name: v.name,
                                    showAllOptions: v.showAllOptions
                                }
                            })} optionLabel="name" placeholder={strings.Select}></Dropdown> : <p>{this.state.payment?.paymentType.name}</p>}
                        </div>

                        <div className="p-field">

                            {this.state.payment.paymentType?.showAllOptions ? <label htmlFor="name">{strings.selectAccount}</label> : ''}
                            <div style={{ display: 'flex', paddingTop: '10px', width:'100%' }}>
                            {this.state.payment.paymentType?.showAllOptions && canEdit ? <Dropdown style={{width:'100%'}} id="companyAccount" value={this.state.payment?.companyAccount} onChange={(e) => {
                                let payment = { ...this.state.payment }
                                payment['companyAccount'] = e.value;
                                this.setState({ payment });
                            }} options={this.state.companyAccounts} optionLabel="name" placeholder={strings.Select}></Dropdown> : <p>{this.state.payment?.companyAccount?.name}</p>}
                            {this.state.payment.paymentType?.showAllOptions && canEdit && <Can yes={() => <Button icon="pi pi-plus" tooltipOptions={{
                                position: 'top'
                            }} tooltip="Create Company Account" onClick={() => { this.setState({ addAccount: true }) }}></Button>}
                                perform={'customers:create'}
                                silent={true}
                                role={role} />}
                            </div>
                        </div>

                        <AddAccountDialog visible={this.state.addAccount} onSuccess={()=>this.PopulateCompanyAccounts()} onHide={() => { this.setState({ addAccount: false }) }} vendorId={payment.vendor?.uuid} />

                        <div className='p-grid'>

                            {canEdit ? <div className="p-field p-col-6 p-md-6">
                                <label htmlFor="name">{strings.amount}*</label>
                                <InputText id="price" type="amount" value={this.state.payment.amount} onChange={(e) => this.onInputChange(e, 'amount')} required autoFocus />
                            </div> : ''}

                            {canEdit ? <div className="p-field p-col-6 p-md-6">
                                <b htmlFor="currencyCode">{strings.Currency}:</b>
                                <div style={{ display: 'flex', paddingTop: '6px' }}>
                                    <Dropdown id="currency" value={this.state.payment?.currency} onChange={(e) => {
                                        let payment = { ...this.state.payment }
                                        payment['currency'] = e.value;
                                        payment['currencyRate'] = this.state.rates?.filter(r => r.code == e.value?.code)[0]
                                        this.setState({ payment });
                                    }} options={currencyCodes.filter(c => {
                                        return this.state.rates.filter(r => r.code == c.code).length > 0
                                    })} optionLabel="name" style={{ width: '100%' }} placeholder="Select One"></Dropdown>
                                    <Can yes={() => <Button icon="pi pi-eye" tooltipOptions={{
                                        position: 'top'
                                    }} tooltip="Check Currency Rate" onClick={() => { this.setState({ addRate: true }) }}></Button>}
                                        perform={'currencyRates:create'}
                                        silent={true}
                                        role={role} />
                                    {(this.state.payment.currency ?? this.state.payment.currency?.code) != getCurrency() ?
                                        <span>&nbsp;({this.state.payment.currencyRate?.rate} {this.state.payment.currency?.code} = 1 {getCurrency()})</span> : ''}
                                </div>

                            </div> : <div className="p-field p-col-12 p-md-12">
                                <label htmlFor="name">{strings.amount}</label>
                                <p>
                                    {this.state.payment?.amount} {this.state.payment.currency?.code}
                                </p>
                            </div>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">{strings.date}*</label>
                            {canEdit ? <Calendar id="paymentDate" showTime={true} showSeconds={true} showIcon showButtonBar value={formatDate(this.state.payment.paymentDate)} onChange={(e) => this.onInputChange(e, 'paymentDate')}></Calendar> : <p>
                                {formatDateString(this.state.payment?.paymentDate)}
                            </p>}
                        </div>

                        <div className="p-field">
                            <label htmlFor="name">{strings.referenceNumber}:</label>
                            {canEdit ? <InputText id="referenceNumber" value={this.state.payment.referenceNumber} onChange={(e) => this.onInputChange(e, 'referenceNumber')} />:<p>
                                    {this.state.payment.referenceNumber??'-'}
                            </p>}
                        </div>

                        <div className="p-field">
                            <label htmlFor="state">{strings.comments}:</label>

                             {canEdit ? <InputTextarea id="comments" value={this.state.payment.comments} onChange={(e) => this.onInputChange(e, 'comments')} type="text" /> : <p>
                                    {this.state.payment.comments ?? '-'}
                            </p>}
                        </div>


                        {canEdit && <div className="p-field">
                            {this.state.errors?.length > 0 && <Message text={this.state.errors.map((m) => <li>{strings[m] ?? m}</li>)} severity="error" />}
                            <Button label={strings.Save} loading={this.state.loading} onClick={this.submitForm} loadingoptions={{ position: 'right' }} />
                        </div>}

                        <Dialog visible={this.state.addRate} style={{ width: '75%' }} modal className="p-fluid" footer={this.vendorDialogFooter} onHide={() => { this.setState({ addRate: false }) }}>
                            <CurrencyRates />
                        </Dialog>

                    </div>
                </div>
            </div>
        );
    }
}
