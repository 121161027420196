import { createSlice } from "@reduxjs/toolkit"

const accounts = createSlice({
    name: 'accounts',
    initialState:{msg:'', accounts: {data:[], count: 0}, account:{}},
    reducers:{
        addAccount(state, action){
            return {...state, accounts: {...state.accounts, data: [...state.accounts.data, action.payload]}};
        },
        addAccounts(state, action){
            return {...state, accounts: action.payload}
        },
        getAccount(state, action){
            return {...state, account: action.payload}
        },
        updateAccount(state, action){
            return {...state, accounts: {...state.accounts, data: action.payload}}
        },
        deleteAccount(state, action){
            return {...state, accounts: {count: state.accounts.count - 1, data: action.payload}}
        },
        addMsg(state, action) {
            return {...state, msg: action.payload}
        }
        
    }
})


export default accounts.reducer
export const {addAccount, addAccounts,getAccount,updateAccount,deleteAccount,addMsg} = accounts.actions