import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import IdentityTypeService from '../service/IdentityTypeService';
import VendorService from '../service/VendorService';
import { useTheme } from 'styled-components';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import strings from '../localization';
import ImageUploading from "react-images-uploading";
import { InputTextarea } from 'primereact/inputtextarea';
import SubscriptionPlanService from '../service/SubscriptionPlanService';
import SubscriptionService from '../service/SubscriptionService';
import UserService from '../service/UserService';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { formatDate } from './InvoiceDates';


function generateToken(n) {
    var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var token = '';
    for(var i = 0; i < n; i++) {
        token += chars[Math.floor(Math.random() * chars.length)];
    }
    return token;
}

const SubscriptionIntervals = [
    { code: '1', name: 'One Month' },
    { code: '3', name: 'One Week' },
    { code: '5', name: '3 Months' },
    { code: '4', name: '6 Months' },
    { code: '2', name: 'One Year' },
    { code: '6', name: 'Three Years' },
    { code: '7', name: 'Unlimited' },
]

export class SubscriptionForm extends Component {


    constructor(props) {
        super(props);


        let emptySubscription = {
            id: null,
            subscriptionPlanId: '',
            name: null,
            userEmail: '',
            userName: '',
            paymentToken: null,
            tapId: "",
            subscriptionInterval: 1,
            userPhone: "",
        }


        this.state = {
            subscription: { emptySubscription },
            plans: [],
            users: [],
            loading: false,
            pageLoading: false,
            errors: [],
        };
        this.toast = React.createRef();



    }


    componentDidMount() {

        this.fillSubscriptionPlans();
        this.fillUsers();
        // console.log(this.props.user)

        if (this.props.subscription != null) {
            // Fill Data
            this.ReturnData()
        }


    }


    ReturnData() {
        this.setState({ subscription: this.props.subscription })
    }

    onEditorValueChange(productKey, props, value) {
        let updatedRow = [...props.value];
        console.log(value)
        updatedRow[props.rowIndex][props.field] = value;
        this.setState({ [`${productKey}`]: updatedRow });
    }



    onInputChange = (e, name) => {
        let val = (e.target && e.target.value) || '';
        if(name=='sendActivationEmail')
        {
            val = !this.state.sendActivationEmail
        }
        let subscription = { ...this.state.subscription };
        subscription[`${name}`] = val;

        this.setState({ subscription });
    }


    fillSubscriptionPlans = async () => {
        let svc = new SubscriptionPlanService();
        this.setState({ pageLoading: true })
        var plans = await svc.GetSubscriptionPlans();
        this.setState({ plans: plans.data, pageLoading: false })
    }

    fillUsers = async () => {
        let svc = new UserService();
        this.setState({ pageLoading: true })
        var users = await svc.GetUsers();
        this.setState({ users: users.data, pageLoading: false })
    }


    AddRow = () => {

        let rows = [...this.state.subscription.rows ?? [], { identityId: null, name: "", value: "" }];

        let _user = { ...this.state.subscription };

        _user.rows = rows;


        this.setState({ user: _user });



    }


    GetData = () => {


        // console.log(this.state.user);

    }


    delete = (rowData) => {
        let _user = { ...this.state.subscription };
        _user.rows = _user.rows.filter(val => val.identityId == rowData.identityId);
        this.setState({ user: _user });

        this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    }


    nameBodyTemplate = (rowData, props) => {
        return (
            <>


                {/* <span className="p-column-title">Name</span> */}

                {/* {console.log(rowData)} */}



                {/* {rowData.name} */}

            </>
        );
    }



    valueBodyTemplate = (rowData, props) => {
        return (
            <>

                <InputText id='value' type="text" value={props.rowData['value']} onChange={(e) => this.onEditorValueChange('value', props, e.target.value)} required autoFocus />

            </>
        );
    }



    actionBodyTemplate = (rowData) => {

        return (
            <div className="actions">
                <Button icon="pi pi-trash" onClick={() => this.delete(rowData)} className="p-button-rounded p-button-warning" />
            </div>
        );
    }

    save = async () => {


        this.setState({ loading: true });

        let { subscription } = this.state;

        let data = {
            id: subscription.uuid,
            subscriptionPlanId: subscription.subscriptionPlanId,
            name: subscription.name,
            userEmail: subscription.userEmail,
            userId: subscription.userId,
            userName: subscription.userName,
            paymentToken: "portal_"+generateToken(8),
            tapId: "portal_"+generateToken(8),
            subscriptionInterval: subscription.subscriptionInterval?.code,
            userPhone: subscription.userPhone,
        }

        var subscriptionService = new SubscriptionService();

        if (this.props.subscription == null) {
            let res = await subscriptionService.CreateSubscription(data);
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors });
            } else {
                this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Subscription Created', life: 3000 });
            }
        }
        else {
            data.uuid = this.props.subscription.uuid;
            let res = await subscriptionService.UpdateSubscription({
                ...data,
                planId: data.subscriptionPlanId??subscription.subscriptionPlan.uuid,
                end: subscription.end,
                start: subscription.start,
                invoicesLeft: subscription.invoicesLeft
            });
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors });
            } else {
                this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Subscription Updated', life: 3000 });
            }
        }
        this.props.FillData();

        this.setState({ loading: false });

        this.setState({
            subscription: {
                id: null,
                subscriptionPlanId: '',
                name: null,
                userEmail: '',
                userId: '',
                userName: '',
                paymentToken: null,
                tapId: "",
                subscriptionInterval: 1,
                userPhone: "",
            }
        });

        this.props.OnHideDialog();

    }


    render() {

        if (this.state.pageLoading) {
            return <div style={{ textAlign: 'center' }}><ProgressSpinner /></div>
        }

        const isRTL = localStorage.getItem('lang')== 'ar' ? 'rtl' : 'ltr';


        const edit = this.props.subscription != null;

        return (

            <div className="p-grid crud-demo"   style={{direction:isRTL}}>
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={this.toast} />

                        <div className="p-grid">

                            <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.subscriptionPlan}</label>
                                    {JSON.stringify(this.state.subscription.subscriptionPlanId)}
                                    <Dropdown id="subscriptionPlanId" value={this.state.subscription.subscriptionPlanId??this.state.subscription.subscriptionPlan?.uuid} onChange={(e) => this.onInputChange(e, 'subscriptionPlanId')} optionLabel="name" options={this.state.plans?.map(o => {
                                        return {
                                            name: o.title,
                                            value: o.uuid
                                        }
                                    })} style={{ width: '100%' }} placeholder={strings.Select} />
                                </div>
                            </div>

                            {this.props.subscription == null && <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.lifetime}</label>
                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <Dropdown style={{ width: '100%' }} id="subscriptionInterval" value={this.state.subscription.subscriptionInterval} onChange={(e) => this.onInputChange(e, 'subscriptionInterval')} optionLabel="name" options={SubscriptionIntervals} placeholder={strings.Select} />
                                    </div>
                                </div>
                            </div>}

                            <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.subscriptionName}</label>
                                    <InputText id="name" value={this.state.subscription.name} onChange={(e) => this.onInputChange(e, 'name')} autoFocus />
                                </div>
                            </div>

                            {this.props.subscription != null && this.props.subscription.status == 3 && <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.startDate}</label>
                                    <Calendar id="start" showTime={true} showSeconds={true} showIcon showButtonBar value={formatDate(this.state.subscription.start)} onChange={(e) => this.onInputChange(e, 'start')}></Calendar>
                                </div>
                            </div>}

                            {this.props.subscription != null && this.props.subscription.status == 3 && <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.Enddate}</label>
                                    <Calendar id="end" showTime={true} showSeconds={true} showIcon showButtonBar value={formatDate(this.state.subscription.end)} onChange={(e) => this.onInputChange(e, 'end')}></Calendar>
                                </div>
                            </div>}

                            {this.props.subscription != null && <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.Invoicesbalance}</label>
                                    <InputText id="invoicesLeft" value={this.state.subscription.invoicesLeft} onChange={(e) => this.onInputChange(e, 'invoicesLeft')} autoFocus />
                                </div>
                            </div>}

                            {this.props.subscription == null && <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="userEmail">{strings.UserEmail}</label>
                                    <InputText id="userEmail" value={this.state.subscription.userEmail} onChange={(e) => this.onInputChange(e, 'userEmail')} autoFocus />
                                </div>
                            </div>}

                            {this.props.subscription == null &&<div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.UserName}</label>
                                    <InputText id="userName" value={this.state.subscription.userName} onChange={(e) => this.onInputChange(e, 'userName')} autoFocus />
                                </div>
                            </div>}

                            {this.props.subscription == null && <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="userPhone">{strings.UserPhone}</label>
                                    <InputText id="userPhone" value={this.state.subscription.userPhone} onChange={(e) => this.onInputChange(e, 'userPhone')} autoFocus />
                                </div>
                            </div>}

                        </div>

                        <div className="p-field">
                            {this.state.errors?.length > 0 && <Message text={this.state.errors.map((m) => <li>{m}</li>)} severity="error" />}
                            <Button label={strings.Save} loading={this.state.loading} onClick={this.save} loadingoptions={{ position: 'right' }} />
                        </div>

                    </div>
                </div>
            </div>

        );
    }
}
