import React from 'react'
import { connect } from 'react-redux'
import routes from './routes'
import Can from '../../Can'
import {Route} from 'react-router-dom'
export const index = ({role, context}) => {

  return (
    <>
       {routes.map((route, i)=> <Can key={`key${i}`} yes={()=> <Route key={`route${i}`} path={route.path} exact={route.exact} component={()=> <route.component context={context}/>}/>} perform={route.perform} path={route.path} role={role}/>)}

    </>
  )
}

const mapStateToProps = (state) => ({
    role: state.user.role,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(index)