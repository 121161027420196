import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import moment from 'moment';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
// import PnlReportService from '../service/PnlReportService';
import { defaultTableConfiguration } from './TableConfiguration';
import { Checkbox } from 'primereact/checkbox';
import strings from '../localization';
import InvoiceReportingService from '../service/InvoiceReportingService';
import JsFileDownloader from 'js-file-downloader';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { formatDate } from './InvoiceDates';
import VendorService from '../service/VendorService';
import { Message } from 'primereact/message';


export const ProfitAndLossReports = ({context}) => {

    let emptyPnlReport = {};

    const [pnlReportDialog, setPnlReportDialog] = useState(false);
    const [deletePnlReportDialog, setDeletePnlReportDialog] = useState(false);
    const [deletePnlReportsDialog, setDeletePnlReportsDialog] = useState(false);
    const [pnlReport, setPnlReport] = useState(emptyPnlReport);
    const [selectedPnlReports, setSelectedPnlReports] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pnlReports, setPnlReports] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [first, setFirst] = useState(0);
    const [saving, setSaving] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        if (vendors.length == 0) {
            var vendorService = new VendorService();
            vendorService.GetVendors(500).then((data) => {
                if (!data.data) return [];
                return data.data.map(v => {
                    return { code: v.uuid, name: v.name, ...v }
                });
            }).then(data => {
                setVendors(
                    [{ code: null, name: '-' }, ...data]);
            });
        }
    }, [localStorage.getItem('subscription')]);

    useEffect(() => {
        // const pnlReportService = new PnlReportService();
        // pnlReportService.getPnlReports().then(data => setPnlReports(data));
        onPage({rows,page:0})
    }, []);


    const openNew = () => {
        setPnlReport(emptyPnlReport);
        setSubmitted(false);
        setPnlReportDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setPnlReportDialog(false);
    }

    const hideDeletePnlReportDialog = () => {
        setDeletePnlReportDialog(false);
    }

    const hideDeletePnlReportsDialog = () => {
        setDeletePnlReportsDialog(false);
    }

    const savePnlReport = async () => {
        setSubmitted(true);
        setSaving(true);
        var pnlReportService = new InvoiceReportingService();

        pnlReport.vendorId = pnlReport.vendor?.code ?? context.company?.uuid;

        await pnlReportService.GeneratePnlReport(pnlReport);
        setSaving(false);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Profit & Loss report Created', life: 3000 });
        onPage({ rows, page })
        // setPnlReports(_pnlReports);
        setPnlReportDialog(false);
        setPnlReport(emptyPnlReport);
    }

    const editPnlReport = (pnlReport) => {
        setPnlReport({ ...pnlReport });
        setPnlReportDialog(true);
    }

    const confirmDeleteReport = (pnlReport) => {
        // setPnlReport(pnlReport);
        setDeletePnlReportDialog(true);
    }

    const deletePnlReport = async () => {
        await (new InvoiceReportingService().DeletePnlReport(pnlReport))
        setDeletePnlReportDialog(false);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'PnlReport Deleted', life: 3000 });
        onPage({ rows, page })
    }


    const changeValue = (e) => {
        let i = { ...pnlReport };

        i[e.target.id] = e.target.value;

        setPnlReport(i);
    }
    const onTranslationChange = (e, locale) => {
        const val = (e.target && e.target.value) || '';
        let _pnlReport = { ...pnlReport };
        _pnlReport.translations[locale-1].name = val;
        setPnlReport(_pnlReport);
    }

    const onCheckBoxChange = (e) => {
        const val = e.checked;
        let _pnlReport = { ...pnlReport };
        _pnlReport[e.target.id] = val;
        setPnlReport(_pnlReport);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }



    const fromTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{strings.from}</span>
                {moment.utc(rowData.from).local().format('DD/MM/YYYY, h:mm:ss a')}
            </>
        );
    }

    const toTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{strings.to}</span>
                {moment.utc(rowData.to).local().format('DD/MM/YYYY, h:mm:ss a')}
            </>
        );
    }

    const showAllOptionsTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Link to account</span>
                {rowData.showAllOptions ? 'Yes' : 'No'}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-file-pdf" style={{width:'100%'}} onClick={() => {
                    new JsFileDownloader({
                        url: rowData.pdfFile,
                        headers: [{
                            name: 'Authorization',
                            value: `Bearer ${localStorage.getItem('token')}`
                        }]
                    })
                        .then(function () {
                            toast.current.show({ severity: 'success', summary: 'Downloaded Report', life: 3000 });
                        })
                        .catch(function (error) {
                            toast.current.show({ severity: 'error', summary: 'Cannot Download Report', life: 3000 });
                        });
                }} tooltip={strings.pdf}> </Button>
                {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteReport(rowData)} /> */}
            </div>
        );
    }

    const onPage = ({ first,
        rows,
        page }) => {
        const pnlReportService = new InvoiceReportingService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        pnlReportService.GetPnlReports({ limit: rows, offset: (page) * rows }).then(({ data, count }) => {
            setLoading(false);
            setPnlReports(data);
            setTotalRecords(count);
        });

    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">{strings.pnlReports}</h5>
            <span className="p-input-icon-left">
                {/* <i className="pi pi-search" /> */}
                {/* <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." /> */}
            </span>
        </div>
    );

    const pnlReportDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" loading={saving} icon="pi pi-check" className="p-button-text" onClick={savePnlReport} />
        </>
    );
    const deletePnlReportDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePnlReportDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deletePnlReport} />
        </>
    );
    const deletePnlReportsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePnlReportsDialog} />
            {/* <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedPnlReports} /> */}
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}></Toolbar>

                    <DataTable
                        {...defaultTableConfiguration}
                        value={pnlReports}
                        selection={selectedPnlReports}
                        totalRecords={totalRecords}
                        first={first}
                        loading={loading}
                        onPage={onPage}
                        rows={rows}
                        header={header}>
                        <Column field="vendor" header={strings.Vendor} body={(rowData)=>rowData.vendor?.name}></Column>
                        <Column field="from" header={strings.from} body={fromTemplate}></Column>
                        <Column field="to" header={strings.to} body={toTemplate}></Column>
                        <Column field="netSales" header={strings.sales} body={(rowData) => rowData.netSales + " " + rowData.currency}></Column>
                        <Column field="netPurchases" header={strings.purchases} body={(rowData) => rowData.netPurchases + " " + rowData.currency}></Column>
                        <Column field="netExpenses" header={strings.expenses} body={(rowData) => rowData.expenses + " " + rowData.currency}></Column>
                        <Column field="netIncome" header={strings.netIncome} body={(rowData) => rowData.netTotal + " " + rowData.currency}></Column>
                        <Column field="isRealized" header={strings.isRealized} body={(rowData) => rowData.isRealized ? strings.Yes : strings.No}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={pnlReportDialog} style={{ width: '650px' }} header="Payment Type Details" modal className="p-fluid" footer={pnlReportDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            {context.showCompany ? <label htmlFor="name">{strings.Vendor}*</label> : ''}
                            {context.showCompany ? <Dropdown id="vendor" value={pnlReport?.vendor} onChange={(e) => {
                                let pnlReport1 = { ...pnlReport }
                                pnlReport1['vendor'] = e.value;
                                setPnlReport(pnlReport1);
                            }} options={vendors?.map((v) => {
                                return {
                                    ...v,
                                    code: v.uuid,
                                    name: v.name
                                }
                            })} optionLabel="name" placeholder={strings.Select}></Dropdown> : <p>{}</p>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">{strings.from}</label>
                            <Calendar id="from" showTime={false} showSeconds={false} showIcon showButtonBar value={formatDate(pnlReport.from)} onChange={changeValue}/>
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">{strings.to}</label>
                            <Calendar id="to" showTime={false} showSeconds={false} showIcon showButtonBar value={formatDate(pnlReport.to)} onChange={changeValue}/>
                        </div>
                        <div className="">
                            <Checkbox inputId="cb1" id="isRealized" checked={pnlReport.isRealized} value={pnlReport.isRealized} onChange={onCheckBoxChange} required autoFocus />
                            <label htmlFor="cb1" className="p-checkbox-label" style={{marginLeft:'2px'}}>{strings.isRealized}</label>
                            <br/>
                            <br/>
                            <Message content={<b>{pnlReport.isRealized ? strings.realizedMessage : strings.nonRealizedMessage}</b>}> </Message>
                        </div>
                    </Dialog>

                    <Dialog visible={deletePnlReportDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePnlReportDialogFooter} onHide={hideDeletePnlReportDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {pnlReport && <span>Are you sure you want to delete <b>{pnlReport.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deletePnlReportsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePnlReportsDialogFooter} onHide={hideDeletePnlReportsDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {pnlReport && <span>Are you sure you want to delete the selected payment type?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
