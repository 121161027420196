import ApiService from './ApiService';
import AuthService from './AuthService';
let api = process.env.REACT_APP_API_URL

export default class UserService extends ApiService {

    constructor() {
        super();
        this.path = '/user';
    }

    async CreateUser(data) {
        try {
            let op = await this.Create(this.path, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async UpdateUser(data) {
        try {
            let op = await this.Update(this.path + "/" + data.uuid, data);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }

    async ActivateUserByEmail(data) {
        let op = await this.Create(`${this.path}/admin/activate`, data);
        return op.data;
    }
    async SendEmailToAllUsers(data) {
        let op = await this.Create(`${this.path}/email/all`, data);
        return op.data;
    }

    async ActivateUser(data) {
        let op = await this.Create(`${this.path}/activate`, data);
        return op.data;
    }

    async DeactivateUser(data) {
        let op = await this.Create(`${this.path}/deactivate`, data);
        return op.data;
    }

    async DeleteUser(id) {
        try {
            let op = await this.Delete(`${this.path}/${id}`);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }

    async ResetPassword(data) {
        let op = await this.Create(`${this.path}/reset-password`, data);
        return op.data;
    }

    async GetUsers(limit=10,offset=0, filters='') {
        try {
            let link = `${this.path}/list?limit=${limit}&offset=${offset}&${filters}`
            let op = await this.Read(link);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }
    async GetCount() {
        try {
            let link = `${this.path}/list?countOnly=true`
            let op = await this.Read(link);
            return op.data.count;
        } catch(e) {
            return e.response.data;
        }
    }
    GetRole() {
        try {
            let user = JSON.parse(localStorage.getItem('user'));
            let subscriptionId = localStorage.getItem('subscription');
            let subscription = user.subscriptions.filter(s=> s.uuid == subscriptionId)[0];
            return subscription?.accessRole;
        }
        catch{
            new AuthService().Logout();
        }
    }

    IsSuperAdmin() {
        return this.GetRole() == 'super_admin';
    }

    async SendResetPasswordRequest(email) {
        try {
            let {data} = await this.Create(`${this.path}/reset-password/request`, {
                email: email
            });
            return data;
        } catch(e) {
            return e.response.data;
        }
    }

}
