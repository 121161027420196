import ApiService from './ApiService';

export default class SupportTicketService extends ApiService {

    constructor() {
        super();
        this.path = '/supportTicket';
    }

    async CreateSupportTicket(data) {
        try {
            let op = await this.Create(this.path, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async UpdateSupportTicket(data) {
        try {
            let op = await this.Update(this.path+"/"+data.uuid, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }


    async GetSupportTickets(limit=100,offset=0) {
        try {
            let link = `${this.path}/list?limit=${limit}&offset=${offset}`
            let op = await this.Read(link);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async GetCount() {
        try {
            let link = `${this.path}/list?countOnly=true`
            let op = await this.Read(link);
            return op.data.count;
        } catch(e) {
            return e.response.data;
        }
    }
}
