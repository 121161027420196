import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import strings from '../localization';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import UserService from '../service/UserService';
import Can, { check } from '../Can';
import rules from '../rbac-rules';
import { SplitButton } from 'primereact/splitbutton';
import { formatDateStringLocal } from './InvoiceDates';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import { FileUpload } from 'primereact/fileupload';
import BulkImportService from '../service/BulkImportService';
import { AutoComplete } from 'primereact/autocomplete';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { TableFilters } from './TableFilters';
import { defaultTableConfiguration } from './TableConfiguration';
import ReferralService from '../service/ReferralService';

export const Referrals = ({context}) => {

    const [referrals, setReferrals] = useState(null);
    const [referal, setReferal] = useState(null);
    const [preFilters, setPreFilters] = useState({});
    const [filters, setFilters] = useState({});

    const [totalRecords, setTotalRecords] = useState(0);

    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [denyReferralDialog, setDenyReferralDialog] = useState(null);


    const toast = useRef(null);
    const dt = useRef(null);

    const onFiltersChange = (e) => {
        let filters = { ...preFilters };
        filters[e.target.id] = e.target.value;
        setPreFilters(filters);
    }

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            onPage({rows,page:0, filters})
        }
    }, [filters]);

    useEffect(() => {
        onPage({rows,page:0})
    }, [localStorage.getItem('subscription')]);


    function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }

    const referrerSubTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Referrer</span>
                {rowData.referrerSub?.name}
            </>
        );
    }
    const trackingIdTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Referral ID</span>
                <b>{rowData.trackingId}</b>
            </>
        );
    }

    const confirmDenyReferral = (val) => {
        setDenyReferralDialog(val);
    }
    const showClicksDialog= (showClicksDialog) => {
        // setDenyReferralDialog(true);
    }

    const actionBodyTemplate = (rowData) => {
        const items = []

        var userService = new UserService();
        var role = userService.GetRole();

        if (check(rules, role, 'referrals:update') && rowData.referralStatus == '1') {
            items.push({ label: "Deny Referral", icon: 'pi pi-times', command:(e)=> {
                setReferal(rowData);
                confirmDenyReferral(true)
            }});
        }

        if (check(rules, role, 'referrals:read')) {
            items.push({ label: "View Clicks", icon: 'pi pi-link', command:()=>  showClicksDialog(rowData)});
        }


        items.push({ label: strings.copyId, icon: 'pi pi-copy', command:(e)=>  navigator.clipboard.writeText(rowData.trackingId) });

        let first = items.shift();

        return first ? <SplitButton label={first.label} icon={first.icon} onClick={()=>first.command()} model={items}></SplitButton> : ''
    }

    const referredSubTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Referred</span>
                {rowData.referredSub?.name ?? 'N/A'}
            </>
        );
    }
    const referralDateTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Referral Date</span>
                {formatDateStringLocal(rowData.referralDate)}
            </>
        );
    }
    const conversionDateTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Conversion Date</span>
                {formatDateStringLocal(rowData.conversionDate) ?? 'N/A'}
            </>
        );
    }
    let statuses = [null, "Pending",
        "Completed",
        "Denied"
    ]

    const referralStatusTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${rowData.referralStatus=='3'?'failed':rowData.referralStatus}`}>{statuses[rowData.referralStatus]}</span>
            </>
        )
    }

    const creationDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Creation Date</span>
                {formatDateStringLocal(rowData.createdAt)}
            </>
        );
    }

    const onPage = ({ first,
        rows,
        page,
        pageCount,
        filters }) => {
        const referralService = new ReferralService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        referralService.GetReferrals(rows, (page) * rows,filters).then(({ data, count }) => {
            setLoading(false);
            setReferrals(data);
            setTotalRecords(count);
        });

        setReferal(null);

    }

    const header = (
        <>
            <div className="p-col">

                <div className="table-header">

                    <h5 className="p-m-0">{strings.ManageReferrals}</h5>
                </div>

            </div>

            <div className="p-col-12">
            <TableFilters>

                    <div className="p-fluid p-grid">

                        {/* {context.showCompany ? <div className="p-field p-col-4" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.Vendor}</label>
                            <AutoComplete dropdown placeholder={strings.filterSearchPlaceHolder} style={{ width: "100%" }} id="vendorId" value={preFilters.vendorId} suggestions={vendors} field="name" completeMethod={changeVendorSearchBar} onChange={(onFiltersChange)} />
                        </div> : ''} */}

                        <div className="p-field p-col-3" style={{ margin: 'auto' }}>

                        <label htmlFor="inputtext">{strings.Name}</label>
                            <InputText id="name" value={preFilters.name} onChange={onFiltersChange} />

                        </div>

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                        <label htmlFor="inputtext">{strings.VatNumber}</label>

                                <InputText id="vatNumber" value={preFilters.vatNumber} onChange={onFiltersChange} />

                        </div>
                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                        <label htmlFor="inputtext">{strings.Phone}</label>
                                <InputText id="phone" value={preFilters.phone} onChange={onFiltersChange} />
                        </div>



                        <div className="p-field p-col-1" style={{ margin: 'auto' }}>
                        <hr/>

                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Filter} icon="pi pi-search" className="p-button-success p-mr-1 p-mb-1" onClick={() => {
                                let f = { ...preFilters }
                                setReferal([])
                                // 2021-09-21T21:50:37.000Z
                                setFilters({
                                    registrationName: f.name,
                                    vatNumber: f.vatNumber?.trim(),
                                    phone: f.phone?.trim(),
                                    vendorId: f.vendorId?.code,
                                });
                            }} />
                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Clear} icon="pi pi-times" className="p-button-warning p-mr-1 p-mb-1" onClick={() => {
                                setPreFilters({});
                            }} />
                        </div>
                    </div>
                    </TableFilters>
            </div></>

    );

    const denyReferral = (referal) => {
        const referralService = new ReferralService();
        // setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        referralService.DenyReferral(referal.trackingId).then((data) => {
            if(data.errorCode) {
                toast.current.show({ severity: 'error', summary: 'Cannot Deny Referral', detail: data.message, life: 3000 });
                setLoading(false);
                return;
            }
            toast.current.show({ severity: 'success', summary: 'Deny Referral', detail: `Denied ${referal.tracking} successfully`, life: 3000 });
        }).finally(()=>{
            onPage({rows,page:0, filters})
            confirmDenyReferral(false);

        }).catch(()=>setLoading(false));

        setReferal(null);

    }

    const denyReferralDialogFooter = (
        <>
            <Button  label="No" icon="pi pi-times" className="p-button-text" onClick={()=>{
                confirmDenyReferral(false)
                setReferal(null)
            }} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={()=>{denyReferral(referal)}} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <DataTable
                        ref={dt}
                        value={referrals}
                        totalRecords={totalRecords}
                        first={first}
                        loading={loading}
                        onPage={onPage}
                        rows={rows}
                        header={header}
                        {...defaultTableConfiguration}>
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                        <Column field="trackingId" header={"Tracking Id"}  body={trackingIdTemplate}></Column>
                        <Column field="referrerSub" header={"Referrer"}  body={referrerSubTemplate}></Column>
                        <Column field="referredSub" header={"Referred"}  body={referredSubTemplate}></Column>
                        <Column field="referralDate" header={"Referral Date"}  body={referralDateTemplate}></Column>
                        <Column field="conversionDate" header={"Conversion Date"}   body={conversionDateTemplate}></Column>
                        <Column field="referralStatus" header={"Status"}   body={referralStatusTemplate}></Column>
                        <Column field="createdAt" header={strings.CreationDate}  body={creationDateBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>

                    </DataTable>

                    <Dialog visible={denyReferralDialog} style={{ width: '450px' }} header={strings.DeleteReferal} modal footer={denyReferralDialogFooter} onHide={()=>confirmDenyReferral(false)}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {/* this feature is coming soon! */}
                            {<span>Are you sure you want to deny referral <b>{referal?.trackingId}</b>?</span>}
                        </div>
                    </Dialog>


                    {/* <Dialog visible={productDialog} style={{ width: '700px' }} header="Products Details" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <ProductForm context={context} OnHideDialog={hideDialog} product={product} FillData={() => onPage({ rows, page: 0, vendorID: vendor?.uuid })} />
                    </Dialog>
 */}


                </div>
            </div>
        </div>
    );
}
