import Issuer from '../../service/IssuerService'
import {addIssuer,addMsg, addIssuers, deleteIssuer,updateIssuer,getIssuer}  from '../issuer'

export const getIssuersHandler = (payload) => async (dispatch) =>{
    try {
        let response = await Issuer.getIssuers(payload)
        dispatch(addIssuers(response))
    } catch (error) {
        dispatch(addMsg(error))
    }
}


export const addIssuerHandler = (payload) => async (dispatch) =>{
    console.log("🚀 ~ file: IssuerAction.js ~ line 17 ~ addIssuerHandler ~ payload", payload)
    try {
        let response = await Issuer.createIssuer(payload)
        dispatch(addIssuer(response))
    } catch (error) {
        dispatch(addMsg(error))
    }
}

export const  deleteIssuerHandler = (payload) => async (dispatch, state) =>{
    try {
        let response = await Issuer.deleteIssuer(payload)
        let newState = state().issuer.issuers.data.filter(issuer => issuer.uuid !== payload)
        dispatch(deleteIssuer(newState))
    } catch (error) {
        dispatch(addMsg(error))
    }
}

export const updateIssuerHandler = (payload) => async (dispatch,state) => {
    try {
        let response = await Issuer.updateIssuer(payload.uuid,payload)
        let newState = state().issuer.issuers.data.map(issuer => {
            if (issuer.uuid === response.uuid) {
                return response
            }
            return issuer
        })
        dispatch(updateIssuer(newState))
    } catch (error) {
        dispatch(addMsg(error))
    }
}

export const getIssuerHandler = (payload) => async (dispatch) => {
    try {
        let response = await Issuer.getIssuer(payload)
        dispatch(getIssuer(response))
    } catch (error) {
        dispatch(addMsg(error))
    }
}