import EditPayment from './EditPayment'
import InvoicePayment from './InvoicePayment'
import PaymentForm from './PaymentForm'
import Payments from './payments'

const path = '/profile/payments'

const routes = [
    {path: `${path}`, component: Payments, exact: true, perform: 'payment:read'},
    {path: `${path}/new`, component: PaymentForm, exact: true, perform: 'payment:create'},
    {path: `${path}/invoice/:id`, component: InvoicePayment, exact: true, perform: 'payment:create'},
    {path: `${path}/payment/:id`, component: EditPayment, exact: true, perform: 'payment:update'}
]

export default routes