import React, { useState, useEffect, useContext } from 'react';
import { classNames } from 'primereact/utils';
import SubscriptionService from '../service/SubscriptionService';
import UserService from '../service/UserService';
import { RTLContext } from '../Profile';
import Can from '../Can';
import moment from 'moment';
import strings from '../localization';
import { TabPanel, TabView } from 'primereact/tabview';
import InvoiceReportingService from '../service/InvoiceReportingService';
import { Invoices } from './Invoices';
import { Button } from 'primereact/button';
import JsFileDownloader from 'js-file-downloader';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getColors, PieChart } from '../components/PieChart';
import ApiService from '../service/ApiService';
import SessionService from '../service/SessionService';
// import { getColors, PieChart } from '../PieChart';

export const SuperAdminReport = (props) => {
    const [loading, setLoading] = useState(false);
    const [subscriptionCount, setSubscriptionCount] = useState(0);
    const [metrics, setMetrics] = useState(false);

    useEffect(() => {
        let apiService = new ApiService();
        setLoading(true);
        apiService.Read('/metrics/homepage').then(({data}) => {
            setMetrics(data);
            setLoading(false);
        });
    }, [localStorage.getItem('subscription')]);

    useEffect(() => {
        const subscriptionService = new SubscriptionService();
        let sub = localStorage.getItem('subscription');
        if (sub == null || sub.trim()==''||sub=="null")
        {
            subscriptionService.GetCount().then(data => setSubscriptionCount(data));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isRTL = useContext(RTLContext)

    if(loading)
    {
        return <div className="p-grid dashboard"><ProgressSpinner/></div>;
    }

    return (
        <div className="p-grid dashboard">
            <div className="p-col-12 p-md-12 p-lg-12">
                <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                    <div className="p-d-flex p-ai-center muted-text">

                        <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >Subscriptions</h6>
                        <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>


                        </div>
                    </div>
                    <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                        <div className="p-d-flex p-flex-column" style={{ width: '80px' }}>
                            <span className="p-mb-1 fs-xlarge">{subscriptionCount}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-col-12 p-md-12 p-lg-12">
                <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                    <div className="p-d-flex p-ai-center muted-text">

                        <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.totalGeneratedInvoices}</h6>
                        <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                        </div>
                    </div>
                    <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                        <div className="p-d-flex p-flex-column">
                            <span className="p-mb-1 fs-xlarge">{metrics?.totalGeneratedInvoices}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-col-12 p-md-12 p-lg-12">
                <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                    <div className="p-d-flex p-ai-center muted-text">

                        <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.uniqueUsersCount}</h6>
                        <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                        </div>
                    </div>
                    <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                        <div className="p-d-flex p-flex-column">
                            <span className="p-mb-1 fs-xlarge">{metrics?.uniqueUsersCount}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-col-12 p-md-12 p-lg-12">
                <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                    <div className="p-d-flex p-ai-center muted-text">

                        <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.totalCustomersCount}</h6>
                        <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                        </div>
                    </div>
                    <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                        <div className="p-d-flex p-flex-column">
                            <span className="p-mb-1 fs-xlarge">{metrics?.totalCustomersCount}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-col-12 p-md-12 p-lg-12">
                <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                    <div className="p-d-flex p-ai-center muted-text">

                        <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.lastLogin}</h6>
                        <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                        </div>
                    </div>
                    <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                        <div className="p-d-flex p-flex-column">
                            <span className="p-mb-1 fs-xlarge">{moment(new SessionService().GetTokenIssueDate()).format('yyyy-MM-DD HH:mm:ss')}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}
