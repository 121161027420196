import ApiService from './ApiService';

export default class CustomerService extends ApiService {

    constructor() {
        super();
        this.path = '/product';
    }

    async Createproduct(data) {
        try {
            let op = await this.Create(this.path, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }


    async Updateproduct(data) {

        try {
            let op = await this.Update(this.path+"/"+data.uuid, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async DeleteProduct(id) {
        try {
            let op = await this.Delete(this.path+"/"+id);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }


    async Getproduct(limit=10,offset=0,vendorID,filters="") {
        try {

            let link = `${this.path}/list?limit=${limit}&offset=${offset}&vendorId=${!vendorID?'':vendorID}&${filters}`
            let op = await this.Read(link);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }
    async GetBulkProducts(limit = 10, offset = 0, vendorID, filters = "") {
        try {

            let data = {
                limit,
                offset,
                vendorId: vendorID,
                ...filters
            }

            let link = `${this.path}/list`
            let op = await this.Create(link, data);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }

    async GetCount() {
        try {
            let link = `${this.path}/list?countOnly=true`
            let op = await this.Read(link);
            return op.data.count;
        } catch(e) {
            return e.response.data;
        }
    }
}
