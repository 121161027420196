import React, { useState, useEffect, useContext } from 'react';
import { classNames } from 'primereact/utils';
import { RTLContext } from '../Profile';
import Can from '../Can';
import moment from 'moment';
import strings from '../localization';
import { TabPanel, TabView } from 'primereact/tabview';
import InvoiceReportingService from '../service/InvoiceReportingService';
import { Button } from 'primereact/button';
import JsFileDownloader from 'js-file-downloader';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getColors, PieChart } from '../components/PieChart';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

// import { getColors, PieChart } from '../PieChart';
let api = process.env.REACT_APP_API_URL

export const InvoiceReport = ({options, context}) => {
    const [loading, setLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [report, setReport] = useState(null);
    const [from, setFrom] = useState(null);
    const [company, setCompany] = useState(null);
    const [branch, setBranch] = useState(null);
    const [to, setTo] = useState(null);

    useEffect(() => {
        let invoiceReportingService = new InvoiceReportingService();
        setLoading(true);
        let f = from;
        let t = to;
        if(t == null)
        {
            t = new Date();
            f = new Date(t.getFullYear(), t.getMonth(), 1);
            // t.setDate(t.getDate() - 1);
            // t.setHours(23);
            // t.setMinutes(59);
            // t.setSeconds(59);
            // t.setMilliseconds(0);

            setTo(t)
            setFrom(f);
        }

        invoiceReportingService.GenerateOrGetReport({
            from: f,
            to: t
        }).then(data => setReport(data)).then(()=>setLoading(false))
    }, [localStorage.getItem('subscription')]);

    const isRTL = useContext(RTLContext)

    if(loading)
    {
        return <div className="p-grid dashboard"><ProgressSpinner/></div>;
    }

    let today = new Date();

    let oneYearAgo = new Date(today);

    oneYearAgo.setDate(oneYearAgo.getDate() - 365)

    return (
        <div className="p-grid dashboard">
            <Can perform='invoices:metrics:read' yes={() =>
                <div className="p-col-12 p-md-12 p-lg-12">
                    {context?.showCompany ? <> <label>{strings.Vendor}: </label>
                        <Dropdown id="vendor" value={company} onChange={(e) => {
                            setCompany(e.value)
                        }} options={context?.companies.map((v) => {
                            return {
                                code: v.uuid,
                                name: v.name,
                                branches: v.branches.map(b=>{
                                    return {
                                        name: b.name,
                                        uuid: b.uuid
                                    }
                                })
                            }
                        })} optionLabel="name" placeholder={strings.Select}></Dropdown>

                        {company?.branches?.length > 0 ? <><label>{strings.Branch}: </label>
                            <Dropdown id="branch" value={branch} onChange={(e) => {
                                setBranch(e.value)
                            }} options={company.branches.map((v) => {
                                return {
                                    code: v.uuid,
                                    name: v.name
                                }
                            })} optionLabel="name" placeholder={strings.Select}></Dropdown></> : ""}
                        <br />
                        <hr />
                    </> : ''}
                    {!options?.editPeriod && <div>
                        <hr/>
                        <h2>{strings.vatReport} </h2></div> }
                    <label>{strings.from}: </label>
                    {options?.editPeriod ? <Calendar showTime={true} disabled={!options?.editPeriod} minDate={oneYearAgo} value={from} onChange={(e) => setFrom(e.value)} style={{paddingLeft:'10px',paddingRight:'10px'}}/>
                     : <b style={{paddingLeft:'10px',paddingRight:'10px'}}>{moment(from).format('DD/MM/yyyy')}</b>
                    }
                    <label>{strings.to}: </label>

                    {options?.editPeriod ? <Calendar showTime={true} maxDate={today} value={to} onChange={(e) => {
                        var t = e.value;
                        // t.setHours(23);
                        // t.setMinutes(59);
                        // t.setSeconds(59);
                        // t.setMilliseconds(0);
                        setTo(t)
                    }} style={{paddingLeft:'10px'}}/>
                     : <b style={{paddingLeft:'10px',paddingRight:'10px'}}>{moment(to).format('DD/MM/yyyy')}</b>}

                        {options?.editPeriod ? <Button  disabled={!options?.editPeriod} label={strings.submit} onClick={() => {
                        let invoiceReportingService = new InvoiceReportingService();
                        setLoading(true);
                        invoiceReportingService.GenerateOrGetReport({
                            from: from,
                            to: to,
                            vendorId: company?.code,
                            branchId: branch?.code
                        }).then(data => setReport(data)).then(() => setLoading(false))
                    }} /> : <></>}


                </div>
            } no={() => ''} />

            <Can perform='invoices:metrics:read' yes={() =>
                <div className="p-col-12 p-md-12 p-lg-12">
                    <h4 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} > {report?.pdfFile && <Button label={strings.downloadPdf} className="p-mb-3" type="button" icon="pi pi-file-pdf" tooltipOptions={{
                        position: 'top'
                    }} tooltip={strings.downloadPDF} onClick={() => {
                        new JsFileDownloader({
                            url: `${api}/download/${report.pdfFile}`,
                            headers: [{
                                name: 'Authorization',
                                value: `Bearer ${localStorage.getItem('token')}`
                            }]
                        })
                    }} />}</h4>
                </div>
            } no={() => ''} />

            <Can perform='invoices:metrics:read' yes={() =>
                <div className="p-col-12 p-md-12 p-lg-12">
                    <div className="p-grid dashboard">
                        <div className="p-col-12 p-md-4 p-lg-4">
                            <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                <div className="p-d-flex p-ai-center muted-text">
                                    <i className="pi pi-shopping-cart"></i>
                                    <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.Invoicesbalance}</h6>
                                    <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                    </div>
                                </div>
                                <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                    <div className="p-d-flex p-flex-column">
                                        <span className="p-mb-1 fs-xlarge">{report?.numberOfInvoices}</span>
                                    </div>
                                </div>
                            </div>
                            <PieChart title={strings.Invoicesbalance} getPieData={() => {
                                const { tealColor, orangeColor, blueColor, deeppurpleColor } = getColors();
                                const borderColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
                                return {
                                    labels: [strings.simplified, strings.evat, strings.DebitNote, strings.CreditNote],
                                    datasets: [
                                        {
                                            data: [report?.simpleInvoiceCount, report?.taxInvoiceCount, report?.debitNoteCount, report?.creditNoteCount],
                                            backgroundColor: [
                                                tealColor,
                                                orangeColor,
                                                blueColor,
                                                deeppurpleColor
                                            ],
                                            borderColor
                                        }]
                                };
                            }} />
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-4">
                            <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                <div className="p-d-flex p-ai-center muted-text">
                                    <i className="pi pi-shopping-cart"></i>
                                    <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.invoiceTotalAmount}</h6>
                                    <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                    </div>
                                </div>
                                <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                    <div className="p-d-flex p-flex-column">
                                        <span className="p-mb-1 fs-xlarge">{report?.totalAmount}</span>
                                    </div>
                                </div>

                            </div>
                            <PieChart title={strings.invoiceTotalAmount} getPieData={() => {
                                const { tealColor, orangeColor, blueColor, deeppurpleColor } = getColors();
                                const borderColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
                                return {
                                    labels: [strings.simplified, strings.evat, strings.DebitNote, strings.CreditNote],
                                    datasets: [
                                        {
                                            data: [report?.simpleTotalAmount, report?.taxTotalAmount, report?.debitTotalAmount, report?.creditTotalAmount],
                                            backgroundColor: [
                                                tealColor,
                                                orangeColor,
                                                blueColor,
                                                deeppurpleColor
                                            ],
                                            borderColor
                                        }]
                                };
                            }} />
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-4">
                            <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                <div className="p-d-flex p-ai-center muted-text">
                                    <i className="pi pi-shopping-cart"></i>
                                    <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.totalVat}</h6>
                                    <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                    </div>
                                </div>
                                <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                    <div className="p-d-flex p-flex-column">
                                        <span className="p-mb-1 fs-xlarge">{report?.totalVat}</span>
                                    </div>
                                </div>
                            </div>
                            <PieChart title={strings.totalVat} getPieData={() => {
                                const { tealColor, orangeColor, blueColor, deeppurpleColor } = getColors();
                                const borderColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
                                return {
                                    labels: [strings.simplified, strings.evat, strings.DebitNote, strings.CreditNote],
                                    datasets: [
                                        {
                                            data: [report?.simpleVatAmount, report?.taxVatAmount, report?.debitVatAmount, report?.creditVatAmount],
                                            backgroundColor: [
                                                tealColor,
                                                orangeColor,
                                                blueColor,
                                                deeppurpleColor
                                            ],
                                            borderColor
                                        }]
                                };
                            }} />
                        </div>
                    </div>
                </div>
            } no={() => ''} />

            <Can perform='invoices:metrics:read' yes={() =>
                <div className="p-col-12 p-md-12 p-lg-12">
                    <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.details}</h6>
                </div>
            } no={() => ''} />

            <Can perform='invoices:metrics:read' yes={() =>
                <div className="p-col-12 p-md-12 p-lg-12">
                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header={strings.taxInvoices}>
                            <div className="p-grid dashboard">
                                <div className="p-col-12 p-md-4 p-lg-4">
                                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                        <div className="p-d-flex p-ai-center muted-text">
                                            <i className="pi pi-shopping-cart"></i>
                                            <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.invoiceCount}</h6>
                                            <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                            </div>
                                        </div>
                                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                            <div className="p-d-flex p-flex-column">
                                                <span className="p-mb-1 fs-xlarge">{report?.taxInvoiceCount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-md-4 p-lg-4">
                                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                        <div className="p-d-flex p-ai-center muted-text">
                                            <i className="pi pi-shopping-cart"></i>
                                            <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.invoiceTotalAmount}</h6>
                                            <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                            </div>
                                        </div>
                                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                            <div className="p-d-flex p-flex-column">
                                                <span className="p-mb-1 fs-xlarge">{report?.taxTotalAmount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-4">
                                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                        <div className="p-d-flex p-ai-center muted-text">
                                            <i className="pi pi-shopping-cart"></i>
                                            <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.totalVat}</h6>
                                            <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                            </div>
                                        </div>
                                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                            <div className="p-d-flex p-flex-column">
                                                <span className="p-mb-1 fs-xlarge">{report?.taxVatAmount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                        </TabPanel>
                        <TabPanel header={strings.simplifiedInvoices}>
                            <div className="p-grid dashboard">

                                <div className="p-col-12 p-md-4 p-lg-4">
                                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                        <div className="p-d-flex p-ai-center muted-text">
                                            <i className="pi pi-shopping-cart"></i>
                                            <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.invoiceCount}</h6>
                                            <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                            </div>
                                        </div>
                                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                            <div className="p-d-flex p-flex-column">
                                                <span className="p-mb-1 fs-xlarge">{report?.simpleInvoiceCount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-4">
                                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                        <div className="p-d-flex p-ai-center muted-text">
                                            <i className="pi pi-shopping-cart"></i>
                                            <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.invoiceTotalAmount}</h6>
                                            <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                            </div>
                                        </div>
                                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                            <div className="p-d-flex p-flex-column">
                                                <span className="p-mb-1 fs-xlarge">{report?.simpleTotalAmount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-4">
                                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                        <div className="p-d-flex p-ai-center muted-text">
                                            <i className="pi pi-shopping-cart"></i>
                                            <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.totalVat}</h6>
                                            <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                            </div>
                                        </div>
                                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                            <div className="p-d-flex p-flex-column">
                                                <span className="p-mb-1 fs-xlarge">{report?.simpleVatAmount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header={strings.creditNotes}>
                            <div className="p-grid dashboard">

                                <div className="p-col-12 p-md-4 p-lg-4">
                                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                        <div className="p-d-flex p-ai-center muted-text">
                                            <i className="pi pi-shopping-cart"></i>
                                            <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.invoiceCount}</h6>
                                            <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                            </div>
                                        </div>
                                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                            <div className="p-d-flex p-flex-column">
                                                <span className="p-mb-1 fs-xlarge">{report?.creditNoteCount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-md-4 p-lg-4">
                                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                        <div className="p-d-flex p-ai-center muted-text">
                                            <i className="pi pi-shopping-cart"></i>
                                            <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.invoiceTotalAmount}</h6>
                                            <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                            </div>
                                        </div>
                                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                            <div className="p-d-flex p-flex-column">
                                                <span className="p-mb-1 fs-xlarge">{report?.creditTotalAmount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-4">
                                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                        <div className="p-d-flex p-ai-center muted-text">
                                            <i className="pi pi-shopping-cart"></i>
                                            <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.totalVat}</h6>
                                            <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                            </div>
                                        </div>
                                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                            <div className="p-d-flex p-flex-column">
                                                <span className="p-mb-1 fs-xlarge">{report?.creditVatAmount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header={strings.debitNotes}>
                            <div className="p-grid dashboard">

                                <div className="p-col-12 p-md-4 p-lg-4">
                                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                        <div className="p-d-flex p-ai-center muted-text">
                                            <i className="pi pi-shopping-cart"></i>
                                            <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.invoiceCount}</h6>
                                            <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                            </div>
                                        </div>
                                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                            <div className="p-d-flex p-flex-column">
                                                <span className="p-mb-1 fs-xlarge">{report?.debitNoteCount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-col-12 p-md-4 p-lg-4">
                                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                        <div className="p-d-flex p-ai-center muted-text">
                                            <i className="pi pi-shopping-cart"></i>
                                            <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.invoiceTotalAmount}</h6>
                                            <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                            </div>
                                        </div>
                                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                            <div className="p-d-flex p-flex-column">
                                                <span className="p-mb-1 fs-xlarge">{report?.debitTotalAmount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-4">
                                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                        <div className="p-d-flex p-ai-center muted-text">
                                            <i className="pi pi-shopping-cart"></i>
                                            <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.totalVat}</h6>
                                            <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                            </div>
                                        </div>
                                        <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                            <div className="p-d-flex p-flex-column">
                                                <span className="p-mb-1 fs-xlarge">{report?.debitVatAmount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>} no={() => ''} />



        </div >
    )
}
