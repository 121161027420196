import ApiService from './ApiService';

class CompanyAccountService extends ApiService {
    constructor() {
        super();
        this.path = '/companyAccount'
    }

    async addCompanyAccount(data) {
        try {
            const response = await this.Create(this.path,data)
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async updateCompanyAccount(id,data){
        try {
            const response = await this.Update(`${this.path}/${id}`,data)
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async deleteCompanyAccount(id){
        try {
            const response = await this.Delete(`${this.path}/${id}`)
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async getCompanyAccount(id){
        try {
            let response = await this.Read(`${this.path}/${id}`)
            return response.data
        } catch (error) {
            return error.response
        }
    }
    async getCompanyAccounts(data){
        try {
            let response = await this.Read(`${this.path}/list`, data)
            return response.data
        } catch (error) {
            return error.response
        }
    }
}

export default CompanyAccountService
