import React, { Component } from 'react';
import { Button } from "primereact/button";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import {getCurrency} from '../service/helpers'


import VendorService from '../service/VendorService'
import CustomerService from '../service/CustomerService'
import ProductService from '../service/ProductService'
import InvoiceService from '../service/InvoiceService'
import { TabView, TabPanel } from 'primereact/tabview';
import { ProgressSpinner } from 'primereact/progressspinner';
import QRCode from 'qrcode.react';
import { Message } from 'primereact/message';
import { confirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog method
import { Dialog } from 'primereact/dialog';
import InvoiceDates, { formatDate, formatDateString } from './InvoiceDates';
import JsFileDownloader from 'js-file-downloader';
import { Toast } from 'primereact/toast';
import { CustomerForm } from './CustomerForm';
import { VendorForm } from './VendorForm';
import ProductForm from './ProductForm';
import strings from '../localization';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import Can, { check } from '../Can';
import UserService from '../service/UserService';
import rules from '../rbac-rules';
import CurrencyRateService from '../service/CurrencyRateService';
import { CurrencyRates } from './CurrencyRates';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Toolbar } from 'primereact/toolbar';
import SubscriptionService from '../service/SubscriptionService';
import { RegisterListerners } from './RegisterListerners';
import { Badge } from 'primereact/badge';
import { SplitButton } from 'primereact/splitbutton';
import CertificateService from '../service/CertificateService';
import { Editor } from 'primereact/editor';
let newFeaturesEnabled = process.env.REACT_APP_NEW_FEATURES_ENABLED

let api = process.env.REACT_APP_API_URL


const currencyCodes = [
    { code: getCurrency(), name: getCurrency() },
    { code: 'USD', name: 'USD' },
    { code: 'EUR', name: 'EUR' },
    { code: 'AED', name: 'AED' },
    { code: 'KWD', name: 'KWD' },
]

export class InvoiceForm extends Component {
    constructor(props) {
        super(props);


        const connection = new HubConnectionBuilder()
            .withUrl(`${api}/hubs/upload`, { accessTokenFactory: () => localStorage.getItem('deviceId') })
            .withAutomaticReconnect()
            .build();

        connection.start().then(() => {
            connection?.on(`PdfGenerationCompleted`, message => {
                let invoice = { ...this.state.invoice };
                invoice.pdfFile = message;
                this.setState({ invoice });
                this.toast.current.show({ severity: `${strings.success}`, summary: `${strings.UploadSuccessfull}`, detail: `${strings.PdfUploadSuccessfullDetails}`, life: 3000 });
            });
            connection?.on(`XmlGenerationCompleted`, message => {
                let invoice = { ...this.state.invoice };
                invoice.xmlFile = message;
                this.setState({ invoice });
                this.toast.current.show({ severity: `${strings.success}`, summary: `${strings.UploadSuccessfull}`, detail: `${strings.XmlUploadSuccessfullDetails}`, life: 3000 });
            });
            connection?.on(`PdfGenerationError`, message => {
                let invoice = { ...this.state.invoice };
                invoice.pdfFile = "NA";
                this.setState({ invoice });
                this.toast.current.show({ severity: `${strings.success}`, summary: `${strings.UploadSuccessfull}`, detail: `${strings.PdfUploadFailedDetails}`, life: 3000 });
            });
            connection?.on(`XmlGenerationError`, message => {
                let invoice = { ...this.state.invoice };
                invoice.xmlFile = "NA";
                this.setState({ invoice });
                this.toast.current.show({ severity: `${strings.success}`, summary: `${strings.UploadSuccessfull}`, detail: `${strings.XmlUploadFailedDetails}`, life: 3000 });
            })

        }).catch(e => console.error(e, "Error whle connecting to SignalR"));

        this.state = {
            invoice: {},
            connection: null,
            debitNoteDialog: false,
            creditNoteDialog: false,
            listening: false,
            addedProducts: [],
            customersLoading: false,
            productsLoading: false,
            emailDialog: false,
            originalRows: [],
            listening: false,
            dscType: {symbol: '%'},
            rates: [
                {
                    code: getCurrency(),
                    name: getCurrency(),
                    targetCurrency: getCurrency(),
                    baseCurrency: getCurrency(),
                    rate: 1.0,
                }
            ],
            toEmail: '',
            sendToType: "3",
            vendor: {},
            loading: true,
            activeIndex: 0,
            errors: [],
        }
        this.toast = React.createRef();

        this.invoiceTypes = [
            { code: 'tax', name: strings.evat },
            { code: 'simple', name: strings.simplified },
        ]

        this.propertyNames = {
            arabicName: [
                { code: 'Prepared By', name: 'أعدت بواسطة' },
                { code: 'Approved By', name: 'تمت الموافقة عليه من قبل' },
                { code: 'Sales Person', name: 'مندوب المبيعات' },
                { code: 'Requested By', name: 'بطلب من' },
                { code: 'Email Address', name: 'البريد الالكترونى' },
                { code: 'Project Name', name: 'اسم المشروع' },
                { code: 'Other (please type)', name: 'أخرى (يرجى الكتابة)' },
            ],
            englishName: [
                { name: 'Prepared By', code: 'Prepared By' },
                { name: 'Approved By', code: 'Approved By' },
                { name: 'Sales Person', code: 'Sales Person' },
                { name: 'Requested By', code: 'Requested By' },
                { name: 'Email Address', code: 'Email Address' },
                { name: 'Project Name', code: 'Project Name' },
                { name: 'Other (please type)', code: 'Other (please type)' },
            ]
        }
        this.transactionTypes = [
            { code: 'nominal', name: strings.nominal },
            { code: 'exports', name: strings.exports },
            { code: 'summary', name: strings.summary },
        ]

        this.agreementTypes = [
            { code: 'selfBilled', name: strings.selfBilled },
            { code: 'thirdParty', name: strings.thirdParty },
        ]

        this.paymentMeansCodes = [
            { code: '1', name: strings.instrumentNotDefined },
            { code: '10', name: strings.inCash },
            { code: '30', name: strings.creditTransfer },
            { code: '42', name: strings.bankTransfer },
            { code: '48', name: strings.creditCard },
        ]

        this.reasons = [
            { code: '1', name: strings.reason1 },
            { code: '2', name: strings.reason2 },
            { code: '3', name: strings.reason3 },
            { code: '4', name: strings.reason4 }
        ]
        this.reload();

    }

    componentDidMount() {
    }

    renderInvoiceNoteHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
        );
    };

    keyBodyTemplate = (rowData, props) => {
        let { invoice } = this.state;

        if (invoice.confirmed) {
            return <span>{rowData.key}</span>
        }
        return (
            <>
                {/* <span className="p-column-title">Value</span> */}
                {/* {rowData.value} */}
                <InputText id='key' disabled={rowData.key} type="text" value={props.rowData['key']} onChange={(e) => this.onEditorValueChange('key', props, e.target.value)} required autoFocus />
            </>
        );
    }

    regenerateFiles = () => {
        this.setState({ errors: [] });
        let { id } = this.props.match.params;
        if (!id.startsWith("new")) {
            var service = new InvoiceService();
            service.RegenerateInvoiceFiles(id).then(res => {
                alert("Request submitted, invoices should regenerated in 1 minute maximum.")
            }).catch(e=>{
                alert("Error: Couldn't regenerate files")
            })
        }
    }

    reload = () => {
        var vendorService = new VendorService();
        this.setState({ errors: [] });

        let company = new SubscriptionService().GetActiveCompany();
        let companyPopulted = company != null;
        let branch = new SubscriptionService().GetActiveBranch();


        new CurrencyRateService().GetCurrencyRates().then(({ data }) => {
            if (data.filter(d => d.targetCurrency == getCurrency()).length == 0) {
                data.push({
                    code: getCurrency(),
                    name: getCurrency(),
                    targetCurrency: getCurrency(),
                    baseCurrency: getCurrency(),
                    rate: 1.0,
                });
            }

            data = data.reverse();

            this.setState({
                rates: data.map(d => {
                    return {
                        code: d.targetCurrency,
                        name: d.targetCurrency,
                        ...d
                    }
                })
            });
        });

        vendorService.GetVendors(500, 0, { simple: true }).then(data => {
            if (!data.data) return [];
            return data.data.map(v => {
                return { code: v.uuid, name: v.name, ...v }
            });
        }).then(data => {
            this.setState({ vendors: data }, () => {
                let { id } = this.props.match.params;
                let { invoice } = this.state;
                if (id.startsWith("new")) {
                    this.setState({
                        invoice: {
                            properties: [],
                            invoiceNumber: 'STASH',
                            invoiceTypeCode: id == 'new' ? '388' : (id == 'newCredit' ? '381' : '383'),
                            externalNote: id != 'new',
                            confirmed: false,
                            includePrepaid: true,
                            includeRetention: true,
                            includeAdvancedAmount: true,
                            includeRecovery: true,
                            includeRetentionInTax: true,
                            retentionTax: 0,
                            includeRecovery: true,
                            includeRecoveryInTax: true,
                            recoveryTax: 0,
                            currencyCode: currencyCodes[0],
                            currencyRate: {
                                code: getCurrency(),
                                name: getCurrency(),
                                targetCurrency: getCurrency(),
                                baseCurrency: getCurrency(),
                                rate: 1.0,
                            },
                            agreementType: this.agreementTypes[0],
                            transactionType: this.transactionTypes[0],
                            type: this.invoiceTypes[0],
                            invoiceIssueDate: new Date(),
                            actualDeliveryDate: null,
                            latestDeliveryDate: null,
                            dueDate: null,
                            vendor: companyPopulted ? company : this.state.vendors[0],
                            branchSerial: {
                                code: branch?.serial,
                                name: `${branch?.name} (${branch?.serial})`
                            },
                            branchId: branch?.uuid
                        },
                    }, () => {
                        this.reloadCustomers();
                        this.reloadProducts();
                        this.setState({ loading: false })
                    });
                } else {
                    var service = new InvoiceService();
                    service.GetInvoice(id).then(res => {

                        let vendor = this.state.vendors.filter(t => t.code == res.invoice.vendor.uuid)[0];
                        let branchSerial = vendor?.branches?.filter(t => t.serial == res.invoice?.branchSerial)[0];
                        let inv = {
                            ...invoice,
                            ...res.invoice,
                            "type": this.invoiceTypes.filter(t => t.code == res.invoice.invoiceType.split("-")[0])[0],
                            "transactionType": this.transactionTypes.filter(t => t.code == res.invoice.invoiceTransactionType)[0],
                            "agreementType": this.agreementTypes.filter(t => t.code == res.invoice.agreementType)[0],
                            "paymentMeansCode": this.paymentMeansCodes.filter(t => t.code == res.invoice.paymentMeansCode)[0],
                            "instructionNote": this.reasons.filter(t => t.code == res.invoice.instructionNote)[0],
                            "currencyCode": currencyCodes.filter(t => t.code == res.invoice.currencyCode)[0],
                            "vendor": vendor,
                            "properties": res.invoice.properties?.map((property, i) => {
                                return {
                                    ...property,
                                    englishName: property.translations?.filter(t => t.cultureName == 'En')[0]?.name,
                                    arabicName: property.translations?.filter(t => t.cultureName == 'Ar')[0]?.name,
                                }
                            }),
                            "products": res.invoice.invoiceProducts?.map((product, i) => {
                                return {
                                    ...product,
                                    "qty": product.quantity,
                                    "productUuid": product.product.uuid,
                                    "productNumber": product.product.productNumber,
                                    "index": i + 1,
                                    "taxes": product.product.taxCategories?.map(t => `${t.name}(${t.percent}%)`).join(", "),
                                    "subtotal": res.amounts.invoiceLineAmounts[product.serial].taxExclusiveAmount.text,
                                    "taxAmount": res.amounts.invoiceLineAmounts[product.serial].taxAmount.text,
                                    "total": res.amounts.invoiceLineAmounts[product.serial].taxInclusiveAmount.text
                                }
                            }),
                            "invoiceIssueDate": res.invoice.issueDate,
                        }

                        inv.amounts = res.amounts;

                        if (branchSerial) {
                            inv.branchSerial = {
                                code: branchSerial.serial,
                                name: `${branchSerial.name} (${branchSerial.serial})`
                            }
                        }

                        let addedProducts = inv.products.map(p => p.product);


                        this.setState({ invoice: inv, addedProducts, amounts: res.amounts }, () => {

                            this.reloadCustomers();
                            this.reloadProducts();
                            new CertificateService().GetCertificateStatus({uuid: res.invoice.vendor.uuid}).then(data=>{
                                this.setState({certificateStatus: data});
                            });

                            this.setState({ loading: false })
                        })
                    })
                }
            });
        });
    }

    reload = () => {
        var vendorService = new VendorService();
        this.setState({ errors: [] });

        let company = new SubscriptionService().GetActiveCompany();
        let companyPopulted = company != null;
        let branch = new SubscriptionService().GetActiveBranch();


        new CurrencyRateService().GetCurrencyRates().then(({ data }) => {
            if (data.filter(d => d.targetCurrency == getCurrency()).length == 0) {
                data.push({
                    code: getCurrency(),
                    name: getCurrency(),
                    targetCurrency: getCurrency(),
                    baseCurrency: getCurrency(),
                    rate: 1.0,
                });
            }

            data = data.reverse();

            this.setState({
                rates: data.map(d => {
                    return {
                        code: d.targetCurrency,
                        name: d.targetCurrency,
                        ...d
                    }
                })
            });
        });

        vendorService.GetVendors(500, 0, { simple: true }).then(data => {
            if (!data.data) return [];
            return data.data.map(v => {
                return { code: v.uuid, name: v.name, ...v }
            });
        }).then(data => {
            this.setState({ vendors: data }, () => {
                let { id } = this.props.match.params;
                let { invoice } = this.state;
                if (id.startsWith("new")) {
                    this.setState({
                        invoice: {
                            properties: [],
                            invoiceNumber: 'STASH',
                            invoiceTypeCode: id == 'new' ? '388' : (id == 'newCredit' ? '381' : '383'),
                            externalNote: id != 'new',
                            confirmed: false,
                            includePrepaid: true,
                            includeRetention: true,
                            includeAdvancedAmount: true,
                            includeRecovery: true,
                            includeRetentionInTax: true,
                            retentionTax: 0,
                            includeRecovery: true,
                            includeRecoveryInTax: true,
                            recoveryTax: 0,
                            currencyCode: currencyCodes[0],
                            currencyRate: {
                                code: getCurrency(),
                                name: getCurrency(),
                                targetCurrency: getCurrency(),
                                baseCurrency: getCurrency(),
                                rate: 1.0,
                            },
                            agreementType: this.agreementTypes[0],
                            transactionType: this.transactionTypes[0],
                            type: this.invoiceTypes[0],
                            invoiceIssueDate: new Date(),
                            actualDeliveryDate: null,
                            latestDeliveryDate: null,
                            dueDate: null,
                            vendor: companyPopulted ? company : this.state.vendors[0],
                            branchSerial: {
                                code: branch?.serial,
                                name: `${branch?.name} (${branch?.serial})`
                            },
                            branchId: branch?.uuid
                        },
                    }, () => {
                        this.reloadCustomers();
                        this.reloadProducts();
                        this.setState({ loading: false })
                    });
                } else {
                    var service = new InvoiceService();
                    service.GetInvoice(id).then(res => {

                        let vendor = this.state.vendors.filter(t => t.code == res.invoice.vendor.uuid)[0];
                        let branchSerial = vendor?.branches?.filter(t => t.serial == res.invoice?.branchSerial)[0];
                        let inv = {
                            ...invoice,
                            ...res.invoice,
                            "type": this.invoiceTypes.filter(t => t.code == res.invoice.invoiceType.split("-")[0])[0],
                            "transactionType": this.transactionTypes.filter(t => t.code == res.invoice.invoiceTransactionType)[0],
                            "agreementType": this.agreementTypes.filter(t => t.code == res.invoice.agreementType)[0],
                            "paymentMeansCode": this.paymentMeansCodes.filter(t => t.code == res.invoice.paymentMeansCode)[0],
                            "instructionNote": this.reasons.filter(t => t.code == res.invoice.instructionNote)[0],
                            "currencyCode": currencyCodes.filter(t => t.code == res.invoice.currencyCode)[0],
                            "vendor": vendor,
                            "properties": res.invoice.properties?.map((property, i) => {
                                return {
                                    ...property,
                                    englishName: property.translations?.filter(t => t.cultureName == 'En')[0]?.name,
                                    arabicName: property.translations?.filter(t => t.cultureName == 'Ar')[0]?.name,
                                }
                            }),
                            "products": res.invoice.invoiceProducts?.map((product, i) => {
                                return {
                                    ...product,
                                    "qty": product.quantity,
                                    "productUuid": product.product.uuid,
                                    "productNumber": product.product.productNumber,
                                    "index": i + 1,
                                    "taxes": product.product.taxCategories?.map(t => `${t.name}(${t.percent}%)`).join(", "),
                                    "subtotal": res.amounts.invoiceLineAmounts[product.serial].taxExclusiveAmount.text,
                                    "taxAmount": res.amounts.invoiceLineAmounts[product.serial].taxAmount.text,
                                    "total": res.amounts.invoiceLineAmounts[product.serial].taxInclusiveAmount.text
                                }
                            }),
                            "invoiceIssueDate": res.invoice.issueDate,
                        }

                        inv.amounts = res.amounts;

                        if (branchSerial) {
                            inv.branchSerial = {
                                code: branchSerial.serial,
                                name: `${branchSerial.name} (${branchSerial.serial})`
                            }
                        }

                        let addedProducts = inv.products.map(p => p.product);


                        this.setState({ invoice: inv, addedProducts, amounts: res.amounts }, () => {

                            this.reloadCustomers();
                            this.reloadProducts();
                            new CertificateService().GetCertificateStatus({uuid: res.invoice.vendor.uuid}).then(data=>{
                                this.setState({certificateStatus: data});
                            });

                            this.setState({ loading: false })
                        })
                    })
                }
            });
        });
    }

    reloadCustomers(filters = { simple: true }, skipDefault = false, newCustomer = false) {
        if (!skipDefault) this.setState({ customersLoading: true });
        let invoice = { ...this.state.invoice };
        var customerService = new CustomerService();
        customerService.GetCustomers(100, 0, invoice.vendor?.code, filters).then(data => {
            if (!data.data) {
                return { code: null, name: '-' }
            }
            return data.data.map(v => {
                return { code: v.uuid, name: v.registrationName, ...v }
            });
        }).then(data => {
            this.setState({ customers: data });
        })
            .then(() => {
                if (skipDefault) return;
                let invoice = { ...this.state.invoice };
                if (newCustomer) {
                    invoice.customer = this.state.customers[0];
                }
                else {
                    invoice.customer = {
                        ...invoice.customer,
                        name: invoice.customer.registrationName,
                        code: invoice.customer.uuid
                    };
                }
                this.setState({ invoice })
            }).catch(() => {
                this.setState({ customers: [] });
            }).finally(() => {
                let invoice = { ...this.state.invoice };
                this.setState({ invoice })
                if (!skipDefault) this.setState({ customersLoading: false });
            })
    }
    reloadProducts(filters = {}, productsLoading = true) {
        this.setState({ productsLoading: productsLoading });
        let invoice = { ...this.state.invoice };
        var productService = new ProductService();

        filters = { ...filters, simple: true, excluded: invoice.products?.filter(p => p.productUuid != null).map(p => { return p.productUuid }) }
        productService.GetBulkProducts(100, 0, invoice.vendor?.code,filters).then(data => {
            this.setState({ products: data.data });
        }).finally(() => {
            this.setState({ productsLoading: false });
        })
    }

    componentDidUpdate(prevProps, prevState) {
        let { invoice, vendors } = this.state;
        let { type, agreementType, transactionType, vendor } = invoice;
        let { type: oType, agreementType: oAgreementType, transactionType: oTransactionType, vendor: oVendor } = prevState.invoice;

        let { id } = this.props.match.params;
        let { oid } = prevProps.match.params;

        if (type != oType || agreementType != oAgreementType || transactionType != oTransactionType) {
            this.calculateInvoiceTransactionCode();
        }
    }

    confirm = () => {
        confirmDialog({
            message: `${strings.Areyousureyouwanttoproceed}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => await this.confirmInvoice(),
        });
    }
    confirmClearInvoice = () => {
        confirmDialog({
            message: `${strings.Areyousureyouwanttoproceed}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                this.setState({ complianceStatusOpen: true, complianceStatusLoading: true }, () => {
                    new InvoiceService().ClearInvoice(this.state.invoice?.uuid).then(data => {
                        this.setState({ complianceStatus: data, complianceStatusLoading: false });
                    }).catch(e => {
                        this.setState({ complianceStatusError: e.response.data.message ?? strings.internalServerError })
                    })
                })
            },
        });
    }

    confirmReportInvoice = () => {
        confirmDialog({
            message: `${strings.Areyousureyouwanttoproceed}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                this.setState({ complianceStatusOpen: true, complianceStatusLoading: true }, () => {
                    new InvoiceService().ReportInvoice(this.state.invoice?.uuid).then(data => {
                        this.setState({ complianceStatus: data, complianceStatusLoading: false });
                    }).catch(e => {
                        this.setState({ complianceStatusError: e.response.data.message ?? strings.internalServerError })
                    })
                })
            },
        });
    }

    confirmRegenerate = () => {
        confirmDialog({
            message: `${strings.Areyousureyouwanttoproceed}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => await this.regenerateFiles(),
        });
    }


    onRowEditInit = (event) => {
        let originalRows = [...this.state.originalRows];
        originalRows[event.index] = { ...this.state.invoice.products[event.index] };
        this.setState({ originalRows });
    }

    onSave = async (confirm, debit = false, credit = false) => {
        let { invoice, debitNoteDialog } = this.state;
        this.setState({ errors: [], loading: true });
        let request = {
            ...invoice,
            "type": (invoice.invoiceType) ?? invoice.type?.code + `${invoice.invoiceTypeCode == '381' ? '-credit-note' : (invoice.invoiceTypeCode == '383' ? '-debit-note' : '')}`,
            "confirmed": confirm,
            "invoiceIssueDate": new Date(),
            "vendorId": invoice.vendor?.code,
            "customerId": invoice.customer?.code,
            "agreementType": invoice.agreementType?.code,
            "retention": invoice.retention ?? 0,
            "advancedAmount": invoice.advancedAmount ?? 0,
            "advancedAmountPercentage": invoice.advancedAmountPercentage ?? 0,
            "retentionPercentage": invoice.retentionPercentage ?? 0,
            "recovery": invoice.recovery ?? 0,
            "recoveryPercentage": invoice.recoveryPercentage ?? 0,

            "paymentMeansCode": invoice.paymentMeansCode?.code,
            "currencyRate": this.state.rates.filter(r => r.code == invoice.currencyCode?.code)[0],
            "transactionType": invoice.transactionType?.code,
            "branchSerial": invoice.branchSerial?.code,
            "prepaidAmount": invoice.prepaidAmount ?? 0,
            "instructionNote": invoice.instructionNote?.code,
            "thirdPartyInvoiceTransaction": invoice.agreementType?.code == "thirdParty",
            "selfBilledInvoiceTransaction": invoice.agreementType?.code == "selfBilled",
            "nominalInvoiceTransaction": invoice.transactionType?.code == "nominal",
            "exportsInvoiceTransaction": invoice.transactionType?.code == "exports",
            "summaryInvoiceTransaction": invoice.transactionType?.code == "summary",
            "currencyCode": invoice.currencyCode?.code,
            "products": invoice.products?.map((line => {
                return {
                    ...line,
                    "productUuid": line.product?.uuid ?? line.product,
                    "label": null,
                    "prioritizePercentage":true
                }
            })),
            "properties": invoice.properties?.map((property => {
                return {
                    ...property,
                    "translations": [
                        {
                            name: (property.englishName?.name) ?? property.englishName,
                            locale: 1
                        },
                        {
                            name: (property.arabicName?.name) ?? property.arabicName,
                            locale: 2
                        },
                    ],
                }
            })),
            "isActive": true
        }


        if (debit) {
            request.billingReferenceId = invoice.invoiceNumber;
            request.invoiceIssueDate = new Date();
            request.confirmed = false;
            request.invoiceTypeCode = 383;
            request.type = `${request.type}-debit-note`;
            request.uuid = null;
            this.setState({ debitNoteDialog: false });
        }

        if (credit) {
            request.billingReferenceId = invoice.invoiceNumber;
            request.confirmed = false;
            request.invoiceIssueDate = new Date();
            request.invoiceTypeCode = 381;
            request.type = `${request.type ?? request.invoiceType}-credit-note`;
            request.uuid = null;
            this.setState({ creditNoteDialog: false });
        }

        let invoiceService = new InvoiceService();
        let invoiceResponse = await invoiceService.CreateInvoice(request);
        if (invoiceResponse.errors) {
            let errors = [];
            Object.keys(invoiceResponse.errors).forEach(key => {
                errors.push(invoiceResponse.errors[key]);
            })
            this.setState({ errors });
            this.setState({ loading: false })

        } else if (invoiceResponse.errorCode) {
            let errors = [];
            errors.push(strings[invoiceResponse.errorCode]);
            this.setState({ errors });
            this.setState({ loading: false })
        }
        else {
            this.props.history.push(`/profile/invoices/${invoiceResponse.invoice?.uuid}`);
            setTimeout(() => {
                this.reload();
                this.setState({ loading: false })
            }, 500)
        }
    }

    GetInvoicePaymentType = () => {
        let { invoice } = this.state;
        if (invoice.type == null) return '';
        return invoice.type.code == 'tax' ? 'B2B' : 'B2C'
    }

    onRowEditCancel = (event) => {
        let i = { ...this.state.invoice }
        i.products[event.index] = this.state.originalRows[event.index];
        let originalRows = [...this.state.originalRows];
        delete originalRows[event.index];
        this.setState({ invoice: i, originalRows });
    }

    calculateInvoiceTransactionCode = () => {
        var code = [];

        let { invoice } = this.state;

        if (invoice.type?.code == "simple") {
            code.push("02");
        }
        else if (invoice.type?.code == "tax") {
            code.push("01");
        }

        if (invoice.agreementType?.code == 'thirdParty') {
            code.push("1");
        } else {
            code.push("0");
        }
        if (invoice.transactionType?.code == 'nominal') {
            code.push("1");
        } else {
            code.push("0");
        }

        if (invoice.transactionType?.code == 'exports') {
            code.push("1");
        } else {
            code.push("0");
        }

        if (invoice.transactionType?.code == 'summary') {
            code.push("1");
        } else {
            code.push("0");
        }

        if (invoice.transactionType?.code == 'selfBilled') {
            code.push("1");
        } else {
            code.push("0");
        }

        let i = { ...invoice };
        i['invoiceTransactionCode'] = code.join("");
        this.setState({ invoice: i });
    }

    confirmInvoice = async () => {
        await this.onSave(true);
        this.reload();
    }

    changeValue = (e, callback = () => { }) => {
        let id = e.target.id;
        let i = { ...this.state.invoice };
        let bools = ['includeRetention','includePrepaid','includeRecovery','includeRetentionInTax','includeRecoveryInTax', 'includeAdvancedAmount'];
        if (bools.includes(id)) {
            i[id] = !e.target.value;
        }
        else i[id] = e.target.value;
        this.setState({ invoice: i }, callback);
    }

    onEditorValueChange = (props, e) => {

        let i = { ...this.state.invoice }
        let ip = i.products[props.rowIndex];


        if(props.field !== 'dscType') {
            i.products[props.rowIndex][props.field] = e.target.value;

        }

        if(props.field === 'discountAmount') {
            var price =  i.products[props.rowIndex]['price']*i.products[props.rowIndex]['qty'];

            i.products[props.rowIndex]['discountPercentage'] = price == 0 ? 0 : e.target.value/price * 100.0;
        }

        if(props.field === 'discountPercentage') {
            var price =  i.products[props.rowIndex]['price']*i.products[props.rowIndex]['qty'];
            i.products[props.rowIndex]['discountAmount'] = price == 0 ? 0 : (e.target.value/100.0)*price;
        }


        let symbol = e.target.value.symbol ?? this.state.dscType.symbol

        let product = this.state.addedProducts.filter((p) => p.uuid == ip.productUuid)[0];

        let taxAmount = 0.0;
        let itemNetPriceAmount = symbol === '%' ? props.rowData.price - (props.rowData.price * props.rowData.discountPercentage / 100) : props.rowData.price - props.rowData.discountPercentage ;



        let lineExtensionAmount = itemNetPriceAmount * props.rowData.qty;
        let rate = i.currencyRate?.rate ?? i.currencyRate;

        product.taxCategories?.map(t => taxAmount += lineExtensionAmount * (t.percent / 100) * rate);
        i.products[props.rowIndex]['taxAmount'] = taxAmount;
        i.products[props.rowIndex]['subtotal'] = lineExtensionAmount.toFixed(2);
        i.products[props.rowIndex]['total'] = (lineExtensionAmount + taxAmount);

        this.setState({ invoice: i });
    }

    onPropertyEditorValueChange = (props, e) => {
        let i = { ...this.state.invoice }


        if (props.field == 'showOnPdf') {
            i.properties[props.rowIndex][props.field] = !e.target.value;
        }
        else {


            if (props.field == 'arabicName') {
                let newVal = this.propertyNames.englishName.filter(en => en.code == e.target.value.code)[0];
                if (newVal != null) {
                    i.properties[props.rowIndex]['englishName'] = newVal;
                }
            }
            else if (props.field == 'englishName') {
                let newVal = this.propertyNames.arabicName.filter(ar => ar.code == e.target.value.code)[0];
                if (newVal != null) {
                    i.properties[props.rowIndex]['arabicName'] = newVal;
                }
            }

            i.properties[props.rowIndex][props.field] = e.target.value;
        }

        this.setState({ invoice: i });
    }


    changeProductsSearchBar = (e, nameOnly) => {
        let { invoice } = this.state;

        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        this.setState({
            productLazyLoading: false,
            productName: e.query,
            typingTimeout: setTimeout(() => {
                let i = { ...invoice }
                this.reloadProducts({ name: nameOnly ? e.query : null, productNumber: nameOnly ? null : e.query, excluded: i.products.filter(p => p.productUuid != null).map(p => { return p.productUuid }) }, false);
                this.setState({ productLazyLoading: false })
            }, 300)
        });

    }

    changeCustomerSearchBar = (e) => {
        if (this.state.typingTimeout2) {
            clearTimeout(this.state.typingTimeout2);
        }

        this.setState({
            customerName: e.query,
            typingTimeout2: setTimeout(() => {
                this.reloadCustomers({ registrationName: e.query, simple: true }, true)
            }, 200)
        });

    }

    productDropdown = (rowData, props) => {
        let { invoice, products } = this.state;
        if (invoice.confirmed) {
            let product = invoice.products?.filter(i => i.productUuid == rowData.productUuid)[0];
            if (props.field == 'product') {
                return <span>{rowData?.label??product?.product.name}</span>
            }

            return <span>{product?.product.productNumber}</span>
            // return <span>{JSON.stringify(invoice.products)}</span>
        }

        return <div style={{ display: 'flex', maxWidth: '100%' }}>
            {/* {JSON.stringify(props.rowData[props.field])} */}

            <AutoComplete input forceSelection={true} dropdown={props.field == 'product'} inputs placeholder={props.field == 'product' ? strings.productFilterSearchPlaceHolder : strings.searchByProductNumber} style={{ width: props.field === 'product' ? "100%": '100%' }} id="product" onSelect={(e) => {


                    let i = { ...invoice }
                let { addedProducts } = this.state;

                let product = products.filter(i => i.uuid == e.value.uuid)[0];

                if (product == null) return;

                addedProducts.push(product);

                i.products[props.rowIndex] = {};

                i.products[props.rowIndex]['productUuid'] = product.uuid;
                i.products[props.rowIndex]['qty'] = 1;
                i.products[props.rowIndex]['price'] = product.price;
                i.products[props.rowIndex]['peppol'] = product.peppol;
                i.products[props.rowIndex]['product'] = product;
                i.products[props.rowIndex]['discountPercentage'] = product.discountPercentage;
                i.products[props.rowIndex]['unitCode'] = product.unitCode;
                i.products[props.rowIndex]['productNumber'] = product.productNumber;
                i.products[props.rowIndex]['index'] = props.rowIndex + 1;
                i.products[props.rowIndex]['taxes'] = product.taxCategories?.map(t => `${t.name}(${t.percent}%)`).join(", ");
                i.products[props.rowIndex]['subtotal'] = product.price;
                let taxAmount = 0.0;

                product.taxCategories?.map(t => taxAmount += (product.price - product.price * (product.discountPercentage / 100)) * (t.percent / 100));
                i.products[props.rowIndex]['taxAmount'] = taxAmount;
                // const reducer = (accumulator, curr, percent) => accumulator + curr * (percent/100);
                i.products[props.rowIndex]['total'] = product.price * 1 + taxAmount;
                this.setState({ invoice: i, addedProducts }, () => {
                    // this.onEditorValueChange(props, e.originalEvent);
                });

            }} value={props.rowData[props.field]} suggestions={products?.map(o => {
                return {
                    ...o,
                    name: props.field == 'product' ? o.name : o.productNumber,
                    value: o.uuid
                }
            })} field="name" completeMethod={(e) => this.changeProductsSearchBar(e, props.field == 'product')} onChange={(e) => {
                let i = { ...invoice }
                i.products[props.rowIndex][props.field] = e.target.value;
                this.setState({ invoice: i })
            }} />

            {props.field == 'product' && <Can yes={() => <Button style={{ width: '25px', height: '25px' }} icon="pi pi-plus" tooltipOptions={{
                position: 'top'
            }} tooltip="Create Product" onClick={() => { this.setState({ addProduct: true }) }}></Button>}
                perform={'products:create'}
                silent={true}
                role={new UserService().GetRole()} />}
        </div>


    }

    inputTextEditor = (rowData, props, type = "text") => {
        let { invoice } = this.state;


        if(props.field == 'discountAmount')
        {
            var discountAmount = props.rowData['allowanceAmount'];
            if(discountAmount == 0 && invoice.products[props.rowIndex]['discountPercentage']>0)
            {
                discountAmount = (invoice.products[props.rowIndex]['discountPercentage']/100)*invoice.products[props.rowIndex]['price']*invoice.products[props.rowIndex]['qty'];
            }
            invoice.products[props.rowIndex][props.field] = discountAmount;
            props.rowData[props.field] = discountAmount;
        }

        if (invoice.confirmed || !check(rules, new UserService().GetRole(), "products:update")) {
            return <span>{props.rowData[props.field]}</span>
        }


        // return <div style={{ maxWidth: '100%' }}><InputText style={{ width: props.field=== 'discountPercentage' ? '50%': '100%' }} type={type} value={invoice.products[props.rowIndex][props.field]} onChange={(e) => this.onEditorValueChange(props, e)} width="100%" />{props.field === 'discountPercentage' && <Dropdown style={{ width: '50%' }} value={this.state.dscType} options={ [{symbol:'%'}, {symbol:'#'}]} optionLabel='symbol' onChange={e=> {this.setState({dscType: e.target.value}); this.onEditorValueChange({...props,field:'dscType'}, {target: {value: e.target.value}})}}/>} </div>;
        return <div style={{ maxWidth: '5rem' }}><InputText type={type} value={invoice.products[props.rowIndex][props.field]} onChange={(e) => this.onEditorValueChange(props, e)} style={{width:"5rem"}} /></div>;

    }

    propertyInputTextEditor = (rowData, props, type = "text") => {
        let { invoice } = this.state;

        if (invoice.confirmed || !check(rules, new UserService().GetRole(), "products:update")) {
            return <span>{props.rowData[props.field]}</span>
        }

        if (props.field == 'showOnPdf') {
            return <div style={{ maxWidth: '100%' }}><Checkbox style={{ width: '100%' }} value={invoice.properties[props.rowIndex][props.field]} checked={invoice.properties[props.rowIndex][props.field]} onChange={(e) => this.onPropertyEditorValueChange(props, e)} width="100%" /></div>;
        }

        return <div style={{ maxWidth: '100%' }}><InputText style={{ width: '100%' }} type={type} value={invoice.properties[props.rowIndex][props.field]} onChange={(e) => this.onPropertyEditorValueChange(props, e)} width="100%" /></div>;
    }

    propertyInputTextEditor = (rowData, props, type = "text") => {
        let { invoice } = this.state;

        if (invoice.confirmed || !check(rules, new UserService().GetRole(), "products:update")) {
            return <span>{props.rowData[props.field]}</span>
        }

        if (props.field == 'showOnPdf') {
            return <div style={{ maxWidth: '100%' }}><Checkbox style={{ width: '100%' }} value={invoice.properties[props.rowIndex][props.field]} checked={invoice.properties[props.rowIndex][props.field]} onChange={(e) => this.onPropertyEditorValueChange(props, e)} width="100%" /></div>;
        }

        return <div style={{ maxWidth: '100%' }}><InputText style={{ width: '100%' }} type={type} value={invoice.properties[props.rowIndex][props.field]} onChange={(e) => this.onPropertyEditorValueChange(props, e)} width="100%" /></div>;
    }

    propertyNameEditor = (rowData, props, type = "text") => {
        let { invoice } = this.state;

        if (invoice.confirmed || !check(rules, new UserService().GetRole(), "products:update")) {
            return <span>{props.rowData[props.field]}</span>
        }

        return <div style={{ maxWidth: '100%' }}><Dropdown value={props.rowData[props.field]} options={this.propertyNames[props.field]} onChange={(e) => this.onPropertyEditorValueChange(props, e)} optionLabel="name" editable /></div>;
    }


    deleteRow = (rowData, props) => {
        let invoice = { ...this.state.invoice };
        invoice.products = invoice.products.filter((val, i) => i != props.rowIndex);
        this.setState({ invoice });
        // this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    }
    deletePropertyRow = (rowData, props) => {
        let invoice = { ...this.state.invoice };
        invoice.properties = invoice.properties.filter((val, i) => i != props.rowIndex);
        this.setState({ invoice });
        // this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    }

    actionBodyTemplate = (rowData, props) => {
        if (!check(rules, new UserService().GetRole(), "products:update")) {
            return "";
        }

        return (
            <div className="actions">
                <Button icon="pi pi-trash" onClick={() => this.deleteRow(rowData, props)} className="p-button-rounded p-button-warning" />
            </div>
        );
    }

    propertyActionBodyTemplate = (rowData, props) => {
        if (!check(rules, new UserService().GetRole(), "products:update")) {
            return "";
        }

        return (
            <div className="actions">
                <Button icon="pi pi-trash" onClick={() => this.deletePropertyRow(rowData, props)} className="p-button-rounded p-button-warning" />
            </div>
        );
    }

    customerDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { this.setState({ addCustomer: false }) }} />
        </>
    );
    vendorDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { this.setState({ addVendor: false }) }} />
        </>
    );
    productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { this.setState({ addProduct: false }) }} />
        </>
    );

    confirmDeleteInvoice = () => {
        this.setState({ deleteInvoiceDialog: true });
    }

    GetConfirmLabel = () => {
        if (this.state.invoice.invoiceTypeCode == '388') {
            return strings.ConfirmInvoice;
        }
        if (this.state.invoice.invoiceTypeCode == '381') {
            return strings.ConfirmCreditNote;
        }
        if (this.state.invoice.invoiceTypeCode == '383') {
            return strings.ConfirmDebitNote;
        }
    }

    deleteInvoice = () => {
        const invoiceService = new InvoiceService();

        invoiceService.DeleteDraftedInvoice(this.state.invoice.uuid).then((data) => {
            if (data.errorCode) {
                this.toast.current.show({ severity: `${strings.error}`, summary: `${strings.CannotDeleteInvoice}`, detail: data.message, life: 3000 });
                this.setState({ deleteInvoiceDialog: false });
                return;
            }
            this.toast.current.show({ severity: `${strings.success}`, summary: `${strings.DeleteDraftInvoice}`, detail: `${strings.Deleteddraftsuccessfully}`, life: 3000 });
            this.props.history.push(`/profile/invoices/new`);
            this.reload();
        }).finally(() => {
            this.setState({ deleteInvoiceDialog: false });
        });

    };

    deleteInvoiceDialogFooter = (
        <>
            <Button label={strings.No} icon="pi pi-times" className="p-button-text" onClick={() => this.setState({ deleteInvoiceDialog: false })} />
            <Button label={strings.Yes} icon="pi pi-check" className="p-button-text" onClick={this.deleteInvoice} />
        </>
    );

    sendInvoiceToEmail = async () => {
        if (!this.state.toEmail || this.state.toEmail == '') {
            this.setState({ sendEmailError: strings.emailIsRequired });
            return;
        }

        this.setState({ sendingEmail: true })

        let service = new InvoiceService();

        var res = await service.SendInvoiceToEmail({
            email: this.state.toEmail,
            pdf: this.state.sendToType == '1' || this.state.sendToType == '3',
            xml: this.state.sendToType == '2' || this.state.sendToType == '3',
            invoiceId: this.state.invoice.uuid
        });
        this.setState({ sendingEmail: false })

        if (res.status == 400) {
            this.setState({ sendEmailError: strings.emailValidationError });
        }

        this.setState({ emailSent: true });
    }

    sendToEmailDialogFooter = () => (
        !this.state.emailSent ?
            <>
                <Button label={strings.Cancel} icon="pi pi-times" className="p-button-text" onClick={() => this.setState({ emailDialog: false, toEmail: this.state.invoice?.customer?.email, emailSent: false })} />
                <Button label={strings.Send} icon="pi pi-send" loading={this.state.sendingEmail} className="p-button-text" onClick={this.sendInvoiceToEmail} />
            </>
            : ''
    );


    AddRow = () => {
        let len = 0;
        if (this.state.invoice.products?.length) len = this.state.invoice.products?.length;

        let rows = [...this.state.invoice.products ?? [], {
            index: len + 1,
            qty: 0,
            label: "",
            productUuid: "",
            price: 0.0,
            unit: "",
            peppol: "",
            discountPercentage: 0.0,
            allowanceAmount: 0.0,
            allowanceBaseAmount: 0.0,
            allowancePercent: 0.0,
        }];

        let i = { ...this.state.invoice };
        i.products = rows;
        this.setState({ invoice: i });
    }

    AddCustomPropertyRow = () => {
        let len = 0;
        if (this.state.invoice.properties?.length) len = this.state.invoice.properties?.length;

        let rows = [...this.state.invoice.properties ?? [], {
            index: len + 1,
            key: "",
            arabicName: "",
            englishName: "",
            showOnPdf: true,
            value: "",
        }];

        let i = { ...this.state.invoice };
        i.properties = rows;
        this.setState({ invoice: i });
    }

    NotesSplitButton = () => {
        let { invoice} = this.state;

        let role = new UserService().GetRole();

        var items = [];

        if(!(invoice.confirmed && invoice.invoiceTypeCode == '388'))
        {
            return <></>
        }

        if(check(rules, role, 'invoices:credit:draft'))
        {
            items.push({ label: strings.CreditNote, icon: 'pi pi-plus', command: (e) => this.setState({ creditNoteDialog: true }) });
        }

        if(check(rules, role, 'invoices:debit:draft'))
        {
            items.push({ label: strings.DebitNote, icon: 'pi pi-plus', command: (e) => this.setState({ debitNoteDialog: true }) });
        }

        if(check(rules, role, 'invoices:credit:draft'))
        {
            items.push({ label: strings.recordPayment, icon: 'pi pi-credit-card', command: (e) => this.props.history.push(`/profile/payments/invoice/${invoice.uuid}`) });
        }

        if(items.length == 0)
        return <></>


        var first = items.shift();
        return <div><SplitButton label={first.label} icon={first.icon} onClick={first.command} model={items}></SplitButton></div>

    }

    render() {
        let { invoice, errors, activeIndex, amounts, vendors, customers, loading } = this.state;
        let { context } = this.props;

        let role = new UserService().GetRole();

        let { id } = this.props.match.params;

        let canEdit = (!invoice.confirmed && invoice.invoiceTypeCode == '388') || (id.startsWith("new"))

        // var showZatcaFeatures = new SubscriptionService().GetActiveCompany()
        let companyPopulted = new SubscriptionService().GetActiveCompany() != null;
        let branchPopulated = new SubscriptionService().GetActiveBranch() != null;

        if (loading) {
            return <div style={{ textAlign: 'center', margin: "auto" }}><ProgressSpinner /></div>
        }
        let token = localStorage.getItem("token");

        const leftToolbarTemplate = () => {
            return (
                <React.Fragment>

{/* invoice.uuid && invoice.confirmed && (invoice.complianceStatus == "PENDING_COMPLIANCE_CHECK") */}
                    {/* {invoice.uuid && invoice.confirmed ? <Can yes={() => <Button className="" type="button" label={strings.checkComplianceStatus} icon="pi pi-check" tooltipOptions={{ position: 'top' }} tooltip="Refresh" onClick={() => this.setState({ complianceStatusOpen: true, complianceStatusLoading: true }, () => {
                        new InvoiceService().GetInvoiceComplianceStatus(invoice?.uuid).then(data => {
                            this.setState({ complianceStatus: data, complianceStatusLoading: false });
                        }).catch(e => {
                            this.setState({ complianceStatusError: e.response.data.message ?? strings.internalServerError })
                        })
                    })} />}
                        perform={'invoices:sendEmail'}
                        silent={true}
                        role={role} /> : ''} */}



                    {invoice.uuid && invoice.confirmed && invoice.invoiceType.startsWith("tax") && invoice.invoiceType.startsWith("tax") && invoice.complianceStatus?.toUpperCase()!="cleared".toUpperCase() && this.state.certificateStatus?.initialized &&this.state.certificateStatus?.isActive ? <Can yes={() => <Button className="" type="button" label={strings.clearInvoice} icon="pi pi-check" tooltipOptions={{ position: 'top' }} tooltip="Refresh" onClick={() => this.confirmClearInvoice()} />}
                        perform={'invoices:sendEmail'}
                        silent={true}
                        role={role} /> : ''}
                    {invoice.uuid && invoice.confirmed && invoice.invoiceType.startsWith("simple") && invoice.complianceStatus?.toUpperCase()!="reported".toUpperCase() && this.state.certificateStatus?.initialized && this.state.certificateStatus?.isActive? <Can yes={() => <Button className="" type="button" label={strings.reportInvoice} icon="pi pi-check" tooltipOptions={{ position: 'top' }} tooltip="Refresh" onClick={()=>this.confirmReportInvoice()} />}
                        perform={'invoices:sendEmail'}
                        silent={true}
                        role={role} /> : ''}

                    {/* {invoice.uuid && invoice.confirmed && (invoice.complianceStatus == "PENDING_SIGNING" || invoice.complianceStatus == null || invoice.complianceStatus == 'COMPLIANCE_ERROR') ? <Can yes={() => <Button className="" type="button" label={strings.signInvoice} icon="pi pi-check" tooltipOptions={{ position: 'top' }} tooltip="Refresh" onClick={() => new InvoiceService().SubmitInvoiceSignTask(invoice?.uuid).then(() => {
                        this.reload();
                    }).catch(e => {
                        this.setState({ error: e.response.data.message ?? strings.internalServerError })
                    })} />}
                        perform={'invoices:sendEmail'}
                        silent={true}
                        role={role} /> : ''} */}

                    {invoice.uuid && invoice.confirmed && invoice.complianceStatus == "SUBMITTED_FOR_SIGNING" ? <Can yes={() => <Button className="" type="button" label={strings.signInvoiceInProgress} icon="pi pi-check" tooltipOptions={{ position: 'top' }} tooltip="Refresh" disabled={true} />}
                        perform={'invoices:sendEmail'}
                        silent={true}
                        role={role} /> : ''}


                    {invoice.uuid && !invoice.confirmed ? <Can yes={() => <Button type="button" label={this.GetConfirmLabel()} icon="pi pi-check-circle" onClick={this.confirm} className="p-mb-3"></Button>}
                        perform={'invoices:create'}
                        silent={true}
                        role={role} /> : ''}

                    {!invoice.confirmed ? <Can yes={() => <Button type="button" label={strings.saveDraft} icon="pi pi-save" onClick={() => this.onSave(false)} className="p-mb-3"></Button>}
                        perform={'invoices:draft:update'}
                        silent={true}
                        role={role} /> : ''}

                    <this.NotesSplitButton />

                    {/* {invoice.confirmed && invoice.invoiceTypeCode == '388' ? <Can yes={() => <Button type="button" label={strings.DebitNote} icon="pi pi-plus" onClick={() => this.setState({ debitNoteDialog: true })} className="p-mb-3"></Button>}
                        perform={'invoices:debit:draft'}
                        silent={true}
                        role={role} /> : ''}



                    {invoice.confirmed && invoice.invoiceTypeCode == '388' ? <Can yes={() => <Button type="button" label={strings.CreditNote} icon="pi pi-plus" onClick={() => this.setState({ creditNoteDialog: true })} className="p-mb-3"></Button>}
                        perform={'invoices:credit:draft'}
                        silent={true}
                        role={role} /> : ''} */}
                    {/* {invoice.confirmed && newFeaturesEnabled === "true" ? <Can yes={() => <Button type="button" label={strings.recordPayment} icon="pi pi-credit-card" onClick={() => this.props.history.push(`/profile/payments/invoice/${invoice.uuid}`)} className="p-mb-3"></Button>}
                        perform={'invoices:credit:draft'}
                        silent={true}
                        role={role} /> : ''} */}

                    {invoice.uuid && invoice.pdfFile ? <Button className="p-mb-3" type="button" label={strings.downloadPdf} icon="pi pi-file-pdf" tooltipOptions={{
                        position: 'top'
                    }} tooltip={strings.downloadPDF} onClick={() => {
                        new JsFileDownloader({
                            url: `${api}/download/${invoice.pdfFile}`,
                            headers: [{
                                name: 'Authorization',
                                value: `Bearer ${token}`
                            }]
                        })
                            .then(() => {
                                this.toast?.current.show({ severity: `${strings.success}`, summary: 'Downloaded PDF', life: 3000 });
                            })
                            .catch((error) => {
                                this.toast?.current.show({ severity: `${strings.error}`, summary: 'PDF is not ready yet, refresh and try again.', life: 3000 });
                            });
                    }} /> : (invoice.uuid && <Button className="p-mb-3" type="button" label="Generating PDF" loading disabled icon="pi pi-file-pdf" />)}


                    {invoice.uuid && invoice.xmlFile ? <Button className="p-mb-3" type="button" label={strings.downloadXml} icon="pi pi-file-excel" tooltipOptions={{
                        position: 'top'
                    }} tooltip="Download PDF" onClick={() => {
                        new JsFileDownloader({
                            url: `${api}/download/${invoice.xmlFile}`,
                            headers: [{
                                name: 'Authorization',
                                value: `Bearer ${token}`
                            }]
                        })
                            .then(() => {
                                this.toast?.current.show({ severity: `${strings.success}`, summary: 'Downloaded XML', life: 3000 });
                            })
                            .catch((error) => {
                                this.toast?.current.show({ severity: `${strings.error}`, summary: `${strings.XMLisnotreadyyetrefreshandtryagain}.`, life: 3000 });
                            });
                    }} /> : (invoice.uuid && <Button className="p-mb-3" type="button" label="Generating XML" loading disabled icon="pi pi-file-excel" />)}
                </React.Fragment>
            )
        }

        const rightToolbarTemplate = () => {
            return (
                <React.Fragment>
                    {invoice.uuid && invoice.confirmed ? <Can yes={() => <Button className="p-mb-3" type="button" label={strings.SendToEmail} icon="pi pi-send" tooltipOptions={{ position: 'top' }} tooltip="Refresh" onClick={() => this.setState({ emailDialog: true, toEmail: invoice?.customer?.email })} />}
                        perform={'invoices:sendEmail'}
                        silent={true}
                        role={role} /> : ''}
                    {/* {invoice.uuid && invoice.confirmed ? <Can yes={() => <Button className="p-mb-3" type="button" label={strings.checkComplianceStatus} icon="pi pi-check" tooltipOptions={{ position: 'top' }} tooltip="Refresh" onClick={() => this.setState({complianceStatusOpen: true, complianceStatusLoading: true}, ()=>{new InvoiceService().GetInvoiceComplianceStatus(invoice?.uuid).then(data=>{
                        this.setState({complianceStatus: data, complianceStatusLoading: false});
                    }).catch(e=>{
                        this.setState({complianceStatusError:strings.internalServerError})
                    })})} />}
                        perform={'invoices:sendEmail'}
                        silent={true}
                        role={role} /> : ''} */}

                    {invoice.uuid && <Button className="p-mb-3" type="button" label={strings.Refresh} icon="pi pi-refresh" tooltipOptions={{ position: 'top' }} tooltip="Refresh" onClick={() => { this.reload() }} />}
                    {invoice.uuid && !sentToZatca && role == "super_admin" && <Button className="p-mb-3" type="button" label={"Regenerate Files"} icon="pi-file-export" tooltipOptions={{ position: 'top' }} tooltip="Regenerate" onClick={() => { this.confirmRegenerate() }} />}

                    {invoice.uuid && !invoice.confirmed ? <Can yes={() => <Button type="button" label={strings.DeleteDraft} icon="pi pi-times" onClick={() => this.setState({ deleteInvoiceDialog: true })} className="p-mb-3 p-button-warning"></Button>}
                        perform={'invoices:draft:delete'}
                        silent={true}
                        role={role} /> : ''}

                    {invoice.confirmed ? <Can yes={() => <Button label={strings.New} icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={() => {
                        window.location.href = `/profile/invoices/new`;
                    }} />}
                        perform={'invoices:draft:create'}
                        silent={true}
                        role={role} /> : ''}


                </React.Fragment>
            )
        }

        var sentToZatca = invoice.complianceStatus?.toUpperCase()=="cleared".toUpperCase() || invoice.complianceStatus?.toUpperCase()=="reported".toUpperCase();
        return (
            <div className="layout-invoice-page">
                <Toast ref={this.toast} />

                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />

                {errors?.length > 0 && <Message style={{ marginBottom: '10px' }} text={errors.map((m) => <li>{m}</li>)} severity="error" />}
                <br />
                <div className="p-grid">
                    <div className="p-md-8 p-col-12">
                        <div className="card">
                            <h5>{invoice.confirmed ? 'View' : (invoice.uuid ? strings.edit : strings.createNew)} {invoice.invoiceTypeCode == '388' ? strings.invoice : (invoice.invoiceTypeCode == '381' ? strings.CreditNote : strings.DebitNote)}
                                {invoice.complianceStatus?.toUpperCase()=="cleared".toUpperCase()  ?<Badge severity="success" value={strings.cleared}/>:""}
                                {invoice.complianceStatus?.toUpperCase()=="reported".toUpperCase()  ? <Badge severity="success" value={strings.reported}/>:""}

                                {/* {invoice.uuid && invoice.confirmed && (invoice.complianceStatus == "PENDING_COMPLIANCE_CHECK") ? <Can yes={() => <Button className="" type="button" label={strings.checkComplianceStatus} icon="pi pi-check" tooltipOptions={{ position: 'top' }} tooltip="Refresh" onClick={() => this.setState({ complianceStatusOpen: true, complianceStatusLoading: true }, () => {
                                    new InvoiceService().GetInvoiceComplianceStatus(invoice?.uuid).then(data => {
                                        this.setState({ complianceStatus: data, complianceStatusLoading: false });
                                    }).catch(e => {
                                        this.setState({ complianceStatusError:  e.response.data.message ?? strings.internalServerError  })
                                    })
                                })} />}
                                    perform={'invoices:sendEmail'}
                                    silent={true}
                                    role={role} /> : ''}

                                {invoice.uuid && invoice.confirmed && invoice.invoiceType.startsWith("tax") ? <Can yes={() => <Button className="" type="button" label={strings.clearInvoice} icon="pi pi-check" tooltipOptions={{ position: 'top' }} tooltip="Refresh" onClick={() => this.setState({ complianceStatusOpen: true, complianceStatusLoading: true }, () => {
                                    new InvoiceService().ClearInvoice(invoice?.uuid).then(data => {
                                        this.setState({ complianceStatus: data, complianceStatusLoading: false });
                                    }).catch(e => {
                                        this.setState({ complianceStatusError: e.response.data.message ?? strings.internalServerError })
                                    })
                                })} />}
                                    perform={'invoices:sendEmail'}
                                    silent={true}
                                    role={role} /> : ''}
                                {invoice.uuid && invoice.confirmed && invoice.invoiceType.startsWith("simple") && invoice.complianceStatus == "COMPLIANCE_PASS" ? <Can yes={() => <Button className="" type="button" label={strings.reportInvoice} icon="pi pi-check" tooltipOptions={{ position: 'top' }} tooltip="Refresh" onClick={() => this.setState({ complianceStatusOpen: true, complianceStatusLoading: true }, () => {
                                    new InvoiceService().ReportInvoice(invoice?.uuid).then(data => {
                                        this.setState({ complianceStatus: data, complianceStatusLoading: false });
                                    }).catch(e => {
                                        this.setState({ complianceStatusError:  e.response.data.message ?? strings.internalServerError  })
                                    })
                                })} />}
                                    perform={'invoices:sendEmail'}
                                    silent={true}
                                    role={role} /> : ''}

                                {invoice.uuid && invoice.confirmed && (invoice.complianceStatus == "PENDING_SIGNING" || invoice.complianceStatus == null || invoice.complianceStatus == 'COMPLIANCE_ERROR') ? <Can yes={() => <Button className="" type="button" label={strings.signInvoice} icon="pi pi-check" tooltipOptions={{ position: 'top' }} tooltip="Refresh" onClick={() => new InvoiceService().SubmitInvoiceSignTask(invoice?.uuid).then(() => {
                                    this.reload();
                                }).catch(e => {
                                    this.setState({ error:  e.response.data.message ?? strings.internalServerError  })
                                })} />}
                                    perform={'invoices:sendEmail'}
                                    silent={true}
                                    role={role} /> : ''}

                                {invoice.uuid && invoice.confirmed && invoice.complianceStatus == "SUBMITTED_FOR_SIGNING" ? <Can yes={() => <Button className="" type="button" label={strings.signInvoiceInProgress} icon="pi pi-check" tooltipOptions={{ position: 'top' }} tooltip="Refresh" disabled={true} />}
                                    perform={'invoices:sendEmail'}
                                    silent={true}
                                    role={role} /> : ''} */}

                                </h5>
                            <hr />
                            <div className="p-fluid p-formgrid p-grid">
                                {/* <div className="p-field p-col-10 p-md-10">
                                </div> */}

                                {/* <div className="p-field p-col-12 p-md-3">
                                    <b htmlFor="state">Subject For invoice:</b>
                                </div> */}
                                <div className="p-field p-col-12 p-md-3">
                                    <b htmlFor="state">{strings.InvoiceType}:</b>
                                    {canEdit ? <Dropdown id="type" value={invoice.type} onChange={this.changeValue} options={this.invoiceTypes} optionLabel="name" placeholder="Select One"></Dropdown> : <span>{invoice.type?.name}</span>}
                                </div>

                                {<div className="p-field p-col-12 p-md-3">
                                    <b htmlFor="originalInvoiceNumber">{strings.UserInvoiceNumber}:</b>
                                    {!invoice.confirmed ? <InputText id="originalInvoiceNumber" value={invoice.originalInvoiceNumber} onChange={this.changeValue} type="text" /> : <span>{invoice.originalInvoiceNumber}</span>}
                                </div>}
                                <div className="p-field p-col-12 p-md-3">
                                    <b htmlFor="state">{strings.PurchaseNumber}:</b>
                                    {canEdit ? <InputText id="orderReferenceId" value={invoice.orderReferenceId} onChange={this.changeValue} type="text" /> : <span>{invoice.orderReferenceId}</span>}
                                </div>

                                <div className="p-field p-col-12 p-md-3">
                                    <b htmlFor="paymentMeansCode">{strings.PaymentMean}:</b>
                                    {canEdit ? <Dropdown id="paymentMeansCode" value={invoice.paymentMeansCode} onChange={this.changeValue} options={this.paymentMeansCodes} optionLabel="name" placeholder={strings.Select}></Dropdown> : <span>{invoice.paymentMeansCode?.name}</span>}
                                </div>

                                <div className="p-field p-col-12 p-md-12">
                                    {/* <hr style={{borderColor:'#039b92'}}/> */}
                                    <div className="p-field p-col-12 p-md-12">
                                        <div className="p-fluid p-formgrid p-grid">
                                            <div className={`p-field p-col-12 p-md-${invoice.vendor?.branches?.length > 0 ? '6' : '12'}`} style={{ textAlign: 'center' }}>
                                                <b>{strings.Vendor}:</b>
                                                {canEdit && !companyPopulted ? <>
                                                    {/* {invoice.agreementType?.code != 'selfBilled' && <b htmlFor="state">Select Vendor:</b>} */}

                                                    {<div style={{ display: 'flex', width: '100%', paddingTop: '10px' }}>
                                                        <Dropdown filter={true} style={{ width: '100%' }} id="vendor" filterPlaceholder={strings.filterSearchPlaceHolder} filterBy="name,vatNumber,phone,email" value={invoice.vendor} onChange={(e) => {
                                                            this.changeValue(e, () => {

                                                                let invoice = { ...this.state.invoice };
                                                                invoice.products = [];
                                                                this.setState({ invoice: invoice });
                                                                this.reloadCustomers();

                                                                this.reloadProducts();
                                                            });
                                                        }} options={vendors} optionLabel="name" placeholder={strings.Select}></Dropdown>

                                                        <Can yes={() => <Button icon="pi pi-plus" tooltipOptions={{
                                                            position: 'top'
                                                        }} tooltip="Create Vendor" onClick={() => { this.setState({ addVendor: true }) }}></Button>}
                                                            perform={'vendors:create'}
                                                            silent={true}
                                                            role={role} />
                                                    </div>
                                                    }
                                                </> : <p>{invoice.vendor?.name}</p>}

                                                <span>{strings.VatNumber}: <b>{invoice.vendor?.vatNumber ?? '-'}:</b></span>

                                            </div>
                                            {canEdit && invoice.vendor?.branches?.length > 0 && !branchPopulated ? <div className="p-field p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                                <b>{strings.Branch}:</b>
                                                <div style={{ display: 'flex', paddingTop: '10px' }}><Dropdown style={{ width: '100%' }} filter={true} id="branchSerial" value={invoice.branchSerial} onChange={this.changeValue} options={invoice.vendor?.branches?.map((i) => {
                                                    return {
                                                        code: i?.serial,
                                                        name: `${i?.name} (${i?.serial})`
                                                    }
                                                })} optionLabel="name" placeholder={strings.Select}></Dropdown></div>
                                            </div> : (invoice.branchSerial && invoice.vendor?.branches?.length > 0 ? <div className="p-field p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                                <h5>{strings.Branch}</h5>
                                                <p>{invoice.branchSerial?.name}</p>
                                            </div> : '')}


                                            {this.state.customersLoading ? <div style={{ textAlign: 'center' }}><ProgressSpinner /></div> : (<>
                                                {!invoice.vendor ? <div className={`p-field p-col-12 p-md-12`} style={{ textAlign: 'center' }}><Message style={{ width: '100%', marginBottom: '20px', padding: '20px' }} severity="info" content={strings.selectVendorsToShowCustomers} /></div> :
                                                    <div className={`p-field p-col-12 p-md-12`} style={{ textAlign: 'center' }}>
                                                        <b>{strings.Customers}:</b>
                                                        {canEdit ?

                                                            <div style={{ display: 'flex', paddingTop: '10px' }}>
                                                                <AutoComplete dropdown placeholder={strings.filterSearchPlaceHolder} style={{ width: "100%" }} id="customer" value={invoice.customer} suggestions={customers} field="name" completeMethod={this.changeCustomerSearchBar} onChange={this.changeValue} />

                                                                <Can yes={() => <Button icon="pi pi-plus" tooltipOptions={{
                                                                    position: 'top'
                                                                }} tooltip="Create Customer" onClick={() => { this.setState({ addCustomer: true }) }}></Button>}
                                                                    perform={'customers:create'}
                                                                    silent={true}
                                                                    role={role} />
                                                            </div>
                                                            : <p>{invoice.customer?.name}</p>}

                                                        <span style={{ textAlign: 'left' }}>{strings.VatNumber}: <b>{invoice.customer?.vatNumber ?? '-'}</b></span>

                                                    </div>}


                                                {/* <div className="p-field p-col-12 p-md-3" style={{ textAlign: 'center' }}>
                                                <b htmlFor="state">{strings.CustomerVATNumber}:</b>
                                                <span style={{paddingTop:'10px'}}>{invoice.customer?.vatNumber??'-'}</span>
                                            </div> */}


                                            </>)}

                                            {/* <div className="p-field p-col-12 p-md-3" style={{textAlign:'center'}}>
                                                <b htmlFor="state">{strings.VendorVAT}:</b>
                                                <span style={{margin:'10px', paddingTop:'10px'}}>{invoice.vendor?.vatNumber}</span>
                                            </div> */}
                                        </div>
                                        {/* <hr style={{borderColor:'#039b92'}}/> */}
                                    </div>
                                </div>


                                {/* <div className="p-field p-col-12 p-md-12"> */}







                                {/* </div> */}

                                <div className="p-field p-col-12 p-md-12">

                                    <Accordion>
                                        <AccordionTab header={<React.Fragment><i className="pi pi-info-circle"></i><span>&nbsp;{strings.advanced}</span></React.Fragment>}>
                                            <div className="card">
                                                <div className="p-fluid p-formgrid p-grid">
                                                    {invoice.type?.code == 'tax' && <div className=" p-col-12 p-md-12">
                                                        <b htmlFor="transactionType">{strings.Specialbillingagreement}:</b>
                                                        {canEdit ? <Dropdown id="agreementType" value={invoice.agreementType} onChange={(e) => {
                                                            let i = { ...invoice };
                                                            i['vendor'] = vendors[0];
                                                            i.branchSerial = null;
                                                            this.setState({ invoice: i }, () => {
                                                                this.reloadCustomers()
                                                                this.reloadProducts()
                                                                this.changeValue(e);
                                                            });
                                                        }} options={this.agreementTypes} optionLabel="name" placeholder={strings.Select}></Dropdown> : <span>{invoice.agreementType?.name}</span>}
                                                    </div>}
                                                    <div className="p-field p-col-12 p-md-12">
                                                        <b htmlFor="transactionType">{strings.TransactionType}:</b>
                                                        {canEdit ? <Dropdown id="transactionType" value={invoice.transactionType} onChange={this.changeValue} options={this.transactionTypes.filter((e) => ((invoice.agreementType?.code != 'selfBilled' || (e.code != 'exports'))))} optionLabel="name" placeholder="Select One"></Dropdown>
                                                            : <span>{invoice.transactionType?.name}</span>}
                                                    </div>

                                                    {invoice.type?.code == 'tax' && <div className="p-field p-col-12 p-md-12">
                                                        <b htmlFor="paymentNote">{strings.PaymentConditions}:</b>
                                                        {canEdit ? <InputText id="paymentNote" value={invoice.paymentNote} onChange={this.changeValue} type="text" /> : <span>{invoice.paymentNote}</span>}
                                                    </div>}
                                                    <div className="p-field p-col-12 p-md-12">
                                                        <b htmlFor="state">{strings.InvoiceNote}:</b>
                                                        {!invoice.confirmed ? <Editor headerTemplate={this.renderInvoiceNoteHeader()} placeholder={strings.InvoiceNote} value={invoice.invoiceNote} id="invoiceNote" onTextChange={(e) => {
                                                            let i = { ...this.state.invoice };
                                                            i['invoiceNote'] = e.htmlValue;
                                                            this.setState({invoice:i});
                                                        }} />:<span>{invoice.invoiceNote}</span>}
                                                    </div>
                                                    <div className="p-field p-col-12 p-md-12">
                                                        <p htmlFor="state"><b>{strings.TotalAmountsInWords}:</b></p>
                                                        <b>العربية:</b>
                                                        {canEdit ? <InputText id="enTotalAmountInWords" value={invoice.enTotalAmountInWords} onChange={this.changeValue} type="text" /> : <span>{invoice.enTotalAmountInWords}</span>}
                                                        <b>English:</b>
                                                        {canEdit ? <InputText id="arTotalAmountInWords" value={invoice.arTotalAmountInWords} onChange={this.changeValue} type="text" /> : <span>{invoice.enTotalAmountInWords}</span>}
                                                    </div>
                                                    <div className="p-field p-col-12 p-md-12">
                                                        <b htmlFor="currencyCode">{strings.Currency}:</b>
                                                        <div style={{ display: 'flex' }}>

                                                            {canEdit ? <Dropdown id="currencyCode" value={invoice.currencyCode} onChange={(e) => {
                                                                this.changeValue(e, () => {
                                                                    this.changeValue({
                                                                        target: {
                                                                            id: "currencyRate",
                                                                            value: this.state.rates.filter(r => r.code == e.target.value?.code)[0]
                                                                        }
                                                                    })
                                                                })
                                                            }} options={currencyCodes.filter(c => {
                                                                return this.state.rates.filter(r => r.code == c.code).length > 0
                                                            })} optionLabel="name" style={{ width: '100%' }} placeholder="Select One"></Dropdown> : <span>{invoice.currencyCode?.name}</span>}
                                                            {canEdit && <Can yes={() => <Button icon="pi pi-eye" tooltipOptions={{
                                                                position: 'top'
                                                            }} tooltip="Check Currency Rate" onClick={() => { this.setState({ addRate: true }) }}></Button>}
                                                                perform={'currencyRates:create'}
                                                                silent={true}
                                                                role={role} />}
                                                            {(invoice.currencyCode?.code) != getCurrency() ?
                                                                <span>&nbsp;({(invoice.currencyRate?.rate) ?? invoice.currencyRate} {invoice.currencyCode?.code} = 1 {getCurrency()})</span> : ''}
                                                        </div>

                                                    </div>
                                                    {/* <Button icon="pi pi-" style={{ marginLeft: '10px', marginTop: '-7px' }} onClick={this.GetData} className="p-button-rounded p-button-success p-mr-2" /> */}
                                                </div>
                                            </div>

                                        </AccordionTab>
                                        <AccordionTab header={<React.Fragment><i className="pi pi-info-circle"></i><span>&nbsp;{strings.dates}</span></React.Fragment>}>
                                            <div className="p-field p-col-12 p-md-12">
                                                <InvoiceDates invoice={invoice} changeValue={this.changeValue} />
                                            </div>


                                        </AccordionTab>

                                        <AccordionTab header={<React.Fragment><i className="pi pi-info-circle"></i><span>&nbsp;{strings.customProperties}</span></React.Fragment>}>
                                            <div className="card">
                                                <div style={{ display: 'flex' }}>
                                                    <h4>{strings.customProperties}</h4>
                                                    {!invoice.confirmed && invoice.properties.length < 4 && <Can yes={() => <Button icon="pi pi-plus" style={{ margin: 'auto', width: '25px', height: '25px' }} onClick={this.AddCustomPropertyRow} className="p-button-rounded p-button-success p-mr-2" />}
                                                        perform={'invoices:draft:update'}
                                                        silent={true}
                                                        role={role} />}

                                                    {/* <Button icon="pi pi-" style={{ marginLeft: '10px', marginTop: '-7px' }} onClick={this.GetData} className="p-button-rounded p-button-success p-mr-2" /> */}
                                                </div>

                                                <div>
                                                    <Message severity="info" content={strings.youCanChangeDropdownContent}></Message>
                                                </div>

                                                <DataTable value={invoice.properties}
                                                    stripedRows
                                                    emptyMessage={strings.Norecordsfound}>
                                                    {/* <Column field="key" header={strings.key} body={this.propertyInputTextEditor} ></Column> */}
                                                    <Column field="arabicName" style={{ width: '30%' }} header={strings.NameArabic} body={this.propertyNameEditor}></Column>
                                                    <Column field="englishName" style={{ width: '30%' }} header={strings.NameEnglish} body={this.propertyNameEditor}></Column>
                                                    <Column field="value" style={{ width: '20%' }} header={strings.Value} body={this.propertyInputTextEditor}></Column>
                                                    <Column field="showOnPdf" style={{ width: '10%' }} header={strings.showOnPdf} body={this.propertyInputTextEditor}></Column>
                                                    <Column header={strings.Action} style={{ width: '10%' }} body={this.propertyActionBodyTemplate}></Column>
                                                </DataTable>
                                            </div>

                                        </AccordionTab>

                                    </Accordion>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="p-md-4 p-col-12">
                        <TabView activeIndex={activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                            <TabPanel header={strings.Generalinfo}>
                                <div className="p-fluid p-formgrid p-grid" style={{ overflowY: 'scroll', maxHeight: '37em' }}>

                                    {invoice.invoiceTypeCode != '388' ? <>
                                        <div className="p-field p-col-12 p-md-12">
                                            <b htmlFor="state">{strings.BillingReferenceId}:</b>
                                            {canEdit ? <InputText id="billingReferenceId" value={invoice.billingReferenceId} onChange={this.changeValue} type="text" /> : <span>{invoice.billingReferenceId}</span>}
                                        </div>
                                        <div className="p-field p-col-12 p-md-12">
                                            <b htmlFor="state">{strings.InstructionNote}:</b>

                                            <b htmlFor="paymentNote">{strings.Reason}:</b>
                                            {!invoice.confirmed ? <Dropdown id="instructionNote" value={invoice.instructionNote} onChange={this.changeValue} options={this.reasons} optionLabel="name" placeholder="Select One"></Dropdown> : <span>{invoice.instructionNote?.name}</span>}
                                        </div>
                                    </>
                                        : ''}


                                    <div className="p-field p-col-12 p-md-12" style={{textAlign:"center"}}>
                                        <QRCode value={invoice.qrCode ?? ''} />
                                    </div>

                                    <div className="p-field p-col-12 p-md-12">
                                        <Dialog visible={this.state.complianceStatusOpen} style={{ width: '700px' }} header={strings.Compliance} modal className="p-fluid" onHide={() => this.setState({ complianceStatusOpen: false })}>
                                            <div className="p-col-12">
                                                <div className="card">
                                                    {this.state.complianceStatusError ? <Message content={this.state.complianceStatusError} severity="error"></Message> : <>
                                                        Compliance status is: {this.state.complianceStatusLoading ? 'Loading...' : <Badge severity={this.state.complianceStatus?.validationResults.status == 'PASS' ? "success" : "danger"} value={this.state.complianceStatus?.validationResults.status}></Badge>}

                                                        <hr />

                                                        {this.state.complianceStatus?.validationResults.infoMessages.map((message => {
                                                            return <Message content={message.messageMessage} severity="info"></Message>
                                                        }))}

                                                        {this.state.complianceStatus?.validationResults.status != 'PASS' ? <>
                                                            {this.state.complianceStatus?.validationResults.errorMessages.map((message => {
                                                                return <><hr /><Message content={message.messageMessage} severity="error"></Message></>
                                                            }))}
                                                            {this.state.complianceStatus?.validationResults.warningMessages.map((message => {
                                                                return <><hr /><Message content={message.messageMessage} severity="warn"></Message></>
                                                            }))}
                                                        </> : ''}
                                                    </>
                                                    }

                                                </div>

                                            </div>
                                        </Dialog>
                                    </div>
                                    <div className="p-field p-col-12 p-md-12">
                                        <h6 htmlFor="name1">{strings.InvoiceNumber}</h6>
                                        {<h3>{invoice.invoiceNumber ?? "DRAFT"}</h3>}
                                    </div>

                                    <div className="p-field p-col-12 p-md-12">
                                        <b htmlFor="state">{strings.InvoiceTypeCode}:</b>
                                        <span>{invoice.invoiceTypeCode}</span>
                                    </div>
                                    <div className="p-field p-col-12 p-md-12">
                                        <b htmlFor="state">{strings.InvoiceTransactionCode}:</b>
                                        <span>{invoice.invoiceTransactionCode}</span>
                                    </div>
                                    <div className="p-field p-col-12 p-md-12">
                                        <b htmlFor="state">{strings.InvoiceCounterValue}:</b>
                                        <span>{invoice.icv}</span>
                                    </div>
                                    <div className="p-field p-col-12 p-md-12">
                                        <b htmlFor="paymentType">{strings.InvoicePaymentType}:</b>
                                        <span id="paymentType">&nbsp; <b>{this.GetInvoicePaymentType()}:</b></span>
                                    </div>
                                    {/* Dhuruma customization */}
                                    {/* workaround TODO:CHANGE TO DYNAMIC CHECK */}
                                    {invoice.vendor?.vatNumber == '300229871200003' ? <>
                                        <div className="p-field p-col-12 p-md-12">
                                            <b htmlFor="state">{strings.Subject}:</b>
                                            {!invoice.confirmed ? <InputTextarea id="subject" value={invoice.subject} onChange={this.changeValue} type="text" /> : <span>{invoice.subject}</span>}
                                        </div>
                                        <div className="p-field p-col-12 p-md-12">
                                            <b htmlFor="state">{strings.AttentionText}:</b>
                                            {!invoice.confirmed ? <InputTextarea id="attention" value={invoice.attention} onChange={this.changeValue} type="text" /> : <span>{invoice.attention}</span>}
                                        </div>
                                    </> : ''}



                                    <div className="p-field p-col-12 p-md-12" style={{"textAlign":"center"}}>

                                        <Button style={{"width":"50%"}} onClick={()=>this.setState({ activeIndex: 1 })}>{strings.goToOtherAmounts}</Button>

                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel header={"Other Amounts"}>
                                <div className="p-fluid p-formgrid p-grid" style={{ overflowY: 'scroll', maxHeight: '37em' }}>


                                    <div className="p-field p-col-12 p-md-12" >

                                        {/* Retention */}
                                        <table style={{width:"100%"}}>
                                            <thead>
                                                <tr>
                                                    <th style={{border:"none"}} colSpan={"5"}>{strings.Retention} ({invoice.currencyCode?.code})</th>
                                                </tr>
                                                <tr>
                                                    <th style={{border:"none"}}>{strings.amount}</th>
                                                    <th style={{border:"none"}}>{strings.percentage}</th>
                                                    <th style={{border:"none"}}> {strings.calculate}</th>
                                                    <th style={{border:"none"}}> {strings.includeInTax}</th>
                                                    <th style={{border:"none"}}> {strings.TaxAmount}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{border:"none"}}>
                                                        {!invoice.confirmed ? <InputText type="number" id="retention" value={invoice.retention} onChange={this.changeValue} /> : <span>{invoice.retention} {invoice.currencyCode?.code}</span>}
                                                    </td>
                                                    <td style={{border:"none"}}>
                                                        {!invoice.confirmed ? <InputText type="number" id="retentionPercentage" value={invoice.retentionPercentage} onChange={this.changeValue} /> : <span>{invoice.retentionPercentage}%</span>}
                                                    </td>
                                                    <td style={{border:"none"}}>
                                                        {!invoice.confirmed ? <Checkbox id="includeRetention" name="includeRetention" value={invoice.includeRetention} checked={invoice.includeRetention} onChange={this.changeValue} /> : <span>{invoice.includeRetention ? strings.calculated : strings.notCalculated}</span>}
                                                    </td>
                                                    <td style={{border:"none"}}>
                                                        {!invoice.confirmed ? <Checkbox disabled={!invoice.includeRetention} id="includeRetentionInTax" name="includeRetentionInTax" value={invoice.includeRetentionInTax} checked={invoice.includeRetentionInTax} onChange={this.changeValue} /> : <span>{invoice.includeRetentionInTax && invoice.includeRetention ? strings.yes : strings.No}</span>}
                                                    </td>
                                                    <td style={{border:"none"}}>
                                                    {!invoice.confirmed ? <InputText disabled={!invoice.includeRetention || !invoice.includeRetentionInTax} type="number" id="retentionTax" value={invoice.retentionTax} onChange={this.changeValue} /> : <span>{ invoice.includeRetentionInTax && invoice.includeRetention ? invoice.retentionTax : '-'} {invoice.includeRetentionInTax && invoice.includeRetention && invoice.currencyCode?.code}</span>}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* Retention */}

                                    <div className="p-field p-col-12 p-md-12" >
                                    <hr/>

                                        <table style={{width:"100%"}}>
                                            <thead>
                                                <tr>
                                                    <th style={{border:"none"}} colSpan={"5"}>{strings.Recovery} ({invoice.currencyCode?.code})</th>
                                                </tr>
                                                <tr>
                                                    <th style={{border:"none"}}>{strings.amount}</th>
                                                    <th style={{border:"none"}}>{strings.percentage}</th>
                                                    <th style={{border:"none"}}> {strings.calculate}</th>
                                                    <th style={{border:"none"}}> {strings.includeInTax}</th>
                                                    <th style={{border:"none"}}> {strings.TaxAmount}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{border:"none"}}>
                                                    {!invoice.confirmed ? <InputText type="number" id="recovery" value={invoice.recovery} onChange={this.changeValue} /> : <span>{invoice.recovery} {invoice.currencyCode?.code}</span>}
                                                    </td>
                                                    <td style={{border:"none"}}>
                                                    {!invoice.confirmed ? <InputText type="number" id="recoveryPercentage" value={invoice.recoveryPercentage} onChange={this.changeValue} /> : <span>{invoice.recoveryPercentage}%</span>}
                                                    </td>
                                                    <td style={{border:"none"}}>
                                                    {!invoice.confirmed ? <Checkbox id="includeRecovery" name="includeRecovery" value={invoice.includeRecovery} checked={invoice.includeRecovery} onChange={this.changeValue} /> : <span>{invoice.includeRecovery ? strings.calculated : strings.notCalculated}</span>}
                                                    </td>
                                                    <td style={{border:"none"}}>
                                                        {!invoice.confirmed ? <Checkbox disabled={!invoice.includeRecovery} id="includeRecoveryInTax" name="includeRecoveryInTax" value={invoice.includeRecoveryInTax} checked={invoice.includeRecoveryInTax} onChange={this.changeValue} /> : <span>{invoice.includeRecoveryInTax && invoice.includeRecovery? strings.Yes : strings.No}</span>}
                                                    </td>
                                                    <td style={{border:"none"}}>
                                                    {!invoice.confirmed ? <InputText disabled={!invoice.includeRecovery || !invoice.includeRecoveryInTax} type="number" id="recoveryTax" value={invoice.recoveryTax} onChange={this.changeValue} /> : <span> { invoice.includeRecoveryInTax && invoice.includeRecovery ? invoice.recoveryTax : '-'} {invoice.includeRecoveryInTax && invoice.includeRecovery && invoice.currencyCode?.code}</span>}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    <hr/>
                                    {/* Advanced Amount */}

                                    <div className="p-field p-col-12 p-md-12" >
                                    <hr/>

                                        <table style={{width:"100%"}}>
                                            <thead>
                                                <tr>
                                                    <th style={{border:"none"}} colSpan={"5"}>{strings.advancedAmount} ({invoice.currencyCode?.code})</th>
                                                </tr>
                                                <tr>
                                                    <th style={{border:"none"}}>{strings.amount}</th>
                                                    <th style={{border:"none"}}>{strings.percentage}</th>
                                                    <th style={{border:"none"}}> {strings.calculate}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{border:"none"}}>
                                                    {!invoice.confirmed ? <InputText type="number" id="advancedAmount" value={invoice.advancedAmount} onChange={this.changeValue} /> : <span>{invoice.advancedAmount} {invoice.currencyCode?.code}</span>}
                                                    </td>
                                                    <td style={{border:"none"}}>
                                                    {!invoice.confirmed ? <InputText type="number" id="advancedAmountPercentage" value={invoice.advancedAmountPercentage} onChange={this.changeValue} /> : <span>{invoice.advancedAmountPercentage}%</span>}
                                                    </td>
                                                    <td style={{border:"none"}}>
                                                    {!invoice.confirmed ? <Checkbox id="includeAdvancedAmount" name="includeAdvancedAmount" value={invoice.includeAdvancedAmount} checked={invoice.includeAdvancedAmount} onChange={this.changeValue} /> : <span>{invoice.includeAdvancedAmount ? strings.calculated : strings.notCalculated}</span>}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    <hr/>

                                    </div>
                                    <div className="p-field p-col-12 p-md-12" >

                                        <table style={{width:"100%"}}>
                                            <thead>
                                                <tr>
                                                    <th style={{border:"none"}} colSpan={"3"}>{strings.prepaidAmount} ({invoice.currencyCode?.code})</th>
                                                </tr>
                                                <tr>
                                                    <th style={{border:"none"}}>{strings.amount}</th>
                                                    <th style={{border:"none"}}>{strings.date}</th>
                                                    <th style={{border:"none"}}> {strings.calculate}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{border:"none"}}>
                                                    {!invoice.confirmed ? <InputText type="number" id="prepaidAmount" value={invoice.prepaidAmount} onChange={this.changeValue} /> : <span>{invoice.prepaidAmount} {invoice.currencyCode?.code}</span>}
                                                    </td>
                                                    <td style={{border:"none"}}>
                                                    {!invoice.confirmed ? <Calendar ref={this.ref} id="prepaidAmountDate" showTime={true} showIcon showSeconds={true} showButtonBar value={formatDate(invoice.prepaidAmountDate)} onChange={this.changeValue}></Calendar> : <span>{invoice.prepaidAmountDate ? formatDateString(invoice.prepaidAmountDate) : '-'}</span>}
                                                    </td>
                                                    <td style={{border:"none"}}>
                                                    {!invoice.confirmed ? <Checkbox id="includePrepaid" name="includePrepaid" value={invoice.includePrepaid} checked={invoice.includePrepaid} onChange={this.changeValue} /> : <span>{invoice.includePrepaid ? strings.calculated : strings.notCalculated}</span>}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table style={{width:"100%"}}>
                                            <thead>
                                                <tr>
                                                    <th style={{border:"none"}} colSpan={"3"}>{strings.documentDiscount} ({invoice.currencyCode?.code})</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{border:"none"}}>
                                                    {!invoice.confirmed ? <InputText type="number" id="allowanceAmount" value={invoice.allowanceAmount} onChange={this.changeValue} /> : <span>{invoice.allowanceAmount} {invoice.currencyCode?.code}</span>}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                </div>
                            </TabPanel>

                            <TabPanel header={strings.QRCode}>
                                <div className="p-field p-col-12 p-md-12" style={{ textAlign: 'center' }}>
                                    <QRCode value={invoice.qrCode ?? ''} />
                                </div>
                                <div className="p-field p-col-6 p-md-6">
                                    <b htmlFor="state">{strings.Name}:</b>
                                    <span>{invoice.vendor?.name}</span>
                                </div>
                                <div className="p-field p-col-12 p-md-12">
                                    <b htmlFor="state">{strings.VatNumber}:</b>
                                    <span>{invoice.vendor?.vatNumber}</span>
                                </div>
                                <div className="p-field p-col-12 p-md-12">
                                    <b htmlFor="state">{strings.IssueDate}:</b>
                                    <span>{formatDateString(invoice.invoiceIssueDate)}</span>
                                </div>
                                <div className="p-field p-col-12 p-md-12">
                                    <b htmlFor="paymentType">{strings.totalAmountDue}:</b>
                                    <span id="paymentType">{amounts?.payableAmount?.text?.toLocaleString()} {amounts?.taxAmount?.currencyID}</span>
                                </div>
                                <div className="p-field p-col-12 p-md-12">
                                    <b htmlFor="paymentType">{strings.TaxAmount}:</b>
                                    <span id="paymentType">{amounts?.taxAmount?.text?.toLocaleString()} {amounts?.taxAmount?.currencyID}</span>
                                </div>
                                <div style={{ wordWrap: "break-word" }} className="p-field p-col-12 p-md-12">
                                    <b htmlFor="paymentType">{strings.Base64Value}:</b>
                                    <span style={{ display: 'block' }} id="paymentType">{invoice.qrCode}</span>
                                </div>
                            </TabPanel>
                            <TabPanel header={strings.Otherinfo}>
                                <div className="p-field p-col-12 p-md-12">
                                    <b htmlFor="paymentType">{strings.UUID}:</b>
                                    <span id="paymentType">{invoice.uuid}</span>
                                </div>
                                <div style={{ wordWrap: "break-word" }} className="p-field p-col-12 p-md-12">
                                    <b htmlFor="paymentType">{strings.PreviousInvoiceHash}:</b>
                                    <span style={{ display: 'block' }} id="paymentType">{invoice.previousHashInvoiceNumber ?? 'TBD on invoice confirmation'}</span>
                                </div>
                                <div style={{ wordWrap: "break-word" }} className="p-field p-col-12 p-md-12">
                                    <b htmlFor="paymentType">{strings.ReferenceHashInvoiceNumber}:</b>
                                    <span style={{ display: 'block' }} id="paymentType">{invoice.referenceHashInvoiceNumber ?? 'TBD on invoice confirmation'}</span>
                                </div>
                                <div style={{ wordWrap: "break-word" }} className="p-field p-col-6 p-md-6">
                                    <b htmlFor="paymentType">{strings.CreatedBy}:</b>
                                    <span style={{ display: 'block' }} id="paymentType">{invoice.createdAt ?? 'TBD on save'}</span>
                                </div>
                                <div style={{ wordWrap: "break-word" }} className="p-field p-col-6 p-md-6">
                                    <b htmlFor="paymentType">{strings.createdAt}:</b>
                                    <span style={{ display: 'block' }} id="paymentType">{invoice.createdBy ?? 'TBD on save'}</span>
                                </div>
                                <div style={{ wordWrap: "break-word" }} className="p-field p-col-6 p-md-6">
                                    <b htmlFor="paymentType">{strings.updatedBy}:</b>
                                    <span style={{ display: 'block' }} id="paymentType">{invoice.updatedBy ?? 'TBD on save'}</span>
                                </div>
                                <div style={{ wordWrap: "break-word" }} className="p-field p-col-6 p-md-6">
                                    <b htmlFor="paymentType">{strings.updatedAt}:</b>
                                    <span style={{ display: 'block' }} id="paymentType">{invoice.updatedAt ?? 'TBD on save'}</span>
                                </div>
                            </TabPanel>

                            <TabPanel header={strings.FileHistory}>
                                <div className="p-field p-col-12 p-md-12" style={{overflowX: 'scroll'}}>

                                    <table className="attachments-table">
                                        <thead>
                                            <tr >
                                                <th>
                                                    {strings.file}
                                                </th>
                                                <th>
                                                    {strings.status}
                                                </th >
                                                <th>
                                                    {strings.CreatedBy}
                                                </th >
                                                <th>
                                                    {strings.date}
                                                </th>

                                            </tr>
                                        </thead>

                                        {invoice.files?.map(i => {

                                            return (
                                                <tr>
                                                    <td style={{ fontSize: '10px' }}>
                                                        {i.filePath ? <Button className="p-mb-3" type="button" label={i.filePath?.endsWith(".pdf") ? strings.downloadPdf : (i.filePath?.endsWith(".xml") ? strings.downloadXml : 'NA')} icon="pi pi-file-pdf" tooltipOptions={{
                                                            position: 'top'
                                                        }} tooltip={strings.downloadPDF} onClick={() => {
                                                            new JsFileDownloader({
                                                                url: `${api}/download/${i.filePath}`,
                                                                headers: [{
                                                                    name: 'Authorization',
                                                                    value: `Bearer ${token}`
                                                                }]
                                                            })
                                                                .then(() => {
                                                                    this.toast?.current.show({ severity: `${strings.success}`, summary: 'Downloaded File', life: 3000 });
                                                                })
                                                                .catch((error) => {
                                                                    this.toast?.current.show({ severity: `${strings.error}`, summary: 'File is not ready yet, refresh and try again.', life: 3000 });
                                                                });
                                                        }} /> : '-'}
                                                    </td>
                                                    <td>

                                                        {strings[`generation-${i.status}`]}
                                                    </td>
                                                    <td >
                                                        {i.createdBy}
                                                    </td>
                                                    <td >
                                                        {formatDateString(i.createdAt)}
                                                    </td>

                                                </tr>
                                            );
                                        })}
                                    </table>
                                </div>


                            </TabPanel>
                        </TabView>
                        {/* <hr/> */}
                    </div>



                    {/* <div className="p-col-4">
                        <div className="card">
                            <h5>Dates</h5>
                            <InvoiceDates invoice={invoice} changeValue={this.changeValue} />
                        </div>
                    </div> */}
                    <div translate="no"  className="p-col-12">
                        <div className="card">

                            {invoice.vendor ? <div>
                                {/* <div style={{ display: 'flex' }}> */}

                                {/* <Button icon="pi pi-" style={{ marginLeft: '10px', marginTop: '-7px' }} onClick={this.GetData} className="p-button-rounded p-button-success p-mr-2" /> */}
                                {/* </div> */}
                                <div className="p-field p-col-12 p-md-12" >
                                    <DataTable
                                        header={<div>
                                            <h4>{strings.Lines}</h4>
                                        </div>}
                                        footer={invoice.products?.length > 0 && (!invoice.confirmed) && <Can yes={() => <div style={{ width: '100%', textAlign: 'center', marginLeft: '10px', marginTop: '-7px' }}><Button icon="pi pi-plus" title={strings.addLine} style={{ width: '25%', alignSelf: 'center', marginLeft: '10px', marginTop: '-7px' }} onClick={this.AddRow} className="p-button-rounded p-button-success p-mr-2">&nbsp;{strings.addLine}</Button></div>}
                                            perform={'products:create'}
                                            silent={true}
                                            role={new UserService().GetRole()} />}
                                        value={invoice.products}
                                        dataKey="id"
                                        responsiveLayout="scroll"
                                        resizableColumns columnResizeMode="fit"
                                        lazy
                                        stripedRows
                                        autoLayout={true}
                                        loading={this.state.productsLoading}
                                        showGridlines={true}
                                        onRowEditInit={this.onRowEditInit}
                                        onRowEditCancel={this.onRowEditCancel}
                                        className="datatable-responsive"
                                        emptyMessage={(!invoice.confirmed) && <Can yes={() => <div style={{ width: '100%', textAlign: 'center', marginLeft: '10px', marginTop: '-7px' }}><Button icon="pi pi-plus" title={strings.addLine} style={{ width: '25%', alignSelf: 'center', marginLeft: '10px', marginTop: '-7px' }} onClick={this.AddRow} className="p-button-rounded p-button-success p-mr-2">&nbsp;{strings.addLine}</Button></div>}
                                            perform={'products:create'}
                                            role={new UserService().GetRole()} />}
                                    >
                                        <Column style={{ width: '2%' }} field="index" header={'#'} body={(rawData, props) => {
                                            return <b>{rawData['index']}</b>;
                                        }}></Column>
                                        <Column style={{ width: '35%', textAlign: 'center' }} field="product" header={strings.Product} body={this.productDropdown}></Column>
                                        <Column style={{ width: '15%', textAlign: 'center' }} field="productNumber" header={strings.ProductNumber} body={this.productDropdown}></Column>
                                        {/* <Column field="label" header={strings.Label} body={(rawData, props) => this.inputTextEditor(rawData, props)}></Column> */}
                                        <Column style={{ width: '7%', textAlign: 'center' }} field="qty" header={strings.Quantity} body={(rawData, props) => this.inputTextEditor(rawData, props, 'number')}></Column>
                                        <Column style={{ width: '3rem', textAlign: 'center' }} field="price" header={`${strings.Price} (${invoice.currencyCode?.code})`} body={(rawData, props) => this.inputTextEditor(rawData, props, 'number')}></Column>
                                        <Column style={{ width: '5%', fontSize: '12px', textAlign: 'center' }} field="discountPercentage" header={`${strings.DISC}%`} body={(rawData, props) => this.inputTextEditor(rawData, props, 'number')}></Column>
                                        <Column style={{ width: '5%', fontSize: '12px', textAlign: 'center' }} field="discountAmount" header={`${strings.DISC} Amount`} body={(rawData, props) => this.inputTextEditor(rawData, props, 'number')}></Column>
                                        <Column style={{ width: '5%', textAlign: 'center' }} field="taxes" header={strings.Taxes} body={(rowData) => <div>{rowData['taxes']}</div>}></Column>
                                        <Column style={{ width: '7%', textAlign: 'center' }} field="taxAmount" header={`${strings.TaxAmount} (${getCurrency()})`} body={(rowData) => <div>{rowData['taxAmount']?.toLocaleString()} {getCurrency()}</div>}></Column>
                                        <Column style={{ width: '7%', textAlign: 'center' }} field="subtotal" header={`${strings.Subtotal} (${invoice.currencyCode?.code})`} body={(rowData) => <div>{rowData['subtotal']?.toLocaleString()}</div>}></Column>
                                        <Column style={{ width: '10%', textAlign: 'center' }} field="total" header={`${strings.Total} (${invoice.currencyCode?.code})`} body={(rowData) => <div>{rowData['total']?.toLocaleString()}</div>}></Column>
                                        {!invoice.confirmed && <Column header="Action" body={this.actionBodyTemplate}></Column>}
                                    </DataTable>
                                </div>
                                {invoice.uuid ?
                                    <div>
                                        <hr />
                                        <div className="p-col-3 p-md-3 p-offset-9">
                                            <div className=" p-col-12 p-md-12">
                                                <Message severity="info" content={strings.TotalCalcNote}></Message>
                                            </div>
                                            <div className=" p-col-12 p-md-12">
                                                <label htmlFor="paymentType">{strings.invoiceTotal}: </label>
                                                <span id="paymentType">{(invoice.amounts?.taxExclusiveAmount.text + invoice.amounts?.allowanceTotalAmount.text)?.toLocaleString()} {invoice.amounts?.taxExclusiveAmount.currencyID}</span>
                                            </div>
                                            <div style={{ wordWrap: "break-word" }} className=" p-col-12 p-md-12">
                                                <label htmlFor="paymentType">{strings.discount}: </label>
                                                <span id="paymentType">{invoice.amounts?.allowanceTotalAmount.text?.toLocaleString()} {invoice.amounts?.allowanceTotalAmount.currencyID}</span>
                                            </div>
                                            <div style={{ wordWrap: "break-word" }} className=" p-col-12 p-md-12">
                                                <label htmlFor="paymentType">{strings.taxableAmount}: </label>
                                                <span id="paymentType">{invoice.amounts?.taxableAmount.text?.toLocaleString()} {invoice.amounts?.taxableAmount.currencyID}</span>
                                            </div>
                                            <div style={{ wordWrap: "break-word" }} className="p-col-12 p-md-12">
                                                <label htmlFor="paymentType">{strings.totalVat}: </label>
                                                <span id="paymentType">{invoice.amounts?.taxAmount.text?.toLocaleString()} {invoice.amounts?.taxAmount.currencyID}</span>
                                            </div>
                                            <hr />
                                            <div style={{ wordWrap: "break-word" }} className=" p-col-12 p-md-12">
                                                <label htmlFor="paymentType">{strings.taxInclAmount}: </label>
                                                <span id="paymentType">{invoice.amounts?.taxInclusiveAmount.text?.toLocaleString()} {invoice.amounts?.payableAmount.currencyID}</span>
                                            </div>
                                            <div style={{ wordWrap: "break-word" }} className=" p-col-12 p-md-12">
                                                <label htmlFor="paymentType">{strings.prepaidAmount}: </label>
                                                <span id="paymentType">{invoice.amounts?.prepaidAmount.text?.toLocaleString()} {invoice.amounts?.prepaidAmount.currencyID}</span>
                                            </div>
                                            <div style={{ wordWrap: "break-word" }} className=" p-col-12 p-md-12">
                                                <label htmlFor="paymentType">{strings.totalAmountDue}: </label>
                                                <span id="paymentType">{invoice.amounts?.payableAmount.text?.toLocaleString()} {invoice.amounts?.payableAmount.currencyID}</span>
                                            </div>
                                        </div>
                                    </div>
                                    : <div className="p-col-2 p-md-2 p-offset-10">
                                        <Message severity="info" content={strings.SaveDraftToShowTotals}></Message>
                                    </div>}

                            </div> : <Message style={{ width: '100%' }} severity="info" content={strings.Selectvendortoaddinvoicelines} />}


                        </div>
                    </div>
                    <Dialog visible={this.state.debitNoteDialog} style={{ width: '700px' }} header={strings.DebitNote} modal className="p-fluid" onHide={() => this.setState({ debitNoteDialog: false })}>

                        <div className="p-col-12">
                            <div className="card">
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-12">
                                        <b htmlFor="paymentNote">{strings.Reason}:</b>
                                        <Dropdown id="instructionNote" value={invoice.instructionNote} onChange={this.changeValue} options={this.reasons} optionLabel="name" placeholder="Select One"></Dropdown>
                                    </div>
                                    <div className="p-field p-col-12 p-md-12" style={{ textAlign: 'center' }}>
                                        <Button className="p-button-rounded p-mr-2" onClick={() => this.onSave(false, true)} >Create Debit Note</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog visible={this.state.creditNoteDialog} style={{ width: '700px' }} header={strings.CreditNote} modal className="p-fluid" onHide={() => this.setState({ creditNoteDialog: false })}>

                        <div className="p-col-12">
                            <div className="card">
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-12 p-md-12">
                                        <b htmlFor="paymentNote">{strings.Reason}:</b>
                                        <Dropdown id="instructionNote" value={invoice.instructionNote} onChange={this.changeValue} options={this.reasons} optionLabel="name" placeholder="Select One"></Dropdown>
                                        {/* <InputText id="instructionNote" value={invoice.instructionNote} onChange={this.changeValue} type="text" /> */}
                                    </div>
                                    <div className="p-field p-col-12 p-md-12" style={{ textAlign: 'center' }}>
                                        <Button className="p-button-rounded p-mr-2" onClick={() => this.onSave(false, false, true)} >{strings.createCreditNote}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog visible={this.state.addCustomer} style={{ width: '75%' }} header={strings.CustomerDetails} modal className="p-fluid" footer={this.customerDialogFooter} onHide={() => { this.setState({ addCustomer: false }) }}>
                        <CustomerForm OnHideDialog={() => { this.setState({ addCustomer: false }) }} vendor={invoice.vendor} full={true} FillData={() => this.reloadCustomers({}, false, true)} />
                    </Dialog>

                    <Dialog visible={this.state.addProduct} style={{ width: '700px' }} header={strings.ProductsDetails} modal className="p-fluid" footer={this.productDialogFooter} onHide={() => { this.setState({ addProduct: false }) }}>
                        <ProductForm OnHideDialog={() => { this.setState({ addProduct: false }) }} vendor={invoice.vendor} full={true} context={this.props.context} FillData={() => this.reloadProducts()} />
                    </Dialog>

                    <Dialog visible={this.state.addVendor} style={{ width: '75%' }} header={strings.VendorsDetails} modal className="p-fluid" footer={this.vendorDialogFooter} onHide={() => { this.setState({ addVendor: false }) }}>
                        <VendorForm OnHideDialog={() => { this.setState({ addVendor: false }) }} full={true} FillData={() => this.reload()} />
                    </Dialog>
                    <Dialog visible={this.state.addRate} style={{ width: '75%' }} modal className="p-fluid" footer={this.vendorDialogFooter} onHide={() => { this.setState({ addRate: false }) }}>
                        <CurrencyRates />
                    </Dialog>

                    <Dialog visible={this.state.deleteInvoiceDialog} style={{ width: '450px' }} header={strings.Confirm} modal footer={this.deleteInvoiceDialogFooter} onHide={() => this.setState({ deleteInvoiceDialog: false })}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {<span>{strings.Areyousureyouwanttodeletedraft}?</span>}
                        </div>
                    </Dialog>
                    <Dialog visible={this.state.emailDialog} style={{ width: '450px' }} header={strings.SendToEmail} modal footer={this.sendToEmailDialogFooter} onHide={() => this.setState({ emailDialog: false, toEmail: invoice?.customer?.email, emailSent: false })}>
                        <div className="confirmation-content">
                            {!this.state.emailSent ? <div className="p-fluid p-grid">
                                <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                                    <label htmlFor="toEmail" className="required">{strings.email}</label>
                                    <InputText id="toEmail" value={this.state.toEmail} defaultValue={this.state.invoice?.customer?.email} onChange={(e) => this.setState({ toEmail: e.target.value })} />
                                </div>
                                <div className="p-field-checkbox" style={{ padding: '10px' }}>
                                    <RadioButton inputId="checkOption3" name="option" value="1" checked={this.state.sendToType == "1"} onChange={(e) => this.setState({ sendToType: e.target.value })} />
                                    <label htmlFor="checkOption3">{strings.pdf}</label>
                                </div>

                                <div className="p-field-checkbox" style={{ padding: '10px' }}>
                                    <RadioButton inputId="checkOption3" name="option" value="2" checked={this.state.sendToType == "2"} onChange={(e) => this.setState({ sendToType: e.target.value })} />
                                    <label htmlFor="checkOption3">{strings.xml}</label>
                                </div>

                                <div className="p-field-checkbox" style={{ padding: '10px' }}>
                                    <RadioButton inputId="checkOption3" name="option" value="3" checked={this.state.sendToType == "3"} onChange={(e) => this.setState({ sendToType: e.target.value })} />
                                    <label htmlFor="checkOption3">{strings.both}</label>
                                </div>
                            </div> : <div className="p-fluid p-grid">
                                <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                                    <Message severity="success" content={strings.emailSent} />
                                </div>
                            </div>}
                        </div>
                    </Dialog>
                    <RegisterListerners context={this.props.context} invoice={invoice} updateInvoice={(inv) => this.setState({ invoice: inv })} toast={this.toast} />
                </div>
            </div>
        );
    }
}

;
