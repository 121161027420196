import { config } from 'react-transition-group';
import ApiService from './ApiService';
let api = process.env.REACT_APP_API_URL

export default class ReferralService extends ApiService {

    constructor() {
        super();
        this.path = '/referral';
    }

    async GetReferralCode() {
        try {
            let {data} = await this.Read(`${this.path}/code`);
            return data;
        } catch(e) {
            return e.response.data;
        }
    }
    async GetReferralLink() {
        try {
            let {data} = await this.Read(`${this.path}/link`);
            return data;
        } catch(e) {
            return e.response.data;
        }
    }
    async GetReferrals(limit, offset, filters) {
        try {
            let {data} = await this.Read(`${this.path}/list?limit=${limit}&offset=${offset}&${filters}`);
            return data;
        } catch(e) {
            return e.response.data;
        }
    }
    async DenyReferral(trackingId) {
        try {
            let {data} = await this.Delete(`${this.path}/${trackingId}`);
            return data;
        } catch(e) {
            return e.response.data;
        }
    }
}
