import ExpenseService from "../../service/ExpenseService";
import {addData, addError} from '../expense'

export const getExpenses = payload => async (dispatch) => {
    try {
        let data = await ExpenseService.GetExpenses(payload)
        dispatch(addData({expenses: data}))
    } catch (error) {
        dispatch(addError(error))
    }
}

export const getExpense = (payload) => async (dispatch) => {
    try {
        let data = await ExpenseService.getExpense(payload)
        dispatch(addData({expense: data}))
    } catch (error) {
        dispatch(addError(error))
    }
}

export const createExpense = (payload) => async (dispatch, state) => {
    try {
        let result = await ExpenseService.CreateExpense(payload)
        let {data, count} = state().expenses.expenses
        dispatch(addData({expenses: {data: [...data, result], count: count +1}}))
        dispatch(addData({}))
    } catch (error) {
        dispatch(addError(error))
    }
}

export const updateExpense = (payload) => async (dispatch, state) => {
    try {
        let result = await ExpenseService.UpdateExpense(payload)
        let {expenses} = state().expenses
        let newExpenses = expenses.data.map(exp => exp.uuid === result.uuid ? result : exp)
        dispatch(addData({expenses: {...expenses, data: newExpenses}}))
    } catch (error) {
        dispatch(addError(error))
    }
}

export const deleteExpense = (payload) => async (dispatch, state) => {
    try {
        let result = await ExpenseService.DeleteExpense(payload)
        let {data, count} = state().expenses.expenses
        let newState = data.filter(exp => exp.uuid !== result.uuid)
        dispatch(addData({expenses:{ data: newState, count: count -1}}))
    } catch (error) {
        dispatch(addError(error))
    }
}
