import { createSlice } from "@reduxjs/toolkit"

const order = createSlice({
    name: 'order',
    initialState: {msg:'', orders: {data:[], count: 0}, order:{}},
    reducers:{
        addDifferentOrder(state, action){
            return {...state, ...action.payload}
        },
        addMsg(state, action){
            return {...state, msg: action.payload}
        }
    }
    
})

export default order.reducer

export const {addDifferentOrder, addMsg} = order.actions