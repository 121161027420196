import {addDifferentRefund, addError} from '../SupplierRefund'
import SupplierPayment from '../../service/SupplierPaymentService'

export const createRefund = (payload) => async (dispatch, state) => {
    try {
        let result = await SupplierPayment.createPayment({...payload,isRefund: true});
        let {data, count} = state().supplierRefunds.refunds
        dispatch(addDifferentRefund({refunds: {data: [...data, result], count: count +1} }))
    } catch (error) {
        addError(dispatch(error))
    }
}

export const getRefunds = (payload) => async (dispatch) => {
    try {
        const result = await SupplierPayment.getPayments({...payload, isRefund: true})
        dispatch(addDifferentRefund({refunds: result}))
    } catch (error) {
        addError(dispatch(error))
    }
}

export const getRefund = (payload) => async (dispatch) =>{
    try {
        let result = await SupplierPayment.getPayment(payload)
        dispatch(addDifferentRefund({refund: result}))
    } catch (error) {
        addError(dispatch(error))
    }
}

export const updateRefund = (payload) => async (dispatch, state) => {
    try {
        let result = await SupplierPayment.updatePayment(payload)
        let {refunds} = state().supplierRefunds
        let newState = refunds.data.map (refund => refund.uuid === result.uuid ? result : refund)

        dispatch(addDifferentRefund({refunds: {...refunds, data: newState}}))
    } catch (error) {
        addError(dispatch(error))
    }
}

export const deleteRefund = (payload) => async (dispatch,state) => {
    try {
        let result = await SupplierPayment.deletePayment(payload)
        let {data,count} = state().supplierRefunds.refunds
        let newData = data.filter(refund => refund.uuid !== result.uuid)
        dispatch(addDifferentRefund({refunds: {data: newData, count: count -1}}))
    } catch (error) {
        addError(dispatch(error))
    }
}

