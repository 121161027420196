import React, { useRef } from "react";
import strings from "../localization";

export const defaultTableConfiguration = {
    dataKey:"uuid",
    lazy: true,
    autoLayout: true,
    // ref: React.createRef(),
    resizableColumns: true,
    paginator: true,
    currentPageReportTemplate:"Showing {first} to {last} of {totalRecords} records",
    rowsPerPageOptions: [5, 10, 25,100],
    rowHover:true,
    scrollDirection:"both",
    scrollable:false,
    responsiveLayout:"scroll",
    showGridlines: false,
    stripedRows: true,
    scrollHeight: "500px",
    className: "datatable-responsive",
    emptyMessage:strings.Norecordsfound,
    paginatorTemplate:"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
}
