import Axios from 'axios';
import qs from 'query-string';
import axiosRetry from 'axios-retry';
import SessionService from './SessionService';
import jwt_decode from "jwt-decode";

let api = process.env.REACT_APP_API_URL

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


  Axios.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      return response;
    },
    (err) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      const status = err.response?.status || 500;
      // we can handle global errors here
      switch (status) {
          // Subscription is terminated.
          case 406: {
              localStorage.setItem('inactiveSub', true);
          }
      }
      return Promise.reject(err);
    }
  );

  let lastRefresh = new SessionService().GetTokenExpDate();

  Axios.interceptors.request.use(config => {
    const retryState = config['axios-retry'] || {};

    if (retryState.retryCount > 0) {
      config.headers['x-retry'] = retryState.retryCount;
    }

    var token = new SessionService().GetToken();

    if(!config.headers['Authorization'] && token)
    {
        config.headers['Authorization'] = `Bearer ${token}`
    }

    return config;
});

 axiosRetry(Axios, { retries: 5, retryDelay: axiosRetry.exponentialDelay, retryCondition: (error)=>{
    return error.response.status == 401 || error.response.status == 403;
 }, onRetry: async (retryCount, error, requestConfig) => {
     var sessionService = new SessionService();
     let data = {
         token: sessionService.GetToken(),
         refreshToken: sessionService.GetRefreshToken(),
         deviceId: sessionService.GetDeviceId(),
         sid: sessionService.GetSubscriptionId()
     }

     var now = new Date();

     if (data.token && data.refreshToken &&  lastRefresh.getTime() <= now.getTime()) {
         sessionService.RefreshJwtToken(data).then((resData) => {
             if (resData.token && resData.refreshToken) {
                 new SessionService().PopulateSession({
                     token: resData.token,
                     refreshToken: resData.refreshToken,
                     user: resData.user,
                     sid: sessionService.GetSubscriptionId()
                 }, resData.sid);

                 lastRefresh = sessionService.GetTokenExpDate();
             }
         });
     }

 }
})



export default class ApiService {

    constructor() {

        //RBAC
        //Role based access control
        this.headers = {};
        let token = localStorage.getItem("token");
        let deviceId = localStorage.getItem("deviceId");
        this.headers = {};
        if (token != null) {
            this.headers['Authorization'] = `Bearer ${token}`;
        }

        let locale = localStorage.getItem('lang') ?? 'Ar';;

        locale = locale.charAt(0).toUpperCase() + locale.slice(1)

        this.headers['Accept-Language'] = locale;

        if(deviceId == null)
        {
            deviceId = uuidv4();
            localStorage.setItem("deviceId", deviceId);
        }

        this.headers['X-DeviceId'] = deviceId;

        this.sid = localStorage.getItem("subscription");
    }

    AddHostName(path, basePath) {
        return api+basePath+path;
    }
    GetSessionSubscriptions = ()=> {
        let user = JSON.parse(localStorage.getItem('user'));
        return user?.subscriptions;
    }

    GetActiveSubscription() {
        let subs = this.GetSessionSubscriptions();
        let current = localStorage.getItem('subscription');
        return subs?.filter(sub=>sub.uuid==current)[0];
    }

    GetActiveBranch() {
        let company = this.GetActiveCompany();
        let current = localStorage.getItem('branch');
        return company?.branches?.filter(b=>b.uuid==current)[0];
    }

    GetActiveCompany() {
        let sub = this.GetActiveSubscription();
        let current = localStorage.getItem('company');
        return sub?.companies?.filter(company=>company.uuid==current)[0];
    }

    async Read(path, config={}) {
        //add context items here
        let context = {
            branchId: this.GetActiveBranch()?.uuid??'',
            vendorId: this.GetActiveCompany()?.uuid??'',
            sid: this.GetActiveSubscription()?.uuid??''
        }
        return Axios.get(api + path +`${path.includes("?")?'&':'?'}${qs.stringify(context)}`, {
            headers: {...this.headers} ,
            ...config
        });
    }

    Create(path, data={}, config={}) {
        return Axios.post(api + path, {
            sid: this.sid,
            ...data
        }, {
           headers:{ ...this.headers},
            ...config
        });
    }

    Update(path, data={}, config={}) {
        return Axios.put(api + path, {
            sid: this.sid,
            ...data
        }, {
           headers:{ ...this.headers},
            ...config
        });
    }

    Delete(path, config={}) {
        return Axios.delete(api + path, {
           headers:{...this.headers},
            ...config
        });

    }
}
