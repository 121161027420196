import ApiService from './ApiService';


class Issuer extends ApiService {
    constructor() {
        super();
        this.path = '/issuer'
    }
    async createIssuer(payload) {
        try {
            let { data } = await this.Create(this.path, payload)
            return data
        } catch (error) {
            return error.response
        }
    }
    async getIssuer(id){
        try {
            let { data } = await this.Read(`${this.path}/${id}`)
            return data
        } catch (error) {
            return error.response
        }
    }
    async getIssuers(payload){
        try {
            let { data } = await this.Read(`${this.path}/list`, payload)
            return data
        } catch (error) {
            return error.response
        }
    }
    async deleteIssuer(id){
        try {
            let { data } = await this.Delete(`${this.path}/${id}`)
            return data
        } catch (error) {
            return error.response
        }
    }
    async updateIssuer(id, payload){
        try {
            let { data } = await this.Update(`${this.path}/${id}`, payload)
            return data
        } catch (error) {
            return error.response
        }
    }

}

export default new Issuer()