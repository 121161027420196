import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { SplitButton } from 'primereact/splitbutton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import querystring from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import Can, { check } from '../Can';
import strings from '../localization';
import rules from '../rbac-rules';
import ExpenseService from '../service/ExpenseService';
import UserService from '../service/UserService';
import VendorService from '../service/VendorService';
import  ExpenseForm  from './ExpenseForm';
import { formatDateStringLocal } from './InvoiceDates';
import { Tooltip } from 'chart.js';
import { FileUpload } from 'primereact/fileupload';
import BulkImportService from '../service/BulkImportService';
import AuthService from '../service/AuthService';
import SubscriptionService from '../service/SubscriptionService';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import { AutoComplete } from 'primereact/autocomplete';
import ExpenseTypeService from '../service/ExpenseTypeService';
import { TableFilters } from './TableFilters';

export const Expenses = ({context}) => {
    const [expenseDialog, setExpenseDialog] = useState(false);
    const [deleteExpenseDialog, setDeleteExpenseDialog] = useState(false);
    const [expenses, setExpenses] = useState(null);
    const [expense, setExpense] = useState(null);
    const [preFilters, setPreFilters] = useState({});
    const [filters, setFilters] = useState({});
    const [totalRecords, setTotalRecords] = useState(0);
    const [vendors, setVendors] = useState(null);
    const [expenseTypes, setExpenseTypes] = useState(null);
    const [error, setError] = useState(null);
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [typingTimeout2, setTypingTimeout2] = useState(null);

    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [bulkImport, setBulkImport] = useState(null);
    const [importStatus, setImportStatus] = useState(null);
    const [importError, setImportError] = useState(null);
    const [vendorId, setVendorId] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    var {vendor, showCompany: full,signalrConnection,role} = context;


    useEffect(() => {
        onPage({ rows, page: 0,vendorID:vendor?.uuid})
    }, [localStorage.getItem('subscription')]);

    useEffect(() => {
        if(vendor != null)
        {
            setVendorId(vendor.uuid);
        }
    }, []);

    useEffect(() => {
        signalrConnection?.on('ExpensesBulkUploadStatus', (msg)=>{
            if(msg == 4)
            {
                onPage({ rows, page: 0,vendorID:vendor?.uuid})
            }
            setImportError(null)
            setImportStatus(msg)
        })
        signalrConnection?.on('ExpensesBulkUploadError', (msg)=>{
            setImportStatus(null)
            setImportError(msg)
        })
    }, [signalrConnection]);



    const openNew = () => {
        setExpenseDialog(true);
    }

    const hideDialog = () => {

        setExpenseDialog(false);

        setExpense(null);
    }

    const hideDeleteExpenseDialog = () => {
        setDeleteExpenseDialog(false);
        setExpense(null);
    }

    const hideImportFromExcelDialog = () => {
        setImportModalOpen(false);
        setBulkImport(null);
    }

    const edit = (obj) => {
        setExpense({
            ...obj,
            expenseType: obj.expenseType,
            uuid: obj.uuid
        })

        setExpenseDialog(true);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Can yes={() => <Button label={strings.New} icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />}
                    perform={'expenses:create'}
                    silent={true}
                    role={role} />
                {/* <Can yes={() => <Button disabled={importStatus != null && importStatus != 4} label={strings.ImportFromExcel} icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openImportModal} />}
                    perform={'expenses:importBulk'}
                    silent={true}
                    role={role} /> */}
                {/* { importStatus != null && <> {importStatus != 4 && <ProgressSpinner />} <Message severity={importStatus == 4 ? "success" : "info"} content={importStatus == 1 ? strings.ExpensesBulkUploadInit : (importStatus == 2 ? strings.ExpensesBulkUploadInProgress : strings.ExpensesBulkUploadSuccessfull)} /></>}
                {importError && <Message severity="error" content={importError}></Message>} */}
            </React.Fragment>
        )
    }



    const expenseNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{strings.referenceNumber}</span>
                {rowData.referenceNumber}
            </>
        );
    }




    const vendorBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Vendor</span>
                {rowData.vendor?.name}
            </>
        );
    }

    const barcodeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {rowData.amount} {rowData.currency}
            </>
        );
    }

    const deleteExpense = () => {
        const expenseService = new ExpenseService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        expenseService.DeleteExpense(expense.uuid).then((data) => {
            if (data.errorCode) {
                toast.current.show({ severity: 'error', summary: 'Cannot Delete Expense', detail: data.message, life: 3000 });
                setLoading(false);
                hideDeleteExpenseDialog();
                return;
            }
            toast.current.show({ severity: 'success', summary: 'Delete Expense', detail: `Deleted ${expense.name} successfully`, life: 3000 });
            expenseService.GetExpenses(rows, (page) * rows).then(({ data, count }) => {
                setLoading(false);
                setExpenses(data);
                setTotalRecords(count);
            });
        }).finally(() => {
            hideDeleteExpenseDialog();
        }).catch(() => setLoading(false));
        setExpense(null);
    }
    const deleteExpenseDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteExpenseDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteExpense} />
        </>
    );

    const creationDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date</span>
                {formatDateStringLocal(rowData.expenseDate)}
            </>
        );
    }
    const expenseTypeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Expense Type</span>
                {rowData.expenseType?.label}
            </>
        );
    }

    const confirmDeleteExpense = (expense) => {

        setDeleteExpenseDialog(true);
        setExpense(expense);
    }

    const actionBodyTemplate = (rowData) => {
        const items = []

        var userService = new UserService();
        var role = userService.GetRole();

        if (check(rules, role, 'expenses:update')) {
            items.push({ label: strings.edit, icon: 'pi pi-pencil', command:()=>edit(rowData) });
        }

        if (check(rules, role, 'expenses:delete')) {
            items.push({ label: strings.deleteExpense, icon: 'pi pi-trash', command:()=>confirmDeleteExpense(rowData) });
        }

        items.push({ label: strings.copyId, icon: 'pi pi-copy', command:()=>  navigator.clipboard.writeText(rowData.uuid) });
        if(role=='super_admin') {
            items.push({ label: "Copy SID", icon: 'pi pi-copy', command:(e)=>  navigator.clipboard.writeText(rowData.sid) });
        }

        let first = items.shift();

        return first ? <SplitButton label={first.label} icon={first.icon} onClick={()=>first.command()} model={items}></SplitButton> : ''

        // return (
        //     <div className="actions">
        //         <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => edit(rowData)} />
        //         <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteExpense(rowData)} />
        //         {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteExpense(rowData)} /> */}
        //     </div>
        // );
    }

    function changeVendorSearchBar (e) {
        if (typingTimeout2) {
            clearTimeout(typingTimeout2);
        }

        setTypingTimeout2(setTimeout(()=> {

            var vendorService = new VendorService();
            vendorService.GetVendors(100, 0, {simple:true, name:e.query}).then((data) => {
                if (!data.data) return [];
                return data.data.map(v => {
                    return { code: v.uuid, name: v.name, ...v }
                });
            }).then(data => {
                setVendors(
                    [{ code: null, name: '-' }, ...data]);
            });
        }, 200))

    }

    const onPage = ({ first,
        rows,
        page }) => {
        const expenseService = new ExpenseService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        expenseService.GetExpenses(rows, (page) * rows, vendor?.uuid??'').then(({ data, count }) => {
            setLoading(false);
            setExpenses(data);
            setTotalRecords(count);
        });

        setExpense(null);

    }

    const onFiltersChange = (e) => {
        let filters = { ...preFilters };
        filters[e.target.id] = e.target.value;
        setPreFilters(filters);
    }

    function formatDate(date) {
        if (!date) return null;
        return new Date(moment(date).format('yyyy-MM-DD HH:mm:ss'))
    }

    useEffect(() => {
        if(vendors == null)
        {
            var vendorService = new VendorService();
            if (full) {
                vendorService.GetVendors(500).then((data) => {
                    if (!data.data) {
                        return [{ code: null, name: '-' }]
                    }
                    return data.data.map(v => {
                        return { code: v.uuid, name: v.name, ...v }
                    });
                }).then(data => {
                    setVendors(
                        [{ code: null, name: '-' }, ...data]);
                });
            }
        }
    }, []);

    useEffect(() => {
        if (expenseTypes == null) {
            var expenseTypeService = new ExpenseTypeService();
            expenseTypeService.GetExpenseTypes(500).then((data) => {
                if (!data.data) {
                    return [{ code: null, name: '-' }]
                }
                return data.data.map(v => {
                    return { code: v.uuid, name: v.label, ...v }
                });
            }).then(data => {
                setExpenseTypes(
                    [{ code: null, name: '-' }, ...data]);
            });
        }
    }, []);

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            const expenseService = new ExpenseService();
            setLoading(true);
            expenseService.GetExpenses(rows, 0, filters).then(({ data, count }) => {
                setTotalRecords(count);
                setLoading(false);
                setExpenses(data)
            });
        }
    }, [filters]);

    const header = (

        <div className="p-grid">

            <div className="p-col">

                <div className="table-header">

                    <h5 className="p-m-0">{strings.ManageExpenses}</h5>
                </div>

            </div>

            <div className="p-col-12">

                <TableFilters>

                    <div className="p-fluid p-grid">

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.referenceNumber}</label>

                            <InputText id="referenceNumber" value={preFilters.referenceNumber} onChange={onFiltersChange} />

                        </div>
                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.Name}</label>

                            <InputText id="name" value={preFilters.name} onChange={onFiltersChange} />

                        </div>

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.amountFrom}</label>
                            <InputText type="number" id="amountGreaterThan" value={preFilters.amountGreaterThan} onChange={onFiltersChange} />
                        </div>

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.amountTo}</label>
                            <InputText type="number" id="amountLessThan" value={preFilters.amountLessThan} onChange={onFiltersChange} />
                        </div>

                        {full && <div className="p-field p-col-4" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.Company}</label>
                            <AutoComplete dropdown placeholder={strings.filterSearchPlaceHolder} style={{width:"100%"}} id="vendor" value={preFilters.vendor} suggestions={vendors} field="name" completeMethod={changeVendorSearchBar} onChange={(onFiltersChange)} />
                            {/* <InputText id="companyName" value={preFilters.companyName} onChange={onFiltersChange} /> */}
                        </div>}

                        {<div className="p-field p-col-3" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.ExpenseType}</label>
                            <Dropdown style={{ width: '100%' }} id="expenseType" value={preFilters.expenseType} onChange={onFiltersChange} options={expenseTypes} optionLabel="name" ></Dropdown>
                            {/* <InputText id="companyName" value={preFilters.companyName} onChange={onFiltersChange} /> */}
                        </div>}

                        <div className={`p-field p-col-3"`} style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.dateFrom}</label>
                            <Calendar id="expenseDateAfter" showTime={true} showSeconds={true} showIcon showButtonBar value={formatDate(preFilters.expenseDateAfter)} onChange={onFiltersChange} />
                        </div>

                        <div className={`p-field p-col-3"`} style={{ margin: 'auto' }}>
                        <label htmlFor="calendar">{strings.dateTo}</label>
                                <Calendar id="expenseDateBefore" showTime={true} showSeconds={true} showIcon showButtonBar value={formatDate(preFilters.expenseDateBefore)} onChange={onFiltersChange} />
                        </div>

                        <div className="p-field p-col" style={{ margin: 'auto' }}>
                        <hr/>

                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Filter} icon="pi pi-search" className="p-button-success p-mr-1 p-mb-1" onClick={() => {
                                let f = { ...preFilters }
                                // 2021-09-21T21:50:37.000Z
                                setFilters({
                                    expenseDateAfter: f.expenseDateAfter ? moment(f.expenseDateAfter).utc().format() : null,
                                    expenseDateBefore: f.expenseDateBefore ? moment(f.expenseDateBefore).utc().format() : null,
                                    referenceNumber: f.referenceNumber,
                                    amountGreaterThan: f.amountGreaterThan,
                                    amountLessThan: f.amountLessThan,
                                    expenseTypeId: f.expenseType?.code,
                                    vendorId: f.vendor?.code
                                });
                            }} />
                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Clear} icon="pi pi-times" className="p-button-warning p-mr-1 p-mb-1" onClick={() => {
                                setPreFilters({});
                            }} />
                        </div>
                    </div>
                </TableFilters>


            </div>
        </div>
    );

    function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }


    const expenseDialogFooter = (
        <>
            <Button label={strings.Cancel} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} ></Toolbar>
                    {importStatus != null && <> {importStatus != 4 && <ProgressSpinner />} <Message severity={importStatus == 4 ? "success" : "info"} content={importStatus == 1 ? strings.ExpensesBulkUploadInit : (importStatus == 2 ? strings.ExpensesBulkUploadInProgress : strings.ExpensesBulkUploadSuccessfull)} /></>}
                    {importError && <Message severity="error" content={importError}></Message>}

                    <DataTable ref={dt}
                        value={expenses}
                        dataKey="id"
                        totalRecords={totalRecords}
                        lazy
                        autoLayout
                        first={first}
                        loading={loading}
                        onPage={onPage}
                        paginator rows={rows}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Vendors"
                        globalFilter={globalFilter}
                        emptyMessage={strings.NoVendorsFound}
                        header={header}>
                        <Column field="referenceNumber" header={strings.referenceNumber} body={expenseNumberBodyTemplate}></Column>

                        {/* <Column field="peppol" header={strings.PEPPOL} body={skuBodyTemplate}></Column> */}
                        <Column field="expenseType" header={strings.ExpenseType} body={expenseTypeBodyTemplate}></Column>
                        {full && <Column field="vendor" header={strings.Vendor} body={vendorBodyTemplate}></Column>}
                        <Column field="amount" header={strings.amount} body={barcodeBodyTemplate}></Column>
                        <Column field="expenseDate" header={strings.date} body={creationDateBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={expenseDialog} style={{ width: '700px' }} header="Expenses Details" modal className="p-fluid" footer={expenseDialogFooter} onHide={hideDialog}>
                        <ExpenseForm context={context} OnHideDialog={hideDialog} expense={expense} FillData={() => onPage({ rows, page: 0, vendorID: vendor?.uuid })} />
                    </Dialog>

                    <Dialog visible={deleteExpenseDialog} style={{ width: '450px' }} header={strings.deleteExpense} modal footer={deleteExpenseDialogFooter} onHide={hideDeleteExpenseDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {<span>Are you sure you want to delete <b>{expense?.name}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
