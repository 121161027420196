import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { getInvoiceHandler } from '../../store/invoices';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import strings from '../../localization/'
import { InputNumber } from 'primereact/inputnumber';
import { getPaymentTypes } from '../../store/actions/SalesPayment'


export const RefundDialog = ({type,mode, visible, onHide, item, createRefund, getPaymentTypes, updateRefund }) => {
    const [_item, setItem] = useState({})
    const [refund, setRefund] = useState({ amount: 0, paymentTypeId: '', paymentDate: '' })
    const { paymentTypes: { data: paymentTypes } } = useSelector(state => state.payments)
    const submitHandler = () =>{
        mode === 'edit' ? updateRefund({...refund, uuid: item.uuid}).then(() => onHide()) : createRefund(refund).then(() => onHide()) 
    }
    const footer = (
        <div>
            <Button label={strings.Yes} icon="pi pi-check" onClick={submitHandler} />
            <Button label={strings.No} icon="pi pi-times" onClick={onHide} />
        </div>
    );
   
    const onNumberChange = (e) => {
        let x = { ...refund }
        x['amount'] = e.value;

        setRefund(x)
    }
    const onChange = (e) => {
        let x = { ...refund }
        x[e.target.id] = e.target.value;
        setRefund(x)
    }
    useEffect(() => {
     
        setRefund(x => { return { vendorId: item?.vendor?.uuid, customerId: item?.customer?.uuid, supplierId: item?.supplier?.uuid, ...x } })
        Promise.all([getPaymentTypes()])
    }, [item])
    useEffect(() => {
        setRefund(x => { return { ...x, vendorId: item?.vendor?.uuid, customerId: item?.customer?.uuid ,supplierId: item?.supplier?.uuid} })
    }, [item?.vendor?.uuid, item?.customer?.uuid,item?.supplier?.uuid])
    const updateRefundProduct = (e, product, field) => {
        let x = { ...product }
        x[field] = e.target.value
        let newRefund = {}
        newRefund.products = refund.products.map(product => {
            if (product.uuid === x.uuid) return x
            return product
        })
        setRefund(() => newRefund)
    }
    const priceBody = (e, field) => {
        return <InputText type='number' id={field.id} onChange={q => updateRefundProduct(q, e, field)} value={e[field]} style={{ width: '50%' }} />
    }

    return (
        <Dialog visible={visible} footer={footer} onHide={onHide} style={{ maxWidth: '70vw' }}>
            <div className="p-grid p-col-12 p-md-12 p-justify-center">
                <div className="p-grid p-col-12 p-md-12 p-justify-center p-2rem card">
                    <div className="p-col-4 p-md-4 p-grid p-justify-center ">
                        <span>{strings.Vendor}</span>
                        <div className="p-col-12 p-md-12 p-grid p-justify-center">

                            <h6>{item?.vendor?.name}</h6>
                        </div>
                    </div>
                    {type === 'purchase' ?
                        <div className="p-col-4 p-md-4 p-grid p-justify-center ">
                            <span >{strings.supplier}</span>
                            <div className="p-col-12 p-md-12 p-grid p-justify-center">
                                <h6 >{item?.supplier?.name}</h6>
                            </div>
                        </div> :
                        <div className="p-col-4 p-md-4 p-grid p-justify-center ">
                            <span >{strings.Customer}</span>
                            <div className="p-col-12 p-md-12 p-grid p-justify-center">
                                <h6 >{item?.customer?.registrationName}</h6>
                            </div>
                        </div>}
                    <div className="p-col-4 p-md-4 p-grid p-justify-center  ">
                        <span>{strings.amount}</span>
                        <div className="p-col-12 p-md-12 p-grid p-justify-center">

                            <h6>{item?.amount}</h6>
                        </div>
                    </div>
                </div>
                <div className="p-grid p-col-12 p-md-12 p-justify-center p-2rem">
                    <div className="p-col-4 p-md-4 p-grid p-justify-center">
                        <Calendar placeholder='select date' id='paymentDate' onChange={onChange} showIcon />
                    </div>
                    <div className="p-col-4 p-md-4 p-grid p-justify-center">
                        <Dropdown style={{ width: '70%' }} value={paymentTypes.find(x => x.uuid === refund.paymentTypeId)} options={paymentTypes} id='paymentTypeId' onChange={e => setRefund(x => { return { ...x, paymentTypeId: e.target.value.uuid } })} optionLabel="name" placeholder="Select Payment Method" required />
                    </div>
                    <div className="p-col-4 p-md-4 p-grid p-justify-center">
                        <InputNumber max={item?.amount} value={item?.amount} placeholder='refund amount' id='amount' onChange={onNumberChange} />
                    </div>
                </div>
                {_item?.invoiceProducts?.length > 0 && <DataTable value={refund.products}>
                    <Column style={{ width: '25%', textAlign: 'center' }} header="product name" field='product.name'></Column>
                    <Column style={{ width: '20%', textAlign: 'center' }} field="price" header='price' body={e => priceBody(e, 'price')}></Column>
                    <Column style={{ width: '20%', textAlign: 'center', overview: 'center' }} field="quantity" header='quantity' body={e => priceBody(e, 'quantity')}></Column>
                    <Column style={{ width: '20%', textAlign: 'center' }} header='refund amount' body={(e) => <span >{e.price * e.quantity}</span>}></Column>
                </DataTable>}

            </div>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    invoice: state.invoices.invoice
})

const mapDispatchToProps = { getInvoiceHandler, getPaymentTypes }

export default connect(mapStateToProps, mapDispatchToProps)(RefundDialog)