import React from 'react';
import strings from '../localization';
import PlansDisplay from '../PlansDisplay';
import { Message } from 'primereact/message';
import { Card } from 'primereact/card';

const InactiveSubscription = (props) => {
    console.log('InactiveSubscription ....')
    console.log(props)
    return (
        <div className="p-grid">
            <div className="p-md-12 p-col-12" style={{ textAlign: 'center' }}>
                <Message style={{ width: '100%', fontSize: '28px' }} severity="error" content={strings.subscriptionInactive}></Message>
                <Card>
                    <PlansDisplay {...props}/>
                </Card>
            </div>
        </div>
    )

}

export default InactiveSubscription;
