import React, {useRef, useEffect} from 'react'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import strings from '../localization'


const DeleteDialog =({show, onDelete, data, onHide}) =>{
    const toast = useRef(null);
    const accept = (rowData) => {
        toast.current?.show({ severity: 'info', summary: strings.Confirm, detail: strings.deletedSuccessfully, life: 3000 });
        onDelete(rowData.uuid)
        onHide()
    }

    
    const confirm = (rowData) => {
        confirmDialog({
            message: strings.confirmDelete,
            header: strings.deleteConfirmation,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => accept(rowData),
            acceptLabel: strings.Yes,
            rejectLabel: strings.No,
            reject: () => onHide(),
            onHide: onHide
        });

    };
  useEffect(() => {
    show&& confirm(data)
  },[show])
    
  return (
    <div>
        <Toast ref={toast} />
    </div>
  )
}

export default DeleteDialog 