import ApiService from './ApiService';
import querystring from 'query-string';

export default class ActivityLogService extends ApiService {

    constructor() {
        super();
        this.path = '/activityLogs';
    }


    async GetActivityLogs(limit = 10, offset = 0, filters={}) {
        try {
            let link = `${this.path}/list?limit=${limit}&offset=${offset}&${querystring.stringify(filters)}`
            let op = await this.Read(link);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }
}
