import ApiService from './ApiService';
import querystring from 'query-string';

class QuotationService extends ApiService {
    constructor() {
        super();
        this.path = '/quotation'
    }
    async GetQuotations(limit=10,offset=0,filters){
        try {
            let { data} = await this.Read(`${this.path}/list?limit=${limit}&offset=${offset}&${querystring.stringify(filters)}`)
            return data
        } catch (error) {
            return error.response
        }
    }
    async GetQuotation(payload){
        try {
            let { data } = await this.Read(`${this.path}/${payload}`)
            return data
        } catch (error) {
            return error.response
        }
    }
    async CreateQuotation(payload){
        try {
            let { data } = await this.Create(`${this.path}`, payload)
            return data
        } catch (error) {
            return error.response
        }
    }
    async ConvertQuotationToInvoice(payload){
        try {
            let { data } = await this.Create(`${this.path}/${payload}/invoice`, {})
            return data
        } catch (error) {
            return error.response
        }
    }
    async UpdateQuotation(payload) {
        try {
            let { data } = await this.Update(`${this.path}/${payload.uuid}`, payload)
            return data
        } catch (error) {
            return error.response
        }
    }
    async DeleteQuotation(payload) {
        try {
            let { data } = await this.Delete(`${this.path}/${payload}`)
            return data
        } catch (error) {
            return error.response
        }
    }
}

export default QuotationService;
