import { createSlice } from "@reduxjs/toolkit";
import { getItem, setItem } from '../service/helpers'
import SalesPayment from '../service/SalesPayment'
import PurchasePayment from '../service/PurchasePayment'

// let paymentsStore = getItem('payments') || []
const payments = createSlice({
    name: 'payments',
    initialState: { error: '',payments: {count: 0, data: []}, pPayments: {}, payment: {}, pPayment: {},paymentTypes:{count: 0, data: []} },
    reducers: {
        addPayment(state, action) {
            setItem('payments', [...state.payments, action.payload])
            return { ...state, payments: [...state.payments, action.payload] }
        },
        getPayment(state, action) {
            return { ...state, payment: action.payload }
        },
        updatePayment(state, action) {
            let newPayments = state.payments.map(payment => {
                if (payment.uuid === action.payload.uuid) return action.payload
                return payment
            })
             
            return { ...state, payments: newPayments }
        },
        deletePayment(state, action) {

            let newPayments = state.payments.filter(payment => payment.uuid !== action.payload.uuid)
           
            return { ...state, payments:{count: state.payments.count -1, data: newPayments} }
        },
        addDifferentPayment(state, action) {
            return { ...state, ...action.payload}
        },
        addError(state, action) {
            return { ...state, error: action.payload}
        }

    }

})

export const getPaymentsHandler = payload => async (dispatch) => {
    try {
        let response = await SalesPayment.getPayments(payload)
        dispatch(addDifferentPayment({payments: response}))
    } catch (error) {
        dispatch(addError(error))
    }

}

export const addPaymentHandler = payload => async (dispatch) =>{
    try {
        let response = await SalesPayment.createPayment(payload)
        console.log("🚀 ~ file: payments.js ~ line 46 ~ addPaymentHandler ~ response", response)
        
        dispatch(addPayment(response))
    } catch (error) {
    console.log("🚀 ~ file: payments.js ~ line 38 ~ addPaymentHandler ~ error", error)
        
    }
}
export const getPaymentHandler = payload => async (dispatch, state) => {
    try {
        let response = state().payments.payments.filter(value => value.id === Number(payload))[0]

        dispatch(getPayment(response))
    } catch (error) {
        console.log("🚀 ~ file: payments.js ~ line 21 ~ error", error)

    }
}
export const updatePaymentHandler = payload => async (dispatch) => {
    try {
        dispatch(updatePayment(payload))
    } catch (error) {
        console.log("🚀 ~ file: payments.js ~ line 34 ~ updatePaymentHandler ~ error", error)

    }
}

export const deletePaymentHandler = (payload) => async (dispatch) => {
console.log("🚀 ~ file: payments.js ~ line 54 ~ deletePaymentHandler ~ payload", payload)
    try {
        dispatch(deletePayment(payload))
    } catch (error) {
        console.log("🚀 ~ file: payments.js ~ line 57 ~ deletePaymentHandler ~ error", error)

    }
}

export const { addPayment, getPayment, updatePayment, deletePayment,addDifferentPayment,addError } = payments.actions
export default payments.reducer