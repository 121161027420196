import React, { useState, useEffect, useContext } from 'react';
import { classNames } from 'primereact/utils';
import SubscriptionService from '../service/SubscriptionService';
import UserService from '../service/UserService';
import { RTLContext } from '../Profile';
import Can from '../Can';
import moment from 'moment';
import strings from '../localization';
import { Invoices } from '../pages/Invoices';
import { getColors, PieChart } from './PieChart';
import { InvoiceReport } from '../pages/InvoiceReport';
import { SuperAdminReport } from '../pages/SuperAdminReport';
import { ProgressSpinner } from 'primereact/progressspinner';
import SessionService from '../service/SessionService';

export const Dashboard = (props) => {
    const [subscription, setSubscription] = useState(null);
    const [loading, setLoading] = useState(0);



    useEffect(() => {
        let subscriptionService = new SubscriptionService();
        setLoading(true);
        subscriptionService.GetSubscription(localStorage.getItem('subscription')).then(data => setSubscription(data)).then(setLoading(false))
    }, [localStorage.getItem('subscription')]);

    const isRTL = useContext(RTLContext)

    if(localStorage.getItem('inactiveSub') == true)
    {
        return <></>;
    }
    return (
        <div className="p-grid dashboard">
            <Can perform='invoices:metrics:read' yes={() =>
                <div className="p-col-12 p-md-5 p-lg-5">
                    {subscription &&<PieChart title={strings.Invoicesbalance} getPieData={() => {
                        const { gray, emeraldGreen } = getColors();
                        const borderColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
                        return {
                            labels: [`${strings.remaining} (${subscription?.invoicesLeft})`, `${strings.used} (${subscription?.subscriptionPlan?.itemsThreshold})`],
                            datasets: [
                                {
                                    data: [subscription?.invoicesLeft??0, subscription?.subscriptionPlan?.itemsThreshold??0 - subscription?.invoicesLeft??0 ],
                                    backgroundColor: [
                                        emeraldGreen,
                                        gray
                                    ],
                                    borderColor
                                }]
                        };
                    }} />}

                </div>
            } no={() => ''} />
            <Can perform='invoices:metrics:read' yes={() =>
                <div className="p-col-12 p-md-7 p-lg-7">

                    <Can perform='invoices:metrics:read' yes={() =>
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                <div className="p-d-flex p-ai-center muted-text">

                                    <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.SubscriptionID}</h6>
                                    <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                    </div>
                                </div>
                                <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                    <div className="p-d-flex p-flex-column">
                                        <span className="p-mb-1 fs-xlarge">{subscription?.uuid}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } no={() => ''} />


                    <Can perform='invoices:metrics:read' yes={() =>
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                <div className="p-d-flex p-ai-center muted-text">

                                    <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.CurrentSubscriptionPlan}</h6>
                                </div>
                                <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                    <div className="p-d-flex p-flex-column">
                                        <span className="p-mb-1 fs-xlarge">{subscription?.subscriptionPlan?.title}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } no={() => ''} />

                    <Can perform='invoices:metrics:read' yes={() =>
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                <div className="p-d-flex p-ai-center muted-text">

                                    <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.Enddate}</h6>
                                    <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                    </div>
                                </div>
                                <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                    <div className="p-d-flex p-flex-column">
                                        <span className="p-mb-1 fs-xlarge">{moment(subscription?.end).format('yyyy-MM-DD HH:mm:ss')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } no={() => ''} />
                    <Can perform='invoices:metrics:read' yes={() =>
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                <div className="p-d-flex p-ai-center muted-text">

                                    <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.lastLogin}</h6>
                                    <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                    </div>
                                </div>
                                <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                    <div className="p-d-flex p-flex-column">
                                        <span className="p-mb-1 fs-xlarge">{moment(new SessionService().GetTokenIssueDate()).format('yyyy-MM-DD HH:mm:ss')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } no={() => ''} />

                </div>
            } no={() => ''} />

            <Can perform='invoices:metrics:read' yes={() =>
                <div className="p-col-12 p-md-12 p-lg-12">
                    <InvoiceReport />
                </div>
            } no={() => ''} />

            <Can perform='invoices:metrics:read' yes={() =>
                <div className="p-col-12 p-md-12 p-lg-12">
                    <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                        <div className="p-d-flex p-ai-center muted-text">

                            <h3 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >Last 10 invoices</h3>
                        </div>
                        <Invoices context={props.context} summary={true} />
                    </div>
                </div>
            } no={() => ''} />


            <Can perform='subscriptions:read' yes={() =>
                <div className="p-col-12 p-md-12 p-lg-12">
                    <SuperAdminReport />
                </div>
            } no={() => ''} />

        </div >
    )
}
