import React, { useEffect, useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import strings from '../localization'
import { CustomerForm } from '../pages/CustomerForm';
import { useSelector, connect } from 'react-redux'
import { Button } from "primereact/button";
import { AutoComplete } from 'primereact/autocomplete';
import { Message } from 'primereact/message';
import { Dialog } from 'primereact/dialog';
import Can, { check } from '../Can'
import { getCustomers } from '../store/customers'

const CustomerSelector = ({ visible = true, item = {}, getCustomers, vendor, onChange ,value}) => {
    const _customers = useSelector(state => state.customers)
    const { role } = useSelector(state => state.user)
    const [customersLoading, setCustomersLoading] = useState(false)
    const [addCustomer, setAddCustomer] = useState(false)
    const [_item, setItem] = useState({})
    const [loading, setLoading] = useState({ typingTimeout: null, customerName: null })
    const [customers, setCustomers] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState({})

    const customerDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { setAddCustomer(false) }} />
        </>
    );
    const changeCustomerSearchBar = (e) => {
        if (loading.typingTimeout) {
            clearTimeout(loading.typingTimeout);
        }
        setLoading({
            ...loading, customerName: e.query, typingTimeout2: setTimeout(() => {
                reloadCustomers({ registrationName: e.query, simple: true }, true)
                console.log('customer')
            }, 200)
        })
        // this.setState({
        //     customerName: e.query,
        //     typingTimeout2: setTimeout(() => {
        //         this.reloadCustomers({ registrationName: e.query, simple: true }, true)
        //     }, 200)
        // });

    }
    const changeValue = (e, callback = () => { }) => {
        let i = { ..._item };
        if (e.target.id == 'includeRetention' || e.target.id == 'includePrepaid') {
            i[e.target.id] = !e.target.value;
        }
        else i[e.target.id] = e.target.value;
        console.log("🚀 ~ file: CustomerSelectorAndAdd.jsx ~ line 51 ~ changeValue ~  e.target.value",  e.target.value)

        // this.setState({ invoice: i }, callback);
        setItem(i)
        setSelectedCustomer(e.target.value)
    }
    useEffect(() => {
        item?.uuid && setItem(item)
    }, [item])
    useEffect(() => {
        vendor?.uuid && getCustomers(vendor.uuid)
    }, [vendor])
    const reloadCustomers = (filters = { simple: true }, skipDefault = false, newCustomer = false) => {
        if (!skipDefault) setCustomersLoading(true);
        let invoice = { ..._item };
        getCustomers(vendor.uuid, 10, 0, filters)
        // var customerService = new CustomerService();
        // customerService.GetCustomers(100, 0, invoice.vendor?.code, filters).then(data => {
        //     if (!data.data) {
        //         return { code: null, name: '-' }
        //     }
        //     return data.data.map(v => {
        //         return { code: v.uuid, name: v.registrationName, ...v }
        //     });
        // }).then(data => {
        //     this.setState({ customers: data });
        // })
        //     .then(() => {
        //         if (skipDefault) return;
        //         let invoice = { ...this.state.invoice };
        //         if (newCustomer) {
        //             invoice.customer = this.state.customers[0];
        //         }
        //         else {
        //             invoice.customer = {
        //                 ...invoice.customer,
        //                 name: invoice.customer.registrationName,
        //                 code: invoice.customer.uuid
        //             };
        //         }
        //         this.setState({ invoice })
        //     }).catch(() => {
        //         this.setState({ customers: [] });
        //     }).finally(() => {
        //         let invoice = { ...this.state.invoice };
        //         this.setState({ invoice })
        //         if (!skipDefault) this.setState({ customersLoading: false });
        //     })
    }
    useEffect(() => {
        setCustomers(x=> _customers.map(value =>{return {code: value.uuid, name: value.registrationName, ...value}}))
    },[_customers])

    useEffect(() => {
        selectedCustomer && onChange(selectedCustomer)
    }, [selectedCustomer])
   
    return (
        <>
            <Dialog visible={addCustomer} style={{ width: '75%' }} header={strings.CustomerDetails} modal className="p-fluid" footer={customerDialogFooter} onHide={() => { setAddCustomer(false) }}>
                <CustomerForm OnHideDialog={() => { setAddCustomer(false) }} vendor={item.vendor} full={true} FillData={() => console.log('reload')} />
            </Dialog>
            {customersLoading ? <div style={{ textAlign: 'center' }}><ProgressSpinner /></div> : (<>
                {!vendor?.uuid ? <div className={`p-field p-col-12 p-md-12`} style={{ textAlign: 'center' }}><Message style={{ width: '100%', marginBottom: '20px', padding: '20px' }} severity="info" content={strings.selectVendorsToShowCustomers} /></div> :
                    <div className={`p-field p-col-12 p-md-12`} style={{ textAlign: 'center' }}>
                        <b>{strings.Customers}:</b>
                        {true ?

                            <div style={{ display: 'flex', paddingTop: '10px' }}>
                                <AutoComplete dropdown placeholder={strings.filterSearchPlaceHolder} style={{ width: "100%" }} id="customer" value={item.customer??value??''} suggestions={customers} field="name" completeMethod={changeCustomerSearchBar} onChange={changeValue} />

                                <Can yes={() => <Button icon="pi pi-plus" type='button' tooltipOptions={{
                                    position: 'top'
                                }} tooltip="Create Customer" onClick={() => { setAddCustomer(true) }}></Button>}
                                    perform={'customers:create'}
                                    silent={true}
                                    role={role} />
                            </div>
                            : <p>{item.customer?.name}</p>}

                        <span style={{ textAlign: 'left' }}>{strings.VatNumber}: <b>{item.customer?.vatNumber ?? '-'}</b></span>

                    </div>}
            </>)}
        </>
    )
}

export default connect(null, { getCustomers })(CustomerSelector)