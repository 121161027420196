import { createSlice } from "@reduxjs/toolkit"
import VendorService from '../service/VendorService';
import SubscriptionService from "../service/SubscriptionService";
import { getItem, setItem, id } from '../service/helpers'
import {_SupplierService} from '../service/SupplierService';

const SP = new SubscriptionService()



const suppliers = createSlice({
    name: 'suppliers',
    initialState: { count: 0, suppliers: [], activeVendor: {} },
    reducers: {
        addVendors(state, action) {
            return { ...state, count: action.payload.count, suppliers: action.payload.data }
        },
        addSupplier(state, action) {
           
            return { ...state, suppliers: [...state.suppliers, action.payload] }
        },
        addSuppliers(state, action) {
            return { ...state, Suppliers: action.payload }
        },
        updateVendor(state, action) {
            let newState = state.Suppliers.map(supplier => {
                if (supplier.uuid === action.payload.uuid) return action.payload
                return supplier
            })
            setItem('Suppliers', newState)
            return { ...state, Suppliers: newState }
        },
        deleteSupplier(state, action) {
            let newState = state.Suppliers.filter(supplier => supplier.uuid !== action.payload.uuid)
            setItem('Suppliers', newState)
            return { ...state, Suppliers: newState }
        },
        setActiveVendor(state, action) {
            return { ...state, activeVendor: action.payload }
        }
    }
})

export const getSuppliers = () => async (dispatch) => {
    try {

        let { count, data } = await _SupplierService.GetSuppliers()
        dispatch(addVendors({ count: count, data: data }))
        return { errorCode: null, errors: null }
    } catch (error) {
        return { errorCode: error.errorCode, errors: error.errors }
    }

}

export const addSupplierHandler = payload => async (dispatch) => {
    try {
        dispatch(addSupplier({ ...payload, uuid: id(), name: payload.nameen }))
    } catch (error) {
        console.log("🚀 ~ file: suppliers.js ~ line 31 ~ addSupplier ~ error", error)

    }
}

export const addSuppliersHandler = (payload) => async dispatch => {
    try {
        dispatch(addSuppliers(getItem('Suppliers')))
    } catch (error) {
        console.log("🚀 ~ file: suppliers.js ~ line 46 ~ error", error)

    }
}

export const updateSuppliersHandler = payload => async (dispatch) => {
    try {
        dispatch(updateVendor(payload));
    } catch (error) {
        console.log("🚀 ~ file: suppliers.js ~ line 58 ~ updateSuppliersHandler ~ error", error)

    }
}

export const deleteSuppliersHandler = payload => async (dispatch) => {
    try {
        dispatch(deleteSupplier(payload))
    } catch (error) {
        console.log("🚀 ~ file: suppliers.js ~ line 74 ~ deleteSuppliersHandler ~ error", error)

    }
}

export const getActiveVendor = () => async (dispatch) => {
    console.log("🚀 ~ file: suppliers.js ~ line 91 ~ getActiveVendor ~ supplier", 'you are here')
    try {
        let supplier = await SP.GetActiveCompany()
        dispatch(setActiveVendor(supplier))
    } catch (error) {
        console.log("🚀 ~ file: suppliers.js ~ line 89 ~ getActiveVendor ~ error", error)

    }
}


export const { addVendors, addSupplier, addSuppliers, updateVendor, deleteSupplier, setActiveVendor } = suppliers.actions

export default suppliers.reducer

