import React, { useEffect, useState, useContext } from 'react';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import strings from './localization';
import { createSubscription, getPlansHandler, getSubscriptionHandler, updateSubscriptionHandler, renewSubscription, cancelSubscription } from './store/actions/subscriptionAcrions';
import { connect, useSelector } from 'react-redux';
import { GoSell } from "@tap-payments/gosell";
import ReCAPTCHA from "react-google-recaptcha";
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RTLContext } from './Profile';
import {useHistory} from 'react-router-dom'
////////////////////////////////////////////////////



export function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

const RenewSubscription = ({ getPlansHandler, getSubscriptionHandler, updateSubscriptionHandler, cancelSubscription }) => {
    const header = (
        <div />
    );

    const history =  useHistory();
    const [freq, setFreq] = useState(2)
    const { plan } = useSelector(state => state.subscriptions)
    const [showCaptcha, setShowCaptcha] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState({})
    const [edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(true)
    const params = new URLSearchParams(window.location.search)
    const isRTL = useContext(RTLContext)
    useEffect(() => {
        Promise.all([getSubscriptionHandler(localStorage.getItem('subscription')), getPlansHandler()]).then(() => setLoading(false))
    }, [])
    useEffect(() => {
        // params.get('token') && localStorage.setItem('paymentToken', params.get('token'))
        // params.get('tap_id') && localStorage.setItem('tapId', params.get('tap_id'))
        // params.get('planId') && localStorage.setItem('planId', params.get('planId'))
        // params.get('freq') && localStorage.setItem('freq', params.get('freq'))
        // if (localStorage.getItem('tapId') && localStorage.getItem('paymentToken')) {
        //     const payload = {
        //         subscriptionInterval: Number(localStorage.getItem('freq')),
        //         tapId: localStorage.getItem('tapId'),
        //         paymentToken: localStorage.getItem('paymentToken'),
        //         userId: user.id,
        //         subscriptionPlanId: localStorage.getItem('planId'),
        //         userEmail: user.email,
        //         userName: user.name,
        //         userPhone: user.phone
        //     }
        //     localStorage.removeItem('renew')
        //     renewSubscription(payload).then(() => {
        //         console.log('Renewed sub');
        //     })

        // }
        // localStorage.removeItem('tapId') && localStorage.removeItem('paymentToken') && localStorage.removeItem('planId') && localStorage.removeItem('freq')
    }, [params])
    const footer = <span>
        <Button label={strings.renew} icon="pi pi-refresh" onClick={() => history.push('/profile/plans')} style={{ marginRight: '.25em' }} />
        {/* <Button label={strings.cancelSub} icon="pi pi-times-circle" className="p-button-danger" style={{ marginRight: '.25em' }} onClick={() => cancelHandler()} /> */}
    </span>;

    // let activateSubscription = subService.GetActiveSubscription();

    // useEffect(() => {

    //     console.log("🚀 ~ file: RenewSubscription.js ~ line 81 ~ RenewSubscription ~ plan", plan)
    // }, [plan])
    const DialogBody = () => {
        return (
            <React.Fragment>
                <div className='p-grid p-justify-center'>
                    <div className='p-col-8'>

                        <strings.checkoutBody />
                    </div>
                </div>
            </React.Fragment>

        )
    }


    const submitHandler = e => {
        e.preventDefault();
        updateSubscriptionHandler({ name: e.target.name.value }).then(() => setEdit(false))
    }
    const TitleBody = ({ plan }) => {
        return (
            <React.Fragment>
                <div className="p-grid ">
                    {edit ?
                        <div className="p-col">
                            <form onSubmit={submitHandler}>
                                <InputText defaultValue={plan.name} id='name' onChange={e => console.log(e.target.value)} />
                                <Button icon="pi pi-check" className="p-button-rounded p-button-text" aria-label="Submit" title={strings.submit} type="submit" />
                                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text" aria-label="Cancel" title={strings.Cancel} type="button" onClick={() => setEdit(false)} />
                            </form>

                        </div>
                        :
                        <div className="p-col">
                            <strong>{plan.name}</strong>
                            <Button icon="pi pi-pencil" className="p-button-rounded p-button-text" aria-label="Edit" onClick={() => setEdit(true)} title={strings.edit} />

                        </div>
                    }


                </div>
            </React.Fragment>
        )
    }
    return (
        <div >
            {loading ? <ProgressSpinner /> :
                <div className="p-grid p-justify-center">

                    {/* <div className="p-col-6">

                    <ConfirmDialog {...props} />
                    </div> */}
                    <div className="p-col-6">

                        <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                            <div className="p-d-flex p-ai-center muted-text">

                                <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.CurrentSubscriptionPlan}</h6>
                                <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                </div>
                            </div>
                            <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                <div className="p-d-flex p-flex-column">
                                    <span className="p-mb-1 fs-large">{plan?.subscriptionPlan?.title}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-6">

                        <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                            <div className="p-d-flex p-ai-center muted-text">

                                <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.SubscriptionID}</h6>
                                <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                </div>
                            </div>
                            <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                <div className="p-d-flex p-flex-column">
                                    <span className="p-mb-1 fs-large">{plan?.uuid}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-6">

                        <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                            <div className="p-d-flex p-ai-center muted-text">

                                <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.startDate}</h6>
                                <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                </div>
                            </div>
                            <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                <div className="p-d-flex p-flex-column">
                                    <span className="p-mb-1 fs-large">{new Date(plan.start).toLocaleDateString()}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-6">

                        <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                            <div className="p-d-flex p-ai-center muted-text">

                                <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.Enddate}</h6>
                                <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                </div>
                            </div>
                            <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                <div className="p-d-flex p-flex-column">
                                    <span className="p-mb-1 fs-large">{new Date(plan.end).toLocaleDateString()}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <Card title={() => <TitleBody plan={plan} />} subTitle={plan.uuid} style={{ width: '100%' }} footer={footer} header={header}>
                            <div className="plan-description" style={{ lineHeight: '1.5' }}>
                                <ul>
                                    <li><i className="lni lni-check-mark-circle"></i>{`${strings.invoicesLeft}: ${plan.invoicesLeft} `}</li>
                                    {/* <li><i className="lni lni-check-mark-circle"></i>{strings.unlimitedUsers}</li> */}

                                    {/* <li><i className="lni lni-check-mark-circle"></i>{`${strings.startDate}: ${new Date(plan.start).toLocaleDateString()}`}</li> */}
                                    <li><i className="lni lni-check-mark-circle"></i>{`${strings.expiry}: ${new Date(plan.end).toLocaleDateString()}`}</li>
                                    {/* <li><i className="lni lni-check-mark-circle"></i>{strings.dashboardAccess}</li>
                                <li><i className="lni lni-check-mark-circle"></i>{strings.unlimitedCompanies}</li>
                                <li><i className="lni lni-check-mark-circle"></i>{strings.unlimitedCustomers}</li>
                                <li><i className="lni lni-check-mark-circle"></i>{strings.unlimitedProducts}</li>
                            {plan.itemsThreshold !== -1 ? <li><small>{strings.taxExclusive}</small></li> : <li><small>&nbsp;</small></li>} */}
                                <li><i className="lni lni-check-mark-circle"></i>{strings.validSubMsg}</li>
                                </ul>
                            </div>
                            <p className="m-0" style={{ lineHeight: '1.5' }}>
                            </p>
                            {showCaptcha && <ReCAPTCHA
                                sitekey="6LcDd_wcAAAAAHf2AWegpgm7j3p4BSySKahdn0QT"
                                onChange={() => {
                                    GoSell.openPaymentPage()
                                }}
                            />}
                        </Card>
                    </div>
                    {/* {plans.map(plan => {
                let isCurrentPlan;
                let quota = `${plan.itemsThreshold * 12} ${strings.planInvoices}`;
                let price = strings.formatString(strings.startingFrom, {
                    annualPrice: plan.annualPrice
                })
                return (

                )

            })} */}

                    {/* <div className="p-xs-12 p-md-8 p-lg-6 p-xl-4 p-col-12">
                        <h4 className='m-2rem border-bottom'>{strings.plans}</h4>
                        <div className='p-grid p-justify-center'>
                            {freq === 6 && <Button className='m-2rem' label={strings.forOneYear} onClick={() => setFreq(2)} icon='pi pi-calendar' />}
                            {freq === 2 && <Button className='m-2rem' label={strings.forThreeYears} onClick={() => setFreq(6)} icon='pi pi-calendar' />}

                        </div>
                        <Accordion>
                            {React.Children.toArray(plans.map(plan =>
                                <AccordionTab header={<AccordionHeader plan={plan} />} className="p-grid">
                                    <div className="p-col-12">
                                        {freq === 2 && `${plan.annualPrice} ${strings[plan.currency]}`}
                                        {freq === 6 && `${plan.threeYearsPrice} ${strings[plan.currency]}`}

                                    </div>
                                    <div className="p-col-12">
                                        {freq === 2 && `${plan.itemsThreshold * 12} ${strings.invoicePerYear}`}
                                        {freq === 6 && `${plan.itemsThreshold * 12 * 3} ${strings.invoicePerYear}`}

                                    </div>
                                    <div className="p-col-12">
                                        {showCaptcha ? <ReCAPTCHA
                                            sitekey="6LcDd_wcAAAAAHf2AWegpgm7j3p4BSySKahdn0QT"
                                            onChange={() => {
                                                GoSell.openPaymentPage()
                                            }}
                                        /> : <Button label={strings.pickPlan} icon="pi pi-check" style={{ marginRight: '.25em' }} onClick={() => clickHandler(plan)} />}

                                    </div>
                                </AccordionTab>
                            ))}
                        </Accordion>
                    </div> */}

                </div>}
            {}

        </div>

    )



}

const mapDispatchToProps = { createSubscription, getPlansHandler, getSubscriptionHandler, updateSubscriptionHandler, renewSubscription, cancelSubscription }

export default connect(null, mapDispatchToProps)(RenewSubscription);
