import React, { useState, useEffect, Children } from 'react'
import { connect, useSelector } from 'react-redux'
import strings from './localization'
import ReCAPTCHA from "react-google-recaptcha";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { GoSell, GoSellElements } from "@tap-payments/gosell";
import { SplitButton } from 'primereact/splitbutton';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import { getCurrency } from './service/helpers';
import SubscriptionService from './service/SubscriptionService';
import { Message } from 'primereact/message';
import WalletService from './service/WalletService';

let publicKey = process.env.REACT_APP_TAP_PK;
let host = process.env.REACT_APP_HOST;
let API = process.env.REACT_APP_API_URL;

export function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}


export const PlansDisplay = (props) => {
    console.log('PlansDisplay props....')
    console.log(props)
    const [showCaptcha, setShowCaptcha] = useState(false)
    const [isOpen, setIsOpen] = useState(true)
    const [freq, setFreq] = useState(2)
    const [transaction, setTransaction] = useState(null)
    const [initGoSell, setInitGoSell] = useState(false)
    const [transactionError, setTransactionError] = useState(false)
    const user = useSelector(state => state.user)
    const [selectedPlan, setSelectedPlan] = useState({})
    const [plans, setPlans] = useState([])
    const [loading, setLoading] = useState(true)
    const [visible, setVisible] = useState(false)
    const Footer = ({ plan }) => <span>
        <Button label={strings.pickPlan} icon="pi pi-check-circle" style={{ marginRight: '.25em'}} onClick={() => setSelectedPlan(plan)} />
        {/* <Button label={strings.cancelSub} icon="pi pi-times-circle" className="p-button-danger" style={{ marginRight: '.25em' }} onClick={() => cancelHandler()} /> */}
    </span>

    useEffect(() => {
        setIsOpen(!selectedPlan.uuid)
    }, [selectedPlan])

    useEffect(() => {
        var service = new SubscriptionService();
        service
            .GetPlans()
            .then(({ data }) => {
                setPlans(data);
                setLoading(false);
            })
            .catch((e) => console.log(e));
    }, [window.location.search]);

    const style = {
        // background: "rgba(0,0,0,.3)",

        opacity: !isOpen ? "0" : "1",
        transition: "all 1s",
        visibility: !isOpen ? "hidden" : "visible",
    }
    const _style = {
        // background: "rgba(0,0,0,.3)",

        opacity: isOpen ? "0" : "1",
        transition: "all 1s",
        visibility: isOpen ? "hidden" : "visible",
    }
    const items = [
        {
            label: strings.bankTransfer,
            icon: 'pi pi-money-bill',
            command: () => setVisible(true),
        },
        {
            label: strings.selectAnother,
            icon: 'pi pi-chevron-left',
            command: () => setSelectedPlan({})
        },

    ];

    const _footer = () => {
        return (
            <React.Fragment>
                <div className="p-grid">
                    <div className="p-col-12">
                        <SplitButton label={strings.onlinePayment} icon="pi pi-credit-card" model={items} className="mr-2 mb-2" onClick={() => setShowCaptcha(true)} />
                    </div>
                </div>

            </React.Fragment>
        )
    }
    const getPrice = (plan) => {

        if (freq == 1) {
            return plan.monthlyPrice;
        } else if (freq == 2) {
            return plan.annualPrice;
        } else if (freq == 6) {
            return plan.threeYearsPrice;
        }
    }

    const getFreqDesc = (plan) => {
        if (!plan) return
        if (freq == 1) {
            return "/mo";
        } else if (freq == 2) {
            return "/yr";
        } else if (freq == 6) {
            return "/3yr";
        }
    }
    const DialogBody = () => {
        return (
            <React.Fragment>
                <div className='p-grid p-justify-center'>
                    <div className='p-col-8'>

                        <strings.checkoutBody />
                    </div>
                </div>
            </React.Fragment>

        )
    }
    const onHide = () => setVisible(false)
    const dialogFooter = <Button label={strings.close} onClick={onHide} />

    if(!props.context.platformSettings)
    {
        return <ProgressSpinner />;
    }

    if(transactionError)
    {
        return <Message content={strings.error} severity='error'></Message>
    }

    return (
        <>
            <Dialog visible={visible} onHide={onHide} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "40vw" }} header={<h4 style={{ direction: strings.direction }}>{strings.bankTransferInfo}</h4>} footer={dialogFooter}>
                <DialogBody />
            </Dialog>
            {loading ? (
                <ProgressSpinner />
            ) : (
                    <div className={!isOpen ? "p-grid p-dir-col-rev" : "p-grid"}>
                        <br />
                        <br />
                        <br />

                        <div className="p-grid p-col-12 p-justify-center" style={style}>
                            <div className="p-xs-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 plans-show">
                                <h1>Renew Subscription</h1>
                            </div>

                            {isOpen && (
                                <div className="p-xs-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 plans-show">
                                    <div className="field-radiobutton" style={{ margin: "10px" }}>
                                        <RadioButton value="2" name="freq" onChange={(e) => setFreq(2)} checked={freq == "2"} />
                                        <label style={{ marginLeft: "5px", fontSize: "20px", padding: '5px' }} htmlFor="freq">
                                            {strings.forOneYear}
                                        </label>
                                        <RadioButton value="6" name="freq" onChange={(e) => setFreq(6)} checked={freq == "6"} />
                                        <label style={{ marginLeft: "5px", fontSize: "20px" }} htmlFor="freq">
                                            {strings.forThreeYears}
                                        </label>
                                    </div>
                                </div>
                            )}

                            {isOpen &&
                                Children.toArray(
                                    plans.map((plan) => (
                                        <div className="p-xs-12 p-sm-12 p-md-6 p-lg-4 p-xl-4 plans-show">
                                            <Card
                                                style={{ width: "100%" }}
                                                footer={<Footer plan={plan} />}
                                                header={
                                                    <div>
                                                        <h1 style={{ padding: "10px" }}>{plan.title}</h1>
                                                        <h4 style={{ paddingLeft: "10px" }}>{freq === 2 ? `${plan.itemsThreshold * 12} ${strings.planInvoices}` : `${plan.itemsThreshold * 12 * 3} ${strings.planInvoices}`}</h4>
                                                    </div>
                                                }
                                            >
                                                <div className="plan-description" style={{ lineHeight: "1.5" }}>
                                                    <ul>
                                                        {/* <li><i className="lni lni-check-mark-circle"></i>{`${strings.invoicesLeft}: ${plan.invoicesLeft} `}</li> */}
                                                        {/* <li><i className="lni lni-check-mark-circle"></i>{strings.validSubMsg}</li> */}
                                                        {/* <li><i className="lni lni-check-mark-circle"></i>{`${strings.startDate}: ${new Date(plan.start).toLocaleDateString()}`}</li> */}
                                                        {/* <li><i className="lni lni-check-mark-circle"></i>{`${strings.expiry}: ${new Date(plan.end).toLocaleDateString()}`}</li> */}
                                                        <li>
                                                            <i className="lni lni-check-mark-circle"></i>
                                                            {freq === 2 && (
                                                                <p className="month priceLabel">
                                                                    <span className="price ">
                                                                        {" "}
                                                                        {plan.annualPrice} {strings[plan.currency]}
                                                                    </span>{" "}
                                                                </p>
                                                            )}
                                                            {freq === 6 && (
                                                                <p className="month priceLabel">
                                                                    <span className="price ">
                                                                        {" "}
                                                                        {plan.threeYearsPrice} {strings[plan.currency]}{" "}
                                                                    </span>{" "}
                                                                </p>
                                                            )}
                                                        </li>
                                                        <li>
                                                            <i className="lni lni-check-mark-circle"></i>
                                                            {strings.unlimitedUsers}
                                                        </li>
                                                        <li>
                                                            <i className="lni lni-check-mark-circle"></i>
                                                            {strings.dashboardAccess}
                                                        </li>
                                                        <li>
                                                            <i className="lni lni-check-mark-circle"></i>
                                                            {strings.unlimitedCompanies}
                                                        </li>
                                                        <li>
                                                            <i className="lni lni-check-mark-circle"></i>
                                                            {strings.unlimitedCustomers}
                                                        </li>
                                                        <li>
                                                            <i className="lni lni-check-mark-circle"></i>
                                                            {strings.unlimitedProducts}
                                                        </li>
                                                        {plan.itemsThreshold !== -1 ? (
                                                            <li>
                                                                <small>{strings.taxExclusive}</small>
                                                            </li>
                                                        ) : (
                                                            <li>
                                                                <small>&nbsp;</small>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                                <p className="m-0" style={{ lineHeight: "1.5" }}></p>
                                            </Card>
                                        </div>
                                    ))
                                )}
                        </div>
                        <div className="p-grid p-justify-center p-col-12" style={_style}>
                            {!isOpen && (
                                <div className="p-col-12">
                                    <Card title={selectedPlan.title} subTitle={freq === 2 ? `${selectedPlan.itemsThreshold * 12} ${strings.planInvoices}` : `${selectedPlan.itemsThreshold * 12 * 3} ${strings.planInvoices}`} style={{ width: "100%" }} footer={_footer}>
                                        <div className="plan-description" style={{ lineHeight: "1.5" }}>
                                            <ul>
                                                {/* <li><i className="lni lni-check-mark-circle"></i>{`${strings.invoicesLeft}: ${plan.invoicesLeft} `}</li> */}
                                                {/* <li><i className="lni lni-check-mark-circle"></i>{strings.validSubMsg}</li> */}
                                                {/* <li><i className="lni lni-check-mark-circle"></i>{`${strings.startDate}: ${new Date(plan.start).toLocaleDateString()}`}</li> */}
                                                {/* <li><i className="lni lni-check-mark-circle"></i>{`${strings.expiry}: ${new Date(plan.end).toLocaleDateString()}`}</li> */}
                                                <li>
                                                    <i className="lni lni-check-mark-circle"></i>
                                                    {freq === 2 && (
                                                        <p className="month priceLabel">
                                                            <span className="price ">
                                                                {" "}
                                                                {selectedPlan.annualPrice} {strings[selectedPlan.currency]}
                                                            </span>{" "}
                                                        </p>
                                                    )}
                                                    {freq === 6 && (
                                                        <p className="month priceLabel">
                                                            <span className="price ">
                                                                {" "}
                                                                {selectedPlan.threeYearsPrice} {strings[selectedPlan.currency]}{" "}
                                                            </span>{" "}
                                                        </p>
                                                    )}
                                                </li>
                                                <li>
                                                    <i className="lni lni-check-mark-circle"></i>
                                                    {strings.unlimitedUsers}
                                                </li>

                                                <li>
                                                    <i className="lni lni-check-mark-circle"></i>
                                                    {strings.dashboardAccess}
                                                </li>
                                                <li>
                                                    <i className="lni lni-check-mark-circle"></i>
                                                    {strings.unlimitedCompanies}
                                                </li>
                                                <li>
                                                    <i className="lni lni-check-mark-circle"></i>
                                                    {strings.unlimitedCustomers}
                                                </li>
                                                <li>
                                                    <i className="lni lni-check-mark-circle"></i>
                                                    {strings.unlimitedProducts}
                                                </li>
                                                {selectedPlan.itemsThreshold !== -1 ? (
                                                    <li>
                                                        <small>{strings.taxExclusive}</small>
                                                    </li>
                                                ) : (
                                                    <li>
                                                        <small>&nbsp;</small>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        <hr />

                                        {props.context?.platformSettings ? props.context?.platformSettings["WalletEnabled"].value == '1' && props.context?.wallet?.balance > 0 ? <Message text={`Wallet credit of ${props.context?.wallet?.balance} SAR will be automatically redeemed upon successful purchase`}></Message> : '' : ''}

                                        {showCaptcha && <GoSell
                                            gateway={{
                                                publicKey: publicKey,
                                                language: "en",
                                                contactInfo: true,
                                                supportedCurrencies: getCurrency(),
                                                supportedPaymentMethods: "all",
                                                saveCardOption: false,
                                                customerCards: true,
                                                notifications: "standard",
                                                backgroundImg: {
                                                    url: "imgURL",
                                                    opacity: "0.5",
                                                },
                                                callback: (e) => console.log(e),
                                                labels: {
                                                    cardNumber: "Card Number",
                                                    expirationDate: "MM/YY",
                                                    cvv: "CVV",
                                                    cardHolder: "Name on Card",
                                                    actionButton: "Pay",
                                                },
                                                style: {
                                                    base: {
                                                        color: "#535353",
                                                        lineHeight: "18px",
                                                        fontFamily: "sans-serif",
                                                        fontSmoothing: "antialiased",
                                                        fontSize: "16px",
                                                        "::placeholder": {
                                                            color: "rgba(0, 0, 0, 0.26)",
                                                            fontSize: "15px",
                                                        },
                                                    },
                                                    invalid: {
                                                        color: "red",
                                                        iconColor: "#fa755a ",
                                                    },
                                                },
                                            }}
                                            customer={{
                                                first_name: user.name,
                                                email: user.email,
                                                phone: {
                                                    country_code: "966",
                                                    number: user.phone,
                                                },
                                            }}
                                            order={{
                                                amount: (getPrice(selectedPlan) - Math.min(props.context?.wallet?.balance ?? 0.0, getPrice(selectedPlan))) * 1.15,
                                                currency: getCurrency(),
                                                items: [
                                                    {
                                                        id: 1,
                                                        name: selectedPlan.title + getFreqDesc(selectedPlan),
                                                        description: null,
                                                        quantity: "1",
                                                        amount_per_unit: getPrice(selectedPlan),
                                                        total_amount: getPrice(selectedPlan),
                                                    },
                                                    {
                                                        id: 2,
                                                        name: "Wallet Discount",
                                                        description: "Wallet Discount",
                                                        quantity: "1",
                                                        amount_per_unit: -1 * Math.min(props.context?.wallet?.balance ?? 0.0, getPrice(selectedPlan)),
                                                        total_amount: -1 * Math.min(props.context?.wallet?.balance ?? 0.0, getPrice(selectedPlan)),
                                                    },
                                                    {
                                                        id: 3,
                                                        name: "VAT",
                                                        description: "15% VAT",
                                                        quantity: "1",
                                                        amount_per_unit: (getPrice(selectedPlan) - props.context?.wallet?.balance ?? 0.0) * .15,
                                                        total_amount: (getPrice(selectedPlan) - props.context?.wallet?.balance ?? 0.0) * .15,
                                                    }
                                                ],
                                                shipping: null,
                                                taxes: null,
                                            }}
                                            transaction={{
                                                mode: "charge",
                                                charge: {
                                                    saveCard: false,
                                                    threeDSecure: true,
                                                    description: selectedPlan.description + " - " + getFreqDesc(selectedPlan),
                                                    statement_descriptor: "Sample",
                                                    reference: {
                                                        transaction: "txn_" + makeid(6),
                                                        order: "ord_" + makeid(6),
                                                    },
                                                    metadata: {},
                                                    receipt: {
                                                        email: true,
                                                        sms: false,
                                                    },
                                                    redirect: `${host}/profile/checkout?planId=${selectedPlan.uuid}&freq=${freq}&transactionId=${transaction?.transactionId??''}`,
                                                    post: `${API}/charge`,
                                                },
                                            }}
                                        />}
                                        {showCaptcha && (
                                            <>
                                        <ReCAPTCHA
                                                sitekey="6LcDd_wcAAAAAHf2AWegpgm7j3p4BSySKahdn0QT"
                                                    onChange={() => {
                                                        var amount = Math.min(props.context?.wallet?.balance ?? 0.0, getPrice(selectedPlan));
                                                        if (amount > 0) {
                                                            new WalletService().CreateWalletTransaction({
                                                                amount,
                                                                transactionType: 2
                                                            }).then((data) => {
                                                                setTransaction(data);
                                                            }).then(()=>{
                                                                setTimeout(() => {
                                                                    GoSell.openPaymentPage();
                                                                }, 2000)
                                                            }).catch(error => {
                                                                console.error(error);
                                                                setTransactionError(true);
                                                            });
                                                        }
                                                        else {
                                                            GoSell.openPaymentPage();
                                                        }

                                                    }}
                                            />
                                            </>
                                        )}
                                        
                                    </Card>
                                </div>
                            )}
                        </div>
                        
                    </div>

            )}
            {/* <Button onClick={() => setIsOpen(!isOpen)} label="Close" /> */}
            
        </>
    );
}

export default PlansDisplay;
