import ApiService from './ApiService';
import querystring from 'query-string';
let api = process.env.REACT_APP_API_URL

export default class CertificateService extends ApiService {

    constructor() {
        super();
        this.path = '/certificate';
    }

    async GetCertificateStatus(filters = {}) {
        try {
            let link = `${this.path}/status?vendorId=${filters.uuid}`
            let op = await this.Read(link);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }

    async SyncCertificates(filters = {}) {
        let link = `${this.path}/sync`
        let op = await this.Create(link, filters);
        return op.data;
    }

}
