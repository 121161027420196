import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import IdentityTypeService from '../service/IdentityTypeService';
import CustomerService from '../service/CustomerService';
import { useTheme } from 'styled-components';
import { ProgressSpinner } from 'primereact/progressspinner';
import VendorService from '../service/VendorService';
import { Message } from 'primereact/message';
import strings from '../localization';
import { countryCodes } from '../assets/country-codes';
import { getCountry } from '../service/helpers';


let types = [
    {name:strings.Company, code:1},
    {name:strings.Individual, code:2},
    {name:strings.GovEntity, code:3},
    {name:strings.exports, code:4},
]

export class CustomerForm extends Component {
    constructor(props) {
        super(props);
        let emptyCustomer = {
            namear: "",
            nameen: "",
            street1: "",
            street2: "",
            buildNo: "",
            additionalNumber: "",
            city: "",
            email:"",
            phone:"",
            postalCode: "",
            state: "",
            neighborhood: "",
            vat: "",
            vatGroup: "",
            rows: []
        }

        this.state = {
            customer: {countryCode:countryCodes[0], ...emptyCustomer},
            vendors:[],
            vendor:null,
            identityTypes: [],
            loading: false,
            errors:[],
            pageLoading: false,
        };
        this.toast = React.createRef();



    }

    componentDidMount() {
        this.FillIdentityType();
        this.FillVendors();
        if(this.props.customer != null)
        {
            // Fill Data
            this.ReturnData()
        }
        else
        {
            this.setState({
                customer: {
                    vendor: {
                        code: this.props.vendor?.uuid,
                        name: this.props.vendor?.name,
                    },
                    countryCode: countryCodes.filter(t => t.code.toLowerCase() == getCountry().toLowerCase())[0],
                }
            })
        }
    }

    ReturnData() {
        this.setState({
            customer: {
                ...this.props.customer,
                vendor: {
                    code: this.props.customer.vendor?.uuid,
                    name: this.props.customer.vendor?.name,
                },
                countryCode: countryCodes.filter(t => t.code == this.props.customer.countryCode.toLowerCase())[0],
                type: types.filter(t => t.code == this.props.customer.type)[0]
            }
        })
    }

    onEditorValueChange(productKey, props, value) {
        let updatedRow = [...props.value];
        updatedRow[props.rowIndex][props.field] = value;
        this.setState({ [`${productKey}`]: updatedRow });
    }

    onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _customer = { ...this.state.customer };
        _customer[`${name}`] = val;
        this.setState({ customer: _customer });
    }

    FillIdentityType = async () => {
        let identityTypeService = new IdentityTypeService();
        this.setState({pageLoading: true})
        var identityTypes = await identityTypeService.GetIdentityTypes();
        this.setState({ identityTypes: identityTypes.data, pageLoading: false })
    }

    FillVendors = async () => {
        let vendorService = new VendorService();
        this.setState({pageLoading: true})
        var vendors = await vendorService.GetVendors(500);
        this.setState({ vendors: vendors.data, pageLoading: false })
    }

    AddRow = () => {
        let rows = [...this.state.customer.rows ?? [], {identityId:null,name: "", value: "" }];
        let _customer = { ...this.state.customer };
        _customer.rows = rows;
        this.setState({ customer: _customer });
    }

    GetData = () => {}

     delete = (rowData) => {
        let _customer = {...this.state.customer};
        _customer.rows = _customer.rows.filter(val => val.identityId == rowData.identityId);
        this.setState({customer:_customer});
        this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    }

    nameBodyTemplate = (rowData, props) => {
        return (
            <>


                {/* <span className="p-column-title">Name</span> */}

                {/* {console.log(rowData)} */}

                <Dropdown value={props.rowData['name']} onChange={(e) => this.onEditorValueChange('name', props, e.value)} optionLabel="name" options={this.state.identityTypes?.map(o => {
                    return {
                        name: o.label,
                        value: o.uuid
                    }
                })} style={{ width: '100%' }} placeholder="Select" />
                {/* {rowData.name} */}

            </>
        );
    }

    valueBodyTemplate = (rowData, props) => {
        return (
            <>

                {/* <span className="p-column-title">Value</span> */}
                {/* {rowData.value} */}
                <InputText id='value' type="text" value={props.rowData['value']} onChange={(e) => this.onEditorValueChange('value', props, e.target.value)} required autoFocus />

            </>
        );
    }


    actionBodyTemplate = (rowData) => {

        return (
            <div className="actions">
                <Button icon="pi pi-trash" onClick={()=>this.delete(rowData)} className="p-button-rounded p-button-warning" />
            </div>
        );
    }

    save = async () => {

        let vendorId = this.props.context?.vendor?.uuid;
        if(!vendorId)
        {
            vendorId=  (this.props.vendor?.uuid)?? this.state.customer.vendor?.code
        }
        this.setState({ loading: true });
        let { customer } = this.state;
        let data={
            "vendorId": vendorId,
            "userId": this.props.customer?.uuid,
            type:customer.type?.code,
            "translations": [
            {
                "name": customer.nameen,
                "locale": 1
            },
            {
               "name": customer.namear,
                "locale": 2
            }

            ],
            "address": {
              "translations": [
                {
                  "street": customer.street1,
                  "additionalStreet": customer.street2,
                  "buildingNumber": customer.buildNo,
                  "additionalNumber": customer.additionalNumber,
                  "city": customer.city,
                  "postalCode": customer.postalCode,
                  "state": customer.state,
                  "neighborhood": customer.neighborhood,
                  "countryCode": customer.countryCode?.code ?? customer.countryCode,
                  "locale": 1
                }
              ]
            },
            "vat": customer.vat,
            "email":customer.email,
            "phone":customer.phone,
            "vatGroupNumber": customer.vatGroup,
            "identities":customer.rows?.map((row) => {
                        return {
                            "identityId": row.identityId,
                            "identityTypeId": row.name,
                            "value": row.value
                        };
                    }),
            "isActive": true

        }

        if (this.props.customer == null) {

            var customerService = new CustomerService();
            let res = await customerService.CreateCustomer(data);
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors, loading: false });
                return;
            } else
                this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Customer Created', life: 3000 });
        }
        else {
            data.uuid = this.props.customer.uuid;
            var customerService = new CustomerService();
            let res = await customerService.UpdateCustomer(data);
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors, loading: false });
                return;
            }
            else {

                this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Customer Updated', life: 3000 });
            }

        }

        this.props.OnHideDialog();
        this.props.FillData();
        this.setState({customer:{
            namear: "",
            nameen: "",
            street1: "",
            street2: "",
            buildNo: "",
            additionalNumber: "",
            city: "",
            postalCode: "",
            state: "",
            neighborhood: "",
            vat: "",
            vatGroup: "",
            rows: []
        }});

        this.setState({ loading: false });


    }


    render() {
        if(this.state.pageLoading) {
            return <div style={{textAlign:'center'}}><ProgressSpinner/></div>
        }



        const isRTL = localStorage.getItem('lang')== 'ar' ? 'rtl' : 'ltr';

        return (

            <div className="p-grid crud-demo" style={{direction:isRTL}}>
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={this.toast} />
                        <div className="p-field">
                            <label htmlFor="name" className="required"> {strings.CustomerType}</label>
                            <Dropdown id="type" value={this.state.customer.type} onChange={(e) => {
                                    let customer = { ...this.state.customer }
                                    customer['type'] = e.value;
                                    this.setState({ customer });
                            }} options={types} optionLabel="name" placeholder={strings.Select}></Dropdown>
                        </div>

                        <div className="p-field">
                            <label htmlFor="name" className="required">{strings.NameEnglish}</label>
                            <InputText id="nameen" value={this.state.customer.nameen} onChange={(e) => this.onInputChange(e, 'nameen')} required autoFocus />
                            {/* <small className="p-invalid">{JSON.stringify(this.state.user)}.</small> */}
                        </div>
                        <div className="p-field">
                            <label htmlFor="name" className="required">{strings.NameArabic}</label>
                            <InputText id="namear" value={this.state.customer.namear} onChange={(e) => this.onInputChange(e, 'namear')} required autoFocus />
                        </div>
                        <div className="p-field">
                            {this.props.full ? <label htmlFor="name">{strings.Vendor}*</label>:''}
                            {this.props.full ? <Dropdown id="vendor" disabled={this.props.vendor} value={this.state.customer.vendor} onChange={(e) => {
                                    let customer = { ...this.state.customer }
                                    customer['vendor'] = e.value;
                                    this.setState({ customer });
                            }} options={this.state.vendors.map((v)=>{
                                return {
                                    code: v.uuid,
                                    name: v.name
                                }
                            })} optionLabel="name" placeholder={strings.Select}></Dropdown>:''}
                        </div>

                        <div className="p-grid">

                            <div className="p-col-4">

                                <div className="p-field">
                                    <label htmlFor="name" className={this.state.customer.type?.code == 1 ? 'required':''}>{strings.Street1}</label>
                                    <InputText id="street1" value={this.state.customer.street1} onChange={(e) => this.onInputChange(e, 'street1')} required autoFocus />

                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.AdditionalStreet}</label>
                                    <InputText id="street2" value={this.state.customer.street2} onChange={(e) => this.onInputChange(e, 'street2')} required autoFocus />

                                </div>
                            </div>


                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name" className={this.state.customer.type?.code == 1 ? 'required':''}>{strings.BuildingNo}</label>
                                    <InputText id="buildNo" value={this.state.customer.buildNo} onChange={(e) => this.onInputChange(e, 'buildNo')} required autoFocus />

                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.AdditionalNumber}</label>
                                    <InputText id="additionalNumber" value={this.state.customer.additionalNumber} onChange={(e) => this.onInputChange(e, 'additionalNumber')} required autoFocus />

                                </div>
                            </div>
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name" className={this.state.customer.type?.code == 1 ? 'required':''}>{strings.Neighborhood}</label>
                                    <InputText id="neighborhood" value={this.state.customer.neighborhood} onChange={(e) => this.onInputChange(e, 'neighborhood')} required autoFocus />

                                </div>
                            </div>


                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name" className={this.state.customer.type?.code == 1 ? 'required':''}>{strings.City}</label>
                                    <InputText id="city" value={this.state.customer.city} onChange={(e) => this.onInputChange(e, 'city')} required autoFocus />

                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name" className={this.state.customer.type?.code == 1 ? 'required':''}>{strings.State}</label>
                                    <InputText id="state" value={this.state.customer.state} onChange={(e) => this.onInputChange(e, 'state')} required autoFocus />

                                </div>
                            </div>


                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name" className={this.state.customer.type?.code == 1 ? 'required' : ''}>{strings.Country}</label>
                                    <Dropdown id="countryCode" value={this.state.customer.countryCode??countryCodes[0]} onChange={(e) => {
                                        let customer = { ...this.state.customer }
                                        customer['countryCode'] = e.target.value;
                                        this.setState({ customer });
                                    }} options={countryCodes} filter={true} optionLabel="name" disabled={this.state.customer.type?.code!=4} placeholder={strings.Select}></Dropdown>
                                </div>
                            </div>


                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name" className={this.state.customer.type?.code == 1 ? 'required':''}>{strings.PostalCode}</label>
                                    <InputText id="postalCode" value={this.state.customer.postalCode} onChange={(e) => this.onInputChange(e, 'postalCode')} required autoFocus />

                                </div>
                            </div>

                            <div className="p-col-6">
                                <div className="p-field">
                            <label htmlFor="name" className={this.state.customer.type?.code == 1 ? 'required':''}>{strings.VatNumber}</label>
                            <InputText id="vat" value={this.state.customer.vat} onChange={(e) => this.onInputChange(e, 'vat')} required autoFocus />

                        </div>
                        </div>

                        <div className="p-col-6">
                                <div className="p-field">
                            <label htmlFor="name" >{strings.GroupVatNumber}</label>
                            <InputText id="vatGroup" value={this.state.customer.vatGroup} onChange={(e) => this.onInputChange(e, 'vatGroup')} required autoFocus />

                        </div>
                        </div>

                        </div>

                        <div className="p-grid">
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="email">{strings.email}</label>
                                    <InputText id="email" type="email" value={this.state.customer.email} onChange={(e) => this.onInputChange(e, 'email')} required autoFocus />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="phone">{strings.phone}</label>
                                    <InputText id="phone" value={this.state.customer.phone} onChange={(e) => this.onInputChange(e, 'phone')} required autoFocus />
                                </div>
                            </div>

                        </div>


                        <div className="card">
                            <div style={{ display: 'flex' }}>
                                <h4>{strings.Identities}</h4>
                                <Button icon="pi pi-plus" style={{ marginLeft: '10px', marginTop: '-7px' }} onClick={this.AddRow} className="p-button-rounded p-button-success p-mr-2" />
                                {/* <Button icon="pi pi-" style={{ marginLeft: '10px', marginTop: '-7px' }} onClick={this.GetData} className="p-button-rounded p-button-success p-mr-2" /> */}
                            </div>
                            <DataTable value={this.state.customer.rows}
                                       stripedRows
                                       emptyMessage={strings.NoVendorsFound}>

                                <Column field="name" header={strings.Name} body={this.nameBodyTemplate} ></Column>
                                <Column field="value" header={strings.Value} body={this.valueBodyTemplate}></Column>
                                <Column header={strings.Action} body={this.actionBodyTemplate}></Column>
                            </DataTable>
                        </div>
                        <div className="p-field">
                            {this.state.errors?.length > 0 && <Message text={this.state.errors.map((m) => <li>{m}</li>)} severity="error" />}
                            <Button label={strings.Save} loading={this.state.loading} onClick={this.save} loadingoptions={{ position: 'right' }} />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
