import ApiService from './ApiService';
import querystring from 'query-string';

export default class ExpenseService extends ApiService {

    constructor() {
        super();
        this.path = '/expense';
    }

    async CreateExpense(data) {
        try {
            let op = await this.Create(this.path, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }


    async UpdateExpense(data) {

        try {

            let op = await this.Update(this.path+"/"+data.uuid, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }
    async DeleteExpense(uuid) {

        try {

            let op = await this.Delete(this.path+"/"+uuid);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }


    async GetExpenses(limit = 10, offset = 0, filters = {}) {
        try {
            let link = `${this.path}/list?limit=${limit}&offset=${offset}&${querystring.stringify(filters)}`
            let op = await this.Read(link);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }

    async GetCount() {
        try {
            let link = `${this.path}/list?countOnly=true`
            let op = await this.Read(link);
            return op.data.count;
        } catch(e) {
            return e.response.data;
        }
    }
    async getExpense (payload) {
        try {
            let link = `${this.path}/${payload}`
            let op = await this.Read(link)
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }
}

// export default new ExpenseService()
