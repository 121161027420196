import { config } from 'react-transition-group';
import ApiService from './ApiService';
let api = process.env.REACT_APP_API_URL

export default class AuthService extends ApiService {

    constructor() {
        super();
        this.path = '/auth';
    }

    async DoBasicLogin(creds) {
        try {
            let {data} = await this.Create(`${this.path}/jwt/token` , creds);
            return data;
        } catch(e) {
            return e.response.data;
        }
    }
    async StartOtpRequest(method, token) {
        let { data } = await this.Create(`${this.path}/mfa/${method}`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return data;
    }
    async VerifyOtpCode(d, token) {
        let { data } = await this.Create(`${this.path}/mfa/verify/token`, d, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return data;
    }

    async RefreshJwtToken(body) {
        try {
            let {data} = await this.Update(`${this.path}/jwt/token` , body);
            return data;
        } catch(e) {
            return e.response.data;
        }
    }

    GetToken() {
        return localStorage.getItem('token');
    }

    GetRefreshToken() {
        return localStorage.getItem('refreshToken');
    }
    GetDeviceId() {
        return localStorage.getItem('deviceId');
    }

    GetSubscriptionId() {
        return localStorage.getItem('subscription');
    }
    Logout(redirect=true) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        localStorage.removeItem('subscription');
        localStorage.removeItem('inactiveSub');
        localStorage.removeItem('company');
        localStorage.removeItem('branch');
        // window.location.reload();
        if (redirect) {
            window.location.href = '/login'
        }
    }
    PopulateSession(data, subId) {
        this.Logout(false);
        localStorage.setItem('token', data.token);
        localStorage.setItem('refreshToken', data.refreshToken);
        localStorage.setItem('user', JSON.stringify(data.user));
        if(subId != null)
        {
            localStorage.setItem('subscription', subId);
        }
        if(data.branchId)
        {
            localStorage.setItem('branch', data.branchId);
        }
        if(data.vendorId)
        {
            localStorage.setItem('company', data.vendorId);
        }
    }
}
