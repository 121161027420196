import { addAction } from "../subscription";
import SubscriptionService from '../../service/SubscriptionService'
const Sub = new SubscriptionService()


export const getPlansHandler = () => async (dispatch) => {
    try {
        let { data } = await Sub.GetPlans()
        dispatch(addAction({ plans: data }))
    } catch (error) {
        dispatch(addAction({ msg: error }))
    }
}

export const getSubscriptionHandler = (id) => async (dispatch) => {
    try {
        let data = await Sub.GetSubscription(id)
        dispatch(addAction({ plan: data }))
    } catch (error) {
        dispatch(addAction({ msg: error }))
    }
}

export const updateSubscriptionHandler = (payload) => async (dispatch) => {
    try {
        let data = await Sub.UpdateSubscriptionName(payload)
        dispatch(addAction({ plan: data }))
    } catch (error) {
        dispatch(addAction({ msg: error }))
    }
}

export const createSubscription = (payload) => async (dispatch) => {
    try {
        let result = await Sub.charge(payload.tapId)
        console.log("🚀 ~ file: subscriptionAcrions.js ~ line 36 ~ createSubscription ~ result", result)
        if (result.status === 200) {
            let data = await Sub.CreateSubscription(payload)
            console.log("🚀 ~ file: subscriptionAcrions.js ~ line 39 ~ createSubscription ~ data", data)
            dispatch(addAction({ plan: data }))
        } else {
            dispatch(addAction({ msg: result }))
        }
    } catch (error) {
        dispatch(addAction({ msg: error }))
    }
}

export const renewSubscription = (payload) => async (dispatch) => {
    try {
        let data = await Sub.RenewSubscription(payload)
        dispatch(addAction({ plan: data }))
    } catch (error) {
        dispatch(addAction({ msg: error }))
    }
}

export const chargeHandler = (payload) => async (dispatch) => {
    try {

    } catch (error) {
        dispatch(addAction({ msg: error }))
    }
}

export const cancelSubscription = (payload) => async (dispatch) => {
    try {
        let result = await Sub.CancelSubscription(payload)
        dispatch(addAction({ plan: {}}))
    } catch (error) {
        dispatch(addAction({ msg: error }))
    }
}


