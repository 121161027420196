import ApiService from './ApiService';

class PurchaseOrderService extends ApiService {
    constructor() {
        super();
        this.path = '/purchaseOrder'
    }
    async getOrders(payload){
        try {
            let { data} = await this.Read(`${this.path}/list`, payload)
            return data
        } catch (error) {
            return error.response
        }
    }
    async getOrder(payload){
        try {
            let { data } = await this.Read(`${this.path}/${payload}`)
            return data
        } catch (error) {
            return error.response
        }
    }
    async createOrder(payload){
        try {
            let { data } = await this.Create(`${this.path}`, payload)
            return data
        } catch (error) {
            return error.response
        }
    }
    async updateOrder(payload) {
        try {
            let { data} = await this.Update(`${this.path}/${payload.uuid}`, payload)
            return data
        } catch (error) {
            return error.response
        }
    }
    async deleteOrder(payload) {
        try {
            let { data } = await this.Delete(`${this.path}/${payload}`)
            return data
        } catch (error) {
            return error.response
        }
    }
}

export default new PurchaseOrderService()