import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import SubscriptionService from '../service/SubscriptionService';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { SubscriptionForm } from './SubscriptionForm';
import strings from '../localization';
import querystring from 'query-string';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import { check } from '../Can';
import rules from '../rbac-rules';
import UserService from '../service/UserService';
import { SubscriptionUsers } from './SubscriptionUsers';

export const Subscriptions = () => {

    let emptyProduct = {
        id: null,
        subscriptionPlanId: '',
        name: null,
        userEmail: '',
        paymentToken: null,
        tapId: "",
        subscriptionInterval: 1,
        userPhone: "",
    };

    const [subscriptions, setSubscriptions] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [subscriptionUsersDialog, setSubscriptionUsersDialog] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState('');
    const [subscription, setSubscription] = useState(null);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [filters, setFilters] = useState({
    });
    const [preFilters, setPreFilters] = useState([])

    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const onFiltersChange = (e) => {
        let filters = { ...preFilters };
        filters[e.target.id] = e.target.value;
        setPreFilters(filters);
    }


    useEffect(() => {
        onPage({rows,page:0})
    }, []);
    useEffect(() => {
        onPage({rows,page:0, filters:querystring.stringify(filters)})
    }, [filters]);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    let statuses = [null, "Awaiting Activation",
        "Trialing",
        "Active",
        "PastDue",
        "Canceled",
        "Expired",
    ]

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    }

    const edit = (subscription) => {
        setProduct(emptyProduct);
        setSubscription(subscription)
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }





    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        // return (
        //     // <React.Fragment>
        //     //     <Button label="Export" icon="pi pi-upload" className="p-button-help"  />
        //     // </React.Fragment>
        // )
    }



    const IDBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.uuid}
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    }


    const emailBodyTemplate = (rowData) => {
        return (
            <Link to={`/profile/users?id=${rowData.user.uuid}`}>
            <span className="p-column-title">Email</span>
            {rowData.user.email}
            </Link>

        );
    }

    const endDateTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">End date</span>
                {rowData.end?moment(rowData.end).format('DD/MM/YYYY, h:mm:ss a'):''}
            </>
        );
    }
    const createdDateTemplate = (rowData) => {
        console.log(rowData.createdAt)
        return (
            <>
                <span className="p-column-title">Created At</span>
                {rowData.createdAt?moment(rowData.createdAt).format('DD/MM/YYYY, h:mm:ss a'):''}
            </>
        );
    }
    const startDateTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Start date</span>
                {rowData.start?moment(rowData.start).format('DD/MM/YYYY, h:mm:ss a'):''}
            </>
        );
    }

    const dateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date</span>
                {moment(rowData.date).format('DD/MM/YYYY, h:mm:ss a')}
            </>
        );
    }

    const Subscription_palnBodyTemplate = (rowData) => {
        return (
            <Link to={`/profile/plans?planId=${rowData.subscriptionPlan.uuid}`}>
                <span className="p-column-title">Date</span>
                {rowData.subscriptionPlan.title}
            </Link>
        );
    }


    const subscriptionStatusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${rowData.status=='6'?'failed':rowData.status}`}>{statuses[rowData.status]}</span>
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        const items = []

        var userService = new UserService();
        var role = userService.GetRole();

        if (check(rules, role, 'subscriptions:update')) {
            items.push({
                label: rowData.status == 3 ? strings.deactivate : strings.activate, icon: `pi pi-${rowData.status == 3 ? 'times' : 'check'}`, command: async () => {
                    var subscriptionService = new SubscriptionService();
                    var data = null;
                    if (rowData.status == 3) {
                        data = await subscriptionService.CancelSubscription(rowData.uuid);
                    }
                    else {
                        data = await subscriptionService.ActivateSubscription(rowData.uuid);
                    }
                    if (data.errorCode) {
                        alert(data.message);
                    } else {
                        onPage({ rows, page });
                    }
                }
            });
        }

        if (check(rules, role, 'subscriptions:update')) {
            items.push({ label: strings.edit, icon: 'pi pi-pencil', command:()=>edit(rowData) });
        }
        if (check(rules, role, 'subscriptions:update')) {
            items.push({ label: strings.subscriptionUsers, icon: 'pi pi-pencil', command:()=>{
                setSelectedSubscription(rowData.uuid)
                setSubscriptionUsersDialog(true)
            } });
        }

        let first = items.shift();

        return first ? <SplitButton label={first.label} icon={first.icon} onClick={()=>first.command()} model={items}></SplitButton> : ''

    }

    const onPage = ({ first,
        rows,
        page,
        filters,
        pageCount }) => {
        const subscriptionService = new SubscriptionService();
        setLoading(true);
        setRows(rows);
        setPage(page);
        setFirst(first)

        subscriptionService.GetSubscriptions(rows, (page) * rows, filters).then(({ data, count }) => {
            setLoading(false);
            setSubscriptions(data);
            setTotalRecords(count);
        });

    }

    const header = (
        <div className="p-grid">

            <div className="p-col">
                <div className="table-header">

                    <h5 className="p-m-0">Manage Subscriptions</h5>
                </div>
            </div>
            <div className="p-col-12">

                <div className="card">

                    <div className="p-fluid p-grid">

                        <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.ID}</label>
                            <InputText id="id" value={preFilters.id} onChange={onFiltersChange} />
                        </div>
                        <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.Name}</label>
                            <InputText id="name" value={preFilters.name} onChange={onFiltersChange} />
                        </div>

                        <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.email}</label>
                            <InputText type="email" id="email" value={preFilters.email} onChange={onFiltersChange} />

                        </div>
                        <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.Status}</label>

                            <Dropdown style={{ width: '100%' }} id="status" value={preFilters.status} onChange={onFiltersChange} options={[
                                {code:null,name:"-"},
                                {code:1,name:"Awaiting Activation"},
                                {code:2, name:"Trialing"},
                                {code:3, name:"Active"},
                                {code:4, name:"PastDue"},
                                {code:5, name:"Canceled"},
                                {code:6, name:"Expired"}
                            ]} optionLabel="name" ></Dropdown>

                        </div>
                        <div className="p-field p-col-4" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">Balance Greater Than</label>
                            <InputText type="number" id="invoicesLeftGreaterThan" value={preFilters.invoicesLeftGreaterThan} onChange={onFiltersChange} />
                        </div>
                        <div className="p-field p-col-4" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">Balance Less Than</label>
                            <InputText type="number" id="invoicesLeftLessThan" value={preFilters.invoicesLeftLessThan} onChange={onFiltersChange} />
                        </div>

                        <div className="p-field p-col-4" style={{ margin: 'auto' }}>
                            <hr />

                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Filter} icon="pi pi-search" className="p-button-success p-mr-1 p-mb-1" onClick={() => {
                                let f = { ...preFilters }
                                // 2021-09-21T21:50:37.000Z
                                setFilters({
                                    email: f.email,
                                    name: f.name,
                                    invoicesLeftLessThan: f.invoicesLeftLessThan,
                                    invoicesLeftGreaterThan: f.invoicesLeftGreaterThan,
                                    id: f.id,
                                    status: f.status?.code,
                                });
                            }} />
                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Clear} icon="pi pi-times" className="p-button-warning p-mr-1 p-mb-1" onClick={() => {
                                setPreFilters({});
                            }} />
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label={strings.Cancel} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );




    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt}
                        value={subscriptions}
                        selection={selectedProducts}
                        dataKey="id"
                        totalRecords={totalRecords}
                        lazy
                        autoLayout={true}
                        first={first}
                        loading={loading}
                        tableStyle={{

                        }}
                        onPage={onPage}
                        paginator rows={rows}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Subscriptions"
                        globalFilter={globalFilter}
                        emptyMessage="No subscriptions found."
                        header={header}>

                        <Column field="id" header="ID" body={(data) => data.uuid}></Column>
                        <Column field="name" header="Name" body={nameBodyTemplate}></Column>
                        <Column field="email" header="Email" body={(rowData) => rowData.subscriptionUsers[0]?.user?.email}></Column>
                        <Column field="phone" header="Phone" body={(rowData) => rowData.subscriptionUsers[0]?.user?.phone}></Column>
                        <Column field="subscription_plan" header="Plan" body={Subscription_palnBodyTemplate}></Column>
                        <Column field="invoicesLeft" header="Invoices Left" body={(rowData) => rowData.invoicesLeft}></Column>
                        <Column field="startDate" header="Start Date" body={startDateTemplate}></Column>
                        <Column field="endDate" header="End Date" body={endDateTemplate}></Column>
                        <Column field="createdDate" header="Created At" body={createdDateTemplate}></Column>
                        <Column field="subscriptionStatus" header="Status" body={subscriptionStatusBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                </div>
            </div>
            <Dialog visible={productDialog} style={{ width: '700px' }} header={strings.subscriptions} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                <SubscriptionForm OnHideDialog={hideDialog} subscription={subscription} FillData={() => onPage({ rows, page: 0 })} />
            </Dialog>
            <Dialog visible={subscriptionUsersDialog} style={{ width: '100%' }} header={strings.subscriptionUsers} modal className="p-fluid" onHide={() => setSubscriptionUsersDialog(false)}>
                <SubscriptionUsers subscriptionId={selectedSubscription} full={true} />
            </Dialog>

        </div>
    );
}
