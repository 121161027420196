import { HubConnectionBuilder } from '@microsoft/signalr';
import classNames from 'classnames';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import PrimeReact from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import 'primereact/resources/primereact.min.css';
import React, { useEffect, useRef, useState } from 'react';
import { Route, useParams } from 'react-router-dom';
import './App.scss';
import AppConfig from './AppConfig';
import AppFooter from './AppFooter';
import AppInlineMenu from './AppInlineMenu';
import AppMenu from './AppMenu';
import AppRightMenu from './AppRightMenu';
import AppTopbar from './AppTopbar';
import Can, { check } from './Can';
import { Dashboard } from './components/Dashboard';
import strings from './localization';
import { ActivityLogs } from './pages/ActivityLogs';
import { Charges } from './pages/Charges';
import { CurrencyRates } from './pages/CurrencyRates';
import { Customers } from './pages/Customers';
import { Feedbacks } from './pages/Feedbacks';
import { IdentityTypes } from './pages/IdentityTypes';
import InactiveSubscription from './pages/InactiveSubscription';
import { InvoiceForm } from './pages/InvoiceForm';
import { Invoices } from './pages/Invoices';
import { ProductCategories } from './pages/ProductCategories';
import { Product } from './pages/Products';
import { SubscriptionPlans } from './pages/SubscriptionPlans';
import { Subscriptions } from './pages/Subscriptions';
import { SubscriptionUsers } from './pages/SubscriptionUsers';
import { SupportTicketForm } from './pages/SupportTicketForm';
import { TaxCategories } from './pages/TaxCategories';
import { Users } from './pages/Users';
import { Vendors } from './pages/Vendors';
import rules from './rbac-rules';
import RenewSubscription from './RenewSubscription';
import SubscriptionService from './service/SubscriptionService';
import UserService from './service/UserService';
import NotifyConnectedUsers from './NotifyConnectedUsers';
import { InvoiceReport } from './pages/InvoiceReport';

import Payment from './pages/invoicesPayment/Main'
import { getInvoicesHandler } from './store/invoices'
import { getSuppliers ,getActiveVendor} from './store/suppliers'
import {getVendors } from './store/vendors'
import { connect } from 'react-redux'
// import Quotations from './pages/quotations'
import { getActiveCompanyAndActiveBranch } from './store/user'
import Email from './pages/emails/email'
import { getCustomers } from './store/customers'
import {Expenses} from './pages/Expenses'
import Suppliers from './pages/suppliers/Suppliers'
import {SupplierProducts} from './pages/SupplierProducts'
import PPayments from './pages/expensePayment'


import { ProgressSpinner } from 'primereact/progressspinner';

import UserManual from './UserManual'
import OrdersIndex from './pages/orders';
import InvoiceRefund from './pages/refunds/InvoiceRefund';
import ExpenseRefund from './pages/refunds/ExpenseRefund';
import { VendorForm } from './pages/VendorForm';
import { Button } from 'primereact/button';
import { CustomerForm } from './pages/CustomerForm';
import VendorService from './service/VendorService';
import AuthService from './service/AuthService';
import SessionService from './service/SessionService';
import ProductForm from './pages/ProductForm';
import { Toast } from 'primereact/toast';
import PlansDisplay from './PlansDisplay';
import Checkout from './Checkout2';
import { QuotationForm } from './pages/QuotationForm';
import { ExpenseTypes } from './pages/ExpenseTypes';
import PurchaseOrderForm from './pages/PurchaseOrderForm';
import { Payments } from './pages/Payments';
import { Quotations } from './pages/Quotations';
import { PaymentTypes } from './pages/PaymentTypes';
import PurchasePaymentForm from './pages/PurchasePaymentForm';
import { PurchasePayments } from './pages/PurchasePayments';
import { ProfitAndLossReports } from './pages/ProfitAndLossReports';
import { ZatcaVatReports } from './pages/ZatcaVatReports';
import { ZatcaSetup } from './pages/ZatcaSetup';
import { PlatformSettings } from './pages/PlatformSettings';
import { Referrals } from './pages/Referrals';
import PlatformService from './service/PlatformService';
import WalletService from './service/WalletService';
import { getCountry } from './service/helpers';

let api = process.env.REACT_APP_API_URL
let newFeaturesEnabled = process.env.REACT_APP_NEW_FEATURES_ENABLED === "true"



export function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}


export const RTLContext = React.createContext();

const Profile = ({ getActiveCompanyAndActiveBranch, getActiveVendor,getVendors }) => {

    const [menuMode, setMenuMode] = useState('static');
    const [inlineMenuPosition, setInlineMenuPosition] = useState('bottom');
    const [desktopMenuActive, setDesktopMenuActive] = useState(true);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [colorMode, setColorMode] = useState('light');
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('filled');
    const [isRTL, setRTL] = useState((localStorage.getItem('lang') ?? 'ar') == 'ar');
    const [ripple, setRipple] = useState(true);
    const [mobileTopbarActive, setMobileTopbarActive] = useState(false);
    const [menuTheme, setMenuTheme] = useState('light');
    const [topbarTheme, setTopbarTheme] = useState('blue');
    const [theme, setTheme] = useState('indigo');
    const [isInputBackgroundChanged, setIsInputBackgroundChanged] = useState(false);
    const [inlineMenuActive, setInlineMenuActive] = useState({});
    const [newThemeLoaded, setNewThemeLoaded] = useState(false);
    const [searchActive, setSearchActive] = useState(false)
    const [signalrConnection, setSignalrConnection] = useState(null)
    const [supportDialog, setSupportDialog] = useState(false);
    const [broadcastMessage, setBroadcastMessage] = useState(null);
    const [listening, setListening] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showCompanyForm, setShowCompanyForm] = useState(false);
    const [demoStatus, setDemoStatus] = useState(-1);
    const [customerStart, setCustomerStart] = useState(false);
    const [productStart, setProductStart] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [context, setContext] = useState({});
    const [role, setRole] = useState({});
    const params =  new URLSearchParams(window.location.search)
    let currentInlineMenuKey = useRef(null);
    var userService = new UserService();

    useEffect(()=>{
        setRole(userService.GetRole());
    },[]);

    const toast = useRef(null);

    useEffect(() => {
        if (params.get('status')==='200') {
            toast.current.show({severity: 'success', summary: strings.updating, detail: strings.successfullyUpdated});
        } else if (params.get('status')==='201' ){
            toast.current.show({severity: 'success', summary: strings.adding, detail: strings.successfullyAdded});
        }
        else if (params.get('status')==='202' ){
            toast.current.show({severity: 'success', summary: strings.renewal, detail: strings.successRenewal});
        }
    },[params])

    PrimeReact.ripple = true;

    let searchClick;
    let topbarItemClick;
    let menuClick;
    let inlineMenuClick;
    let items = []
    let adminItems = []
    let purchaseItems = []
    let reportItems = []
    let helpItems = []

    if (check(rules, role, 'users:route')) {
        adminItems.push({ label: strings.users, icon: 'pi pi-fw pi-id-card', to: '/profile/users' });
    }
    if (check(rules, role, 'subscriptions:route')) {
        adminItems.push({ label: strings.subscriptions, icon: 'pi pi-fw pi-check-square', to: '/profile/subscriptions' });
    }
    if (check(rules, role, 'platformSettings:route')) {
        adminItems.push({ label: 'Platform Settings', icon: 'pi pi-fw pi-cog', to: '/profile/platformSettings' });
    }
    if (check(rules, role, 'referrals:route')) {
        adminItems.push({ label: 'Referrals', icon: 'pi pi-fw pi-cog', to: '/profile/referrals' });
    }
    if (check(rules, role, 'subscriptions:route')) {
        adminItems.push({ label: strings.notifications, icon: 'pi pi-fw pi-check-square', to: '/profile/notifications' });
    }
    if (check(rules, role, 'identityTypes:route')) {
        adminItems.push({ label: strings.identityTypes, icon: 'pi pi-fw pi-check-square', to: '/profile/identityTypes' });
    }
    if (check(rules, role, 'expenseTypes:route')) {
        adminItems.push({ label: strings.expenseTypes, icon: 'pi pi-fw pi-check-square', to: '/profile/expenseTypes' });
    }
    if (check(rules, role, 'paymentTypes:route')) {
        adminItems.push({ label: strings.paymentTypes, icon: 'pi pi-fw pi-check-square', to: '/profile/paymentTypes' });
    }
    if (check(rules, role, 'taxCategories:route')) {
        adminItems.push({ label: strings.taxCategories, icon: 'pi pi-fw pi-check-square', to: '/profile/taxCategories' });
    }
    if (check(rules, role, 'invoices:metrics:read')) {
        reportItems.push({ label: strings.vatReport, icon: 'pi pi-fw pi-check-square', to: '/profile/vatReport', disabled:companies.length==0 && role != 'super_admin' });
    }

    if (check(rules, role, 'invoices:metrics:read')) {
        reportItems.push({ label: strings.pnlReports, icon: 'pi pi-fw pi-check-square', to: '/profile/pnlReports', disabled: companies.length == 0 && role != 'super_admin' });
    }
    if (check(rules, role, 'invoices:metrics:read') && getCountry()=='SA') {
        reportItems.push({ label: strings.ZatcaVatReports, icon: 'pi pi-fw pi-check-square', to: '/profile/zatcaVatReports', disabled: companies.length == 0 && role != 'super_admin' });
    }


    if (check(rules, role, 'charges:route')) {
        adminItems.push({ label: strings.charges, icon: 'pi pi-fw pi-check-square', to: '/profile/charges' });
    }
    if (check(rules, role, 'subscriptionPlans:route')) {
        adminItems.push({ label: strings.subscriptionPlans, icon: 'pi pi-fw pi-check-square', to: '/profile/subscriptionPlans' });
    }
    if (check(rules, role, 'feedbacks:route')) {
        adminItems.push({ label: strings.feedbacks, icon: 'pi pi-fw pi-check-square', to: '/profile/feedbacks' });
    }


    // let company = localStorage.getItem('company');

    if (check(rules, role, 'vendors:route')) {
        items.push({ label: `${strings.vendors}${companies.length || role == 'super_admin'?'':` (${strings.starthere})`}`, icon: 'pi pi-fw pi-sitemap', to: '/profile/vendors' });
    }
    // if (check(rules, role, 'vendors:route')) {
    //     items.push({ label: `${strings.Compliance}${companies.length || role == 'super_admin'?'':` (${strings.starthere})`}`, icon: 'pi pi-fw pi-sitemap', to: '/profile/zatca/settings' });
    // }

    if (check(rules, role, 'customers:route')) {
        items.push({ label: strings.customers, icon: 'pi pi-fw pi-users', to: '/profile/customers', disabled:companies.length==0 && role != 'super_admin' });
    }

    if (check(rules, role, 'products:route')) {
        items.push({ label: strings.products, icon: 'pi pi-fw pi-tags', to: '/profile/products', disabled:companies.length==0 && role != 'super_admin'});
    }

    if (check(rules, role, 'invoices:route')) {
        items.push({ label: strings.invoices, icon: 'pi pi-fw pi-money-bill', to: '/profile/invoices', disabled:companies.length==0 && role != 'super_admin' });
    }

    if (check(rules, role, 'subscriptionUsers:route')) {
        adminItems.push({ label: strings.subscriptionUsers, icon: 'pi pi-fw pi-user-edit', to: '/profile/subscriptionUsers', disabled: false });
    }
    if (check(rules, role, 'mySubscription:route')) {
        adminItems.push({ label: strings.subscriptionManage, icon: 'pi pi-fw pi-ticket', to: '/profile/subscription' });
    }
    if (check(rules, role, 'currencyRates:route')) {
        items.push({ label: strings.currencyRates, icon: 'pi pi-fw pi-money-bill', to: '/profile/currencyRates', disabled:companies.length==0 && role != 'super_admin' });
    }


    if (check(rules, role, 'productCategories:route')) {
        items.push({ label: strings.productCategories, icon: 'pi pi-fw pi-sitemap', to: '/profile/productCategories', disabled: false });
    }

    if (check(rules, role, "suppliers:route")) {
        purchaseItems.push({ label: strings.suppliers, icon: 'pi pi-fw pi-sitemap', to: '/profile/suppliers' })
    }
    // if (check(rules, role, 'customers:route')) {
    //     purchaseItems.push({ label: strings.customers, icon: 'pi pi-fw pi-users', to: '/profile/purchase/customers' });
    // }
    if (check(rules, role, 'orders:route')) {
        purchaseItems.push({ label: strings.purchasedOrders, icon: 'pi pi-fw pi-shopping-cart', to: '/profile/orders' });
    }
    if (check(rules, role, 'products:route')) {
        purchaseItems.push({ label: strings.products, icon: 'pi pi-fw pi-tags', to: '/profile/purchase/products' });
    }
    if (check(rules, role, "expenses:route")) {
        purchaseItems.push({ label: strings.expenses, icon: 'pi pi-fw pi-book', to: '/profile/expenses' })
    }
    if (check(rules, role, 'payment:route')) {
        purchaseItems.push({ label: strings.payments, icon: 'pi pi-fw pi-credit-card', to: '/profile/purchase/payments' });
    }
    // if (check(rules, role, 'refund:purchase:route')) {
    //     purchaseItems.push({ label: strings.refunds, icon: 'pi pi-fw pi-replay', to: '/profile/purchase/refunds' });
    // }

    if (check(rules, role, 'activityLogs:route')) {
        helpItems.push({ label: strings.activityLogs, icon: 'pi pi-fw pi-list', to: '/profile/activityLogs', disabled: false });
    }
    if (check(rules, role, 'payment:route') && newFeaturesEnabled) {
        items.push({ label: strings.payments, icon: 'pi pi-fw pi-credit-card', to: '/profile/payments' });
    }
    if (check(rules, role, 'quotations:route') && newFeaturesEnabled) {
        items.push({ label: strings.quotations, icon: 'pi pi-fw pi-file', to: '/profile/quotations' });
    }
    // if (check(rules, role, 'refund:route') && newFeaturesEnabled) {
    //     items.push({ label: strings.refunds, icon: 'pi pi-fw pi-replay', to: '/profile/refunds' });
    // }

    const menu = [];

    menu.push(
        {
            // label: strings., icon: 'pi pi-fw pi-home',
            items: [
                { label: strings.dashboard, icon: 'pi pi-fw pi-home', to: '/profile' }
            ]
        },
    )
    if (adminItems.length > 0) {
        menu.push(
            {
                label: strings.admin, icon: 'pi pi-fw pi-home',
                items: adminItems
            },
        )
    }

    if (items.length > 0) {
        menu.push(
            {
                label: strings.invoicing, icon: 'pi pi-fw pi-home',
                items: [
                    ...items
                ]
            },
        )
    }
    if (purchaseItems.length > 0 && newFeaturesEnabled) {
        menu.push(
            {
                label: strings.purchases, icon: 'pi pi-fw pi-home',
                items: [...purchaseItems]
            }
        )
    }
    if (reportItems.length > 0) {
        menu.push(
            {
                label: strings.reports, icon: 'pi pi-fw pi-home',
                items: [
                    ...reportItems
                ]
            },
        )
    }


    helpItems.push({ label: strings.openSupportRequest, icon: 'pi pi-fw pi-info', command: () => setSupportDialog(true) })
    helpItems.push({ label: strings.userManual, icon: 'pi pi-fw pi-book', to: '/profile/userManual' })


    menu.push(
        {
            label: strings.help,
            items: helpItems
        },
    )

    useEffect(() => {
        if (menuMode === 'overlay') {
            hideOverlayMenu()
        }
        if (menuMode === 'static') {
            setDesktopMenuActive(true)
        }
    }, [menuMode])

    useEffect(() => {
        onColorModeChange(colorMode)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        const connection = new HubConnectionBuilder()
            .withUrl(`${api}/hubs/upload`, { accessTokenFactory: () => localStorage.getItem('deviceId') })
            .withAutomaticReconnect()
            .build();

        connection.start().then(() => {
            setLoading(false);
            console.log('Connected to SignalR');
            connection.on(`BroadcastMessage`, message => {
                if (message == 'clear-cache') {
                    window.location.href = window.location.href
                }
                else {
                    setBroadcastMessage(message);
                }
            });

            setSignalrConnection(connection);
        }).catch(e => console.error(e, "Error whle connecting to SignalR"));



    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onMenuThemeChange = (theme) => {
        setMenuTheme(theme)
    }

    const onTopbarThemeChange = (theme) => {
        setTopbarTheme(theme);
    }

    useEffect(() => {
        const appLogoLink = document.getElementById('app-logo');
        if (!loading) {
            if (topbarTheme === 'white' || topbarTheme === 'yellow' || topbarTheme === 'amber' || topbarTheme === 'orange' || topbarTheme === 'lime') {
                appLogoLink.src = '/assets/layout/images/logo-dark.svg';
            }
            else {
                appLogoLink.src = '/assets/layout/images/logo-white.svg';
            }

        }
    }, [topbarTheme])

    const onThemeChange = (theme) => {
        setTheme(theme);
        const themeLink = document.getElementById('theme-css');
        const themeHref = '/assets/theme/' + theme + '/theme-' + colorMode + '.css';
        replaceLink(themeLink, themeHref);
    }

    const onColorModeChange = (mode) => {
        setColorMode(mode);
        setIsInputBackgroundChanged(true);

        if (isInputBackgroundChanged) {
            if (mode === 'dark') {
                setInputStyle('filled');
            } else {
                setInputStyle('outlined')
            }
        }

        if (mode === 'dark') {
            setMenuTheme('dark');
            setTopbarTheme('dark');
        } else {
            setMenuTheme('light');
            setTopbarTheme('blue');

        }

        const layoutLink = document.getElementById('layout-css');
        const layoutHref = '/assets/layout/css/layout-' + mode + '.css';
        replaceLink(layoutLink, layoutHref);

        const themeLink = document.getElementById('theme-css');
        const urlTokens = themeLink.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = 'theme-' + mode + '.css';
        const newURL = urlTokens.join('/');

        replaceLink(themeLink, newURL, () => {
            setNewThemeLoaded(true);
        });

    }

    const replaceLink = (linkElement, href, callback) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);

            if (callback) {
                callback();
            }
        }
        else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);

                if (callback) {
                    callback();
                }
            });
        }
    }

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    }

    const onInlineMenuPositionChange = (mode) => {
        setInlineMenuPosition(mode)
    }

    const onMenuModeChange = (mode) => {
        setMenuMode(mode);
    }

    const onRTLChange = () => {
        strings.setLanguage(isRTL ? 'en' : 'ar')
        localStorage.setItem('lang', isRTL ? 'en' : 'ar');
        setRTL(prevState => !prevState);



    }

    const onMenuClick = () => {
        menuClick = true;
    }

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (isDesktop())
            setDesktopMenuActive((prevState) => !prevState);
        else
            setMobileMenuActive((prevState) => !prevState)

        event.preventDefault();

    }

    const onSearch = (event) => {
        searchClick = true;
        setSearchActive(event);
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items && (menuMode === 'overlay' || !isDesktop())) {
            hideOverlayMenu();
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false)
        }
    }

    const onRootMenuItemClick = () => {
        setMenuActive((prevState) => !prevState);
    }

    const onRightMenuButtonClick = () => {
        setRightMenuActive((prevState) => !prevState)
    }

    const onMobileTopbarButtonClick = (event) => {
        setMobileTopbarActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onDocumentClick = (event) => {
        if (!searchClick && event.target.localName !== 'input') {
            setSearchActive(false);
        }

        // if (!topbarItemClick) {
        //     setActiveTopbarItem(null);
        // }

        if (!menuClick && (menuMode === 'overlay' || !isDesktop())) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false)
            }
            hideOverlayMenu();
        }

        if (inlineMenuActive[currentInlineMenuKey.current] && !inlineMenuClick) {
            let menuKeys = { ...inlineMenuActive };
            menuKeys[currentInlineMenuKey.current] = false;
            setInlineMenuActive(menuKeys);
        }

        if (!menuClick && (isSlim() || isHorizontal())) {
            setMenuActive(false);
        }

        searchClick = false;
        topbarItemClick = false;
        inlineMenuClick = false;
        menuClick = false;
    }

    const hideOverlayMenu = () => {
        setMobileMenuActive(false)
        setDesktopMenuActive(false)
    }

    const isDesktop = () => {
        return window.innerWidth > 1024;
    }

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    }

    const isSlim = () => {
        return menuMode === 'slim';
    }

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }

    const onInlineMenuClick = (e, key) => {
        let menuKeys = { ...inlineMenuActive };
        if (key !== currentInlineMenuKey.current && currentInlineMenuKey.current) {
            menuKeys[currentInlineMenuKey.current] = false;
        }

        menuKeys[key] = !menuKeys[key];
        setInlineMenuActive(menuKeys);
        currentInlineMenuKey.current = key;
        inlineMenuClick = true;
    }

    const layoutContainerClassName = classNames('layout-wrapper ', 'layout-menu-' + menuTheme + ' layout-topbar-' + topbarTheme, {
        'layout-menu-static': menuMode === 'static',
        'layout-menu-overlay': menuMode === 'overlay',
        'layout-menu-slim': menuMode === 'slim',
        'layout-menu-horizontal': menuMode === 'horizontal',
        'layout-menu-active': desktopMenuActive,
        'layout-menu-mobile-active': mobileMenuActive,
        'layout-topbar-mobile-active': mobileTopbarActive,
        'layout-rightmenu-active': rightMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple,
        'layout-rtl': isRTL
    });


    useEffect(() => {
        let subService = new SubscriptionService();
        var service = new PlatformService();
        service.GetPlatformSettings().then(settings => {
            if (settings['WalletEnabled']) {
                var service = new WalletService();
                service.GetWallet().then(wallet => {
                    setContext({
                        branch: subService.GetActiveBranch(),
                        vendor: subService.GetActiveCompany(),
                        subscription: subService.GetActiveSubscription(),
                        branches: subService.GetBranches(),
                        wallet: wallet,
                        platformSettings: settings,
                        companies: companies,
                        showCompany: subService.GetActiveCompany() == null,
                        showBranch: subService.GetActiveBranch() == null,
                        role: new UserService().GetRole(),
                        signalrConnection: signalrConnection
                    })
                }).catch(e => {
                    setContext({
                        branch: subService.GetActiveBranch(),
                        vendor: subService.GetActiveCompany(),
                        subscription: subService.GetActiveSubscription(),
                        branches: subService.GetBranches(),
                        wallet: null,
                        platformSettings: settings,
                        companies: companies,
                        showCompany: subService.GetActiveCompany() == null,
                        showBranch: subService.GetActiveBranch() == null,
                        role: new UserService().GetRole(),
                        signalrConnection: signalrConnection
                    })
                    console.error(e);

                });
            }
            else
            {
                setContext({
                    branch: subService.GetActiveBranch(),
                    vendor: subService.GetActiveCompany(),
                    subscription: subService.GetActiveSubscription(),
                    branches: subService.GetBranches(),
                    wallet: null,
                    platformSettings: settings,
                    companies: companies,
                    showCompany: subService.GetActiveCompany() == null,
                    showBranch: subService.GetActiveBranch() == null,
                    role: new UserService().GetRole(),
                    signalrConnection: signalrConnection
                })
            }
        }).catch(e => {

            setContext({
                branch: subService.GetActiveBranch(),
                vendor: subService.GetActiveCompany(),
                subscription: subService.GetActiveSubscription(),
                branches: subService.GetBranches(),
                wallet: null,
                platformSettings: {},
                companies: companies,
                showCompany: subService.GetActiveCompany() == null,
                showBranch: subService.GetActiveBranch() == null,
                role: new UserService().GetRole(),
                signalrConnection: signalrConnection
            })

            console.log(e)
        } );
    },[companies])

    let vendorService = new VendorService();

    useEffect(() => {
        localStorage.removeItem('inactiveSub')
    }, [])

    useEffect(() => {
        if (localStorage.getItem('inactiveSub') != "true" && newFeaturesEnabled) {
            Promise.all([
                // getInvoicesHandler(),
            // getSuppliers(),
            getActiveCompanyAndActiveBranch(),
            // getCustomers(),
            getActiveVendor(),
            getVendors()])
        }
    }, [])


    useEffect(() => {
        if (localStorage.getItem('inactiveSub') != "true") {

            if (userService.GetRole() != 'super_admin') {
                vendorService.GetVendors(500, 0, { simple: true }).then((data) => {
                    setDemoStatus(data.count == 0 ? 0 : -1)
                    setCompanies(data.data);
                })
            }
        }
     },[true])

    return (


        <RTLContext.Provider value={isRTL}>
            <Toast ref={toast}/>
            {loading ? <ProgressSpinner style={{ margin: '25% 50%' }} /> : <div className={layoutContainerClassName} onClick={onDocumentClick}>
                <AppTopbar horizontal={isHorizontal()}
                    onMenuButtonClick={onMenuButtonClick}
                    onRTLChange={onRTLChange}
                    context = {context}
                    onRightMenuButtonClick={onRightMenuButtonClick}
                    onMobileTopbarButtonClick={onMobileTopbarButtonClick} mobileTopbarActive={mobileTopbarActive}
                    searchActive={searchActive} onSearch={onSearch} />

                <div className="menu-wrapper" onClick={onMenuClick}>
                    <div className="layout-menu-container">
                        {(inlineMenuPosition === 'top' || inlineMenuPosition === 'both') && <AppInlineMenu menuKey="top" inlineMenuActive={inlineMenuActive} onInlineMenuClick={onInlineMenuClick} horizontal={isHorizontal()} menuMode={menuMode} />}
                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} onRootMenuItemClick={onRootMenuItemClick}
                            menuMode={menuMode} active={menuActive} />
                        {/* {(inlineMenuPosition === 'bottom' || inlineMenuPosition === 'both') && <AppInlineMenu menuKey="bottom" inlineMenuActive={inlineMenuActive} onInlineMenuClick={onInlineMenuClick} horizontal={isHorizontal()} menuMode={menuMode} />} */}
                    </div>
                </div>

                <div className="layout-main">
                    <Dialog visible={demoStatus >= 0 && demoStatus <= 6} style={{ width: '700px' }}  header={`${strings.Setup} (${demoStatus+1}/7)`} modal className="p-fluid" onHide={()=>{
                        setDemoStatus(-1);
                        // window.location.reload();
                    }}>
                        {demoStatus == 0 && <h5>{strings.companyStart}</h5>}
                        {demoStatus == 0 && <Button className="p-button-raised p-button-rounded" style={{marginTop:'20px'}} onClick={()=>setDemoStatus(1)}>{strings.continue}</Button>}
                        {demoStatus == 1 && <VendorForm FillData={()=>{}} OnHideDialog={()=>setDemoStatus(2)} />}
                        {demoStatus == 2 && <h5>{strings.customerStart}</h5>}
                        {demoStatus == 2 &&<Button className="p-button-raised p-button-rounded" style={{marginTop:'20px'}} onClick={()=>setDemoStatus(3)}>{strings.continue}</Button>}
                        {demoStatus == 3 && <CustomerForm full={true} FillData={()=>{}} OnHideDialog={()=>setDemoStatus(4)} />}
                        {demoStatus == 4 && <h5>{strings.productStart}</h5>}
                        {demoStatus == 4 &&<Button className="p-button-raised p-button-rounded" style={{marginTop:'20px'}} onClick={()=>setDemoStatus(5)}>{strings.continue}</Button>}
                        {demoStatus == 5 && <ProductForm context={{showCompany: true}} full={true} FillData={()=>{}} OnHideDialog={()=>setDemoStatus(6)} />}
                        {demoStatus == 6 &&<h5>{strings.demoEnd}</h5>}
                        {demoStatus == 6 &&<Button  onClick={()=>{
                            window.location.reload();
                        }}>{strings.end}</Button>}

                    </Dialog>

                    <div className="layout-content">
                        <Can context={context} yes={() => <Route path="/profile" exact>
                            <Dashboard context={context} colorMode={colorMode} isNewThemeLoaded={newThemeLoaded} onNewThemeChange={e => setNewThemeLoaded(e)} />
                        </Route>
                        }
                            perform={'dashboard:route'}
                            path={'/profile'}
                            role={role} />

                        <Can context={context} yes={() => <Route path="/profile/users" component={Users} />}
                            perform={'users:route'}
                            path={'/profile/users'}
                            role={role} />

                        <Can context={context} yes={() => <Route path="/profile/subscriptions"
                            component={Subscriptions} />}
                            perform={'subscriptions:route'}
                            path={'/profile/subscriptions'}
                            role={role}
                        />
                        <Route path="/profile/checkout"
                            component={()=><Checkout context={context}/>} />
                        {/* <Can context={context} yes={() => }
                            perform={'subscriptions:route'}
                            path={'/profile/checkout'}
                            role={role}
                        /> */}

                        <Can context={context} yes={() => <Route path="/profile/invoices" component={() => <Invoices context={context} />} exact />}                            perform={'invoices:route'}
                            path={'/profile/invoices'}
                            role={role} />

                        <Can context={context} yes={() => <Route path="/profile/invoices/:id" component={(props) => <InvoiceForm {...props} startListener={() => setListening(true)} listening={listening} context={context} />} />}
                            perform={'invoices:route'}
                            path={'/profile/invoices/:id'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/quotations/:id" component={(props) => <QuotationForm {...props} startListener={() => setListening(true)} listening={listening} context={context} />} />}
                            perform={'invoices:route'}
                            path={'/profile/invoices/:id'}
                            role={role} />

                        <Can context={context} yes={() => <Route path="/profile/vendors" component={(props)=><Vendors {...props} context={context}/>} />}
                            perform={'vendors:route'}
                            path={'/profile/vendors'}
                            role={role} />

                        {/* <Can context={context} yes={() => <Route path="/profile/zatca/settings" component={(props)=><ZatcaSetup {...props} context={context}/>} />}
                            perform={'vendors:route'}
                            path={'/profile/zatca/settings'}
                            role={role} /> */}

                        <Can context={context} yes={() => <Route path="/profile/identityTypes" component={IdentityTypes} />}
                            perform={'identityTypes:route'}
                            path={'/profile/identityTypes'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/expenseTypes" component={ExpenseTypes} />}
                            perform={'expenseTypes:route'}
                            path={'/profile/expenseTypes'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/paymentTypes" component={PaymentTypes} />}
                            perform={'paymentTypes:route'}
                            path={'/profile/paymentTypes'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/referrals" component={Referrals} />}
                            perform={'referrals:route'}
                            path={'/profile/referrals'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/platformSettings" component={PlatformSettings} />}
                            perform={'platformSettings:route'}
                            path={'/profile/platformSettings'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/taxCategories" component={TaxCategories} />}
                            perform={'taxCategories:route'}
                            path={'/profile/taxCategories'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/charges" component={Charges} />}
                            perform={'charges:route'}
                            path={'/profile/charges'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/notifications" component={NotifyConnectedUsers} />}
                            perform={'charges:route'}
                            path={'/profile/notifications'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/subscriptionPlans" component={SubscriptionPlans} />}
                            perform={'subscriptionPlans:route'}
                            path={'/profile/subscriptionPlans'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/feedbacks" component={Feedbacks} />}
                            perform={'feedbacks:route'}
                            path={'/profile/feedbacks'}
                            role={role} />

                        <Can context={context} yes={() => <Route path="/profile/customers" component={() => <Customers context={context} />} />}
                            perform={'customers:route'}
                            path={'/profile/customers'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/products" component={(p) => <Product {...p} context={context} />} />}
                            perform={'customers:route'}
                            path={'/profile/products'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/subscriptionUsers" component={() => <SubscriptionUsers full={true} />} />}
                            perform={'subscriptionUsers:route'}
                            path={'/profile/subscriptionUsers'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/activityLogs" component={() => <ActivityLogs full={true} />} />}
                            perform={'activityLogs:route'}
                            path={'/profile/activityLogs'}
                            role={role} />

                        <Can context={context} yes={() => <Route path="/profile/vatReport">
                            <InvoiceReport options={{
                                editPeriod: true
                            }}
                            context={context}
                            />
                        </Route>}
                            perform={'invoices:metrics:read'}
                            path={'/profile/vatReport'}
                            role={role} />

                        <Can context={context} yes={() => <Route path="/profile/pnlReports">
                            <ProfitAndLossReports
                                context={context}
                            />
                        </Route>}
                            perform={'invoices:metrics:read'}
                            path={'/profile/pnlReports'}
                            role={role} />

                        
                        {getCountry()=='SA' ? <Can context={context} yes={() => <Route path="/profile/zatcaVatReports">
                            <ZatcaVatReports
                                context={context}
                            />
                        </Route>}
                            perform={'invoices:metrics:read'}
                            path={'/profile/zatcaVatReports'}
                            role={role} />:''}

                        <Can context={context} yes={() => <Route path="/profile/currencyRates" component={() => <CurrencyRates />} />}
                            perform={'currencyRates:route'}
                            path={'/profile/currencyRates'}
                            role={role} />

                        <Can context={context} yes={() => <Route path="/profile/productCategories" component={() => <ProductCategories context={context} />} />}
                            perform={'productCategories:route'}
                            path={'/profile/productCategories'}
                            role={role} />

                        <Can context={context} yes={() => <Route path="/profile/subscription" component={() => <RenewSubscription />} />}
                            perform={'mySubscription:route'}
                            path={'/profile/subscription'}
                            role={role} />
                            <Can context={context} yes={() => <Route path="/profile/plans" component={() => <PlansDisplay context={context}/>} />}
                            perform={'mySubscription:route'}
                            path={'/profile/plans'}
                            role={role} />

                        <Can context={context} yes={() => <Route path="/profile/payments" component={() => <Payments context={context} />} />}
                            perform={'payment:route'}
                            path={'/profile/payments'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/quotations" exact={true} component={() => <Quotations context={context} />} />}
                            perform={'quotations:route'}
                            path={'/profile/quotations'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/expenses" component={() => <Expenses context={context}/>} />}
                            perform={'expenses:route'}
                            path={'/profile/expenses'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/suppliers" component={() => <Suppliers context={context}/>} />}
                            perform={'vendors:purchase:route'}
                            path={'/profile/suppliers'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/orders" component={() => <OrdersIndex />} />}
                            perform={'orders:route'}
                            path={'/profile/orders'}
                            role={role} />

                        <Can context={context} yes={() => <Route path="/profile/purchaseOrder/:id" component={(p) => <PurchaseOrderForm {...p} context={context}/>} />}
                            perform={'orders:route'}
                            path={'/profile/orders'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/purchase/products" component={(p) => <SupplierProducts {...p} context={context} />} />}
                            perform={'customers:route'}
                            path={'/profile/purchase/products'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/purchase/payments" component={(props) => <PurchasePayments context={context} {...props} />} context={context} />}
                            perform={'payment:purchase:route'}
                            path={'/profile/purchase/payments'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/purchase/payments/:id" component={(props) => <PurchasePayments context={context} {...props} />} context={context} />}
                            perform={'payment:purchase:route'}
                            path={'/profile/purchase/payments'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/refunds" component={() => <InvoiceRefund />} />}
                            perform={'refund:route'}
                            path={'/profile/refunds'}
                            role={role} />
                        <Can context={context} yes={() => <Route path="/profile/purchase/refunds" component={() => <ExpenseRefund />} />}
                            perform={'refund:purchase:route'}
                            path={'/profile/purchase/refunds'}
                            role={role} />

                      {/* <Can context={context} yes={() => <Route path="/profile/subscription" component={() => <RenewSubscription />} />}
                            perform={'subscription:route'}
                            path={'/profile/renew'}
                            role={role} /> */}
                        <Route path='/profile/email' component={() => <Email />} />
                        <Route path="/profile/subscriptionInactive" component={(props) => <InactiveSubscription context={context} {...props} />} />

                        <Route path="/profile/userManual" component={() => <UserManual />} />

                    </div>

                    <AppFooter colorMode={colorMode} />

                </div>

                <AppConfig inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                    rippleEffect={ripple} onRippleEffect={onRipple}
                    menuMode={menuMode} onMenuModeChange={onMenuModeChange}
                    inlineMenuPosition={inlineMenuPosition} onInlineMenuPositionChange={onInlineMenuPositionChange}
                    colorMode={colorMode} onColorModeChange={onColorModeChange}
                    menuTheme={menuTheme} onMenuThemeChange={onMenuThemeChange}
                    topbarTheme={topbarTheme} onTopbarThemeChange={onTopbarThemeChange}
                    theme={theme} onThemeChange={onThemeChange}
                    isRTL={isRTL} onRTLChange={onRTLChange} />

                <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuButtonClick={onRightMenuButtonClick} />

                {mobileMenuActive && <div className="layout-mask modal-in"></div>}
                <Dialog visible={supportDialog} style={{ width: '450px' }} header={strings.Confirm} modal onHide={() => setSupportDialog(false)}>
                    <SupportTicketForm close={() => setSupportDialog(false)} />
                </Dialog>
                <Dialog visible={broadcastMessage != null} style={{ width: '450px' }} header={""} modal onHide={() => setBroadcastMessage(null)}>
                    {broadcastMessage}
                </Dialog>

            </div>
            }        </RTLContext.Provider>
    );

}

export default connect(null, { getInvoicesHandler, getSuppliers, getActiveCompanyAndActiveBranch, getCustomers ,getActiveVendor,getVendors})(Profile);
