import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import ActivityLogService from '../service/ActivityLogService';
import strings from '../localization';
import { formatDate, formatDateStringLocal } from './InvoiceDates';
import UserService from '../service/UserService';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';


export const ActivityLogs = () => {

    const [totalRecords, setTotalRecords] = useState(0);
    const [activityLogs, setActivityLogs] = useState([]);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [preFilters, setPreFilters] = useState({});
    const [filters, setFilters] = useState({});
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        // const feedbackService = new FeedbackService();
        // feedbackService.getFeedbacks().then(data => setFeedbacks(data));
        onPage({ rows, page: 0 })
    }, []);

    const customerEmailTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Customer Email</span>
                {rowData.email}
            </>
        );
    }

    const ipTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">IP Address</span>
                {rowData.ip}
            </>
        );
    }

    const onFiltersChange = (e) => {
        let filters = { ...preFilters };
        filters[e.target.id] = e.target.value;
        setPreFilters(filters);
    }

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            onPage({rows,page:0})
        }
    }, [filters]);

    useEffect(() => {
        onPage({rows,page:0})
    }, [localStorage.getItem('subscription')]);

    const statuses = [1,2,3,4,5].map(i=>{
        return {
            name: strings[`actionStatus${i}`],
            code: i
        }
    });


    const logActionTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Action</span>
                {rowData.operation}
            </>
        );
    }
    const customerDescriptionTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData.operationDescription}
            </>
        );
    }
    const customerStatusTemplate = (rowData) => {
        return (
            <>
            <span className={`product-badge status-${rowData.operationStatus == 1? '3' : 'failed'}`}>{strings[`actionStatus${rowData.operationStatus}`]}</span>
            <span className="p-column-title">Status</span>
            </>
        );
    }
    const createdAtTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Created At</span>
                {formatDateStringLocal(rowData.createdAt)}
            </>
        );
    }

    const onPage = ({ first,
        rows,
        page }) => {
        const activityLogService = new ActivityLogService();
        setLoading(true);
        setRows(rows);
        setFirst(first)

        activityLogService.GetActivityLogs(rows, (page) * rows, filters).then(({ data, count }) => {
            setLoading(false);
            setActivityLogs(data);
            setTotalRecords(count);
        });

    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">{strings.activityLogs}</h5>
        </div>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">

                <div className="card">

                    <div className="p-fluid p-grid">

                        <div className="p-field p-col-3" style={{ margin: 'auto' }}>

                            <label htmlFor="inputtext">{strings.Action}</label>

                            <InputText id="operation" value={preFilters.operation} onChange={onFiltersChange} />

                        </div>

                        <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                            <label htmlFor="operationStatus">{strings.Status}</label>
                            <Dropdown id="operationStatus" options={statuses} value={preFilters.operationStatus} onChange={onFiltersChange} optionLabel="name"></Dropdown>
                        </div>
                        <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.description}</label>

                            <InputText id="operationDescription" value={preFilters.operationDescription} onChange={onFiltersChange} />
                        </div>
                        <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.email}</label>

                            <InputText id="email" value={preFilters.email} onChange={onFiltersChange} />
                        </div>
                        <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.ip}</label>

                            <InputText id="ip" value={preFilters.ip} onChange={onFiltersChange} />
                        </div>

                        <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.dateFrom}</label>
                            <Calendar id="afterDate" showTime={true} showSeconds={true} showIcon showButtonBar value={formatDate(preFilters.afterDate)} onChange={onFiltersChange} />
                        </div>
                        <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.dateTo}</label>
                            <Calendar id="beforeDate" showTime={true} showSeconds={true} showIcon showButtonBar value={formatDate(preFilters.beforeDate)} onChange={onFiltersChange} />
                        </div>

                        {new UserService().GetRole() == 'super_admin' && <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.SubscriptionID}</label>
                            <InputText id="sid" value={preFilters.sid} onChange={onFiltersChange} />
                        </div>}

                        <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Filter} icon="pi pi-search" className="p-button-success p-mr-1 p-mb-1" onClick={() => {
                                let f = { ...preFilters }
                                setFilters({
                                    operation: f.operation,
                                    operationStatus: f.operationStatus?.code,
                                    operationDescription: f.operationDescription,
                                    email: f.email,
                                    ip: f.ip,
                                    afterDate: f.afterDate ? moment(f.afterDate).utc().format() : null,
                                    beforeDate: f.beforeDate ? moment(f.beforeDate).utc().format() : null,
                                    sid: f.sid?.trim(),
                                });
                            }} />
                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Clear} icon="pi pi-times" className="p-button-warning p-mr-1 p-mb-1" onClick={() => {
                                setPreFilters({});
                            }} />
                        </div>
                    </div>
                </div>


            </div>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {/* <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> */}

                    <DataTable ref={dt}
                        value={activityLogs}
                        dataKey="id"
                        totalRecords={totalRecords}
                        lazy
                        first={first}
                        loading={loading}
                        onPage={onPage}
                        paginator rows={rows}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} feedbacks"
                        globalFilter={globalFilter}
                        emptyMessage="No feedbacks found."
                        header={header}>
                        <Column field="action" header={strings.Action} style={{ width: "250px" }}body={logActionTemplate}></Column>
                        <Column field="status" header={strings.Status} style={{ width: "250px" }}body={customerStatusTemplate}></Column>
                        <Column field="description" header={strings.description} style={{ width: "500px" }}body={customerDescriptionTemplate}></Column>
                        <Column field="email" header={strings.email} style={{ width: "250px" }}body={customerEmailTemplate}></Column>
                        <Column field="ip" header={strings.ipAddress} style={{ width: "250px" }}body={ipTemplate}></Column>
                        <Column field="createdAt" header={strings.createdAt} body={createdAtTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
}
