import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import TaxCategoriesService from '../service/TaxCategoriesService';
import SupplierProductService from '../service/SupplierProductService';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';
import VendorService from '../service/VendorService';
import { Message } from 'primereact/message';
import strings from '../localization';
import SupplierService from '../service/SupplierService';
import vendors from '../store/vendors';
import {connect} from 'react-redux'

class SupplierProductForm extends Component {
    constructor(props) {
        console.log("🚀 ~ file: SupplierProductForm.js ~ line 19 ~ SupplierProductForm ~ constructor ~ props", props.suppliers.find(x=> x.uuid === props.supplierId))
        super(props);

        let emptySupplierProduct = {
            vendorId: "",
            supplierId: "",
            productNumber: "",
            price: "",
            taxCategories:[],
            nameen:"",
            namear:""
        }

        this.state = {
            product: { emptySupplierProduct },
            taxCategories: [],
            Selected : [],
            errors:[],
            vendor:[],
            loading: false,
            pageLoading: false,
            vendors: this.props.vendors,
            suppliers: this.props.suppliers
        };
        this.toast = React.createRef();
    }

     citySelectItems = [
        {label: 'New York', value: 'NY'},
        {label: 'Rome', value: 'RM'},
        {label: 'London', value: 'LDN'},
        {label: 'Istanbul', value: 'IST'},
        {label: 'Paris', value: 'PRS'}
    ];


    FillVendors = async () => {
        let vendorService = new VendorService();
        this.setState({pageLoading: true});
        var vendors = await vendorService.GetVendors(500);
        this.setState({ vendors: vendors.data, pageLoading: false })
    }
    FillSuppliers = async (vendorId) => {
        let supplierService = new SupplierService();
        this.setState({ pageLoading: true });
        var suppliers = await supplierService.GetSuppliers(500, 0, {
            vendorId: vendorId?.code ?? vendorId
        });
        this.setState({ suppliers: suppliers.data, pageLoading: false })
    }

    FillBranches = async () => {
        let { branches, showBranch } = this.props.context??{};
        if (showBranch) {
            this.setState({ branches: this.state.product['vendor']?.branches })
        }
        else
        {
            this.setState({ branches });
        }
    }


    componentDidMount() {
        this.init().then(()=>console.log('init SupplierProductForm finished'))
    }


    init = async ()=>{
        this.FillTaxCategory();
        if(this.props.context?.showCompany) this.FillVendors()
        this.FillBranches();
        if(this.props.product?.uuid)
        {
            this.FillSuppliers(this.props.product?.vendor?.uuid);
            // Fill Data
            this.ReturnData()
        }
        else
        {
            this.setState({
                product: {
                    vendor: {
                        code: this.props.context?.vendor?.uuid,
                        name: this.props.context?.vendor?.name,
                    },
                    branch: {
                        code: this.props.context?.branch?.uuid,
                        name: this.props.context?.branch?.name,
                    },
                    // supplier: {
                    //     code: this.props.product.supplier?.uuid,
                    //     name: this.props.product.supplier?.name,
                    // }
                }
            })
        }
    }


    ReturnData()
    {
        this.setState({
            product: {
                ...this.props.product,
                vendor: {
                    code: this.props.product.supplier.vendor?.uuid,
                    name: this.props.product.supplier.vendor?.name,
                },
                supplier: {
                    code: this.props.product.supplier?.uuid,
                    name: this.props.product.supplier?.name,
                }
            }
        })
    }

    onEditorValueChange(productKey, props, value) {
        let updatedRow = [...props.value];
        updatedRow[props.rowIndex][props.field] = value;
        this.setState({ [`${productKey}`]: updatedRow });
    }

    onSelect(e) {

        // let updatedRow = [...this.state.product];
        // updatedRow.taxCategories = e.value;
        // this.setState({ this.state.pr: citySelectItems });
        let tax = this.state.product.taxCategories
        this.setState({ tax: e.value })
    }



    onInputChange = (e, name) => {
        let val = (e.target && e.target.value) || '';
        let _product = { ...this.state.product };

        if(name=='discountPercentage')
        {
            if(val > 100)
            {
                val = 100;
            }

            if(val < 0)
            {
                val = 0;
            }
        }
        _product[`${name}`] = val;

        this.setState({ product: _product });
    }


    FillTaxCategory = async () => {
        let taxCategoriesService = new TaxCategoriesService();
        this.setState({pageLoading: true})
        var taxes = await taxCategoriesService.GetTaxCategory(50,0);
        this.setState({Selected:taxes.data?.filter(tx=>tx.taxCategoryCode=='S'&&tx.percent==15).map((tx)=>tx.uuid)})

        let newTax = taxes.data?.map((row) => {
            return {
                "label": row.name,
                "value": row.uuid
            };
        })


        this.setState({taxCategories:newTax, pageLoading: false });

    }



    GetData = () => {
        // console.log(this.state.user);

    }


     delete = (rowData) => {
        // let _SupplierProduct = {...this.state.product};
        // _SupplierProduct.rows = _SupplierProduct.rows.filter(val => val.identityId == rowData.identityId);
        // this.setState({customer:_customer});
        // this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'SupplierProduct Deleted', life: 3000 });
    }

    save = async () => {
        this.setState({ loading: true });

        let { product } = this.state;

        let translations = [];

        if((product.nameen != null && product.nameen != '') || product.decriptionen != null)
        {
            translations.push({
                "name": product.nameen,
                "description": product.decriptionen,
                "locale": 1
            })
        }
        if ((product.namear != null && product.namear != '') || product.decriptionar != null) {
            translations.push({
                "name": product.namear,
                "description": product.decriptionar,
                "locale": 2
            })
        }

        let data = {
            "vendorId": this.props.context?.vendor?.uuid ?? (product.vendor?.code) ,
            "branchId": this.props.context?.branch?.uuid ?? (product.branch?.code),
            "supplierId": this.props.context?.supplier?.uuid ?? (product.supplier?.code),
            "productNumber": product.productNumber,
            "price": product.price,
            "taxCategories": this.state.Selected,
            "translations": translations,
            "isActive": true,
        }
        var productService = new SupplierProductService();

        if (this.props.product == null) {
            let res = await productService.CreatesupplierProduct(data);
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors });
            } else {
                this.props.FillData(data.vendorId)
                this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'product Created', life: 3000 });
                this.props.OnHideDialog();
                this.setState({
                    product: {
                        vendorId: "",
                        productNumber: "",
                        price: "",
                        unitCode: "",
                        taxCategories: [],
                        nameen: "",
                        namear: ""
                    },
                    errors: [],
                    Selected: []
                });
            }
        }
        else {
            data.uuid = this.props.product.uuid;
            let res = await productService.UpdatesupplierProduct(data);
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors });
            } else
            {
                this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'product Updated', life: 3000 });
                this.props.OnHideDialog();
                this.props.FillData();
            }
        }
        this.setState({ loading: false });
    }


    render() {
        if(this.state.pageLoading) {
            return <div style={{textAlign:'center'}}><ProgressSpinner/></div>
        }
        let {showCompany, showSupplier=true} = this.props.context??{};
        let image = this.state.product.image;
        const isRTL = localStorage.getItem('lang')== 'ar' ? 'rtl' : 'ltr';

        return (

            <div className="p-grid crud-demo" style={{direction:isRTL}}>
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={this.toast} />
                        <div className="p-field">
                            {showCompany ? <label htmlFor="name">{strings.Vendor}*</label> : ''}
                            {showCompany ? <Dropdown id="vendor" value={this.state.product?.vendor} onChange={(e) => {
                                let product = {...this.state.product}
                                product['vendor'] = e.value;
                                this.setState({ product }, ()=>{
                                    this.FillBranches().then(()=>this.FillSuppliers(e.value))
                                });
                            }} options={this.state.vendors?.map((v) => {
                                return {
                                    code: v.uuid,
                                    name: v.name
                                }
                            })} optionLabel="name" placeholder={strings.Select}></Dropdown> : ''}
                        </div>
                        <div className="p-field">
                            {showSupplier ? <label htmlFor="name">{strings.supplier}*</label> : ''}
                            {showSupplier ? <Dropdown id="supplier" value={this.state.product?.supplier } onChange={(e) => {
                                let product = {...this.state.product}
                                product['supplier'] = e.value;
                                this.setState({ product })
                            }} options={this.state.suppliers?.map((v) => {
                                return {
                                    code: v.uuid,
                                    name: v.name
                                }
                            })} optionLabel="name" placeholder={strings.Select}></Dropdown> : ''}
                        </div>

                        <div className="p-field">
                            <label htmlFor="name">{strings.NameEnglish}</label>
                            <InputText id="nameen" value={this.state.product.nameen} onChange={(e) => this.onInputChange(e, 'nameen')} required autoFocus />
                        </div>

                        <div className="p-field">
                            <label htmlFor="name">{strings.NameArabic}</label>
                            <InputText id="namear" value={this.state.product.namear} onChange={(e) => this.onInputChange(e, 'namear')} required autoFocus />
                        </div>


                        <div className="p-grid">
                        <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.Price}*</label>
                                    <InputText id="price" type="number" value={this.state.product.price} onChange={(e) => this.onInputChange(e, 'price')} required autoFocus />
                                </div>
                            </div>

                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.ProductNumber}</label>
                                    <InputText id="productNumber" value={this.state.product.productNumber} onChange={(e) => this.onInputChange(e, 'productNumber')} required autoFocus />
                                </div>
                            </div>
                        </div>

                        <div className="p-field">
                            <label htmlFor="name">{strings.TaxCategories}</label>
                            <MultiSelect value={this.state.Selected} onChange={(e) => { this.setState({ Selected: e.value })}}  options={this.state.taxCategories}/>
                        </div>

                        <div className="p-field">
                            {this.state.errors?.length > 0 && <Message text={this.state.errors.map((m) => <li>{strings[m]??m}</li>)} severity="error" />}
                            <Button label={strings.Save} loading={this.state.loading} onClick={this.save} loadingoptions={{ position: 'right' }} />
                        </div>

                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    suppliers: state.suppliers.suppliers,
    vendors: state.vendors.vendors.data
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierProductForm)
