import React, {useEffect, useState} from "react";
import strings from './localization/index'



const UserManual = (props) => {
    return(
        <>
        { strings.getLanguage() !== 'ar' ?
        <iframe src="/E-invoicing-User-Manual-English.pdf" width="100%" height="100%" allow="autoplay"></iframe> :
        <iframe src="/ارشادات استخدام-نظاملفوتير-الالكترونية-AR.pdf" width="100%" height="100%" allow="autoplay"></iframe>}
        </>
    )
}

export default UserManual;