import { createSlice } from "@reduxjs/toolkit"
import InvoiceCon from '../service/InvoiceService'
const InvoiceService = new InvoiceCon()
const invoices = createSlice({
    name: 'invoices',
    initialState: {invoice: {}, invoices: [] },
    reducers: {
        addInvoices(state, action) {
            return {...state, invoices: action.payload}
        },
        addInvoice(state, action) {
            return {...state, invoice: action.payload}
        }
    }
})

export const getInvoicesHandler = payload => async (dispatch, state) => {
    try {
        let invoicesBatch = await InvoiceService.GetInvoices()
        dispatch(addInvoices(invoicesBatch.data))
    } catch (error) {
        console.log("🚀 ~ file: invoices.js ~ line 17 ~ getInvoicesHandler ~ error", error)

    }
}

export const getInvoiceHandler = (payload) => async (dispatch)=>{
    try {
        let {invoice} = await InvoiceService.GetInvoice(payload) 
        dispatch(addInvoice(invoice.invoice))
    } catch (error) {
    console.log("🚀 ~ file: invoices.js ~ line 28 ~ getInvoiceHandler ~ error", error)
        
    }
}

export const {addInvoices,addInvoice} = invoices.actions

export default invoices.reducer


