import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import moment from 'moment';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
// import ZatcaVatReportService from '../service/ZatcaVatReportService';
import { defaultTableConfiguration } from './TableConfiguration';
import { Checkbox } from 'primereact/checkbox';
import strings from '../localization';
import InvoiceReportingService from '../service/InvoiceReportingService';
import JsFileDownloader from 'js-file-downloader';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { formatDate } from './InvoiceDates';
import VendorService from '../service/VendorService';
import { Message } from 'primereact/message';


export const ZatcaVatReports = ({context}) => {

    let emptyZatcaVatReport = {};

    const [zatcaVatReportDialog, setZatcaVatReportDialog] = useState(false);
    const [deleteZatcaVatReportDialog, setDeleteZatcaVatReportDialog] = useState(false);
    const [deleteZatcaVatReportsDialog, setDeleteZatcaVatReportsDialog] = useState(false);
    const [zatcaVatReport, setZatcaVatReport] = useState(emptyZatcaVatReport);
    const [selectedZatcaVatReports, setSelectedZatcaVatReports] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [zatcaVatReports, setZatcaVatReports] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [first, setFirst] = useState(0);
    const [saving, setSaving] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        if (vendors.length == 0) {
            var vendorService = new VendorService();
            vendorService.GetVendors(500).then((data) => {
                if (!data.data) return [];
                return data.data.map(v => {
                    return { code: v.uuid, name: v.name, ...v }
                });
            }).then(data => {
                setVendors(
                    [{ code: null, name: '-' }, ...data]);
            });
        }
    }, [localStorage.getItem('subscription')]);

    useEffect(() => {
        // const zatcaVatReportService = new ZatcaVatReportService();
        // zatcaVatReportService.getZatcaVatReports().then(data => setZatcaVatReports(data));
        onPage({rows,page:0})
    }, []);


    const openNew = () => {
        setZatcaVatReport(emptyZatcaVatReport);
        setSubmitted(false);
        setZatcaVatReportDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setZatcaVatReportDialog(false);
    }

    const hideDeleteZatcaVatReportDialog = () => {
        setDeleteZatcaVatReportDialog(false);
    }

    const hideDeleteZatcaVatReportsDialog = () => {
        setDeleteZatcaVatReportsDialog(false);
    }

    const saveZatcaVatReport = async () => {
        setSubmitted(true);
        setSaving(true);
        var zatcaVatReportService = new InvoiceReportingService();

        zatcaVatReport.vendorId = zatcaVatReport.vendor?.code ?? context.company?.uuid;

        await zatcaVatReportService.GenerateZatcaVatReport(zatcaVatReport);
        setSaving(false);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Profit & Loss report Created', life: 3000 });
        onPage({ rows, page })
        // setZatcaVatReports(_zatcaVatReports);
        setZatcaVatReportDialog(false);
        setZatcaVatReport(emptyZatcaVatReport);
    }

    const editZatcaVatReport = (zatcaVatReport) => {
        setZatcaVatReport({ ...zatcaVatReport });
        setZatcaVatReportDialog(true);
    }

    const confirmDeleteReport = (zatcaVatReport) => {
        // setZatcaVatReport(zatcaVatReport);
        setDeleteZatcaVatReportDialog(true);
    }

    const deleteZatcaVatReport = async () => {
        await (new InvoiceReportingService().DeleteZatcaVatReport(zatcaVatReport))
        setDeleteZatcaVatReportDialog(false);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'ZatcaVatReport Deleted', life: 3000 });
        onPage({ rows, page })
    }


    const changeValue = (e) => {
        let i = { ...zatcaVatReport };

        i[e.target.id] = e.target.value;

        setZatcaVatReport(i);
    }
    const onTranslationChange = (e, locale) => {
        const val = (e.target && e.target.value) || '';
        let _zatcaVatReport = { ...zatcaVatReport };
        _zatcaVatReport.translations[locale-1].name = val;
        setZatcaVatReport(_zatcaVatReport);
    }

    const onCheckBoxChange = (e) => {
        const val = e.checked;
        let _zatcaVatReport = { ...zatcaVatReport };
        _zatcaVatReport[e.target.id] = val;
        setZatcaVatReport(_zatcaVatReport);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }



    const fromTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{strings.from}</span>
                {moment.utc(rowData.from).local().format('DD/MM/YYYY, h:mm:ss a')}
            </>
        );
    }

    const toTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{strings.to}</span>
                {moment.utc(rowData.to).local().format('DD/MM/YYYY, h:mm:ss a')}
            </>
        );
    }

    const showAllOptionsTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Link to account</span>
                {rowData.showAllOptions ? 'Yes' : 'No'}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-file-pdf" style={{width:'100%'}} onClick={() => {
                    new JsFileDownloader({
                        url: rowData.pdfFile,
                        headers: [{
                            name: 'Authorization',
                            value: `Bearer ${localStorage.getItem('token')}`
                        }]
                    })
                        .then(function () {
                            toast.current.show({ severity: 'success', summary: 'Downloaded Report', life: 3000 });
                        })
                        .catch(function (error) {
                            toast.current.show({ severity: 'error', summary: 'Cannot Download Report', life: 3000 });
                        });
                }} tooltip={strings.pdf}> </Button>
                {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteReport(rowData)} /> */}
            </div>
        );
    }

    const onPage = ({ first,
        rows,
        page }) => {
        const zatcaVatReportService = new InvoiceReportingService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        zatcaVatReportService.GetZatcaVatReports({ limit: rows, offset: (page) * rows }).then(({ data, count }) => {
            setLoading(false);
            setZatcaVatReports(data);
            setTotalRecords(count);
        });

    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">{strings.zatcaVatReports}</h5>
            <span className="p-input-icon-left">
                {/* <i className="pi pi-search" /> */}
                {/* <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." /> */}
            </span>
        </div>
    );

    const zatcaVatReportDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" loading={saving} icon="pi pi-check" className="p-button-text" onClick={saveZatcaVatReport} />
        </>
    );
    const deleteZatcaVatReportDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteZatcaVatReportDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteZatcaVatReport} />
        </>
    );
    const deleteZatcaVatReportsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteZatcaVatReportsDialog} />
            {/* <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedZatcaVatReports} /> */}
        </>
    );

    var items = [
        {
            hasCorrection: true,
            hasVat: false,
            calcVat: true,
            hasTotal: true
        },
        {
            hasCorrection: true,
            hasVat: false,
            hasTotal: true
        },
        {
            hasCorrection: true,
            hasVat: false,
            hasTotal: true

        },
        {
            hasCorrection: true,
            hasVat: false,
            hasTotal: true
        },
        {
            hasCorrection: true,
            hasVat: false,
            hasTotal: true
        },
        {
            // hasCorrection: true,
            // hasVat: true,
            summaryRow: true,
            // hasTotal: true
        },
        {
            hasCorrection: true,
            hasVat: true,
            calcVat: true,
            hasTotal: true
        },
        {
            hasCorrection: true,
            hasVat: true,
            calcVat: true,
            hasTotal: true
        },
        {
            hasCorrection: true,
            hasVat: true,
            calcVat: true,
            hasTotal: true
        },
        {
            hasCorrection: true,
            hasVat: false,
            hasTotal: true
        },
        {
            hasCorrection: true,
            hasVat: false,
            hasTotal: true
        },
        {
            // hasCorrection: true,
            // hasVat: false,
            summaryRow: true,
            // hasTotal: true
        },

        {
            hasCorrection: false,
            hasVat: true,
            hasTotal: false
        },
        {
            hasCorrection: false,
            hasVat: true,
            hasTotal: false
        }
    ]

    const onChange = (e) => {
        let i = { ...zatcaVatReport };
        i[e.target.id] = e.target.value;
        setZatcaVatReport(i);
    }


    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}></Toolbar>

                    <DataTable
                        {...defaultTableConfiguration}
                        value={zatcaVatReports}
                        selection={selectedZatcaVatReports}
                        totalRecords={totalRecords}
                        first={first}
                        loading={loading}
                        onPage={onPage}
                        rows={rows}
                        header={header}>
                        <Column field="vendor" header={strings.Vendor} body={(rowData)=>rowData.vendor?.name}></Column>
                        <Column field="from" header={strings.from} body={fromTemplate}></Column>
                        <Column field="to" header={strings.to} body={toTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={zatcaVatReportDialog} style={{ width: '100%' }} header="Payment Type Details" modal className="p-fluid" footer={zatcaVatReportDialogFooter} onHide={hideDialog}>
                        <div className="p-grid crud-demo">
                        <div className="p-col-12">

                        <div className="p-field">
                            {context.showCompany ? <label htmlFor="name">{strings.Vendor}*</label> : ''}
                            {context.showCompany ? <Dropdown id="vendor" value={zatcaVatReport?.vendor} onChange={(e) => {
                                let zatcaVatReport1 = { ...zatcaVatReport }
                                zatcaVatReport1['vendor'] = e.value;
                                setZatcaVatReport(zatcaVatReport1);
                            }} options={vendors?.map((v) => {
                                return {
                                    ...v,
                                    code: v.uuid,
                                    name: v.name
                                }
                            })} optionLabel="name" placeholder={strings.Select}></Dropdown> : <p>{}</p>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">{strings.from}</label>
                            <Calendar id="from" showTime={false} showSeconds={false} showIcon showButtonBar value={formatDate(zatcaVatReport.from)} onChange={changeValue}/>
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">{strings.to}</label>
                            <Calendar id="to" showTime={false} showSeconds={false} showIcon showButtonBar value={formatDate(zatcaVatReport.to)} onChange={changeValue}/>
                        </div>
                        <div className="">
                            <table style={{width:'100%', border:"none", borderColor: 'transparent'}}>
                                <thead>
                                    <tr>
                                        <th style={{width:'2.5%'}}>#</th>
                                        <th></th>
                                        <th>{strings.totalInSar}</th>
                                        <th>{strings.correctionsInSar}</th>
                                        <th>{strings.totalVatInSar}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((item, index)=>{
                                        var pointNumber = index + 1;
                                        if(item.summaryRow)
                                        {
                                            return <>
                                                <tr>
                                                    <td>
                                                        {pointNumber}
                                                    </td>
                                                    <td style={{ textAlign: 'left', width: '25%', backgroundColor: "silver" }}>
                                                        <b>{strings[`zatcaPoint${pointNumber}`]}</b>
                                                    </td>
                                                    <td>
                                                        {strings.autoGenerated}
                                                        {/* {<InputText id={`point${pointNumber}`} value={zatcaVatReport[`point${pointNumber}`]} onChange={onChange} /> : ''} */}
                                                    </td>
                                                    <td>
                                                    {strings.autoGenerated}
                                                    </td>
                                                    <td>
                                                    {strings.autoGenerated}
                                                    </td>
                                                </tr>
                                                <tr style={{backgroundColor:"Highlight"}}>
                                                    <td>
                                                    </td>
                                                    <td style={{ textAlign: 'left', width: '25%', backgroundColor: "silver" }}>
                                                    </td>
                                                    <td>
                                                    </td>
                                                    <td>
                                                    </td>
                                                    <td>
                                                    </td>
                                                </tr>
                                            </>
                                        }
                                        return (<tr>
                                        <td>
                                            {pointNumber}
                                        </td>
                                        <td style={{textAlign: 'left', width:'25%', backgroundColor:"silver"}}>
                                            <b>{strings[`zatcaPoint${pointNumber}`]}</b>
                                        </td>
                                        <td>
                                            {item.hasTotal ? <InputText type={"number"} id={`point${pointNumber}`} value={zatcaVatReport[`point${pointNumber}`]} onChange={onChange} /> : ''}
                                        </td>
                                        <td>
                                            {item.hasCorrection ? <InputText type={"number"} id={`point${pointNumber}Correction`} value={zatcaVatReport[`point${pointNumber}Correction`]} onChange={onChange} /> : ''}
                                        </td>
                                        <td>
                                            {item.hasVat ? <InputText  type={"number"} id={`point${pointNumber}Vat`} value={zatcaVatReport[`point${pointNumber}Vat`]} onChange={onChange} /> : ''}
                                        </td>
                                    </tr>)
                                    })}
                                </tbody>
                            </table>
                            </div>
                        </div>
                        </div>


                        {/* <div className="">
                            <Checkbox inputId="cb1" id="isRealized" checked={zatcaVatReport.isRealized} value={zatcaVatReport.isRealized} onChange={onCheckBoxChange} required autoFocus />
                            <label htmlFor="cb1" className="p-checkbox-label" style={{marginLeft:'2px'}}>{strings.isRealized}</label>
                            <br/>
                            <br/>
                            <Message content={<b>{zatcaVatReport.isRealized ? strings.realizedMessage : strings.nonRealizedMessage}</b>}> </Message>
                        </div> */}
                    </Dialog>

                    <Dialog visible={deleteZatcaVatReportDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteZatcaVatReportDialogFooter} onHide={hideDeleteZatcaVatReportDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {zatcaVatReport && <span>Are you sure you want to delete <b>{zatcaVatReport.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteZatcaVatReportsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteZatcaVatReportsDialogFooter} onHide={hideDeleteZatcaVatReportsDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {zatcaVatReport && <span>Are you sure you want to delete the selected payment type?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
