// ES6 module syntax
import LocalizedStrings from 'react-localization';
import ar from './ar';
import en from './en';

const strings = new LocalizedStrings({
 en:en,
 ar:ar
});


strings.setLanguage(localStorage.getItem('lang')??'ar')

export default strings;
