import { config } from 'react-transition-group';
import ApiService from './ApiService';
let api = process.env.REACT_APP_API_URL

export default class WalletService extends ApiService {

    constructor() {
        super();
        this.path = '/wallet';
    }

    async GetWallet() {
        try {
            let {data} = await this.Read(`${this.path}`);
            return data;
        } catch(e) {
            return e.response.data;
        }
    }
    async GetWalletTransactions(query) {
        try {
            let {data} = await this.Read(`${this.path}/transactions?${query}`);
            return data;
        } catch(e) {
            return e.response.data;
        }
    }
    async CreateWalletTransaction(body) {
        try {
            let {data} = await this.Create(`${this.path}/transaction`, body);
            return data;
        } catch(e) {
            return e.response.data;
        }
    }
    async GetWalletTransaction(id) {
        try {
            let {data} = await this.Read(`${this.path}/transaction/${id}`);
            return data;
        } catch(e) {
            return e.response.data;
        }
    }
    async RedeemWalletCredits(body) {
        try {
            let {data} = await this.Create(`${this.path}/redeem`, body);
            return data;
        } catch(e) {
            return e.response.data;
        }
    }
}
