import { Component } from 'react'
import axios from 'axios';
import strings from './localization';
import SubscriptionService from './service/SubscriptionService';
import { Message } from 'primereact/message';
import WalletService from './service/WalletService';

let API = process.env.REACT_APP_API_URL;

class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            validate: false,
            vendor: {},
            plans: [],
            loading: true,
            method:'online',
            checked: false,
            charge: null
        }
        // this.onLoad();
    }

    componentDidUpdate(prevProps,prevState) {

        let e = document.querySelector('.nav-link')
        let x = document.querySelector('.nav-item')
        let y = document.querySelector('.navbar')

        if(e && x && y) {

            let c = e.style.color
            // document.querySelectorAll('.nav-link').forEach(i => i.style.color = 'white')
            document.querySelectorAll('div').forEach((i,idx) => {
                if(idx === 1){
                    i.style.backgroundColor ='#fff'
                    return
                }
            } )

            // x.style.color = '#95a8be'
            // y.style.backgroundColor = '#95a8be'
        }
    }

    init = () => {
        let tap_id = new URLSearchParams(window.location.search).get("tap_id");
        let planId = new URLSearchParams(window.location.search).get("planId");
        let freq = new URLSearchParams(window.location.search).get("freq");
        let transactionId = new URLSearchParams(window.location.search).get("transactionId");
        let user = JSON.parse(localStorage.getItem("user"));
        let paymentToken = new URLSearchParams(window.location.search).get("token");
        if (tap_id&&tap_id.startsWith('chg_')) {
            axios.get(`${API}/charge/${tap_id}`).then(({ data }) => {
                this.setState({ charge: data });
                this.setState({ loading: false });
                if (data.status == 'CAPTURED') {
                        const payload = {
                            subscriptionInterval: Number(freq),
                            tapId: data.id,
                            paymentToken: paymentToken,
                            userId: user.id,
                            subscriptionPlanId: planId,
                            userEmail: user.email,
                            userName: user.name,
                            userPhone: user.phone,
                        }

                    this.setState({ renewingSubscription: true }, () => {
                        new SubscriptionService().RenewSubscription(payload).then(() => {
                            this.setState({ reedemingWallet: true, renewingSubscription: false }, () => {
                                new WalletService().GetWalletTransaction(transactionId).then((data) => {
                                    new WalletService().RedeemWalletCredits({ amount: data.amount, transactionId }).then(() => {
                                        this.setState({ reedemingWallet: false })
                                        window.location.href = '/'
                                    })
                                })
                            })
                            console.log('Renewed sub');
                        })

                    })
                    }

            }).catch(() => {
                this.setState({ loading: false });
                this.setState({ error: "internal_server_error" });
            });
        } else {
            axios.get(`${API}/subscriptionPlan/${planId}`, {
                headers: {
                    "Accept-Language": strings.getLanguage(),
                }
            }).then(({ data }) => {
                this.setState({ plan: data });
                this.setState({ loading: false });
            }).catch(() => {
                this.setState({ plan: null });
                this.setState({ loading: false });
            });
        }
    }

    componentDidMount() {
        this.init();
    }


    submitForm = () => {
        var planId = new URLSearchParams(this.props.location.search).get("planId")

        axios.post(`${API}/subscription`, {
            SubscriptionPlanId: planId,
            UserEmail: this.state.user.email,
            UserPhone: this.state.user.phone,
            UserName: this.state.user.name,
            SubscriptionInterval: 7
        }).then(() => {
            this.setState({
                charge: {
                    status: "AskedForQuota"
                }
            })
        });
    }

    changeUserField = (e) => {
        let user = { ...this.state.user }
        user[e.target.name] = e.target.value;
        this.setState({ user })
    }

    update = () => {
        this.setState({ validate: true })
    }

    render() {


        // let w = e.offsetWidth
        // console.log("🚀 ~ file: Checkout.js ~ line 107 ~ Checkout ~ render ~ w", w)
        // console.log("🚀 ~ file: Checkout.js ~ line 106 ~ Checkout ~ render ~ h", h)
        let { charge, loading, error } = this.state;
        let freq = new URLSearchParams(window.location.search).get("freq")
        let planId = new URLSearchParams(window.location.search).get("planId")

        if(error)
        {
            return <div className="container">
            <div className="row">
                <div className="col-md-12 py-5 text-center">
                    <div className="payment-card error">
                        <div className="mark error">
                            <i className="checkmark">!</i>
                        </div>
                        <h1 className="error">{strings.internalServerError}</h1>
                    </div>
                </div>
            </div>
        </div>
        }

        if (!planId) {
            return <div>Error: pick a plan</div>
        }

        if (loading) {
            return <div className="container">
                <div className="row">
                    <div className="col-md-12 py-5 text-center">
                        <h1>{strings.loading}</h1>
                    </div>
                </div>
            </div>
        }

        if (charge) {
            if (charge.status == "CAPTURED") {

                return <div className="p-grid">
                    <div style={{ width: '100%' }} className="card">
                        {this.state.renewingSubscription ? <div className="p-md-12 p-col-12">
                            <Message style={{ width: '100%' }} content={<>Renewing subscription...</>} severity="info">
                            </Message>
                        </div>:''}
                        {this.state.reedemingWallet?<div className="p-md-12 p-col-12">
                            <Message style={{ width: '100%' }} content={<>Reedeming Wallet...</>} severity="info">
                            </Message>
                        </div>:''}
                        <div className="p-md-12 p-col-12">
                            <Message style={{ width: '100%' }} content={<>{strings.paymentSuccess}: {strings.paymentSuccessMessage}</>} severity="success">
                            </Message>
                        </div>
                        <div className="p-md-12 p-col-12">
                            <Message style={{ width: '100%' }} content={<span>{strings.status}: {charge.status}</span>} severity="success" />
                        </div>
                        <div className="p-md-12 p-col-12">
                            <Message style={{ width: '100%' }} content={<span>{strings.chargeId}: {charge.id}</span>} severity="success"/>
                        </div>
                    </div>
                </div>

            } else if (charge.status == "AskedForQuota") {
                return (
                    <div className="p-grid">
                    <div style={{ width: '100%' }} className="card">
                        <div className="p-md-12 p-col-12">
                            <Message style={{ width: '100%' }} content={<>{strings.quotaSuccess}: {strings.quotaSuccessMessage}</>} severity="success">
                            </Message>
                        </div>
                    </div>
                </div>
                )
            }
            else {
                return <div className="p-grid">
                    <div style={{ width: '100%' }} className="card">
                        <div className="p-md-12 p-col-12">
                            <Message style={{ width: '100%' }} content={<>{strings.paymentFailure}: {strings.paymentFailureMessage}</>} severity="error">
                            </Message>
                        </div>
                        <div className="p-md-12 p-col-12">
                            <Message style={{ width: '100%' }} content={<span>{strings.status}: {charge.status}</span>} severity="error" />
                        </div>
                        <div className="p-md-12 p-col-12">
                            <Message style={{ width: '100%' }} content={<span>{strings.chargeId}: {charge.id}</span>} severity="error"/>
                        </div>
                    </div>
                </div>
            }
        }
    }
}

export default Checkout;

