import { createSlice } from "@reduxjs/toolkit"

const quotation = createSlice({
    name: 'quotation',
    initialState : {msg:'', quotation: {}, quotations: {data:[], count : 0}},
    reducers : {
        addData (state, action) {
            return {...state, ...action.payload}
        },
        addMsg(state, action) {
            return {...state, msg:action.payload}
        }
    }
})

export default quotation.reducer

export const {addData, addMsg} = quotation.actions