import ApiService from './ApiService';

export default class ComplianceService extends ApiService {

    constructor() {
        super();
        this.path = '/compliance';
    }

    async ConfigureComplianceSettings(data) {
        let link = `${this.path}/profile/configure`
        let op = await this.Update(link, data);
        return op.data;
    }

}
