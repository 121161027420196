import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { SplitButton } from 'primereact/splitbutton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import querystring from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import Can, { check } from '../Can';
import strings from '../localization';
import rules from '../rbac-rules';
import ProductCategoryService from '../service/ProductCategoryService';
import UserService from '../service/UserService';
import VendorService from '../service/VendorService';
import { ProductCategoryForm } from './ProductCategoryForm';
import { formatDateStringLocal } from './InvoiceDates';

export const ProductCategories = ({vendor, context, full}) => {



    const [productCategoryDialog, setProductCategoryDialog] = useState(false);
    const [deleteProductCategoryDialog, setDeleteProductCategoryDialog] = useState(false);
    const [productCategories, setProductCategories] = useState(null);
    const [productCategory, setProductCategory] = useState(null);
    const [preFilters, setPreFilters] = useState({});
    const [filters, setFilters] = useState({});
    const [totalRecords, setTotalRecords] = useState(0);
    const [vendors, setVendors] = useState(null);

    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    let role = new UserService().GetRole();

    useEffect(() => {

        onPage({ rows, page: 0,vendorID:vendor?.uuid})
    }, [localStorage.getItem('subscription')]);



    const openNew = () => {


        setProductCategoryDialog(true);
    }

    const hideDialog = () => {

        setProductCategoryDialog(false);

        setProductCategory(null);
    }

    const hideDeleteProductCategoryDialog = () => {
        setDeleteProductCategoryDialog(false);
        setProductCategory(null);
    }

    const edit = (obj) => {
        setProductCategory({
            ...obj,
            namear:  obj.translations.filter(t=>t.cultureName=='Ar')[0]?.label,
            nameen: obj.translations.filter(t=>t.cultureName=='En')[0]?.label,
            uuid: obj.uuid
        })




        setProductCategoryDialog(true);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Can yes={() => <Button label={strings.New} icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />}
                    perform={'productCategories:create'}
                    silent={true}
                    role={role} />
            </React.Fragment>
        )
    }



    const productCategoryNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ProductCategory Number</span>
                {rowData.productCategoryNumber}
            </>
        );
    }

    const nameenBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                {/* <img src={`${rowData.imageUrl}`} alt={rowData.imageUrl} className="productCategory-image" /> */}
                {rowData.translations.filter(t=>t.cultureName=='En')[0]?.label}
            </>
        )
    }

    const namearBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name ar</span>
                {/* <img src={`${rowData.imageUrl}`} alt={rowData.imageUrl} className="productCategory-image" /> */}
                {rowData.translations.filter(t=>t.cultureName=='Ar')[0]?.label}
            </>
        )
    }

    const skuBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">peppol</span>
                {rowData.peppol}
            </>
        );
    }

    const vendorBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Vendor</span>
                {rowData.vendor?.name}
            </>
        );
    }

    const barcodeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {rowData.price}
            </>
        );
    }

    const deleteProductCategory = () => {
        const productCategoryService = new ProductCategoryService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        productCategoryService.DeleteProductCategory(productCategory.uuid).then((data) => {
            if (data.errorCode) {
                toast.current.show({ severity: 'error', summary: 'Cannot Delete ProductCategory', detail: data.message, life: 3000 });
                setLoading(false);
                hideDeleteProductCategoryDialog();
                return;
            }
            toast.current.show({ severity: 'success', summary: 'Delete ProductCategory', detail: `Deleted ${productCategory.name} successfully`, life: 3000 });
            productCategoryService.GetProductCategory(rows, (page) * rows).then(({ data, count }) => {
                setLoading(false);
                setProductCategories(data);
                setTotalRecords(count);
            });
        }).finally(() => {
            hideDeleteProductCategoryDialog();
        }).catch(() => setLoading(false));
        setProductCategory(null);
    }
    const deleteProductCategoryDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductCategoryDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProductCategory} />
        </>
    );

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {rowData.price}
            </>
        );
    }

    const creationDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Created At</span>
                {formatDateStringLocal(rowData.createdAt)}
            </>
        );
    }

    const confirmDeleteProductCategory = (productCategory) => {

        setDeleteProductCategoryDialog(true);
        setProductCategory(productCategory);
    }

    const actionBodyTemplate = (rowData) => {
        const items = []

        var userService = new UserService();
        var role = userService.GetRole();

        if (check(rules, role, 'productCategories:update')) {
            items.push({ label: strings.edit, icon: 'pi pi-pencil', command:()=>edit(rowData) });
        }

        if (check(rules, role, 'productCategories:delete')) {
            items.push({ label: strings.deleteProductCategory, icon: 'pi pi-trash', command:(e)=>confirmDeleteProductCategory(rowData) });
        }

        items.push({ label: strings.copyId, icon: 'pi pi-copy', command:(e)=>  navigator.clipboard.writeText(rowData.uuid) });

        let first = items.shift();

        return first ? <SplitButton label={first.label} icon={first.icon} onClick={()=>first.command()} model={items}></SplitButton> : ''

        // return (
        //     <div className="actions">
        //         <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => edit(rowData)} />
        //         <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteProductCategory(rowData)} />
        //         {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteProductCategory(rowData)} /> */}
        //     </div>
        // );
    }

    const onPage = ({ first,
        rows,
        page,
        pageCount }) => {
        const productCategoryService = new ProductCategoryService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        productCategoryService.GetProductCategory(rows, (page) * rows, vendor?.uuid??'').then(({ data, count }) => {
            setLoading(false);
            setProductCategories(data);
            setTotalRecords(count);
        });

        setProductCategory(null);

    }

    const onFiltersChange = (e) => {
        let filters = { ...preFilters };
        filters[e.target.id] = e.target.value;
        setPreFilters(filters);
    }

    function formatDate(date) {
        if (!date) return null;
        return new Date(moment(date).format('yyyy-MM-DD HH:mm:ss'))
    }

    useEffect(() => {
        var vendorService = new VendorService();
        vendorService.GetVendors(500).then((data)=>{
            if(!data.data)
            {
                return [{code:null, name: '-'}]
            }
            return data.data.map(v => {
                return { code: v.uuid, name: v.name, ...v }
            });
        }).then(data=>{
            setVendors(
                [{code:null, name: '-'}, ...data]);
        });
    }, []);

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            const productCategoryService = new ProductCategoryService();
            setLoading(true);
            productCategoryService.GetProductCategory(rows, 0,filters.vendorId??"", querystring.stringify(filters)).then(({ data, count }) => {
                setTotalRecords(count);
                setLoading(false);
                setProductCategories(data)
            });
        }
    }, [filters]);

    const header = (

        <div className="p-grid">

            <div className="p-col">

                <div className="table-header">

                    <h5 className="p-m-0">{strings.ManageProductCategories}</h5>
                </div>

            </div>

            <div className="p-col-12">

                <div className="card">

                    <div className="p-fluid p-grid">

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.Name}</label>

                            <InputText id="label" value={preFilters.label} onChange={onFiltersChange} />

                        </div>

                        <div className="p-field p-col-10" style={{ margin: 'auto' }}>
                        <hr/>

                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Filter} icon="pi pi-search" className="p-button-success p-mr-1 p-mb-1" onClick={() => {
                                let f = { ...preFilters }
                                // 2021-09-21T21:50:37.000Z
                                setFilters({
                                    label: f.label,
                                });
                            }} />
                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Clear} icon="pi pi-times" className="p-button-warning p-mr-1 p-mb-1" onClick={() => {
                                setPreFilters({});
                            }} />
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );

    const productCategoryDialogFooter = (
        <>
            <Button label={strings.Cancel} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} ></Toolbar>

                    <DataTable ref={dt}
                        value={productCategories}
                        dataKey="id"
                        totalRecords={totalRecords}
                        lazy
                        autoLayout
                        first={first}
                        loading={loading}
                        onPage={onPage}
                        paginator rows={rows}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Vendors"
                        globalFilter={globalFilter}
                        emptyMessage={strings.Norecordsfound}
                        header={header}>
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                        <Column field="id" header={strings.ProductCategoryNumber}  body={productCategoryNumberBodyTemplate}></Column>
                        <Column field="nameen" header={strings.NameEnglish}  body={nameenBodyTemplate} ></Column>
                        <Column field="namear" header={strings.NameArabic}  body={namearBodyTemplate} ></Column>
                        {context?.showCompany && <Column field="vendor" header={strings.Vendor}  body={vendorBodyTemplate}></Column>}
                        <Column field="createdAt" header={strings.CreationDate}  body={creationDateBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={productCategoryDialog} style={{ width: '700px' }} header="ProductCategories Details" modal className="p-fluid" footer={productCategoryDialogFooter} onHide={hideDialog}>
                        <ProductCategoryForm context={context} full={full} OnHideDialog={hideDialog} productCategory={productCategory} vendor={localStorage.getItem('company')}  FillData={() => onPage({ rows, page: 0,vendorID:localStorage.getItem('company') })} />
                    </Dialog>

                    <Dialog visible={deleteProductCategoryDialog} style={{ width: '450px' }} header={strings.deleteProductCategory} modal footer={deleteProductCategoryDialogFooter} onHide={hideDeleteProductCategoryDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {<span>Are you sure you want to delete <b>{productCategory?.label}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
