import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import PaymentForm from './PaymentForm'

export const InvoicePayment = (props) => {
    const { id } = useParams()

    return (
        <>
            <PaymentForm iId={id} />
        </>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePayment)