import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { SplitButton } from 'primereact/splitbutton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import querystring from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import Can, { check } from '../Can';
import strings from '../localization';
import rules from '../rbac-rules';
import ProductService from '../service/ProductService';
import UserService from '../service/UserService';
import VendorService from '../service/VendorService';
import  ProductForm  from './ProductForm';
import { formatDateStringLocal } from './InvoiceDates';
import { Tooltip } from 'chart.js';
import { FileUpload } from 'primereact/fileupload';
import BulkImportService from '../service/BulkImportService';
import AuthService from '../service/AuthService';
import SubscriptionService from '../service/SubscriptionService';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import ProductCategoryService from '../service/ProductCategoryService';
import { AutoComplete } from 'primereact/autocomplete';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { TableFilters } from './TableFilters';
import { defaultTableConfiguration } from './TableConfiguration';

export const Product = ({context}) => {
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [products, setProducts] = useState(null);
    const [product, setProduct] = useState(null);
    const [preFilters, setPreFilters] = useState({});
    const [filters, setFilters] = useState({});
    const [totalRecords, setTotalRecords] = useState(0);
    const [vendors, setVendors] = useState(null);
    const [productCategories, setProductCategories] = useState(null);
    const [error, setError] = useState(null);
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [typingTimeout2, setTypingTimeout2] = useState(null);

    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [bulkImport, setBulkImport] = useState(null);
    const [importStatus, setImportStatus] = useState(null);
    const [importError, setImportError] = useState(null);
    const [vendorId, setVendorId] = useState(null);
    const toast = useRef(null);
    var {vendor, branch, showCompany: full,signalrConnection,role} = context;


    useEffect(() => {
        onPage({ rows, page: 0,vendorID:vendor?.uuid})
    }, [localStorage.getItem('subscription')]);

    useEffect(() => {
        if(vendor != null)
        {
            setVendorId(vendor.uuid);
        }
    }, []);

    useEffect(() => {
        signalrConnection?.on('ProductsBulkUploadStatus', (msg)=>{
            if(msg == 4)
            {
                onPage({ rows, page: 0,vendorID:vendor?.uuid})
            }
            setImportError(null)
            setImportStatus(msg)
        })
        signalrConnection?.on('ProductsBulkUploadError', (msg)=>{
            setImportStatus(null)
            setImportError(msg)
        })
    }, [signalrConnection]);



    const openNew = () => {
        setProductDialog(true);
    }
    const openImportModal = () => {
        setImportModalOpen(true);
    }

    const hideDialog = () => {

        setProductDialog(false);

        setProduct(null);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
        setProduct(null);
    }

    const hideImportFromExcelDialog = () => {
        setImportModalOpen(false);
        setBulkImport(null);
    }

    const edit = (obj) => {
        setProduct({
            ...obj,
            namear:  obj.translations.filter(t=>t.cultureName=='Ar')[0]?.name,
            nameen: obj.translations.filter(t=>t.cultureName=='En')[0]?.name,
            productType: obj.productType,
            image: obj.imageUrl,
            uuid: obj.uuid,
            productCategory: {
                code: obj.productCategory?.uuid,
                name: obj.productCategory?.label
            },
            rows:
                obj.identities?.map((row) => {
                    return {
                        "identityId": row.uuid,
                        "name": row.identityType.uuid,
                        "value": row.value
                    };
                }),

        })




        setProductDialog(true);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Can yes={() => <Button label={strings.New} icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />}
                    perform={'products:create'}
                    silent={true}
                    role={role} />
                {/* <Can yes={() => <Button disabled={importStatus != null && importStatus != 4} label={strings.ImportFromExcel} icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openImportModal} />}
                    perform={'products:importBulk'}
                    silent={true}
                    role={role} /> */}
                {/* { importStatus != null && <> {importStatus != 4 && <ProgressSpinner />} <Message severity={importStatus == 4 ? "success" : "info"} content={importStatus == 1 ? strings.ProductsBulkUploadInit : (importStatus == 2 ? strings.ProductsBulkUploadInProgress : strings.ProductsBulkUploadSuccessfull)} /></>}
                {importError && <Message severity="error" content={importError}></Message>} */}
            </React.Fragment>
        )
    }



    const productNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Product Number</span>
                {rowData.productNumber}
            </>
        );
    }

    const nameenBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                {/* <img src={`${rowData.imageUrl}`} alt={rowData.imageUrl} className="product-image" /> */}
                {rowData.translations.filter(t=>t.cultureName=='En')[0]?.name}
            </>
        )
    }

    const namearBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name ar</span>
                {/* <img src={`${rowData.imageUrl}`} alt={rowData.imageUrl} className="product-image" /> */}
                {rowData.translations.filter(t=>t.cultureName=='Ar')[0]?.name}
            </>
        )
    }
    const productCategoryTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Product Category</span>
                {/* <img src={`${rowData.imageUrl}`} alt={rowData.imageUrl} className="product-image" /> */}
                {rowData.productCategory?.label}
            </>
        )
    }

    const skuBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">peppol</span>
                {rowData.peppol}
            </>
        );
    }

    const vendorBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Vendor</span>
                {rowData.vendor?.name}
            </>
        );
    }

    const barcodeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {rowData.price}
            </>
        );
    }

    const deleteProduct = () => {
        const productService = new ProductService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        productService.DeleteProduct(product.uuid).then((data) => {
            if (data.errorCode) {
                toast.current.show({ severity: 'error', summary: 'Cannot Delete Product', detail: data.message, life: 3000 });
                setLoading(false);
                hideDeleteProductDialog();
                return;
            }
            toast.current.show({ severity: 'success', summary: 'Delete Product', detail: `Deleted ${product.name} successfully`, life: 3000 });
            productService.Getproduct(rows, (page) * rows).then(({ data, count }) => {
                setLoading(false);
                setProducts(data);
                setTotalRecords(count);
            });
        }).finally(() => {
            hideDeleteProductDialog();
        }).catch(() => setLoading(false));
        setProduct(null);
    }
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );

    const creationDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Created At</span>
                {formatDateStringLocal(rowData.createdAt)}
            </>
        );
    }

    const confirmDeleteProduct = (product) => {

        setDeleteProductDialog(true);
        setProduct(product);
    }

    const actionBodyTemplate = (rowData) => {
        const items = []

        var userService = new UserService();
        var role = userService.GetRole();

        if (check(rules, role, 'products:update')) {
            items.push({ label: strings.edit, icon: 'pi pi-pencil', command:()=>edit(rowData) });
        }

        if (check(rules, role, 'products:delete')) {
            items.push({ label: strings.deleteProduct, icon: 'pi pi-trash', command:(e)=>confirmDeleteProduct(rowData) });
        }

        items.push({ label: strings.copyId, icon: 'pi pi-copy', command:(e)=>  navigator.clipboard.writeText(rowData.uuid) });
        if(role=='super_admin') {
            items.push({ label: "Copy SID", icon: 'pi pi-copy', command:(e)=>  navigator.clipboard.writeText(rowData.sid) });
        }

        let first = items.shift();

        return first ? <SplitButton label={first.label} icon={first.icon} onClick={()=>first.command()} model={items}></SplitButton> : ''

        // return (
        //     <div className="actions">
        //         <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => edit(rowData)} />
        //         <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteProduct(rowData)} />
        //         {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteProduct(rowData)} /> */}
        //     </div>
        // );
    }

    function changeVendorSearchBar (e) {
        if (typingTimeout2) {
            clearTimeout(typingTimeout2);
        }

        setTypingTimeout2(setTimeout(()=> {

            var vendorService = new VendorService();
            vendorService.GetVendors(100, 0, {simple:true, name:e.query}).then((data) => {
                if (!data.data) return [];
                return data.data.map(v => {
                    return { code: v.uuid, name: v.name, ...v }
                });
            }).then(data => {
                setVendors(
                    [{ code: null, name: '-' }, ...data]);
            });
        }, 200))

    }

    const onPage = ({ first,
        rows,
        page,
        pageCount }) => {
        const productService = new ProductService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        productService.Getproduct(rows, (page) * rows, vendor?.uuid??'').then(({ data, count }) => {
            setLoading(false);
            setProducts(data);
            setTotalRecords(count);
        });

        setProduct(null);

    }

    const onFiltersChange = (e) => {
        let filters = { ...preFilters };
        filters[e.target.id] = e.target.value;
        setPreFilters(filters);
    }

    function formatDate(date) {
        if (!date) return null;
        return new Date(moment(date).format('yyyy-MM-DD HH:mm:ss'))
    }

    useEffect(() => {
        if (preFilters.vendor != null) {
            var productCategoryService = new ProductCategoryService();

            var vendorId = context?.vendor?.uuid ?? preFilters.vendor?.code;

            productCategoryService.GetProductCategory(500, 0, vendorId).then((data) => {
                if (!data.data) {
                    return [{ code: null, name: '-' }]
                }
                return data.data.map(v => {
                    return { code: v.uuid, name: v.label }
                });
            }).then(data => {
                setProductCategories(
                    [{ code: null, name: '-' }, ...data]);
            });

        }
    }, [preFilters.vendor]);

    useEffect(() => {
        if (context?.vendor != null) {
            var productCategoryService = new ProductCategoryService();

            var vendorId = context?.vendor?.uuid ?? preFilters.vendor?.code;

            productCategoryService.GetProductCategory(500, 0, vendorId).then((data) => {
                if (!data.data) {
                    return [{ code: null, name: '-' }]
                }
                return data.data.map(v => {
                    return { code: v.uuid, name: v.label }
                });
            }).then(data => {
                setProductCategories(
                    [{ code: null, name: '-' }, ...data]);
            });

        }
    }, [context]);

    useEffect(() => {
        if(vendors == null)
        {
            var vendorService = new VendorService();
            if (full) {
                vendorService.GetVendors(500).then((data) => {
                    if (!data.data) {
                        return [{ code: null, name: '-' }]
                    }
                    return data.data.map(v => {
                        return { code: v.uuid, name: v.name, ...v }
                    });
                }).then(data => {
                    setVendors(
                        [{ code: null, name: '-' }, ...data]);
                });
            }
        }
    }, []);

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            const productService = new ProductService();
            setLoading(true);
            productService.Getproduct(rows, 0,filters.vendorId??"", querystring.stringify(filters)).then(({ data, count }) => {
                setTotalRecords(count);
                setLoading(false);
                setProducts(data)
            });
        }
    }, [filters]);

    const header = (

        <div className="p-grid">

            <div className="p-col">

                <div className="table-header">

                    <h5 className="p-m-0">{strings.ManageProducts}</h5>
                </div>

            </div>

            <div className="p-col-12">

            <TableFilters>


                    <div className="p-fluid p-grid">

                        {full && <div className="p-field p-col-4" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.Company}</label>
                            <AutoComplete dropdown placeholder={strings.filterSearchPlaceHolder} style={{width:"100%"}} id="vendor" value={preFilters.vendor} suggestions={vendors} field="name" completeMethod={changeVendorSearchBar} onChange={(onFiltersChange)} />
                        </div>}

                        <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.ProductNumber}</label>

                            <InputText id="productNumber" value={preFilters.productNumber} onChange={onFiltersChange} />

                        </div>
                        <div className="p-field p-col-5" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.Name}</label>

                            <InputText id="name" value={preFilters.name} onChange={onFiltersChange} />

                        </div>

                        <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.priceFrom}</label>

                            <InputText type="number" id="priceHigherThan" value={preFilters.priceHigherThan} onChange={onFiltersChange} />

                        </div>

                        <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.priceTo}</label>

                            <InputText type="number" id="priceLowerThan" value={preFilters.priceLowerThan} onChange={onFiltersChange} />

                        </div>



                        {<div className="p-field p-col-5" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.productCategory}</label>
                            <Dropdown style={{ width: '100%' }} id="productCategory" value={preFilters.productCategory} onChange={onFiltersChange} options={productCategories} optionLabel="name" ></Dropdown>
                            {/* <InputText id="companyName" value={preFilters.companyName} onChange={onFiltersChange} /> */}
                        </div>}

                        <div className="p-field p-col" style={{ margin: 'auto' }}>
                        <hr/>

                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Filter} icon="pi pi-search" className="p-button-success p-mr-1 p-mb-1" onClick={() => {
                                let f = { ...preFilters }
                                // 2021-09-21T21:50:37.000Z
                                setFilters({
                                    dateFrom: f.dateFrom ? moment(f.dateFrom).utc().format() : null,
                                    dateTo: f.dateTo ? moment(f.dateTo).utc().format() : null,
                                    productNumber: f.productNumber,
                                    peppol: f.peppol,
                                    priceHigherThan: f.priceHigherThan,
                                    productCategoryId: f.productCategory?.code,
                                    priceLowerThan: f.priceLowerThan,
                                    vendorId: f.vendor?.code,
                                    name: f.name
                                });
                            }} />
                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Clear} icon="pi pi-times" className="p-button-warning p-mr-1 p-mb-1" onClick={() => {
                                setPreFilters({});
                            }} />
                        </div>
                    </div>
                    </TableFilters>
            </div>
        </div>
    );

    function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }

    const onUpload = async (e) => {
        let base64 = await getBase64(e);
        let bulkImportService = new BulkImportService();
        try {
            await bulkImportService.ImportProducts({
                fileContent: base64,
                deviceId: localStorage.getItem('deviceId'),
                vendorId: vendorId?.code ?? vendorId
            });
            setImportStatus(1);
            hideImportFromExcelDialog();
        } catch(e)
        {
            setError(e.message);
        }
    }

    const productDialogFooter = (
        <>
            <Button label={strings.Cancel} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} ></Toolbar>
                    {importStatus != null && <> {importStatus != 4 && <ProgressSpinner />} <Message severity={importStatus == 4 ? "success" : "info"} content={importStatus == 1 ? strings.ProductsBulkUploadInit : (importStatus == 2 ? strings.ProductsBulkUploadInProgress : strings.ProductsBulkUploadSuccessfull)} /></>}
                    {importError && <Message severity="error" content={importError}></Message>}

                    <DataTable
                        {...defaultTableConfiguration}
                        // ref={dt}
                        value={products}
                        totalRecords={totalRecords}
                        first={first}
                        loading={loading}
                        onPage={onPage}
                        rows={rows}
                        header={header}>
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                        <Column field="id" header={strings.ProductNumber} body={productNumberBodyTemplate}></Column>
                        <Column field="imageUrl" header={strings.image} body={(rowData) => <img alt={strings.image} onError={(e) => { e.target.src = "/icon-256x256.png" }} src={rowData.imageUrl} style={{ width: '75px', height: '75px' }}></img>}></Column>
                        <Column field="nameen" header={strings.NameEnglish} body={nameenBodyTemplate} ></Column>
                        <Column field="namear" header={strings.NameArabic} body={namearBodyTemplate} ></Column>
                        <Column field="productCategory" header={strings.productCategory} body={productCategoryTemplate} ></Column>
                        {/* <Can yes={() => <Column field="productCategory" header={strings.NameArabic} body={productCategoryTemplate} ></Column>}
                            perform={'productCategories:route'}
                            role={role} /> */}


                        {/* <Column field="peppol" header={strings.PEPPOL} body={skuBodyTemplate}></Column> */}
                        {full && <Column field="vendor" header={strings.Vendor} body={vendorBodyTemplate}></Column>}
                        <Column field="price" header={strings.Price} body={barcodeBodyTemplate}></Column>
                        <Column field="createdAt" header={strings.CreationDate} body={creationDateBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: '700px' }} header="Products Details" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <ProductForm context={context} OnHideDialog={hideDialog} product={product} FillData={() => onPage({ rows, page: 0, vendorID: vendor?.uuid })} />
                    </Dialog>

                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header={strings.deleteProduct} modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {<span>Are you sure you want to delete <b>{product?.name}</b>?</span>}
                        </div>
                    </Dialog>
                    <Dialog visible={importModalOpen} style={{ width: '450px' }} header={strings.ImportFromExcel} modal onHide={hideImportFromExcelDialog}>
                        <div>
                            <div className="card">
                                <div className="p-fluid p-grid">
                                    <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                                        <label htmlFor="inputtext">{strings.productsImportSteps}</label>
                                        <ul>
                                            <li>
                                                1- {strings.productStep1} <a href="/products-upload-sample.xlsx" className="btn btn-primary" style={{ padding: '5px', color: 'white', marginBottom: '5px' }} download>{strings.here}</a>
                                            </li>
                                            <li>
                                                2-  {strings.productStep2}
                                            </li>
                                            <li>
                                                3- {strings.productStep3}
                                            </li>
                                            <li>
                                                4- {strings.productStep4}
                                            </li>
                                            <li>
                                                5- {strings.productStep5}
                                            </li>
                                        </ul>
                                        <hr />
                                    </div>
                                    <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                                        <label htmlFor="inputtext" style={{marginLeft: "5px"}}>{strings.Vendor}: </label>
                                        { !vendor ? <Dropdown style={{ width: '100%' }} id="vendorId" value={vendorId} onChange={(e) => setVendorId(e.value)} options={vendors} optionLabel="name" ></Dropdown> : vendor.name}
                                    </div>
                                    <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                                        <label htmlFor="inputtext">{strings.file}</label>
                                        <FileUpload chooseLabel={strings.selectFile} onSelect={(e) => setFile(e.files[0])} uploadHandler={onUpload} mode="basic" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" maxFileSize={1000000} emptyTemplate={<p className="m-0">{strings.dropFileHere}</p>} />
                                    </div>
                                    <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                                        <label htmlFor="inputtext">{strings.file}</label>
                                        <Button disabled={file == null} label={strings.submit} onClick={() => onUpload(file)}></Button>
                                    </div>

                                </div>

                                {error && <Message severity="error">{error}</Message>}
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
