import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import VendorService from '../service/VendorService';
import ExpenseTypeService from '../service/ExpenseTypeService';
import { defaultTableConfiguration } from './TableConfiguration';


export const ExpenseTypes = () => {

    let emptyExpenseType = {
        "translations": [
            {
                label:'',
                locale:'1'
            },
            {
                label:'',
                locale:'2'
            }
        ],
    };

    const [expenseTypeDialog, setExpenseTypeDialog] = useState(false);
    const [deleteExpenseTypeDialog, setDeleteExpenseTypeDialog] = useState(false);
    const [deleteExpenseTypesDialog, setDeleteExpenseTypesDialog] = useState(false);
    const [expenseType, setExpenseType] = useState(emptyExpenseType);
    const [selectedExpenseTypes, setSelectedExpenseTypes] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [expenseTypes, setExpenseTypes] = useState([]);
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        // const expenseTypeService = new ExpenseTypeService();
        // expenseTypeService.getExpenseTypes().then(data => setExpenseTypes(data));
        onPage({rows,page:0})
    }, []);

    const formatCurrency = (value) => {
        if(!value) return ""
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setExpenseType(emptyExpenseType);
        setSubmitted(false);
        setExpenseTypeDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setExpenseTypeDialog(false);
    }

    const hideDeleteExpenseTypeDialog = () => {
        setDeleteExpenseTypeDialog(false);
    }

    const hideDeleteExpenseTypesDialog = () => {
        setDeleteExpenseTypesDialog(false);
    }

    const saveExpenseType = async () => {
        setSubmitted(true);

        var expenseTypeService = new ExpenseTypeService();
        if (expenseType.uuid) {
            expenseType.translations.map(t=>{
                t.locale = t.cultureName == 'En' ? '1' : '2'
                return t;
            });
            await expenseTypeService.UpdateExpenseType(expenseType);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'ExpenseType Updated', life: 3000 });
        }
        else {
            await expenseTypeService.CreateExpenseType(expenseType);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'ExpenseType Created', life: 3000 });
        }
        onPage({ rows, page })
        // setExpenseTypes(_expenseTypes);
        setExpenseTypeDialog(false);
        setExpenseType(emptyExpenseType);
    }

    const editExpenseType = (expenseType) => {
        setExpenseType({ ...expenseType });
        setExpenseTypeDialog(true);
    }

    const confirmDeleteExpenseType = (expenseType) => {
        setExpenseType(expenseType);
        setDeleteExpenseTypeDialog(true);
    }

    const deleteExpenseType = async () => {
        await (new ExpenseTypeService().DeleteExpenseType(expenseType))
        setDeleteExpenseTypeDialog(false);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'ExpenseType Deleted', life: 3000 });
        onPage({ rows, page })
    }

    const confirmDeleteSelected = () => {
        setDeleteExpenseTypesDialog(true);
    }

    const deleteSelectedExpenseTypes = () => {
        let _expenseTypes = expenseTypes.filter(val => !selectedExpenseTypes.includes(val));
        setExpenseTypes(_expenseTypes);
        setDeleteExpenseTypesDialog(false);
        setSelectedExpenseTypes(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'ExpenseTypes Deleted', life: 3000 });
    }

    const onTranslationChange = (e, locale) => {
        const val = (e.target && e.target.value) || '';
        let _expenseType = { ...expenseType };
        _expenseType.translations[locale-1].label = val;
        setExpenseType(_expenseType);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        // return (
        //     <React.Fragment>
        //         <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" />
        //         <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
        //     </React.Fragment>
        // )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.code}
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.email}
            </>
        );
    }

    const arabicLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Label [AR]</span>
                {rowData.translations[1]?.label}
            </>
        );
    }

    const englishLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.translations[0]?.label}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editExpenseType(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteExpenseType(rowData)} />
            </div>
        );
    }

    const onPage = ({ first,
        rows,
        page,
        pageCount }) => {
        const expenseTypeService = new ExpenseTypeService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        expenseTypeService.GetExpenseTypes(rows, (page) * rows).then(({ data, count }) => {
            setLoading(false);
            setExpenseTypes(data);
            setTotalRecords(count);
        });

    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Identity Types</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const expenseTypeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveExpenseType} />
        </>
    );
    const deleteExpenseTypeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteExpenseTypeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteExpenseType} />
        </>
    );
    const deleteExpenseTypesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteExpenseTypesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedExpenseTypes} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        {...defaultTableConfiguration}
                        value={expenseTypes}
                        selection={selectedExpenseTypes}
                        totalRecords={totalRecords}
                        first={first}
                        loading={loading}
                        onPage={onPage}
                        rows={rows}
                        header={header}>
                        <Column field="email" header="Label [AR]"  style={{width: "250px"}} body={arabicLabelTemplate}></Column>
                        <Column field="phone" header="Label [EN]"body={englishLabelTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={expenseTypeDialog} style={{ width: '450px' }} header="expenseType Details" modal className="p-fluid" footer={expenseTypeDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="name">Label [AR]</label>
                            <InputText id="name" value={expenseType.translations[1]?.label} onChange={(e) => onTranslationChange(e, '2')} required autoFocus className={classNames({ 'p-invalid': submitted && !expenseType.name })} />
                            {submitted && !expenseType.name && <small className="p-invalid">Label [AR] is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">Label [EN]</label>
                            <InputText id="name" value={expenseType.translations[0]?.label} onChange={(e) => onTranslationChange(e, '1')} required autoFocus className={classNames({ 'p-invalid': submitted && !expenseType.name })} />
                            {submitted && !expenseType.name && <small className="p-invalid">Label [EN] is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteExpenseTypeDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteExpenseTypeDialogFooter} onHide={hideDeleteExpenseTypeDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {expenseType && <span>Are you sure you want to delete <b>{expenseType.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteExpenseTypesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteExpenseTypesDialogFooter} onHide={hideDeleteExpenseTypesDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {expenseType && <span>Are you sure you want to delete the selected expenseTypes?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
