// import axios from 'axios'

// export class CustomerService {
//     getCustomersMedium() {
//         return axios.get('assets/demo/data/customers-medium.json')
//             .then(res => res.data.data);
//     }

//     getCustomersLarge() {
//         return axios.get('assets/demo/data/customers-large.json')
//                 .then(res => res.data.data);
//     }
    
// }



import ApiService from './ApiService';
let api = process.env.REACT_APP_API_URL

export default class TaxCategoriesService extends ApiService {

    constructor() {
        super();
        this.path = '/taxCategory';
    }

    async CreateTaxCategory(data) {
        try {
            let op = await this.Create(this.path, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }


    async UpdateTaxCategory(data) {

      
        try {
        
            let op = await this.Update(this.path+"/"+data.uuid, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }


    async GetTaxCategory(limit=10,offset=0) {
        try {
            let link = `${this.path}/list?limit=${limit}&offset=${offset}`
            let op = await this.Read(link);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async GetCount() {
        try {
            let link = `${this.path}/list?countOnly=true`
            let op = await this.Read(link);
            return op.data.count;
        } catch(e) {
            return e.response.data;
        }
    }
}
