import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import VendorService from '../service/VendorService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import strings from '../localization';
import { InputTextarea } from 'primereact/inputtextarea';
import Files from 'react-files'
import Axios from 'axios';
import ReactImageUploading from 'react-images-uploading';
import SupportTicketService from '../service/SupportTicketService';

export class SupportTicketForm extends Component {


    constructor(props) {
        super(props);

        this.state = {
            ticket: {},
            loading: false,
            pageLoading: false,
            errors: [],
        };
        this.toast = React.createRef();



    }


    ReturnData() {
        this.setState({ user: this.props.user })
    }

    onEditorValueChange(productKey, props, value) {
        let updatedRow = [...props.value];
        console.log(value)
        updatedRow[props.rowIndex][props.field] = value;
        this.setState({ [`${productKey}`]: updatedRow });
    }



    onInputChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let ticket = { ...this.state.ticket };
        ticket[`${e.target.id}`] = val;

        this.setState({ ticket: ticket });
    }

    save = async () => {
        this.setState({ loading: true });

        let { ticket } = this.state;

        var service = new SupportTicketService();

        let email = JSON.parse(localStorage.getItem('user'))?.email;

        await service.CreateSupportTicket({
            email,
            ...ticket
        });

        this.setState({ loading: false });

        this.props.close();
    }

    render() {

        if (this.state.pageLoading) {
            return <div style={{ textAlign: 'center' }}><ProgressSpinner /></div>
        }

        let image = this.state.ticket.image;

        const isRTL = localStorage.getItem('lang') == 'ar' ? 'rtl' : 'ltr';

        let email = JSON.parse(localStorage.getItem('user'))?.email;

        return (

            <div className="p-grid crud-demo" style={{ direction: isRTL }}>
                <div className="p-col-12">

                    <div className="card" style={{ width: '100%' }}>
                        <Toast ref={this.toast} />

                        <h3>{strings.openSupportRequest}</h3>
                        <hr />

                        <div className="p-grid">

                            <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.email}</label>
                                    <h6>{email}</h6>
                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="title" className="required">{strings.issueTitle}</label>
                                    <br />
                                    <InputText style={{ width: '100%' }} id="title" value={this.state.ticket.title} onChange={(e) => this.onInputChange(e)} required autoFocus />
                                </div>
                            </div>

                            <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="description" className="required">{strings.problemDescription}</label>
                                    <br />
                                    <InputTextarea style={{ width: '100%' }} id="description" value={this.state.ticket.description} onChange={(e) => this.onInputChange(e)} required autoFocus />

                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.Attachments}</label>
                                    <div>
                                    <ReactImageUploading
                                                        value={this.state.ticket.image}
                                                        onError={(errors, imageList) => {
                                                            if (errors.maxNumber) {
                                                                let image = imageList[0]?.data_url;
                                                                let ticket = { ...this.state.ticket };
                                                                ticket['image'] = image;
                                                                this.setState({ ticket: ticket });
                                                            }

                                                        }}
                                                        onChange={(imageList) => {
                                                            let image = imageList[0]?.data_url;
                                                            let ticket = { ...this.state.ticket };
                                                            ticket['image'] = image;
                                                            this.setState({ ticket: ticket });
                                                        }}
                                                        // maxFileSize={1048576}
                                                        // resolutionHeight="150"
                                                        // resolutionWidth="150"
                                                        acceptType={["png","jpg","svg","jpeg"]}
                                                        dataURLKey="data_url"
                                                    >
                                                        {({
                                                            imageList,
                                                            onImageUpload,
                                                            onImageRemoveAll,
                                                            onImageUpdate,
                                                            onImageRemove,
                                                            isDragging,
                                                            errors,
                                                            dragProps
                                                        }) => {
                                                            let style = { width: "100%", height: "100px", borderStyle: "dotted", textAlign: 'center', margin: "auto" };
                                                            return (
                                                                // write your building UI
                                                                <div className="upload__image-wrapper">
                                                                    {errors?.maxFileSize && <Message severity="error" text="Selected file size exceed 1 MB" />}
                                                                    {errors?.acceptType && <Message severity="error" text="Selected file type is invalid, it should be one of: png, jpg, jpeg, svg" />}
                                                                    {!this.state.ticket.image ? <div
                                                                        style={isDragging ? { color: "red", ...style } : style}
                                                                        onClick={onImageUpload}
                                                                        {...dragProps}
                                                                    >
                                                                        <span style={{ top: '20%' }}>{strings.ClickorDropheretouploadvendorlogo}</span>
                                                                    </div> :
                                                                        <div className="image-item" style={{ textAlign: "center" }}>
                                                                            <img src={image} alt="" width="150" height="150" />
                                                                            <div className="image-item__btn-wrapper">
                                                                                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={onImageUpload} />
                                                                                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => onImageRemove(0)} />
                                                                            </div>
                                                                        </div>}
                                                                </div>
                                                            )
                                                        }}
                                                    </ReactImageUploading>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-field">
                            {this.state.errors?.length > 0 && <Message text={this.state.errors.map((m) => <li>{m}</li>)} severity="error" />}
                            <Button style={{ width: '100%' }} label={strings.submit} loading={this.state.loading} onClick={this.save} loadingoptions={{ position: 'right' }} />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
