import CompanyAccountService from '../../service/CompanyAccountService'
import {addAccount, addAccounts,getAccount,updateAccount,deleteAccount,addMsg} from '../accounts'


export const getAccountsHandler = (payload) => async (dispatch) =>{
    try {
        console.log("🚀 ~ file: accountAction.js ~ line 8 ~ getAccountsHandler ~ payload", payload)
        let result = await new CompanyAccountService().getCompanyAccounts(payload)
        console.log("🚀 ~ file: accountAction.js ~ line 8 ~ getAccountsHandler ~ result", result)
        dispatch(addAccounts(result))
    } catch (error) {
        dispatch(addMsg(error))
    }
}

export const addAccountHandler = (payload) => async (dispatch) =>{
    try {
        let result = await new CompanyAccountService().addCompanyAccount(payload)
       result.uuid ? dispatch(addAccount(result)) : dispatch(addMsg(result.statusText))
    } catch (error) {
        dispatch(addMsg(error))
    }
}

export const getAccountHandler = (payload) => async (dispatch) =>{
    try {
        let result = await  new CompanyAccountService().getCompanyAccount(payload)
        dispatch(getAccount(result))
    } catch (error) {
        dispatch(addMsg(error))
    }
}

export const updateAccountHandler = (payload) => async (dispatch, state)=>{
    try {
        let result = await CompanyAccountService.updateCompanyAccount(payload.uuid, payload)
        let newState = state().bankAccounts.accounts.data.map((d) => d.uuid === payload.uuid ? result: d)
        dispatch(updateAccount(newState))
    } catch (error) {
        dispatch(addMsg(error))
    }
}


export const deleteAccountHandler = (payload) => async (dispatch, state) => {
    try {
        let result = await  new CompanyAccountService().deleteCompanyAccount(payload)
        let newState = state().bankAccounts.accounts.data.filter(d=> d.uuid !== result.uuid);
        dispatch(updateAccount(newState))
    } catch (error) {
        deleteAccount(addMsg(error))
    }
}
