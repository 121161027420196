import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import PaymentForm from './PaymentForm'
import { useParams } from 'react-router-dom'
import { addSalesPayment } from '../../store/actions/SalesPayment'
import { ProgressSpinner } from 'primereact/progressspinner'

export const EditPayment = ({addSalesPayment,payment}) => {
    const id = useParams().id
    useEffect(() => {
        addSalesPayment(id)
    },[])
    return (
        <>
            {payment?.uuid ?<PaymentForm mode='edit' iId={payment?.invoiceId} paymentForEdit={{...payment, paymentTypeId:payment.paymentType?.uuid, customerId:payment.customer?.uuid, vendorId :payment.vendor.uuid, issuerId: payment.issuer?.uuid, companyAccountId: payment.companyAccount?.uuid}} />: <ProgressSpinner/>}
        </>
    )
}

const mapStateToProps = (state) => ({
    payment: state.payments.payment
})

const mapDispatchToProps = {addSalesPayment}

export default connect(mapStateToProps, mapDispatchToProps)(EditPayment)