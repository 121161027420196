export const countryCodes =
[
    {
        name: "السعودية (sa)",
        code: "sa"
    },
    {
      name: "أفغانستان (af)",
      code: "af"
    },
    {
      name: "ألبانيا (al)",
      code: "al"
    },
    {
      name: "الجزائر (dz)",
      code: "dz"
    },
    {
      name: "أندورا (ad)",
      code: "ad"
    },
    {
      name: "أنغولا (ao)",
      code: "ao"
    },
    {
      name: "أنتيغوا وباربودا (ag)",
      code: "ag"
    },
    {
      name: "الأرجنتين (ar)",
      code: "ar"
    },
    {
      name: "أرمينيا (am)",
      code: "am"
    },
    {
      name: "أستراليا (au)",
      code: "au"
    },
    {
      name: "النمسا (at)",
      code: "at"
    },
    {
      name: "أذربيجان (az)",
      code: "az"
    },
    {
      name: "باهاماس (bs)",
      code: "bs"
    },
    {
      name: "البحرين (bh)",
      code: "bh"
    },
    {
      name: "بنغلاديش (bd)",
      code: "bd"
    },
    {
      name: "باربادوس (bb)",
      code: "bb"
    },
    {
      name: "بيلاروس (by)",
      code: "by"
    },
    {
      name: "بلجيكا (be)",
      code: "be"
    },
    {
      name: "بليز (bz)",
      code: "bz"
    },
    {
      name: "بنين (bj)",
      code: "bj"
    },
    {
      name: "بوتان (bt)",
      code: "bt"
    },
    {
      name: "بوليفيا (bo)",
      code: "bo"
    },
    {
      name: "البوسنة والهرسك (ba)",
      code: "ba"
    },
    {
      name: "بوتسوانا (bw)",
      code: "bw"
    },
    {
      name: "البرازيل (br)",
      code: "br"
    },
    {
      name: "بروناي (bn)",
      code: "bn"
    },
    {
      name: "بلغاريا (bg)",
      code: "bg"
    },
    {
      name: "بوركينا فاسو (bf)",
      code: "bf"
    },
    {
      name: "بوروندي (bi)",
      code: "bi"
    },
    {
      name: "كمبوديا (kh)",
      code: "kh"
    },
    {
      name: "الكاميرون (cm)",
      code: "cm"
    },
    {
      name: "كندا (ca)",
      code: "ca"
    },
    {
      name: "الرأس الأخضر (cv)",
      code: "cv"
    },
    {
      name: "جمهورية أفريقيا الوسطى (cf)",
      code: "cf"
    },
    {
      name: "تشاد (td)",
      code: "td"
    },
    {
      name: "تشيلي (cl)",
      code: "cl"
    },
    {
      name: "الصين (cn)",
      code: "cn"
    },
    {
      name: "كولومبيا (co)",
      code: "co"
    },
    {
      name: "جزر القمر (km)",
      code: "km"
    },
    {
      name: "جمهورية الكونغو (cg)",
      code: "cg"
    },
    {
      name: "جمهورية الكونغو الديمقراطية (cd)",
      code: "cd"
    },
    {
      name: "كوستاريكا (cr)",
      code: "cr"
    },
    {
      name: "ساحل العاج (ci)",
      code: "ci"
    },
    {
      name: "كرواتيا (hr)",
      code: "hr"
    },
    {
      name: "كوبا (cu)",
      code: "cu"
    },
    {
      name: "قبرص (cy)",
      code: "cy"
    },
    {
      name: "جمهورية التشيك (cz)",
      code: "cz"
    },
    {
      name: "الدنمارك (dk)",
      code: "dk"
    },
    {
      name: "جيبوتي (dj)",
      code: "dj"
    },
    {
      name: "دومينيكا (dm)",
      code: "dm"
    },
    {
      name: "جمهورية الدومينيكان (do)",
      code: "do"
    },
    {
      name: "الإكوادور (ec)",
      code: "ec"
    },
    {
      name: "مصر (eg)",
      code: "eg"
    },
    {
      name: "السلفادور (sv)",
      code: "sv"
    },
    {
      name: "غينيا الاستوائية (gq)",
      code: "gq"
    },
    {
      name: "إريتريا (er)",
      code: "er"
    },
    {
      name: "إستونيا (ee)",
      code: "ee"
    },
    {
      name: "إثيوبيا (et)",
      code: "et"
    },
    {
      name: "فيجي (fj)",
      code: "fj"
    },
    {
      name: "فنلندا (fi)",
      code: "fi"
    },
    {
      name: "فرنسا (fr)",
      code: "fr"
    },
    {
        name: "(pl) فلسطين",
        code: "pl"
    },
    {
      name: "الغابون (ga)",
      code: "ga"
    },
    {
      name: "غامبيا (gm)",
      code: "gm"
    },
    {
      name: "جورجيا (ge)",
      code: "ge"
    },
    {
      name: "ألمانيا (de)",
      code: "de"
    },
    {
      name: "غانا (gh)",
      code: "gh"
    },
    {
      name: "اليونان (gr)",
      code: "gr"
    },
    {
      name: "غرينادا (gd)",
      code: "gd"
    },
    {
      name: "غواتيمالا (gt)",
      code: "gt"
    },
    {
      name: "غينيا (gn)",
      code: "gn"
    },
    {
      name: "غينيا بيساو (gw)",
      code: "gw"
    },
    {
      name: "غيانا (gy)",
      code: "gy"
    },
    {
      name: "هايتي (ht)",
      code: "ht"
    },
    {
      name: "هندوراس (hn)",
      code: "hn"
    },
    {
      name: "المجر (hu)",
      code: "hu"
    },
    {
      name: "آيسلندا (is)",
      code: "is"
    },
    {
      name: "الهند (in)",
      code: "in"
    },
    {
      name: "إندونيسيا (id)",
      code: "id"
    },
    {
      name: "إيران (ir)",
      code: "ir"
    },
    {
      name: "العراق (iq)",
      code: "iq"
    },
    {
      name: "أيرلندا (ie)",
      code: "ie"
    },
    {
      name: "إيطاليا (it)",
      code: "it"
    },
    {
      name: "جامايكا (jm)",
      code: "jm"
    },
    {
      name: "اليابان (jp)",
      code: "jp"
    },
    {
      name: "الأردن (jo)",
      code: "jo"
    },
    {
      name: "كازاخستان (kz)",
      code: "kz"
    },
    {
      name: "كينيا (ke)",
      code: "ke"
    },
    {
      name: "كيريباتي (ki)",
      code: "ki"
    },
    {
      name: "كوريا الشمالية (kp)",
      code: "kp"
    },
    {
      name: "كوريا الجنوبية (kr)",
      code: "kr"
    },
    {
      name: "الكويت (kw)",
      code: "kw"
    },
    {
      name: "قيرغيزستان (kg)",
      code: "kg"
    },
    {
      name: "لاوس (la)",
      code: "la"
    },
    {
      name: "لاتفيا (lv)",
      code: "lv"
    },
    {
      name: "لبنان (lb)",
      code: "lb"
    },
    {
      name: "ليسوتو (ls)",
      code: "ls"
    },
    {
      name: "ليبيريا (lr)",
      code: "lr"
    },
    {
      name: "ليبيا (ly)",
      code: "ly"
    },
    {
      name: "ليختنشتاين (li)",
      code: "li"
    },
    {
      name: "ليتوانيا (lt)",
      code: "lt"
    },
    {
      name: "لوكسمبورغ (lu)",
      code: "lu"
    },
    {
      name: "مقدونيا (mk)",
      code: "mk"
    },
    {
      name: "مدغشقر (mg)",
      code: "mg"
    },
    {
      name: "مالاوي (mw)",
      code: "mw"
    },
    {
      name: "ماليزيا (my)",
      code: "my"
    },
    {
      name: "جزر المالديف (mv)",
      code: "mv"
    },
    {
      name: "مالي (ml)",
      code: "ml"
    },
    {
      name: "مالطا (mt)",
      code: "mt"
    },
    {
      name: "جزر مارشال (mh)",
      code: "mh"
    },
    {
      name: "موريتانيا (mr)",
      code: "mr"
    },
    {
      name: "موريشيوس (mu)",
      code: "mu"
    },
    {
      name: "المكسيك (mx)",
      code: "mx"
    },
    {
      name: "ولايات ميكرونيسيا المتحدة (fm)",
      code: "fm"
    },
    {
      name: "المغرب (ma)",
      code: "ma"
    },
    {
      name: "مولدوفا (md)",
      code: "md"
    },
    {
      name: "موناكو (mc)",
      code: "mc"
    },
    {
      name: "منغوليا (mn)",
      code: "mn"
    },
    {
      name: "الجبل الأسود (me)",
      code: "me"
    },
    {
      name: "موزمبيق (mz)",
      code: "mz"
    },
    {
      name: "ميانمار (mm)",
      code: "mm"
    },
    {
      name: "ناميبيا (na)",
      code: "na"
    },
    {
      name: "ناورو (nr)",
      code: "nr"
    },
    {
      name: "نيبال (np)",
      code: "np"
    },
    {
      name: "هولندا (nl)",
      code: "nl"
    },
    {
      name: "نيوزيلندا (nz)",
      code: "nz"
    },
    {
      name: "نيكاراغوا (ni)",
      code: "ni"
    },
    {
      name: "النيجر (ne)",
      code: "ne"
    },
    {
      name: "نيجيريا (ng)",
      code: "ng"
    },
    {
      name: "النرويج (no)",
      code: "no"
    },
    {
      name: "عمان (om)",
      code: "om"
    },
    {
      name: "باكستان (pk)",
      code: "pk"
    },
    {
      name: "بالاو (pw)",
      code: "pw"
    },
    {
      name: "بنما (pa)",
      code: "pa"
    },
    {
      name: "بابوا غينيا الجديدة (pg)",
      code: "pg"
    },
    {
      name: "باراغواي (py)",
      code: "py"
    },
    {
      name: "بيرو (pe)",
      code: "pe"
    },
    {
      name: "الفلبين (ph)",
      code: "ph"
    },
    {
      name: "بولندا (pl)",
      code: "pl"
    },
    {
      name: "البرتغال (pt)",
      code: "pt"
    },
    {
      name: "قطر (qa)",
      code: "qa"
    },
    {
      name: "رومانيا (ro)",
      code: "ro"
    },
    {
      name: "روسيا (ru)",
      code: "ru"
    },
    {
      name: "رواندا (rw)",
      code: "rw"
    },
    {
      name: "سانت كيتس ونيفيس (kn)",
      code: "kn"
    },
    {
      name: "سانت لوسيا (lc)",
      code: "lc"
    },
    {
      name: "سانت فينسنت والغرينادين (vc)",
      code: "vc"
    },
    {
      name: "ساموا (ws)",
      code: "ws"
    },
    {
      name: "سان مارينو (sm)",
      code: "sm"
    },
    {
      name: "ساو تومي وبرينسيب (st)",
      code: "st"
    },
    {
      name: "السنغال (sn)",
      code: "sn"
    },
    {
      name: "صربيا (rs)",
      code: "rs"
    },
    {
      name: "سيشل (sc)",
      code: "sc"
    },
    {
      name: "سيراليون (sl)",
      code: "sl"
    },
    {
      name: "سنغافورة (sg)",
      code: "sg"
    },
    {
      name: "سلوفاكيا (sk)",
      code: "sk"
    },
    {
      name: "سلوفينيا (si)",
      code: "si"
    },
    {
      name: "جزر سليمان (sb)",
      code: "sb"
    },
    {
      name: "الصومال (so)",
      code: "so"
    },
    {
      name: "جنوب أفريقيا (za)",
      code: "za"
    },
    {
      name: "جنوب السودان (ss)",
      code: "ss"
    },
    {
      name: "إسبانيا (es)",
      code: "es"
    },
    {
      name: "سريلانكا (lk)",
      code: "lk"
    },
    {
      name: "السودان (sd)",
      code: "sd"
    },
    {
      name: "سورينام (sr)",
      code: "sr"
    },
    {
      name: "إسواتيني (sz)",
      code: "sz"
    },
    {
      name: "السويد (se)",
      code: "se"
    },
    {
      name: "سويسرا (ch)",
      code: "ch"
    },
    {
      name: "سوريا (sy)",
      code: "sy"
    },
    {
      name: "طاجيكستان (tj)",
      code: "tj"
    },
    {
      name: "تنزانيا (tz)",
      code: "tz"
    },
    {
      name: "تايلاند (th)",
      code: "th"
    },
    {
      name: "تيمور الشرقية (tl)",
      code: "tl"
    },
    {
      name: "توغو (tg)",
      code: "tg"
    },
    {
      name: "تونغا (to)",
      code: "to"
    },
    {
      name: "ترينيداد وتوباغو (tt)",
      code: "tt"
    },
    {
      name: "تونس (tn)",
      code: "tn"
    },
    {
      name: "تركيا (tr)",
      code: "tr"
    },
    {
      name: "تركمانستان (tm)",
      code: "tm"
    },
    {
      name: "توفالو (tv)",
      code: "tv"
    },
    {
      name: "أوغندا (ug)",
      code: "ug"
    },
    {
      name: "أوكرانيا (ua)",
      code: "ua"
    },
    {
      name: "الإمارات العربية المتحدة (ae)",
      code: "ae"
    },
    {
      name: "المملكة المتحدة (gb)",
      code: "gb"
    },
    {
      name: "الولايات المتحدة (us)",
      code: "us"
    },
    {
      name: "الأوروغواي (uy)",
      code: "uy"
    },
    {
      name: "أوزبكستان (uz)",
      code: "uz"
    },
    {
      name: "فانواتو (vu)",
      code: "vu"
    },
    {
      name: "فنزويلا (ve)",
      code: "ve"
    },
    {
      name: "فيتنام (vn)",
      code: "vn"
    },
    {
      name: "اليمن (ye)",
      code: "ye"
    },
    {
      name: "زامبيا (zm)",
      code: "zm"
    },
    {
      name: "زيمبابوي (zw)",
      code: "zw"
    }
  ]
