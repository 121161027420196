import ApiService from './ApiService';
import querystring from 'query-string';

export default class InvoiceService extends ApiService {

    constructor() {
        super();
        this.path = '/invoice';
    }

    async  CreateInvoice(data) {
        try {
            let op = await this.Create(this.path, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async RegenerateInvoiceFiles(id) {
        try {
            let op = await this.Create(this.path+"/regenerate/"+id, {});
            return op.data;
        } catch(e) {
            throw e;
        }
    }

    async ConfirmInvoice(id) {
        try {
            let op = await this.Update(`${this.path}/confirm/${id}`);
            return op.data;
        } catch(e) {
            return e.response;
        }
    }

    async SendInvoiceToEmail(data) {
        try {
            let op = await this.Create(`${this.path}/email`, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }
    async DeleteDraftedInvoice(id) {
        try {
            let op = await this.Delete(`${this.path}/${id}`);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async GetInvoices(limit=10,offset=0, filters={}) {
        try {
            let link = `${this.path}/list?limit=${limit}&offset=${offset}&${filters}`
            let op = await this.Read(link);
            return {
                count:op.data.count,
                data:op.data.invoices.map(i=>{
                    return {
                        ...i.invoice,
                        amounts: i.amounts
                    }
                })
            };
        } catch(e) {
            return e.response.data;
        }
    }

    async GetInvoicesPendingPayment(limit=100,offset=0, filters={}) {
        try {
            let link = `${this.path}/pendingPayment?limit=${limit}&offset=${offset}&${querystring.stringify(filters)}`
            let op = await this.Read(link);
            return {
                count:op.data.count,
                data:op.data.invoices.map(i=>{
                    return {
                        ...i.invoice,
                        amounts: i.amounts
                    }
                })
            };
        } catch(e) {
            return e.response.data;
        }
    }

    async GetInvoice(id) {
        try {
            let link = `${this.path}/${id}`
            let op = await this.Read(link);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }
    async GetInvoiceComplianceStatus(id) {
        let link = `${this.path}/compliance?invoiceId=${id}`
        let op = await this.Read(link);
        return op.data;
    }

    async SubmitInvoiceSignTask(id) {
        let link = `${this.path}/sign?invoiceId=${id}`
        let op = await this.Read(link);
        return op.data;
    }

    async CheckCompliance(vendorId) {
        try {
            let op = await this.Create(`${this.path}/checkCompliance`, {vendorId: vendorId});
            return op.data;
        } catch(e) {
            return e.response;
        }
    }

    async ReportInvoice(id) {
        let link = `${this.path}/reporting`
        let op = await this.Create(link, {
            invoiceId: id
        });
        return op.data;
    }

    async ClearInvoice(id) {
        let link = `${this.path}/clearance`
        let op = await this.Create(link, {
            invoiceId: id
        });
        return op.data;
    }

    async GetCount() {
        try {
            let link = `${this.path}/list?countOnly=true`
            let op = await this.Read(link);
            return op.data.count;
        } catch(e) {
            return e.response.data;
        }
    }
}
