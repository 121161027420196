import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import strings from '../localization';

export const getColors = (colorMode="light") => {
    const isLight = colorMode === 'light';
    return {
        pinkColor: isLight ? '#EC407A' : '#F48FB1',
        redColor: isLight ? '#EC407A' : '#F48FB1',
        purpleColor: isLight ? '#AB47BC' : '#CE93D8',
        deeppurpleColor: isLight ? '#7E57C2' : '#B39DDB',
        indigoColor: isLight ? '#5C6BC0' : '#9FA8DA',
        blueColor: isLight ? '#42A5F5' : '#90CAF9',
        lightblueColor: isLight ? '#29B6F6' : '#81D4FA',
        cyanColor: isLight ? '#00ACC1' : '#4DD0E1',
        tealColor: isLight ? '#26A69A' : '#80CBC4',
        greenColor: isLight ? '#66BB6A' : '#A5D6A7',
        lightgreenColor: isLight ? '#9CCC65' : '#C5E1A5',
        limeColor: isLight ? '#D4E157' : '#E6EE9C',
        yellowColor: isLight ? '#FFEE58' : '#FFF59D',
        amberColor: isLight ? '#FFCA28' : '#FFE082',
        orangeColor: isLight ? '#FFA726' : '#FFCC80',
        deeporangeColor: isLight ? '#FF7043' : '#FFAB91',
        brownColor: isLight ? '#8D6E63' : '#BCAAA4',
        emeraldGreen: isLight ? '#50c878' : '#50c878',
        gray: isLight ? '#989898' : '#989898'
    }
}


const getPieChartOptions = () => {
    const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
    const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
    return {
        events:['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
        plugins: {
            legend: {
                position: 'left',
                display: true,
                labels: {
                    fontFamily,
                    fontColor: textColor,
                }
            }
        }
    }
}
let pieChartOptions;


export const PieChart = (props) => {
    const [pieData, setPieData] = useState(null);
    const [hidePie, setHidePie] = useState(false);

    const updateCharts = () => {
        setPieData(props.getPieData());
        pieChartOptions = getPieChartOptions();
    }

    useEffect(() => {
        updateCharts();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        var sum = pieData?.datasets[0]?.data.reduce((a,b)=>a+b,0);
        if(sum==0)
        {
            setHidePie(true);
        }
    }, [pieData]) // eslint-disable-line react-hooks/exhaustive-deps


    if(hidePie)
    {
        return <></>;
    }

    return (
        <div className="card">
            <h5 className="centerText">{props.title}</h5>
            <div className="p-d-flex p-jc-center">
                <Chart style={{ position: 'relative', width: '50%' }} type="pie" data={pieData} options={pieChartOptions} ></Chart>
            </div>
        </div>
    )
}
