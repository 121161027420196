import ApiService from './ApiService';
import qs from 'query-string';

export default class PaymentService extends ApiService {
    constructor() {
        super();
        this.path = '/payment'
    }
    async GetPayment(id){
        try {
            let link = `${this.path}/${id}`;
            let op = await this.Read(link)
            return op.data
        } catch (e) {
            return e.response.data;
        }
    }

    async GetPayments(data){
        try {
            let link = `${this.path}/list?${qs.stringify(data)}`
            let op = await this.Read(link,data)
            return op.data
        } catch (e) {
            return e.response.data;
        }
    }
    async UpdatePayment(data) {
        try {
            let link = `${this.path}/${data.uuid}`
            let op = await this.Update(link, data)
            return op.data
        } catch (e) {
            return e.response.data;
        }
    }
    async ReconcilePayment(data) {
        let link = `${this.path}/reconcile`
        let op = await this.Update(link, data)
        return op.data
    }
    async CreatePayment(data) {
        try {
            let link = `${this.path}`
            let op = await this.Create(link, data);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }
    async DeletePayment(id) {
        try {
            let link = `${this.path}/${id}`;
            let op = await this.Delete(link);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }
    async GetPaymentTypes(payload) {
        try {
            let op = await this.Read('/paymentType/list',payload)
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }
}
