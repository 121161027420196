import strings from '../../localization'
import { getCurrency } from '../../service/helpers'

const currencyCodes = [
    { code: getCurrency(), name: getCurrency() },
    { code: 'USD', name: 'USD' },
    { code: 'EUR', name: 'EUR' },
    { code: 'AED', name: 'AED' },
    { code: 'KWD', name: 'KWD' },
]
const agreementTypes = [
    { code: 'selfBilled', name: strings.selfBilled },
    { code: 'thirdParty', name: strings.thirdParty },
]
const transactionTypes = [
    { code: 'nominal', name: strings.nominal },
    { code: 'exports', name: strings.exports },
    { code: 'summary', name: strings.summary },
]

export const getInitialOrder = (activeBranch, vendor) => {
    return {
        properties: [],
        orderNumber: 'STASH',
        orderTypeCode: 111,
        externalNote: 'new',
        confirmed: false,
        includePrepaid: true,
        includeRetention: true,
        currencyCode: currencyCodes[0],
        currencyRate: {
            code: getCurrency(),
            name: getCurrency(),
            targetCurrency: getCurrency(),
            baseCurrency: getCurrency(),
            rate: 1.0,
        },
        agreementType: agreementTypes[0],
        transactionType: transactionTypes[0],
        orderIssueDate: new Date(),
        actualDeliveryDate: null,
        latestDeliveryDate: null,
        dueDate: null,
        vendor: vendor,
        branchSerial: {
            code: activeBranch?.serial,
            name: `${activeBranch?.name} (${activeBranch?.serial})`
        },
        branchId: activeBranch?.uuid
    }
}

