import ApiService from './ApiService';

export default class FeedbackService extends ApiService {

    constructor() {
        super();
        this.path = '/feedback';
    }

    async  CreateFeedback(data) {
        try {
            let op = await this.Create(this.path, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async ActivateFeedback(id) {
        try {
            let op = await this.Create(`${this.path}/activate/${id}`);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async GetFeedbacks(limit = 10, offset = 0) {
        try {
            let link = `${this.path}/list?limit=${limit}&offset=${offset}`
            let op = await this.Read(link);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }
    async GetCount() {
        try {
            let link = `${this.path}/list?countOnly=true`
            let op = await this.Read(link);
            return op.data.count;
        } catch(e) {
            return e.response.data;
        }
    }
}
