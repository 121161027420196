import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import InvoiceService from '../service/InvoiceService';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import JsFileDownloader from 'js-file-downloader';
import querystring from 'query-string';
import strings from '../localization';
import { Dropdown } from 'primereact/dropdown';
import VendorService from '../service/VendorService';
import CustomerService from '../service/CustomerService';
import Axios from 'axios';
import ApiService from '../service/ApiService';
import { SplitButton } from 'primereact/splitbutton';
import UserService from '../service/UserService';
import Can, { check } from '../Can';
import rules from '../rbac-rules';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { AutoComplete } from 'primereact/autocomplete';
import InvoiceReportingService from '../service/InvoiceReportingService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import { TableFilters } from './TableFilters';
import { defaultTableConfiguration } from './TableConfiguration';
import CertificateService from '../service/CertificateService';
import subscription from '../store/subscription';
import SubscriptionService from '../service/SubscriptionService';
import { FileUpload } from 'primereact/fileupload';
import BulkImportService from '../service/BulkImportService';
import { getCountry } from '../service/helpers';

let api = process.env.REACT_APP_API_URL


export const Invoices = ({ summary, context }) => {

    var userService = new UserService();
    var role = userService.GetRole();


    const [invoices, setInvoices] = useState([]);
    const [selectedInvoices, setSelectedInvoices] = useState([]);

    const invoiceTypes = [
        { code: 'tax', name: strings.evat },
        { code: 'simple', name: strings.simplified },
    ]

    const history = useHistory();

    const [page, setPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(25);
    const [loading, setLoading] = useState(false);
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [typingTimeout2, setTypingTimeout2] = useState(null);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [invoice, setInvoice] = useState(false);
    const [deleteInvoiceDialog, setDeleteInvoiceDialog] = useState(false);
    const [filters, setFilters] = useState({});
    const [vendors, setVendors] = useState(null);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [subscriptions, setSubscriptions] = useState(null);
    const [customers, setCustomers] = useState(null);
    const [exportLoading, setExportLoading] = useState(false);
    const [exportError, setExportError] = useState(false);
    const [exportedCompany, setExportedCompany] = useState(context?.vendor?.uuid);
    const [exportedBranch, setExportedBranch] = useState(context?.branch?.uuid);
    const [importStatus, setImportStatus] = useState(null);
    const [vendorId, setVendorId] = useState(null);
    const [error, setError] = useState(null);

    const [exportToExcel, setExportToExcel] = useState(false);
    const [loadingCertificateStatus, setLoadingCertificateStatus] = useState(false);
    const [certificateStatus, setCertificateStatus] = useState(null);
    const [exportToExcelBody, setExportToExcelBody] = useState(null);
    const [excelPath, setExcelPath] = useState(null);
    const [invoicesBulkUploadCount, setInvoicesBulkUploadCount] = useState(0);
    const [invoicesBulkUploadProgressCount, setInvoicesBulkUploadProgressCount] = useState(0);
    const [date, setDate] = useState(null);
    const [preFilters, setPreFilters] = useState({});
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [file, setFile] = useState(null);

    const [customerNameFilter, setCustomerNameFilter] = useState({});
    const [activeIndex, setActiveIndex] = useState(0);
    const toast = useRef(null);
    const dt = useRef(null);

    const [importError, setImportError] = useState(null);
    var {vendor, branch, showCompany: full,signalrConnection,role} = context;

    const openImportModal = () => {
        setImportModalOpen(true);
    }
    function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }

    const onUpload = async (e) => {
        let base64 = await getBase64(e);
        let bulkImportService = new BulkImportService();
        try {
            await bulkImportService.ImportInvoices({
                fileContent: base64,
                deviceId: localStorage.getItem('deviceId'),
                vendorId: vendorId?.code ?? vendorId
            });
            setImportStatus(1);
            hideImportFromExcelDialog();
        } catch(e)

        {
            setError(e.message);
        }
    }
    useEffect(() => {
        onPage({ rows: summary ? 10 : rows, page: 0 })
    }, [localStorage.getItem('subscription')]);

    useEffect(() => {
        context.signalrConnection?.on('InvoicesBulkUploadStatus', (msg)=>{
            if(msg == 4)
            {
                onPage({ rows, page: 0,vendorID:context.vendor?.uuid})
            }
            setImportError(null)
            setImportStatus(msg)
        })
        context.signalrConnection?.on('InvoicesBulkUploadError', (msg)=>{
            setImportStatus(null)
            setImportError(msg)
        })
        context.signalrConnection?.on('InvoicesBulkUploadCount', (msg)=>{
            setInvoicesBulkUploadCount(msg)
        })
        context.signalrConnection?.on('InvoicesBulkUploadProgressCount', (msg)=>{
            setInvoicesBulkUploadProgressCount(msg)
        })
    }, [context.signalrConnection]);


    useEffect(() => {
        if (context?.vendor?.uuid != null) {
            // Fill Data
            setLoadingCertificateStatus(true);
            new CertificateService().GetCertificateStatus({uuid: context?.vendor?.uuid}).then(data=>{
                setCertificateStatus(data);
            }).finally(()=>{
                setLoadingCertificateStatus(false);
            });
        }
    }, [localStorage.getItem('subscription')]);

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            onPage({ rows, page: 0 })
        }
    }, [filters]);

    useEffect(() => {

        if (preFilters.vendor != null) {
            var customerService = new CustomerService();
            customerService.GetCustomers(100, 0, preFilters.vendor?.code, { registrationName: customerNameFilter, simple: true }).then(data => {
                return data.data.map(v => {
                    return { code: v.uuid, name: v.registrationName, ...v }
                });
            }).then(data => {
                setCustomers([{ code: null, name: '-' }, ...data]);
            })
        }
    }, [customerNameFilter]);


    useEffect(() => {
        if (preFilters.vendor != null) {
            var customerService = new CustomerService();
            customerService.GetCustomers(100, 0, preFilters.vendor?.code).then(data => {
                return data.data.map(v => {
                    return { code: v.uuid, name: v.registrationName, ...v }
                });
            }).then(data => {
                setCustomers([{ code: null, name: '-' }, ...data]);
            })
        }
    }, [preFilters.vendor]);

    useEffect(() => {
        var vendorService = new VendorService();
        vendorService.GetVendors(500).then((data) => {
            if (!data.data) return [];
            return data.data.map(v => {
                return { code: v.uuid, name: v.name, ...v }
            });
        }).then(data => {
            setVendors(
                [{ code: null, name: '-' }, ...data]);
        });
    }, []);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }


    var reloadCustomers = (filters = {}) => {
        // if (preFilters.vendor != null) {
        var customerService = new CustomerService();
        customerService.GetCustomers(100, 0, preFilters.vendor?.code, filters).then(data => {
            return data.data.map(v => {
                return { code: v.uuid, name: v.registrationName, ...v }
            });
        }).then(data => {
            setCustomers([{ code: null, name: '-' }, ...data]);
        })
        // }
    }

    let statuses = [null, "Awaiting Activation",
        "Trialing",
        "Active",
        "PastDue",
        "Canceled"
    ]

    const openNew = () => {
        history.push(`/profile/invoices/new`);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Can yes={() => <Button label={strings.New} icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />}
                    perform={'invoices:draft:create'}
                    silent={true}
                    role={role} />

                <Can yes={() => <Button label={strings.CreditNote} icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={() => history.push(`/profile/invoices/newCredit`)} />}
                    perform={'invoices:draft:create'}
                    silent={true}
                    role={role} />

                <Can yes={() => <Button label={strings.DebitNote} icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={() => history.push(`/profile/invoices/newDebit`)} />}
                    perform={'invoices:draft:create'}
                    silent={true}
                    role={role} />

                <Can yes={() => <Button disabled={importStatus != null && importStatus != 4} label={strings.ImportFromExcel} icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openImportModal} />}
                                    perform={'invoices:importBulk'}
                                    silent={true}
                                    role={role} />


                <Button disabled={selectedInvoices.length == 0} loading={downloadLoading} label={strings.DownloadSelected} icon="pi pi-download" className="p-button-success p-mr-2 p-mb-2" onClick={() => {
                    setDownloadLoading(true);
                    new ApiService().Create(`/download/bulk`, {
                        files: selectedInvoices.map(si => si.pdfFile).filter(f => f != null),
                    }, {
                        responseType: 'arraybuffer',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }).then(function (res) {
                        const temp = window.URL.createObjectURL(new Blob([res.data]));
                        const link = document.createElement('a');
                        link.href = temp;
                        link.setAttribute('download', `arms-export-${moment().format('DD/MM/YYYY HH:MM:SS')}.zip`); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        toast.current.show({ severity: 'success', summary: 'Downloaded PDF', life: 3000 });
                        setDownloadLoading(false);
                    })
                        .catch(function (error) {
                            toast.current.show({ severity: 'error', summary: 'Cannot Download PDF', life: 3000 });
                            setDownloadLoading(false);
                        });

                }} />

                <Button disabled={selectedInvoices.length == 0} loading={downloadLoading} label={strings.recordPayment} icon="pi pi-money-bill" className="p-button-success p-mr-2 p-mb-2" onClick={() => {}} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label={strings.exportMonthlyReport} onClick={() => {
                    setExportToExcel(true);
                }} icon="pi pi-upload" className="p-button-text" />
            </React.Fragment>
        )
    }

    const numberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Number</span>
                {rowData.invoiceNumber}
            </>
        );
    }

    const userInvoiceNumberTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{strings.UserInvoiceNumber}</span>
                {rowData.originalInvoiceNumber}
            </>
        );
    }

    const companyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Company</span>
                {rowData.vendor?.name}
            </>
        );
    }
    const invoiceTypeTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Invoice Type</span>
                {rowData.invoiceType?.split('-').join(" ")}
            </>
        );
    }
    const issueDateTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Issue date</span>
                {moment.utc(rowData.issueDate).local().format('DD/MM/YYYY, h:mm:ss a')}
            </>
        );
    }

    const customerBodyTemplate = (rowData) => {
        return (
            <div>
                <span className="p-column-title">Customer</span>
                {rowData.customer?.registrationName}
            </div>
        );
    }


    const totalBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Total</span>
                {rowData.amounts?.taxInclusiveAmount.text} {rowData.amounts?.taxInclusiveAmount.currencyID}
            </>
        )
    }
    const taxExcludedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tax Excluded</span>
                {rowData.amounts?.taxExclusiveAmount.text} {rowData.amounts?.taxExclusiveAmount.currencyID}
            </>
        )
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${rowData.confirmed=='1'?'3':'1'}`}>{rowData.confirmed ? 'Confirmed' : 'Draft'}</span>
            </>
        )
    }
    const paymentStatusBodyTemplate = (rowData) => {

        var status = strings.notPaid;
        var statusNumber = 'failed';
        if(rowData.paymentStatus == '1')
        {
            status = strings.fullyPaid;
            statusNumber = '3';
        }
        else if(rowData.paymentStatus == '2')
        {
            status = strings.partiallyPaid;
            statusNumber = '2';
        }

        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${statusNumber}`}>{status}</span>
            </>
        )
    }

    const complianceStatusBodyTemplate = (rowData) => {

        var status = rowData.complianceStatus;
        var statusNumber = 'failed';
        if(rowData.complianceStatus?.toUpperCase() == 'CLEARED'.toUpperCase() || rowData.complianceStatus?.toUpperCase() == 'REPORTED'.toUpperCase())
        {
            statusNumber = '3';
        }
        else
        {
            status='Pending'
        }

        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${statusNumber}`}>{status}</span>
            </>
        )
    }
    const hideImportFromExcelDialog = () => {
        setImportModalOpen(false);
    }

    const createdByBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Created By</span>
                <span>{rowData.createdBy}</span>
            </>
        )
    }

    const hideDeleteInvoiceDialog = () => {
        setDeleteInvoiceDialog(false);
        setInvoice(null);
    }
    const hideExportToExcelDialog = () => {
        setExportToExcel(false);
        setExportToExcelBody(null);
    }

    let token = localStorage.getItem("token");

    const actionBodyTemplate = (rowData) => {
        const items = [
            {
                label: strings.downloadPdf,
                icon: 'pi pi-file-pdf',
                command: (e) => {
                    new JsFileDownloader({
                        url: `${api}/download/${rowData.pdfFile}`,
                        headers: [{
                            name: 'Authorization',
                            value: `Bearer ${token}`
                        }]
                    })
                        .then(function () {
                            toast.current.show({ severity: 'success', summary: 'Downloaded PDF', life: 3000 });
                        })
                        .catch(function (error) {
                            toast.current.show({ severity: 'error', summary: 'Cannot Download PDF', life: 3000 });
                        });
                }
            },
            {
                label: strings.downloadXml,
                icon: 'pi pi-external-link',
                command: (e) => {
                    new JsFileDownloader({
                        url: `${api}/download/${rowData.xmlFile}`,
                        headers: [{
                            name: 'Authorization',
                            value: `Bearer ${token}`
                        }]
                    })
                        .then(function () {
                            toast.current.show({ severity: 'success', summary: 'Downloaded XML', life: 3000 });
                        })
                        .catch(function (error) {
                            toast.current.show({ severity: 'error', summary: 'Cannot Download XML', life: 3000 });
                        });

                }
            }
        ]

        if (!rowData.confirmed && check(rules, role, 'invoices:draft:delete')) {
            items.push({
                label: strings.DeleteDraft,
                icon: 'pi pi-trash',
                command: (e) => {
                    confirmDeleteInvoice(rowData)
                }
            })
        }
        let newFeaturesEnabled = process.env.REACT_APP_NEW_FEATURES_ENABLED

        rowData.confirmed && newFeaturesEnabled==="true" && items.push({ label: strings.recordPayment, icon: 'pi pi-credit-card', command: (e) => history.push(`/profile/payments/invoice/${rowData.uuid}`) });
        items.push({ label: strings.copyId, icon: 'pi pi-copy', command: (e) => navigator.clipboard.writeText(rowData.uuid) });
        if(role=='super_admin') {
            items.push({ label: "Copy SID", icon: 'pi pi-copy', command:(e)=>  navigator.clipboard.writeText(rowData.sid) });
        }

        let lang = localStorage.getItem('lang');
        return <SplitButton label={strings.details} icon={`pi pi-arrow-circle-${lang == 'ar' ? 'left' : 'right'}`} onClick={() => history.push(`/profile/invoices/${rowData.uuid}`)} model={items}></SplitButton>
    }
    const confirmDeleteInvoice = (invoice) => {
        setDeleteInvoiceDialog(true);
        setInvoice(invoice);
    }

    const deleteInvoice = () => {
        const invoiceService = new InvoiceService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        console.log(invoice)
        invoiceService.DeleteDraftedInvoice(invoice.uuid).then((data) => {
            if (data.errorCode) {
                toast.current.show({ severity: 'error', summary: 'Cannot Delete Invoice', detail: data.message, life: 3000 });
                setLoading(false);
                hideDeleteInvoiceDialog();
                return;
            }
            toast.current.show({ severity: 'success', summary: 'Delete Draft Invoice', detail: `Deleted ${invoice.invoiceNumber} successfully`, life: 3000 });
            invoiceService.GetInvoices(rows, (page) * rows).then(({ data, count }) => {
                setLoading(false);
                setInvoices(data);
                setTotalRecords(count);
            });
        }).finally(() => {
            hideDeleteInvoiceDialog();
        }).catch(() => setLoading(false));

        setInvoice(null);

    };

    const deleteInvoiceDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteInvoiceDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteInvoice} />
        </>
    );

    const exportToExcelDialogFooter = (
        <>
            <Button label={strings.Cancel} icon="pi pi-times" className="p-button-text" onClick={hideExportToExcelDialog} />
            <Button label={strings.export} icon="pi pi-check" className="p-button-text" onClick={async () => {
                setExportLoading(true);
                setExportError(null)
                await new InvoiceReportingService().GetDetailedReport({
                    from: exportToExcelBody.from,
                    to: exportToExcelBody.to,
                    vendorId: exportedCompany?.code ?? exportedCompany,
                    branchId: exportedBranch?.code ?? exportedBranch,
                }).then(({ data }) => setExcelPath(data)).then(() => setExportLoading(false)).catch((error) => {
                    if (error.response.status == 400) {
                        setExportError(strings[error.response.data]);
                    }
                    else {
                        setExportError(strings.errorWhileExportingToExcel);
                    }
                    setExportLoading(false);
                })
            }} />
        </>
    );

    const onPage = ({ first,
        rows,
        page,
        pageCount }) => {
        setLoading(true);
        setRows(rows);
        setPage(page);
        setFirst(first)
        setLoading(true);
        const invoiceService = new InvoiceService();
        invoiceService.GetInvoices(rows, (page) * rows, querystring.stringify(filters)).then(({ data, count }) => {
            setTotalRecords(count);
            setLoading(false);
            setInvoices(data)
        });


        // invoiceService.GetInvoices(rows, (page) * rows).then(({ data, count }) => {
        //     setLoading(false);
        //     setInvoices(data);
        //     setTotalRecords(count);
        // });

    }

    const onFiltersChange = (e) => {
        let filters = { ...preFilters };
        filters[e.target.id] = e.target.value;
        setPreFilters(filters);
    }

    function formatDate(date) {
        if (!date) return null;
        return new Date(moment(date).format('yyyy-MM-DD HH:mm:ss'))
    }

    function changeCustomerSearchBar(e) {
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setTypingTimeout(setTimeout(() => {
            reloadCustomers({ registrationName: e.query, simple: true }, true)
        }, 200))

    }
    function changeVendorSearchBar(e) {
        if (typingTimeout2) {
            clearTimeout(typingTimeout2);
        }

        setTypingTimeout2(setTimeout(() => {

            var vendorService = new VendorService();
            vendorService.GetVendors(100, 0, { simple: true, name: e.query }).then((data) => {
                if (!data.data) return [];
                return data.data.map(v => {
                    return { code: v.uuid, name: v.name, ...v }
                });
            }).then(data => {
                setVendors(
                    [{ code: null, name: '-' }, ...data]);
            });
        }, 200))

    }

    function changeSubscriptionSearchBar(e) {
        if (typingTimeout2) {
            clearTimeout(typingTimeout2);
        }

        setTypingTimeout2(setTimeout(() => {

            var subscriptionService = new SubscriptionService();
            subscriptionService.GetSubscriptions(100, 0, `name=${e.query}`).then((data) => {
                if (!data.data) return [];
                return data.data.map(v => {
                    return { code: v.uuid, name: v.name, ...v }
                });
            }).then(data => {
                setSubscriptions(
                    [{ code: null, name: '-' }, ...data]);
            });
        }, 200))

    }

    const header = (

        <div className="p-grid">

            <div className="p-col">

                <div className="table-header">
                    <h5 className="p-m-0">{strings.ManageInvoices}</h5>
                </div>

            </div>



            <div className="p-col-12">


                {/* <div className="card"> */}

                <TableFilters>

                    <div className="p-fluid p-grid">

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>

                            <label htmlFor="inputtext">{strings.InvoiceNumber}</label>
                            <InputText id="invoiceNumber" value={preFilters.invoiceNumber} onChange={onFiltersChange} />

                        </div>
                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>

                            <label htmlFor="inputtext">{strings.UserInvoiceNumber}</label>
                            <InputText id="originalInvoiceNumber" value={preFilters.originalInvoiceNumber} onChange={onFiltersChange} />

                        </div>
                        {/* <div className="p-field p-col-2" style={{ margin: 'auto' }}>

                                    <label htmlFor="inputtext">{strings.Phone}</label>
                                    <InputText id="phone" value={preFilters.phone} onChange={onFiltersChange} />

                                </div> */}

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>

                            <label htmlFor="calendar">{strings.dateFrom}</label>
                            <Calendar id="dateFrom" showTime={true} showSeconds={true} showIcon showButtonBar value={formatDate(preFilters.dateFrom)} onChange={onFiltersChange} />

                        </div>

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.dateTo}</label>
                            <Calendar id="dateTo" showTime={true} showSeconds={true} showIcon showButtonBar value={formatDate(preFilters.dateTo)} onChange={onFiltersChange} />

                        </div>


                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.Confirmed}</label>
                            <Dropdown optionLabel="name" id="confirmed" value={preFilters.confirmed} onChange={onFiltersChange} options={[
                                { code: null, name: '-' },
                                { code: true, name: strings.Yes },
                                { code: false, name: strings.No }
                            ]} />
                        </div>

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.InvoiceType}</label>
                            <Dropdown style={{ width: '100%' }} id="invoiceType" value={preFilters.invoiceType} onChange={(e) => {
                                onFiltersChange(e);
                            }} options={invoiceTypes} optionLabel="name" ></Dropdown>
                        </div>


                        {context?.showCompany ? <div className="p-field p-col-3" style={{ margin: 'auto' }}>

                            <label htmlFor="calendar">{strings.Vendor}</label>
                            <AutoComplete dropdown placeholder={strings.filterSearchPlaceHolder} style={{ width: "100%" }} id="vendor" value={preFilters.vendor} suggestions={vendors} field="name" completeMethod={changeVendorSearchBar} onChange={(onFiltersChange)} />

                        </div> : ''}

                        {context?.showBranch ? <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.Branch}</label>
                            {/* <Dropdown id="branchSerial" options={preFilters.vendor?.branches} onChange={onFiltersChange} /> */}

                            <Dropdown disabled={preFilters.vendor?.branches?.length == 0} style={{ width: '100%' }} id="branchSerial" value={preFilters.branchSerial} onChange={(e) => {
                                onFiltersChange(e);
                            }} options={preFilters.vendor?.branches?.map(b => {
                                return {
                                    code: b.serial,
                                    name: `${b.name} (${b.serial})`,
                                }
                            })} optionLabel="name" ></Dropdown>

                            {/* <InputText id="branchSerial" value={preFilters.branchSerial} onChange={onFiltersChange} /> */}

                        </div> : ''}
                        <div className="p-field p-col-4" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.Customer}</label>
                            <AutoComplete dropdown placeholder={strings.filterSearchPlaceHolder} style={{ width: "100%" }} id="customer" value={preFilters.customer} suggestions={customers} field="name" completeMethod={changeCustomerSearchBar} onChange={onFiltersChange} />
                        </div>



                        <div className="p-field p-col" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.CreatedBy}</label>
                            <InputText id="createdBy" value={preFilters.createdBy} onChange={onFiltersChange} />
                        </div>

                        {new UserService().IsSuperAdmin() && <div className="p-field p-col-11" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.subscriptionName}</label>
                            <AutoComplete dropdown style={{ width: "100%" }} id="vendor" value={selectedSubscription} suggestions={subscriptions} field="name" completeMethod={changeSubscriptionSearchBar} onChange={(e) => {
                            setSelectedSubscription(e.value);
                            let filters = { ...preFilters };
                            filters['sid'] = e.value.uuid;
                            setPreFilters(filters);
                            }} />
                            {/* <InputText id="sid" value={preFilters.sid} onChange={onFiltersChange} /> */}
                        </div>}


                        <div className="p-field-2" style={{ margin: 'auto' }}>
                            <hr />
                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Filter} icon="pi pi-search" className="p-button-success p-mr-1 p-mb-1" onClick={() => {
                                let f = { ...preFilters }
                                setSelectedInvoices([])
                                // 2021-09-21T21:50:37.000Z
                                setFilters({
                                    dateFrom: f.dateFrom ? moment(f.dateFrom).utc().format() : null,
                                    dateTo: f.dateTo ? moment(f.dateTo).utc().format() : null,
                                    invoiceNumber: f.invoiceNumber,
                                    confirmed: f.confirmed?.code,
                                    phone: f.phone,
                                    createdBy: f.createdBy,
                                    originalInvoiceNumber: f.originalInvoiceNumber,
                                    invoiceType: f.invoiceType?.code,
                                    branchSerial: f.branchSerial?.code,
                                    vendor: f.vendor?.code,
                                    customer: f.customer?.code,
                                    sid: f.sid,
                                });
                            }} />
                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Clear} icon="pi pi-times" className="p-button-warning p-mr-1 p-mb-1" onClick={() => {
                                setPreFilters({});
                            }} />
                        </div>
                    </div>

                </TableFilters>


            </div>
        </div>
    );




    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    {importStatus != null && <> <Message severity={importStatus == 4 ? "success" : "info"} content={importStatus == 1 ? strings.InvoicesBulkUploadInit : (importStatus == 2 ? strings.InvoicesBulkUploadInProgress + ` ${invoicesBulkUploadProgressCount}/${invoicesBulkUploadCount}` : strings.InvoicesBulkUploadSuccessfull)} /></>}
                    {importError && <Message severity="error" content={importError}></Message>}

                    <DataTable
                        {...defaultTableConfiguration}
                        value={invoices}
                        totalRecords={totalRecords}
                        first={first}
                        loading={loading}
                        onPage={onPage}
                        rows={rows}
                        selection={selectedInvoices}
                        onSelectionChange={(e => {
                            setSelectedInvoices(e.value)
                        })}
                        header={summary ? '' : header}
                        paginator={!summary}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="number" header={strings.Number} body={numberBodyTemplate} ></Column>
                        <Column field="originalInvoiceNumber" header={strings.UserInvoiceNumber} body={userInvoiceNumberTemplate} ></Column>
                        <Column field="customer" header={strings.Customer} body={customerBodyTemplate} ></Column>
                        <Column field="Issue Date" header={strings.IssueDate} body={issueDateTemplate}></Column>
                        <Column field="company" header={strings.Company} body={companyTemplate}></Column>
                        {/* <Column field="invoiceType" header={strings.InvoiceType} body={invoiceTypeTemplate}></Column> */}
                        <Column field="Total" header={strings.Total} body={totalBodyTemplate} ></Column>
                        <Column field="Status" header={strings.Status} body={statusBodyTemplate} ></Column>
                        {certificateStatus?.isActive || new UserService().IsSuperAdmin() && getCountry() == 'SA' ? <Column field="ComplianceStatus" header={strings.complianceStatus} body={complianceStatusBodyTemplate} ></Column>
                        : <Column field="PaymentStatus" header={strings.PaymentStatus} body={paymentStatusBodyTemplate} ></Column>}
                        <Column field="createdBy" header={strings.CreatedBy} body={createdByBodyTemplate} ></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
            <Dialog visible={deleteInvoiceDialog} style={{ width: '450px' }} header={strings.Confirm} modal footer={deleteInvoiceDialogFooter} onHide={hideDeleteInvoiceDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {<span>{strings.Areyousureyouwanttodeletedraft}?</span>}
                </div>
            </Dialog>

            <Dialog visible={importModalOpen} style={{ width: '450px' }} header={strings.ImportFromExcel} modal onHide={hideImportFromExcelDialog}>
                <div>
                    <div className="card">
                        <div className="p-fluid p-grid">
                            <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                                <label htmlFor="inputtext">{strings.productsImportSteps}</label>
                                <ul>
                                    <li>
                                        1- {strings.invoiceStep1} <a href="/invoices-upload-sample.xlsx" className="btn btn-primary" style={{ padding: '5px', color: 'white', marginBottom: '5px' }} download>{strings.here}</a>
                                    </li>
                                    <li>
                                        2-  {strings.invoiceStep2}
                                    </li>
                                    <li>
                                        3- {strings.invoiceStep3}
                                    </li>
                                    <li>
                                        4- {strings.invoiceStep4}
                                    </li>
                                    <li>
                                        5- {strings.invoiceStep5}
                                    </li>
                                </ul>
                                <hr />
                            </div>
                            <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                                <label htmlFor="inputtext" style={{ marginLeft: "5px" }}>{strings.Vendor}: </label>
                                {!vendor ? <Dropdown style={{ width: '100%' }} id="vendorId" value={vendorId} onChange={(e) => setVendorId(e.value)} options={vendors} optionLabel="name" ></Dropdown> : vendor.name}
                            </div>
                            <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                                <label htmlFor="inputtext">{strings.file}</label>
                                <FileUpload chooseLabel={strings.selectFile} onSelect={(e) => setFile(e.files[0])} uploadHandler={onUpload} mode="basic" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" maxFileSize={1000000} emptyTemplate={<p className="m-0">{strings.dropFileHere}</p>} />
                            </div>
                            <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                                <label htmlFor="inputtext">{strings.file}</label>
                                <Button disabled={file == null} label={strings.submit} onClick={() => onUpload(file)}></Button>
                            </div>

                        </div>

                        {error && <Message severity="error">{error}</Message>}
                    </div>
                </div>
            </Dialog>


            <Dialog visible={exportToExcel} style={{ width: '450px' }} header={strings.Confirm} modal footer={exportToExcelDialogFooter} onHide={hideExportToExcelDialog}>
                <div className="p-fluid p-grid">

                    {context?.showCompany ? <div className="p-field p-col-12" style={{ margin: 'auto' }}>

                        <label htmlFor="calendar">{strings.Vendor}</label>
                        <AutoComplete dropdown placeholder={strings.filterSearchPlaceHolder} style={{ width: "100%" }} id="vendor" value={exportedCompany} suggestions={vendors} field="name" completeMethod={changeVendorSearchBar} onChange={(e) => {
                            setExportedCompany(e.value);
                        }} />

                    </div> : ''}

                    {context?.showBranch ? <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                        <label htmlFor="inputtext">{strings.Branch}</label>
                        {/* <Dropdown id="branchSerial" options={preFilters.vendor?.branches} onChange={onFiltersChange} /> */}

                        <Dropdown disabled={exportedCompany?.branches?.length == 0} style={{ width: '100%' }} id="branchSerial" value={exportedBranch} onChange={(e) => {
                            setExportedBranch(e.value);
                        }} options={exportedCompany?.branches?.map(b => {
                            return {
                                code: b.uuid,
                                name: `${b.name} (${b.serial})`,
                            }
                        })} optionLabel="name" ></Dropdown>

                        {/* <InputText id="branchSerial" value={preFilters.branchSerial} onChange={onFiltersChange} /> */}

                    </div> : ''}
                    <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                        <label>{strings.pickMonth}: </label>
                        <Calendar value={date} onChange={(e) => {
                            const startOfMonth = moment(e.value).startOf('month');
                            const endOfMonth = moment(e.value).endOf('month');

                            setExportToExcelBody({
                                from: startOfMonth,
                                to: endOfMonth
                            })
                            setDate(e.value)
                        }} view="month" dateFormat="mm/yy" yearNavigator yearRange={`2021:${new Date().getFullYear()}`} />
                    </div>

                    <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                        {exportLoading ? <ProgressSpinner /> : ''}
                        {excelPath && <span>{strings.fileIsReady} <Button onClick={() => {
                            new JsFileDownloader({
                                url: `${api}/download/${excelPath}`,
                                headers: [{
                                    name: 'Authorization',
                                    value: `Bearer ${token}`
                                }]
                            })
                                .then(function () {
                                    toast.current.show({ severity: 'success', summary: 'Downloaded Excel Report', life: 3000 });
                                })
                                .catch(function (error) {
                                    toast.current.show({ severity: 'error', summary: 'Cannot Download Excel', life: 3000 });
                                });
                        }}>{strings.downloadHere} </Button></span>}
                        {exportError && <hr />}
                        {exportError && <Message content={exportError} severity={"error"}></Message>}
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
