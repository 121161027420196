import React, { Component, useEffect, useRef } from 'react';
import { Button } from "primereact/button";
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import querystring from 'query-string';
import { AutoComplete } from 'primereact/autocomplete';
import {getCurrency} from '../service/helpers'


import VendorService from '../service/VendorService'
import CustomerService from '../service/CustomerService'
import ProductService from '../service/ProductService'
import QuotationService from '../service/QuotationService'
import { TabView, TabPanel } from 'primereact/tabview';
import { ProgressSpinner } from 'primereact/progressspinner';
import QRCode from 'qrcode.react';
import { Message } from 'primereact/message';
import { confirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog method
import { Dialog } from 'primereact/dialog';
import QuotationDates, { formatDate, formatDateString } from './InvoiceDates';
import JsFileDownloader from 'js-file-downloader';
import { Toast } from 'primereact/toast';
import { CustomerForm } from './CustomerForm';
import { VendorForm } from './VendorForm';
import ProductForm from './ProductForm';
import { ThemeConsumer } from 'styled-components';
import strings from '../localization';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import Can, { check } from '../Can';
import UserService from '../service/UserService';
import rules from '../rbac-rules';
import CurrencyRateService from '../service/CurrencyRateService';
import { CurrencyRates } from './CurrencyRates';
import { Subscription } from './Subscription';
import { Skeleton } from 'primereact/skeleton';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Toolbar } from 'primereact/toolbar';
import SubscriptionService from '../service/SubscriptionService';
import { RegisterListerners } from './RegisterListerners';

let api = process.env.REACT_APP_API_URL



const currencyCodes = [
    { code: getCurrency(), name: getCurrency() },
    { code: 'USD', name: 'USD' },
    { code: 'EUR', name: 'EUR' },
    { code: 'AED', name: 'AED' },
    { code: 'KWD', name: 'KWD' },
]

export class QuotationForm extends Component {
    constructor(props) {
        super(props);


        const connection = new HubConnectionBuilder()
            .withUrl(`${api}/hubs/upload`, { accessTokenFactory: () => localStorage.getItem('deviceId') })
            .withAutomaticReconnect()
            .build();

        connection.start().then(() => {
            connection?.on(`PdfGenerationCompleted`, message => {
                let quotation = { ...this.state.quotation };
                quotation.pdfFile = message;
                this.setState({ quotation });
                this.toast.current.show({ severity: `${strings.success}`, summary: `${strings.UploadSuccessfull}`, detail: `${strings.PdfUploadSuccessfullDetails}`, life: 3000 });
            });
            connection?.on(`XmlGenerationCompleted`, message => {
                let quotation = { ...this.state.quotation };
                quotation.xmlFile = message;
                this.setState({ quotation });
                this.toast.current.show({ severity: `${strings.success}`, summary: `${strings.UploadSuccessfull}`, detail: `${strings.XmlUploadSuccessfullDetails}`, life: 3000 });
            });
            connection?.on(`PdfGenerationError`, message => {
                let quotation = { ...this.state.quotation };
                quotation.pdfFile = "NA";
                this.setState({ quotation });
                this.toast.current.show({ severity: `${strings.success}`, summary: `${strings.UploadSuccessfull}`, detail: `${strings.PdfUploadFailedDetails}`, life: 3000 });
            });
            connection?.on(`XmlGenerationError`, message => {
                let quotation = { ...this.state.quotation };
                quotation.xmlFile = "NA";
                this.setState({ quotation });
                this.toast.current.show({ severity: `${strings.success}`, summary: `${strings.UploadSuccessfull}`, detail: `${strings.XmlUploadFailedDetails}`, life: 3000 });
            })

        }).catch(e => console.error(e, "Error whle connecting to SignalR"));

        this.state = {
            quotation: {},
            connection: null,
            debitNoteDialog: false,
            creditNoteDialog: false,
            listening: false,
            addedProducts: [],
            customersLoading: false,
            productsLoading: false,
            emailDialog: false,
            originalRows: [],
            listening: false,
            dscType: {symbol: '%'},
            rates: [
                {
                    code: getCurrency(),
                    name: getCurrency(),
                    targetCurrency: getCurrency(),
                    baseCurrency: getCurrency(),
                    rate: 1.0,
                }
            ],
            toEmail: '',
            sendToType: "3",
            vendor: {},
            loading: true,
            activeIndex: 0,
            errors: [],
        }
        this.toast = React.createRef();

        this.quotationTypes = [
            { code: 'tax', name: strings.evat },
            { code: 'simple', name: strings.simplified },
        ]

        this.propertyNames = {
            arabicName: [
                { code: 'Prepared By', name: 'أعدت بواسطة' },
                { code: 'Approved By', name: 'تمت الموافقة عليه من قبل' },
                { code: 'Sales Person', name: 'مندوب المبيعات' },
                { code: 'Requested By', name: 'بطلب من' },
                { code: 'Email Address', name: 'البريد الالكترونى' },
                { code: 'Project Name', name: 'اسم المشروع' },
                { code: 'Other (please type)', name: 'أخرى (يرجى الكتابة)' },
            ],
            englishName: [
                { name: 'Prepared By', code: 'Prepared By' },
                { name: 'Approved By', code: 'Approved By' },
                { name: 'Sales Person', code: 'Sales Person' },
                { name: 'Requested By', code: 'Requested By' },
                { name: 'Email Address', code: 'Email Address' },
                { name: 'Project Name', code: 'Project Name' },
                { name: 'Other (please type)', code: 'Other (please type)' },
            ]
        }
        this.transactionTypes = [
            { code: 'nominal', name: strings.nominal },
            { code: 'exports', name: strings.exports },
            { code: 'summary', name: strings.summary },
        ]

        this.agreementTypes = [
            { code: 'selfBilled', name: strings.selfBilled },
            { code: 'thirdParty', name: strings.thirdParty },
        ]

        this.paymentMeansCodes = [
            { code: '1', name: strings.instrumentNotDefined },
            { code: '10', name: strings.inCash },
            { code: '30', name: strings.creditTransfer },
            { code: '42', name: strings.bankTransfer },
            { code: '48', name: strings.creditCard },
        ]

        this.reasons = [
            { code: '1', name: strings.reason1 },
            { code: '2', name: strings.reason2 },
            { code: '3', name: strings.reason3 },
            { code: '4', name: strings.reason4 }
        ]
        this.reload();

    }

    componentDidMount() {
    }


    keyBodyTemplate = (rowData, props) => {
        let { quotation } = this.state;

        if (quotation.confirmed) {
            return <span>{rowData.key}</span>
        }
        return (
            <>
                {/* <span className="p-column-title">Value</span> */}
                {/* {rowData.value} */}
                <InputText id='key' disabled={rowData.key} type="text" value={props.rowData['key']} onChange={(e) => this.onEditorValueChange('key', props, e.target.value)} required autoFocus />
            </>
        );
    }


    reload = () => {
        var vendorService = new VendorService();
        this.setState({ errors: [] });

        let company = new SubscriptionService().GetActiveCompany();
        let companyPopulted = company != null;
        let branch = new SubscriptionService().GetActiveBranch();


        new CurrencyRateService().GetCurrencyRates().then(({ data }) => {
            if (data.filter(d => d.targetCurrency == getCurrency()).length == 0) {
                data.push({
                    code: getCurrency(),
                    name: getCurrency(),
                    targetCurrency: getCurrency(),
                    baseCurrency: getCurrency(),
                    rate: 1.0,
                });
            }

            data = data.reverse();

            this.setState({
                rates: data.map(d => {
                    return {
                        code: d.targetCurrency,
                        name: d.targetCurrency,
                        ...d
                    }
                })
            });
        });

        vendorService.GetVendors(500, 0, { simple: true }).then(data => {
            if (!data.data) return [];
            return data.data.map(v => {
                return { code: v.uuid, name: v.name, ...v }
            });
        }).then(data => {
            this.setState({ vendors: data }, () => {
                let { id } = this.props.match.params;
                let { quotation } = this.state;
                if (id.startsWith("new")) {
                    this.setState({
                        quotation: {
                            referenceNumber: '',
                            quotationStatus: 2,
                            confirmed: false,
                            currencyCode: currencyCodes[0],
                            currencyRate: {
                                code: getCurrency(),
                                name: getCurrency(),
                                targetCurrency: getCurrency(),
                                baseCurrency: getCurrency(),
                                rate: 1.0,
                            },
                            transactionType: this.transactionTypes[0],
                            quotationDate: new Date(),
                            vendor: companyPopulted ? company : this.state.vendors[0],
                            branchSerial: {
                                code: branch?.serial,
                                name: `${branch?.name} (${branch?.serial})`
                            },
                            branchId: branch?.uuid
                        },
                    }, () => {
                        this.reloadCustomers();
                        this.reloadProducts();
                        this.setState({ loading: false })
                    });
                } else {
                    new QuotationService().GetQuotation(id).then(res => {
                        let vendor = this.state.vendors.filter(t => t.code == res.vendor.uuid)[0];
                        let branchSerial = vendor?.branches?.filter(t => t.serial == res?.branchSerial)[0];
                        let inv = {
                            ...quotation,
                            ...res,
                            "transactionType": this.transactionTypes.filter(t => t.code == res.transactionType)[0],
                            "currencyCode": currencyCodes.filter(t => t.code == res.currency)[0],
                            "vendor": vendor,
                            "confirmed":res.quotationStatus==1,
                            "lines": res.lines?.map((line, i) => {

                                let symbol = '%';

                                let product = line.product;

                                let taxAmount = 0.0;
                                let itemNetPriceAmount = symbol === '%' ? line.rate - (line.rate * line.discount / 100) : line.rate - line.rate ;

                                let lineExtensionAmount = itemNetPriceAmount * line.qty;
                                let rate = res.currencyRate?.rate ?? res.currencyRate;

                                let subtotal = lineExtensionAmount.toFixed(2);;
                                product.taxCategories?.map(t => taxAmount += lineExtensionAmount * (t.percent / 100) * rate);

                                let total = lineExtensionAmount + taxAmount;

                                return {
                                    ...line,
                                    "qty": line.qty,
                                    "productId": line.product.uuid,
                                    "productNumber": line.product.productNumber,
                                    "index": i + 1,
                                    "taxes": line.product.taxCategories?.map(t => `${t.name}(${t.percent}%)`).join(", "),
                                    "subtotal": subtotal,
                                    "taxAmount": taxAmount,
                                    "total": total
                                }
                            }),
                            "quotationIssueDate": res.issueDate,
                        }

                        inv.amounts = res.amounts;

                        if (branchSerial) {
                            inv.branchSerial = {
                                code: branchSerial.serial,
                                name: `${branchSerial.name} (${branchSerial.serial})`
                            }
                        }

                        let addedProducts = inv.lines.map(p => p.product);


                        this.setState({ quotation: inv, addedProducts, amounts: res.amounts }, () => {
                            this.reloadCustomers();
                            this.reloadProducts();
                            this.setState({ loading: false })
                        })
                    })
                }
            });
        });
    }

    reloadCustomers(filters = { simple: true }, skipDefault = false, newCustomer = false) {
        if (!skipDefault) this.setState({ customersLoading: true });
        let quotation = { ...this.state.quotation };
        var customerService = new CustomerService();
        customerService.GetCustomers(100, 0, quotation.vendor?.code, filters).then(data => {
            if (!data.data) {
                return { code: null, name: '-' }
            }
            return data.data.map(v => {
                return { code: v.uuid, name: v.registrationName, ...v }
            });
        }).then(data => {
            this.setState({ customers: data });
        })
            .then(() => {
                if (skipDefault) return;
                let quotation = { ...this.state.quotation };
                if (newCustomer) {
                    quotation.customer = this.state.customers[0];
                }
                else {
                    quotation.customer = {
                        ...quotation.customer,
                        name: quotation.customer.registrationName,
                        code: quotation.customer.uuid
                    };
                }
                this.setState({ quotation })
            }).catch(() => {
                this.setState({ customers: [] });
            }).finally(() => {
                let quotation = { ...this.state.quotation };
                this.setState({ quotation })
                if (!skipDefault) this.setState({ customersLoading: false });
            })
    }
    reloadProducts(filters = {}, productsLoading = true) {
        this.setState({ productsLoading: productsLoading });
        let quotation = { ...this.state.quotation };
        var productService = new ProductService();

        filters = { ...filters, simple: true, excluded: quotation.lines?.filter(p => p.productId != null).map(p => { return p.productId }) }
        productService.GetBulkProducts(100, 0, quotation.vendor?.code,filters).then(data => {
            this.setState({ products: data.data });
        }).finally(() => {
            this.setState({ productsLoading: false });
        })
    }

    confirm = () => {
        confirmDialog({
            message: `${strings.Areyousureyouwanttoproceed}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => await this.confirmQuotation(),
        });
    }
    confirmConversion = () => {
        let { id } = this.props.match.params;

        confirmDialog({
            message: `${strings.Areyousureyouwanttoproceed}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                await new QuotationService().ConvertQuotationToInvoice(id)
                this.reload();
            }
        });
    }

    onRowEditInit = (event) => {
        let originalRows = [...this.state.originalRows];
        originalRows[event.index] = { ...this.state.quotation.lines[event.index] };
        this.setState({ originalRows });
    }

    onSave = async (confirm) => {
        let { quotation } = this.state;
        this.setState({ errors: [], loading: true });
        let request = {
            ...quotation,
            "quotationStatus": confirm ? 1 : 2,
            "quotationDate": new Date(),
            "vendorId": quotation.vendor?.code,
            "customerId": quotation.customer?.code,
            "currencyRate": this.state.rates.filter(r => r.code == quotation.currencyCode?.code)[0],
            "transactionType": quotation.transactionType?.code,
            "branchSerial": quotation.branchSerial?.code,
            "currencyCode": quotation.currencyCode?.code,
            "lines": quotation.lines?.map((line => {
                return {
                    ...line,
                    "productId": line.product.uuid ?? line.product
                }
            })),
            "isActive": true
        }

        let quotationResponse = await new QuotationService().CreateQuotation(request);
        if (quotationResponse.errors) {
            let errors = [];
            Object.keys(quotationResponse.errors).forEach(key => {
                errors.push(quotationResponse.errors[key]);
            })
            this.setState({ errors });
            this.setState({ loading: false })

        } else if (quotationResponse.errorCode) {
            let errors = [];
            errors.push(strings[quotationResponse.errorCode]);
            this.setState({ errors });
            this.setState({ loading: false })
        }
        else if (quotationResponse.uuid == null) {
            let errors = [];
            errors.push(strings.internalServerError);
            this.setState({ errors });
            this.setState({ loading: false })
        }
        else {
            this.props.history.push(`/profile/quotations/${quotationResponse?.uuid}`);
            setTimeout(() => {
                this.reload();
                this.setState({ loading: false })
            }, 500)

        }
    }

    onRowEditCancel = (event) => {
        let i = { ...this.state.quotation }
        i.lines[event.index] = this.state.originalRows[event.index];
        let originalRows = [...this.state.originalRows];
        delete originalRows[event.index];
        this.setState({ quotation: i, originalRows });
    }

    confirmQuotation = async () => {
        await this.onSave(true);
        this.reload();
    }

    changeValue = (e, callback = () => { }) => {
        let i = { ...this.state.quotation };
        i[e.target.id] = e.target.value;
        this.setState({ quotation: i }, callback);
    }

    onEditorValueChange = (props, e) => {

        let i = { ...this.state.quotation }
        let ip = i.lines[props.rowIndex];


        if(props.field !== 'dscType') {
            i.lines[props.rowIndex][props.field] = e.target.value;
        }
        if(props.field === 'discountAmount') {
            var price =  i.lines[props.rowIndex]['rate']*i.lines[props.rowIndex]['qty'];
            i.lines[props.rowIndex]['discount'] = price == 0 ? 0 : e.target.value/price * 100.0;
        }

        if(props.field === 'discount') {
            var price =  i.lines[props.rowIndex]['rate']*i.lines[props.rowIndex]['qty'];
            i.lines[props.rowIndex]['discountAmount'] = price == 0 ? 0 : (e.target.value/100.0)*price;
        }


        let symbol = e.target.value.symbol ?? this.state.dscType.symbol

        let product = this.state.addedProducts.filter((p) => p.uuid == ip.productId)[0];

        let taxAmount = 0.0;
        let itemNetPriceAmount = symbol === '%' ? props.rowData.rate - (props.rowData.rate * props.rowData.discount / 100) : props.rowData.rate - props.rowData.rate ;

        let lineExtensionAmount = itemNetPriceAmount * props.rowData.qty;
        let rate = i.currencyRate?.rate ?? i.currencyRate;

        let subtotal = lineExtensionAmount.toFixed(2);
        product.taxCategories?.map(t => taxAmount += lineExtensionAmount * (t.percent / 100) * rate);

        let total = lineExtensionAmount + taxAmount;

        i.lines[props.rowIndex]['taxAmount'] = taxAmount;
        i.lines[props.rowIndex]['subtotal'] = subtotal
        i.lines[props.rowIndex]['total'] = total;

        this.setState({ quotation: i });
    }

    onPropertyEditorValueChange = (props, e) => {
        let i = { ...this.state.quotation }


        if (props.field == 'showOnPdf') {
            i.properties[props.rowIndex][props.field] = !e.target.value;
        }
        else {


            if (props.field == 'arabicName') {
                let newVal = this.propertyNames.englishName.filter(en => en.code == e.target.value.code)[0];
                if (newVal != null) {
                    i.properties[props.rowIndex]['englishName'] = newVal;
                }
            }
            else if (props.field == 'englishName') {
                let newVal = this.propertyNames.arabicName.filter(ar => ar.code == e.target.value.code)[0];
                if (newVal != null) {
                    i.properties[props.rowIndex]['arabicName'] = newVal;
                }
            }

            i.properties[props.rowIndex][props.field] = e.target.value;
        }

        this.setState({ quotation: i });
    }


    changeProductsSearchBar = (e, nameOnly) => {
        let { quotation } = this.state;

        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        this.setState({
            productLazyLoading: false,
            productName: e.query,
            typingTimeout: setTimeout(() => {
                let i = { ...quotation }
                this.reloadProducts({ name: nameOnly ? e.query : null, productNumber: nameOnly ? null : e.query, excluded: i.lines.filter(p => p.productId != null).map(p => { return p.productId }) }, false);
                this.setState({ productLazyLoading: false })
            }, 300)
        });

    }

    changeCustomerSearchBar = (e) => {
        if (this.state.typingTimeout2) {
            clearTimeout(this.state.typingTimeout2);
        }

        this.setState({
            customerName: e.query,
            typingTimeout2: setTimeout(() => {
                this.reloadCustomers({ registrationName: e.query, simple: true }, true)
            }, 200)
        });

    }

    productDropdown = (rowData, props) => {
        let { quotation, products } = this.state;
        if (quotation.confirmed) {
            let product = quotation.lines?.filter(i => i.productId == rowData.productId)[0];
            if (props.field == 'product') {
                return <span>{product?.product.name}</span>
            }

            return <span>{product?.product.productNumber}</span>
            // return <span>{JSON.stringify(quotation.lines)}</span>
        }

        return <div style={{ display: 'flex', maxWidth: '100%' }}>
            {/* {JSON.stringify(props.rowData[props.field])} */}

            <AutoComplete dropdown={props.field == 'product'} inputs placeholder={props.field == 'product' ? strings.productFilterSearchPlaceHolder : strings.searchByProductNumber} style={{ width: props.field === 'product' ? "100%": '100%' }} id="product" onSelect={(e) => {

                let i = { ...quotation }
                let { addedProducts } = this.state;

                let product = products.filter(i => i.uuid == e.value.uuid)[0];

                if (product == null) return;

                addedProducts.push(product);

                i.lines[props.rowIndex] = {};

                let symbol = '%';

                let taxAmount = 0.0;
                let itemNetPriceAmount = product.price - (product.price * product.discountPercentage / 100) ;

                let lineExtensionAmount = itemNetPriceAmount;
                let rate = quotation.currencyRate?.rate ?? quotation.currencyRate;

                let subtotal = lineExtensionAmount.toFixed(2);;
                product.taxCategories?.map(t => taxAmount += lineExtensionAmount * (t.percent / 100) * rate);

                let total = lineExtensionAmount + taxAmount;

                i.lines[props.rowIndex]['productId'] = product.uuid;
                i.lines[props.rowIndex]['label'] = product?.name;
                i.lines[props.rowIndex]['qty'] = 1;
                i.lines[props.rowIndex]['rate'] = product.price;
                i.lines[props.rowIndex]['peppol'] = product.peppol;
                i.lines[props.rowIndex]['product'] = product;
                i.lines[props.rowIndex]['discount'] = product.discount??0;
                i.lines[props.rowIndex]['productNumber'] = product.productNumber;
                i.lines[props.rowIndex]['index'] = props.rowIndex + 1;
                i.lines[props.rowIndex]['taxes'] = product.taxCategories?.map(t => `${t.name}(${t.percent}%)`).join(", ");
                i.lines[props.rowIndex]['subtotal'] = subtotal;

                i.lines[props.rowIndex]['taxAmount'] = taxAmount;
                // const reducer = (accumulator, curr, percent) => accumulator + curr * (percent/100);
                i.lines[props.rowIndex]['total'] = total;
                this.setState({ quotation: i, addedProducts }, () => {
                    // this.onEditorValueChange(props, e.originalEvent);
                });

            }} value={props.rowData[props.field]} suggestions={products?.map(o => {
                return {
                    ...o,
                    name: props.field == 'product' ? o.name : o.productNumber,
                    value: o.uuid
                }
            })} field="name" completeMethod={(e) => this.changeProductsSearchBar(e, props.field == 'product')} onChange={(e) => {
                let i = { ...quotation }
                i.lines[props.rowIndex][props.field] = e.target.value;
                this.setState({ quotation: i })
            }} />

            {props.field == 'product' && <Can yes={() => <Button style={{ width: '25px', height: '25px' }} icon="pi pi-plus" tooltipOptions={{
                position: 'top'
            }} tooltip="Create Product" onClick={() => { this.setState({ addProduct: true }) }}></Button>}
                perform={'products:create'}
                silent={true}
                role={new UserService().GetRole()} />}
        </div>


    }

    inputTextEditor = (rowData, props, type = "text") => {
        let { quotation } = this.state;


        if(props.field == 'discountAmount')
        {
            var discountAmount = (quotation.lines[props.rowIndex]['discount']/100)*quotation.lines[props.rowIndex]['rate']*quotation.lines[props.rowIndex]['qty'];
            quotation.lines[props.rowIndex][props.field] = discountAmount;
            props.rowData[props.field] = discountAmount;
        }

        if (quotation.confirmed || !check(rules, new UserService().GetRole(), "products:update")) {
            return <span>{props.rowData[props.field]}</span>
        }


        // return <div style={{ maxWidth: '100%' }}><InputText style={{ width: props.field=== 'discount' ? '50%': '100%' }} type={type} value={quotation.lines[props.rowIndex][props.field]} onChange={(e) => this.onEditorValueChange(props, e)} width="100%" />{props.field === 'discount' && <Dropdown style={{ width: '50%' }} value={this.state.dscType} options={ [{symbol:'%'}, {symbol:'#'}]} optionLabel='symbol' onChange={e=> {this.setState({dscType: e.target.value}); this.onEditorValueChange({...props,field:'dscType'}, {target: {value: e.target.value}})}}/>} </div>;
        return <div style={{ maxWidth: '5rem' }}><InputText type={type} value={quotation.lines[props.rowIndex][props.field]} onChange={(e) => this.onEditorValueChange(props, e)} style={{width:"5rem"}} /></div>;

    }

    propertyInputTextEditor = (rowData, props, type = "text") => {
        let { quotation } = this.state;

        if (quotation.confirmed || !check(rules, new UserService().GetRole(), "products:update")) {
            return <span>{props.rowData[props.field]}</span>
        }

        if (props.field == 'showOnPdf') {
            return <div style={{ maxWidth: '100%' }}><Checkbox style={{ width: '100%' }} value={quotation.properties[props.rowIndex][props.field]} checked={quotation.properties[props.rowIndex][props.field]} onChange={(e) => this.onPropertyEditorValueChange(props, e)} width="100%" /></div>;
        }

        return <div style={{ maxWidth: '100%' }}><InputText style={{ width: '100%' }} type={type} value={quotation.properties[props.rowIndex][props.field]} onChange={(e) => this.onPropertyEditorValueChange(props, e)} width="100%" /></div>;
    }

    propertyInputTextEditor = (rowData, props, type = "text") => {
        let { quotation } = this.state;

        if (quotation.confirmed || !check(rules, new UserService().GetRole(), "products:update")) {
            return <span>{props.rowData[props.field]}</span>
        }

        if (props.field == 'showOnPdf') {
            return <div style={{ maxWidth: '100%' }}><Checkbox style={{ width: '100%' }} value={quotation.properties[props.rowIndex][props.field]} checked={quotation.properties[props.rowIndex][props.field]} onChange={(e) => this.onPropertyEditorValueChange(props, e)} width="100%" /></div>;
        }

        return <div style={{ maxWidth: '100%' }}><InputText style={{ width: '100%' }} type={type} value={quotation.properties[props.rowIndex][props.field]} onChange={(e) => this.onPropertyEditorValueChange(props, e)} width="100%" /></div>;
    }

    propertyNameEditor = (rowData, props, type = "text") => {
        let { quotation } = this.state;

        if (quotation.confirmed || !check(rules, new UserService().GetRole(), "products:update")) {
            return <span>{props.rowData[props.field]}</span>
        }

        return <div style={{ maxWidth: '100%' }}><Dropdown value={props.rowData[props.field]} options={this.propertyNames[props.field]} onChange={(e) => this.onPropertyEditorValueChange(props, e)} optionLabel="name" editable /></div>;
    }


    deleteRow = (rowData, props) => {
        let quotation = { ...this.state.quotation };
        quotation.lines = quotation.lines.filter((val, i) => i != props.rowIndex);
        this.setState({ quotation });
        // this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    }
    deletePropertyRow = (rowData, props) => {
        let quotation = { ...this.state.quotation };
        quotation.properties = quotation.properties.filter((val, i) => i != props.rowIndex);
        this.setState({ quotation });
        // this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    }

    actionBodyTemplate = (rowData, props) => {
        if (!check(rules, new UserService().GetRole(), "products:update")) {
            return "";
        }

        return (
            <div className="actions">
                <Button icon="pi pi-trash" onClick={() => this.deleteRow(rowData, props)} className="p-button-rounded p-button-warning" />
            </div>
        );
    }

    propertyActionBodyTemplate = (rowData, props) => {
        if (!check(rules, new UserService().GetRole(), "products:update")) {
            return "";
        }

        return (
            <div className="actions">
                <Button icon="pi pi-trash" onClick={() => this.deletePropertyRow(rowData, props)} className="p-button-rounded p-button-warning" />
            </div>
        );
    }

    customerDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { this.setState({ addCustomer: false }) }} />
        </>
    );
    vendorDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { this.setState({ addVendor: false }) }} />
        </>
    );
    productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { this.setState({ addProduct: false }) }} />
        </>
    );

    confirmDeleteQuotation = () => {
        this.setState({ deleteQuotationDialog: true });
    }

    GetConfirmLabel = () => {
        return strings.ConfirmQuotation;
    }

    deleteQuotation = () => {

        new QuotationService().DeleteDraftedQuotation(this.state.quotation.uuid).then((data) => {
            if (data.errorCode) {
                this.toast.current.show({ severity: `${strings.error}`, summary: `${strings.CannotDeleteQuotation}`, detail: data.message, life: 3000 });
                this.setState({ deleteQuotationDialog: false });
                return;
            }
            this.toast.current.show({ severity: `${strings.success}`, summary: `${strings.DeleteDraftQuotation}`, detail: `${strings.Deleteddraftsuccessfully}`, life: 3000 });
            this.props.history.push(`/profile/quotations/new`);
            this.reload();
        }).finally(() => {
            this.setState({ deleteQuotationDialog: false });
        });

    };

    deleteQuotationDialogFooter = (
        <>
            <Button label={strings.No} icon="pi pi-times" className="p-button-text" onClick={() => this.setState({ deleteQuotationDialog: false })} />
            <Button label={strings.Yes} icon="pi pi-check" className="p-button-text" onClick={this.deleteQuotation} />
        </>
    );

    sendQuotationToEmail = async () => {
        if (!this.state.toEmail || this.state.toEmail == '') {
            this.setState({ sendEmailError: strings.emailIsRequired });
            return;
        }

        this.setState({ sendingEmail: true })

        var res = await new QuotationService().SendQuotationToEmail({
            email: this.state.toEmail,
            pdf: this.state.sendToType == '1' || this.state.sendToType == '3',
            xml: this.state.sendToType == '2' || this.state.sendToType == '3',
            quotationId: this.state.quotation.uuid
        });
        this.setState({ sendingEmail: false })

        if (res.status == 400) {
            this.setState({ sendEmailError: strings.emailValidationError });
        }

        this.setState({ emailSent: true });
    }

    sendToEmailDialogFooter = () => (
        !this.state.emailSent ?
            <>
                <Button label={strings.Cancel} icon="pi pi-times" className="p-button-text" onClick={() => this.setState({ emailDialog: false, toEmail: this.state.quotation?.customer?.email, emailSent: false })} />
                <Button label={strings.Send} icon="pi pi-send" loading={this.state.sendingEmail} className="p-button-text" onClick={this.sendQuotationToEmail} />
            </>
            : ''
    );


    AddRow = () => {
        let len = 0;
        if (this.state.quotation.lines?.length) len = this.state.quotation.lines?.length;

        let rows = [...this.state.quotation.lines ?? [], {
            index: len + 1,
            qty: 0,
            label: "",
            productId: "",
            rate: 0.0,
            unit: "",
            peppol: "",
            discount: 0.0
        }];

        let i = { ...this.state.quotation };
        i.lines = rows;
        this.setState({ quotation: i });
    }

    AddCustomPropertyRow = () => {
        let len = 0;
        if (this.state.quotation.properties?.length) len = this.state.quotation.properties?.length;

        let rows = [...this.state.quotation.properties ?? [], {
            index: len + 1,
            key: "",
            arabicName: "",
            englishName: "",
            showOnPdf: true,
            value: "",
        }];

        let i = { ...this.state.quotation };
        i.properties = rows;
        this.setState({ quotation: i });
    }

    render() {
        let { quotation, errors, vendors, customers, loading } = this.state;

        let role = new UserService().GetRole();

        let { id } = this.props.match.params;

        let canEdit = (!quotation.confirmed && quotation.quotationTypeCode == '388') || (id.startsWith("new"))

        let companyPopulted = new SubscriptionService().GetActiveCompany() != null;
        let branchPopulated = new SubscriptionService().GetActiveBranch() != null;

        if (loading) {
            return <div style={{ textAlign: 'center', margin: "auto" }}><ProgressSpinner /></div>
        }
        let token = localStorage.getItem("token");

        const leftToolbarTemplate = () => {
            return (
                <React.Fragment>
                    {quotation.uuid && !quotation.confirmed ? <Can yes={() => <Button type="button" label={this.GetConfirmLabel()} icon="pi pi-check-circle" onClick={this.confirm} className="p-mb-3"></Button>}
                        perform={'quotations:create'}
                        silent={true}
                        role={role} /> : ''}

                    {quotation.confirmed && !quotation.linkedInvoiceId ? <Can yes={() => <Button type="button" label={strings.convertQuotationToInvoice} icon="pi pi-check-circle" onClick={this.confirmConversion} className="p-mb-3"></Button>}
                        perform={'quotations:create'}
                        silent={true}
                        role={role} /> : ''}

                    {quotation.confirmed && quotation.linkedInvoiceId ? <Can yes={() => <Button type="button" label={strings.checkDraftInvoice} icon="pi pi-check-circle" onClick={()=>{
                            this.props.history.push(`/profile/invoices/${quotation.linkedInvoiceId}`);
                        }} className="p-mb-3"></Button>}
                        perform={'quotations:create'}
                        silent={true}
                        role={role} /> : ''}



                    {!quotation.confirmed ? <Can yes={() => <Button type="button" label={strings.saveDraft} icon="pi pi-save" onClick={() => this.onSave(false)} className="p-mb-3"></Button>}
                        perform={'quotations:draft:update'}
                        silent={true}
                        role={role} /> : ''}

                    {quotation.uuid && quotation.receiptPath ? <Button className="p-mb-3" type="button" label={strings.downloadPDFQuotation} icon="pi pi-file-pdf" tooltipOptions={{
                        position: 'top'
                    }} tooltip={strings.downloadPDF} onClick={() => {
                        new JsFileDownloader({
                            url: `${quotation.receiptPath}`,
                            headers: [{
                                name: 'Authorization',
                                value: `Bearer ${token}`
                            }]
                        })
                            .then(() => {
                                this.toast?.current.show({ severity: `${strings.success}`, summary: 'Downloaded PDF', life: 3000 });
                            })
                            .catch((error) => {
                                this.toast?.current.show({ severity: `${strings.error}`, summary: 'PDF is not ready yet, refresh and try again.', life: 3000 });
                            });
                    }} /> : (quotation.uuid && <Button className="p-mb-3" type="button" label="Generating PDF" loading disabled icon="pi pi-file-pdf" />)}

                </React.Fragment>
            )
        }

        const rightToolbarTemplate = () => {
            return (
                <React.Fragment>
                    {quotation.uuid && quotation.confirmed ? <Can yes={() => <Button className="p-mb-3" type="button" label={strings.SendToEmail} icon="pi pi-send" tooltipOptions={{ position: 'top' }} tooltip="Refresh" onClick={() => this.setState({ emailDialog: true, toEmail: quotation?.customer?.email })} />}
                        perform={'quotations:sendEmail'}
                        silent={true}
                        role={role} /> : ''}

                    {quotation.uuid && <Button className="p-mb-3" type="button" label={strings.Refresh} icon="pi pi-refresh" tooltipOptions={{ position: 'top' }} tooltip="Refresh" onClick={() => { this.reload() }} />}

                    {quotation.uuid && !quotation.confirmed ? <Can yes={() => <Button type="button" label={strings.DeleteDraft} icon="pi pi-times" onClick={() => this.setState({ deleteQuotationDialog: true })} className="p-mb-3 p-button-warning"></Button>}
                        perform={'quotations:draft:delete'}
                        silent={true}
                        role={role} /> : ''}

                    {quotation.confirmed ? <Can yes={() => <Button label={strings.New} icon="pi pi-plus" className="p-button-success p-mr-3 p-mb-3" onClick={() => {
                        window.location.href = `/profile/quotations/new`;
                    }} />}
                        perform={'quotations:draft:create'}
                        silent={true}
                        role={role} /> : ''}


                </React.Fragment>
            )
        }

        return (
            <div className="layout-invoice-page">
                <Toast ref={this.toast} />

                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />

                {errors?.length > 0 && <Message style={{ marginBottom: '10px' }} text={errors.map((m) => <li>{m}</li>)} severity="error" />}
                <br />
                <div className="p-grid">
                    <div className="p-md-12 p-col-12">
                        <div className="card">
                            <h5>{quotation.confirmed ? `View` : (quotation.uuid ? strings.edit : strings.createNew)} {strings.quotation} {quotation.confirmed?`#${quotation.quotationNumber}`:''}</h5>
                            <hr />
                            <div className="p-fluid p-formgrid p-grid">
<div className="p-field p-col-12 p-md-12">
                                    {/* <hr style={{borderColor:'#039b92'}}/> */}
                                    <div className="p-field p-col-12 p-md-12">
                                        <div className="p-fluid p-formgrid p-grid">
                                            <div className={`p-field p-col-12 p-md-6`} style={{ textAlign: 'center' }}>
                                                <h6>{strings.Vendor}:</h6>
                                                {canEdit && !companyPopulted ? <>

                                                    {<div style={{ display: 'flex', width: '100%', paddingTop: '10px' }}>
                                                        <Dropdown filter={true} style={{ width: '100%' }} id="vendor" filterPlaceholder={strings.filterSearchPlaceHolder} filterBy="name,vatNumber,phone,email" value={quotation.vendor} onChange={(e) => {
                                                            this.changeValue(e, () => {
                                                                let quotation = { ...this.state.quotation };
                                                                quotation.lines = [];
                                                                this.setState({ quotation: quotation });
                                                                this.reloadCustomers();

                                                                this.reloadProducts();
                                                            });
                                                        }} options={vendors} optionLabel="name" placeholder={strings.Select}></Dropdown>

                                                        <Can yes={() => <Button icon="pi pi-plus" tooltipOptions={{
                                                            position: 'top'
                                                        }} tooltip="Create Vendor" onClick={() => { this.setState({ addVendor: true }) }}></Button>}
                                                            perform={'vendors:create'}
                                                            silent={true}
                                                            role={role} />
                                                    </div>
                                                    }
                                                </> : <p>{quotation.vendor?.name}</p>}

                                                <span>{strings.VatNumber}: <b>{quotation.vendor?.vatNumber ?? '-'}:</b></span>

                                            </div>
                                            {/* {canEdit && quotation.vendor?.branches?.length > 0 && !branchPopulated ? <div className="p-field p-col-12 p-md-12" style={{ textAlign: 'center' }}>
                                                <b>{strings.Branch}:</b>
                                                <div style={{ display: 'flex', paddingTop: '10px' }}><Dropdown style={{ width: '100%' }} filter={true} id="branchSerial" value={quotation.branchSerial} onChange={this.changeValue} options={quotation.vendor?.branches?.map((i) => {
                                                    return {
                                                        code: i?.serial,
                                                        name: `${i?.name} (${i?.serial})`
                                                    }
                                                })} optionLabel="name" placeholder={strings.Select}></Dropdown></div>
                                            </div> : (quotation.branchSerial && quotation.vendor?.branches?.length > 0 ? <div className="p-field p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                                <h5>{strings.Branch}</h5>
                                                <p>{quotation.branchSerial?.name}</p>
                                            </div> : '')} */}


                                            {this.state.customersLoading ? <div style={{ textAlign: 'center' }}><ProgressSpinner /></div> : (<>
                                                {!quotation.vendor ? <div className={`p-field p-col-12 p-md-6`} style={{ textAlign: 'center' }}><Message style={{ width: '100%', marginBottom: '20px', padding: '20px' }} severity="info" content={strings.selectVendorsToShowCustomers} /></div> :
                                                    <div className={`p-field p-col-12 p-md-6`} style={{ textAlign: 'center' }}>
                                                        <h6>{strings.Customers}:</h6>
                                                        {canEdit ?

                                                            <div style={{ display: 'flex', paddingTop: '10px' }}>
                                                                <AutoComplete dropdown placeholder={strings.filterSearchPlaceHolder} style={{ width: "100%" }} id="customer" value={quotation.customer} suggestions={customers} field="name" completeMethod={this.changeCustomerSearchBar} onChange={this.changeValue} />

                                                                <Can yes={() => <Button icon="pi pi-plus" tooltipOptions={{
                                                                    position: 'top'
                                                                }} tooltip="Create Customer" onClick={() => { this.setState({ addCustomer: true }) }}></Button>}
                                                                    perform={'customers:create'}
                                                                    silent={true}
                                                                    role={role} />
                                                            </div>
                                                            : <p>{quotation.customer?.name}</p>}

                                                        <span style={{ textAlign: 'left' }}>{strings.VatNumber}: <b>{quotation.customer?.vatNumber ?? '-'}</b></span>

                                                    </div>}

                                            </>)}

                                        </div>
                                    </div>
                                    <hr/>
                                </div>

                                <div className="p-field p-col-12 p-md-6">
                                    <h6>{strings.date}:</h6>
                                    {!quotation.confirmed ? <Calendar id="quotationDate" showTime={true} showSeconds={true} showIcon showButtonBar value={formatDate(quotation.quotationDate)} onChange={this.changeValue}></Calendar> : <span>{quotation.quotationDate ? formatDateString(quotation.quotationDate) : '-'}</span>}
                                </div>

                                {<div className="p-field p-col-12 p-md-6">
                                    <h6 htmlFor="referenceNumber">{strings.referenceNumber}:</h6>
                                    {!quotation.confirmed ? <InputText id="referenceNumber" value={quotation.referenceNumber} onChange={this.changeValue} type="text" /> : <span>{quotation.referenceNumber}</span>}
                                </div>}


                                <div className="p-field p-col-12 p-md-6">
                                    <h6 htmlFor="currencyCode">{strings.Currency}:</h6>
                                    <div style={{ display: 'flex' }}>

                                        {canEdit ? <Dropdown id="currencyCode" value={quotation.currencyCode} onChange={(e) => {
                                            this.changeValue(e, () => {
                                                this.changeValue({
                                                    target: {
                                                        id: "currencyRate",
                                                        value: this.state.rates.filter(r => r.code == e.target.value?.code)[0]
                                                    }
                                                })
                                            })
                                        }} options={currencyCodes.filter(c => {
                                            return this.state.rates.filter(r => r.code == c.code).length > 0
                                        })} optionLabel="name" style={{ width: '100%' }} placeholder="Select One"></Dropdown> : <span>{quotation.currencyCode?.name}</span>}
                                        {canEdit && <Can yes={() => <Button icon="pi pi-eye" tooltipOptions={{
                                            position: 'top'
                                        }} tooltip="Check Currency Rate" onClick={() => { this.setState({ addRate: true }) }}></Button>}
                                            perform={'currencyRates:create'}
                                            silent={true}
                                            role={role} />}
                                        {(quotation.currencyCode?.code) != getCurrency() ?
                                            <span>&nbsp;({(quotation.currencyRate?.rate) ?? quotation.currencyRate} {quotation.currencyCode?.code} = 1 {getCurrency()})</span> : ''}
                                    </div>

                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <h6 htmlFor="transactionType">{strings.TransactionType}:</h6>
                                    {canEdit ? <Dropdown id="transactionType" value={quotation.transactionType} onChange={this.changeValue} options={this.transactionTypes.filter((e) => ((quotation.agreementType?.code != 'selfBilled' || (e.code != 'exports'))))} optionLabel="name" placeholder="Select One"></Dropdown>
                                        : <span>{quotation.transactionType?.name}</span>}

                                </div>
                                <div className='p-field p-col-12 p-md-12'>
                                    <hr/>
                                </div>


                                <div className="p-field p-col-12 p-md-12">
                                                        <b htmlFor="state">{strings.InvoiceNote}:</b>
                                                        {!quotation.confirmed ? <InputTextarea id="notes" value={quotation.notes} onChange={this.changeValue} type="text" /> : <span>{quotation.notes}</span>}

                                                    </div>

                            </div>
                        </div>
                    </div>


                    <div className="p-col-12">
                        <div className="card">

                            {quotation.vendor ? <div>
                                <div className="p-field p-col-12 p-md-12" >
                                    <DataTable
                                        header={<div>
                                            <h4>{strings.Lines}</h4>
                                        </div>}
                                        footer={quotation.lines?.length > 0 && (!quotation.confirmed) && <Can yes={() => <div style={{ width: '100%', textAlign: 'center', marginLeft: '10px', marginTop: '-7px' }}><Button icon="pi pi-plus" title={strings.addLine} style={{ width: '25%', alignSelf: 'center', marginLeft: '0px', marginTop: '5px' }} onClick={this.AddRow} className="p-button-rounded p-button-success p-mr-4">&nbsp;{strings.addLine}</Button></div>}
                                            perform={'products:create'}
                                            silent={true}
                                            role={new UserService().GetRole()} />}
                                        value={quotation.lines}
                                        dataKey="id"
                                        responsiveLayout="scroll"
                                        resizableColumns columnResizeMode="fit"
                                        lazy
                                        stripedRows
                                        autoLayout={true}
                                        loading={this.state.linesLoading}
                                        showGridlines={true}
                                        onRowEditInit={this.onRowEditInit}
                                        onRowEditCancel={this.onRowEditCancel}
                                        className="datatable-responsive"
                                        emptyMessage={(!quotation.confirmed) && <Can yes={() => <div style={{ width: '100%', textAlign: 'center', marginLeft: '10px', marginTop: '-7px' }}><Button icon="pi pi-plus" title={strings.addLine} style={{ width: '25%', alignSelf: 'center', marginLeft: '10px', marginTop: '-7px' }} onClick={this.AddRow} className="p-button-rounded p-button-success p-mr-2">&nbsp;{strings.addLine}</Button></div>}
                                            perform={'products:create'}
                                            role={new UserService().GetRole()} />}
                                    >
                                        <Column style={{ width: '2%' }} field="index" header={'#'} body={(rawData, props) => {
                                            return <b>{rawData['index']}</b>;
                                        }}></Column>
                                        <Column style={{ width: '35%', textAlign: 'center' }} field="product" header={strings.Product} body={this.productDropdown}></Column>
                                        <Column style={{ width: '15%', textAlign: 'center' }} field="productNumber" header={strings.ProductNumber} body={this.productDropdown}></Column>
                                        {/* <Column field="label" header={strings.Label} body={(rawData, props) => this.inputTextEditor(rawData, props)}></Column> */}
                                        <Column style={{ width: '7%', textAlign: 'center' }} field="qty" header={strings.Quantity} body={(rawData, props) => this.inputTextEditor(rawData, props, 'number')}></Column>
                                        <Column style={{ width: '3rem', textAlign: 'center' }} field="rate" header={`${strings.Price} (${quotation.currencyCode?.code})`} body={(rawData, props) => this.inputTextEditor(rawData, props, 'number')}></Column>
                                        <Column style={{ width: '5%', fontSize: '12px', textAlign: 'center' }} field="discount" header={`${strings.DISC}%`} body={(rawData, props) => this.inputTextEditor(rawData, props, 'number')}></Column>
                                        <Column style={{ width: '5%', fontSize: '12px', textAlign: 'center' }} field="discountAmount" header={`${strings.DISC} Amount`} body={(rawData, props) => this.inputTextEditor(rawData, props, 'number')}></Column>
                                        <Column style={{ width: '5%', textAlign: 'center' }} field="taxes" header={strings.Taxes} body={(rowData) => <div>{rowData['taxes']}</div>}></Column>
                                        <Column style={{ width: '7%', textAlign: 'center' }} field="taxAmount" header={`${strings.TaxAmount} ({getCurrency()})`} body={(rowData) => <div>{rowData['taxAmount']?.toLocaleString()} {getCurrency()}</div>}></Column>
                                        <Column style={{ width: '7%', textAlign: 'center' }} field="subtotal" header={`${strings.Subtotal} (${quotation.currencyCode?.code})`} body={(rowData) => <div>{rowData['subtotal']?.toLocaleString()}</div>}></Column>
                                        <Column style={{ width: '10%', textAlign: 'center' }} field="total" header={`${strings.Total} (${quotation.currencyCode?.code})`} body={(rowData) => <div>{rowData['total']?.toLocaleString()}</div>}></Column>
                                        {!quotation.confirmed && <Column header="Action" body={this.actionBodyTemplate}></Column>}
                                    </DataTable>
                                </div>
                                {quotation.uuid ?
                                    <div>
                                        <hr />
                                        <div className="p-col-3 p-md-3 p-offset-9">
                                            <div className=" p-col-12 p-md-12">
                                                <Message severity="info" content={strings.TotalCalcNote}></Message>
                                            </div>
                                            <div className=" p-col-12 p-md-12">
                                                <label htmlFor="paymentType">{strings.invoiceTotal}: </label>
                                                <span id="paymentType">{quotation.totalWithoutVat} {quotation.currency}</span>
                                            </div>
                                            <div style={{ wordWrap: "break-word" }} className=" p-col-12 p-md-12">
                                                <label htmlFor="paymentType">{strings.discount}: </label>
                                                <span id="paymentType">{quotation.totalDiscount} {quotation.currency}</span>
                                            </div>
                                            <div style={{ wordWrap: "break-word" }} className=" p-col-12 p-md-12">
                                                <label htmlFor="paymentType">{strings.taxableAmount}: </label>
                                                <span id="paymentType">{quotation.totalTaxableAmountWithoutVat} {quotation.currency}</span>
                                            </div>
                                            <div style={{ wordWrap: "break-word" }} className="p-col-12 p-md-12">
                                                <label htmlFor="paymentType">{strings.totalVat}: </label>
                                                <span id="paymentType">{quotation.totalVat} {quotation.currency}</span>
                                            </div>
                                            <hr />
                                            <div style={{ wordWrap: "break-word" }} className=" p-col-12 p-md-12">
                                                <label htmlFor="paymentType">{strings.taxInclAmount}: </label>
                                                <span id="paymentType">{quotation.taxInclusiveAmount} {quotation.currency}</span>
                                            </div>
                                        </div>
                                    </div>
                                    : <div className="p-col-2 p-md-2 p-offset-10">
                                        <Message severity="info" content={strings.SaveDraftToShowTotals}></Message>
                                    </div>}

                            </div> : <Message style={{ width: '100%' }} severity="info" content={strings.Selectvendortoaddquotationlines} />}
                        </div>
                    </div>

                    <Dialog visible={this.state.addCustomer} style={{ width: '75%' }} header={strings.CustomerDetails} modal className="p-fluid" footer={this.customerDialogFooter} onHide={() => { this.setState({ addCustomer: false }) }}>
                        <CustomerForm OnHideDialog={() => { this.setState({ addCustomer: false }) }} vendor={quotation.vendor} full={true} FillData={() => this.reloadCustomers({}, false, true)} />
                    </Dialog>

                    <Dialog visible={this.state.addProduct} style={{ width: '700px' }} header={strings.linesDetails} modal className="p-fluid" footer={this.productDialogFooter} onHide={() => { this.setState({ addProduct: false }) }}>
                        <ProductForm OnHideDialog={() => { this.setState({ addProduct: false }) }} vendor={quotation.vendor} full={true} context={this.props.context} FillData={() => this.reloadProducts()} />
                    </Dialog>

                    <Dialog visible={this.state.addVendor} style={{ width: '75%' }} header={strings.VendorsDetails} modal className="p-fluid" footer={this.vendorDialogFooter} onHide={() => { this.setState({ addVendor: false }) }}>
                        <VendorForm OnHideDialog={() => { this.setState({ addVendor: false }) }} full={true} FillData={() => this.reload()} />
                    </Dialog>
                    <Dialog visible={this.state.addRate} style={{ width: '75%' }} modal className="p-fluid" footer={this.vendorDialogFooter} onHide={() => { this.setState({ addRate: false }) }}>
                        <CurrencyRates />
                    </Dialog>

                    <Dialog visible={this.state.deleteQuotationDialog} style={{ width: '450px' }} header={strings.Confirm} modal footer={this.deleteQuotationDialogFooter} onHide={() => this.setState({ deleteQuotationDialog: false })}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {<span>{strings.Areyousureyouwanttodeletedraft}?</span>}
                        </div>
                    </Dialog>
                    <Dialog visible={this.state.emailDialog} style={{ width: '450px' }} header={strings.SendToEmail} modal footer={this.sendToEmailDialogFooter} onHide={() => this.setState({ emailDialog: false, toEmail: quotation?.customer?.email, emailSent: false })}>
                        <div className="confirmation-content">
                            {!this.state.emailSent ? <div className="p-fluid p-grid">
                                <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                                    <label htmlFor="toEmail" className="required">{strings.email}</label>
                                    <InputText id="toEmail" value={this.state.toEmail} defaultValue={this.state.quotation?.customer?.email} onChange={(e) => this.setState({ toEmail: e.target.value })} />
                                </div>
                                <div className="p-field-checkbox" style={{ padding: '10px' }}>
                                    <RadioButton inputId="checkOption3" name="option" value="1" checked={this.state.sendToType == "1"} onChange={(e) => this.setState({ sendToType: e.target.value })} />
                                    <label htmlFor="checkOption3">{strings.pdf}</label>
                                </div>

                                <div className="p-field-checkbox" style={{ padding: '10px' }}>
                                    <RadioButton inputId="checkOption3" name="option" value="2" checked={this.state.sendToType == "2"} onChange={(e) => this.setState({ sendToType: e.target.value })} />
                                    <label htmlFor="checkOption3">{strings.xml}</label>
                                </div>

                                <div className="p-field-checkbox" style={{ padding: '10px' }}>
                                    <RadioButton inputId="checkOption3" name="option" value="3" checked={this.state.sendToType == "3"} onChange={(e) => this.setState({ sendToType: e.target.value })} />
                                    <label htmlFor="checkOption3">{strings.both}</label>
                                </div>
                            </div> : <div className="p-fluid p-grid">
                                <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                                    <Message severity="success" content={strings.emailSent} />
                                </div>
                            </div>}
                        </div>
                    </Dialog>
                    <RegisterListerners context={this.props.context} quotation={quotation} updateQuotation={(inv) => this.setState({ quotation: inv })} toast={this.toast} />
                </div>
            </div>
        );
    }
}
