import {createSlice} from "@reduxjs/toolkit"

import CustomerService from '../service/CustomerService'


const customers = createSlice({
    name: 'customers',
    initialState: [],
    reducers: {
        addCustomers(state, action){
            return action.payload
        }
    }
})

export const getCustomers = (vendorID = '', limit=10, offset = 0, filters={}) => async (dispatch) =>{
    try {
        let {count, data} = await new CustomerService().GetCustomers(limit, offset, vendorID, filters)
        dispatch(addCustomers(data))
    } catch (error) {
    console.log("🚀 ~ file: customers.js ~ line 20 ~ getCustomers ~ error", error)
        
    }
}

export const {addCustomers} = customers.actions

export default customers.reducer