import { createSlice } from "@reduxjs/toolkit"

const supplierProducts = createSlice({
    name: 'supplierProducts',
    initialState: {msg:'', products: {count: 0, data: []}, product: {}},
    reducers: {
        addData(state, action) {
            return { ...state, ...action.payload}
        }, 
        addMsg(state, action) {
            return {...state, msg: action.payload}
        }
    }
})

export default supplierProducts.reducer

export const {addData, addMsg} = supplierProducts.actions