import PurchaseOrderForm from "../PurchaseOrderForm"
import EditOrder from "./EditOrder"
import  Order  from "./Order"
import Orders from "./Orders"


const routes = [
    { path: '/profile/orders', component: Orders, perform: 'orders:route' , exact: true },
    // { path: '/profile/orders/new', component: PurchaseOrderForm, perform: 'orders:create', exact:false},
    // { path: '/profile/orders/edit/:id', component: EditOrder, perform: 'orders:update', exact:false}
]

export default routes
