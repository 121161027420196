import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import ExpenseService from '../service/ExpenseService';
import { ProgressSpinner } from 'primereact/progressspinner';
import VendorService from '../service/VendorService';
import { Message } from 'primereact/message';
import strings from '../localization';
import Can from '../Can';
import ExpenseTypeService from '../service/ExpenseTypeService';
import { InputTextarea } from 'primereact/inputtextarea';
import { formatDate } from './InvoiceDates';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { CurrencyRates } from './CurrencyRates';
import CurrencyRateService from '../service/CurrencyRateService';
import {getCurrency} from '../service/helpers'



const currencyCodes = [
    { code: getCurrency(), name: getCurrency() },
    { code: 'USD', name: 'USD' },
    { code: 'EUR', name: 'EUR' },
    { code: 'AED', name: 'AED' },
    { code: 'KWD', name: 'KWD' },
]

export default class ExpenseForm extends Component {

    constructor(props) {
        super(props);

        let emptyExpense = {
            amount: 0,
            quantity: "",
            unitCode: "",
            currency: currencyCodes[0],
            taxCategories: [],
            nameen: "",
            namear: "",
            expenseType: {},
            decriptionen: "",
            peppol: "",
            decriptionar: "",

        }

        this.state = {
            expense: emptyExpense,
            taxCategories: [],
            rates: [
                {
                    code: getCurrency(),
                    name: getCurrency(),
                    targetCurrency: getCurrency(),
                    baseCurrency: getCurrency(),
                    rate: 1.0,
                }
            ],
            Selected: [],
            errors: [],
            vendor: [],
            loading: false,
            pageLoading: false,
        };
        this.toast = React.createRef();
    }

    PopulateVendors = async () => {
        let vendorService = new VendorService();
        this.setState({ pageLoading: true });
        var vendors = await vendorService.GetVendors(500);
        this.setState({ vendors: vendors.data, pageLoading: false })
    }
    PopulateExpenseTypes = async () => {
        let expenseTypeService = new ExpenseTypeService();
        this.setState({ pageLoading: true });
        var expenseTypes = await expenseTypeService.GetExpenseTypes(500);
        this.setState({ expenseTypes: expenseTypes.data, pageLoading: false })
    }



    PopulateBranches = async () => {
        let { branches, showBranch } = this.props.context ?? {};
        if (showBranch) {
            this.setState({ branches: this.state.expense['vendor']?.branches })
        }
        else {
            this.setState({ branches });
        }
    }

    componentDidMount() {

        if (this.props.context?.showCompany) this.PopulateVendors();

        this.PopulateExpenseTypes();
        this.PopulateBranches();
        if (this.props.expense != null) {
            // Fill Data
            this.PopulateExpense()
        }
        else {
            this.setState({
                expense: {
                    vendor: {
                        code: this.props.context?.vendor?.uuid,
                        name: this.props.context?.vendor?.name,
                    },
                    expenseType: {
                        code: this.props.context?.expenseType?.uuid,
                        name: this.props.context?.expenseType?.label,
                    },
                    branch: {
                        code: this.props.context?.branch?.uuid,
                        name: this.props.context?.branch?.name,
                    },
                    currency: currencyCodes[0]
                }
            })
        }

        new CurrencyRateService().GetCurrencyRates().then(({ data }) => {
            if (data.filter(d => d.targetCurrency == getCurrency()).length == 0) {
                data.push({
                    code: getCurrency(),
                    name: getCurrency(),
                    targetCurrency: getCurrency(),
                    baseCurrency: getCurrency(),
                    rate: 1.0,
                });
            }

            data = data.reverse();

            this.setState({
                rates: data.map(d => {
                    return {
                        code: d.targetCurrency,
                        name: d.targetCurrency,
                        ...d
                    }
                })
            });
        });
    }


    PopulateExpense() {
        this.setState({
            expense: {
                ...this.props.expense,
                vendor: {
                    code: this.props.expense.vendor?.uuid,
                    name: this.props.expense.vendor?.name,
                },
                expenseType: {
                    code: this.props.expense.expenseType?.uuid,
                    name: this.props.expense.expenseType?.label,
                },
                currency: {
                    code: this.props.expense.currency,
                    name: this.props.expense.currency
                },
                currencyRate: {
                    rate: this.props.expense.currencyRate
                },

            }
        })
    }

    onInputChange = (e, name) => {
        let val = (e.target && e.target.value) || '';
        let _expense = { ...this.state.expense };

        if (name == 'discountPercentage') {
            if (val > 100) {
                val = 100;
            }

            if (val < 0) {
                val = 0;
            }
        }
        _expense[`${name}`] = val;

        this.setState({ expense: _expense });
    }

    submitForm = async () => {
        this.setState({ loading: true });

        let { expense } = this.state;

        let data = {
            ...expense,
            "vendorId": this.props.context?.vendor?.uuid ?? (expense.vendor?.code),
            "branchId": this.props.context?.branch?.uuid ?? (expense.branch?.code),
            "expenseTypeId": expense.expenseType?.code,
            "currency": expense.currency?.code,
            "currencyRate": expense.currencyRate?.rate ?? expense.currencyRate,
            "isActive": true
        }

        var expenseService = new ExpenseService();

        if (this.props.expense == null) {
            let res = await expenseService.CreateExpense(data);
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors });
            } else {
                this.toast.current.show({ severity: 'success', summary: 'Successful', detail: strings.expenseCreatedSuccessfully, life: 3000 });
                this.props.OnHideDialog();
                this.props.FillData();
                this.setState({
                    expense: {
                        vendorId: "",
                        referenceNumber: "",
                        amount: 0,
                    },
                    errors: [],
                    Selected: []
                });
            }
        }
        else {
            data.uuid = this.props.expense.uuid;
            let res = await expenseService.UpdateExpense(data);
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors });
            } else {
                this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'expense Updated', life: 3000 });
                this.props.OnHideDialog();
                this.props.FillData();
            }
        }
        this.setState({ loading: false });
    }


    vendorDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { this.setState({ addVendor: false }) }} />
        </>
    );

    render() {
        if (this.state.pageLoading) {
            return <div style={{ textAlign: 'center' }}><ProgressSpinner /></div>
        }

        let { showCompany, role } = this.props.context ?? {};
        const isRTL = localStorage.getItem('lang') == 'ar' ? 'rtl' : 'ltr';

        return (

            <div className="p-grid crud-demo" style={{ direction: isRTL }}>
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={this.toast} />

                        <div className="p-field">
                            {showCompany ? <label htmlFor="name">{strings.Vendor}*</label> : ''}
                            {showCompany ? <Dropdown id="vendor" value={this.state.expense?.vendor} onChange={(e) => {
                                let expense = { ...this.state.expense }
                                expense['vendor'] = e.value;
                                this.setState({ expense }, () => this.PopulateBranches());
                            }} options={this.state.vendors?.map((v) => {
                                return {
                                    code: v.uuid,
                                    name: v.name
                                }
                            })} optionLabel="name" placeholder={strings.Select}></Dropdown> : ''}
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">{strings.ExpenseType}*</label>
                            <Dropdown id="expenseType" value={this.state.expense?.expenseType} onChange={(e) => {
                                let expense = { ...this.state.expense }
                                expense['expenseType'] = e.value;
                                this.setState({ expense }, () => this.PopulateBranches());
                            }} options={this.state.expenseTypes?.map((v) => {
                                return {
                                    code: v.uuid,
                                    name: v.label
                                }
                            })} optionLabel="name" placeholder={strings.Select}></Dropdown>
                        </div>

                        <Can yes={() => <div className="p-field">
                            {this.state.expense?.vendor ? <label htmlFor="name">{strings.expenseCategory}</label> : ''}
                            {this.state.expense?.vendor ? <Dropdown id="expenseCategory" value={this.state.expense.expenseCategory} onChange={(e) => {
                                let expense = { ...this.state.expense }
                                expense['expenseCategory'] = e.value;
                                this.setState({ expense });
                            }} options={this.state.expenseCategories?.map((v) => {
                                return {
                                    code: v.uuid,
                                    name: v.label
                                }
                            })} optionLabel="name" placeholder={strings.Select}></Dropdown> : ''}
                        </div>}
                            perform={'expenseCategories:route'}
                            path={'/profile/expenseCategories'}
                            role={role} />

                        <div className='p-grid'>
                            <div className="p-field p-col-6 p-md-6">
                                <label htmlFor="name">{strings.amount}*</label>
                                <InputText id="price" type="amount" value={this.state.expense.amount} onChange={(e) => this.onInputChange(e, 'amount')} required autoFocus />
                            </div>

                            <div className="p-field p-col-6 p-md-6">
                                <b htmlFor="currencyCode">{strings.Currency}:</b>
                                <div style={{ display: 'flex', paddingTop: '6px' }}>
                                    <Dropdown id="currency" value={this.state.expense?.currency} onChange={(e) => {
                                        let expense = { ...this.state.expense }
                                        expense['currency'] = e.value;
                                        expense['currencyRate'] = this.state.rates?.filter(r => r.code == e.value?.code)[0]
                                        this.setState({ expense });
                                    }} options={currencyCodes.filter(c => {
                                        return this.state.rates.filter(r => r.code == c.code).length > 0
                                    })} optionLabel="name" style={{ width: '100%' }} placeholder="Select One"></Dropdown>
                                    <Can yes={() => <Button icon="pi pi-eye" tooltipOptions={{
                                        position: 'top'
                                    }} tooltip="Check Currency Rate" onClick={() => { this.setState({ addRate: true }) }}></Button>}
                                        perform={'currencyRates:create'}
                                        silent={true}
                                        role={role} />
                                    {(this.state.expense.currency ?? this.state.expense.currency?.code) != getCurrency() ?
                                        <span>&nbsp;({this.state.expense.currencyRate?.rate} {this.state.expense.currency?.code} = 1 {getCurrency()})</span> : ''}
                                </div>

                            </div>
                        </div>

                        <div className="p-field">
                            <label htmlFor="name">{strings.date}*</label>
                            <Calendar id="expenseDate" showTime={true} showSeconds={true} showIcon showButtonBar value={formatDate(this.state.expense.expenseDate)} onChange={(e) => this.onInputChange(e, 'expenseDate')}></Calendar>
                        </div>

                        <div className="p-field">
                            <label htmlFor="name">{strings.referenceNumber}</label>
                            <InputText id="referenceNumber" value={this.state.expense.referenceNumber} onChange={(e) => this.onInputChange(e, 'referenceNumber')} />
                        </div>

                        <b htmlFor="state">{strings.comments}:</b>
                        <InputTextarea id="comments" value={this.state.expense.comments} onChange={(e) => this.onInputChange(e, 'comments')} type="text" />

                        <div className="p-field">
                            {this.state.errors?.length > 0 && <Message text={this.state.errors.map((m) => <li>{strings[m] ?? m}</li>)} severity="error" />}
                            <Button label={strings.Save} loading={this.state.loading} onClick={this.submitForm} loadingoptions={{ position: 'right' }} />
                        </div>

                        <Dialog visible={this.state.addRate} style={{ width: '75%' }} modal className="p-fluid" footer={this.vendorDialogFooter} onHide={() => { this.setState({ addRate: false }) }}>
                            <CurrencyRates />
                        </Dialog>

                    </div>
                </div>
            </div>
        );
    }
}
