import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Message } from 'primereact/message';
import React,{Component} from 'react'
import strings from '../localization';

class InvoiceDates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoice:{},
        }
        this.ref = React.createRef();
        this.setState({invoice: this.props.invoice})
    }
    componentDidMount()
    {
    }
    render() {
        let {changeValue,invoice} = this.props;
        // let {invoice} = this.state;
        return (
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <h6 htmlFor="state"><b>{strings.dates}</b></h6>
                    {/* <div>{invoice.invoiceIssueDate}</div> */}
                </div>

                <div className="p-field p-col-12 p-md-3">
                    <b>{strings.IssueDate}:</b>
                    {/* <div>{invoice.invoiceIssueDate}</div> */}
                    <br/>
                    {invoice.confirmed ? formatDateString(invoice.invoiceIssueDate):<Message content={strings.calculatedWhenConfirmed}></Message>}

                    {/* {<span>{formatDateString(invoice.invoiceIssueDate)}</span>} */}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <b>{strings.SupplyDate}:</b>
                    <br/>
                    {!invoice.confirmed && invoice.invoiceTypeCode == '388' ? <Calendar id="actualDeliveryDate" showTime={true} showSeconds={true} showIcon showButtonBar value={formatDate(invoice.actualDeliveryDate)} onChange={changeValue}></Calendar> : <span>{invoice.actualDeliveryDate?formatDateString(invoice.actualDeliveryDate):'-'}</span>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <b>{strings.SupplyEndDate}:</b>
                    <br/>

                    {!invoice.confirmed && invoice.invoiceTypeCode == '388' ? <Calendar id="latestDeliveryDate" showTime={true} showSeconds={true} showIcon showButtonBar value={formatDate(invoice.latestDeliveryDate)} onChange={changeValue}></Calendar> : <span>{invoice.latestDeliveryDate?formatDateString(invoice.latestDeliveryDate):'-'}</span>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <b>{strings.DueDate}:</b>
                    <br/>
                    {!invoice.confirmed && invoice.invoiceTypeCode == '388' ? <Calendar ref={this.ref} id="dueDate" showTime={true} showIcon showSeconds={true} showButtonBar value={formatDate(invoice.dueDate)} onChange={changeValue}></Calendar> : <span>{invoice.dueDate?formatDateString(invoice.dueDate):'-'}</span>}
                </div>
            </div>
          );
    }
}

export function formatDate(date)
{
    if(!date) return null;
    return new Date(moment.utc(date).local().format('yyyy-MM-DD HH:mm:ss'));
}

export  function formatDateString(date)
{
    if(!date) return null;
    return moment.utc(date).local().format('yyyy-MM-DD HH:mm:ss');
}
export function formatDateStringLocal(date)
{
    if(!date) return null;
    return moment(date).local().format('yyyy-MM-DD HH:mm:ss');
}

export default InvoiceDates;
