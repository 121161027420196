import { _ProductService } from "../../service/SupplierProductService";
import {addMsg, addData } from "../supplierProducts"


export const getProducts =( {limit,offset,supplierId} )=> async (dispatch) => {
    try {
        let result = await _ProductService.GetsupplierProduct(limit,offset,supplierId)
        dispatch(addData({products: result}))
    } catch (error) {
        dispatch(addMsg(error))
    }
}

export const getProduct = payload => async (dispatch) => {
    try {
        let result = await _ProductService.getProduct(payload)
        dispatch(addData({product: result}))
    } catch (error) {
        dispatch(addMsg(error))
    }
}

export const createProduct = payload => async (dispatch, state) => {
    try {
        let result = await _ProductService.CreatesupplierProduct(payload)
        let {data, count} = state().supplierProducts.products
        dispatch(addData({products: {data: [...data, result], count: count +1}}))
    } catch (error) {
        dispatch(addMsg(error))
    }
}

export const updateProduct = payload => async (dispatch, state) => {
    try {
        let result = await _ProductService.UpdatesupplierProduct(payload)
        let {products} =  state().supplierProducts
        let newState= products.data.map(product => product.uuid === result.uuid? result : product)
        dispatch(addData({products: {...products,data: newState}}))
    } catch (error) {
        dispatch(addMsg(error))
    }
}

export const deleteProduct = (payload) => async (dispatch, state) => {
    try {
        let result = await _ProductService.DeleteSupplierProduct(payload)
        let {data, count} = state().supplierProducts.products
        let newState = data.filter(exp => exp.uuid !== result.uuid)
        dispatch(addData({expenses:{ data: newState, count: count -1}}))
    } catch (error) {
        dispatch(addMsg(error))
    }
}
