import React, {useEffect, useState} from 'react'
import {Route} from 'react-router-dom'
import routes from './routes'
import Can, { check} from '../../Can'
import rules from '../../rbac-rules'
import {useSelector} from 'react-redux'

const OrdersIndex =(props) => {
    const {role} = useSelector(state => state.user)
  return (
    <>
    {routes.map((route, i)=> <Can key={`key${i}`} yes={()=> <Route key={`route${i}`} path={route.path} exact={route.exact} component={()=> <route.component/>}/>} perform={route.perform} path={route.path} role={role}/>)}
    </>
  )
}

export default OrdersIndex