import ApiService from './ApiService';
let api = process.env.REACT_APP_API_URL

export default class SubscriptionPlanService extends ApiService {

    constructor() {
        super();
        this.path = '/subscriptionPlan';
    }

    async  CreateSubscriptionPlan(data) {
        try {
            let op = await this.Create(this.path, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }
    async  UpdateSubscriptionPlan(data) {
        try {
            let op = await this.Update(this.path+"/"+data.uuid, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }
    async  GetPlanForSubscription(id) {
        try {
            let op = await this.Read(this.path+"/"+id+"/plan");
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async GetSubscriptionPlans(limit=10,offset=0, filters) {
        try {
            let link = `${this.path}/list?limit=${limit}&offset=${offset}&${filters}`
            let op = await this.Read(link);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async DeletePlan(uuid) {
        try {
            let op = await this.Delete(this.path + "/" + uuid);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }

    async GetCount() {
        try {
            let link = `${this.path}/list?countOnly=true`
            let op = await this.Read(link);
            return op.data.count;
        } catch(e) {
            return e.response.data;
        }
    }

}
