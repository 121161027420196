import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import ChargeService from '../service/ChargeService';
import JSONPretty from 'react-json-pretty';
import { formatDateStringLocal } from './InvoiceDates';



export const Charges = () => {

    let emptyCharge = {
        "percent": 15,
        "chargeCode": "",
        "taxScheme": "VAT",
        "translations": [
            {
                name: '',
                locale: '1'
            },
            {
                name: '',
                locale: '2'
            }
        ]
    };

    const [chargeDialog, setChargeDialog] = useState(false);
    const [deleteChargeDialog, setDeleteChargeDialog] = useState(false);
    const [deleteChargesDialog, setDeleteChargesDialog] = useState(false);
    const [charge, setCharge] = useState(emptyCharge);
    const [selectedCharges, setSelectedCharges] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [charges, setCharges] = useState([]);
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        // const chargeService = new ChargeService();
        // chargeService.getCharges().then(data => setCharges(data));
        onPage({ rows, page: 0 })
    }, []);

    const formatCurrency = (value) => {
        if (!value) return ""
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setCharge(emptyCharge);
        setSubmitted(false);
        setChargeDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setChargeDialog(false);
    }

    const hideDeleteChargeDialog = () => {
        setDeleteChargeDialog(false);
    }

    const hideDeleteChargesDialog = () => {
        setDeleteChargesDialog(false);
    }

    const taxCategoyCodes = [
        { code: 'S', name: '[S] Standard rate' },
        { code: 'Z', name: '[Z] Zero rated goods' },
        { code: 'E', name: '[E] Exempt from Tax' },
        { code: 'O', name: '[O] Services outside scope of tax' },
    ]


    const editCharge = (charge) => {
        setCharge({ ...charge });
        setChargeDialog(true);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="View charge JSON" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        // return (
        //     <React.Fragment>
        //         <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" />
        //         <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
        //     </React.Fragment>
        // )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.code}
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.email}
            </>
        );
    }

    const arabicLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Label [AR]</span>
                {rowData.translations[1]?.name}
            </>
        );
    }

    const englishLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.translations[0]?.name}
            </>
        );
    }

    const percentLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Percent</span>
                {rowData.percent}
            </>
        );
    }

    const chargeCodeTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tax Category Code</span>
                {rowData.chargeCode}
            </>
        );
    }
    const customerEmailTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Customer Email</span>
                {rowData.customer.email}
            </>
        );
    }

    const customerNameTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.customer.first_name}
            </>
        );
    }
    const customerPhoneTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Phone</span>
                {rowData.customer.phone.country_code}{rowData.customer.phone.number}
            </>
        );
    }
    const statusTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                {rowData.status}
            </>
        );
    }
    const createdAtTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Created At</span>
                {formatDateStringLocal(rowData.createdAt)}
            </>
        );
    }
    const amountTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Amount</span>
                {rowData.amount} {rowData.currency}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button label="View charge JSON" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={() => editCharge(rowData)} />
            </div>
        );
    }

    const onPage = ({ first,
        rows,
        page,
        pageCount }) => {
        const chargeService = new ChargeService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        chargeService.GetCharges(rows, (page) * rows).then(({ data, count }) => {
            setLoading(false);
            setCharges(data);
            setTotalRecords(count);
        });

    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">View Charges</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const chargeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );
    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {/* <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> */}

                    <DataTable ref={dt}
                        value={charges}
                        selection={selectedCharges}
                        dataKey="id"
                        totalRecords={totalRecords}
                        lazy
                        first={first}
                        loading={loading}
                        autoLayout={true}
                        onPage={onPage}
                        paginator rows={rows}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} charges"
                        globalFilter={globalFilter}
                        emptyMessage="No charges found."
                        header={header}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="customer" header="Customer Name" style={{ width: "250px" }}body={customerNameTemplate}></Column>
                        <Column field="customerEmail" header="Customer Email" style={{ width: "250px" }}body={customerEmailTemplate}></Column>
                        <Column field="customerPhone" header="Customer Phone" style={{ width: "250px" }}body={customerPhoneTemplate}></Column>
                        <Column field="status" header="Status" style={{ width: "250px" }}body={statusTemplate}></Column>
                        {/* <Column field="namear" header="Name [AR]" style={{ width: "250px" }} body={arabicLabelTemplate}></Column>
                        <Column field="nameen" header="Name [EN]"body={englishLabelTemplate}></Column> */}
                        <Column field="amount" header="Amount"body={amountTemplate}></Column>
                        <Column field="createdAt" header="Created At"body={createdAtTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={chargeDialog} style={{ width: '450px' }} header="Charge JSON" modal className="p-fluid" footer={chargeDialogFooter} onHide={hideDialog}>

                        <div>
                        <JSONPretty id="json-pretty" data={charge}></JSONPretty>

                        </div>

                    </Dialog>

                </div>
            </div>
        </div>
    );
}
