import React from "react";
import { Redirect, Route } from "react-router-dom";

function AuthlessRoute({ component: Component, ...restOfProps }) {
  const isAuthenticated = localStorage.getItem("token") != null;

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ?<Redirect to="/profile" />: <Component {...props} />
      }
    />
  );
}

export default AuthlessRoute;
