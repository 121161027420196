import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import TaxCategoriesService from '../service/TaxCategoriesService';
import ProductService from '../service/ProductService';
import { useTheme } from 'styled-components';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';
import VendorService from '../service/VendorService';
import { Message } from 'primereact/message';
import strings from '../localization';
import ReactImageUploading from 'react-images-uploading';
import ApiService from '../service/ApiService';
import ProductCategoryService from '../service/ProductCategoryService';
import SubscriptionService from '../service/SubscriptionService';
import Can from '../Can';
import {connect } from 'react-redux'
import { addProduct } from '../store/products';
import { getProducts } from '../store/products';
import { addPProductHandler } from '../store/products';

const mapStateToProps = (state)=>({
    pVendors: state.vendors.pVendors
})
const mapDispatchToProps = (dispatch) => ({
    addProduct: () => dispatch(addProduct),
});

export default connect( mapStateToProps, {...mapDispatchToProps,getProducts, addPProductHandler}) (class ProductForm extends Component {


    constructor(props) {
        super(props);

        let emptyProduct = {
            vendorId: "",
            productNumber: "",
            sku: "",
            barcode: "",
            price: "",
            discountPercentage: "",
            discountAmount: "",
            quantity: "",
            unitCode: "",
            taxCategories:[],
            nameen:"",
            namear:"",
            productType:{},
            decriptionen:"",
            peppol:"",
            decriptionar:"",

        }

        const citySelectItems = [];

        this.state = {
            product: { emptyProduct },
            taxCategories: [],
            Selected : [],
            errors:[],
            vendor:[],
            loading: false,
            pageLoading: false,
        };
        this.toast = React.createRef();
    }

     citySelectItems = [
        {label: 'New York', value: 'NY'},
        {label: 'Rome', value: 'RM'},
        {label: 'London', value: 'LDN'},
        {label: 'Istanbul', value: 'IST'},
        {label: 'Paris', value: 'PRS'}
    ];


    FillVendors = async () => {
        let vendorService = new VendorService();
        this.setState({pageLoading: true});
        var vendors = this.props.type === 'purchase' ? {data: this.props.pVendors} : await vendorService.GetVendors(500);
        this.setState({ vendors: vendors.data, pageLoading: false })
    }

    FillBranches = async () => {
        let { branches, showBranch } = this.props.context??{};
        if (showBranch) {
            this.setState({ branches: this.state.product['vendor']?.branches })
        }
        else
        {
            this.setState({ branches });
        }
    }

    FillProductCategories = async (vendorId) => {
        let productCategoryService = new ProductCategoryService();
        this.setState({pageLoading: true});
        var productCategories = await productCategoryService.GetProductCategory(500,0,vendorId);
        this.setState({ productCategories: productCategories.data, pageLoading: false })
    }

    componentDidMount() {

        this.FillTaxCategory();
        if(this.props.context?.showCompany) this.FillVendors();
        this.FillBranches();
        this.FillProductCategories(this.props.context?.vendor?.uuid??'');
        if(this.state.product.vendor?.code ?? this.props.vendor?.code)
        {
        }
        // console.log(this.props.user)
        if(this.props.product != null)
        {
            // Fill Data
            this.ReturnData()
        }
        else
        {
            this.setState({
                product: {
                    vendor: {
                        code: this.props.context?.vendor?.uuid,
                        name: this.props.context?.vendor?.name,
                    },
                    branch: {
                        code: this.props.context?.branch?.uuid,
                        name: this.props.context?.branch?.name,
                    }
                }
            })
        }
    }


    ReturnData()
    {
        this.setState({
            product: {
                ...this.props.product,
                vendor: {
                    code: this.props.product.vendor?.uuid,
                    name: this.props.product.vendor?.name,
                },
                productType: this.productTypes.filter(t => t.code == this.props.product.productType)[0]
            }
        })
    }

    onEditorValueChange(productKey, props, value) {
        let updatedRow = [...props.value];
        updatedRow[props.rowIndex][props.field] = value;
        this.setState({ [`${productKey}`]: updatedRow });
    }

    onSelect(e) {

        // let updatedRow = [...this.state.product];
        // updatedRow.taxCategories = e.value;
        // this.setState({ this.state.pr: citySelectItems });
        let tax = this.state.product.taxCategories
        this.setState({ tax: e.value })
    }



    onInputChange = (e, name) => {
        let val = (e.target && e.target.value) || '';
        let _product = { ...this.state.product };

        if(name=='discountPercentage')
        {
            if(val > 100)
            {
                val = 100;
            }

            if(val < 0)
            {
                val = 0;
            }
        }
        _product[`${name}`] = val;

        this.setState({ product: _product });
    }


    FillTaxCategory = async () => {
        let taxCategoriesService = new TaxCategoriesService();
        this.setState({pageLoading: true})
        var taxes = await taxCategoriesService.GetTaxCategory(50,0);
        this.setState({Selected:taxes.data?.filter(tx=>tx.taxCategoryCode=='S'&&tx.percent==15).map((tx)=>tx.uuid)})

        let newTax = taxes.data?.map((row) => {
            return {
                "label": row.name,
                "value": row.uuid
            };
        })


        this.setState({taxCategories:newTax, pageLoading: false });

    }



    GetData = () => {
        // console.log(this.state.user);

    }


     delete = (rowData) => {
        // let _Product = {...this.state.product};
        // _Product.rows = _Product.rows.filter(val => val.identityId == rowData.identityId);
        // this.setState({customer:_customer});
        // this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    }

    productTypes=[
        { "code": "service", "name": strings.service },
        { "code": "consumable", "name": strings.consumable }
    ];
    save = async () => {
        this.setState({ loading: true });

        let { product } = this.state;

        let translations = [];

        if((product.nameen != null && product.nameen != '') || product.decriptionen != null)
        {
            translations.push({
                "name": product.nameen,
                "description": product.decriptionen,
                "locale": 1
            })
        }
        if ((product.namear != null && product.namear != '') || product.decriptionar != null) {
            translations.push({
                "name": product.namear,
                "description": product.decriptionar,
                "locale": 2
            })
        }

        let data = {
            "vendorId": this.props.context?.vendor?.uuid ?? (product.vendor?.code) ,
            "branchId": this.props.context?.branch?.uuid ?? (product.branch?.code),
            "productCategoryId": (product.productCategory?.code),
            "productType": product.productType?.code,
            "productNumber": product.productNumber,
            "image":product.image,
            "sku": product.sku,
            "barcode": product.barcode,
            "peppol": product.peppol,
            "price": product.price,
            "discountPercentage": product.discountPercentage ?? 0,
            "unitCode": product.unitCode,
            "taxCategories": this.state.Selected,
            "translations": translations,
            "isActive": true,
        }
        var productService = new ProductService();

        if (this.props.product == null) {
            console.log("🚀 ~ file: ProductForm.js ~ line 271 ~ ProductForm ~ save= ~ this.props.type", this.props.type)
            let res = this.props.type === 'purchase'? this.props.addPProductHandler(data) : await productService.Createproduct(data);
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors });
            } else {
                this.props.getProducts(data.vendorId)
                this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'product Created', life: 3000 });
                this.props.OnHideDialog();
                this.props.FillData();
                this.setState({
                    product: {
                        vendorId: "",
                        "image":"",
                        productNumber: "",
                        sku: "",
                        barcode: "",
                        price: "",
                        discountPercentage: "",
                        discountAmount: "",
                        quantity: "",
                        unitCode: "",
                        taxCategories: [],
                        nameen: "",
                        namear: "",
                        decriptionen: "",
                        decriptionar: "",
                    },
                    errors: [],
                    Selected: []
                });
            }
        }
        else {
            data.uuid = this.props.product.uuid;
            let res = await productService.Updateproduct(data);
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors });
            } else
            {
                this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'product Updated', life: 3000 });
                this.props.OnHideDialog();
                this.props.FillData();
            }
        }
        this.setState({ loading: false });
    }


    render() {
        if(this.state.pageLoading) {
            return <div style={{textAlign:'center'}}><ProgressSpinner/></div>
        }
        let {showBranch, showCompany, branches, company, role} = this.props.context??{};
        let image = this.state.product.image;
        const isRTL = localStorage.getItem('lang')== 'ar' ? 'rtl' : 'ltr';

        return (

            <div className="p-grid crud-demo" style={{direction:isRTL}}>
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={this.toast} />
                        <div className="p-field">
                            <label htmlFor="name">{strings.NameEnglish}</label>
                            <InputText id="nameen" value={this.state.product.nameen} onChange={(e) => this.onInputChange(e, 'nameen')} required autoFocus />
                        </div>

                        <div className="p-field">
                            <label htmlFor="name">{strings.NameArabic}</label>
                            <InputText id="namear" value={this.state.product.namear} onChange={(e) => this.onInputChange(e, 'namear')} required autoFocus />
                        </div>

                        <div className="p-field">
                            {showCompany ? <label htmlFor="name">{strings.Vendor}*</label> : ''}
                            {showCompany ? <Dropdown id="vendor" value={this.state.product?.vendor} onChange={(e) => {
                                let product = {...this.state.product}
                                product['vendor'] = e.value;
                                this.setState({ product }, ()=>this.FillBranches());
                            }} options={this.state.vendors?.map((v) => {
                                return {
                                    code: v.uuid,
                                    name: v.name
                                }
                            })} optionLabel="name" placeholder={strings.Select}></Dropdown> : ''}
                        </div>

                        <Can yes={() => <div className="p-field">
                            {this.state.product?.vendor ? <label htmlFor="name">{strings.productCategory}</label> : ''}
                            {this.state.product?.vendor ? <Dropdown id="productCategory" value={this.state.product.productCategory} onChange={(e) => {
                                let product = {...this.state.product}
                                product['productCategory'] = e.value;
                                this.setState({ product });
                            }} options={this.state.productCategories?.map((v) => {
                                return {
                                    code: v.uuid,
                                    name: v.label
                                }
                            })} optionLabel="name" placeholder={strings.Select}></Dropdown> : ''}
                        </div>}
                            perform={'productCategories:route'}
                            path={'/profile/productCategories'}
                            role={role} />

                        <div className="p-grid">
                        <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.Price}*</label>
                                    <InputText id="price" type="number" value={this.state.product.price} onChange={(e) => this.onInputChange(e, 'price')} required autoFocus />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.DiscountPercentage}</label>
                                    <InputText id="discountPercentage" type="number" max="100" min="0" value={this.state.product.discountPercentage} onChange={(e) => this.onInputChange(e, 'discountPercentage')} required autoFocus />

                                </div>
                            </div>

                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.ProductNumber}</label>
                                    <InputText id="productNumber" value={this.state.product.productNumber} onChange={(e) => this.onInputChange(e, 'productNumber')} required autoFocus />
                                </div>
                            </div>

                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.PEPPOL}</label>
                                    <InputText id="peppol" value={this.state.product.peppol} onChange={(e) => this.onInputChange(e, 'peppol')} required autoFocus />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.productType}</label>
                                    <Dropdown id="productType" value={this.state.product.productType} onChange={(e) => this.onInputChange(e, 'productType')} optionLabel="name" options={this.productTypes} required autoFocus />
                                </div>
                            </div>

                            <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.image}</label>
                                    <ReactImageUploading
                                        value={this.state.product.image}
                                        onError={(errors, imageList) => {
                                            if (errors.maxNumber) {
                                                let image = imageList[0]?.data_url;
                                                let _product = { ...this.state.product };
                                                _product['image'] = image;
                                                this.setState({ product: _product });
                                            }

                                        }}
                                        onChange={(imageList) => {
                                            let image = imageList[0]?.data_url;
                                            let _product = { ...this.state.product };
                                            _product['image'] = image;
                                            this.setState({ product: _product });
                                        }}
                                        // maxFileSize={1048576}
                                        // resolutionHeight="150"
                                        // resolutionWidth="150"
                                        // acceptType={["png","jpg","svg","jpeg"]}
                                        dataURLKey="data_url"
                                    >
                                        {({
                                            onImageUpload,
                                            onImageRemove,
                                            isDragging,
                                            errors,
                                            dragProps
                                        }) => {
                                            let style = { width: "100%", height: "100px", borderStyle: "dotted", textAlign: 'center', margin: "auto" };
                                            return (
                                                // write your building UI
                                                <div className="upload__image-wrapper">
                                                    {errors?.maxFileSize && <Message severity="error" text="Selected file size exceed 1 MB" />}
                                                    {errors?.acceptType && <Message severity="error" text="Selected file type is invalid, it should be one of: png, jpg, jpeg, svg" />}
                                                    {!this.state.product.image ? <div
                                                        style={isDragging ? { color: "red", ...style } : style}
                                                        onClick={onImageUpload}
                                                        {...dragProps}
                                                    >
                                                        <span style={{ top: '20%' }}>{strings.ClickOrDropHereToUploadProductImage}</span>
                                                    </div> :
                                                        <div className="image-item" style={{ textAlign: "center" }}>
                                                            <img src={image} onError={(e) => { e.onError = null; e.target.src = "/icon-256x256.png" }} alt="" width="150" height="150" />
                                                            <div className="image-item__btn-wrapper">
                                                                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={onImageUpload} />
                                                                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => onImageRemove(0)} />
                                                            </div>
                                                        </div>}
                                                </div>
                                            )
                                        }}
                                    </ReactImageUploading>
                                </div>
                            </div>



                        </div>



                        <div className="p-field">
                            <label htmlFor="name">{strings.UnitCode}</label>
                            <InputText id="unitCode" value={this.state.product.unitCode} onChange={(e) => this.onInputChange(e, 'unitCode')} required autoFocus />

                        </div>

                        <div className="p-field">
                            <label htmlFor="name">{strings.TaxCategories}</label>
                            <MultiSelect value={this.state.Selected} onChange={(e) => { this.setState({ Selected: e.value })}}  options={this.state.taxCategories}/>
                        </div>


                        <div className="p-field">
                            {this.state.errors?.length > 0 && <Message text={this.state.errors.map((m) => <li>{strings[m]??m}</li>)} severity="error" />}
                            <Button label={strings.Save} loading={this.state.loading} onClick={this.save} loadingoptions={{ position: 'right' }} />
                        </div>

                    </div>
                </div>
            </div>

        );
    }
}

)
