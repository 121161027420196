import ApiService from './ApiService';

export default class BulkImportService extends ApiService {

    constructor() {
        super();
        this.path = '/bulkImport';
    }

    async ImportProducts(data) {
        try {
            let op = await this.Create(this.path+'/bulk/products', data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }
    async ImportCustomers(data) {
        try {
            let op = await this.Create(this.path+'/bulk/customers', data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async ImportInvoices(data) {
        try {
            let op = await this.Create(this.path+'/bulk/invoices', data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }


    async Updateproduct(data) {

        try {
            let op = await this.Update(this.path+"/bulk/products"+data.uuid, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async DeleteProduct(id) {
        try {
            let op = await this.Delete(this.path+"/"+id);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }


    async Getproduct(limit=10,offset=0,vendorID,filters="") {
        try {
            let link = `${this.path}/list?limit=${limit}&offset=${offset}&vendorId=${vendorID}&${filters}`
            let op = await this.Read(link);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async GetCount() {
        try {
            let link = `${this.path}/list?countOnly=true`
            let op = await this.Read(link);
            return op.data.count;
        } catch(e) {
            return e.response.data;
        }
    }
}
