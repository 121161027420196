import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { CustomerForm } from './CustomerForm';
import CustomerService from '../service/CustomerService';
import moment from 'moment';
import strings from '../localization';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import VendorService from '../service/VendorService';
import UserService from '../service/UserService';
import Can, { check } from '../Can';
import rules from '../rbac-rules';
import { SplitButton } from 'primereact/splitbutton';
import { formatDateStringLocal } from './InvoiceDates';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import { FileUpload } from 'primereact/fileupload';
import BulkImportService from '../service/BulkImportService';
import { AutoComplete } from 'primereact/autocomplete';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { TableFilters } from './TableFilters';
import { defaultTableConfiguration } from './TableConfiguration';

export const Customers = ({context}) => {
    const [customerDialog, setCustomerDialog] = useState(false);
    const [comingSoon, setComingSoon] = useState(false);

    const [customers, setCustomers] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [preFilters, setPreFilters] = useState({});
    const [filters, setFilters] = useState({});
    const [typingTimeout2, setTypingTimeout2] = useState(null);

    const [totalRecords, setTotalRecords] = useState(0);

    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [vendors, setVendors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [bulkImport, setBulkImport] = useState(null);
    const [importStatus, setImportStatus] = useState(null);
    const [importError, setImportError] = useState(null);
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [vendorId, setVendorId] = useState(null);
    const [error, setError] = useState(null);

    var {vendor, branch, showCompany: full,signalrConnection,role} = context;

    const toast = useRef(null);
    const dt = useRef(null);

    var userService = new UserService();
    var role = userService.GetRole();

    const onFiltersChange = (e) => {
        let filters = { ...preFilters };
        filters[e.target.id] = e.target.value;
        setPreFilters(filters);
    }

    useEffect(() => {
        if (vendors.length == 0) {
            var vendorService = new VendorService();
            vendorService.GetVendors(500).then((data) => {
                if (!data.data) return [];
                return data.data.map(v => {
                    return { code: v.uuid, name: v.name, ...v }
                });
            }).then(data => {
                setVendors(
                    [{ code: null, name: '-' }, ...data]);
            });
        }
    }, [localStorage.getItem('subscription')]);

    const hideImportFromExcelDialog = () => {
        setImportModalOpen(false);
        setBulkImport(null);
    }

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            onPage({rows,page:0,vendorId:vendor?.uuid, filters})
        }
    }, [filters]);

    useEffect(() => {
        onPage({rows,page:0,vendorId:vendor?.uuid})
    }, [localStorage.getItem('subscription')]);

    useEffect(() => {
        context.signalrConnection?.on('CustomersBulkUploadStatus', (msg)=>{
            if(msg == 4)
            {
                onPage({ rows, page: 0,vendorID:vendor?.uuid})
            }
            setImportError(null)
            setImportStatus(msg)
        })
        context.signalrConnection?.on('CustomersBulkUploadError', (msg)=>{
            setImportStatus(null)
            setImportError(msg)
        })
    }, [context.signalrConnection]);
    function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }
    const onUpload = async (e) => {
        let base64 = await getBase64(e);
        let bulkImportService = new BulkImportService();
        try {
            await bulkImportService.ImportCustomers({
                fileContent: base64,
                deviceId: localStorage.getItem('deviceId'),
                vendorId: vendorId?.code ?? vendorId
            });
            setImportStatus(1);
            hideImportFromExcelDialog();
        } catch(e)

        {
            setError(e.message);
        }
    }
    const openNew = () => {

        // console.log("VendorID : "+JSON.stringify(user.uuid))
        setCustomerDialog(true);
    }

    const hideDialog = () => {
        setCustomerDialog(false);
        setCustomer(null);
    }
    const edit = (obj) => {

         setCustomer( {
            ...obj,
            namear: obj.translations[1]?.name,
            nameen: obj.translations[0]?.name,
            street1: obj.address?.street,
            street2: obj.address?.additionalStreet,
            email:obj.email,
            phone:obj.phone,
            buildNo: obj.address?.buildingNumber,
            additionalNumber: obj.address?.additionalNumber,
            city: obj.address?.city,
            countryCode: obj.address?.countryCode,
            uuid: obj.uuid,
            postalCode: obj.address?.postalCode,
            state: obj.address?.state,
            vendor: obj.vendor,
            neighborhood: obj.address?.neighborhood,
            vat: obj.vatNumber,
            vatGroup: obj.groupVatNumber,
            rows:
                obj.identities?.map((row) => {
                    return {
                        "identityId":row.uuid,
                        "name": row.identityType.uuid,
                        "value": row.value
                    };
                }),
        })
        setCustomerDialog(true);
    }

    const openImportModal = () => {
        setImportModalOpen(true);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Can yes={() => <Button label={strings.New} icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />}
                    perform={'customers:create'}
                    silent={true}
                    role={role} />
                <Can yes={() => <Button disabled={importStatus != null && importStatus != 4} label={strings.ImportFromExcel} icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openImportModal} />}
                    perform={'customers:importBulk'}
                    silent={true}
                    role={role} />
            </React.Fragment>
        )
    }



    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.address?.vendorId}
            </>
        );
    }

    function changeVendorSearchBar (e) {
        if (typingTimeout2) {
            clearTimeout(typingTimeout2);
        }

        setTypingTimeout2(setTimeout(()=> {

            var vendorService = new VendorService();
            vendorService.GetVendors(100, 0, {simple:true, name:e.query}).then((data) => {
                if (!data.data) return [];
                return data.data.map(v => {
                    return { code: v.uuid, name: v.name, ...v }
                });
            }).then(data => {
                setVendors(
                    [{ code: null, name: '-' }, ...data]);
            });
        }, 200))

    }
    const nameenBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                {/* <img src={`${rowData.imageUrl}`} alt={rowData.imageUrl} className="product-image" /> */}
                {rowData.translations[0]?.name}
            </>
        )
    }

    const namearBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name ar</span>
                {/* <img src={`${rowData.imageUrl}`} alt={rowData.imageUrl} className="product-image" /> */}
                {rowData.translations[1]?.name}
            </>
        )
    }
    const vendorBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Vendor</span>
                {/* <img src={`${rowData.imageUrl}`} alt={rowData.imageUrl} className="product-image" /> */}
                {rowData.vendor?.name}
            </>
        )
    }

    const cityBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">City</span>
                {rowData.address?.city}
            </>
        );
    }

    const vatNumberTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Vat Number</span>
                {rowData.vatNumber}
            </>
        );
    }

    const phoneTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Phone</span>
                {rowData.phone}
            </>
        );
    }

    const creationDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Creation Date</span>
                {formatDateStringLocal(rowData.createdAt)}
            </>
        );
    }



    const productBodyTemplate = (rowData) => {
        return (
            <div className="actions" style={{textAlign:"center"}}>
                <Button icon="pi pi-sitemap" className="p-button-rounded p-button-success p-mr-2" />

            </div>
        );
    }

    const actionBodyTemplate = (rowData) => {
        const items = []

        if (check(rules, role, 'customers:update')) {
            items.push({ label: strings.edit, icon: 'pi pi-pencil', command:()=>edit(rowData) });
        }

        if (check(rules, role, 'customers:delete')) {
            items.push({ label: strings.DeleteCustomer, icon: 'pi pi-trash', command:(e)=>{
                setComingSoon(true);
                setCustomer({
                    ...rowData
                })
            } });
        }

        items.push({ label: strings.copyId, icon: 'pi pi-copy', command:(e)=>  navigator.clipboard.writeText(rowData.uuid) });
        if(role=='super_admin') {
            items.push({ label: "Copy SID", icon: 'pi pi-copy', command:(e)=>  navigator.clipboard.writeText(rowData.sid) });
        }

        let first = items.shift();

        return first ? <SplitButton label={first.label} icon={first.icon} onClick={()=>first.command()} model={items}></SplitButton> : ''
    }

    const onPage = ({ first,
        rows,
        page,
        pageCount,
        vendorId,
        filters }) => {
        const customerService = new CustomerService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        customerService.GetCustomers(rows, (page) * rows,vendorId,filters).then(({ data, count }) => {
            setLoading(false);
            setCustomers(data);
            setTotalRecords(count);
        });

        setCustomer(null);

    }

    const header = (
        <>
            <div className="p-col">

                <div className="table-header">

                    <h5 className="p-m-0">{strings.ManageCustomers}</h5>
                </div>

            </div>

            <div className="p-col-12">
            <TableFilters>

                    <div className="p-fluid p-grid">

                        {context.showCompany ? <div className="p-field p-col-4" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.Vendor}</label>
                            <AutoComplete dropdown placeholder={strings.filterSearchPlaceHolder} style={{ width: "100%" }} id="vendorId" value={preFilters.vendorId} suggestions={vendors} field="name" completeMethod={changeVendorSearchBar} onChange={(onFiltersChange)} />
                        </div> : ''}

                        <div className="p-field p-col-3" style={{ margin: 'auto' }}>

                        <label htmlFor="inputtext">{strings.Name}</label>
                            <InputText id="name" value={preFilters.name} onChange={onFiltersChange} />

                        </div>

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                        <label htmlFor="inputtext">{strings.VatNumber}</label>

                                <InputText id="vatNumber" value={preFilters.vatNumber} onChange={onFiltersChange} />

                        </div>
                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                        <label htmlFor="inputtext">{strings.Phone}</label>
                                <InputText id="phone" value={preFilters.phone} onChange={onFiltersChange} />
                        </div>



                        <div className="p-field p-col-1" style={{ margin: 'auto' }}>
                        <hr/>

                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Filter} icon="pi pi-search" className="p-button-success p-mr-1 p-mb-1" onClick={() => {
                                let f = { ...preFilters }
                                setCustomer([])
                                // 2021-09-21T21:50:37.000Z
                                setFilters({
                                    registrationName: f.name,
                                    vatNumber: f.vatNumber?.trim(),
                                    phone: f.phone?.trim(),
                                    vendorId: f.vendorId?.code,
                                });
                            }} />
                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Clear} icon="pi pi-times" className="p-button-warning p-mr-1 p-mb-1" onClick={() => {
                                setPreFilters({});
                            }} />
                        </div>
                    </div>
                    </TableFilters>
            </div></>

    );

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    const deleteProduct = () => {
        const customerService = new CustomerService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        customerService.DeleteCustomer(customer.uuid).then((data) => {
            if(data.errorCode) {
                toast.current.show({ severity: 'error', summary: 'Cannot Delete Vendor', detail: data.message, life: 3000 });
                setLoading(false);
                setComingSoon(false);
                return;
            }
            toast.current.show({ severity: 'success', summary: 'Delete Vendor', detail: `Deleted ${customer.name} successfully`, life: 3000 });
            customerService.GetCustomers(rows, (page) * rows).then(({ data, count }) => {
                setLoading(false);
                setCustomers(data);
                setTotalRecords(count);
            });
        }).finally(()=>{
            setComingSoon(false);
        }).catch(()=>setLoading(false));

        setCustomer(null);

    }

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={()=>{
                setComingSoon(false)
            }} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            {/* <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text"  /> */}
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} ></Toolbar>
                    {importStatus != null && <> {importStatus != 4 && <ProgressSpinner />} <Message severity={importStatus == 4 ? "success" : "info"} content={importStatus == 1 ? strings.CustomersBulkUploadInit : (importStatus == 2 ? strings.CustomersBulkUploadInProgress : strings.CustomersBulkUploadSuccessfull)} /></>}
                    {importError && <Message severity="error" content={importError}></Message>}

                    <DataTable
                        ref={dt}
                        value={customers}
                        totalRecords={totalRecords}
                        first={first}
                        loading={loading}
                        onPage={onPage}
                        rows={rows}
                        header={header}
                        {...defaultTableConfiguration}>
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                        <Column field="nameen" header={strings.NameEnglish}   body={nameenBodyTemplate}></Column>
                        <Column field="namear" header={strings.NameArabic}   body={namearBodyTemplate}></Column>
                        {full && <Column field="vendor" header={strings.vendors}  body={vendorBodyTemplate}></Column>}
                        <Column field="city" header={strings.City}  body={cityBodyTemplate}></Column>
                        <Column field="vat" header={strings.VatNumber}  body={vatNumberTemplate}></Column>
                        <Column field="phone" header={strings.Phone}  body={phoneTemplate}></Column>
                        <Column field="createdAt" header={strings.CreationDate}  body={creationDateBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={comingSoon} style={{ width: '450px' }} header={strings.DeleteCustomer} modal footer={deleteProductDialogFooter} onHide={()=>setComingSoon(false)}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {/* this feature is coming soon! */}
                            {<span>Are you sure you want to delete <b>{customer?.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={customerDialog} style={{ width: '700px' }} header={strings.CustomerDetails} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                         <CustomerForm OnHideDialog={hideDialog} full={full} customer={customer} context={context} vendor={vendor} FillData={()=>onPage({rows,page:0,vendorId:vendor?.uuid})}   />
                    </Dialog>
                    <Dialog visible={importModalOpen} style={{ width: '450px' }} header={strings.ImportFromExcel} modal onHide={hideImportFromExcelDialog}>
                        <div>
                            <div className="card">
                                <div className="p-fluid p-grid">
                                    <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                                        <label htmlFor="inputtext">{strings.productsImportSteps}</label>
                                        <ul>
                                            <li>
                                                1- {strings.customerStep1} <a href="/customers-upload-sample.xlsx" className="btn btn-primary" style={{ padding: '5px', color: 'white', marginBottom: '5px' }} download>{strings.here}</a>
                                            </li>
                                            <li>
                                                2-  {strings.customerStep2}
                                            </li>
                                            <li>
                                                3- {strings.customerStep3}
                                            </li>
                                            <li>
                                                4- {strings.customerStep4}
                                            </li>
                                            <li>
                                                5- {strings.customerStep5}
                                            </li>
                                        </ul>
                                        <hr />
                                    </div>
                                    <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                                        <label htmlFor="inputtext" style={{marginLeft: "5px"}}>{strings.Vendor}: </label>
                                        { !vendor ? <Dropdown style={{ width: '100%' }} id="vendorId" value={vendorId} onChange={(e) => setVendorId(e.value)} options={vendors} optionLabel="name" ></Dropdown> : vendor.name}
                                    </div>
                                    <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                                        <label htmlFor="inputtext">{strings.file}</label>
                                        <FileUpload chooseLabel={strings.selectFile} onSelect={(e) => setFile(e.files[0])} uploadHandler={onUpload} mode="basic" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" maxFileSize={1000000} emptyTemplate={<p className="m-0">{strings.dropFileHere}</p>} />
                                    </div>
                                    <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                                        <label htmlFor="inputtext">{strings.file}</label>
                                        <Button disabled={file == null} label={strings.submit} onClick={() => onUpload(file)}></Button>
                                    </div>

                                </div>

                                {error && <Message severity="error">{error}</Message>}
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
