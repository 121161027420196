import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import TaxCategoriesService from '../service/TaxCategoriesService';
import ProductCategoryService from '../service/ProductCategoryService';
import { useTheme } from 'styled-components';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';
import VendorService from '../service/VendorService';
import { Message } from 'primereact/message';
import strings from '../localization';
import ReactImageUploading from 'react-images-uploading';
import ApiService from '../service/ApiService';




export class ProductCategoryForm extends Component {


    constructor(props) {
        super(props);

        let emptyProductCategory = {
            vendorId: "",
            productCategoryNumber: "",
            sku: "",
            barcode: "",
            price: "",
            discountPercentage: "",
            discountAmount: "",
            quantity: "",
            unitCode: "",
            taxCategories:[],
            nameen:"",
            namear:"",
            productCategoryType:{},
            decriptionen:"",
            peppol:"",
            decriptionar:"",

        }

        const citySelectItems = [];

        this.state = {
            productCategory: { emptyProductCategory },
            taxCategories: [],
            Selected : [],
            errors:[],
            vendor:[],
            loading: false,
            pageLoading: false,
        };
        this.toast = React.createRef();
    }

     citySelectItems = [
        {label: 'New York', value: 'NY'},
        {label: 'Rome', value: 'RM'},
        {label: 'London', value: 'LDN'},
        {label: 'Istanbul', value: 'IST'},
        {label: 'Paris', value: 'PRS'}
    ];


    FillVendors = async () => {
        let vendorService = new VendorService();
        this.setState({pageLoading: true});
        var vendors = await vendorService.GetVendors(500);
        this.setState({ vendors: vendors.data, pageLoading: false })
    }

    componentDidMount() {

        this.FillTaxCategory();
        this.FillVendors();
        // console.log(this.props.user)
        if(this.props.productCategory != null)
        {
            // Fill Data
            this.ReturnData()
        }
        else
        {
            this.setState({
                productCategory: {
                    vendor: {
                        code: this.props.vendor?.uuid,
                        name: this.props.vendor?.name,
                    }
                }
            })
        }
    }


    ReturnData()
    {
        this.setState({
            productCategory: {
                ...this.props.productCategory,
                vendor: {
                    code: this.props.productCategory.vendor?.uuid,
                    name: this.props.productCategory.vendor?.name,
                },
                productCategoryType: this.productCategoryTypes.filter(t => t.code == this.props.productCategory.productCategoryType)[0]
            }
        })
    }

    onEditorValueChange(productCategoryKey, props, value) {
        let updatedRow = [...props.value];
        updatedRow[props.rowIndex][props.field] = value;
        this.setState({ [`${productCategoryKey}`]: updatedRow });
    }

    onSelect(e) {

        // let updatedRow = [...this.state.productCategory];
        // updatedRow.taxCategories = e.value;
        // this.setState({ this.state.pr: citySelectItems });
        let tax = this.loadingoptionsCategory.taxCategories
        this.setState({ tax: e.value })
    }



    onInputChange = (e, name) => {
        let val = (e.target && e.target.value) || '';
        let _productCategory = { ...this.state.productCategory };

        if(name=='discountPercentage')
        {
            if(val > 100)
            {
                val = 100;
            }

            if(val < 0)
            {
                val = 0;
            }
        }
        _productCategory[`${name}`] = val;

        this.setState({ productCategory: _productCategory });
    }


    FillTaxCategory = async () => {
        let taxCategoriesService = new TaxCategoriesService();
        this.setState({pageLoading: true})
        var taxes = await taxCategoriesService.GetTaxCategory(50,0);
        console.log(taxes.data);


        this.setState({Selected:taxes.data?.filter(tx=>tx.taxCategoryCode=='S'&&tx.percent==15).map((tx)=>tx.uuid)})

        let newTax = taxes.data?.map((row) => {
            return {
                "label": row.name,
                "value": row.uuid
            };
        })


        this.setState({taxCategories:newTax, pageLoading: false });

    }



    GetData = () => {


        // console.log(this.state.user);

    }


     delete = (rowData) => {
        // let _ProductCategory = {...this.state.productCategory};
        // _ProductCategory.rows = _ProductCategory.rows.filter(val => val.identityId == rowData.identityId);
        // this.setState({customer:_customer});

        // this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'ProductCategory Deleted', life: 3000 });
    }

    productCategoryTypes=[
        { "code": "service", "name": strings.service },
        { "code": "consumable", "name": strings.consumable }
    ];
    save = async () => {
        this.setState({ loading: true });

        let { productCategory } = this.state;

        let translations = [];

        if((productCategory.nameen != null && productCategory.nameen != '') || productCategory.decriptionen != null)
        {
            translations.push({
                "label": productCategory.nameen,
                "description": productCategory.decriptionen,
                "locale": 1
            })
        }
        if ((productCategory.namear != null && productCategory.namear != '') || productCategory.decriptionar != null) {
            translations.push({
                "label": productCategory.namear,
                "description": productCategory.decriptionar,
                "locale": 2
            })
        }
        
        let vendorId = this.props.context.vendor?.uuid;
        if(!vendorId)
        {
            vendorId = (productCategory.vendor?.code) ?? this.props.vendor;
        }
        let data = {
            "vendorId": vendorId,
            "translations": translations,
            "isActive": true,
        }

        if (this.props.productCategory == null) {

            var productCategoryService = new ProductCategoryService();
            let res = await productCategoryService.CreateProductCategory(data);
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors });
            } else {
                this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'productCategory Created', life: 3000 });
                this.props.OnHideDialog();
                this.props.FillData();
                this.setState({
                    productCategory: {
                        vendorId: "",
                        "image":"",
                        productCategoryNumber: "",
                        sku: "",
                        barcode: "",
                        price: "",
                        discountPercentage: "",
                        discountAmount: "",
                        quantity: "",
                        unitCode: "",
                        taxCategories: [],
                        nameen: "",
                        namear: "",
                        decriptionen: "",
                        decriptionar: "",
                    },
                    errors: [],
                    Selected: []
                });
            }
        }
        else {
            data.uuid = this.props.productCategory.uuid;
            var productCategoryService = new ProductCategoryService();
            let res = await productCategoryService.UpdateProductCategory(data);
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors });
            } else
            {
                this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'productCategory Updated', life: 3000 });
                this.props.OnHideDialog();
                this.props.FillData();
            }
        }
        this.setState({ loading: false });
    }


    render() {
        if(this.state.pageLoading) {
            return <div style={{textAlign:'center'}}><ProgressSpinner/></div>
        }


        let image = this.state.productCategory.image;

        const isRTL = localStorage.getItem('lang')== 'ar' ? 'rtl' : 'ltr';

        const vendor = localStorage.getItem('vendor');
        let vendorName = '';

        if(vendorName != null)
        {
            vendorName = JSON.parse(vendor)?.name;
        }

        return (

            <div className="p-grid crud-demo" style={{direction:isRTL}}>
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={this.toast} />
                        <div className="p-field">
                            <label htmlFor="name">{strings.NameEnglish}</label>
                            <InputText id="nameen" value={this.state.productCategory.nameen} onChange={(e) => this.onInputChange(e, 'nameen')} required autoFocus />
                        </div>

                        <div className="p-field">
                            <label htmlFor="name">{strings.NameArabic}</label>
                            <InputText id="namear" value={this.state.productCategory.namear} onChange={(e) => this.onInputChange(e, 'namear')} required autoFocus />
                        </div>

                        {this.props.context.showCompany?<div className="p-field">

                            <label htmlFor="name">{strings.Vendor}</label>
                            <label htmlFor="name">{vendorName}</label>
                             <Dropdown id="vendor" value={this.state.productCategory?.vendor} onChange={(e) => {
                                let productCategory = {...this.state.productCategory}
                                productCategory['vendor'] = e.value;
                                this.setState({productCategory})
                            }} options={this.state.vendors?.map((v) => {
                                return {
                                    code: v.uuid,
                                    name: v.name
                                }
                            })} optionLabel="name" placeholder={strings.Select}></Dropdown>
                        </div> : ''}

                        <div className="p-field">
                            {this.state.errors?.length > 0 && <Message text={this.state.errors.map((m) => <li>{strings[m]??m}</li>)} severity="error" />}
                            <Button label={strings.Save} loading={this.state.loading} onClick={this.save} loadingoptions={{ position: 'right' }} />
                        </div>

                    </div>
                </div>
            </div>

        );
    }
}
