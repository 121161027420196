import React,{ useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import  {getExpense } from '../../store/actions/expenseAction'
import {useSelector} from 'react-redux'
import Payment from './Payement'
import { ProgressSpinner } from 'primereact/progressspinner'
export const ExpensePayment = ({getExpense}) => {
    const {id} =useParams()
    const {expense} = useSelector(state => state.expenses)
    useEffect(() => {
        Promise.all([getExpense(id)])
    },[])
  return (
    <div>
        {!expense.uuid?<ProgressSpinner/>:
        <Payment expense={expense}/>}
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {getExpense}

export default connect(mapStateToProps, mapDispatchToProps)(ExpensePayment)