import React, { useEffect, useState } from 'react';
import Can, { check } from '../Can'
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import {VendorForm} from '../pages/VendorForm'
import strings from '../localization'
import { useSelector, connect } from 'react-redux';
import {getSuppliers} from '../store/suppliers'
import { SupplierForm } from '../pages/SupplierForm';


const Company = ({getSuppliers, onChange, style, type, vendor, value}) => {
    const {role} = useSelector(state => state.user)
    const {vendors, pVendors} = useSelector(state => state.vendors)
const {suppliers} = useSelector(state => state.suppliers)
    const [selectedVendor, setSelectedVendor] = useState({})
    // const [vendors, setVendors] = useState([])
    const [addVendor, setAddVendor] = useState(false)
    const reload = () => {
        console.log('hello')
    }
    const vendorDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { setAddVendor(false) }} />
        </>
    );
    useEffect(() => {
        selectedVendor && selectedVendor !== vendor && onChange && onChange(selectedVendor)
        // selectedVendor && selectedVendor !== vendor
        // console.log("🚀 ~ file: CompanySelecorAndDialog.jsx ~ line 28 ~ useEffect ~ selectedVendor && selectedVendor !== vendor", selectedVendor && selectedVendor !== vendor)
    },[selectedVendor])

    // useEffect(() => {
    //      setSelectedVendor(vendor)
    // },[vendor])

    // useEffect(() => {
    //     vendors.length === 0 && getVendors()
    //     console.log("🚀 ~ file: CompanySelecorAndDialog.jsx ~ line 27 ~ useEffect ~ vendors", vendors)
    // },[])
    return (
        <>
            <Dialog
                visible={addVendor}
                style={{ width: "75%" }}
                header={strings.VendorsDetails}
                modal
                className="p-fluid"
                footer={vendorDialogFooter}
                onHide={() => {
                    setAddVendor(false);
                }}
            >
                {type !== "purchase" ? (
                    <VendorForm
                        type={type}
                        OnHideDialog={() => {
                            setAddVendor(false);
                        }}
                        full={true}
                        FillData={() => reload()}
                    />
                ) : (
                    <SupplierForm type="purchase" />
                )}
            </Dialog>
            <div style={{ ...style, display: "flex", width: "100%", paddingTop: "10px" }}>
                <label htmlFor="number">{strings.supplier}</label>
                <Dropdown
                    filter={true}
                    style={{ width: "100%" }}
                    id="vendor"
                    filterPlaceholder={strings.filterSearchPlaceHolder}
                    filterBy="name,vatNumber,phone,email"
                    value={vendor ?? selectedVendor ?? value}
                    onChange={(e) => setSelectedVendor(e.target.value)}
                    options={type === "purchase" ? suppliers : vendors}
                    optionLabel="name"
                    placeholder={type === "purchase" ? strings.selectSupplier : strings.SelectCompany}
                ></Dropdown>

                <Can
                    yes={() => (
                        <Button
                            icon="pi pi-plus"
                            tooltipOptions={{
                                position: "top",
                            }}
                            tooltip="Create Vendor"
                            onClick={() => {
                                setAddVendor(true);
                            }}
                        ></Button>
                    )}
                    perform={"vendors:create"}
                    silent={true}
                    role={role}
                />
            </div>
        </>
    );
}

export default connect(null, {getSuppliers})(Company)
