import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom'
import { getOrders,deleteOrder } from '../../store/actions/orderAction';
import strings from '../../localization';
import DeleteDialog from '../../components/DeleteDialog';
import UserService from '../../service/UserService';
import Can, { check } from '../../Can';
import rules from '../../rbac-rules';
import { SplitButton } from 'primereact/splitbutton';
import { defaultTableConfiguration } from '../TableConfiguration';
import { TableFilters } from '../TableFilters';
import { AutoComplete } from 'primereact/autocomplete';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';
import VendorService from '../../service/VendorService';
import { Toolbar } from 'primereact/toolbar';
// import Toolbar from '@fullcalendar/core/Toolbar';

const Orders = ({ role, getOrders,deleteOrder }) => {
    const { orders: { data, count } } = useSelector(state => state.orders)
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [first, setFirst] = useState(0);
    const [vendors, setVendors] = useState([]);
    const [preFilters, setPreFilters] = useState({});
    const [filters, setFilters] = useState({});
    const [deleteDialog, setDeleteDialog] = useState({ show: false, data: {} })
    const [typingTimeout2, setTypingTimeout2] = useState(null);

    useEffect(() => {
        Promise.all([getOrders()]).then(() => setLoading(false))

    }, [])

    useEffect(() => {
        if (vendors == null) {
            var vendorService = new VendorService();
            vendorService
                .GetVendors(500)
                .then((data) => {
                    if (!data.data) {
                        return [{ code: null, name: "-" }];
                    }
                    return data.data.map((v) => {
                        return { code: v.uuid, name: v.name, ...v };
                    });
                })
                .then((data) => {
                    setVendors([{ code: null, name: "-" }, ...data]);
                });
        }
    }, []);

    const dateBody = (data) => {
        return (
            <span>{new Date(data.date).toLocaleString()}</span>
        )
    }

    const actionBodyTemplate = (rowData) => {
        const items = []

        var userService = new UserService();
        var role = userService.GetRole();

        if (check(rules, role, 'products:update')) {
            items.push({ label: strings.details, icon: 'pi pi-pencil', command:()=>history.push(`/profile/purchaseOrder/${rowData.uuid}`) });
        }

        if (check(rules, role, 'products:delete')) {
            items.push({ label: strings.deleteProduct, icon: 'pi pi-trash', command:(e)=>setDeleteDialog({ show: true, data: rowData }) });
        }

        items.push({ label: strings.copyId, icon: 'pi pi-copy', command:(e)=>  navigator.clipboard.writeText(rowData.uuid) });
        if(role=='super_admin') {
            items.push({ label: "Copy SID", icon: 'pi pi-copy', command:(e)=>  navigator.clipboard.writeText(rowData.sid) });
        }

        let first = items.shift();

        return first ? <SplitButton label={first.label} icon={first.icon} onClick={()=>first.command()} model={items}></SplitButton> : ''

        // return (
        //     <div className="actions">
        //         <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => edit(rowData)} />
        //         <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteProduct(rowData)} />
        //         {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteProduct(rowData)} /> */}
        //     </div>
        // );
    }

    const onFiltersChange = (e) => {
        let filters = { ...preFilters };
        filters[e.target.id] = e.target.value;
        setPreFilters(filters);
    }

    function changeVendorSearchBar (e) {
        if (typingTimeout2) {
            clearTimeout(typingTimeout2);
        }

        setTypingTimeout2(setTimeout(()=> {

            var vendorService = new VendorService();
            vendorService.GetVendors(100, 0, {simple:true, name:e.query}).then((data) => {
                if (!data.data) return [];
                return data.data.map(v => {
                    return { code: v.uuid, name: v.name, ...v }
                });
            }).then(data => {
                setVendors(
                    [{ code: null, name: '-' }, ...data]);
            });
        }, 200))

    }

    const header = (
        <div className="p-grid">
            <div className="p-col">
                <div className="table-header">
                    <h5 className="p-m-0">{strings.purchasedOrders}</h5>
                </div>
            </div>

            <div className="p-col-12">
                <TableFilters>
                    <div className="p-fluid p-grid">
                        <div className="p-field p-col-4" style={{ margin: "auto" }}>
                            <label htmlFor="calendar">{strings.Company}</label>
                            <AutoComplete dropdown placeholder={strings.filterSearchPlaceHolder} style={{ width: "100%" }} id="vendor" value={preFilters.vendor} suggestions={vendors} field="name" completeMethod={changeVendorSearchBar} onChange={onFiltersChange} />
                        </div>

                        <div className="p-field p-col-3" style={{ margin: "auto" }}>
                            <label htmlFor="inputtext">{strings.ProductNumber}</label>
                            <InputText id="productNumber" value={preFilters.productNumber} onChange={onFiltersChange} />
                        </div>

                        <div className="p-field p-col-5" style={{ margin: "auto" }}>
                            <label htmlFor="inputtext">{strings.Name}</label>
                            <InputText id="name" value={preFilters.name} onChange={onFiltersChange} />
                        </div>

                        <div className="p-field p-col-3" style={{ margin: "auto" }}>
                            <label htmlFor="calendar">{strings.priceFrom}</label>
                            <InputText type="number" id="priceHigherThan" value={preFilters.priceHigherThan} onChange={onFiltersChange} />
                        </div>

                        <div className="p-field p-col-3" style={{ margin: "auto" }}>
                            <label htmlFor="calendar">{strings.priceTo}</label>
                            <InputText type="number" id="priceLowerThan" value={preFilters.priceLowerThan} onChange={onFiltersChange} />
                        </div>

                        <div className="p-field p-col" style={{ margin: "auto" }}>
                            <hr />

                            <Button
                                tooltipOptions={{
                                    position: "top",
                                }}
                                tooltip={strings.Filter}
                                icon="pi pi-search"
                                className="p-button-success p-mr-1 p-mb-1"
                                onClick={() => {
                                    let f = { ...preFilters };
                                    // 2021-09-21T21:50:37.000Z
                                    setFilters({
                                        dateFrom: f.dateFrom ? moment(f.dateFrom).utc().format() : null,
                                        dateTo: f.dateTo ? moment(f.dateTo).utc().format() : null,
                                        productNumber: f.productNumber,
                                        peppol: f.peppol,
                                        priceHigherThan: f.priceHigherThan,
                                        productCategoryId: f.productCategory?.code,
                                        priceLowerThan: f.priceLowerThan,
                                        vendorId: f.vendor?.code,
                                        name: f.name,
                                    });
                                }}
                            />
                            <Button
                                tooltipOptions={{
                                    position: "top",
                                }}
                                tooltip={strings.Clear}
                                icon="pi pi-times"
                                className="p-button-warning p-mr-1 p-mb-1"
                                onClick={() => {
                                    setPreFilters({});
                                }}
                            />
                        </div>
                    </div>
                </TableFilters>
            </div>
        </div>
    );

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Can yes={() => <Button label={strings.addOrder} icon="pi pi-plus" onClick={() => history.push("/profile/purchaseOrder/new")} style={{ margin: "2rem 0" }} />}
                    perform={'purchaseOrders:create'}
                    silent={true}
                    role={role} />
            </React.Fragment>
        )
    }

    const columns = [
        { field: "purchaseOrderNumber", header: strings.orderNumber, body: null },
        { field: "vendor.name", header: strings.Vendor, body: null },
        { field: "supplier.name", header: strings.supplier, body: null },
        {
            field: "totalAmount",
            header: strings.amount,
            body: (rowData) => {
                return (
                    <>
                        <span className="p-column-title">{strings.amount}</span>
                        <span>
                            {" "}
                            {rowData.totalAmount} {rowData.currency}
                        </span>
                    </>
                );
            },
        },
        {
            field: "confirmed",
            header: strings.status,
            body: (rowData) => {
                return (
                    <>
                        <span className="p-column-title">{strings.status}</span>
                        <span className={`product-badge status-${rowData.confirmed == "1" ? "3" : "1"}`}>{rowData.confirmed ? strings.Confirmed : strings.draft}</span>
                    </>
                );
            },
        },
        { field: "date", header: strings.date, body: dateBody },
        { field: strings.Action, header: "Action", body: actionBodyTemplate },
    ];
    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}></Toolbar>
                    <DeleteDialog onDelete={deleteOrder} show={deleteDialog.show} data={deleteDialog.data} onHide={() => setDeleteDialog({ show: false, data: {} })} />
                    <DataTable {...defaultTableConfiguration} value={data} first={first} loading={loading} header={header} onPage={(e) => console.log(e)}>
                        {columns.map((column, i) => (
                            <Column key={`column${i}`} field={column.field} header={column.header} body={column.body} />
                        ))}
                    </DataTable>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    role: state.user.role,
})

const mapDispatchToProps = { getOrders,deleteOrder }

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
