
import thunk from 'redux-thunk';
import {combineReducers ,configureStore } from '@reduxjs/toolkit';
import {  applyMiddleware } from 'redux';
import user from './user'
import payments from './payments';
import accounts from './accounts'
import invoices from './invoices'
import vendors from './vendors'
import products from './products';
import customers from './customers'
import expenses from './expense';
import issuer from './issuer';
import refunds from './refund'
import orders from './order'
import quotations from './quotation'
import supplierPayments from './supplierPayment'
import suppliers from './suppliers'
import supplierProducts from './supplierProducts';
import supplierRefunds from './SupplierRefund'
import subscriptions from './subscription'
const reducers = combineReducers({user: user, payments: payments, bankAccounts: accounts, invoices: invoices, vendors: vendors,products: products, customers:customers, expenses:expenses,issuer:issuer, refunds:refunds , orders:orders, quotations:quotations,supplierPayments:supplierPayments, suppliers:suppliers,supplierProducts:supplierProducts,supplierRefunds:supplierRefunds,subscriptions:subscriptions});
const store = configureStore({reducer: reducers}, applyMiddleware(thunk))
export default store
// export const store = configureStore({
//   reducer: {},
// })