import ApiService from './ApiService';
import querystring from 'query-string';
let api = process.env.REACT_APP_API_URL

export default class InvoiceReportingService extends ApiService {

    constructor() {
        super();
        this.path = '/invoiceReport';
    }

    async  GenerateOrGetReport(data) {
        try {
            let op = await this.Create(this.path, {...data, force: false});
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async  GeneratePnlReport(data) {
        try {
            let op = await this.Create(this.path + "/pnlReport", {...data, force: false});
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }
    async  GenerateZatcaVatReport(data) {
        try {
            let op = await this.Create(this.path + "/zatcaReport", {...data, force: false});
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async GetPnlReports(filters) {
        try {
            let op = await this.Read(this.path + `/pnlReport/list?${querystring.stringify(filters)}`);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }

    async GetZatcaVatReports(filters) {
        try {
            let op = await this.Read(this.path + `/zatcaReport/list?${querystring.stringify(filters)}`);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }


    async GetReportHistory(limit=10,offset=0, filters='') {
        try {
            let link = `${this.path}/list?limit=${limit}&offset=${offset}&${filters}`
            let op = await this.Read(link);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async DeleteReport(uuid) {
        try {
            let op = await this.Delete(this.path + "/user/" + uuid);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }
    async DeletePnlReport(uuid) {
        try {
            let op = await this.Delete(this.path + "/pnlReport/" + uuid);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }

    async GetDetailedReport(body) {
        return await this.Create(this.path + "/detailed/excel", body);
    }
}
