import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import VendorService from '../service/VendorService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import strings from '../localization';
import { InputTextarea } from 'primereact/inputtextarea';
import Files from 'react-files'
import Axios from 'axios';
import ReactImageUploading from 'react-images-uploading';
import SupportTicketService from '../service/SupportTicketService';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import QRCode from 'qrcode.react';

import
{
WhatsappShareButton,
WhatsappIcon,
EmailShareButton,
EmailIcon
} from "react-share";
import ReferralService from '../service/ReferralService';

export class ReferralForm extends Component {


    constructor(props) {
        super(props);

        this.state = {
            ticket: {},
            loading: false,
            pageLoading: false,
            qrCode:false,
            copyToClipboardColor:'black',
            referralLink:'https://e-inv.armsit.com/referral',
            errors: [],
        };
        this.toast = React.createRef();
        this.referralService = new ReferralService();



    }
    componentDidMount()
    {
        this.init();
    }

    init()
    {

        this.referralService.GetReferralCode().then(code=>{
            this.setState({referralCode: code});
        })
    }

    ReturnData() {
        this.setState({ user: this.props.user })
    }

    onEditorValueChange(productKey, props, value) {
        let updatedRow = [...props.value];
        console.log(value)
        updatedRow[props.rowIndex][props.field] = value;
        this.setState({ [`${productKey}`]: updatedRow });
    }



    onInputChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let ticket = { ...this.state.ticket };
        ticket[`${e.target.id}`] = val;

        this.setState({ ticket: ticket });
    }

    save = async () => {
        this.setState({ loading: true });

        let { ticket } = this.state;

        var service = new SupportTicketService();

        let email = JSON.parse(localStorage.getItem('user'))?.email;

        await service.CreateSupportTicket({
            email,
            ...ticket
        });

        this.setState({ loading: false });

        this.props.close();
    }

    render() {

        if (this.state.pageLoading) {
            return <div style={{ textAlign: 'center' }}><ProgressSpinner /></div>
        }

        let image = this.state.ticket.image;

        const isRTL = localStorage.getItem('lang') == 'ar' ? 'rtl' : 'ltr';

        let email = JSON.parse(localStorage.getItem('user'))?.email;

        return (

            <div className="p-grid crud-demo" style={{ direction: isRTL }}>
                <div className="p-col-12">

                    <div className="card" style={{ width: '100%' }}>
                        <Toast ref={this.toast} />

                        <h3>{strings.inviteMoreEarnMore}</h3>
                        <hr />

                        <div className="p-grid">

                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                    <div className="p-d-flex p-ai-center muted-text">
                                        <h6>{strings.promotionCode}</h6>
                                    </div>
                                    <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap" >
                                        <div className="p-d-flex  p-flex-column" >
                                            <span className="p-mb-1  fs-xlarge" ><b>{this.state.referralCode}</b></span>
                                        </div>
                                        <div className="p-d-flex p-flex-column">
                                            <CopyToClipboard text={this.state.referralCode}
                                                onCopy={() => this.setState({ copied: true })}>
                                                <Button onClick={() => {
                                                    this.setState({ copyToClipboardColor: 'green' });
                                                }} icon="pi pi-copy" style={{ backgroundColor: this.state.copyToClipboardColor }} />
                                            </CopyToClipboard>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="p-col-4 p-md-4 p-lg-4">
                                <div className="p-d-flex p-flex-column">
                                    <WhatsappShareButton
                                        beforeOnClick ={async ()=>{
                                            const code = await this.referralService.GetReferralLink();
                                            this.setState({ referralLink: code });
                                        }}
                                        url={this.state.referralLink}
                                        title={"Onboard your company to ZATCA phase 2 now!"}
                                        separator=":: "
                                        className="Demo__some-network__share-button"
                                        style={{ backgroundColor: '#25d366' }}
                                    >
                                        <WhatsappIcon size={32} />
                                    </WhatsappShareButton>
                                </div>
                            </div>
                            <div className="p-col-4 p-md-4 p-lg-4">
                                <div className="p-d-flex p-flex-column">
                                    <EmailShareButton
                                        url={this.state.referralLink}
                                        beforeOnClick={()=>{
                                            this.referralService.GetReferralLink().then(code=>{
                                                this.setState({referralLink: code});
                                            })
                                        }}
                                        subject={`Join ARMS IT and get ${this.props.context?.platformSettings ? this.props.context?.platformSettings['ReferralAmount']:'-'} SAR free`}
                                        body="Onboard your company to ZATCA phase 2 now! \n"
                                        className="Demo__some-network__share-button"
                                        style={{ backgroundColor: 'rgb(127, 127, 127)' }}
                                    >
                                        <EmailIcon size={32} />
                                    </EmailShareButton>
                                </div>
                            </div>
                            <div className="p-col-4 p-md-4 p-lg-4">
                                <div className="p-d-flex p-flex-column">
                                    <Button icon="pi pi-qrcode" onClick={() => {
                                        this.referralService.GetReferralLink().then(code => {
                                            this.setState({ referralLink: code, qrCode: true });
                                        })
                                    }} aria-label="QR Code" title="QR Code" style={{ backgroundColor: 'black', width:'100%' }}> &nbsp;&nbsp;&nbsp;&nbsp;QR Code</Button>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-12 p-lg-12">
                                    {this.state.qrCode ? <div style={{textAlign:"center"}}>
                                        <QRCode value={this.state.referralLink} />
                                        <br/>
                                        <br/>
                                        <Message content={"Ask friends to scan QR code, they will be redirect to unique registeration page"}></Message>
                                    </div> : ''}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
