export const id = ()=>{
    return (Math.random() * 100000).toFixed(0)
}

export const productAmount = (data)=>{
    return ((Number(data.price) * (data.discount ? 1 - Number(data.discount) / 100 : 1) * (1 + Number(data.vat ?? 0) / 100)) * Number(data.quantity)).toFixed(2)

}

export const totalAmountCal = data =>{
    return data.reduce((p, c)=> p+Number(c.total), 0)
}

export const getItem = (item)=>{
    try {
        return JSON.parse(localStorage.getItem(item))
    }
    catch (e) {
        return localStorage.getItem(item);
    }
}

export const setItem = (item, value)=>{
    if (typeof value === 'string' || value instanceof String)
    {
        return localStorage.setItem(item, value)
    }

    return localStorage.setItem(item, JSON.stringify(value))
}

export const getCountry = () => {
    var country = getItem('country');
    if(country == null)
    {
        var hostname = document.location.hostname;
        if (hostname == "einv.armsit.com") {
            country = 'SA';
        } else if (hostname == "einv-bh.armsit.com") {
            country = 'BH'
        } else country = 'SA';

        setItem('country',country);
    }
    return country;
}

export const getCurrency = () => {
    var currency = getItem('currency');
    if(currency == null)
    {
        var hostname = document.location.hostname;
        if (hostname == "einv.armsit.com") {
            currency = 'SAR';
        } else if (hostname == "einv-bh.armsit.com") {
            currency = 'BHD'
        } else currency = 'SAR';

        setItem('currency',currency);
    }
    return currency;


    // var hostname = document.location.hostname;

    // var ksaHosts = ['einv.armsit.com','einv-test.armsit.com','localhost'];
    // var bhHosts = ['einv-bh.armsit.com','einv-bh-test.armsit.com'];

    // if(ksaHosts.includes(hostname))
    // {
    //     return "SAR";
    // }

    // if(bhHosts.includes(hostname))
    // {
    //     return "BHD"
    // }
    // return null;
}
