import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import PlatformService from '../service/PlatformService';
import { defaultTableConfiguration } from './TableConfiguration';
import { Checkbox } from 'primereact/checkbox';


export const PlatformSettings = () => {

    let emptyPlatformSetting = {
        name:'',
        value:''
    };

    const [platformSettingDialog, setPlatformSettingDialog] = useState(false);
    const [deletePlatformSettingDialog, setDeletePlatformSettingDialog] = useState(false);
    const [deletePlatformSettingsDialog, setDeletePlatformSettingsDialog] = useState(false);
    const [platformSetting, setPlatformSetting] = useState(emptyPlatformSetting);
    const [selectedPlatformSettings, setSelectedPlatformSettings] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [platformSettings, setPlatformSettings] = useState([]);
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        // const platformService = new PlatformService();
        // platformService.getPlatformSettings().then(data => setPlatformSettings(data));
        onPage({rows,page:0})
    }, []);


    const openNew = () => {
        setPlatformSetting(emptyPlatformSetting);
        setSubmitted(false);
        setPlatformSettingDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setPlatformSettingDialog(false);
    }

    const hideDeletePlatformSettingDialog = () => {
        setDeletePlatformSettingDialog(false);
    }

    const hideDeletePlatformSettingsDialog = () => {
        setDeletePlatformSettingsDialog(false);
    }

    const savePlatformSetting = async () => {
        setSubmitted(true);

        var platformService = new PlatformService();

        await platformService.UpdateOrCreatePlatformSetting(platformSetting);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Platform Setting Created/Updated', life: 3000 });
        onPage({ rows, page })
        // setPlatformSettings(_platformSettings);
        setPlatformSettingDialog(false);
        setPlatformSetting(emptyPlatformSetting);
    }

    const editPlatformSetting = (platformSetting) => {
        setPlatformSetting({ ...platformSetting });
        setPlatformSettingDialog(true);
    }

    const confirmDeletePlatformSetting = (platformSetting) => {
        setPlatformSetting(platformSetting);
        setDeletePlatformSettingDialog(true);
    }

    const deletePlatformSetting = async () => {
        await (new PlatformService().DeletePlatformSetting(platformSetting))
        setDeletePlatformSettingDialog(false);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'PlatformSetting Deleted', life: 3000 });
        onPage({ rows, page })
    }


    const deleteSelectedPlatformSettings = () => {
        let _platformSettings = platformSettings.filter(val => !selectedPlatformSettings.includes(val));
        setPlatformSettings(_platformSettings);
        setDeletePlatformSettingsDialog(false);
        setSelectedPlatformSettings(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'PlatformSettings Deleted', life: 3000 });
    }

    const onFieldChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _platformSetting = { ...platformSetting };
        _platformSetting[e.target.id] = val;
        setPlatformSetting(_platformSetting);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const nameLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData?.name}
            </>
        );
    }

    const valueLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Value</span>
                {rowData?.value}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editPlatformSetting(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeletePlatformSetting(rowData)} />
            </div>
        );
    }

    const onPage = ({ first,
        rows,
        page }) => {
        const platformService = new PlatformService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        platformService.GetPlatformSettings().then((data) => {
            setLoading(false);
            setPlatformSettings(Object.keys(data).map(k=>{
                return data[k];
            }));

        });

    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Platform Settings</h5>
            <span className="p-input-icon-left">
                {/* <i className="pi pi-search" /> */}
                {/* <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." /> */}
            </span>
        </div>
    );

    const platformSettingDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={savePlatformSetting} />
        </>
    );
    const deletePlatformSettingDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePlatformSettingDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deletePlatformSetting} />
        </>
    );
    const deletePlatformSettingsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePlatformSettingsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedPlatformSettings} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}></Toolbar>

                    <DataTable
                        {...defaultTableConfiguration}
                        value={platformSettings}
                        selection={selectedPlatformSettings}
                        first={first}
                        loading={loading}
                        onPage={onPage}
                        rows={rows}
                        header={header}>
                        <Column field="name" header="Name"  style={{width: "250px"}} body={nameLabelTemplate}></Column>
                        <Column field="value" header="Value" body={valueLabelTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={platformSettingDialog} style={{ width: '450px' }} header="Payment Type Details" modal className="p-fluid" footer={platformSettingDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={platformSetting.name} onChange={(e) => onFieldChange(e)} required autoFocus className={classNames({ 'p-invalid': submitted && !platformSetting.name })} />
                            {submitted && !platformSetting.name && <small className="p-invalid">Name is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="value">Value</label>
                            <InputText id="value" value={platformSetting.value} onChange={(e) => onFieldChange(e)} required autoFocus className={classNames({ 'p-invalid': submitted && !platformSetting.value })} />
                            {submitted && !platformSetting.value && <small className="p-invalid">Value is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deletePlatformSettingDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePlatformSettingDialogFooter} onHide={hideDeletePlatformSettingDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {platformSetting && <span>Are you sure you want to delete <b>{platformSetting.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deletePlatformSettingsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePlatformSettingsDialogFooter} onHide={hideDeletePlatformSettingsDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {platformSetting && <span>Are you sure you want to delete the selected payment type?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
