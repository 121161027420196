import ApiService from "./ApiService";
import qs from 'query-string';

class SalesPayment extends ApiService {
    constructor(){
        super();
        this.path = '/payment'
    }
    async getPayment(id){
        try {
            let link = `${this.path}/${id}`;
            let op = await this.Read(link)
            return op.data
        } catch (e) {
            return e.response.data;
        }
    }

    async getPayments(data){
        try {
          
            let link = `${this.path}/list?${qs.stringify(data)}`
            let op = await this.Read(link,data)
            return op.data
        } catch (e) {
            return e.response.data;
        }
    }
    async updatePayment(data) {
        try {
            let link = `${this.path}/${data.uuid}`
            let op = await this.Update(link, data)
            return op.data
        } catch (e) {
            return e.response.data;
        }
    }
    async createPayment(data) {
        try {
            let link = `${this.path}`
            let op = await this.Create(link, data);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }
    async deletePayment(id) {
        try {
            let link = `${this.path}/${id}`;
            let op = await this.Delete(link);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }
    async getPaymentTypes(payload) {
        try {
            let op = await this.Read('/paymentType/list',payload)
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }
}

export default new SalesPayment()