import ApiService from './ApiService';
import querystring from 'query-string';
let api = process.env.REACT_APP_API_URL

export default class SubscriptionService extends ApiService {

    constructor() {
        super();
        this.path = '/subscription';
    }

    async  CreateSubscription(data) {
        try {
            let op = await this.Create(this.path, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }
    async  UpdateSubscription(data) {
        try {
            let op = await this.Update(this.path, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async  UpdateSubscriptionName(data) {
        try {
            let op = await this.Update(this.path+'/name', data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async  RenewSubscription(data) {
        try {
            let op = await this.Update(this.path + "/renew", data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }
    async  CreateSubscriptionUser(data) {
        try {
            let op = await this.Create(this.path + "/user", data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }
    async  UpdateSubscriptionUser(data) {
        try {
            let op = await this.Update(this.path + "/user", data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async ActivateSubscription(id) {
        try {
            let op = await this.Create(`${this.path}/activate/${id}`);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }
    async CancelSubscription(id) {
        try {
            let op = await this.Create(`${this.path}/cancel/${id}`);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async GetSubscriptions(limit=10,offset=0, filters='') {
        try {
            let link = `${this.path}/list?limit=${limit}&offset=${offset}&${filters}`
            let op = await this.Read(link);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }
    async GetPlans(limit=10,offset=0, filters='') {
        try {
            let link = `${this.path}Plan/list?limit=${limit}&offset=${offset}&homepageEnabled=true&${filters}`
            let op = await this.Read(link);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }
    async GetSubscriptionUsers(limit = 10, offset = 0, sid=null, filters=null) {
        try {
            let link = `${this.path}/user/list?limit=${limit}&offset=${offset}&${sid != null ? 'sid=' + sid : ''}&${querystring.stringify(filters)}`
            let op = await this.Read(link);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }

    async DeleteSubscriptionUser(uuid) {
        try {

            let op = await this.Delete(this.path+"/user/"+uuid);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async GetCount() {
        try {
            let link = `${this.path}/list?countOnly=true`
            let op = await this.Read(link);
            return op.data.count;
        } catch(e) {
            return e.response.data;
        }
    }

    GetSessionSubscriptions = ()=> {
        let user = JSON.parse(localStorage.getItem('user'));
        return user?.subscriptions;
    }

    GetActiveSubscription() {
        let subs = this.GetSessionSubscriptions();
        let current = localStorage.getItem('subscription');
        return subs?.filter(sub=>sub.uuid==current)[0];
    }

    GetActiveBranch() {
        let company = this.GetActiveCompany();
        let current = localStorage.getItem('branch');
        return company?.branches?.filter(b=>b.uuid==current)[0];
    }

    GetBranches() {
        let company = this.GetActiveCompany();
        return company?.branches;
    }

    HasCompany()
    {
        let sub = this.GetActiveSubscription();
        return sub?.companies?.length > 0;
    }

    GetActiveCompany() {
        let sub = this.GetActiveSubscription();
        let current = localStorage.getItem('company');
        return sub?.companies?.filter(company=>company.uuid==current)[0];
    }

    async GetSubscription(id) {
        try {
            let link = `${this.path}/${id}`;
            let op = await this.Read(link);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }
async charge(payload) {
    try {
        let result = await this.Read(`/charge/${payload}`)
        return result
    } catch (e) {
        return e.response.data;
    }
}

}
