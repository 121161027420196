import ApiService from './ApiService';
let api = process.env.REACT_APP_API_URL

export default class ChargeService extends ApiService {

    constructor() {
        super();
        this.path = '/charge';
    }

    async GetCharges(limit=100,offset=0) {
        try {
            let link = `${this.path}/list?limit=${limit}&offset=${offset}`
            let op = await this.Read(link);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async GetCount() {
        try {
            let link = `${this.path}/list?countOnly=true`
            let op = await this.Read(link);
            return op.data.count;
        } catch(e) {
            return e.response.data;
        }
    }
}
