import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom'
import { useSelector, connect } from 'react-redux'
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import strings from '../../localization'
import DeleteDialog from '../../components/DeleteDialog';
import { deletePayment, getPayments } from '../../store/actions/SupplierPayment'
import RefundDialog from '../refunds/RefundDialog';
import { createRefund, updateRefund } from '../../store/actions/supplierRefundAction'

const Payment = ({ deletePayment, getPayments, createRefund, updateRefund }) => {
    const { payments: { data: payments, count } } = useSelector(state => state.supplierPayments)

    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(true);
    const history = useHistory()
    const [selectedProducts7, setSelectedProducts7] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState({ show: false, data: {} })
    const [forRefund, setForRefund] = useState({ show: false, item: {} })
    useEffect(() => {
        Promise.all([getPayments()]).then(() => setLoading(false))
    }, [])

    const forRefundHandler = (rawData) => {
        setForRefund({ show: true, item: rawData })
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>

                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning p-button-text" title={strings.delete} onClick={() => setDeleteDialog({ show: true, data: rowData })} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-secondary p-button-text" title={strings.edit} onClick={() => history.push(`/profile/purchase/payments/edit/${rowData.uuid}`)} />
                {/* <Button icon="pi pi-credit-card" className="p-button-rounded p-button-success p-button-text" title='payment' onClick={()=> history.push(`/profile/purchase/payments/expense/${rowData.uuid}`)} /> */}
                <Button icon="pi pi-undo" className="p-button-rounded p-button-success p-button-text" title={strings.refund} onClick={() => forRefundHandler(rowData)} />
            </React.Fragment>
        );
    }

    return (
        <>
            <DeleteDialog onDelete={deletePayment} show={deleteDialog.show} data={deleteDialog.data} onHide={() => setDeleteDialog({ show: false, data: {} })} />
            <RefundDialog type='purchase' visible={forRefund.show} item={forRefund.item} onHide={() => setForRefund({ ...forRefund, show: false })} createRefund={createRefund} updateRefund={updateRefund} />
            <Button label={strings.makePayment} icon="pi pi-plus" onClick={() => history.push('/profile/purchase/payments/new')} />
            <h3 style={{ margin: '2rem auto' }}>{strings.payments}</h3>
            <DataTable value={payments} className="datatable-responsive" selection={selectedProducts7} paginator={true} loading={loading} totalRecords={count} onSelectionChange={e => setSelectedProducts7(e.value)} dataKey="uuid" responsiveLayout="scroll" globalFilterFields={['name', 'country.name', 'representative.name', 'status']} rows={10} first={1} onPage={e => console.log(e)} autoLayout={true}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3em' }} ></Column>
                <Column field="date" header={strings.date} body={e => <span>{new Date(e.paymentDate).toLocaleString()}</span>} ></Column>
                <Column field="vendor.name" header={strings.Vendor}  ></Column>
                <Column field="supplier.name" header={strings.supplier}  ></Column>
                <Column field="amount" header={strings.amount}></Column>
                <Column field="paymentType.name" header={strings.paymentType}> </Column>
                <Column header={strings.Action} body={actionBodyTemplate} />
            </DataTable>
        </>
    )
}

export default connect(null, { deletePayment, getPayments, createRefund, updateRefund })(Payment)