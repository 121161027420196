import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import Refund from './index'
import { getRefunds, deleteRefund, updateRefund } from '../../store/actions/refundAction'

export const InvoiceRefund = ({ getRefunds,deleteRefund, updateRefund }) => {
    const { refunds: { data, count } } = useSelector(state => state.refunds)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        Promise.all([getRefunds()]).finally(() => setLoading(false))
    }, [])
    return (
        <>
            <Refund type="Invoice" data={data} loading={loading} onDelete={deleteRefund} onUpdate={updateRefund} count={count}/>
        </>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { getRefunds ,deleteRefund, updateRefund}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceRefund)