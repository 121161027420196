import Axios from 'axios';
import { config } from 'react-transition-group';
import ApiService from './ApiService';
import jwt_decode from "jwt-decode";

let api = process.env.REACT_APP_API_URL

export default class SessionService {

    constructor() {
        //RBAC
        //Role based access control
        this.headers = {};
        let token = localStorage.getItem("token");
        let deviceId = localStorage.getItem("deviceId");
        this.headers = {};
        if (token != null) {
            this.headers['Authorization'] = `Bearer ${token}`;
        }

        let locale = localStorage.getItem('lang') ?? 'Ar';;

        locale = locale.charAt(0).toUpperCase() + locale.slice(1)

        this.headers['Accept-Language'] = locale;

        this.headers['X-DeviceId'] = deviceId;
    }

    async RefreshJwtToken(body) {
        try {
            let {data} = await Axios.put(`${api}/auth/jwt/token`, {
                sid: this.GetSubscriptionId(),
                ...body
            }, {
                headers: { ...this.headers }
            });



            return data;
        } catch (e) {
            return e.response.data;
        }
    }

    GetToken() {
        return localStorage.getItem('token');
    }

    GetTokenExpDate() {
        if (this.GetToken()) {

            var date = new Date(0);
            date.setUTCSeconds(jwt_decode(this.GetToken()).exp);
            return date;
        }
        return new Date();
    }

    GetTokenIssueDate() {
        if (this.GetToken()) {

            var date = new Date(0);
            date.setUTCSeconds(jwt_decode(this.GetToken()).iat);
            return date;
        }
        return new Date();
    }


    GetRefreshToken() {
        return localStorage.getItem('refreshToken');
    }
    GetDeviceId() {
        return localStorage.getItem('deviceId');
    }

    GetSubscriptionId() {
        return localStorage.getItem('subscription');
    }
    Logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        localStorage.removeItem('subscription');
        localStorage.removeItem('inactiveSub');
        localStorage.removeItem('company');
        localStorage.removeItem('branch');
        // window.location.reload();
        window.location.href = '/login'
    }
    PopulateSession(data, subId) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('refreshToken', data.refreshToken);
        localStorage.setItem('user', JSON.stringify(data.user));
        if(subId != null)
        {
            localStorage.setItem('subscription', subId);
        }
        if(data.branchId)
        {
            localStorage.setItem('branch', data.branchId);
        }
        if(data.vendorId)
        {
            localStorage.setItem('company', data.vendorId);
        }
    }
}
