import React, {  } from 'react';
import { Route, useHistory } from 'react-router-dom'

import { ActivateUser } from './pages/ActivateUser';
import Profile from './Profile';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import Login from './pages/Login';
import ProtectedRoute from './ProtectedRoute';
import { ErrorBoundary } from './ErrorBoundary';
import AuthlessRoute from './AuthlessRoute';
import { ResetPassword } from './pages/ResetPassword';

const App = () => {
    let {history} = useHistory();
    // setIsOpen(true)
    // Axios.interceptors.response.use((response) => {
    //     return response
    //   }, (error) => {
    //     console.log(error);
    //     // if (error.response.status == 401 || error.response.status == 403) {
    //     //     let service = new AuthService();
    //     //     let data = {
    //     //         token: service.GetToken(),
    //     //         refreshToken: service.GetRefreshToken(),
    //     //         deviceId: service.GetDeviceId(),
    //     //         sid: service.GetSubscriptionId()
    //     //     }
    //     //     if(data.token==null||data.refreshToken==null) return Promise.reject(error);
    //     //     service.RefreshJwtToken(data).then((data) => {
    //     //         service.PopulateSession({
    //     //             token: data.token,
    //     //             refreshToken: data.refreshToken,
    //     //             user: data.user,
    //     //             sid: service.GetSubscriptionId()
    //     //         }, data.sid);

    //     //         return data.token;

    //     //         console.log('Refreshed token succesfully');
    //     //     }).then((token)=>{
    //     //         alert('here')
    //     //         error.config.headers['Authorization'] = 'Bearer ' + token;
    //     //         // error.config.baseURL = undefined;
    //     //         return Axios.request(error.config);

    //     //     }).catch(e=>console.error('Refreshing token failed'));
    //     // }
    //     return Promise.reject(error)
    //   })


    return (
        <ErrorBoundary>
            <div>
                <AuthlessRoute path="/" exact component={Login} />
                <Route path="/activate-user" exact component={ActivateUser} />
                <Route path="/reset-password" exact component={ResetPassword} />
                <AuthlessRoute path="/login" component={Login} />
                <ProtectedRoute path="/profile" component={Profile} />
            </div>
        </ErrorBoundary>

    );

}

export default App;
