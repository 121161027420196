import { ProgressSpinner } from 'primereact/progressspinner'
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getOrder } from '../../store/actions/orderAction'
import Order from './Order'

export const EditOrder = ({ getOrder }) => {
    const { id } = useParams()
    const { order } = useSelector(state => state.orders)
    useEffect(() => {
        Promise.all([getOrder(id)])
    }, [])
    return (
        <div>
            {order.uuid ? <Order orderForEdit={order} mode='edit'/> :
                <ProgressSpinner />}
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { getOrder }

export default connect(mapStateToProps, mapDispatchToProps)(EditOrder)