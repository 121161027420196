import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputTextarea } from 'primereact/inputtextarea';
import UserService from './service/UserService';
import { Dialog } from 'primereact/dialog';
import strings from './localization';
import { Editor } from 'primereact/editor';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';

const NotifyConnectedUsers = (props) => {
    let [message, setMessage] = useState(null);
    let [subject, setSubject] = useState(null);
    let [content, setContent] = useState(null);
    let [loading, setLoading] = useState(null);
    let [error, setError] = useState(null);
    let [success, setSuccess] = useState(null);
    let [confirmSendDialog, setConfirmSendDialog] = useState(false);
    const deleteInvoiceDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setConfirmSendDialog(false)} />
            <Button loading={loading} label="Yes" icon="pi pi-check" className="p-button-text" onClick={async () => {
                setLoading(true);
                await new UserService().SendEmailToAllUsers({
                    content: content,
                    subject: subject
                }).then(()=>{ 
                    setSuccess(true);
                    setSubject(null);
                    setContent(null);
                } ).catch(()=>setError(true)).finally(()=>setLoading(false));
                setConfirmSendDialog(false)
            }} />
        </>
    );
    return <Card>
        <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-12">
                <h3>Send email notification to all users</h3>
            </div>
            <div className="p-field p-col-12 p-md-12">
                {success ? <Message severity={"success"} content={"Email was sent succesfully!"} /> : ''}
                {error ? <Message severity={"warn"} content={"Couldn't send email :("} /> : ''}
            </div>
            <div className="p-field p-col-12 p-md-12">
                <InputText placeholder='Subject' id="subject" value={subject} onChange={(e) => setSubject(e.target.value)} required autoFocus />
            </div>
            <div className="p-field p-col-12 p-md-12">
               <Editor placeholder='Email Content' style={{height:'320px'}} value={content} onTextChange={(e) => setContent(e.htmlValue)} />
            </div>
            <div className="p-field p-col-12 p-md-12">
                <span style={{ "width": "100%" }}>
                    <Button disabled={subject == null || content == null} label="Send" icon="pi pi-check" onClick={() => setConfirmSendDialog(true)} style={{ marginRight: '.25em' }} />
                </span>
            </div>
            
        </div>

        <Dialog visible={confirmSendDialog} style={{ width: '450px' }} header={strings.Confirm} modal footer={deleteInvoiceDialogFooter} onHide={() => setConfirmSendDialog(false)}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                {<span>Are you sure?</span>}
            </div>
        </Dialog>

    </Card>
}

export default NotifyConnectedUsers;
