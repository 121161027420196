import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import VendorService from '../service/VendorService';
import FeedbackService from '../service/FeedbackService';
import { Dropdown } from 'primereact/dropdown';
import JSONPretty from 'react-json-pretty';
import moment from 'moment';

import { formatDateStringLocal } from './InvoiceDates';

export const Feedbacks = () => {

    let emptyFeedback = {
        "percent": 15,
        "feedbackCode": "",
        "taxScheme": "VAT",
        "translations": [
            {
                name: '',
                locale: '1'
            },
            {
                name: '',
                locale: '2'
            }
        ]
    };

    const [feedbackDialog, setFeedbackDialog] = useState(false);
    const [deleteFeedbackDialog, setDeleteFeedbackDialog] = useState(false);
    const [deleteFeedbacksDialog, setDeleteFeedbacksDialog] = useState(false);
    const [feedback, setFeedback] = useState(emptyFeedback);
    const [selectedFeedbacks, setSelectedFeedbacks] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [feedbacks, setFeedbacks] = useState([]);
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        // const feedbackService = new FeedbackService();
        // feedbackService.getFeedbacks().then(data => setFeedbacks(data));
        onPage({ rows, page: 0 })
    }, []);

    const formatCurrency = (value) => {
        if (!value) return ""
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setFeedback(emptyFeedback);
        setSubmitted(false);
        setFeedbackDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setFeedbackDialog(false);
    }

    const hideDeleteFeedbackDialog = () => {
        setDeleteFeedbackDialog(false);
    }

    const hideDeleteFeedbacksDialog = () => {
        setDeleteFeedbacksDialog(false);
    }

    const taxCategoyCodes = [
        { code: 'S', name: '[S] Standard rate' },
        { code: 'Z', name: '[Z] Zero rated goods' },
        { code: 'E', name: '[E] Exempt from Tax' },
        { code: 'O', name: '[O] Services outside scope of tax' },
    ]


    const editFeedback = (feedback) => {
        setFeedback({ ...feedback });
        setFeedbackDialog(true);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="View feedback JSON" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        // return (
        //     <React.Fragment>
        //         <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" />
        //         <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
        //     </React.Fragment>
        // )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.code}
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.email}
            </>
        );
    }

    const arabicLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Label [AR]</span>
                {rowData.translations[1]?.name}
            </>
        );
    }

    const englishLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.translations[0]?.name}
            </>
        );
    }

    const percentLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Percent</span>
                {rowData.percent}
            </>
        );
    }

    const feedbackCodeTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tax Category Code</span>
                {rowData.feedbackCode}
            </>
        );
    }
    const customerEmailTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Customer Email</span>
                {rowData.email}
            </>
        );
    }

    const descriptionTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Customer Email</span>
                {rowData.description}
            </>
        );
    }

    const cityTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Customer Email</span>
                {rowData.city}
            </>
        );
    }
    const typeTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Customer Email</span>
                {rowData.type}
            </>
        );
    }
    const industryTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Customer Email</span>
                {rowData.industry}
            </>
        );
    }
    const organizationSizeTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Customer Email</span>
                {rowData.organizationSize}
            </>
        );
    }

    const customerNameTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    }
    const customerPhoneTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Phone</span>
                {rowData.phone}
            </>
        );
    }
    const statusTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                {rowData.status}
            </>
        );
    }
    const createdAtTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Created At</span>
                {formatDateStringLocal(rowData.createdAt)}
            </>
        );
    }
    const amountTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Amount</span>
                {rowData.amount} {rowData.currency}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button label="View message" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={() => editFeedback(rowData)} />
            </div>
        );
    }

    const onPage = ({ first,
        rows,
        page,
        pageCount }) => {
        const feedbackService = new FeedbackService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        feedbackService.GetFeedbacks(rows, (page) * rows).then(({ data, count }) => {
            setLoading(false);
            setFeedbacks(data);
            setTotalRecords(count);
        });

    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">View Feedbacks</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const feedbackDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );
    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {/* <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> */}

                    <DataTable ref={dt}
                        value={feedbacks}
                        selection={selectedFeedbacks}
                        dataKey="id"
                        totalRecords={totalRecords}
                        lazy
                        first={first}
                        autoLayout={true}
                        loading={loading}
                        onPage={onPage}
                        paginator
                        rows={rows}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} feedbacks"
                        globalFilter={globalFilter}
                        emptyMessage="No feedbacks found."
                        header={header}>
                        <Column field="customer" header="Name" style={{ width: "250px" }}body={customerNameTemplate}></Column>
                        <Column field="email" header="Email" style={{ width: "250px" }}body={customerEmailTemplate}></Column>
                        <Column field="phone" header="Phone" style={{ width: "250px" }}body={customerPhoneTemplate}></Column>
                        <Column field="city" header="City" style={{ width: "250px" }}body={cityTemplate}></Column>
                        {/* <Column field="type" header="Type" style={{ width: "250px" }}body={typeTemplate}></Column> */}
                        <Column field="industry" header="Industry" style={{ width: "250px" }}body={industryTemplate}></Column>
                        <Column field="organizationSize" header="Org. Size" style={{ width: "250px" }}body={organizationSizeTemplate}></Column>
                        <Column field="createdAt" header="Created At"body={createdAtTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={feedbackDialog} style={{ width: '450px' }} header="Message" modal className="p-fluid" footer={feedbackDialogFooter} onHide={hideDialog}>

                        <div>
                            <h5>{feedback.description}</h5>

                        </div>

                    </Dialog>

                </div>
            </div>
        </div>
    );
}
