import { createSlice } from "@reduxjs/toolkit";


const refund = createSlice({
    name: 'refund',
    initialState : {refund:{}, refunds: {data:[], count: 0}, error: ''},
    reducers: {
        addDifferentRefund(state, action){
            return {...state,...action.payload}
        },
        addError(state, action) {
            return {...state,error: action.payload}
        }
    },

})

export default refund.reducer
export const {addDifferentRefund, addError} = refund.actions
