import {addData, addError} from '../supplierPayment'
import SupplierPayment from '../../service/SupplierPaymentService'

export const getPayment = payload => async (dispatch) => {
    try {
        let result = await SupplierPayment.getPayment(payload)
        dispatch(addData({payment:result}))
    } catch (error) {
        dispatch(addError(error))
    }
}

export const getPayments = (payload) => async (dispatch) => {
    try {
        let result = await SupplierPayment.getPayments({...payload, isRefund: false})
        dispatch(addData({payments: result}))
    } catch (error) {
        dispatch(addError(error))
    }
}

export const updatePayment = (payload) => async (dispatch) => {
    try {
        let result = await SupplierPayment.updatePayment(payload)
        dispatch(addData({payment: result}))
    } catch (error) {
        dispatch(addError(error))
    }
}

export const createPayment = (payload) => async (dispatch) => {
    try {
        let result = await SupplierPayment.createPayment(payload)
        dispatch(addData({payment: result}))
    } catch (error) {
        dispatch(addError(error))
    }
}

export const deletePayment = (payload) => async (dispatch, state) => {
    try {
        let result = await SupplierPayment.deletePayment(payload)
        let newPayments = state().supplierPayments.payments.data.filter(x=> x.uuid !== result.uuid)
        dispatch(addData({payments: {data:newPayments, count: state().payments.payments.count -1}}))
    } catch (error) {
        dispatch(addError(error))
    }
}

export const getPaymentTypes = (payload) => async (dispatch) => {
    try {
        let result = await SupplierPayment.getPaymentTypes(payload)
        dispatch(addData({paymentTypes : result}))
    } catch (error) {
        dispatch(addError(error))
    }
}



