import React, { useEffect, useState, useRef } from 'react'
import { connect, useSelector } from 'react-redux'
import AddProductTable from '../../components/AddProductTable'
import Company from '../../components/CompanySelecorAndDialog'
import { getInitialOrder } from './newOrder'
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { SplitButton } from 'primereact/splitbutton';
import { createOrder, updateOrder } from '../../store/actions/orderAction'
import { getProducts } from '../../store/actions/supplierProductAction'
import SupplierView from '../expenses/SupplierView'
import strings from '../../localization'
import { Button } from 'primereact/button'
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import Can from '../../Can'
import { Dropdown } from 'primereact/dropdown'
import { getSuppliers } from '../../store/suppliers'
import SupplierService from '../../service/SupplierService'
const Order = ({ createOrder, updateOrder, getProducts, orderForEdit = {}, mode }) => {
    const { activeBranch, role, vendor } = useSelector(state => state.user)
    const { products: { data: pProducts }, msg } = useSelector(state => state.supplierProducts)
    const initialOrder = { date: '', vendorId: '', supplierId: '', lines: [], notes: '', purchaseOrderNumber: '', supplier: {} }
    const [order, setOrder] = useState(initialOrder)

    const [productLoading, setProductLoading] = useState(false)
    const [suppliers, setSuppliers] = useState([])
    const history = useHistory()
    const toast = useRef(null);
    // useEffect(() => {
    //     setOrder(x => getInitialOrder(activeBranch, vendor))

    // }, [])
    const items = [
        {
            label: 'Save and send email',
            icon: 'pi pi-send',
            command: () => createOrder(order)
        },
        {
            label: 'Delete',
            icon: 'pi pi-times',
            command: (e) => {
                console.log(e)
            }
        },
        {
            label: 'React Website',
            icon: 'pi pi-external-link',
            command: (e) => {
                console.log(e)
            }
        },
        {
            label: 'Upload',
            icon: 'pi pi-upload',
            command: (e) => {
                console.log(e)
            }
        }
    ]

    useEffect(() => {

        (order.supplierId || orderForEdit.vendor?.uuid) && Promise.all([getProducts({ limit: 100, offset: 0, supplierId: orderForEdit.supplier?.uuid ?? order.supplierId }), setProductLoading(true)]).then(() => setProductLoading(false))
    }, [order.supplierId, orderForEdit.supplier?.uuid])

    useEffect(() => {
        orderForEdit.uuid && setOrder(orderForEdit)
    }, [])

    useEffect(() => {
        if (suppliers.length === 0) {
            new SupplierService().GetSuppliers().then(({ data }) => {
                setSuppliers(data);
            });
        }
    }, [])


    const saveHandler = () => {
        let obj = { uuid: orderForEdit?.uuid, vendorId: order.vendor?.uuid, supplierId: order.supplier?.uuid, lines: order.lines.map(line => { return { ...line, supplierProductId: line.product?.uuid, taxCategoryId: line.taxCategory?.uuid } }), notes: order.notes, date: order.date, purchaseOrderNumber: order.purchaseOrderNumber }

        Promise.all([mode === 'edit' ? updateOrder(obj) : createOrder(order)]).then(() => {

            !msg && history.push(`/profile/orders?status=${mode? 200: 201}`)

        }).catch((e) => {

            console.log(e.toString());
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: e.toString().split(':')[1] })
        })
    }
    // useEffect(() => {

    //     console.log("🚀 ~ file: Order.jsx ~ line 64 ~ Order ~ order", order)
    // }, [order])
    return (
        <>
            <Toast ref={toast} />
            <h4 style={{ margin: "2rem 0" }}>{strings.addOrder}</h4>
            <div className="p-grid card">
                <div className="p-col-6">
                    <label htmlFor="number">{strings.orderNumber}</label>
                    <br></br>
                    <InputText style={{ width: "100%" }} value={order.purchaseOrderNumber} placeholder={strings.autoGenerated} disabled />
                </div>


                  <div className="p-col-6">
                    <label htmlFor="number">{strings.supplier}</label>
                    <br></br>
                    <Dropdown
                        filter={true}
                        style={{ width: "100%" }}
                        id="vendor"
                        filterPlaceholder={strings.filterSearchPlaceHolder}
                        filterBy="name,vatNumber,phone,email"
                        value={order.supplier}
                        onChange={(e) => setOrder({ ...order, supplier: e.target.value, supplierId: e.target.value?.code })}
                        options={suppliers}
                        optionLabel="name"
                        placeholder={strings.selectSupplier}
                    ></Dropdown>
                    <Can
                        yes={() => (
                            <Button
                                icon="pi pi-plus"
                                tooltipOptions={{
                                    position: "top",
                                }}
                                tooltip="Create Supplier"
                                onClick={() => {
                                    // (true);
                                }}
                            ></Button>
                        )}
                        perform={"vendors:create"}
                        silent={true}
                        role={role}
                    />{" "}
                </div>

                {/* <div className="p-col-6">{mode === "edit" ? <SupplierView supplier={order.supplier} /> : <Company type="purchase" onChange={(e) => setOrder({ ...order, supplierId: e.uuid, vendorId: e.vendor?.uuid })} />}</div> */}

                <div className="p-col-6">
                    <label htmlFor="icon">{strings.date}</label>
                    <br></br>
                    <Calendar
                        id="icon"
                        value={new Date(order.date)}
                        onChange={(e) =>
                            setOrder((x) => {
                                return { ...x, date: e.value };
                            })
                        }
                        showIcon
                    />
                </div>

                <div className="p-col-12">
                    <h5>{strings.comments}</h5>
                    <InputTextarea
                        value={order.notes}
                        onChange={(e) =>
                            setOrder((x) => {
                                return { ...x, notes: e.target.value };
                            })
                        }
                        rows={5}
                        style={{ width: "85%" }}
                    />
                </div>
                <div className="p-grid" style={{ padding: "2rem 10%" }}></div>



                <div className="p-col-12 p-grid p-justify-center m-2rem">
                    <Button label={strings.Save} icon="pi pi-save" model={items} onClick={saveHandler} />
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { createOrder, updateOrder, getProducts }

export default connect(mapStateToProps, mapDispatchToProps)(Order)
