const rules = {
    visitor: {
      static: []
    },
    super_admin: {
      static: [
        "dashboard:route",
        "reports:read",
        "products:importBulk",
        "customers:importBulk",
        "invoices:importBulk",
        "subscriptions:read",
        "subscriptions:route",
        "feedbacks:read",
        "feedbacks:route",
        "platformSettings:route",
        "subscriptions:update",
        "subscriptions:create",
        "subscriptions:delete",
        "subscriptions:activate",
        "subscriptionPlans:read",
        "subscriptionPlans:update",
        "subscriptionPlans:delete",
        "subscriptionPlans:create",
        "subscriptionPlans:route",
        "taxCategories:read",
        "taxCategories:route",
        "taxCategories:update",
        "taxCategories:delete",
        "taxCategories:create",
        "referrals:read",
        "referrals:route",
        "referrals:update",
        "referrals:delete",
        "referrals:create",
        "identityTypes:route",
        "identityTypes:read",
        "identityTypes:update",
        "identityTypes:delete",
        "identityTypes:create",
        "expenseTypes:route",
        "expenseTypes:read",
        "expenseTypes:update",
        "expenseTypes:delete",
        "expenseTypes:create",
        "paymentTypes:route",
        "paymentTypes:read",
        "paymentTypes:update",
        "paymentTypes:delete",
        "paymentTypes:create",
        "users:route",
        "users:update",
        "users:delete",
        "users:create",
        "users:read",
        "vendors:route",
        "vendors:read",
        "vendors:update",
        "vendors:delete",
        "vendors:create",
        "suppliers:route",
        "suppliers:read",
        "suppliers:update",
        "suppliers:delete",
        "suppliers:create",
        "invoices:route",
        "invoices:read",
        "invoices:update",
        "invoices:delete",
        "invoices:create",
        "invoices:draft:update",
        "invoices:draft:create",
        "invoices:draft:delete",
        "invoices:sendEmail",
        "invoices:debit:draft",
        "invoices:credit:draft",
        "customers:route",
        "customers:read",
        "customers:update",
        "customers:delete",
        "customers:create",
        "products:route",
        "products:read",
        "all:products:read",
        "products:update",
        "products:delete",
        "products:create",
        "charges:route",
        "charges:read",
        "activityLogs:route",
        "activityLogs:read",
        "currencyRates:route",
        "currencyRates:read",
        "currencyRates:update",
        "currencyRates:create",
        "createSupportTicket:route",
        "payment:route",
        "payment:create",
        "payment:update",
        "payment:delete",
        "payment:read",
        "quotation:route",
        "quotation:create",
        "quotation:update",
        "quotation:delete",
        "quotation:read",
        "quotations:route",
        "quotations:create",
        "quotations:update",
        "quotations:delete",
        "quotations:read",
        "quotations:route",
        "quotations:create",
        "quotations:update",
        "quotations:delete",
        "quotations:read",
        "quotations:draft:update",
        "quotations:draft:create",
        "quotations:draft:delete",
        "purchaseOrders:route",
        "purchaseOrders:create",
        "purchaseOrders:update",
        "purchaseOrders:delete",
        "purchaseOrders:read",
        "purchaseOrders:draft:update",
        "purchaseOrders:draft:create",
        "purchaseOrders:draft:delete",
        "checkout:route",
        "expenses:route",
        "expenses:update",
        "expenses:create",
        "expenses:delete",
        "expenses:read",
        "vendors:purchase:route",
        "vendors:purchase:read",
        "vendors:purchase:update",
        "vendors:purchase:delete",
        "vendors:purchase:create",
        "orders:route",
        "orders:update",
        "orders:create",
        "orders:delete",
        "orders:read",
        "refund:route",
        "refund:update",
        "refund:create",
        "refund:delete",
        "refund:read",
        "refund:purchase:route",
        "refund:purchase:update",
        "refund:purchase:create",
        "refund:purchase:delete",
        "refund:purchase:read",
        "payment:purchase:route",
        "payment:purchase:create",
        "payment:purchase:update",
        "payment:purchase:delete",
        "payment:purchase:read",
        "refund:purchase:route",
        "refund:purchase:update",
        "refund:purchase:create",
        "refund:purchase:delete",
        "refund:purchase:read",
      ],
      dynamic: {
        "posts:edit": ({userId, postOwnerId}) => {
          if (!userId || !postOwnerId) return false;
          return userId === postOwnerId;
        }
      }
    },
    subscription_admin: {
      static: [
        "dashboard:route",
        "subscriptionUsers:route",
        "subscriptionUsers:update",
        "subscriptionUsers:delete",
        "subscriptionUsers:create",
        "subscriptionUsers:read",
        "invoices:metrics:read",
        "vendors:route",
        "vendors:read",
        "vendors:update",
        "vendors:delete",
        "vendors:create",
        "invoices:route",
        "invoices:read",
        "invoices:update",
        "invoices:delete",
        "invoices:create",
        "invoices:draft:update",
        "invoices:draft:create",
        "invoices:draft:delete",
        "invoices:sendEmail",
        "invoices:debit:draft",
        "invoices:credit:draft",
        "customers:route",
        "customers:read",
        "customers:update",
        "customers:delete",
        "customers:create",
        "products:route",
        "products:read",
        "products:update",
        "products:importBulk",
        "invoices:importBulk",
        "customers:importBulk",
        "products:delete",
        "products:create",
        "currencyRates:route",
        "currencyRates:read",
        "currencyRates:update",
        "currencyRates:create",
        "activityLogs:route",
        "activityLogs:read",
        "createSupportTicket:route",
        "payment:route",
        "payment:create",
        "payment:update",
        "payment:delete",
        "payment:read",
        "quotations:route",
        "quotations:create",
        "quotations:update",
        "quotations:delete",
        "quotations:read",
        "quotations:route",
        "quotations:create",
        "quotations:update",
        "quotations:delete",
        "quotations:read",
        "quotations:draft:update",
        "quotations:draft:create",
        "quotations:draft:delete",
        "mySubscription:route",
        "checkout:route",
        "expenses:route",
        "expenses:update",
        "expenses:create",
        "expenses:delete",
        "expenses:read",
        "vendors:purchase:route",
        "vendors:purchase:read",
        "vendors:purchase:update",
        "vendors:purchase:delete",
        "vendors:purchase:create",
        "orders:route",
        "orders:update",
        "orders:create",
        "orders:delete",
        "orders:read",
        "refund:route",
        "refund:update",
        "refund:create",
        "refund:delete",
        "refund:read",
        "refund:purchase:route",
        "refund:purchase:update",
        "refund:purchase:create",
        "refund:purchase:delete",
        "refund:purchase:read",
        "payment:purchase:route",
        "suppliers:route",
        "suppliers:read",
        "suppliers:update",
        "suppliers:delete",
        "suppliers:create",
        "payment:purchase:create",
        "payment:purchase:update",
        "payment:purchase:delete",
        "payment:purchase:read",
        "refund:purchase:route",
        "refund:purchase:update",
        "refund:purchase:create",
        "refund:purchase:delete",
        "refund:purchase:read",
        'mySubscription:route',
        "purchaseOrders:route",
        "purchaseOrders:create",
        "purchaseOrders:update",
        "purchaseOrders:delete",
        "purchaseOrders:read",
        "purchaseOrders:draft:update",
        "purchaseOrders:draft:create",
        "purchaseOrders:draft:delete"
      ]
    },
    subscription_user: {
      static: [
        "dashboard:route",
        "invoices:metrics:read",
        "invoices:route",
        "invoices:read",
        "invoices:update",
        "invoices:delete",
        "invoices:create",
        "invoices:draft:update",
        "invoices:draft:create",
        "invoices:draft:delete",
        "invoices:sendEmail",
        "invoices:debit:draft",
        "invoices:credit:draft",
        "vendors:route",
        "vendors:read",
        "suppliers:route",
        "suppliers:read",
        "suppliers:update",
        "suppliers:delete",
        "suppliers:create",
        "customers:route",
        "customers:read",
        "customers:update",
        "customers:delete",
        "customers:create",
        "products:route",
        "products:read",
        "products:update",
        "products:delete",
        "products:importBulk",
        "customers:importBulk",
        "invoices:importBulk",
        "quotations:route",
        "quotations:create",
        "quotations:update",
        "quotations:delete",
        "quotations:read",
        "quotations:draft:update",
        "quotations:draft:create",
        "quotations:draft:delete",

        "purchaseOrders:route",
        "purchaseOrders:create",
        "purchaseOrders:update",
        "purchaseOrders:delete",
        "purchaseOrders:read",
        "purchaseOrders:draft:update",
        "purchaseOrders:draft:create",
        "purchaseOrders:draft:delete",


        "payment:route",
        "payment:create",
        "payment:update",
        "payment:delete",
        "payment:read",
        "payment:purchase:route",
        "payment:purchase:create",
        "payment:purchase:update",
        "payment:purchase:delete",
        "payment:purchase:read",

        "currencyRates:route",
        "currencyRates:read",
        "currencyRates:update",
        "currencyRates:create",
        "createSupportTicket:route",
        "products:create",
      ]
    },
    subscription_admin_ro: {
        static: [
            "dashboard:route",
            "invoices:metrics:read",
            "invoices:route",
            "invoices:read",
            "customers:route",
            "customers:read",
            "products:route",
            "products:read",
            "vendors:route",
            "vendors:read",
            "invoices:sendEmail",
            "currencyRates:route",
            "currencyRates:read",
            "createSupportTicket:route",

        ]
    },
    subscription_data_entry: {
        static: [
            "dashboard:route",
            "invoices:metrics:read",
            "invoices:route",
            "invoices:read",
            "invoices:draft:update",
            "invoices:draft:create",
            "invoices:draft:delete",
            "invoices:delete",
            "invoices:debit:draft",
            "invoices:credit:draft",
            "quotations:route",
            "quotations:update",
            "quotations:delete",
            "quotations:read",
            "quotations:draft:update",
            "quotations:draft:create",
            "quotations:draft:delete",
            "purchaseOrders:route",
            "purchaseOrders:update",
            "purchaseOrders:delete",
            "purchaseOrders:read",
            "purchaseOrders:draft:update",
            "purchaseOrders:draft:create",
            "purchaseOrders:draft:delete",
            "customers:route",
            "invoices:sendEmail",
            "customers:read",
            "customers:update",
            "customers:delete",
            "customers:create",
            "products:route",
            "products:read",
            "products:update",
            "products:importBulk",
        "invoices:importBulk",

        "customers:importBulk",
        "suppliers:route",
        "suppliers:read",
        "suppliers:update",
        "suppliers:delete",
        "suppliers:create",

            "products:delete",
            "products:create",
            "vendors:route",
            "vendors:read",
            "currencyRates:route",
            "currencyRates:read",
            "currencyRates:update",
            "currencyRates:create",
            "createSupportTicket:route",
        ]
    },
    subscription_pos_user: {
        static: [
            "dashboard:route",
            "invoices:metrics:read",
            "invoices:route",
            "invoices:read",
            "invoices:draft:update",
            "invoices:draft:create",
            "invoices:draft:delete",
            "invoices:delete",
            "invoices:debit:draft",
            "invoices:credit:draft",
            "customers:route",
            "invoices:sendEmail",
            "customers:read",
            "customers:update",
            "customers:delete",
            "customers:create",
            "products:route",
            "products:read",
            "products:update",
            "products:importBulk",
            "customers:importBulk",
            "invoices:importBulk",
            "products:delete",
            "products:create",
            "vendors:route",
            "vendors:read",
            "currencyRates:route",
            "currencyRates:read",
            "currencyRates:update",
            "currencyRates:create",
            "createSupportTicket:route",
            "productCategories:route",
            "productCategories:read",
            "productCategories:update",
            "productCategories:delete",
            "productCategories:create"
        ]
    },
    read_only_admin: {
      static: [
        "dashboard:route",
        "subscriptions:read",
        "subscriptions:route",
        "subscriptionPlans:route",
        "subscriptionPlans:read",
        "taxCategories:read",
        "taxCategories:route",
        "identityTypes:route",
        "identityTypes:read",
        "expenseTypes:route",
        "expenseTypes:read",
        "users:route",
        "users:read",
        "suppliers:route",
        "suppliers:read",
        "suppliers:update",
        "suppliers:delete",
        "suppliers:create",
        "vendors:route",
        "vendors:read",
        "invoices:route",
        "invoices:read",
        "customers:route",
        "customers:read",
        "currencyRates:route",
        "currencyRates:read",
        "products:route",
        "products:read",
        "createSupportTicket:route",
    ]
    },
  };

  export default rules
