import React from 'react'
import strings from '../../localization'
const SupplierView = ({ supplier }) => {
    return (
        <div style={{textAlign: 'center'}}>
            <b>{strings.supplier}:</b>
            <p>{supplier.name}</p>
            <span>{strings.VatNumber}: <b>{supplier?.vatNumber ?? '-'}:</b></span>
        </div>
    )
}

export default SupplierView