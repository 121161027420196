import SalesPayment from '../../service/SalesPayment'
import { addDifferentPayment, addError } from '../payments'

export const addSalesPayment = payload => async (dispatch) => {
    try {
        let result = await SalesPayment.getPayment(payload)
        console.log("🚀 ~ file: SalesPayment.js ~ line 7 ~ addSalesPayment ~ result", result)
        dispatch(addDifferentPayment({payment:result}))
    } catch (error) {
        dispatch(addError(error))
    }
}

export const getPayments = (payload) => async (dispatch) => {
    try {
        let result = await SalesPayment.getPayments({...payload, isRefund: false})
        dispatch(addDifferentPayment({payments: result}))
    } catch (error) {
        dispatch(addError(error))
    }
}

export const updatePayment = (payload) => async (dispatch) => {
    try {
        let result = await SalesPayment.updatePayment(payload.uuid, payload)
        dispatch(addDifferentPayment({payment: result}))
    } catch (error) {
        dispatch(addError(error))
    }
}

export const addPayment = (payload) => async (dispatch) => {
    try {
        let result = await SalesPayment.createPayment(payload)
        dispatch(addDifferentPayment({payment: result}))
    } catch (error) {
        dispatch(addError(error))
    }
}

export const deletePayment = (payload) => async (dispatch, state) => {
    try {
        let result = await SalesPayment.deletePayment(payload)
        let newPayments = state().payments.payments.data.filter(x=> x.uuid !== result.uuid)
        dispatch(addDifferentPayment({payments: {data:newPayments, count: state().payments.payments.count -1}}))
    } catch (error) {
        dispatch(addError(error))
    }
}

export const getPaymentTypes = (payload) => async (dispatch) => {
    try {
        let result = await SalesPayment.getPaymentTypes(payload)
        dispatch(addDifferentPayment({paymentTypes : result}))
    } catch (error) {
        dispatch(addError(error))
    }
}
