import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import { InputTextarea } from 'primereact/inputtextarea';
import ReactImageUploading from 'react-images-uploading';
import { MegaMenu } from 'primereact/megamenu';
import AuthService from './service/AuthService';
import SubscriptionPlanService from './service/SubscriptionPlanService';
import { Dropdown } from 'primereact/dropdown';
import strings from './localization';
import SubscriptionService from './service/SubscriptionService';

export class ScopeConfiguration extends Component {


    constructor(props) {
        super(props);

        this.state = {
            ticket: {},
            loading: false,
            pageLoading: false,
            errors: [],
        };
        this.toast = React.createRef();
    }

    init =()=>{
        var subscriptionService = new SubscriptionService();
        let sessionSubscriptions = subscriptionService.GetSessionSubscriptions();
        let companies = sessionSubscriptions.filter(s=>s.uuid==localStorage.getItem('subscription'))[0].companies;
        let branches = companies?.filter(s=>s.uuid==localStorage.getItem('company'))[0]?.branches;
        this.setState({subscriptions:sessionSubscriptions });
        this.setState({companies:companies});
        this.setState({branches:branches });

    }

    componentDidMount(){
        this.init();
        this.setState({company: localStorage.getItem('company'), subscription: localStorage.getItem('subscription'), branch:localStorage.getItem('branch')})
    }

    save = async () => {
        let { subscription, company, branch, companies, branches } = this.state;
        let token = localStorage.getItem("token");
        let refreshToken = localStorage.getItem("refreshToken");

        this.setState({ loading: true });
        let service = new AuthService();
        service.RefreshJwtToken({
            token: token,
            refreshToken: refreshToken,
            deviceId: service.GetDeviceId(),
            sid: subscription,
            vendorId: company,
            branchId: branch
        }).then((data) => {
            data.vendorId = company;
            data.branchId = branch;
            data.company = companies?.filter(s => s.uuid == company)[0];
            data.branch = branches?.filter(s => s.uuid == branch)[0];
            service.PopulateSession(data, subscription);
            window.location.reload();
        }).finally(() => {
            // setLoading(false);
        });

    }

    render() {

        let {subscriptions, subscription,companies,branches,branch,company} = this.state;

        if (this.state.pageLoading) {
            return <div style={{ textAlign: 'center' }}><ProgressSpinner /></div>
        }

        const isRTL = localStorage.getItem('lang') == 'ar' ? 'rtl' : 'ltr';

        return (

            <div className="p-grid crud-demo" style={{ direction: isRTL }}>
                <div className="p-col-12">

                    <div className="card" style={{ width: '100%' }}>
                        <Toast ref={this.toast} />
                        <div className="input-panel p-d-flex p-flex-column p-px-3">
                            {subscriptions != null && subscriptions.length > 0 ? <div className="p-inputgroup p-mt-3 p-mb-3">
                                <span className="p-inputgroup-addon">
                                    {strings.pickSubscription}
                                </span>
                                <Dropdown value={subscription} onChange={(e) => {
                                    this.setState({subscription: e.value})
                                    this.setState({
                                        companies:subscriptions.filter(s=>s.uuid==e.value)[0].companies,
                                        company: null,
                                        branch: null});
                                    let branches = subscriptions.filter(s=>s.uuid==e.value)[0].companies?.filter(s=>s.uuid==localStorage.getItem('company'))[0]?.branches;
                                    this.setState({branches: branches});
                                }} options={subscriptions.map(sub => {
                                    return {
                                        value: sub.uuid,
                                        label: sub.name,
                                    };
                                })}></Dropdown>
                            </div> : ''}

                            {companies && companies.length > 0? <div className="p-inputgroup p-mt-3 p-mb-3">

                                <span className="p-inputgroup-addon">
                                    {strings.Company}
                                </span>

                                <Dropdown value={company} onChange={(e) => {
                                    this.setState({company: e.value})
                                    this.setState({branches: companies.filter(s=>s.uuid==e.value)[0].branches, branch: null});
                                }} options={companies.map(sub => {
                                    return {
                                        value: sub.uuid,
                                        label: sub.name,
                                        branches: sub.branches
                                    };
                                })}></Dropdown></div> : ''}

                            {branches && branches.length > 0 ? <div className="p-inputgroup p-mt-3 p-mb-3">
                                <span className="p-inputgroup-addon">
                                    {strings.Branch}
                                </span>
                                <Dropdown value={branch} onChange={(e) => {
                                    this.setState({branch: e.value})
                                }} options={branches.map(sub => {
                                    return {
                                        value: sub.uuid,
                                        label: sub.name,
                                    };
                                })}></Dropdown> </div> : ''}


                        </div>
                        <div className="p-field p-px-3">
                            {this.state.errors?.length > 0 && <Message text={this.state.errors.map((m) => <li>{m}</li>)} severity="error" />}
                            <Button style={{ width: '100%' }} label={strings.Save} loading={this.state.loading} onClick={this.save} loadingoptions={{ position: 'right' }} />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
