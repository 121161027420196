import React, { useEffect, useState }from 'react'
import { connect, useSelector} from 'react-redux'
import Payment from './Payement'
import {getPayment} from '../../store/actions/SupplierPayment'
import {useParams} from 'react-router-dom'
import { ProgressSpinner } from 'primereact/progressspinner'
export const EditPayment = ({getPayment}) => {
    const {payment} = useSelector(state => state.supplierPayments)
    const [loading, setLoading] = useState(true)
    const {id} = useParams()
    useEffect(() => {
        Promise.all([getPayment(id)]).then(() => setLoading(false))
    },[])

  return (
    <div>
        {loading?<ProgressSpinner/>:
        <Payment paymentForEdit={payment} mode='edit'/>}
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {getPayment}

export default connect(mapStateToProps, mapDispatchToProps)(EditPayment)