import { createSlice } from "@reduxjs/toolkit"


const subscription = createSlice({
    name: 'subscription',
    initialState:{plans: [], plan:{}, msg:''},
    reducers:{
        addAction(state, action){
            return {...state, ...action.payload}
        }
    }
})

export default subscription.reducer
export const {addAction} = subscription.actions