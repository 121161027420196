import { createSlice } from "@reduxjs/toolkit";
import { setItem, getItem, id } from "../service/helpers";
import ProductService from '../service/ProductService'


const products = createSlice({
    name: 'products',
    initialState : {pProducts: getItem('pProducts')?? [],products:[]},
    reducers: {
        addProducts(state, action){
            return {...state, products: action.payload}
        },
        addProduct(state, action){
            return{ ...state, products: [...state.products, action.payload]}
        },
        addPProduct(state, action){
            setItem('pProducts', [...state.pProducts, action.payload])
            return{ ...state, pProducts: [...state.pProducts, action.payload]}
        }
    }
})


export const getProducts = (vendorID, limit = 50, offset=0  ) => async ( dispatch, state) => {
    try {
        const {data} =await  new ProductService().Getproduct(limit, offset, vendorID);
       dispatch(addProducts(data))
    } catch (error) {
    console.log("🚀 ~ file: products.js ~ line 19 ~ error", error)

    }
}

export const addPProductHandler = payload => async (dispatch) =>{
console.log("🚀 ~ file: products.js ~ line 35 ~ addPProductHandler ~ payload", payload)
    try {
        dispatch(addPProduct({ uuid: id() ,...payload, name: payload.translations[0]?.name}));
    } catch (error) {
    console.log("🚀 ~ file: products.js ~ line 34 ~ addPProduct ~ error", error)

    }
}



export const {addProducts,addProduct,addPProduct} = products.actions

export default products.reducer

