// import axios from 'axios'

// export class CustomerService {
//     getCustomersMedium() {
//         return axios.get('assets/demo/data/customers-medium.json')
//             .then(res => res.data.data);
//     }

//     getCustomersLarge() {
//         return axios.get('assets/demo/data/customers-large.json')
//                 .then(res => res.data.data);
//     }

// }



import ApiService from './ApiService';
import querystring from 'query-string';
let api = process.env.REACT_APP_API_URL


export default class CustomerService extends ApiService {

    constructor() {
        super();
        this.path = '/customer';
    }

    async CreateCustomer(data) {
        try {
            let op = await this.Create(this.path, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }


    async UpdateCustomer(data) {

        console.log(data);
        try {

            let op = await this.Update(this.path+"/"+data.uuid, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async DeleteCustomer(id) {

        try {

            let op = await this.Delete(this.path+"/"+id);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }


    async GetCustomers(limit=10,offset=0,vendorID=null, filters={}) {
        try {
            let link = `${this.path}/list?limit=${limit}&offset=${offset}${vendorID?'&vendorId='+vendorID:''}&${querystring.stringify(filters)}`
            let op = await this.Read(link);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async GetCount() {
        try {
            let link = `${this.path}/list?countOnly=true`
            let op = await this.Read(link);
            return op.data.count;
        } catch(e) {
            return e.response.data;
        }
    }
}
