import React, {  useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import {  connect } from 'react-redux'
import { addIssuerHandler } from '../../../store/actions/IssuerAction'

const IssuerDialog = ({ visible, onHide, addIssuerHandler, vendorId }) => {
    const [issuer, setIssuer] = useState([{ locale: 'en', name: '' }, { locale: 'ar', name: '' }])
    const onChange = (e) => {
        const newState = issuer.map(iss => iss.locale === e.target.id ? { ...iss, name: e.target.value } : iss)
        setIssuer(() => newState)
    }
    return (
        <Dialog visible={visible} onHide={onHide} style={{ lineHeight: '4rem' }}>

            <h5 style={{marginBottom: '2rem'}}>Add Issuer</h5>
            <div className="p-grid">
                <InputText type='text' id='en' placeholder='English Name' onChange={onChange} />
                <InputText type='text' id='ar' placeholder='Arabic Name' onChange={onChange}/>
                <Button label='Add' onClick={() => addIssuerHandler({ vendorId: vendorId, translations:issuer}).then(() => onHide())} />

            </div>
        </Dialog>
    )
}

export default connect(null, { addIssuerHandler })(IssuerDialog)