import ApiService from "./ApiService";

class PurchasePayment extends ApiService {
    constructor(){
        super();
        this.path = '/'
    }
    async getPayment(id){
        try {
            let link = `${this.path}/${id}`
            let op = await this.Read(link)
            return op.data
        } catch (e) {
            return e.response.data;
        }
    }

    async getPayments(){
        try {
            let link = `${this.path}`
            let op = await this.Read(link)
            return op.data
        } catch (e) {
            return e.response.data;
        }
    }
    async updatePayment(data) {
        try {
            let link = `${this.path}`
            let op = await this.Update(link, data)
            return op.data
        } catch (e) {
            return e.response.data;
        }
    }
    async createPayment(data) {
        try {
            let link = `${this.path}`
            let op = await this.Create(link, data);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }
    async deletePayment(data) {
        try {
            let link = `${this.path}`
            let op = await this.Delete(link, data);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }
}

export default new PurchasePayment()