import React, { Component } from 'react';
import { Button } from "primereact/button";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';


import VendorService from '../service/VendorService'
import SupplierService from '../service/SupplierService'
import PurchaseOrderService from '../service/PurchaseOrderService'
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import { confirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog method
import { Dialog } from 'primereact/dialog';
import { formatDate, formatDateString } from './InvoiceDates';
import JsFileDownloader from 'js-file-downloader';
import { Toast } from 'primereact/toast';
import { SupplierForm } from './SupplierForm';
import { VendorForm } from './VendorForm';
import ProductForm from './ProductForm';
import strings from '../localization';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import Can, { check } from '../Can';
import UserService from '../service/UserService';
import rules from '../rbac-rules';
import { Toolbar } from 'primereact/toolbar';
import SubscriptionService from '../service/SubscriptionService';
import { Editor } from 'primereact/editor';
import SupplierProductService from '../service/SupplierProductService';
import { getCurrency } from '../service/helpers';
import SupplierProductForm from './SupplierProductForm';
// import PurchaseOrderService from '../service/PurchaseOrderService';

const currencys = [
    { code: getCurrency(), name: getCurrency() },
    { code: 'USD', name: 'USD' },
    { code: 'EUR', name: 'EUR' },
]

export default class PurchaseOrderForm extends Component {
    constructor(props) {
        super(props);


        this.state = {
            purchaseOrder: {},
            connection: null,
            debitNoteDialog: false,
            creditNoteDialog: false,
            listening: false,
            addedProducts: [],
            suppliersLoading: false,
            productsLoading: false,
            emailDialog: false,
            originalRows: [],
            dscType: {symbol: '%'},
            rates: [
                {
                    code: getCurrency(),
                    name: getCurrency(),
                    targetCurrency: getCurrency(),
                    baseCurrency: getCurrency(),
                    rate: 1.0,
                }
            ],
            toEmail: '',
            sendToType: "3",
            vendor: {},
            loading: true,
            activeIndex: 0,
            errors: [],
        }

        this.toast = React.createRef();
        this.reload();
    }

    reload = () => {
        var vendorService = new VendorService();
        this.setState({ errors: [] });

        let company = new SubscriptionService().GetActiveCompany();
        let companyPopulted = company != null;
        let branch = new SubscriptionService().GetActiveBranch();

        vendorService.GetVendors(500, 0, { simple: true }).then(data => {
            if (!data.data) return [];
            return data.data.map(v => {
                return { code: v.uuid, name: v.name, ...v }
            });
        }).then(data => {
            this.setState({ vendors: data }, () => {
                let { id } = this.props.match.params;
                let { purchaseOrder } = this.state;
                if (id.startsWith("new")) {
                    this.setState({
                        purchaseOrder: {
                            referenceNumber: '',
                            purchaseOrderStatus: 2,
                            confirmed: false,
                            currency: currencys[0],
                            date: new Date(),
                            vendor: companyPopulted ? company : this.state.vendors[0],
                            branchSerial: {
                                code: branch?.serial,
                                name: `${branch?.name} (${branch?.serial})`
                            },
                            branchId: branch?.uuid
                        },
                    }, () => {
                        this.reloadSuppliers();
                        this.reloadProducts();
                        this.setState({ loading: false })
                    });
                } else {
                    PurchaseOrderService.getOrder(id).then(res => {
                        let vendor = this.state.vendors?.filter(t => t.code == res.vendor.uuid)[0];
                        // let supplier = this.state.suppliers?.filter(t => t.code == res.vendor.uuid)[0];
                        console.log(res)
                        let po = {
                            ...res,
                            ...purchaseOrder,
                            "currency": currencys.filter(t => t.code == res.currency)[0],
                            "vendor": vendor,
                            "lines": res.lines?.map((line, i) => {
                                return {
                                    ...line,
                                    "qty": line.qty,
                                    "supplierProductId": line.product.uuid,
                                    "productNumber": line.product.productNumber,
                                    "index": i + 1,
                                    "total": line.qty * line.rate
                                }
                            })
                        }
                        let addedProducts = po.lines.map(p => p.product);
                        this.setState({ purchaseOrder: po, addedProducts }, () => {
                            this.reloadSuppliers();
                            this.reloadProducts();
                            this.setState({ loading: false })
                        })
                    })
                }
            });
        });
    }

    reloadSuppliers(filters = { simple: true }, skipDefault = false, newSupplier = false) {
        if (!skipDefault) this.setState({ suppliersLoading: true });
        let purchaseOrder = { ...this.state.purchaseOrder };
        var supplierService = new SupplierService();
        filters.vendorId = purchaseOrder.vendor?.code;
        supplierService.GetSuppliers(100, 0, filters).then(data => {
            if (!data.data) {
                return { code: null, name: '-' }
            }
            return data.data.map(v => {
                return { code: v.uuid, name: v.name, ...v }
            });
        }).then(data => {
            this.setState({ suppliers: data });
        })
            .then(() => {
                if (skipDefault) return;
                let purchaseOrder = { ...this.state.purchaseOrder };
                if (newSupplier) {
                    purchaseOrder.supplier = this.state.suppliers[0];
                }
                else {
                    purchaseOrder.supplier = {
                        ...purchaseOrder.supplier,
                        name: purchaseOrder.supplier.name,
                        code: purchaseOrder.supplier.uuid
                    };
                }
                this.setState({ purchaseOrder })
            }).catch(() => {
                this.setState({ suppliers: [] });
            }).finally(() => {
                let purchaseOrder = { ...this.state.purchaseOrder };
                this.setState({ purchaseOrder })
                if (!skipDefault) this.setState({ suppliersLoading: false });
            })
    }
    reloadProducts(filters = {}, productsLoading = true) {
        this.setState({ productsLoading: productsLoading });
        let purchaseOrder = { ...this.state.purchaseOrder };
        var productService = new SupplierProductService();

        filters = { ...filters, simple: true, excluded: purchaseOrder.lines?.filter(p => p.productId != null).map(p => { return p.productId }) }
        productService.GetBulkProducts(100, 0, purchaseOrder.supplier?.code,filters).then(data => {
            this.setState({ products: data.data });
        }).finally(() => {
            this.setState({ productsLoading: false });
        })
    }

    confirm = () => {
        confirmDialog({
            message: `${strings.Areyousureyouwanttoproceed}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => await this.confirmPurchaseOrder(),
        });
    }
    confirmConversion = () => {
        let { id } = this.props.match.params;

        confirmDialog({
            message: `${strings.Areyousureyouwanttoproceed}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                await PurchaseOrderService.convertPurchaseOrderToInvoice(id)
                this.reload();
            }
        });
    }

    onRowEditInit = (event) => {
        let originalRows = [...this.state.originalRows];
        originalRows[event.index] = { ...this.state.purchaseOrder.lines[event.index] };
        this.setState({ originalRows });
    }

    onSave = async (confirm) => {
        let { purchaseOrder } = this.state;
        this.setState({ errors: [], loading: true });
        let request = {
            ...purchaseOrder,
            "confirmed": confirm,
            "date": new Date(),
            "vendorId": purchaseOrder.vendor?.code,
            "supplierId": purchaseOrder.supplier?.code,
            "currency": purchaseOrder.currency?.code,
            "lines": purchaseOrder.lines?.map((line => {
                return {
                    ...line,
                    "supplierProductId": line.product.uuid ?? line.product
                }
            })),
            "isActive": true
        }

        let purchaseOrderResponse = await PurchaseOrderService.createOrder(request);
        if (purchaseOrderResponse.errors) {
            let errors = [];
            Object.keys(purchaseOrderResponse.errors).forEach(key => {
                errors.push(purchaseOrderResponse.errors[key]);
            })
            this.setState({ errors });
            this.setState({ loading: false })

        } else if (purchaseOrderResponse.errorCode) {
            let errors = [];
            errors.push(strings[purchaseOrderResponse.errorCode]);
            this.setState({ errors });
            this.setState({ loading: false })
        }
        else if (purchaseOrderResponse.uuid == null) {
            let errors = [];
            errors.push(strings.internalServerError);
            this.setState({ errors });
            this.setState({ loading: false })
        }
        else {
            this.props.history.push(`/profile/purchaseOrder/${purchaseOrderResponse?.uuid}`);
            setTimeout(() => {
                this.reload();
                this.setState({ loading: false })
            }, 500)

        }
    }

    onRowEditCancel = (event) => {
        let i = { ...this.state.purchaseOrder }
        i.lines[event.index] = this.state.originalRows[event.index];
        let originalRows = [...this.state.originalRows];
        delete originalRows[event.index];
        this.setState({ purchaseOrder: i, originalRows });
    }

    confirmPurchaseOrder = async () => {
        await this.onSave(true);
        this.reload();
    }

    changeValue = (e, callback = () => { }) => {
        let i = { ...this.state.purchaseOrder };
        i[e.target.id] = e.target.value;
        this.setState({ purchaseOrder: i }, callback);
    }

    onEditorValueChange = (props, e) => {

        let i = { ...this.state.purchaseOrder }

        i.lines[props.rowIndex][props.field] = e.target.value;

        let total = props.rowData.qty * props.rowData.rate;

        i.lines[props.rowIndex]['total'] = total;

        this.setState({ purchaseOrder: i });
    }

    onPropertyEditorValueChange = (props, e) => {
        let i = { ...this.state.purchaseOrder }


        if (props.field == 'showOnPdf') {
            i.properties[props.rowIndex][props.field] = !e.target.value;
        }
        else {


            if (props.field == 'arabicName') {
                let newVal = this.propertyNames.englishName.filter(en => en.code == e.target.value.code)[0];
                if (newVal != null) {
                    i.properties[props.rowIndex]['englishName'] = newVal;
                }
            }
            else if (props.field == 'englishName') {
                let newVal = this.propertyNames.arabicName.filter(ar => ar.code == e.target.value.code)[0];
                if (newVal != null) {
                    i.properties[props.rowIndex]['arabicName'] = newVal;
                }
            }

            i.properties[props.rowIndex][props.field] = e.target.value;
        }

        this.setState({ purchaseOrder: i });
    }


    changeProductsSearchBar = (e, nameOnly) => {
        let { purchaseOrder } = this.state;

        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        this.setState({
            productLazyLoading: false,
            productName: e.query,
            typingTimeout: setTimeout(() => {
                let i = { ...purchaseOrder }
                this.reloadProducts({ name: nameOnly ? e.query : null, productNumber: nameOnly ? null : e.query, excluded: i.lines.filter(p => p.productId != null).map(p => { return p.productId }) }, false);
                this.setState({ productLazyLoading: false })
            }, 300)
        });

    }

    changeSupplierSearchBar = (e) => {
        if (this.state.typingTimeout2) {
            clearTimeout(this.state.typingTimeout2);
        }

        this.setState({
            supplierName: e.query,
            typingTimeout2: setTimeout(() => {
                this.reloadSuppliers({ name: e.query, simple: true }, true)
            }, 200)
        });

    }

    productDropdown = (rowData, props) => {
        let { purchaseOrder, products } = this.state;
        if (purchaseOrder.confirmed) {
            let product = purchaseOrder.lines?.filter(i => i.productId == rowData.productId)[0];
            if (props.field == 'product') {
                return <span>{product?.product.name}</span>
            }

            return <span>{product?.product.productNumber}</span>
            // return <span>{JSON.stringify(purchaseOrder.lines)}</span>
        }

        return <div style={{ display: 'flex', maxWidth: '100%' ,width: '100%' }}>
            {/* {JSON.stringify(props.rowData[props.field])} */}

            <AutoComplete dropdown={props.field == 'product'} inputs placeholder={props.field == 'product' ? strings.productFilterSearchPlaceHolder : strings.searchByProductNumber} style={{ width: '100%' }} id="product" onSelect={(e) => {

                let i = { ...purchaseOrder }
                let { addedProducts } = this.state;

                let product = products.filter(i => i.uuid == e.value.uuid)[0];

                if (product == null) return;

                addedProducts.push(product);

                i.lines[props.rowIndex] = {};

                let total = product.price * 1;

                i.lines[props.rowIndex]['productId'] = product.uuid;
                i.lines[props.rowIndex]['label'] = product?.name;
                i.lines[props.rowIndex]['qty'] = 1;
                i.lines[props.rowIndex]['rate'] = product.price;
                i.lines[props.rowIndex]['product'] = product;
                i.lines[props.rowIndex]['productNumber'] = product.productNumber;
                i.lines[props.rowIndex]['index'] = props.rowIndex + 1;
                i.lines[props.rowIndex]['total'] = total;
                this.setState({ purchaseOrder: i, addedProducts }, () => {
                    // this.onEditorValueChange(props, e.originalEvent);
                });

            }} value={props.rowData[props.field]} suggestions={products?.map(o => {
                return {
                    ...o,
                    name: props.field == 'product' ? o.name : o.productNumber,
                    value: o.uuid
                }
            })} field="name" completeMethod={(e) => this.changeProductsSearchBar(e, props.field == 'product')} onChange={(e) => {
                let i = { ...purchaseOrder }
                i.lines[props.rowIndex][props.field] = e.target.value;
                this.setState({ purchaseOrder: i })
            }} />

            {props.field == 'product' && <Can yes={() => <Button style={{ width: '25px', height: '25px' }} icon="pi pi-plus" tooltipOptions={{
                position: 'top'
            }} tooltip="Create Product" onClick={() => { this.setState({ addProduct: true }) }}></Button>}
                perform={'products:create'}
                silent={true}
                role={new UserService().GetRole()} />}
        </div>


    }

    inputTextEditor = (rowData, props, type = "text") => {
        let { purchaseOrder } = this.state;

        if (purchaseOrder.confirmed || !check(rules, new UserService().GetRole(), "products:update")) {
            return <span>{props.rowData[props.field]}</span>
        }


        // return <div style={{ maxWidth: '100%' }}><InputText style={{ width: props.field=== 'discount' ? '50%': '100%' }} type={type} value={purchaseOrder.lines[props.rowIndex][props.field]} onChange={(e) => this.onEditorValueChange(props, e)} width="100%" />{props.field === 'discount' && <Dropdown style={{ width: '50%' }} value={this.state.dscType} options={ [{symbol:'%'}, {symbol:'#'}]} optionLabel='symbol' onChange={e=> {this.setState({dscType: e.target.value}); this.onEditorValueChange({...props,field:'dscType'}, {target: {value: e.target.value}})}}/>} </div>;
        return <div  style={{ width: '100%' }}><InputText type={type} value={purchaseOrder.lines[props.rowIndex][props.field]} onChange={(e) => this.onEditorValueChange(props, e)} style={{ width: '100%' }} /></div>;

    }

    propertyInputTextEditor = (rowData, props, type = "text") => {
        let { purchaseOrder } = this.state;

        if (purchaseOrder.confirmed || !check(rules, new UserService().GetRole(), "products:update")) {
            return <span>{props.rowData[props.field]}</span>
        }

        if (props.field == 'showOnPdf') {
            return <div style={{ maxWidth: '100%' }}><Checkbox style={{ width: '100%' }} value={purchaseOrder.properties[props.rowIndex][props.field]} checked={purchaseOrder.properties[props.rowIndex][props.field]} onChange={(e) => this.onPropertyEditorValueChange(props, e)} width="100%" /></div>;
        }

        return <div style={{ maxWidth: '100%' }}><InputText style={{ width: '100%' }} type={type} value={purchaseOrder.properties[props.rowIndex][props.field]} onChange={(e) => this.onPropertyEditorValueChange(props, e)} width="100%" /></div>;
    }

    propertyInputTextEditor = (rowData, props, type = "text") => {
        let { purchaseOrder } = this.state;

        if (purchaseOrder.confirmed || !check(rules, new UserService().GetRole(), "products:update")) {
            return <span>{props.rowData[props.field]}</span>
        }

        if (props.field == 'showOnPdf') {
            return <div style={{ maxWidth: '100%' }}><Checkbox style={{ width: '100%' }} value={purchaseOrder.properties[props.rowIndex][props.field]} checked={purchaseOrder.properties[props.rowIndex][props.field]} onChange={(e) => this.onPropertyEditorValueChange(props, e)} width="100%" /></div>;
        }

        return <div style={{ maxWidth: '100%' }}><InputText style={{ width: '100%' }} type={type} value={purchaseOrder.properties[props.rowIndex][props.field]} onChange={(e) => this.onPropertyEditorValueChange(props, e)} width="100%" /></div>;
    }

    propertyNameEditor = (rowData, props, type = "text") => {
        let { purchaseOrder } = this.state;

        if (purchaseOrder.confirmed || !check(rules, new UserService().GetRole(), "products:update")) {
            return <span>{props.rowData[props.field]}</span>
        }

        return <div style={{ maxWidth: '100%' }}><Dropdown value={props.rowData[props.field]} options={this.propertyNames[props.field]} onChange={(e) => this.onPropertyEditorValueChange(props, e)} optionLabel="name" editable /></div>;
    }


    deleteRow = (rowData, props) => {
        let purchaseOrder = { ...this.state.purchaseOrder };
        purchaseOrder.lines = purchaseOrder.lines.filter((val, i) => i != props.rowIndex);
        this.setState({ purchaseOrder });
        // this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    }
    deletePropertyRow = (rowData, props) => {
        let purchaseOrder = { ...this.state.purchaseOrder };
        purchaseOrder.properties = purchaseOrder.properties.filter((val, i) => i != props.rowIndex);
        this.setState({ purchaseOrder });
        // this.toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    }

    actionBodyTemplate = (rowData, props) => {
        if (!check(rules, new UserService().GetRole(), "products:update")) {
            return "";
        }

        return (
            <div className="actions">
                <Button icon="pi pi-trash" onClick={() => this.deleteRow(rowData, props)} className="p-button-rounded p-button-warning" />
            </div>
        );
    }

    propertyActionBodyTemplate = (rowData, props) => {
        if (!check(rules, new UserService().GetRole(), "products:update")) {
            return "";
        }

        return (
            <div className="actions">
                <Button icon="pi pi-trash" onClick={() => this.deletePropertyRow(rowData, props)} className="p-button-rounded p-button-warning" />
            </div>
        );
    }

    supplierDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { this.setState({ addSupplier: false }) }} />
        </>
    );
    vendorDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { this.setState({ addVendor: false }) }} />
        </>
    );
    productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { this.setState({ addProduct: false }) }} />
        </>
    );

    confirmDeletePurchaseOrder = () => {
        this.setState({ deletePurchaseOrderDialog: true });
    }

    GetConfirmLabel = () => {
        return strings.Confirm;
    }

    deletePurchaseOrder = () => {

        PurchaseOrderService.DeleteDraftedPurchaseOrder(this.state.purchaseOrder.uuid).then((data) => {
            if (data.errorCode) {
                this.toast.current.show({ severity: `${strings.error}`, summary: `${strings.CannotDeletePurchaseOrder}`, detail: data.message, life: 3000 });
                this.setState({ deletePurchaseOrderDialog: false });
                return;
            }
            this.toast.current.show({ severity: `${strings.success}`, summary: `${strings.DeleteDraftPurchaseOrder}`, detail: `${strings.Deleteddraftsuccessfully}`, life: 3000 });
            this.props.history.push(`/profile/purchaseOrders/new`);
            this.reload();
        }).finally(() => {
            this.setState({ deletePurchaseOrderDialog: false });
        });

    };

    deletePurchaseOrderDialogFooter = (
        <>
            <Button label={strings.No} icon="pi pi-times" className="p-button-text" onClick={() => this.setState({ deletePurchaseOrderDialog: false })} />
            <Button label={strings.Yes} icon="pi pi-check" className="p-button-text" onClick={this.deletePurchaseOrder} />
        </>
    );

    sendPurchaseOrderToEmail = async () => {
        if (!this.state.toEmail || this.state.toEmail == '') {
            this.setState({ sendEmailError: strings.emailIsRequired });
            return;
        }

        this.setState({ sendingEmail: true })

        var res = await PurchaseOrderService.SendPurchaseOrderToEmail({
            email: this.state.toEmail,
            pdf: this.state.sendToType == '1' || this.state.sendToType == '3',
            xml: this.state.sendToType == '2' || this.state.sendToType == '3',
            purchaseOrderId: this.state.purchaseOrder.uuid
        });
        this.setState({ sendingEmail: false })

        if (res.status == 400) {
            this.setState({ sendEmailError: strings.emailValidationError });
        }

        this.setState({ emailSent: true });
    }

    sendToEmailDialogFooter = () => (
        !this.state.emailSent ?
            <>
                <Button label={strings.Cancel} icon="pi pi-times" className="p-button-text" onClick={() => this.setState({ emailDialog: false, toEmail: this.state.purchaseOrder?.supplier?.email, emailSent: false })} />
                <Button label={strings.Send} icon="pi pi-send" loading={this.state.sendingEmail} className="p-button-text" onClick={this.sendPurchaseOrderToEmail} />
            </>
            : ''
    );


    AddRow = () => {
        let len = 0;
        if (this.state.purchaseOrder.lines?.length) len = this.state.purchaseOrder.lines?.length;

        let rows = [...this.state.purchaseOrder.lines ?? [], {
            index: len + 1,
            qty: 0,
            label: "",
            productId: "",
            rate: 0.0,
            unit: "",
            peppol: "",
            discount: 0.0
        }];

        let i = { ...this.state.purchaseOrder };
        i.lines = rows;
        this.setState({ purchaseOrder: i });
    }

    AddCustomPropertyRow = () => {
        let len = 0;
        if (this.state.purchaseOrder.properties?.length) len = this.state.purchaseOrder.properties?.length;

        let rows = [...this.state.purchaseOrder.properties ?? [], {
            index: len + 1,
            key: "",
            arabicName: "",
            englishName: "",
            showOnPdf: true,
            value: "",
        }];

        let i = { ...this.state.purchaseOrder };
        i.properties = rows;
        this.setState({ purchaseOrder: i });
    }

    render() {
        let { purchaseOrder, errors, vendors, suppliers, loading } = this.state;

        let role = new UserService().GetRole();

        let { id } = this.props.match.params;

        let canEdit = !purchaseOrder.confirmed || (id.startsWith("new"))

        let companyPopulted = new SubscriptionService().GetActiveCompany() != null;

        if (loading) {
            return <div style={{ textAlign: 'center', margin: "auto" }}><ProgressSpinner /></div>
        }
        let token = localStorage.getItem("token");

        const leftToolbarTemplate = () => {
            return (
                <React.Fragment>
                    {purchaseOrder.uuid && !purchaseOrder.confirmed ? <Can yes={() => <Button type="button" label={this.GetConfirmLabel()} icon="pi pi-check-circle" onClick={this.confirm} className="p-mb-3"></Button>}
                        perform={'purchaseOrders:create'}
                        silent={true}
                        role={role} /> : ''}

                    {/* {purchaseOrder.confirmed && !purchaseOrder.linkedInvoiceId ? <Can yes={() => <Button type="button" label={strings.convertPurchaseOrderToInvoice} icon="pi pi-check-circle" onClick={this.confirmConversion} className="p-mb-3"></Button>}
                        perform={'purchaseOrders:create'}
                        silent={true}
                        role={role} /> : ''} */}

                    {purchaseOrder.confirmed && purchaseOrder.linkedInvoiceId ? <Can yes={() => <Button type="button" label={strings.checkDraftInvoice} icon="pi pi-check-circle" onClick={()=>{
                            this.props.history.push(`/profile/invoices/${purchaseOrder.linkedInvoiceId}`);
                        }} className="p-mb-3"></Button>}
                        perform={'purchaseOrders:create'}
                        silent={true}
                        role={role} /> : ''}



                    {!purchaseOrder.confirmed ? <Can yes={() => <Button type="button" label={strings.saveDraft} icon="pi pi-save" onClick={() => this.onSave(false)} className="p-mb-3"></Button>}
                        perform={'purchaseOrders:draft:update'}
                        silent={true}
                        role={role} /> : ''}

                    {purchaseOrder.uuid && purchaseOrder.pdfPath ? <Button className="p-mb-3" type="button" label={strings.downloadPDFPurchaseOrder} icon="pi pi-file-pdf" tooltipOptions={{
                        position: 'top'
                    }} tooltip={strings.downloadPDF} onClick={() => {
                        new JsFileDownloader({
                            url: `${purchaseOrder.pdfPath}`,
                            headers: [{
                                name: 'Authorization',
                                value: `Bearer ${token}`
                            }]
                        })
                            .then(() => {
                                this.toast?.current.show({ severity: `${strings.success}`, summary: 'Downloaded PDF', life: 3000 });
                            })
                            .catch((error) => {
                                this.toast?.current.show({ severity: `${strings.error}`, summary: 'PDF is not ready yet, refresh and try again.', life: 3000 });
                            });
                    }} /> : (purchaseOrder.uuid && <Button className="p-mb-3" type="button" label="Generating PDF" loading disabled icon="pi pi-file-pdf" />)}

                </React.Fragment>
            )
        }

        const rightToolbarTemplate = () => {
            return (
                <React.Fragment>
                    {purchaseOrder.uuid && purchaseOrder.confirmed ? <Can yes={() => <Button className="p-mb-3" type="button" label={strings.SendToEmail} icon="pi pi-send" tooltipOptions={{ position: 'top' }} tooltip="Refresh" onClick={() => this.setState({ emailDialog: true, toEmail: purchaseOrder?.supplier?.email })} />}
                        perform={'purchaseOrders:sendEmail'}
                        silent={true}
                        role={role} /> : ''}

                    {purchaseOrder.uuid && <Button className="p-mb-3" type="button" label={strings.Refresh} icon="pi pi-refresh" tooltipOptions={{ position: 'top' }} tooltip="Refresh" onClick={() => { this.reload() }} />}

                    {purchaseOrder.uuid && !purchaseOrder.confirmed ? <Can yes={() => <Button type="button" label={strings.DeleteDraft} icon="pi pi-times" onClick={() => this.setState({ deletePurchaseOrderDialog: true })} className="p-mb-3 p-button-warning"></Button>}
                        perform={'purchaseOrders:draft:delete'}
                        silent={true}
                        role={role} /> : ''}

                    {purchaseOrder.confirmed ? <Can yes={() => <Button label={strings.New} icon="pi pi-plus" className="p-button-success p-mr-3 p-mb-3" onClick={() => {
                        window.location.href = `/profile/purchaseOrders/new`;
                    }} />}
                        perform={'purchaseOrders:draft:create'}
                        silent={true}
                        role={role} /> : ''}


                </React.Fragment>
            )
        }

        return (
            <div className="layout-invoice-page">
                <Toast ref={this.toast} />

                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />

                {errors?.length > 0 && <Message style={{ marginBottom: '10px' }} text={errors.map((m) => <li>{m}</li>)} severity="error" />}
                <br />
                <div className="p-grid">
                    <div className="p-md-12 p-col-12">
                        <div className="card">
                            <h5>{purchaseOrder.confirmed ? `View` : (purchaseOrder.uuid ? strings.edit : strings.createNew)} {strings.purchaseOrder} {purchaseOrder.confirmed?`#${purchaseOrder.purchaseOrderNumber}`:''}</h5>
                            <hr />
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12 p-md-12">
                                    {/* <hr style={{borderColor:'#039b92'}}/> */}
                                    <div className="p-field p-col-12 p-md-12">
                                        <div className="p-fluid p-formgrid p-grid">
                                            <div className={`p-field p-col-12 p-md-6`} style={{ textAlign: 'center' }}>
                                                <h6>{strings.Vendor}:</h6>
                                                {canEdit && !companyPopulted ? <>

                                                    {<div style={{ display: 'flex', width: '100%', paddingTop: '10px' }}>
                                                        <Dropdown filter={true} style={{ width: '100%' }} id="vendor" filterPlaceholder={strings.filterSearchPlaceHolder} filterBy="name,vatNumber,phone,email" value={purchaseOrder.vendor} onChange={(e) => {
                                                            this.changeValue(e, () => {
                                                                let purchaseOrder = { ...this.state.purchaseOrder };
                                                                purchaseOrder.lines = [];
                                                                this.setState({ purchaseOrder: purchaseOrder });
                                                                this.reloadSuppliers();

                                                                this.reloadProducts();
                                                            });
                                                        }} options={vendors} optionLabel="name" placeholder={strings.Select}></Dropdown>

                                                        <Can yes={() => <Button icon="pi pi-plus" tooltipOptions={{
                                                            position: 'top'
                                                        }} tooltip="Create Vendor" onClick={() => { this.setState({ addVendor: true }) }}></Button>}
                                                            perform={'vendors:create'}
                                                            silent={true}
                                                            role={role} />
                                                    </div>
                                                    }
                                                </> : <p>{purchaseOrder.vendor?.name}</p>}

                                                <span>{strings.VatNumber}: <b>{purchaseOrder.vendor?.vatNumber ?? '-'}:</b></span>

                                            </div>

                                            {this.state.suppliersLoading ? <div style={{ textAlign: 'center' }}><ProgressSpinner /></div> : (<>
                                                {!purchaseOrder.vendor ? <div className={`p-field p-col-12 p-md-6`} style={{ textAlign: 'center' }}><Message style={{ width: '100%', marginBottom: '20px', padding: '20px' }} severity="info" content={strings.selectVendorsToShowSuppliers} /></div> :
                                                    <div className={`p-field p-col-12 p-md-6`} style={{ textAlign: 'center' }}>
                                                        <h6>{strings.supplier}:</h6>
                                                        {canEdit ?

                                                            <div style={{ display: 'flex', paddingTop: '10px' }}>
                                                                <AutoComplete dropdown placeholder={strings.filterSearchPlaceHolder} style={{ width: "100%" }} id="supplier" value={purchaseOrder.supplier} suggestions={suppliers} field="name" completeMethod={this.changeSupplierSearchBar} onChange={this.changeValue} />

                                                                <Can yes={() => <Button icon="pi pi-plus" tooltipOptions={{
                                                                    position: 'top'
                                                                }} tooltip="Create Supplier" onClick={() => { this.setState({ addSupplier: true }) }}></Button>}
                                                                    perform={'suppliers:create'}
                                                                    silent={true}
                                                                    role={role} />
                                                            </div>
                                                            : <p>{purchaseOrder.supplier?.name}</p>}

                                                        <span style={{ textAlign: 'left' }}>{strings.VatNumber}: <b>{purchaseOrder.supplier?.vatNumber ?? '-'}</b></span>

                                                    </div>}

                                            </>)}

                                        </div>
                                    </div>
                                    <hr />
                                </div>

                                <div className="p-field p-col-12 p-md-6">
                                    <h6>{strings.date}:</h6>
                                    {!purchaseOrder.confirmed ? <Calendar id="date" showTime={true} showSeconds={true} showIcon showButtonBar value={formatDate(purchaseOrder.date)} onChange={this.changeValue}></Calendar> : <span>{purchaseOrder.purchaseOrderDate ? formatDateString(purchaseOrder.purchaseOrderDate) : '-'}</span>}
                                </div>

                                <div className="p-field p-col-12 p-md-6">
                                    <h6 htmlFor="currency">{strings.Currency}:</h6>
                                    <div style={{ display: 'flex' }}>

                                        {canEdit ? <Dropdown id="currency" value={purchaseOrder.currency} onChange={(e) => {
                                            this.changeValue(e)
                                        }} options={currencys.filter(c => {
                                            return this.state.rates.filter(r => r.code == c.code).length > 0
                                        })} optionLabel="name" style={{ width: '100%' }} placeholder="Select One"></Dropdown> : <span>{purchaseOrder.currency?.name}</span>}
                                    </div>
                                </div>

                                <div className="p-field p-col-12 p-md-12">
                                    <h6 htmlFor="state">{strings.termsAndConditions}:</h6>
                                    <Editor readOnly={purchaseOrder.confirmed} style={{ height: '320px' }} value={purchaseOrder.termsAndConditions} onTextChange={(e) => {
                                        let po = { ...purchaseOrder }
                                        po['termsAndConditions'] = e.htmlValue;
                                        this.setState({ purchaseOrder: po })
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="p-col-12">
                        <div className="card">

                            {purchaseOrder.vendor ? <div>
                                <div className="p-field p-col-12 p-md-12" >
                                    <DataTable
                                        header={<div>
                                            <h4>{strings.Lines}</h4>
                                        </div>}
                                        footer={purchaseOrder.lines?.length > 0 && (!purchaseOrder.confirmed) && <Can yes={() => <div style={{ width: '100%', textAlign: 'center', marginLeft: '10px', marginTop: '-7px' }}><Button icon="pi pi-plus" title={strings.addLine} style={{ width: '25%', alignSelf: 'center', marginLeft: '0px', marginTop: '5px' }} onClick={this.AddRow} className="p-button-rounded p-button-success p-mr-4">&nbsp;{strings.addLine}</Button></div>}
                                            perform={'products:create'}
                                            silent={true}
                                            role={new UserService().GetRole()} />}
                                        value={purchaseOrder.lines}
                                        dataKey="id"
                                        responsiveLayout="scroll"
                                        resizableColumns columnResizeMode="fit"
                                        lazy
                                        stripedRows
                                        autoLayout={true}
                                        loading={this.state.linesLoading}
                                        showGridlines={true}
                                        onRowEditInit={this.onRowEditInit}
                                        onRowEditCancel={this.onRowEditCancel}
                                        className="datatable-responsive"
                                        emptyMessage={(!purchaseOrder.confirmed) && <Can yes={() => <div style={{ width: '100%', textAlign: 'center', marginLeft: '10px', marginTop: '-7px' }}><Button icon="pi pi-plus" title={strings.addLine} style={{ width: '25%', alignSelf: 'center', marginLeft: '10px', marginTop: '-7px' }} onClick={this.AddRow} className="p-button-rounded p-button-success p-mr-2">&nbsp;{strings.addLine}</Button></div>}
                                            perform={'products:create'}
                                            role={new UserService().GetRole()} />}
                                    >
                                        <Column style={{ textAlign: 'center' }} field="product" header={strings.Product} body={this.productDropdown}></Column>
                                        <Column style={{ textAlign: 'center' }} field="productNumber" header={strings.Product+'#'} body={this.productDropdown}></Column>
                                        <Column style={{ textAlign: 'center' }} field="qty" header={strings.Quantity} body={(rawData, props) => this.inputTextEditor(rawData, props, 'number')}></Column>
                                        <Column style={{ textAlign: 'center' }} field="rate" header={`${strings.Price} (${purchaseOrder.currency?.code})`} body={(rawData, props) => this.inputTextEditor(rawData, props, 'number')}></Column>
                                        <Column style={{ textAlign: 'center' }} field="total" header={`${strings.Total} (${purchaseOrder.currency?.code})`} body={(rowData) => <div>{rowData['total']?.toLocaleString()}</div>}></Column>
                                        {!purchaseOrder.confirmed && <Column header="Action" body={this.actionBodyTemplate}></Column>}
                                    </DataTable>
                                </div>
                                {purchaseOrder.uuid ?
                                    <div>
                                        <hr />
                                        <div className="p-col-1 p-md-1 p-offset-11">
                                            <div style={{ wordWrap: "break-word" }} className=" p-col-12 p-md-12">
                                                <label htmlFor="paymentType">{strings.totalAmountDue}: </label>
                                                <Message severity="success" content={<b>{purchaseOrder.totalAmount} {purchaseOrder.currency?.code??purchaseOrder.currency}</b>}></Message>
                                                {/* <span id="paymentType">{purchaseOrder.totalAmount} {purchaseOrder.currency?.code??purchaseOrder.currency}</span> */}
                                            </div>
                                        </div>
                                    </div>
                                    : <div className="p-col-2 p-md-2 p-offset-10">
                                        <Message severity="info" content={strings.SaveDraftToShowTotals}></Message>
                                    </div>}

                            </div> : <Message style={{ width: '100%' }} severity="info" content={strings.SelectvendortoaddpurchaseOrderlines} />}


                        </div>
                    </div>

                    <Dialog visible={this.state.addSupplier} style={{ width: '75%' }} header={strings.SupplierDetails} modal className="p-fluid" footer={this.supplierDialogFooter} onHide={() => { this.setState({ addSupplier: false }) }}>
                        <SupplierForm OnHideDialog={() => { this.setState({ addSupplier: false }) }} vendor={purchaseOrder.vendor} full={true} FillData={() => this.reloadSuppliers({}, false, true)} />
                    </Dialog>

                    <Dialog visible={this.state.addProduct} style={{ width: '700px' }} header={strings.linesDetails} modal className="p-fluid" footer={this.productDialogFooter} onHide={() => { this.setState({ addProduct: false }) }}>
                        <SupplierProductForm context={this.props.context} OnHideDialog={() => { this.setState({ addProduct: false }) }} product={null} FillData={() => this.reloadProducts()} />
                    </Dialog>

                    <Dialog visible={this.state.addVendor} style={{ width: '75%' }} header={strings.VendorsDetails} modal className="p-fluid" footer={this.vendorDialogFooter} onHide={() => { this.setState({ addVendor: false }) }}>
                        <VendorForm OnHideDialog={() => { this.setState({ addVendor: false }) }} full={true} FillData={() => this.reload()} />
                    </Dialog>
                    <Dialog visible={this.state.deletePurchaseOrderDialog} style={{ width: '450px' }} header={strings.Confirm} modal footer={this.deletePurchaseOrderDialogFooter} onHide={() => this.setState({ deletePurchaseOrderDialog: false })}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {<span>{strings.Areyousureyouwanttodeletedraft}?</span>}
                        </div>
                    </Dialog>
                    <Dialog visible={this.state.emailDialog} style={{ width: '450px' }} header={strings.SendToEmail} modal footer={this.sendToEmailDialogFooter} onHide={() => this.setState({ emailDialog: false, toEmail: purchaseOrder?.supplier?.email, emailSent: false })}>
                        <div className="confirmation-content">
                            {!this.state.emailSent ? <div className="p-fluid p-grid">
                                <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                                    <label htmlFor="toEmail" className="required">{strings.email}</label>
                                    <InputText id="toEmail" value={this.state.toEmail} defaultValue={this.state.purchaseOrder?.supplier?.email} onChange={(e) => this.setState({ toEmail: e.target.value })} />
                                </div>
                                <div className="p-field-checkbox" style={{ padding: '10px' }}>
                                    <RadioButton inputId="checkOption3" name="option" value="1" checked={this.state.sendToType == "1"} onChange={(e) => this.setState({ sendToType: e.target.value })} />
                                    <label htmlFor="checkOption3">{strings.pdf}</label>
                                </div>

                                <div className="p-field-checkbox" style={{ padding: '10px' }}>
                                    <RadioButton inputId="checkOption3" name="option" value="2" checked={this.state.sendToType == "2"} onChange={(e) => this.setState({ sendToType: e.target.value })} />
                                    <label htmlFor="checkOption3">{strings.xml}</label>
                                </div>

                                <div className="p-field-checkbox" style={{ padding: '10px' }}>
                                    <RadioButton inputId="checkOption3" name="option" value="3" checked={this.state.sendToType == "3"} onChange={(e) => this.setState({ sendToType: e.target.value })} />
                                    <label htmlFor="checkOption3">{strings.both}</label>
                                </div>
                            </div> : <div className="p-fluid p-grid">
                                <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                                    <Message severity="success" content={strings.emailSent} />
                                </div>
                            </div>}
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }
}
