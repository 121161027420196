import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import UserService from '../service/UserService';
import VendorService from '../service/VendorService';
import { Checkbox } from 'primereact/checkbox';
import { Message } from 'primereact/message';
import strings from '../localization';
import { Dropdown } from 'primereact/dropdown';
import SubscriptionService from '../service/SubscriptionService';
import { MultiSelect } from 'primereact/multiselect';
import querystring from 'query-string';


export const Users = () => {

    let emptyUser = {
        id: null,
        name: '',
        email: null,
        accessRole: '',
        password: null,
    };

    const [userDialog, setUserDialog] = useState(false);
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [resetPasswordDialog, setResetPasswordDialog] = useState(false);
    const [activateUserDialog, setActivateUserDialog] = useState(false);
    const [deactivateUserDialog, setDeactivateUserDialog] = useState(false);
    const [deleteUsersDialog, setDeleteUsersDialog] = useState(false);
    const [user, setUser] = useState(emptyUser);
    const [deactivationReason, setDeactivationReason] = useState(null)
    const [sid, setSid] = useState(null)
    const [errors, setErrors] = useState([])
    const [preFilters, setPreFilters] = useState([])

    const [activationError, setActivationError] = useState(null)
    const [withResetPassword, setWithResetPassword] = useState(false)
    const [activateUserEmail, setActivateUserEmail] = useState(null)
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [users, setUsers] = useState([]);
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [filters, setFilters] = useState({
    });


    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        // const userService = new UserService();
        // userService.getUsers().then(data => setUsers(data));
        onPage({rows,page:0})
    }, []);

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            setLoading(true);
            onPage({rows,page:0, filters:querystring.stringify(filters)})
        }

    }, [filters]);

    const formatCurrency = (value) => {
        if(!value) return ""
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setUser(emptyUser);
        setSubmitted(false);
        setUserDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setUserDialog(false);
        setErrors([]);
        setUser(emptyUser);
    }

    const hideDeleteUserDialog = () => {
        setDeleteUserDialog(false);
        setUser(emptyUser);
    }
    const hideResetPasswordDialog = () => {
        setResetPasswordDialog(false);
        setUser(emptyUser);
    }

    const hideDeleteUsersDialog = () => {
        setDeleteUsersDialog(false);
    }

    const hideActivateUserDialog = () => {
        setActivateUserEmail(null);
        setWithResetPassword(false);
        setActivationError(false);
        setActivateUserDialog(false);
    }
    const hideDectivateUserDialog = () => {
        setActivateUserEmail(null);
        setDeactivationReason(null);
        setActivationError(null);
        setDeactivateUserDialog(false);
    }

    const saveUser = async () => {
        setSubmitted(true);
        setLoading(true);
        var userService = new UserService();

        if (user.uuid) {
            let res = await userService.UpdateUser(user);
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                setErrors(errors);
            } else if (res.errorCode != null) {
                setErrors([res.message]);
            }
            else {
                hideDialog();
                onPage({ rows, page })
                setUser(emptyUser);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User Updated', life: 3000 });
            }
        }
        else
        {
            let res = await userService.CreateUser(user);

            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                setErrors(errors);
            } else if (res.errorCode != null) {
                setErrors([res.message]);
            } else {
                let subService = new SubscriptionService();
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User Created', life: 3000 });
                hideDialog();
                onPage({ rows, page })
                setUser(emptyUser);
            }
        }
        setLoading(false);
    }

    const editUser = (user) => {
        setUser({ ...user });
        setUserDialog(true);
    }

    const confirmDeleteUser = (user) => {
        setUser(user);
        setDeleteUserDialog(true);
    }

    const confirmPasswordReset = (user) => {
        setUser(user);
        setResetPasswordDialog(true);
    }

    const deleteUser = async () => {

        var userService = new UserService();

        try {
            await userService.DeleteUser(user.uuid);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User Deleted', life: 3000 });
            onPage({rows,page:0})
            hideDeleteUserDialog();
        } catch{
            toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Error while deleting user', life: 3000 });
        }
    }
    const resetPassword = async () => {

        var userService = new UserService();

        try {
            await userService.SendResetPasswordRequest(user.email);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Sent Reset Password Email', life: 3000 });
            onPage({rows,page:0})
            hideResetPasswordDialog();
        } catch{
            toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Error while sending email', life: 3000 });
        }
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < users.length; i++) {
            if (users[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteUsersDialog(true);
    }

    const deleteSelectedUsers = () => {
        var service = new UserService();
        service.
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Users Deleted', life: 3000 });
    }

    const onCategoryChange = (e) => {
        let _user = { ...user };
        _user['accessRole'] = e.value;
        setUser(_user);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _user = { ...user };
        _user[`${name}`] = val;

        setUser(_user);
    }
    const onInputChangeBool = (e, name) => {
        let _user = { ...user };
        _user[`${name}`] = e.checked;

        setUser(_user);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _user = { ...user };
        _user[`${name}`] = val;

        setUser(_user);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
                <Button label="Delete" icon="pi pi-trash" className="p-button-danger p-mb-2" onClick={confirmDeleteSelected} disabled={!selectedUsers || !selectedUsers.length} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        // return (
        //     <React.Fragment>
        //         <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" />
        //         <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
        //     </React.Fragment>
        // )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.code}
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    }

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`${rowData.imageUrl}`} alt={rowData.imageUrl} className="user-image" />
            </>
        )
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.email}
            </>
        );
    }

    const phoneBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Phone</span>
                {rowData.phone}
            </>
        );
    }

    const accessRoleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reviews</span>
                {rowData.accessRole}
            </>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${rowData.activatedAt ? '3' : '1'}`}>{rowData.activatedAt ? 'Active' : 'Inactive'}</span>
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        let status = rowData.activatedAt ? '3' : '1';

        let showButton = JSON.parse(localStorage.getItem('user')).id != rowData.uuid

        return (
            <div className="actions">
           {showButton &&  <Button icon={`pi pi-${status == 3?'times':'check'}`} tooltip={status != 3?'Activate':'Deactivate'} className={`p-button-rounded p-button-${status == 3? 'danger':'success'} p-mr-2`} onClick={async () => {
                    if (status == 1) {
                        setActivateUserEmail(rowData.email)
                        setActivateUserDialog(true);
                    }
                    else {
                        setActivateUserEmail(rowData.uuid)
                        setDeactivateUserDialog(true);
                    }
                }}/>}

                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editUser(rowData)} />
                {showButton && <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteUser(rowData)} />}

                <Button style={{marginLeft:'5px'}} icon="pi pi-send" tooltip="Send Reset Password Email" className="p-button-rounded p-button-success" onClick={() => confirmPasswordReset(rowData)} />
            </div>
        );
    }

    const onPage = ({ first,
        rows,
        page,
        filters,
        pageCount }) => {
        const userService = new UserService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        userService.GetUsers(rows, (page) * rows, filters).then(({ data, count }) => {
            setLoading(false);
            setUsers(data);
            setTotalRecords(count);
        });

    }

    const onFiltersChange = (e) => {
        let filters = { ...preFilters };
        filters[e.target.id] = e.target.value;
        setPreFilters(filters);
    }

    const header = (
        <div className="p-grid">

            <div className="p-col">

                <div className="table-header">

                    <h5 className="p-m-0">Manage Users</h5>
                </div>

            </div>
            <div className="p-col-12">

                <div className="card">

                    <div className="p-fluid p-grid">

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.Name}</label>

                            <InputText id="name" value={preFilters.name} onChange={onFiltersChange} />

                        </div>

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.email}</label>

                            <InputText type="email" id="email" value={preFilters.email} onChange={onFiltersChange} />

                        </div>
                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.Phone}</label>

                            <InputText id="phone" value={preFilters.phone} onChange={onFiltersChange} />

                        </div>

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.accessRole}</label>

                            <Dropdown style={{ width: '100%' }} id="accessRole" value={preFilters.accessRole} onChange={onFiltersChange} options={[
                                { code: '', name: '-' },
                                { code: 'super_admin', name: 'Super Admin' },
                                { code: 'read_only_admin', name: 'Read Only Admin' },
                                { code: 'data_entry_employee', name: 'Data Entry Admin' },
                                { code: 'default_role', name: 'Default Role' },
                            ]} optionLabel="name" ></Dropdown>

                        </div>

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="calendar">{strings.Status}</label>

                            <Dropdown style={{ width: '100%' }} id="status" value={preFilters.status} onChange={onFiltersChange} options={[
                                { code: '', name: '-' },
                                { code: 'active', name: 'Active' },
                                { code: 'inactive', name: 'Inactive' },
                            ]} optionLabel="name" ></Dropdown>
                            {/* <InputText id="companyName" value={preFilters.companyName} onChange={onFiltersChange} /> */}
                        </div>


                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <hr />

                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Filter} icon="pi pi-search" className="p-button-success p-mr-1 p-mb-1" onClick={() => {
                                let f = { ...preFilters }
                                // 2021-09-21T21:50:37.000Z
                                setFilters({
                                    email: f.email,
                                    phone: f.phone,
                                    name: f.name,
                                    accessRole: f.accessRole?.code,
                                    status: f.status?.code,
                                });
                            }} />
                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Clear} icon="pi pi-times" className="p-button-warning p-mr-1 p-mb-1" onClick={() => {
                                setPreFilters({});
                            }} />
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );

    const userDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button loading={loading} label="Save" icon="pi pi-check" className="p-button-text" onClick={saveUser} />
        </>
    );

    const deleteUserDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteUser} />
        </>
    );
    const resetPasswordDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideResetPasswordDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={resetPassword} />
        </>
    );

    const activateUserDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideActivateUserDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={async () => {

                var userService = new UserService();
                var data = null;
                try {
                    data = await userService.ActivateUserByEmail({
                        email: activateUserEmail,
                        withResetPassword: withResetPassword
                    });
                    if (data.errorCode) {
                        setActivationError(data.message);
                    } else {
                        onPage({ rows, page, filters:'' });
                        hideActivateUserDialog();
                    }
                }
                catch(e) {
                    setActivationError("Internal Server Error");
                }
            }} />
        </>
    );

    const deactivateUserDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDectivateUserDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={async () => {

                var userService = new UserService();
                var data = null;
                try {
                    data = await userService.DeactivateUser({
                        userId: activateUserEmail,
                        reason: deactivationReason
                    });
                    if (data.errorCode) {
                        setActivationError(data.message);
                    } else {
                        onPage({ rows, page });
                        hideDectivateUserDialog();
                    }
                }
                catch(e) {
                    setActivationError("Internal Server Error");
                }
            }} />
        </>
    );
    const deleteUsersDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUsersDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedUsers} />
        </>
    );
    const statusRowFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={[
            {name:'Active'},
            {name:'Inactive'},
        ]} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="name" itemTemplate={(option)=>{
            <div className="p-multiselect-representative-option">
                <span className="image-text">{option.name}</span>
        </div>
        }} placeholder="Any" className="p-column-filter" maxSelectedLabels={1} />;
    }

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt}
                               value={users}
                               selection={selectedUsers}
                               dataKey="id"
                               totalRecords={totalRecords}
                               lazy
                               first={first}
                               loading={loading}
                               responsiveLayout="scroll"
                               onPage={onPage}
                               paginator rows={rows}
                               rowsPerPageOptions={[5, 10, 25]}
                               className="datatable-responsive"
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
                               emptyMessage="No users found."
                               header={header}>
                        <Column field="name" header="Name" style={{width: "250px"}} body={nameBodyTemplate}></Column>
                        <Column field="email" header="Email"  style={{width: "250px"}} body={emailBodyTemplate}></Column>
                        <Column field="phone" header="Phone" body={phoneBodyTemplate}></Column>
                        <Column field="accessRole" header="Access Role"  body={accessRoleBodyTemplate} />
                        <Column field="status" header="Status"  body={statusBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={userDialog} style={{ width: '450px' }} header="User Details" modal className="p-fluid" footer={userDialogFooter} onHide={hideDialog}>
                        {user.image && <img src={`assets/demo/images/user/${user.image}`} alt={user.image} className="user-image" />}

                        <div className="p-field">
                            <label htmlFor="name">Email</label>
                            <InputText id="email" value={user.email} onChange={(e) => onInputChange(e, 'email')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.email })} />
                            {submitted && !user.email && <small className="p-invalid">Email is required.</small>}
                        </div>

                        <div className="p-field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={user.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.name })} />
                            {submitted && !user.name && <small className="p-invalid">Name is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">Phone</label>
                            <InputText id="name" value={user.phone} onChange={(e) => onInputChange(e, 'phone')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.phone })} />
                            {submitted && !user.phone && <small className="p-invalid">Phone is required.</small>}
                        </div>
                        <div className="p-field">
                            <Checkbox id="mfaEnabled"   checked={user.mfaEnabled} value={user.mfaEnabled} onChange={(e) => onInputChangeBool(e, 'mfaEnabled')} required autoFocus/>
                            <label htmlFor="name" style={{padding:'5px'}}>Enable MFA</label>
                        </div>

                        <div className="p-field">
                            <label className="p-mb-3">Access Role</label>
                            <div className="p-formgrid p-grid">
                                <div className="p-field-radiobutton p-col-6">
                                    <RadioButton inputId="category1" name="category" value="super_admin" onChange={onCategoryChange} checked={user.accessRole === 'super_admin'} />
                                    <label htmlFor="category1">Super Admin</label>
                                </div>
                                <div className="p-field-radiobutton p-col-6">
                                    <RadioButton inputId="category2" name="category" value="read_only_admin" onChange={onCategoryChange} checked={user.accessRole === 'read_only_admin'} />
                                    <label htmlFor="category2">Read Only Admin</label>
                                </div>
                                <div className="p-field-radiobutton p-col-6">
                                    <RadioButton inputId="category3" name="category" value="data_entry_employee" onChange={onCategoryChange} checked={user.accessRole === 'data_entry_employee'} />
                                    <label htmlFor="category3">Data Entry Employee</label>
                                </div>
                                <div className="p-field-radiobutton p-col-6">
                                    <RadioButton inputId="category4" name="category" value="default_role" onChange={onCategoryChange} checked={user.accessRole === 'default_role'} />
                                    <label htmlFor="category4">Default Role</label>
                                </div>
                            </div>
                        </div>

                        {/* {!user.uuid && <div className="p-field">
                            <label className="p-mb-3">Associated Subscription ID (Optional)</label>
                            <Message severity="info" content="Will create a subscription user associated with the UUID"/>
                            <br/>
                            <div className="p-formgrid p-grid">
                                <InputText value={sid} onChange={(e) => setSid(e.target.value)} style={{ width: '100%' }} placeholder="Subscription ID" />
                            </div>
                        </div>} */}

                        <hr/>
                        {!user.uuid && <Message severity="info" content="Reset password email will be sent after creation"/>}

                        {errors?.length > 0 && <Message text={errors.map((m) => <li>{m}</li>)} severity="error" />}
                    </Dialog>

                    <Dialog visible={deleteUserDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteUserDialogFooter} onHide={hideDeleteUserDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {user && <span>Are you sure you want to delete <b>{user.name}</b>?</span>}
                        </div>
                    </Dialog>
                    <Dialog visible={resetPasswordDialog} style={{ width: '450px' }} header="Confirm" modal footer={resetPasswordDialogFooter} onHide={hideResetPasswordDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {user && <span>Are you sure you want to send reset password to <b>{user.email}</b>?</span>}
                        </div>
                    </Dialog>
                    <Dialog visible={activateUserDialog} style={{ width: '450px' }} header="Confirm" modal footer={activateUserDialogFooter} onHide={hideActivateUserDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {user && <span>Are you sure you want to activate <b>{user.email}</b>?</span>}
                            <hr/>
                            <Checkbox inputId="allday" name="allday" value="All Day" checked={withResetPassword} onChange={(e) => setWithResetPassword(e.checked)}  />
                            <label style={{padding:'10px'}} htmlFor="allday">Send Reset Password Email</label>
                            <br/>
                            <br/>
                            {activationError && <Message severity="error" content={activationError}/>}
                        </div>
                    </Dialog>
                    <Dialog visible={deactivateUserDialog} style={{ width: '450px' }} header="Confirm" modal footer={deactivateUserDialogFooter} onHide={hideDectivateUserDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {user && <span>Are you sure you want to deactivate <b>{user.email}</b>?</span>}
                            <hr/>
                            <label style={{padding:'10px'}} htmlFor="allday">Reason:</label>
                            <InputText inputId="allday" name="allday" value={deactivationReason} onChange={(e) => setDeactivationReason(e.target.value)}  />
                            <br/>
                            <br/>

                            {activationError && <Message severity="error" content={activationError}/>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
