import moment from 'moment';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { Component } from 'react'
import Can from '../Can';
import strings from '../localization';
import SupplierService from '../service/SupplierService';
import UserService from '../service/UserService';
import { SupplierForm } from './SupplierForm';

class SupplierDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoice: {},
            suppliers:[]
        }
        this.ref = React.createRef();
        this.setState({ invoice: this.props.invoice })
    }

    reloadSuppliers(filters = { simple: true }, skipDefault = false, newSupplier = false) {
        if (!skipDefault) this.setState({ loading: true });
        var supplierService = new SupplierService();
        supplierService.GetSuppliers(100, 0, this.props.vendor, filters).then(data => {
            if (!data.data) {
                return { code: null, name: '-' }
            }
            return data.data.map(v => {
                return { code: v.uuid, name: v.registrationName, ...v }
            });
        }).then(data => {
            this.setState({ suppliers: data });
        }).then((data) => {
            // this.props.setDefaultValue(data);
        }).catch((e) => {
            this.setState({ suppliers: [] });
        }).finally(() => {
            let invoice = { ...this.state.invoice };
            this.setState({ invoice })
            if (!skipDefault) this.setState({ loading: false });
        })
    }

    supplierDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { this.setState({ addSupplier: false }) }} />
        </>
    );


    changeSupplierSearchBar = (e) => {
        if (this.state.typingTimeout2) {
            clearTimeout(this.state.typingTimeout2);
        }

        this.setState({
            supplierName: e.query,
            typingTimeout2: setTimeout(() => {
                this.reloadSuppliers({ registrationName: e.query, simple: true }, true)
            }, 200)
        });

    }
    componentDidMount() {
    }
    render() {
        let { onChange, vendor, canEdit, supplier } = this.props;
        let { suppliers } = this.state;
        // let {invoice} = this.state;
        if (this.state.loading) {
            return <ProgressSpinner />
        }


        if (!vendor) {
            return <div className={`p-field p-col-12 p-md-12`} style={{ textAlign: 'center' }}><Message style={{ width: '100%', marginBottom: '20px', padding: '20px' }} severity="info" content={strings.selectVendorsToShowSuppliers} /></div>
        }


        if (canEdit) {
            let role = new UserService().GetRole();

            return <div style={{ display: 'flex', paddingTop: '10px' }}>
                <AutoComplete dropdown placeholder={strings.filterSearchPlaceHolder} style={{ width: "100%" }} id="supplier" value={supplier} suggestions={suppliers} field="name" completeMethod={this.changeSupplierSearchBar} onChange={onChange} />

                <Can yes={() => <Button icon="pi pi-plus" tooltipOptions={{
                    position: 'top'
                }} tooltip="Create Supplier" onClick={() => { this.setState({ addSupplier: true }) }}></Button>}
                    perform={'suppliers:create'}
                    silent={true}
                    role={role} />

                <Dialog visible={this.state.addSupplier} style={{ width: '75%' }} header={strings.SupplierDetails} modal className="p-fluid" footer={this.supplierDialogFooter} onHide={() => { this.setState({ addSupplier: false }) }}>
                    <SupplierForm OnHideDialog={() => { this.setState({ addSupplier: false }) }} vendor={vendor} full={true} FillData={() => this.reloadSuppliers({}, false, true)} />
                </Dialog>

            </div>

        }


        return <div className={`p-field`}>
            <b>{strings.Suppliers}:</b>
            <p>{supplier.registrationName}</p>

            <span style={{ textAlign: 'left' }}>{strings.VatNumber}: <b>{supplier?.vatNumber ?? '-'}</b></span>

        </div>
    }
}

export default SupplierDropdown;
