import React, { useEffect, useState, useContext } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import strings from '../../localization'
import Can, { check } from '../../Can'
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import VendorForm from './components/VendorForm';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import AddAccount from './components/AddAccountDialog';
import { useParams, useHistory } from 'react-router-dom'
import { classNames } from 'primereact/utils';
import { RTLContext } from '../../Profile';
import { useSelector, useDispatch, connect } from 'react-redux'
import { getPaymentTypes } from '../../store/actions/SupplierPayment';
import SupplierForm from '../SupplierForm';
import SupplierView from '../expenses/SupplierView';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { getIssuersHandler, deleteIssuerHandler } from '../../store/actions/IssuerAction'
import { getAccountsHandler, deleteAccountHandler } from '../../store/actions/accountAction'
import IssuerDialog from '../invoicesPayment/components/IssuerDialog';
import { createPayment, updatePayment } from '../../store/actions/SupplierPayment';


const PaymentForm = ({ getPaymentTypes, expense = {}, context, getIssuersHandler, getAccountsHandler, createPayment, updatePayment, mode, paymentForEdit = {},deleteIssuerHandler,deleteAccountHandler }) => {

    const history = useHistory()
    const { vendors } = useSelector(state => state.vendors)
    const { role, activeBranch: branchPopulated } = useSelector(state => state.user)
    const { activeCompany } = useSelector(state => state.user)
    const initialState = { paymentDate: '', amount: null, paymentTypeId: '', comments: '', expenses: [], companyAccountId: '', issuerId: '', referenceNumber: '', vendorId: '', supplierId: '' }
    const { paymentTypes: { data: paymentTypes } } = useSelector(state => state.supplierPayments)
    const { suppliers } = useSelector(state => state.suppliers)
    const { data: accounts } = useSelector(state => state.bankAccounts.accounts)
    const { data } = useSelector(state => state.issuer.issuers)
    const [visible, setVisible] = useState('');
    const [addIssuer, setAddIssuer] = useState(false)
    const [loading, setLoading] = useState(true)
    const [selectedVendor, setSelectedVendor] = useState({})
    const [addSupplier, setAddSupplier] = useState(false)
    const [addVendor, setAddVendor] = useState(false)
    const [addAccount, setAddAccount] = useState(false)
    const [paymentDetails, setPaymentDetails] = useState(initialState)
    const [progressLoading,setProgressLoading] = useState(false)



    let canEdit = true

    const setEditDetails = (p) => {
        let obj = { ...p, vendorId: p.vendor?.uuid, paymentTypeId: p.paymentType?.uuid, supplierId: p.supplier?.uuid, issuerId: p.issuer?.uuid, companyAccountId: p.companyAccount?.uuid, uuid: p.uuid}
        delete obj.paymentType
        console.log("🚀 ~ file: Payement.jsx ~ line 57 ~ setEditDetails ~ obj", obj)
        delete obj.vendor
        delete obj.supplier
        delete obj.issuer
        return obj

    }
    useEffect(() => {
        expense.uuid && setPaymentDetails(x => { return { ...x, supplierId: expense.supplier.uuid, vendorId: expense.vendor.uuid, expenses: [expense.uuid] } })
        paymentForEdit.uuid && setPaymentDetails(setEditDetails(paymentForEdit))
        Promise.all([getPaymentTypes()]).then(() => setLoading(false))

    }, [])
    useEffect(() => {
        paymentDetails.vendorId && Promise.all([getAccountsHandler({ vendorId: paymentDetails.vendorId }), getIssuersHandler({ vendorId: paymentDetails.vendorId })])
    }, [paymentDetails.vendorId])
    const isRTL = useContext(RTLContext)
    const addPaymentHandler = (e) => {
        e.preventDefault()
        setProgressLoading(true)
        let obj = { ...paymentDetails }
        delete obj.lines
        delete obj.vendor
        delete obj.supplier
        Promise.all([ mode !== 'edit' ? createPayment({ ...paymentDetails, amount: paymentDetails.amount ?? expense.amount }) : updatePayment(paymentDetails)]).then(() =>history.push(`/profile/purchase/payments?status=${mode ? 200: 201}`))
        e.target.reset()

        setPaymentDetails(initialState)
        // setValue(null)
    }

    const vendorDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { setAddVendor(false) }} />
        </>
    );
    const reload = () => {
        console.log('hello')
    }
    useEffect(() => {
        activeCompany?.uuid && setSelectedVendor(activeCompany)
    }, [activeCompany, selectedVendor])

    const productDialogFooter = (
        <>
            <Button label={strings.Cancel} icon="pi pi-times" className="p-button-text" onClick={() => { setAddSupplier(false) }} />
        </>
    );
    const paymentTypeValidator = () => {
        return paymentTypes.find(x => x.uuid === paymentDetails.paymentTypeId)?.translations.find(x => x.name === 'Cash')
    }
    const accountTemplate = (option) => {
        return (
            <React.Fragment >
                <Button className="p-button-sm" icon='pi pi-trash' onClick={() => deleteAccountHandler(option.uuid)} style={{ zIndex: 20000 }} /> {' '}
                <span style={{ lineHeight: '200%' }} >{option.name}</span>
                <ConfirmDialog visible={visible === option.uuid} onHide={() => setVisible(false)} message={`Are you sure you want to delete ${option.name}?`}
                    header="Confirmation" icon="pi pi-exclamation-triangle" accept={() => deleteAccountHandler(option.uuid)} />
            </React.Fragment>

        )
    }
    const issuerTemplate = (option) => {
        return (
            <React.Fragment >
                <Button className="p-button-sm" icon='pi pi-trash' onClick={() => deleteIssuerHandler(option.uuid)} style={{ zIndex: 20000 }} /> {' '}
                <span style={{ lineHeight: '200%' }} >{option.name}</span>
                <ConfirmDialog visible={visible === option.uuid} onHide={() => setVisible(false)} message="Are you sure you want to delete?"
                    header="Confirmation" icon="pi pi-exclamation-triangle" accept={() => deleteIssuerHandler(option.uuid)} />
            </React.Fragment>

        )
    }

    return (
        <>
            <h3>{strings.makePayment}</h3>
            <AddAccount visible={addAccount} onHide={() => { setAddAccount(false) }} vendorId={paymentDetails.vendorId} />
            <Dialog visible={addVendor} style={{ width: '75%' }} header={strings.VendorsDetails} modal className="p-fluid" footer={vendorDialogFooter} onHide={() => { setAddVendor(false) }}>
                <VendorForm OnHideDialog={() => { setAddVendor(false) }} full={true} FillData={() => reload()} />
            </Dialog >
            <Dialog visible={addSupplier} style={{ width: '700px' }} header={strings.SuppliersDetails} modal className="p-fluid" footer={productDialogFooter} onHide={() => { setAddSupplier(false) }}>
                <SupplierForm vendor={selectedVendor} OnHideDialog={() => { setAddSupplier(false) }} context={context} FillData={() => reload()} />
            </Dialog>
            <IssuerDialog visible={addIssuer} onHide={() => setAddIssuer(false)} vendorId={paymentDetails.vendorId} />
            {loading ? <ProgressSpinner /> :
                <div className="p-field p-col-12 p-md-12">
                    {expense?.uuid && <div className=" p-col-12 p-grid p-justify-center" >
                        <div className=" p-col-4 p-md-4">
                            <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                <div className="p-d-flex p-ai-center muted-text">

                                    <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.Vendor}</h6>
                                    <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                    </div>
                                </div>
                                <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                    <div className="p-d-flex p-flex-column">
                                        <span className="p-mb-1 fs-large">{expense?.vendor?.name}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" p-col-4 p-md-4">
                            <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                <div className="p-d-flex p-ai-center muted-text">

                                    <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.amount}</h6>
                                    <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                    </div>
                                </div>
                                <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                    <div className="p-d-flex p-flex-column">
                                        <span className="p-mb-1 fs-large">{expense?.amount}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {/* <hr style={{borderColor:'#039b92'}}/> */}
                    <form action="" onSubmit={addPaymentHandler}>
                        <div className="p-field p-col-12 p-md-12 align-items-end p-justify-center">
                            <div className="p-fluid p-formgrid p-grid p-justify-center">
                                <div className={`p-field p-col-12 p-md-${expense.vendor?.branches?.length > 0 ? '6' : '12'}`} style={{ textAlign: 'center' }}>
                                    <b>{strings.Vendor}:</b>
                                    {canEdit && !selectedVendor.uuid ? <>
                                        {/* {invoice.agreementType?.code != 'selfBilled' && <b htmlFor="state">Select Vendor:</b>} */}

                                        {<div style={{ display: 'flex', width: '100%', paddingTop: '10px' }}>
                                            <Dropdown filter={true} style={{ width: '100%' }} id="vendor" filterPlaceholder={strings.filterSearchPlaceHolder} filterBy="name,vatNumber,phone,email" value={selectedVendor} onChange={(e) => setSelectedVendor(e.target.value)} options={vendors} optionLabel="name" placeholder={strings.Select}></Dropdown>

                                            <Can yes={() => <Button icon="pi pi-plus" type="button" tooltipOptions={{
                                                position: 'top'
                                            }} tooltip="Create Vendor" onClick={() => { setAddVendor(true) }}></Button>}
                                                perform={'vendors:create'}
                                                silent={true}
                                                role={role} />
                                        </div>
                                        }
                                    </> : <p>{selectedVendor?.name}</p>}

                                    <span>{strings.VatNumber}: <b>{selectedVendor?.vatNumber ?? '-'}:</b></span>

                                </div>
                                {canEdit && expense.vendor?.branches?.length > 0 && !branchPopulated ? <div className="p-field p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                    <b>{strings.Branch}:</b>
                                    <div style={{ display: 'flex', paddingTop: '10px' }}><Dropdown style={{ width: '100%' }} filter={true} id="branchSerial" value={expense.branchSerial} onChange={this.changeValue} options={expense.vendor?.branches?.map((i) => {
                                        return {
                                            code: i?.serial,
                                            name: `${i?.name} (${i?.serial})`
                                        }
                                    })} optionLabel="name" placeholder={strings.Select}></Dropdown></div>
                                </div> : (expense.branchSerial && expense.vendor?.branches?.length > 0 ? <div className="p-field p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                    <h5>{strings.Branch}</h5>
                                    <p>{expense.branchSerial?.name}</p>
                                </div> : '')}
                                <div className="p-col-12 p-grid p-justify-center p-align-center">
                                    {expense.supplier ? <SupplierView supplier={expense.supplier} /> :
                                        <>
                                            <div className='p-col-11'>

                                                <Dropdown value={suppliers.find(x => x.uuid === paymentDetails.supplierId)} options={suppliers} optionLabel="name" filter={true} style={{ width: '100%' }} filterBy="name,vatNumber,phone,email" onChange={e => setPaymentDetails(x => { return { ...x, supplierId: e.value.uuid, vendorId: e.value.vendor.uuid } })} placeholder={strings.selectSupplier}/>
                                            </div>
                                            <div className="p-col-1">
                                                <Can yes={() => <Button icon="pi pi-plus" type="button" tooltipOptions={{
                                                    position: 'top'
                                                }} tooltip="Create Supplier" onClick={() => { setAddSupplier(true) }}></Button>}
                                                    perform={'suppliers:create'}
                                                    silent={true}
                                                    role={role} />

                                            </div>
                                        </>}
                                </div>
                                <div className="p-col-12" style={{ margin: '1rem' }}></div>
                                <div className="p-grid align-item-end p-col-12">

                                    <div className="p-field p-col-4 p-md-4 ">
                                        <label htmlFor="icon">{strings.date}</label>
                                        <Calendar id="Date" value={new Date(paymentDetails.paymentDate)} onChange={(e) => setPaymentDetails({ ...paymentDetails, paymentDate: e.value })} showIcon required />
                                    </div>
                                    <div className="p-col-4" style={{ margin: 'auto' }}>
                                        <Dropdown value={paymentTypes.find(x => x.uuid === paymentDetails.paymentTypeId)} options={paymentTypes} onChange={e => setPaymentDetails({ ...paymentDetails, paymentTypeId: e.value.uuid })} optionLabel="name" placeholder={strings.selectPaymentType} required  disabled={!paymentDetails.vendorId}/>
                                    </div>
                                    <div className="p-col-4" style={{ margin: 'auto' }}>
                                        <label htmlFor="withoutgrouping">{strings.amount}</label>
                                        <InputNumber inputId="withoutgrouping" value={paymentDetails.amount} id="amount" onChange={e => setPaymentDetails({ ...paymentDetails, amount: e.value })} mode="decimal" useGrouping={false} max={expense.amount} />
                                    </div>
                                </div>

                                {paymentDetails.paymentTypeId && !paymentTypeValidator() && <div className="p-grid p-col-12 p-align-center vertical-container p-card" style={{ padding: '2rem', margin: '1rem' }}>
                                    <div className="p-col-3" >
                                        <Dropdown value={accounts?.find(val => val.uuid === paymentDetails.companyAccountId)} options={accounts} onChange={e => { setPaymentDetails({ ...paymentDetails, companyAccountId: e.value.uuid }) }} filter={true} filterBy='name' optionLabel="name" editable emptyMessage={strings.noAccount} placeholder={strings.selectAccount} itemTemplate={accountTemplate} required={paymentDetails.paymentTypeId && !paymentTypeValidator()} />

                                    </div>

                                    <div className="p-col-1" >
                                        <Button icon="pi pi-plus" title={strings.addAccount} onClick={() => setAddAccount(true)} type="button" />

                                    </div>
                                    <div className="p-col-3 " >
                                        <Dropdown value={data.find(v => v.uuid === paymentDetails.issuerId)} onChange={e => setPaymentDetails(i => { return { ...i, issuerId: e.target.value.uuid } })} options={data} optionLabel="name" filter showClear filterBy="name" placeholder={strings.selectIssuer} emptyMessage={strings.noIssuer} itemTemplate={issuerTemplate} required={paymentDetails.paymentTypeId && !paymentTypeValidator()} />
                                    </div>
                                    <div className="p-col-1">

                                        <Button icon='pi pi-plus' type='button' title={strings.addIssuer} onClick={() => setAddIssuer(true)} />
                                    </div>
                                    <div className="p-col-3" >
                                        <InputText type="text" className="block mb-2" placeholder={strings.referenceNumber} value={paymentDetails.referenceNumber} onChange={e => setPaymentDetails({ ...paymentDetails, referenceNumber: e.target.value })} required={paymentDetails.paymentTypeId && !paymentTypeValidator()} />

                                    </div>
                                </div>}
                                <div className="p-col-12">
                                    <div className=" p-col-2">
                                        {/* <h5>Basic</h5> */}
                                        {/* <InputText value={value} onChange={(e) => setValue(e.target.value)} />
                                    <span className="ml-2">{value}</span> */}
                                        <div className=" p-col-8 p-md-4">
                                        </div>
                                    </div>
                                    <div className="p-col-4"></div>
                                </div>
                                <div className="p-col-12" style={{ margin: '1rem' }}></div>

                                <div className="p-col-8 p-grid  ">
                                    <h5>{strings.comments}</h5>
                                    <InputTextarea value={paymentDetails.comments} onChange={(e) => setPaymentDetails({ ...paymentDetails, comments: e.target.value })} rows={5} cols={30} />
                                </div>


                            </div>
                            <div className="p-grid p-justify-center" style={{ margin: '2rem auto' }}>
                                {progressLoading? <ProgressSpinner style={{width: '50px', height: '50px'}}/>:

                                <Button type="submit" icon='pi pi-check-circle' label={strings.submit} />}
                            </div>
                            {/* <hr style={{borderColor:'#039b92'}}/> */}
                        </div>
                    </form>
                </div>}
        </>
    )
}

export default connect(null, { getPaymentTypes, getIssuersHandler, getAccountsHandler, createPayment, updatePayment ,deleteIssuerHandler,deleteAccountHandler})(PaymentForm)
