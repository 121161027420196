import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Link, Router, useHistory } from 'react-router-dom';
import AuthService from '../service/AuthService';
import { Message } from 'primereact/message';
import { Dropdown } from 'primereact/dropdown';
import strings from '../localization';
import { Dialog } from 'primereact/dialog';
import UserService from '../service/UserService';
import {RTLContext} from '../Profile'
import { Steps } from 'primereact/steps';
import Auth from '../components/Auth';
import { getCountry } from '../service/helpers';

const Login = () => {

	const history = useHistory();
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [loginData, setLoginData] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    const [subscription, setSubscription] = useState(null);
    const [company, setCompany] = useState(null);
    const [branch, setBranch] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [branches, setBranches] = useState(null);
    const [otpForm, setOtpForm] = useState(false);
    const [otpOptions, setOtpOptions] = useState(false);
    const [otpValue, setOtpValue] = useState(false);
    const [token, setToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState(null);
    const [resetPasswordEmail, setResetPasswordEmail] = useState(null);
    const [sentEmail, setSentEmail] = useState(false);
    const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
    const [isRTL, setRTL] = useState((localStorage.getItem('lang')??'ar')=='ar');

    const onRTLChange = () => {
        strings.setLanguage(isRTL?'en':'ar')
        localStorage.setItem('lang',isRTL?'en':'ar');
        setRTL(prevState => !prevState);
    }


    const doLogin = async () => {
        setError(false);
        if(otpForm && otpValue && loginData?.mfaEnabled)
        {
            setLoading(true);
            let service = new AuthService();
            return service.VerifyOtpCode({
                token: token,
                OTP:otpValue,
                refreshToken: refreshToken,
                deviceId: service.GetDeviceId(),
            }, token).then((data)=>{
                setOtpForm(false);
                setOtpValue(false);
                setOtpOptions(false);
                setLoginData(data);
                setSubscription(data.user.subscriptions[0]?.uuid);
                setCompanies(data.user.subscriptions[0]?.companies);
                setSubscriptions(data.user.subscriptions);
                if (data.user.subscriptions == null | data.user.subscriptions.length == 0) {
                    setError('Cannot find a subscription for this user.');
                }

            }).catch(e=>{
                setError(strings[e.response.data.messageCode??'internalServerError']);
            }).finally(()=>{
                setLoading(false);
            });
        }
        if(subscription!=null && subscription !="null")
        {
            setLoading(true);
            let service = new AuthService();
            service.RefreshJwtToken({
                token: loginData.token,
                refreshToken: loginData.refreshToken,
                deviceId: service.GetDeviceId(),
                sid: subscription,
                vendorId: company,
                branchId: branch
            }).then((data)=>{
                data.vendorId = company;
                data.branchId = branch;
                data.branch = companies?.filter(s=>s.uuid==company)[0];
                data.company = branches?.filter(s=>s.uuid==branch)[0];
                service.PopulateSession(data, subscription);
                history.push('/profile');

            }).finally(()=>{
                setLoading(false);
            });
        }
        else
        {
            try {
                var service = new AuthService();
                setLoading(true);
                let data = await service.DoBasicLogin({
                    username,
                    password,
                    deviceID: "deviceId"
                });
                setLoading(false);
                if (data.errors) {
                    setError(data.errors.join('\n'));
                } else if (data.token) {
                    setToken(data.token);
                    setRefreshToken(data.refreshToken);
                    setLoginData(data);
                    if (!data.mfaEnabled) {
                        setSubscription(data.user.subscriptions[0]?.uuid);
                        setCompanies(data.user.subscriptions[0]?.companies);
                        setSubscriptions(data.user.subscriptions);
                        if (data.user.subscriptions == null | data.user.subscriptions.length == 0) {
                            setError('Cannot find a subscription for this user.');
                        }
                    }
                    else {
                        setOtpOptions(true);
                    }
                }
            } catch (e) {
                setError('Internal Server Error');
            }
            finally {
                setLoading(false);
            }
        }
    }

    const startMFA = async (method) => {
        try {
            setError(false);
            var service = new AuthService();
            setLoading(true);
            let data = await service.StartOtpRequest(method, token);
            setOtpForm(true);
            setOtpOptions(false);
        } catch (e) {
            console.error(e)
            setError('Error while sending One Time Password, please try again, or contact admin.');
        }
        finally {
            setLoading(false);
        }
    }

    const sendResetPasswordRequest = async () => {
        try {
            setError(false);
            var service = new UserService();
            setLoading(true);
            await service.SendResetPasswordRequest(resetPasswordEmail);
            setSentEmail(true);
            setLoading(false);
        } catch (e) {
            setError('Internal Server Error');
        }
    }

    const goDashboard = () => {
		history.push('/');
	}
    
    const countryCode = getCountry();
    
	return (
        <>
            <div className={`pages-body ${countryCode=='BH'?'login-page-bh':'login-page'} p-d-flex p-flex-column`} >
                <div className="topbar p-p-3 p-d-flex p-jc-between p-flex-row p-ai-center">
                    <div className="topbar-left p-ml-3 p-d-flex">
                        <div className="logo">
                            <img src="assets/layout/images/logo-white.svg" alt="" />
                        </div>
                    </div>
                    <div className="topbar-right p-mr-3 p-d-flex">
                        <Button onClick={(event) => {
                            onRTLChange();
                            window.location.reload()
                        }}>
                            {isRTL ? 'English' : 'العربية'}
                        </Button>
                    </div>
                </div>

                <div className="p-as-center p-mt-auto p-mb-auto">
                    <div className="pages-panel card p-d-flex p-flex-column" style={{ backgroundColor: "#fff" }}>
                        <div className="p-px-3 p-py-1" style={{ borderRadius: '0%', backgroundColor: '#039b92', marginBottom: '20px' }}>
                            <img src="assets/layout/images/logo-white.svg" alt="Arms IT - E-Invoicing Solution" />
                        </div>

                        <h4>{strings.welcome}</h4>

                        <div className="pages-detail p-mb-6 p-px-6">{strings.signinMessage}</div>

                        <div className="input-panel p-d-flex p-flex-column p-px-3">
                            {!(subscriptions != null && subscriptions.length > 0 || otpOptions || otpForm) ? <><div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-envelope"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText onChange={(e) => setUsername(e.target.value)} type="text" id="inputgroup1" />
                                    <label htmlFor="inputgroup1">{strings.email}</label>
                                </span>
                            </div>

                            <div className="p-inputgroup p-mt-3">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-lock"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText onChange={(e) => setPassword(e.target.value)} type="password" id="inputgroup2" />
                                    <label htmlFor="inputgroup2">{strings.password}</label>
                                </span>
                            </div>
                            <div className="p-inputgroup p-mt-3">
                                <span className="p-float-label">
                                    <a htmlFor="inputgroup2" href="#" onClick={() => setResetPasswordDialogOpen(true)}>{strings.forgotpassword}</a>
                                </span>
                            </div></>:''}

                            {loginData?.mfaEnabled && otpOptions && <> {loginData?.allowedMethods.some((method) => method == 'email') && <div className="p-inputgroup p-mt-3 p-mb-3">
                                <Button loading={loading} onClick={() => startMFA("email")} style={{ width: '100%' }} icon="pi pi-envelope" type="text" id="inputgroup1" label={strings.formatString(strings.sendOtpTo, {
                otpTo: loginData?.user.email})} />
                            </div>}

                                {loginData?.user.phone && loginData?.allowedMethods.some((method) => method == 'sms') && <div className="p-inputgroup">
                                    {"".replace()}
                                    <Button loading={loading} onClick={() => startMFA("sms")} style={{ width: '100%' }} icon="pi pi-phone" type="text" id="inputgroup1" label={strings.formatString(strings.sendOtpTo, {
                                            otpTo: loginData?.user.phone.slice(0,-5)+"XXXXX"})} />
                                </div>}
                            </>}

                            {loginData?.mfaEnabled && otpForm && <><div className="p-inputgroup">
                            </div>
                                <b>
                                    {strings.fillOtp}
                                </b>

                                <InputText type={'text'} placeholder={"e.g. 123456"} onChange={(e) => setOtpValue(e.target.value)} />

                            </>}

                            {subscriptions != null && subscriptions.length > 0 ? <div className="p-inputgroup p-mt-3 p-mb-3">
                                <span className="p-inputgroup-addon">
                                    {strings.pickSubscription}
                                </span>
                                <Dropdown value={subscription} onChange={(e) => {
                                    setSubscription(e.value);
                                    setCompanies(subscriptions.filter(s=>s.uuid==e.value)[0].companies);
                                }} options={subscriptions.map(sub => {
                                    return {
                                        value: sub.uuid,
                                        label: sub.name,
                                    };
                                })}></Dropdown>
                            </div> : ''}

                            {companies && companies.length > 0? <div className="p-inputgroup p-mt-3 p-mb-3">

                                <span className="p-inputgroup-addon">
                                    {strings.Company}
                                </span>

                                <Dropdown showClear value={company} onChange={(e) => {
                                    setCompany(e.value)
                                    setBranches(companies.filter(s=>s.uuid==e.value)[0]?.branches);
                                }} options={companies.map(sub => {
                                    return {
                                        value: sub.uuid,
                                        label: sub.name,
                                        branches: sub.branches
                                    };
                                })}></Dropdown></div> : ''}

                            {branches && branches.length > 0 ? <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    {strings.Branch}
                                </span>
                                <Dropdown showClear value={branch} onChange={(e) => {
                                    setBranch(e.value)
                                }} options={branches.map(sub => {
                                    return {
                                        value: sub.uuid,
                                        label: sub.name,
                                    };
                                })}></Dropdown> </div> : ''}


                        </div>
                        {error && <div className='"p-inputgroup p-mt-3 p-mb-3"'>

                            <Message content={error} severity="error" />
                        </div>}
                        <br />
                        {!(otpOptions) && <Button loading={loading} onClick={doLogin} className="login-button p-mb-2 p-px-3" label={subscription == null ? 'Continue' : 'Login'}></Button>}
                        {!(subscriptions != null && subscriptions.length > 0) && !(otpForm && otpValue) && !otpOptions ?<span>{strings.signupMessage} <a alt="Landing page" style={{all:'unset !important'}} href="https://e-inv.armsit.com/#pricing">{strings.here}</a></span>:''}

                    </div>
                    <Dialog visible={resetPasswordDialogOpen} style={{ width: '450px' }} header={strings.resetPassword} modal className="p-fluid" onHide={() => setResetPasswordDialogOpen(false)}>

                        {sentEmail ? <Message severity="success" content={strings.emailSent} /> :
                            <div className="input-panel p-d-flex p-flex-column p-px-3">
                                <br />
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-envelope"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText onChange={(e) => setResetPasswordEmail(e.target.value)} type="text" id="inputgroup1" />
                                        <label htmlFor="inputgroup1">{strings.email}</label>
                                    </span>
                                </div>
                                <br />

                                <Button loading={loading} onClick={sendResetPasswordRequest} className="login-button p-mb-6 p-px-3" label={strings.sendResetPasswordRequest}></Button>
                            </div>}

                    </Dialog>


                </div>

            </div>
    </>

	)
}

export default Login;
