import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import SupplierService from '../service/SupplierService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import strings from '../localization';
import ApiService from '../service/ApiService';
import VendorService from '../service/VendorService';
import { connect } from 'react-redux';
import { addSupplier } from '../store/suppliers';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import IdentityTypeService from '../service/IdentityTypeService';
import { getCountry } from '../service/helpers';
import { countryCodes } from '../assets/country-codes';

export class SupplierForm extends Component {


    constructor(props) {
        super(props);

        let emptySupplier = {
            namear: "",
            nameen: "",
            vat: "",
        }


        this.state = {
            supplier: { ...emptySupplier },
            identityTypes: [],
            loading: false,
            pageLoading: false,
            errors: [],
        };
        this.toast = React.createRef();



    }

    FillVendors = async () => {
        let vendorService = new VendorService();
        this.setState({pageLoading: true});
        var vendors =await vendorService.GetVendors(500);
        this.setState({ vendors: vendors.data, pageLoading: false })
    }


    componentDidMount() {
        this.FillIdentityType();

        if(this.props.context?.showCompany) this.FillVendors();

        if (this.props.supplier != null) {
            // Fill Data

            this.ReturnData()
        }
    }


    ReturnData() {
        this.setState({
            supplier: {
                ...this.props.supplier,
                vendor: {
                    code: this.props.supplier.vendor?.uuid,
                    name: this.props.supplier.vendor?.name,
                }
            }
        })
    }

    onEditorValueChange(productKey, props, value) {
        let updatedRow = [...props.value];
        console.log(value)
        updatedRow[props.rowIndex][props.field] = value;
        this.setState({ [`${productKey}`]: updatedRow });
    }



    onInputChange = (e, name) => {
        let val = (e.target && e.target.value) || '';


        let _supplier = { ...this.state.supplier };

        if(name == 'pdfHasBorders')
        {
            val= !_supplier.pdfHasBorders;
        }
        if(name == 'seperateSequencePerType')
        {
            val= !_supplier.seperateSequencePerType;
        }

        if(name == 'isSimpleAddress')
        {
            val= !_supplier.isSimpleAddress;
        }

        if(name == 'isLetterStyle')
        {
            val= !_supplier.isLetterStyle;
        }

        _supplier[`${name}`] = val;


        this.setState({ supplier: _supplier });
    }


    delete = (rowData) => {
        let _supplier = { ...this.state.supplier };

        _supplier.rows = _supplier.rows.filter(val => val.identityId == rowData.identityId);
        this.setState({ supplier: _supplier });

        this.toast.current?.show({ severity: 'success', summary: 'Successful', detail: 'Identity Deleted', life: 3000 });
    }
    FillIdentityType = async () => {

        let identityTypeService = new IdentityTypeService();
        this.setState({ pageLoading: true })
        var identityTypes = await identityTypeService.GetIdentityTypes();
        this.setState({ identityTypes: identityTypes.data, pageLoading: false })

    }


    nameBodyTemplate = (rowData, props) => {
        return (
            <>


                {/* <span className="p-column-title">Name</span> */}

                {/* {console.log(rowData)} */}


                <Dropdown value={props.rowData['name']} onChange={(e) => this.onEditorValueChange('name', props, e.value)} optionLabel="name" options={this.state.identityTypes?.map(o => {
                    return {
                        name: o.label,
                        value: o.uuid
                    }
                })} style={{ width: '100%' }} placeholder={strings.Select} />
                {/* {rowData.name} */}

            </>
        );
    }



    valueBodyTemplate = (rowData, props) => {
        return (
            <>

                {/* <span className="p-column-title">Value</span> */}
                {/* {rowData.value} */}
                <InputText id='value' type="text" value={props.rowData['value']} onChange={(e) => this.onEditorValueChange('value', props, e.target.value)} required autoFocus />

            </>
        );
    }
    branchNameTemplate = (rowData, props) => {
        return (
            <>

                {/* <span className="p-column-title">Value</span> */}
                {/* {rowData.value} */}
                <InputText id='name' disabled={rowData.branchId} type="text" value={props.rowData['name']} onChange={(e) => this.onEditorValueChange('name', props, e.target.value)} required autoFocus />

            </>
        );
    }
    branchSerialTemplate = (rowData, props) => {
        return (
            <>
                {/* <span className="p-column-title">Value</span> */}
                <InputText disabled={rowData.branchId} id='serial' type="text" value={props.rowData['serial']} onChange={(e) => this.onEditorValueChange('serial', props, e.target.value)} required autoFocus />

            </>
        );
    }


    actionBodyTemplate = (rowData) => {

        return (
            <div className="actions">
                <Button icon="pi pi-trash" onClick={() => this.delete(rowData)} className="p-button-rounded p-button-warning" />
            </div>
        );
    }

    save = async () => {


        this.setState({ loading: true });


        let { supplier } = this.state;

        let data = {
            ...supplier,
            "vat": supplier.vat,
            "email": supplier.email,
            "vendorId": this.props.context?.vendor?.uuid ?? (supplier.vendor?.code) ?? this.props.vendor?.uuid,
            "phone": supplier.phone,
            "translations": [
                {
                    "name": supplier.nameen,
                    "locale": 1
                },
                {
                    "name": supplier.namear,
                    "locale": 2
                }
            ],
            "address": {
                "translations": [
                    {
                        "street": supplier.street1,
                        "additionalStreet": supplier.street2,
                        "buildingNumber": supplier.buildNo,
                        "additionalNumber": supplier.additionalNumber,
                        "city": supplier.city,
                        "postalCode": supplier.postalCode,
                        "state": supplier.state,
                        "neighborhood": supplier.neighborhood,
                        "countryCode": getCountry(),
                        "locale": 1
                    },
                    {
                        "street": supplier.street1,
                        "additionalStreet": supplier.street2,
                        "buildingNumber": supplier.buildNo,
                        "additionalNumber": supplier.additionalNumber,
                        "city": supplier.city,
                        "postalCode": supplier.postalCode,
                        "state": supplier.state,
                        "neighborhood": supplier.neighborhood,
                        "countryCode": getCountry(),
                        "locale": 2
                    }
                ]
            },
            "identities": supplier.rows?.map((row) => {
                return {
                    "identityId": row.identityId,
                    "identityTypeId": row.name,
                    "value": row.value
                };
            }),
            "isActive": true
        }

        var supplierService = new SupplierService();

        if (!data.uuid) {
            let res = await supplierService.CreateSupplier(data);
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors });
            }
            else if(res.errorCode)
            {
                let errors = [];

                errors.push(strings[res.errorCode]);
                this.setState({ errors });
            }
            else
            {
                this.props.OnHideDialog();
                this.toast.current?.show({ severity: 'success', summary: 'Successful', detail: 'Supplier Created', life: 3000 });
            }
        }
        else {
            let res = await supplierService.UpdateSupplier(data);

            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors });
            } else {
                this.props.OnHideDialog();
                this.toast.current?.show({ severity: 'success', summary: 'Successful', detail: 'Supplier Updated', life: 3000 });
            }
        }
        this.props.FillData();

        this.setState({ loading: false });
    }


    AddRow = () => {
        let rows = [...this.state.supplier.rows ?? [], { identityId: null, name: "", value: "" }];
        let _supplier = { ...this.state.supplier };
        _supplier.rows = rows;
        this.setState({ supplier: _supplier });
    }


    render() {

        if (this.state.pageLoading) {
            return <div style={{ textAlign: 'center' }}><ProgressSpinner /></div>
        }

        const isRTL = localStorage.getItem('lang') == 'ar' ? 'rtl' : 'ltr';

        let image = this.state.supplier.image;

        if(image?.startsWith(this.state.supplier.uuid))
        {
            image = new ApiService().AddHostName("","/download/supplier/logo/")+"?path="+image;
        }

        let {showCompany} = this.props.context??{};


        return (

            <div className="p-grid crud-demo" style={{ direction: isRTL }}>
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={this.toast} />


                        <div className="p-field">
                            {showCompany ? <label htmlFor="name">{strings.Vendor}*</label> : ''}
                            {showCompany ? <Dropdown id="vendor" value={this.state.supplier?.vendor} onChange={(e) => {
                                let supplier = {...this.state.supplier}
                                supplier['vendor'] = e.value;
                                this.setState({ supplier });
                            }} options={this.state.vendors?.map((v) => {
                                return {
                                    code: v.uuid,
                                    name: v.name
                                }
                            })} optionLabel="name" placeholder={strings.Select}></Dropdown> : ''}
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">{strings.NameEnglish}*</label>
                            <InputText id="nameen" value={this.state.supplier.nameen} onChange={(e) => this.onInputChange(e, 'nameen')} required autoFocus />
                            {/* <small className="p-invalid">{JSON.stringify(this.state.user)}.</small> */}
                        </div>

                        <div className="p-field">
                            <label htmlFor="name">{strings.NameArabic}*</label>
                            <InputText id="namear" value={this.state.supplier.namear} onChange={(e) => this.onInputChange(e, 'namear')} required autoFocus />

                        </div>

                        <div className="p-grid">

                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.VatNumber}*</label>
                                    <InputText id="vat" value={this.state.supplier.vat} onChange={(e) => this.onInputChange(e, 'vat')} required autoFocus />
                                </div>
                            </div>


                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="email">{strings.email}</label>
                                    <InputText id="email" value={this.state.supplier.email} onChange={(e) => this.onInputChange(e, 'email')} required autoFocus />
                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="phone">{strings.phone}</label>
                                    <InputText id="phone" value={this.state.supplier.phone} onChange={(e) => this.onInputChange(e, 'phone')} required autoFocus />
                                </div>
                            </div>
                            <div className="p-col-4">

                                <div className="p-field">
                                    <label htmlFor="name" className="required">{strings.Street1}</label>
                                    <InputText id="street1" value={this.state.supplier.street1} onChange={(e) => this.onInputChange(e, 'street1')} required autoFocus />
                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.AdditionalStreet}</label>
                                    <InputText id="street2" value={this.state.supplier.street2} onChange={(e) => this.onInputChange(e, 'street2')} required autoFocus />

                                </div>
                            </div>


                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name" className="required">{strings.BuildingNo}</label>
                                    <InputText id="buildNo" value={this.state.supplier.buildNo} onChange={(e) => this.onInputChange(e, 'buildNo')} required autoFocus />

                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.AdditionalNumber}</label>
                                    <InputText id="additionalNumber" value={this.state.supplier.additionalNumber} onChange={(e) => this.onInputChange(e, 'additionalNumber')} required autoFocus />

                                </div>
                            </div>




                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name" className="required">{strings.Neighborhood}</label>
                                    <InputText id="neighborhood" value={this.state.supplier.neighborhood} onChange={(e) => this.onInputChange(e, 'neighborhood')} required autoFocus />
                                </div>
                            </div>
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name" className="required">{strings.City}</label>
                                    <InputText id="city" value={this.state.supplier.city} onChange={(e) => this.onInputChange(e, 'city')} required autoFocus />

                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name" className="required">{strings.State}</label>
                                    <InputText id="state" value={this.state.supplier.state} onChange={(e) => this.onInputChange(e, 'state')} required autoFocus />
                                </div>
                            </div>
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name" className="required">{strings.Country}</label>

                                    <Dropdown id="state" value={this.state.supplier.countryCode??countryCodes[0]} onChange={(e) => {
                                        let supplier = { ...this.state.supplier }
                                        supplier['countryCode'] = e.target.value;
                                        this.setState({ supplier });
                                    }} options={countryCodes} filter={true} optionLabel="name" placeholder={strings.Select}></Dropdown>

                                    {/* <InputText id="state" value={this.state.supplier.countryCode} defaultValue={getCountry()} disabled required autoFocus /> */}
                                </div>
                            </div>
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name" className="required">{strings.PostalCode}</label>
                                    <InputText id="postalCode" value={this.state.supplier.postalCode} onChange={(e) => this.onInputChange(e, 'postalCode')} required autoFocus />
                                </div>
                            </div>

                        </div>
                        <Accordion>
                            <AccordionTab header={<React.Fragment><i className="pi pi-globe"></i><span>&nbsp;{strings.Identities}</span></React.Fragment>}>
                                <div className="card">

                                    <div style={{ display: 'flex' }}>
                                        <h4>{strings.Identities}</h4>
                                        <Button icon="pi pi-plus" style={{ marginLeft: '10px', marginTop: '-7px' }} onClick={this.AddRow} className="p-button-rounded p-button-success p-mr-2" />
                                        {/* <Button icon="pi pi-" style={{ marginLeft: '10px', marginTop: '-7px' }} onClick={this.GetData} className="p-button-rounded p-button-success p-mr-2" /> */}
                                    </div>

                                    <DataTable value={this.state.supplier.rows}
                                        stripedRows
                                        emptyMessage={strings.Norecordsfound}>
                                        <Column field="name" header={strings.Name} body={this.nameBodyTemplate} ></Column>
                                        <Column field="value" header={strings.Value} body={this.valueBodyTemplate}></Column>
                                        <Column header={strings.Action} body={this.actionBodyTemplate}></Column>
                                    </DataTable>
                                </div>
                            </AccordionTab>
                        </Accordion>
                        <hr/>

                        <div className="p-field">
                            {this.state.errors?.length > 0 && <Message text={this.state.errors.map((m) => <li>{m}</li>)} severity="error" />}
                            <Button label={strings.Save} loading={this.state.loading} onClick={this.save} loadingOptions={{ position: 'right' }} />
                        </div>

                    </div>
                </div>
            </div>

        );
    }
}
const mapStateToProps = (state) => ({
    suppliers: state.suppliers
})

const mapDispatchToProps = (dispatch) => {
    return {
        addSupplier :a =>  dispatch(addSupplier(a))

    }
}
export default connect(mapStateToProps,mapDispatchToProps)(SupplierForm)
