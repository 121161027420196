const en = {
    signinMessage: "Please use the form to sign-in to ARMS EInvoicing platform",
    customProperties: "Additional Information",
    calculated: "Included in calculation",
    quotationNumber: "Quotation Number",
    notCalculated: "Not included in calculation",
    automaticReconciliationWarning: "Based on your company configuration, this payment will be automically reconciled.",
    advancedAmount: "Advanced Amount",
    clearInvoice: "Clear Invoice",
    TotalAmountsInWords: "Total Amount In Words",
    inviteMoreEarnMore: "Invite More, Earn More",
    referrAFriend: "Refer a friend and save money",
    wallet: "Wallet",
    walletDescription: "You can redeem your wallet credit on your subscription renewal.",
    walletBalance: "Wallet Balance",
    promotionCode: "Promo Code",

    documentDiscount: "Document Level Discount/Penalty",
    complianceStatus: "Zatca Status",
    cleared: "Cleared",
    reported: "Reported",
    generateCSID: "Generate CSID",
    generateCSIDText: "Final Step: Generate CSID to be able to clear and report invoices",
    zatcaOnboardingIsDisabled: "Zatca Onboarding is not enabled yet for this company, please contact support to start the onboarding process.",
    ConfirmOtpText: "Are you sure to generate the ZATCA compliance certificates?",
    reportInvoice: "Report Invoice",
    performComplianceChecks: "Perform Compliance Checks on Sample Invoices",
    StartOnboarding: "Start ZATCA Onboarding",
    PreComplianceCertificates: "Generate Pre-Compliance Certificates",
    performComplianceChecksText: "Before proceeding with CSID generation, Zatca requires the submission of sample invoices for compliance checks. Our system will automatically generate sample invoices based on your company's data and perform the necessary checks. If the results are satisfactory, you may proceed with CSID generation. If any issues are found, please try to resolve them or contact our support team for assistance.",
    PreComplianceCertificatesText: "Step 1: Generate CSR, Private and Public Key Pair, and Compliance CSID.",
    purgeWarning: "Warning: by checking this option, all certificates will be re-initialized again",
    GenerateCertificates: "Generate ZATCA Certificates",
    automaticZatcaIntegration: "Push invoices to ZATCA automatically.",
    signInvoice: "Sign Invoice",
    signInvoiceInProgress: "Signing Invoice In Progress",
    checkComplianceStatus: "Check Compliance Status",
    manualReconciliationWarning: "Based on your company configuration, you should reconcile this payment manually. ",
    zatcaEffectiveDate: "Zatca Integration Effective Date",
    purgeCerts: "Purge existing certificates",
    SyncCertificatesText: "Are you sure to sync ZATCA certificates? if everything is working correctly for you, please avoid the sync operation.",
    syncSuccess: "Synced certificates successfully!",
    syncCerts: "Sync Certificates",
    certsNotInitialized: "Certificates are not initialized",
    isRealized: "Realized",
    certificateStatus: "Certificates Status",
    ZatcaVatReports: "Zatca Vat Reports",
    totalInSar: "Total (SAR)",
    correctionsInSar: "Corrections (SAR)",
    totalVatInSar: "Total VAT (SAR)",
    zatcaPoint1: "Standard rate sales",
    zatcaPoint2: "Sales to customers in GCC countries that apply VAT",
    zatcaPoint3: "Zero-rated domestic sales",
    zatcaPoint4: "Exports",
    zatcaPoint5: "Exempted Sales",
    zatcaPoint6: "Total Sales",
    zatcaPoint7: "Purchases subject to the standard rate",
    zatcaPoint8: "Imports subject to VAT paid at customs",
    zatcaPoint9: "Imports subject to VAT to which the reverse charge mechanism applies",
    zatcaPoint10: "Zero-rate purchases",
    zatcaPoint11: "Exempted Purchases",
    zatcaPoint12: "Total Purchases",
    zatcaPoint13: "Total value added tax due for the current tax period",
    zatcaPoint14: "Corrections from previous periods (between 5000 +- SAR)",
    pnlReports: "Profit and Loss (P&L) Reports",
    Account: "Account",
    convertPurchaseOrderToInvoice: "Convert to Invoice",
    realizedMessage: "Net Income = Payments - Supplier Payments (purchases) - Expenses",
    nonRealizedMessage: "Net Income = Invoices - Purchase Orders - Expenses",
    ManageExpenses: "Manage Expenses",
    downloadPDFPurchaseOrder: "Download PDF",
    draft: 'Draft',
    sendOtpTo: "Send OTP to {otpTo}",
    pickInvoices: 'Pick Invoices',
    reconcilePayment: "Reconcile Payment",
    relatedInvoices: "Related Invoices",
    reconciledAmount: "Reconciled Total",
    reconciliationStatus: "Reconciliation Status",
    ManageSuppliers: "Manage Suppliers",
    active: "Active",
    ManagePayments: "Manage Payments",
    paymentTypes: "Payment Types",
    inactive: "Inactive",
    ConfirmQuotation: "Confirm Quotation",
    convertQuotationToInvoice: "Convert To Draft Invoice",
    PaymentStatus: "Payment Status",
    notPaid: "Not Paid",
    fullyPaid: "Fully Paid",
    partiallyPaid: "Partially Paid",
    expenseCreatedSuccessfully: "Expense got created successfully",
    purchaseOrderNumber: "Purchase Order Number",
    termsAndConditions: "Terms and Conditions",
    purchaseOrder: "Purchase Order",
    amountFrom: "Amount From",
    amountTo: "Amount To",
    expenseTypes: "Expense Types",
    amount: "Amount",
    downloadPDFQuotation: "Download PDF",
    ExpenseType: "Expense Type",
    automaticReconciliation: "Automatic Payments Reconciliation",
    Otp: "ZATCA's OTP",
    percentage: "Percentage %",
    starthere: "Start Here",
    checkDraftInvoice: "View Linked Invoice",
    quotation: "quotation",
    Compliance: "Zatca Compliance",
    companyStart: "Let's start with creating your company's profile!",
    companyDescription: "Company is ",
    ip: "IP Address ",
    Setup: "Company Creation Process",
    end: "End",
    includedInTax: "Included In Tax",
    customerStart: "Let's create your first customer!",
    continue: "Continue >",
    productStart: "Let's create your first product!",
    demoEnd: "You're set to go! you can now create compliant invoices easily!",
    lastLogin: "Last Login",
    includeInTax: "Include In Tax",
    goToOtherAmounts: "Show prepaid, retention and recovery amounts",
    changeScope: "Environment",
    EXPIRED_OTP: "OTP is expired, please try again.",
    updateaccount: "Update Profile",
    calculate: "Include in calculation",
    fillOtp: "Please fill received 4 digits One Time Password OTP: ",
    ImportFromExcel: "Import From Excel",
    errorWhileExportingToExcel: "Error while exporting to excel, please try again..",
    advanced: "Advanced Options",
    export: "Export",
    supplier: "Supplier",
    suppliers: "Suppliers",
    OTP_NOTFOUND: "Invalid OTP, please try again.",
    exportMonthlyReport: "Export detailed monthly report",
    NO_RESULTS_FOUND: "No results found, try another month",
    fileIsReady: "File is ready ",
    downloadHere: "Download here",
    downloadPdf: "Export to PDF",
    RetentionPercentage: "Retention Percentage",
    reports: "Reports",
    notifications: "Notifications",
    vatReport: "VAT Report",
    taxInvoices: "Tax Invoices",
    productsImportSteps: "Import Steps",
    pickMonth: "Pick Month",
    ReachedMaxVendorCapacity: "Cannot create company, reached max companies per subscription limit",
    productStep1: "Download sample from",
    productStep2: "Fill excel sheet with your products.",
    productStep3: "Pick the company and branch",
    productStep4: "Upload the file.",
    productStep5: "Customers will be uploaded in the background.",
    customerStep1: "Download sample from",
    customerStep2: "Fill excel sheet with your customers.",
    customerStep3: "Pick the company",
    customerStep4: "Upload the file.",
    customerStep5: "Customers will be uploaded in the background.",
    invoiceStep1: "Download sample from",
    invoiceStep2: "Fill excel sheet with your invoices.",
    invoiceStep3: "Pick the company",
    invoiceStep4: "Upload the file.",
    invoiceStep5: "Invoices will be uploaded in the background.",

    from: "From",
    to: "to",
    simplifiedInvoices: "Simplified Invoices",
    creditNotes: "Credit Notes (Refund)",
    "totalCustomersCount": "Total Customers Count",
    "totalGeneratedInvoices": "Total Generated Invoices",
    "uniqueUsersCount": "Unique Users Count",
    debitNotes: "Debit Notes",
    invoiceCount: "Invoice Count",
    remaining: "Remaining",
    used: "Used",
    invoiceTotalAmount: "Total Amount Tax Incl.",
    invoiceVatTotal: "VAT Total",
    downloadSample: "Download Sample",
    selectFile: "Select File",
    productCategories: 'Product Categories',
    dropFileHere: 'Drag and drop file to here to upload.',
    pickPlan: 'Pick Plan',
    admin: 'Administration',
    unlimitedCompanies: 'Unlimited Companies',
    unlimitedCustomers: 'Unlimited Customers',
    unlimitedProducts: 'Unlimited Products',
    invoicing: 'Invoicing',
    seperateSequencePerType: "Seperate Invoice Sequence Per Type (Tax, Credit, Debit)",
    productCategory: "Product Category",
    selectCompanies: "Select User Companies",
    totalAmountDue: 'Total Amount Due',
    taxExclusive: "Tax Exclusive",
    planInvoices: 'invoices',
    unlimitedUsers: "Unlimited users for subscription",
    dashboardAccess: "24/7 online access",
    askForQuote: "Ask For Quote (More than 1000 monthly invoices)",
    pricingPlans: "Pricing Plans",
    startingFrom: 'Starting from {annualPrice} {currency}',
    image: 'Image',
    signupMessage: 'New here? buy a subscription by clicking ',
    youCanChangeDropdownContent: 'You can type your own names/labels by typing in the dropdowns.',
    showOnPdf: 'Show On Pdf',
    totalVat: 'Total VAT',
    ClickOrDropHereToUploadProductImage: 'Click Or Drop Here To Upload Product Image',
    addLine: 'New Line',
    FileHistory: 'File History',
    calculatedWhenConfirmed: 'Calculated on confirmation',
    file: 'File',
    searchByProductNumber: "Search by product number..",
    status: 'Status',
    date: 'Date',
    "generation-1": 'Generated',
    "generation-2": 'Not Started',
    "generation-3": 'Failed',
    "generation-4": 'Not Generated',
    updatedAt: "Updated At",
    updatedBy: 'Updated By',
    createdAt: "Created At",
    taxableAmount: 'Total Taxable Amount (Excluding VAT)',
    key: 'key',

    discount: 'Discount',
    invoiceTotal: 'Total (Excluding VAT)',
    welcome: "Welcome",
    CreatedBy: "Created By",
    branchSerial: "Branch Serial",
    SaveDraftToShowTotals: "Save Draft To Calculate Totals",
    copyId: "Copy Id",
    removeFromSubscription: "Remove from subscription",
    ExternalInvoiceNumberAlreadyExist: "External Number Already Exist",
    isSimpleAddress: "Simple Address",
    SubscriptionNotFoundException: "Subscription Not Found..",
    CreditNoteViolation: "Credit note should be greater or equal to the original invoice.",
    BillingReferenceId: "Billing Reference Id",
    OutOfInvoiceBalance: "We're sorry, but you are out of invoices balance, please contact support to renew your subscription.",
    InvoiceValidationError: "Invoice Validation Error",
    ViolatedInvoiceBalance: "Invoice balance violition, subscription balance is less than 0",
    startDate: "Start Date",
    filters: "Filters",
    deleteProduct: "Delete Product",
    isLetterStyle: "Letter Style",
    pdfHasBorders: "Show borders",
    invoiceNumberPrefix: "Invoice Number Prefix",
    prepaidAmountDate: "Prepaid Amount Date",
    taxInclAmount: "Tax Inclusive Amount",
    dates: "Dates",
    VendorVAT: "Company VAT Number",
    productFilterSearchPlaceHolder: "Name ,Price, Peppol or Product Number...",
    Retention: "Retention",
    activate: "Activate",
    deactivate: "Deactivate",
    subscriptionInactive: "Subscription has expired or cancelled.",
    ProductNameRequired: "Please enter either arabic or english product name",
    filterSearchPlaceHolder: "Name, VAT Number, Phone or Email...",
    prepaidAmount: "Prepaid Amount",
    customization: "PDF Customization",
    DeleteVendor: "Delete Vendor",
    currencyRates: "Currency Rates",
    baseCurrency: "Base Currency",
    quoteCurrency: "Quote Currency",
    USD: "US Dollar",
    EUR: "Euro",
    SAR: "Saudi Riyal",
    BHD: "Bahrini Dinar",
    rate: "Rate",
    DeleteCustomer: "Delete Customer",
    newVersionIsRealeased: "New application version is realeased!",
    attention: "Attention",
    updateApp: "Update Application",
    emailValidationError: "Email validation error",
    reason1: "Cancellation or suspension of the supplies after its occurrence either wholly or partially",
    reason2: "In case of essential change or amendment in the supply, which leads to the change of the VAT due",
    reason3: "Amendment of the supply value which is pre-agreed upon between the supplier and consumer",
    reason4: "In case of goods or services refund.",
    Send: "Send",
    details: 'Details',
    TotalCalcNote: "Totals will be calculated only when saving the invoice.",
    pdf: "Pdf",
    xml: "Xml",
    both: "Both",
    SendToEmail: "Send to email",
    internalServerError: "Internal Server Error",
    InternalServerError: "Internal Server Error",
    openSupportRequest: "Contact Support",
    here: "here",
    issueTitle: 'Issue Title',
    emailSent: "Email was sent successfully!",
    help: "Help",
    problemDescription: "Problem Description",
    submit: "Submit",
    title: 'Title',
    Attachments: 'Attachments',
    userIsAlreadyActive: 'User is already active or not found, if you are unable to login to your account, try reseting your password by clicking',
    saveDraft: "Save Draft",
    pickSubscription: "Pick Subscription",
    simplified: 'Simplified E-Invoice',
    resetPassword: 'Reset Password',
    accessRole: 'Access Role',
    evat: 'E-Vat Invoice',
    description: 'Description/Related ID',
    subscription_user: 'Normal Admin (Generate Invoices, Manage Products, Manage customers)',
    subscription_data_entry: 'Data Entry Admin (Draft Invoices, Manage Products, Manage customers)',
    subscription_admin_ro: 'Read only admin',
    subscription_admin: 'Subscription Admin (Full)',
    subscription_pos_user: 'POS User - used for POS system only',
    activityLogs: 'Audit Logs',
    actionStatus1: 'Success',
    actionStatus2: 'Failed',
    actionStatus3: 'Permission Denied',
    actionStatus4: 'Unauthorized',
    actionStatus5: 'Unknown',
    ipAddress: 'IP Address',
    thirdParty: 'Third party billed invoice on behalf of supplier',
    selfBilled: 'Self billed',
    headerBgColor: 'Headers Background Color',
    headerFontColor: 'Headers Font Color',
    pdfFontColor: 'Pdf Font Color',
    invoiceFooterContent: 'Invoice Footer Content',
    invoiceHeader1Content: 'Line 1',
    invoiceHeader2Content: 'Line 2',
    invoiceHeader3Content: 'Line 3',
    invoiceHeader: 'Invoice Header',
    summary: 'Summary',
    exports: 'Exports',
    nominal: 'Nominal',
    instrumentNotDefined: '[1] Instrument Not Defined',
    bankTransfer: '[42] Bank Payment',
    creditTransfer: '[30] Credit Transfer',
    inCash: '[10] In Cash',
    creditCard: '[48] Credit Card',
    downloadPdf: "Download PDF Invoice",
    downloadXml: "Download XML Invoice",
    email: "Email",
    forgotpassword: "Forgot Password?",
    sendResetPasswordRequest: "Reset Password",
    Branch: "Branch",
    Branches: 'Branches',
    Serial: 'Serial',
    password: "Password",
    Customers: "Customer",
    subscriptionUsers: 'Subscription Users',
    subscriptions: 'Subscriptions',
    charges: 'Charges',
    users: 'Users',
    CustomerType: 'Customer Type',
    createCreditNote: 'Create Credit Note',
    user: 'User',
    DownloadSelected: 'Download Selected',
    sendActivationEmail: 'Send Activation Email',
    subscriptionName: 'Subscription Name',
    lifetime: 'Subscription period',
    ConfirmCreditNote: 'Confirm Credit Note',
    ConfirmDebitNote: 'Confirm Debit Note',
    InvoiceNote: 'Notes',
    invoices: 'Invoices',
    identityTypes: 'Identity Types',
    subscriptionPlans: 'Subscription Plans',
    taxCategories: 'Tax Categories',
    UserName: 'User Name',
    UserEmail: 'User Email',
    UserPhone: 'User Phone',
    subscriptionPlan: 'Subscription Plan',
    dateTo: 'Date To',
    dateFrom: 'Date From',
    priceFrom: 'Price From',
    priceTo: 'Price To',
    vendors: 'Companies',
    UploadSuccessfull: 'Success',
    ProductsBulkUploadSuccessfull: 'Products was imported successfully!',
    ProductsBulkUploadInProgress: 'Products upload is on progress..',
    ProductsBulkUploadInit: 'Initializing products upload.',
    CustomersBulkUploadInit: 'Initializing customers upload.',
    InvoicesBulkUploadInit: 'Initializing invoices upload.',
    CustomersBulkUploadInProgress: 'Customers upload is on progress..',
    CustomersBulkUploadSuccessfull: 'Customers was imported successfully!',
    InvoicesBulkUploadInProgress: 'Invoices upload is on progress..',
    InvoicesBulkUploadSuccessfull: 'Customers was imported successfully!',
    PdfUploadSuccessfullDetails: 'PDF File was generated successfully!',
    XmlUploadSuccessfullDetails: 'XML File was generated successfully!',
    PdfUploadFailedDetails: 'PDF File was generated successfully!',
    XmlUploadFailedDetails: 'XML File was generated successfully!',
    phone: 'Phone',
    customers: 'Customers',
    Confirmed: 'Confirmed',
    Vendor: 'Company',
    Stash: 'Stashed',
    products: 'Products',
    feedbacks: 'Feedbacks',
    dashboard: 'Dashboard',
    Filter: 'Filter',
    New: "New",
    CreationDate: "Creation Date",
    Phone: "Phone",
    Name: "Name",
    Value: "Value",
    Action: "Action",
    Norecordsfound: "No records found",
    expenseNumber: "Expense Number",
    Save: "Save",
    Cancel: "Cancel",
    ClickorDropheretouploadvendorlogo: "Click or Drop here to upload logo",
    ID: "ID",
    recordPayment: 'Record Payment',
    Confirm: "Confirm",
    Select: "Select",
    SelectCompany: 'Select Company',
    NameEnglish: "Name English",
    NameArabic: "Name Arabic",
    GovEntity: "Government Entity",
    Individual: "Individual",
    Company: "Company",
    City: "City",
    VatNumber: "Vat Number",
    GroupVatNumber: "Group Vat Number",
    NoVendorsFound: "No Companies Found.",
    ManageVendors: "Manage Companies",
    VendorsDetails: "Company Details",
    Street1: "Street",
    AdditionalStreet: "Additional Street",
    BuildingNo: "Building No",
    AdditionalNumber: "Additional Number",
    PostalCode: "Postal Code",
    UserInvoiceNumber: "External Number",
    State: "State",
    JoinNow: "Subscribe now",
    Country: "Country",
    Subject: "Subject",
    AttentionText: "Attention Text",
    Neighborhood: "Neighborhood",
    Logo: "Logo",
    Identities: "Identities",

    ManageCustomers: "Manage Customers",
    CustomerDetails: "Customer Details",

    Price: "Price",
    DiscountPercentage: "Discount Percentage",
    ProductNumber: "Product Number",
    PEPPOL: "PEPPOL",
    UnitCode: "Unit Code",
    TaxCategories: "Tax Categories",
    ManageProducts: "Manage Products",


    SubscriptionID: "Subscription ID",
    Invoicesbalance: "Invoices balance",
    CurrentSubscriptionPlan: "Current Subscription Plan",
    Enddate: "End date",
    CustomersCount: "Customers Count",
    Comingsoon: "Coming soon",
    VendorsCount: "Companies Count",
    Gotoinvoices: " Go to invoices > ",
    SeptemberInvoicesTotal: "SeptemberInvoicesTotal",
    ManageQuotations: "Manage Quotations",
    ManageInvoices: "Manage Invoices",
    Number: "Number",
    Customer: "Customer",
    IssueDate: "Issue Date",
    Company: "Company",
    CustomerNotFound: "Customer not found",
    TaxExcluded: "Tax Excluded",
    Total: "Total",
    Status: "Status",
    InvoiceType: "Invoice Type",
    TransactionType: "Transaction Type",
    Specialbillingagreement: "Special billing agreement",
    PaymentMean: "Payment Mean",
    IssueDate: "Issue Date",
    Currency: "Currency",
    SupplyDate: "Supply Date",
    PaymentConditions: "Payment Conditions",
    SupplyEndDate: "Supply End Date",
    PurchaseNumber: "Purchase Number",
    Customer: "Customer",
    selectVendorsToShowCustomers: "Select company to show customers",
    DueDate: "Due Date",
    CustomerVATNumber: "Customer VAT Number",
    GroupVATRegistrationNumber: "Group VAT Registration",
    Createnewinvoice: "Create new invoice",
    SaveDraft: "Create new invoice",
    Lines: "Lines",
    invoice: 'Invoice',
    createNew: 'Create New',
    edit: 'Edit',
    Product: "Product",
    Label: "Label",
    Quantity: "Quantity",
    Price: "Price",
    DISC: "DISC",
    Taxes: "Taxes",
    TaxAmount: "Tax Amount",
    Subtotal: "Subtotal",
    Total: "Total",
    Action: "Action",
    Generalinfo: "General info",
    QRCode: "QR Code",
    Otherinfo: "Other info",
    InvoiceNumber: "Invoice Number",
    InvoiceTypeCode: "Invoice Type Code",
    InvoiceTransactionCode: "Invoice Transaction Code",
    InvoicePaymentType: "Invoice Payment Type",
    InvoiceCounterValue: "Invoice Counter Value",
    UUID: "UUID",
    PreviousInvoiceHash: "Previous Invoice Hash",
    ReferenceHashInvoiceNumber: "Reference Hash Invoice Number",
    Areyousureyouwanttodeletedraft: "Are you sure you want to delete draft",
    Areyousureyouwanttoproceed: "Are you sure you want to proceed",
    success: "success",
    error: "Error",
    CannotDeleteInvoice: "Cannot Delete Invoice",
    DeleteDraftInvoice: "Delete Draft Invoice",
    Deleteddraftsuccessfully: "Deleted draft successfully",
    No: "No",
    Yes: "Yes",
    ConfirmInvoice: "Confirm Invoice",
    PDFisnotreadyyetrefreshandtryagain: "PDF is not ready yet, refresh and try again",
    Refresh: "Refresh",
    DeleteDraft: "Delete Draft",
    InstructionNote: "Instruction Note",
    Base64Value: "Base64 Value",
    Selectvendortoaddinvoicelines: "Select company to add invoice lines",
    DebitNote: "Debit Note",
    Reason: "Reason",
    CreditNote: "Credit Note",
    productType: 'Product Type',
    service: 'Service',
    consumable: 'Consumable',
    comments: 'Comments',
    payments: 'Payments',
    payment: 'Payment',
    pay: 'Pay',
    makePayment: 'Make Payment',
    quotations: 'Quotations',
    amount: 'Amount',
    close: 'Close',
    userManual: 'User Manual',
    Recovery: 'Recovery',
    RecoveryPercentage: 'Recovery Percentage',
    selectPaymentType: 'Select Payment Type',
    noAccount: 'no accounts yet, add one',
    selectAccount: 'Select Account',
    selectIssuer: 'Select Issuer',
    noIssuer: 'No Issuer, add one',
    addIssuer: 'Add Issuer',
    addAccount: 'Add Account',
    referenceNumber: 'Reference Number',
    paymentType: 'Payment Type',
    confirmDelete: 'Are you sure you want to delete?',
    deleteConfirmation: 'Confirm Delete',
    refund: 'Refund',
    deletePayment: 'Delete Payment',
    deletedSuccessfully: 'Deleted successfully',
    updatePayment: 'Update Payment',
    paymentUpdated: 'Payment has been updated successfully',
    addPayment: 'Add Payment',
    paymentAdded: 'Payment has been added successfully',
    delete: 'Delete',
    category: 'Category',
    selectSupplier: 'Select Supplier',
    addQuotation: 'Add Quotation',
    refunds: 'Refunds',
    purchasedOrders: 'Purchase Orders',
    expenses: 'Expenses',
    purchases: 'Purchases',
    sales: 'Sales',
    netIncome: 'Net Income',
    addOrder: 'Add Order',
    orderNumber: 'Order Number',
    autoGenerated: 'Auto Generated',
    businessExpense: 'Business Expense',
    proOrSerExpense: 'Product Or Service Purchase',
    officeEquipment: 'Office Equipment Purchase',
    selectSupplierText: 'Select supplier to choose products',
    successfullyUpdated: 'Successfully Updated',
    successfullyAdded: 'Successfully Added',
    adding: 'Adding',
    updating: 'Updating',
    subscriptionManage: 'Manage Subscription',
    renew: 'Renew Subscription',
    plans: 'Plans',
    startedDate: 'Started Date',
    expiry: 'Expiry Date',
    invoicesLeft: 'Invoices Left',
    cancelSub: 'Cancel Subscription',
    invoicePerYear: 'Invoices / Yr',
    forOneYear: '1 year',
    forThreeYears: '3 years',
    checkoutBody: () => <p> For bank transfer please make a payment to:
        <br />
        Bank: Al Inma bank
        <br />
        - Account Number: 68208959595000
        <br />
        - IBAN: SA4505000068208959595000
        <br />
        - Account Holder: ARMS For Information Technologies - مؤسسة ارمس لتقنية المعلومات.
        <br />
        Please mention your business name, your vat number and the email address you want to sign up as Admin
        Reach us by email: sales@armsit.com </p>,
    checkoutHead: 'Checkout',
    checkout: 'checkout',
    confirmSubCancel: 'Are you sure you want to cancel the subscription?',
    confirmSubCancelHead: 'Subscription Cancellation',
    validSubMsg: 'your subscription is still valid, however you can renew or upgrade any time.',
    onlinePayment: 'Online payment',
    bankTransfer: 'Bank transfer',
    quotaSuccess: "Quota request submitted successfully.",
    quotaSuccessMessage: "Our team will reach you as soon as possible",

    selectAnother: 'Select Another Plan',
    chargeId: "Payment ID",
    bankTransferInfo: 'Bank transfer information',
    direction: 'ltr',
    renewal: 'Renewal',
    paymentFailure: "Payment failure",
    paymentFailureMessage: "We couldn't complete your purchase order, please try again, and ensure your credit card info is correct.",
    paymentSuccess: "Payment success",
    paymentSuccessMessage: "You subscription will be activated soon!",

    successRenewal: 'Your subscription has been renewed successfully'
}

export default en;
