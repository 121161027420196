import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import Styled from "styled-components";

import { useParams } from "react-router-dom";
import UserService from '../service/UserService';
import strings from '../localization';

export const RegisterListerners = ({context,invoice, toast, updateInvoice}) => {


    return (
        <></>
    )
}

