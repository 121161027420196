import Payments from "./Payments";
import Payment from "./Payement";
import ExpensePayment from "./ExpensePayment"
import EditPayment from "./EditPayment";

const routes = [
    { path: '/profile/purchase/payments', component: Payments, perform: 'payment:purchase:route' , exact: true },
    { path: '/profile/purchase/payments/new', component: Payment, perform: 'payment:purchase:create' , exact: true },
    { path: '/profile/purchase/payments/expense/:id', component: ExpensePayment, perform: 'payment:purchase:create' , exact: true },
    { path: '/profile/purchase/payments/edit/:id', component: EditPayment, perform: 'payment:purchase:update' , exact: true }
]
export default routes
