import { Route } from "react-router-dom";
import Access from "./pages/Access";
import InactiveSubscription from "./pages/InactiveSubscription";
import rules from "./rbac-rules";
import UserService from "./service/UserService";

export const check = (rules, role, action, data = {}) => {
    const permissions = rules[role];

    let inactiveSub = localStorage.getItem('inactiveSub');

    if (inactiveSub)
        return false;

    if (!permissions) {
        // role is not present in the rules
        return false;
    }
    const staticPermissions = permissions.static;
    if (staticPermissions && staticPermissions.includes(action)) {
        // static rule not provided for action
        return true;
    }

    const dynamicPermissions = permissions.dynamic;

    if (dynamicPermissions) {
        const permissionCondition = dynamicPermissions[action];
        if (!permissionCondition) {
            // dynamic rule not provided for action
            return false;
        }

        return permissionCondition(data);
    }
    return false;
};


const Can = props => {
    let role = new UserService().GetRole();
    return check(rules, props.role ?? role, props.perform, props.data)
        ? props.yes()
        : props.no(props);

}

Can.defaultProps = {
    yes: () => null,
    no: (props) => {
        var context = props.context;
        return props.silent ? '' : <Route path={props.path} exact component={(p) => localStorage.getItem('inactiveSub') == 'true' ? <InactiveSubscription {...p} context={context} /> : <Access {...p} context={context} />} />
    }
};

export default Can;
