import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import SubscriptionPlanService from '../service/SubscriptionPlanService';
import { Link, Redirect, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Checkbox } from 'primereact/checkbox';
import {getCurrency} from '../service/helpers'

export const SubscriptionPlans = () => {

    let emptyPlan = {
        "monthlyPrice": 0,
        "annualPrice": 0,
        "threeYearsPrice": 0,
        "itemsThreshold": 0,
        "discount": 0,
        "taxPercent": 15,
        "currency": getCurrency(),
        "trialPeriodInDays": 0,
        "interval": 1,
        "translations": [
          {
            "title": "",
            "description": "",
            "locale": 1
          },
          {
            "title": "",
            "description": "",
            "locale": 1
          }
        ],
        "isActive": true,
      }

    const [subscriptionPlans, setSubscriptionPlans] = useState(null);
    const [subscriptionPlanDialog, setSubscriptionPlanDialog] = useState(false);
    const [deleteSubscriptionPlanDialog, setDeleteSubscriptionPlanDialog] = useState(false);
    const [deleteSubscriptionPlansDialog, setDeleteSubscriptionPlansDialog] = useState(false);
    const [subscriptionPlan, setSubscriptionPlan] = useState(emptyPlan);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [selectedSubscriptionPlans, setSelectedSubscriptionPlans] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        onPage({rows,page:0})
        // const subscriptionPlanService = new SubscriptionPlanService();
        // subscriptionPlanService.GetSubscriptionPlans(rows).then(({data,count}) => {
        //     setTotalRecords(count);
        //     setSubscriptionPlans(data)
        // });

    }, []);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }


    let statuses = [null, "Awaiting Activation",
        "Trialing",
        "Active",
        "PastDue",
        "Canceled"
    ]

    const openNew = () => {
        setSubscriptionPlan(emptyPlan);
        setSubmitted(false);
        setSubscriptionPlanDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setSubscriptionPlanDialog(false);
    }

    const hideDeleteSubscriptionPlanDialog = () => {
        setDeleteSubscriptionPlanDialog(false);
    }

    const hideDeleteSubscriptionPlansDialog = () => {
        setDeleteSubscriptionPlansDialog(false);
    }





    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        // return (
        //     // <React.Fragment>
        //     //     <Button label="Export" icon="pi pi-upload" className="p-button-help"  />
        //     // </React.Fragment>
        // )
    }



    const IDBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.uuid}
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    }


    const emailBodyTemplate = (rowData) => {
        return (
            <Link to={`/profile/users?id=${rowData.user.uuid}`}>
            <span className="p-column-title">Email</span>
            {rowData.user.email}
            </Link>

        );
    }

    const monthlyPriceTemplate = (rowData) => {
        if(rowData.monthlyPrice == -1) return 'Ask For Quote'
        return (
            <>
                <span className="p-column-title">Monthly Price</span>
                {rowData.monthlyPrice} {rowData.currency}
            </>
        );
    }
    const yearlyPriceTemplate = (rowData) => {
        if(rowData.annualPrice == -1) return 'Ask For Quote'
        return (
            <>
                <span className="p-column-title">End date</span>
                {rowData.annualPrice} {rowData.currency}
            </>
        );
    }
    const threeYearsPriceTemplate = (rowData) => {
        if(rowData.threeYearsPrice == -1) return 'Ask For Quote'
        return (
            <>
                <span className="p-column-title">End date</span>
                {rowData.threeYearsPrice} {rowData.currency}
            </>
        );
    }
    const startDateTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Start date</span>
                {moment(rowData.start).format('DD/MM/YYYY, h:mm:ss a')}
            </>
        );
    }

    const dateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date</span>
                {moment(rowData.date).format('DD/MM/YYYY, h:mm:ss a')}
            </>
        );
    }
    const arabicLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Title [AR]</span>
                {rowData.translations[1]?.title}
            </>
        );
    }
    const monthlyBalanceTemplate = (rowData) => {
        if(rowData.itemsThreshold == -1) return 'Ask For Quote'
        return (
            <>
                <span className="p-column-title">Title [AR]</span>
                {rowData.itemsThreshold} invoices
            </>
        );
    }

    const englishLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Title [EN]</span>
                {rowData.translations[0]?.title}
            </>
        );
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon={`pi pi-${rowData.status == 3 ? 'times' : 'check'}`} className={`p-button-rounded p-button-${rowData.status == 3 ? 'danger' : 'success'} p-mr-2`} onClick={async () => {
                    setSubscriptionPlan(rowData);
                    setSubmitted(false);
                    setSubscriptionPlanDialog(true);
                }}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={async () => {
                    var subscriptionPlanService = new SubscriptionPlanService();
                    var data = await subscriptionPlanService.DeletePlan(rowData.uuid);
                    if(data.errorCode) {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: data.message, life: 3000 });
                    } else {
                        onPage({rows,page});
                    }
                }} />
            </div>

        );
    }

    const onPage = ({ first,
        rows,
        page,
        pageCount }) => {
        const subscriptionPlanService = new SubscriptionPlanService();
        setLoading(true);
        setRows(rows);
        setPage(page);
        setFirst(first)

        subscriptionPlanService.GetSubscriptionPlans(rows, (page) * rows).then(({ data, count }) => {
            setLoading(false);
            setSubscriptionPlans(data);
            setTotalRecords(count);
        });

    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Subscription Plans</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" placeholder="Search by Name..." />

            </span>
        </div>
    );
    const saveSubscriptionPlan = async () => {
        setSubmitted(true);

        var subscriptionPlanService = new SubscriptionPlanService();
        if (subscriptionPlan.uuid) {
            await subscriptionPlanService.UpdateSubscriptionPlan({...subscriptionPlan,
                "translations": [
                    {
                        "title": subscriptionPlan.translations[0].title,
                        "description": subscriptionPlan.translations[0].description,
                        "locale": 1
                    },
                    {
                        "title": subscriptionPlan.translations[1].title,
                        "description": subscriptionPlan.translations[1].description,
                        "locale": 2
                    }
                ],
            });
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'SubscriptionPlan Updated', life: 3000 });
        }
        else {
            await subscriptionPlanService.CreateSubscriptionPlan(subscriptionPlan);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'SubscriptionPlan Created', life: 3000 });
        }
        onPage({ rows, page })
        // setSubscriptionPlans(_subscriptionPlans);
        setSubscriptionPlanDialog(false);
        setSubscriptionPlan(emptyPlan);
    }
    const subscriptionPlanDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveSubscriptionPlan} />
        </>
    );

    const onInputChange = (e, name) => {
        let _subscriptionPlan = { ...subscriptionPlan };
        if(name == 'homepageEnabled')
        {
            _subscriptionPlan[`${name}`] = !_subscriptionPlan.homepageEnabled;
        }
        else
        {
            const val = (e.target && e.target.value) || '';
            _subscriptionPlan[`${name}`] = val;
        }

        setSubscriptionPlan(_subscriptionPlan);
    }

    const onTranslationChange = (e, locale) => {
        const val = (e.target && e.target.value) || '';
        let _subscriptionPlan = { ...subscriptionPlan };
        _subscriptionPlan.translations[locale-1][e.target.id] = val;
        setSubscriptionPlan(_subscriptionPlan);
    }



    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt}
                               value={subscriptionPlans}
                               selection={selectedSubscriptionPlans}
                               dataKey="id"
                               totalRecords={totalRecords}
                               lazy
                               first={first}
                               loading={loading}
                               onPage={onPage}
                               autoLayout={true}
                               paginator rows={rows}
                               rowsPerPageOptions={[5, 10, 25]}
                               className="datatable-responsive"
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} SubscriptionPlans"
                               globalFilter={globalFilter}

                               emptyMessage="No subscriptionPlans found."
                               header={header}>

                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}

                        {/* <Column field="subscriptionPlan_plan"  header="Plan" body={SubscriptionPlan_palnBodyTemplate}></Column> */}
                        <Column field="titleAr" header="Title [AR]"  style={{width: "250px"}} body={arabicLabelTemplate}></Column>
                        <Column field="titleEn" header="Title [EN]"body={englishLabelTemplate}></Column>
                        <Column field="monthlyPrice" header="Monthly Price" body={monthlyPriceTemplate}></Column>
                        <Column field="annualPrice" header="Annual Price" body={yearlyPriceTemplate}></Column>
                        <Column field="threeYearsPrice" header="Three Years Price" body={threeYearsPriceTemplate}></Column>
                        <Column field="monthlyBalance" header="Monthly Balance" body={monthlyBalanceTemplate}></Column>
                        <Column field="homepageEnabled" header="Home Page Enabled" body={(rowData)=>rowData['homepageEnabled']?'Yes':'No'}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={subscriptionPlanDialog} style={{ width: '450px' }} header="Subscription Plan Details" modal className="p-fluid" footer={subscriptionPlanDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="monthlyPrice">Monthly Price</label>
                            <InputText type="number" id="monthlyPrice" value={subscriptionPlan.monthlyPrice} onChange={(e) => onInputChange(e, 'monthlyPrice')} autoFocus className={classNames({ 'p-invalid': submitted && !subscriptionPlan.name })} />
                            {submitted && !subscriptionPlan.monthlyPrice && <small className="p-invalid">Monthly Price is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="annualBalance">Annual Price</label>
                            <InputText type="number" id="annualPrice" value={subscriptionPlan.annualPrice} onChange={(e) => onInputChange(e, 'annualPrice')} autoFocus className={classNames({ 'p-invalid': submitted && !subscriptionPlan.name })} />
                            {submitted && !subscriptionPlan.monthlyPrice && <small className="p-invalid">Annual Price is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="threeYearsBalance">Three Years Price</label>
                            <InputText type="number" id="threeYearsPrice" value={subscriptionPlan.threeYearsPrice} onChange={(e) => onInputChange(e, 'threeYearsPrice')} autoFocus className={classNames({ 'p-invalid': submitted && !subscriptionPlan.name })} />
                            {submitted && !subscriptionPlan.monthlyPrice && <small className="p-invalid">Three Years Price is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="threeYearsBalance">Invoices per month</label>
                            <InputText type="number" id="itemsThreshold" value={subscriptionPlan.itemsThreshold} onChange={(e) => onInputChange(e, 'itemsThreshold')} autoFocus className={classNames({ 'p-invalid': submitted && !subscriptionPlan.itemsThreshold })} />
                            {submitted && !subscriptionPlan.itemsThreshold && <small className="p-invalid">Invoices per month is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="title">Title [AR]</label>
                            <InputText id="title" value={subscriptionPlan.translations[1]?.title} onChange={(e) => onTranslationChange(e, '2')} autoFocus className={classNames({ 'p-invalid': submitted && !subscriptionPlan.name })} />
                            {submitted && !subscriptionPlan.name && <small className="p-invalid">Label [AR] is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="title">Title [EN]</label>
                            <InputText id="title" value={subscriptionPlan.translations[0]?.title} onChange={(e) => onTranslationChange(e, '1')} autoFocus className={classNames({ 'p-invalid': submitted && !subscriptionPlan.name })} />
                            {submitted && !subscriptionPlan.name && <small className="p-invalid">Label [EN] is required.</small>}
                        </div>
                        <div className="p-field">
                            <Checkbox id="homepageEnabled" name="homepageEnabled" value={subscriptionPlan.homepageEnabled} checked={subscriptionPlan.homepageEnabled} onChange={(e) => onInputChange(e, 'homepageEnabled')} />
                            <label style={{padding:'5px', marginTop:'10px'}} htmlFor="title">Enabled on homepage</label>
                            {/* <InputText id="title" value={subscriptionPlan.homepageEnabled} onChange={(e) => onInputChange(e, 'homepageEnabled')} autoFocus className={classNames({ 'p-invalid': submitted && !subscriptionPlan.name })} /> */}
                            {/* {submitted && !subscriptionPlan.name && <small className="p-invalid">Label [EN] is required.</small>} */}
                        </div>
                        <div className="p-field">
                            <label htmlFor="description">Description [AR]</label>
                            <InputTextarea id="description" value={subscriptionPlan.translations[1]?.description} onChange={(e) => onTranslationChange(e, '2')} autoFocus className={classNames({ 'p-invalid': submitted && !subscriptionPlan.name })} />
                            {submitted && !subscriptionPlan.name && <small className="p-invalid">description [AR] is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="description">Description [EN]</label>
                            <InputTextarea id="description" value={subscriptionPlan.translations[0]?.description} onChange={(e) => onTranslationChange(e, '1')} autoFocus className={classNames({ 'p-invalid': submitted && !subscriptionPlan.name })} />
                            {submitted && !subscriptionPlan.name && <small className="p-invalid">Label [EN] is required.</small>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
