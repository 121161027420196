import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import strings from '../../localization'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Ripple } from 'primereact/ripple';
import { InputText } from 'primereact/inputtext';
import RefundDialog from './RefundDialog'

export const Index = ({ type, data = [], loading, onDelete, onUpdate, count,dialogType }) => {
  const toast = useRef(null);
  const [pageParameters, setPageParameters] = useState({ first: 0, rows: 10, page: 0, pageCount: Math.ceil(count / 10) })
  const [pageInputTooltip, setPageInputTooltip] = useState('Press \'Enter\' key to go to this page.');
  const [currentPage, setCurrentPage] = useState(1);
  const [first1, setFirst1] = useState(0);
  const [editRefund, setEditRefund] = useState({visible: false, item :{}})
  const { first, rows, page, pageCount } = pageParameters
  const dateBody = (options) => {
    return (
      <span>{new Date(options.paymentDate).toLocaleString()}</span>
    )
  }
  const accept = (rowData) => {
    toast.current.show({ severity: 'info', summary: strings.Confirm, detail: strings.deletedSuccessfully, life: 3000 });
    onDelete(rowData.uuid)
  }
  const confirm = (rowData) => {
    confirmDialog({
      message: strings.confirmDelete,
      header: strings.deleteConfirmation,
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => accept(rowData),
      acceptLabel: strings.Yes,
      rejectLabel: strings.No
    });

  };
  const onPageInputChange = (event) => {
    setCurrentPage(event.target.value);
}
  const onPageInputKeyDown = (event, options) => {
    if (event.key === 'Enter') {
        const page = parseInt(currentPage);
        if (page < 1 || page > options.totalPages) {
            setPageInputTooltip(`Value must be between 1 and ${options.totalPages}.`);
        }
        else {
            const first = currentPage ? options.rows * (page - 1) : 0;

            setFirst1(first);
            setPageInputTooltip('Press \'Enter\' key to go to this page.');
        }
    }
}
  const actionBody = option => {
    return (
      <React.Fragment>
        <Button icon="pi pi-trash" className="p-button-rounded p-button-warning p-button-text" title={strings.delete} onClick={() => confirm(option)} />
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-secondary p-button-text" title={strings.edit} onClick={() =>setEditRefund({visible: true, item: option})}/>

      </React.Fragment>
    )
  }
  const columns = [{ field: 'paymentDate', header: strings.date, body: dateBody }, { field: `${type}#`, header: strings[`${type}Number`], body: null }, { field: 'vendor.name', header: strings.Vendor, body: null }, { field: 'paymentType.name', header: strings.paymentType, body: null }, { field: 'amount', header: strings.amount, body: null }, { field: 'action', header: strings.Action, body: actionBody }]
  const onPageHandler = (e) => {
    setPageParameters({...e, pageCount: Math.ceil(count/e.rows)})
      
  }
  const template2 = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
        const dropdownOptions = [
            { label: 5, value: 5 },
            { label: 10, value: 10 },
            { label: 20, value: 20 },
            { label: 50, value: 50 }
        ];

        return (
            <React.Fragment>
                {/* <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </span> */}
                <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
            </React.Fragment>
        );
    },
    'CurrentPageReport': (options) => {
        return (
            <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                {options.first} - {options.last} of {options.totalRecords}
            </span>
        )
    }
};
const template1 = {
  layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
  'PrevPageLink': (options) => {
      return (
          <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
              <span className="p-3">Previous</span>
              <Ripple />
          </button>
      )
  },
  'NextPageLink': (options) => {
      return (
          <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
              <span className="p-3">Next</span>
              <Ripple />
          </button>
      )
  },
  'PageLinks': (options) => {
      if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
          const className = classNames(options.className, { 'p-disabled': true });

          return <span className={className} style={{ userSelect: 'none' }}>...</span>;
      }

      return (
          <button type="button" className={options.className} onClick={options.onClick}>
              {options.page + 1}
              <Ripple />
          </button>
      )
  },
  'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        { label: 5, value: 5 },
          { label: 10, value: 10 },
          { label: 20, value: 20 },
          { label: 50, value: 50 },
          { label: 'All', value: options.totalRecords }
      ];

      return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
  },
  'CurrentPageReport': (options) => {
      return (
          <span className="mx-3" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
              Go to <InputText size="2" className="ml-1" value={currentPage} tooltip={pageInputTooltip}
                  onKeyDown={(e) => onPageInputKeyDown(e, options)} onChange={onPageInputChange}/>
          </span>
      )
  }
};
  return (
    <>
      <h3 style={{marginBottom: '2rem'}}>{strings.refunds}</h3>
      <Toast ref={toast} />
      <RefundDialog type={dialogType} visible={editRefund.visible} item={editRefund.item} onHide={()=> setEditRefund({visible: false, item: {}})} mode='edit' updateRefund={onUpdate}/>
      <DataTable value={data} loading={loading} paginator rows={rows} rowsPerPageOptions={[5, 10, 20, 50]} first={first} onPage={onPageHandler} paginatorTemplate={template1} >
        <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
        {columns.map((column, i) => <Column key={`column${i}`} field={column.field} header={column.header} body={column.body} />)}
      </DataTable>
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Index)