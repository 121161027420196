import { createSlice } from "@reduxjs/toolkit";
import SubscriptionService from "../service/SubscriptionService";
import User from '../service/UserService'

const newUser = new User();
const storedUser =JSON.parse(localStorage.getItem('user'))
const user = createSlice({
    name: 'user',
    initialState : {...storedUser, activeCompany:{}, activeBranch:{}},
    reducers: {
        addUserDetails(state, action){
            return {...state, ...action.payload}
        }
    }
})


export const getActiveCompanyAndActiveBranch = payload => async (dispatch) =>{
        try {
            let company = await new SubscriptionService().GetActiveCompany()
            let branch = await new SubscriptionService().GetActiveBranch()
            dispatch(addUserDetails({activeCompany: company?? null, activeBranch: branch?? null}))
        } catch (error) {
        console.log("🚀 ~ file: user.js ~ line 22 ~ getActiveCompanyAndActiveBranch ~ error", error)
            
        }
}

export const {addUserDetails} = user.actions
export default user.reducer

// const getActiveCompanyAndActiveBranch = payload => async (dispatch) =>{

// }