import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import strings from '../localization';
import SubscriptionService from '../service/SubscriptionService';
import {TreeSelect} from 'primereact/treeselect';

import { RadioButton } from 'primereact/radiobutton';
import { SplitButton } from 'primereact/splitbutton';
import { check } from '../Can';
import rules from '../rbac-rules';
import UserService from '../service/UserService';
import VendorService from '../service/VendorService';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { TableFilters } from './TableFilters';
import { defaultTableConfiguration } from './TableConfiguration';
import { getCountry } from '../service/helpers';

export const SubscriptionUsers = ({subscriptionId}) => {

    let emptyUser = {
        uuid: null,
        name: '',
        email: null,
        phone: null,
    };

    let accessRoleOptions = [
        {
            code: null,
            name: '-'
        }
        , {
            code: 'subscription_admin',
            name: strings.subscription_admin
        }
        , {
            code: 'subscription_user',
            name: strings.subscription_user
        }, {
            code: 'subscription_admin_ro',
            name: strings.subscription_admin_ro
        }, {
            code: 'subscription_data_entry',
            name: strings.subscription_data_entry
        }, {
            code: 'subscription_pos_user',
            name: strings.subscription_pos_user
        }]

    let statusOptions = [
        {
            code: null,
            name: '-'
        }
        , {
        code: 'active',
        name: strings.active
    }
    ,{
        code: 'Inactive',
        name: strings.inactive
    }]

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [user, setUser] = useState(emptyUser);
    const [preFilters, setPreFilters] = useState({});
    const [filters, setFilters] = useState({});
    const [totalRecords, setTotalRecords] = useState(0);
    const [users, setUsers] = useState([]);
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [nodes, setNodes] = useState(null);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [selectedNodeKey3, setSelectedNodeKey3] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        // const productService = new ProductService();
        // productService.getProducts().then(data => setProducts(data));
        onPage({rows,page:0})
    }, []);


    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            setLoading(true);
            onPage({rows,page:0})
        }
    }, [filters]);

    const openNew = () => {
        setUser({
            ...emptyUser,
            sid:subscriptionId,
        });
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setSelectedNodeKey3(null);
        setProductDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }

    const saveProduct = async () => {
        setSubmitted(true);

        var subscriptionService = new SubscriptionService();

        if (selectedNodeKey3 != null) {
            let c = Object.keys(selectedNodeKey3)?.filter(s => s.startsWith("vendor=")).map(s => s.split('=')[1]);
            let b = Object.keys(selectedNodeKey3)?.filter(s => s.startsWith("branch=")).map(s => s.split('=')[1]);

            user.companies = c;
            user.branches = b;
        }

        if (user.uuid) {
            let res = await subscriptionService.UpdateSubscriptionUser(user);
            if (res.errorCode != null) {
                toast.current.show({ severity: 'error', summary: 'Failed', detail: res.message, life: 3000 });
            }
            else {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Created', life: 3000 });
            }
        }
        else {
            let res = await subscriptionService.CreateSubscriptionUser(user);
            if (res.errorCode != null) {
                toast.current.show({ severity: 'error', summary: 'Failed', detail: res.message, life: 3000 });
            }
            else {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Created', life: 3000 });
            }
        }
        onPage({ rows, page })
        // setProducts(_products);
        setProductDialog(false);
        setSelectedNodeKey3(null);
        setUser({
            ...emptyUser,
            sid:subscriptionId,
        });
    }

    const calcNodes = (companies)=>{
        let tree = {root:[]};

        tree.root = companies.map((v,i)=>{
            return {
                "key": `vendor=${v.uuid}`,
                "label": v.name,
                "data": v.uuid,
                "children": v.branches.map((b,y)=>{
                    return {
                        "key": `branch=${b.uuid}`,
                        "label": b.name,
                        "data": b.uuid,
                    }
                })
            }
        })

        return tree;
    }
    const fetchCompanies = async () => {
        var service = new VendorService();
        var vendors = await service.GetVendors(100, 0);
        setNodes(calcNodes(vendors.data));
    }

    const editSubscriptionUser = async (sUser) => {
        setUser({
            email:sUser.user.email,
            name:sUser.user.name,
            sid:subscriptionId,
            phone:sUser.user.phone,
            uuid:sUser.uuid,
            accessRole:sUser.accessRole,
        });

        let selectNodes = {};

        sUser.vendors?.map(v=>{
            selectNodes[`vendor=${v.uuid}`] ={"checked":true,"partialChecked":false};
        });

        sUser.branches?.map(v=>{
            selectNodes[`branch=${v.uuid}`] ={"checked":true,"partialChecked":false};
        });
        setSelectedNodeKey3(selectNodes);

        if(['subscription_pos_user','subscription_user','subscription_data_entry','subscription_admin_ro'].includes(sUser.accessRole))
        {
            if(nodes == null)
            {
                await fetchCompanies();
            }
        }
        else {
            setNodes(null)
        }
        setProductDialog(true);
    }

    const confirmDeleteUser = (product) => {
        setUser({
            ...product,
            sid:subscriptionId??user.sid,
        });
        setDeleteProductDialog(true);
    }

    const deleteProduct = async () => {

        var subscriptionService = new SubscriptionService();
        await subscriptionService.DeleteSubscriptionUser(user.uuid);
        setDeleteProductDialog(false);
        onPage({ rows, page })
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User deleted', life: 3000 });
    }

    const onCategoryChange = async (e) => {
        let _product = { ...user };
        _product['accessRole'] = e.value;
        if(['subscription_pos_user','subscription_user','subscription_data_entry','subscription_admin_ro'].includes(e.value))
        {
            if(nodes == null)
            {
                await fetchCompanies();
            }
        }
        else {
            setNodes(null)
        }
        setUser({
            ..._product,
            sid:subscriptionId
        });
    }

    const onCompanyChange = async (e) => {
        let _product = { ...user };
        _product['companies'] = e.value.map(c=>c.code);
        setUser({
            ..._product,
            sid:subscriptionId
        });
    }

    const onInputChange = (e, name) => {
        let val = (e.target && e.target.value) || '';
        let _user = { ...user };

        if(name=='phone')
        {
            val = e
        }

        _user[`${name}`] = val;

        setUser({
            ..._user,
            sid:subscriptionId,
        });
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label={strings.New} icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        // return (
        //     <React.Fragment>
        //         <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" />
        //         <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
        //     </React.Fragment>
        // )
    }

    const onFiltersChange = (e) => {
        let filters = { ...preFilters };
        filters[e.target.id] = e.target.value;
        setPreFilters(filters);
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.user.name}
            </>
        );
    }


    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.user.email}
            </>
        );
    }

    const phoneBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Phone</span>
                {rowData.user.phone}
            </>
        );
    }

    const accessRoleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reviews</span>
                {rowData.accessRole}
            </>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${rowData.user.activatedAt?'3':'1'}`}>{rowData.user.activatedAt?strings.active:strings.inactive}</span>
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {

        const items = [
            {
                label: strings.edit,
                icon: 'pi pi-pencil',
                command: (e) => {
                    editSubscriptionUser(rowData)
                }
            },
            {
                label: strings.removeFromSubscription,
                icon: 'pi pi-trash',
                command:(e) => {
                    confirmDeleteUser(rowData)
                }
            }
        ]

        items.push({ label: strings.copyId, icon: 'pi pi-copy', command:(e)=>  navigator.clipboard.writeText(rowData.uuid) });

        let first = items.shift();

        return <SplitButton label={first.label} icon={first.icon} onClick={()=>first.command()} model={items}></SplitButton>
    }

    const onPage = ({ first,
        rows,
        page,
        pageCount }) => {
        const subscriptionService = new SubscriptionService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        subscriptionService.GetSubscriptionUsers(rows, (page) * rows, subscriptionId, filters).then(({ data, count }) => {
            setLoading(false);
            setUsers(data);
            setTotalRecords(count);
        });

    }

    const header = (
        <>
            <div className="p-col">

                <div className="table-header">

                    <h5 className="p-m-0">{strings.subscriptionUsers}</h5>
                </div>

            </div>

            <div className="p-col-12">

<TableFilters>
                        <div className="p-fluid p-grid">

                            <div className="p-field p-col-3" style={{ margin: 'auto' }}>

                                <label htmlFor="inputtext">{strings.Name}</label>
                                <InputText id="userName" value={preFilters.userName} onChange={onFiltersChange} />

                            </div>

                            <div className="p-field p-col-2" style={{ margin: 'auto' }}>

                                <label htmlFor="inputtext">{strings.email}</label>
                                <InputText id="email" value={preFilters.email} onChange={onFiltersChange} />
                            </div>
                            <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                                <label htmlFor="inputtext">{strings.phone}</label>
                                <InputText id="phone" value={preFilters.phone} onChange={onFiltersChange} />
                            </div>

                            <div className="p-field p-col-2" style={{ margin: 'auto' }}>

                                <label htmlFor="inputtext">{strings.accessRole}</label>
                                <Dropdown style={{ width: '100%' }} id="accessRole" value={preFilters.accessRole} onChange={onFiltersChange} options={accessRoleOptions} optionLabel="name" ></Dropdown>
                            </div>

                            <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                                <label htmlFor="inputtext">{strings.status}</label>
                                <Dropdown style={{ width: '100%' }} id="userStatus" value={preFilters.userStatus} onChange={onFiltersChange} options={statusOptions} optionLabel="name" ></Dropdown>
                            </div>

                            <div className="p-field p-col-1" style={{ margin: 'auto' }}>
                                <hr />

                                <Button tooltipOptions={{
                                    position: "top"
                                }} tooltip={strings.Filter} icon="pi pi-search" className="p-button-success p-mr-1 p-mb-1" onClick={() => {
                                    let f = { ...preFilters }
                                    // 2021-09-21T21:50:37.000Z
                                    setFilters({
                                        ...f,
                                        accessRole: f.accessRole?.code,
                                        userActive: f.userStatus?.code != null ? f.userStatus?.code  == 'active' : null
                                    });
                                }} />
                                <Button tooltipOptions={{
                                    position: "top"
                                }} tooltip={strings.Clear} icon="pi pi-times" className="p-button-warning p-mr-1 p-mb-1" onClick={() => {
                                    setPreFilters({});
                                }} />
                            </div>
                        </div>
                        </TableFilters>
            </div>
        </>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );


    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        // ref={dt}
                        value={users}
                        totalRecords={totalRecords}
                        first={first}
                        loading={loading}
                        onPage={onPage}
                        rows={rows}
                        header={header}
                        {...defaultTableConfiguration}>
                        <Column field="name" header={strings.Name}  style={{width: "250px"}}body={nameBodyTemplate}></Column>
                        <Column field="email" header={strings.email}  style={{width: "250px"}} body={emailBodyTemplate}></Column>
                        <Column field="phone" header={strings.phone} body={phoneBodyTemplate}></Column>
                        <Column field="accessRole" header={strings.accessRole} body={accessRoleBodyTemplate}></Column>
                        <Column field="status" header={strings.Status} body={statusBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: '900px' }} header="User Details"  className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="name">{strings.SubscriptionID}</label>
                            <br/>
                            <span><b>{localStorage.getItem("subscription")}</b></span>
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">{strings.email}</label>
                            {!user.uuid ? <InputText id="email" value={user.email} onChange={(e) => onInputChange(e, 'email')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.email })} />:
                            <><br/><span>{user.email}</span></>}
                        </div>

                        <div className="p-field">
                            <label htmlFor="name">{strings.Name}</label>
                            <InputText id="name" value={user.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.name })} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">{strings.phone}</label>
                            <ReactPhoneInput
                                defaultCountry={getCountry()}
                                inputStyle={{width:'100%'}}
                                excludeCountries={'il'}
                                autocompleteSearch={true}
                                value={user?.phone}
                                onChange={(e) => onInputChange(e, 'phone')}
                            />
                        </div>

                        <div className="p-field">
                            <label className="p-mb-3">{strings.accessRole}</label>
                            <div className="p-formgrid p-grid">
                                <div className="p-field-radiobutton p-col-12">
                                    <RadioButton inputId="category1" name="category" value="subscription_admin" onChange={onCategoryChange} checked={user.accessRole === 'subscription_admin'} />
                                    <label htmlFor="category1">{strings.subscription_admin}</label>
                                </div>
                                <div className="p-field-radiobutton p-col-12">
                                    <RadioButton inputId="category2" name="category" value="subscription_admin_ro" onChange={onCategoryChange} checked={user.accessRole === 'subscription_admin_ro'} />
                                    <label htmlFor="category2">{strings.subscription_admin_ro}</label>
                                </div>
                                <div className="p-field-radiobutton p-col-12">
                                    <RadioButton inputId="category3" name="category" value="subscription_data_entry" onChange={onCategoryChange} checked={user.accessRole === 'subscription_data_entry'} />
                                    <label htmlFor="category3">{strings.subscription_data_entry}</label>
                                </div>
                                <div className="p-field-radiobutton p-col-12">
                                    <RadioButton inputId="category4" name="category" value="subscription_user" onChange={onCategoryChange} checked={user.accessRole === 'subscription_user'} />
                                    <label htmlFor="category4">{strings.subscription_user}</label>
                                </div>
                                <div className="p-field-radiobutton p-col-12">
                                    <RadioButton inputId="category4" name="category" value="subscription_pos_user" onChange={onCategoryChange} checked={user.accessRole === 'subscription_pos_user'} />
                                    <label htmlFor="category4">{strings.subscription_pos_user}</label>
                                </div>
                            </div>
                        </div>
                        {nodes && <div className="p-field">
                            <div className="p-formgrid p-grid">
                                <div className=" p-col-12">
                                    <TreeSelect filter value={selectedNodeKey3} options={nodes?.root} selectionMode="checkbox" placeholder={strings.selectCompanies} onChange={e => setSelectedNodeKey3(e.value)} />
                                </div>
                            </div>
                        </div>}

                    </Dialog>

                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {user && <span>Are you sure you want to remove <b>{user.name} from subscription</b>?</span>}
                        </div>
                    </Dialog>

                </div>
            </div>
        </div>
    );
}
