import ApiService from './ApiService';
let api = process.env.REACT_APP_API_URL

export default class ProductCategoryService extends ApiService {

    constructor() {
        super();
        this.path = '/productCategory';
    }

    async CreateProductCategory(data) {
        try {
            let op = await this.Create(this.path, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }


    async UpdateProductCategory(data) {


        try {

            let op = await this.Update(this.path+"/"+data.uuid, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async DeleteProductCategory(id) {
        try {
            let op = await this.Delete(this.path+"/"+id);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }


    async GetProductCategory(limit=10,offset=0,vendorID,filters="") {
        try {
            if(!vendorID) vendorID ='';
            let link = `${this.path}/list?limit=${limit}&offset=${offset}&vendorId=${vendorID}&${filters}`
            let op = await this.Read(link);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async GetCount() {
        try {
            let link = `${this.path}/list?countOnly=true`
            let op = await this.Read(link);
            return op.data.count;
        } catch(e) {
            return e.response.data;
        }
    }
}
