import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { addAccountHandler } from '../../../store/actions/accountAction'
import { useDispatch, useSelector, connect } from 'react-redux'


const AddAccount = ({ visible, onHide, addAccountHandler, vendorId }) => {
    const accounts = useSelector(state => state.bankAccounts)
    let initialState = { accountName: '', bankName: '', accountNumber: '' }
    const [bankAccount, setBankAccount] = useState(initialState)
    let { accountName, bankName, accountNumber } = bankAccount
    const addAccountSubmission = () => {
        let obj = { translations:[ { locale: 'en', name: accountName },{ locale: 'ar', name: accountName }], bankName: bankName, accountNumber: accountNumber, vendorId: vendorId }
        addAccountHandler(obj)
        onHide()
    }
    return (
        <Dialog visible={visible} onHide={onHide} style={{ lineHeight: '4rem' }}>
            <h5>Add bank account </h5>
            <InputText type="text" className="block mb-2" placeholder="Account Name" value={accountName} onChange={e => setBankAccount({ ...bankAccount, accountName: e.target.value })} /><br />
            <InputText type="text" className="block mb-2" placeholder="Bank" value={bankName} onChange={e => setBankAccount({ ...bankAccount, bankName: e.target.value })} /><br />
            <InputText type="text" className="block mb-2" placeholder="Account number" value={accountNumber} onChange={e => setBankAccount({ ...bankAccount, accountNumber: e.target.value })} /><br />
            <Button style={{ margin: 'auto' }} onClick={addAccountSubmission}>Add account</Button>

        </Dialog>
    )

}

export default connect(null, { addAccountHandler })(AddAccount)