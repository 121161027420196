import React, { useState, useEffect } from 'react'
import { InputTextarea } from 'primereact/inputtextarea'
import ContentEditable from 'react-contenteditable'
import { Button } from 'primereact/button'
import { Editor } from 'primereact/editor';
import { InputText } from 'primereact/inputtext';
// import { SMTPClient, Message } from 'emailjs';
import strings from '../../localization'
const EmailTemplate = (props) => {
   
  const [validFrom, setValidFrom] =useState(true)
  const [validTo, setValidTo] =useState(true)
    const [text1, setText1] = useState(`<div>مرحبا / Hello</div><div>Please find files for invoice #<b>{InvoiceNumber}</b> attached.</div><div>يرجى العثور على ملفات الفاتورة رقم #<b>{InvoiceNumber}</b> في المرفقات.<br></div> <div>تحياتنا / Greetings</div>

    <div>{ArVendorName} / {EnVendorName}</div>`);
    const filter = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    
const  changeHandler = e =>{
    if(e.target.id ==='from' ){
    
        if(!filter.test(e.target.value)){
            setValidFrom(false)
        } else setValidFrom(true)
    } else {
        if(!filter.test(e.target.value)){
            setValidTo(false)
        } else setValidTo(true)
    }
}
    
    
    return (
        // <EmailHTML/>
        <div>
            <div className="card">
                {/* <h5>Default</h5> */}
                <div className="p-grid">
                    <label htmlFor="from" className="p-col-1">from: </label>
                    <InputText id="from" aria-describedby="username2-help" className={validFrom ?" p-col-11" : "p-col-11 p-invalid"} defaultValue='someone@email.com' onChange={changeHandler}/>
                   {!validFrom && <small id="username2-help" className="p-error block p-col-3">please enter a valid email</small>}
                </div>
                <div className="p-grid" style={{margin: '1rem auto'}}>
                    <label htmlFor="to" className="p-col-1">To: </label>
                    <InputText id="to" aria-describedby="username2-help" className={validTo ?" p-col-11" : "p-col-11 p-invalid"}  defaultValue='receiver@email.com' onChange={changeHandler}/>
                   {!validTo &&  <small id="username2-help" className="p-error block p-col-3">please enter a valid email</small>}
                </div>
                <Editor style={{ height: '320px', textAlign: 'right' }} value={text1} onTextChange={(e) => setText1(e.htmlValue)} />

                
            </div>
            <Button label='send email' className="pi pi-send"/>
        </div>
    )
}

export default EmailTemplate
