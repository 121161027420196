import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import IdentityTypeService from '../service/IdentityTypeService';
import { Badge } from 'primereact/badge';

import VendorService from '../service/VendorService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import strings from '../localization';
import ApiService from '../service/ApiService';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { formatDate } from './InvoiceDates';
import CertificateService from '../service/CertificateService';
import { Dialog } from 'primereact/dialog';
import InvoiceService from '../service/InvoiceService';
import { getCountry } from '../service/helpers';
import UserService from '../service/UserService';
import { RadioButton } from 'primereact/radiobutton';
import ComplianceService from '../service/ComplianceService';

export class ZatcaSetup extends Component {


    constructor(props) {
        super(props);


        let emptyVendor = {

            namear: "",
            nameen: "",
            street1: "",
            street2: "",
            buildNo: "",
            invoiceFooter: "",
            additionalNumber: "",
            isLetterStyle: false,
            pdfHasBorders: true,
            seperateSequencePerType: false,
            automaticReconciliation: false,
            city: "",
            postalCode: "",
            state: "",
            countryCode: getCountry(),
            neighborhood: "",
            vat: "",
            vatGroup: "",
            rows: []

        }


        this.state = {
            vendor: { ...emptyVendor },
            identityTypes: [],
            loading: false,
            pageLoading: false,
            syncCertOpen: false,
            purge: false,
            syncError: null,
            certificateStatus:{},
            errors: [],
        };
        this.toast = React.createRef();



    }


    componentDidMount() {

        this.FillIdentityType();
        // console.log(this.props.user)

        if (this.props.user != null) {
            // Fill Data
            this.setState({loadingCertificateStatus:true});
            new CertificateService().GetCertificateStatus({uuid: this.props.user.uuid}).then(data=>{
                this.setState({certificateStatus: data});
            }).finally(()=>{
                this.setState({loadingCertificateStatus:false});
            });

            this.ReturnData()
        }
    }


    ReturnData() {
        this.setState({ vendor: this.props.user })
    }

    onEditorValueChange(productKey, props, value) {
        let updatedRow = [...props.value];
        console.log(value)
        updatedRow[props.rowIndex][props.field] = value;
        this.setState({ [`${productKey}`]: updatedRow });
    }

    syncCertDialogFooter = (
        <>

            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={()=>{
                this.setState({syncCertOpen: false, syncCertLoading: false, purge: false, confirmOtpOpen:false});
            }} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={()=>{
                this.setState({ syncCertLoading: true, syncCertOpen: false,confirmOtpOpen: false }, () => {
                    new CertificateService().SyncCertificates({ vendorId: this.state.vendor.uuid, purge: this.state.purge, otp: this.state.otp }).then((data) => {
                        this.setState({ certificateStatus: data, syncCertLoading: false, purge: false, syncSuccess: true, confirmOtpOpen: false, initializePreCertifcates:false });
                    }).catch((e)=>{
                        this.setState({syncError:e.response.data?.message??strings.internalServerError, syncCertLoading: false, purge: false})
                    });
                });

            }} />
        </>
    );


    onInputChange = (e, name) => {
        let val =  (e.target && e.target.value) || '';

        let _vendor = { ...this.state.vendor };

        if(name == 'pdfHasBorders')
        {
            val= !_vendor.pdfHasBorders;
        }
        if(name == 'automaticZatcaIntegration')
        {
            val= !_vendor.automaticZatcaIntegration;
        }
        if(name == 'seperateSequencePerType')
        {
            val= !_vendor.seperateSequencePerType;
        }
        if(name == 'automaticReconciliation')
        {
            val= !_vendor.automaticReconciliation;
        }

        if(name == 'isSimpleAddress')
        {
            val= !_vendor.isSimpleAddress;
        }

        if(name == 'isLetterStyle')
        {
            val= !_vendor.isLetterStyle;
        }

        _vendor[`${name}`] = val;


        this.setState({ vendor: _vendor });
    }


    FillIdentityType = async () => {
        let identityTypeService = new IdentityTypeService();
        this.setState({ pageLoading: true })
        var identityTypes = await identityTypeService.GetIdentityTypes();
        this.setState({ identityTypes: identityTypes.data, pageLoading: false })

    }


    AddRow = () => {
        let rows = [...this.state.vendor.rows ?? [], { identityId: null, name: "", value: "" }];
        let _vendor = { ...this.state.vendor };
        _vendor.rows = rows;
        this.setState({ vendor: _vendor });
    }

    AddBranchRow = () => {
        let branches = [...this.state.vendor.branches ?? [], { branchId: null, name: "", serial: "" }];
        let _vendor = { ...this.state.vendor };
        _vendor.branches = branches;
        this.setState({ vendor: _vendor });
    }


    GetData = () => {


        // console.log(this.state.user);

    }


    delete = (rowData) => {
        let _vendor = { ...this.state.vendor };

        _vendor.rows = _vendor.rows.filter(val => val.identityId == rowData.identityId);
        this.setState({ vendor: _vendor });

        this.toast.current?.show({ severity: 'success', summary: 'Successful', detail: 'Identity Deleted', life: 3000 });
    }


    nameBodyTemplate = (rowData, props) => {
        return (
            <>


                {/* <span className="p-column-title">Name</span> */}

                {/* {console.log(rowData)} */}


                <Dropdown value={props.rowData['name']} onChange={(e) => this.onEditorValueChange('name', props, e.value)} optionLabel="name" options={this.state.identityTypes?.map(o => {
                    return {
                        name: o.label,
                        value: o.uuid
                    }
                })} style={{ width: '100%' }} placeholder={strings.Select} />
                {/* {rowData.name} */}

            </>
        );
    }



    valueBodyTemplate = (rowData, props) => {
        return (
            <>

                {/* <span className="p-column-title">Value</span> */}
                {/* {rowData.value} */}
                <InputText id='value' type="text" value={props.rowData['value']} onChange={(e) => this.onEditorValueChange('value', props, e.target.value)} required autoFocus />

            </>
        );
    }
    branchNameTemplate = (rowData, props) => {
        return (
            <>

                {/* <span className="p-column-title">Value</span> */}
                {/* {rowData.value} */}
                <InputText id='name' disabled={rowData.branchId} type="text" value={props.rowData['name']} onChange={(e) => this.onEditorValueChange('name', props, e.target.value)} required autoFocus />

            </>
        );
    }
    branchSerialTemplate = (rowData, props) => {
        return (
            <>
                {/* <span className="p-column-title">Value</span> */}
                <InputText disabled={rowData.branchId} id='serial' type="text" value={props.rowData['serial']} onChange={(e) => this.onEditorValueChange('serial', props, e.target.value)} required autoFocus />

            </>
        );
    }


    actionBodyTemplate = (rowData) => {

        return (
            <div className="actions">
                <Button icon="pi pi-trash" onClick={() => this.delete(rowData)} className="p-button-rounded p-button-warning" />
            </div>
        );
    }

    save = async () => {


        this.setState({ loading: true });


        let { vendor } = this.state;

        let data = {
            ...vendor,
            "address": {
                "translations": [
                    {
                        "street": vendor.street1,
                        "additionalStreet": vendor.street2,
                        "buildingNumber": vendor.buildNo,
                        "additionalNumber": vendor.additionalNumber,
                        "city": vendor.city,
                        "postalCode": vendor.postalCode,
                        "state": vendor.state,
                        "neighborhood": vendor.neighborhood,
                        "countryCode": getCountry(),
                        "locale": 1
                    },
                    {
                        "street": vendor.street1,
                        "additionalStreet": vendor.street2,
                        "buildingNumber": vendor.buildNo,
                        "additionalNumber": vendor.additionalNumber,
                        "city": vendor.city,
                        "postalCode": vendor.postalCode,
                        "state": vendor.state,
                        "neighborhood": vendor.neighborhood,
                        "countryCode": getCountry(),
                        "locale": 2
                    }
                ]
            },
            "vat": vendor.vat,
            "vatGroupNumber": vendor.vatGroup,
            "headerBgColor": vendor.headerBgColor??'#f0ffff',
            "pdfFontColor": vendor.pdfFontColor??'#039b92',
            "headerFontColor": vendor.headerFontColor??'#000',
            "email": vendor.email,
            "phone": vendor.phone,
            "invoiceFooter": vendor.invoiceFooter,
            "image": vendor.image,
            "taxScheme": "VAT",
            "translations": [
                {
                    "name": vendor.nameen,
                    "locale": 1
                },
                {
                    "name": vendor.namear,
                    "locale": 2
                }
            ],
            "branches": vendor.branches?.map((row) => {
                return {
                    "branchId": row.branchId,
                    "name": row.name,
                    "serial": row.serial
                };
            }),
            "identities": vendor.rows?.map((row) => {
                return {
                    "identityId": row.identityId,
                    "identityTypeId": row.name,
                    "value": row.value
                };
            }),
            "isActive": true
        }

        var vendorService = new VendorService();

        if (!data.uuid) {
            let res = await vendorService.CreateVendor(data);
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors });
            }
            else if(res.errorCode)
            {
                let errors = [];

                errors.push(strings[res.errorCode]);
                this.setState({ errors });
            }
            else
            {
                this.props.OnHideDialog();
                this.toast.current?.show({ severity: 'success', summary: 'Successful', detail: 'Vendor Created', life: 3000 });
            }
        }
        else {
            let res = await vendorService.UpdateVendor(data);

            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors });
            } else {
                this.props.OnHideDialog();
                this.toast.current?.show({ severity: 'success', summary: 'Successful', detail: 'Vendor Updated', life: 3000 });
            }
        }
        this.props.FillData();

        this.setState({ loading: false });
    }


    render() {

        if (this.state.pageLoading) {
            return <div style={{ textAlign: 'center' }}><ProgressSpinner /></div>
        }

        if (this.state.loadingCertificateStatus) {
            return <div style={{ textAlign: 'center' }}><ProgressSpinner /></div>
        }

        const isRTL = localStorage.getItem('lang') == 'ar' ? 'rtl' : 'ltr';

        let image = this.state.vendor.image;

        if(image?.startsWith(this.state.vendor.uuid))
        {
            image = new ApiService().AddHostName("","/download/vendor/logo/")+"?path="+image;
        }

        let role = new UserService().GetRole();

        if(role=='super_admin')
        {
        }

        var ControlPlane = () => { return role == 'super_admin' ? <div className="p-col-12">
        <hr/>
        <h6>Zatca Onboarding Status:</h6>

        <div className="flex flex-wrap gap-3">
            <div className="flex align-items-center mr-3">
                <RadioButton inputId="disabled" name="disabled" value="0" onChange={async (e) =>
                {
                    this.setState({syncCertLoading:true});

                    new ComplianceService().ConfigureComplianceSettings({
                        useZatcaSandbox: this.state.vendor.useZatcaSandbox,
                        isActive: e.value =='1',
                        sid: this.state.vendor.sid,
                        vendorId: this.state.vendor.uuid
                    })

                    this.setState({
                        certificateStatus: {
                            ...this.state.certificateStatus,
                            isActive: e.value =='1'
                        }
                    });

                    this.setState({syncCertLoading:false});

                }} checked={!this.state.certificateStatus.isActive} />
                <label htmlFor="disabled" className="ml-2">Disabled</label>
            </div>
            <div className="flex align-items-center mr-3">
                <RadioButton inputId="enabled" name="enabled" value="1" onChange={async (e) =>
                {
                    this.setState({syncCertLoading:true});

                    await new ComplianceService().ConfigureComplianceSettings({
                        useZatcaSandbox: this.state.vendor.useZatcaSandbox,
                        isActive: e.value =='1',
                        sid: this.state.vendor.sid,
                        vendorId: this.state.vendor.uuid
                    })

                    this.setState({
                        certificateStatus: {
                            ...this.state.certificateStatus,
                            isActive: e.value =='1'
                        }
                    });
                    this.setState({syncCertLoading:false});


                }} checked={this.state.certificateStatus.isActive} />
                <label htmlFor="enabled" className="ml-2">Enabled</label>
            </div>
        </div>
        <hr/>

        <h6>Environment:</h6>
        <div className="flex flex-wrap gap-3">
            <div className="flex align-items-center mr-3">
                <RadioButton inputId="sandbox" name="sandbox" value="sandbox" onChange={async (e) =>
                {
                    this.setState({syncCertLoading:true});

                    await new ComplianceService().ConfigureComplianceSettings({
                        useZatcaSandbox: e.value == 'sandbox',
                        isActive: this.state.certificateStatus.isActive,
                        sid: this.state.vendor.sid,
                        vendorId: this.state.vendor.uuid
                    })


                    this.setState({
                        vendor: {
                            ...this.state.vendor,
                            useZatcaSandbox: e.value =='sandbox'
                        }
                    });
                    this.setState({syncCertLoading:false});

                }} checked={this.state.vendor.useZatcaSandbox} />
                <label htmlFor="disabled" className="ml-2">Sandbox</label>
            </div>
            <div className="flex align-items-center mr-3">
                <RadioButton inputId="live" name="live" value="live" onChange={async (e) =>
                    {
                        this.setState({syncCertLoading:true});
                        await new ComplianceService().ConfigureComplianceSettings({
                            useZatcaSandbox: e.value == 'sandbox',
                            isActive: this.state.certificateStatus.isActive,
                            sid: this.state.vendor.sid,
                            vendorId: this.state.vendor.uuid
                        })

                        this.setState({
                            vendor: {
                                ...this.state.vendor,
                                useZatcaSandbox: e.value =='sandbox'
                            }
                        });
                        this.setState({syncCertLoading:false});

                    }} checked={!this.state.vendor.useZatcaSandbox} />
                <label htmlFor="enabled" className="ml-2">Live</label>
            </div>
        </div>

        <hr/>
    </div> : <></>}

        if(!this.state.certificateStatus.isActive)
        {
            return <div className="p-grid crud-demo" style={{ direction: isRTL }}>
                <ControlPlane/>
                <b>Environment: <Badge value={this.state.vendor.useZatcaSandbox?'Sandbox':'Live'} size="large" style={{ background: this.state.vendor.useZatcaSandbox ? 'orange' : 'green' }}></Badge></b>
                    <hr/>
                <div className="p-col-12">
                    <Message severity={"error"} content={strings.zatcaOnboardingIsDisabled} size="large"/>
                </div>
            </div>
        }

        if((this.state.initializePreCertifcates) || ["pending_pk_pair_generation","pending_cnf_generation","pending_csr_generation","pending_ccsid_generation"].includes(this.state.certificateStatus.status))
        {
            return <div className="p-grid crud-demo" style={{ direction: isRTL }}>
                <ControlPlane/>
                <div className="p-col-12">
                        <Message severity={"info"} content={strings.PreComplianceCertificatesText} size="large"/>
                    <hr/>
                    <b>Environment: <Badge value={this.state.vendor.useZatcaSandbox?'Sandbox':'Live'} size="large" style={{ background: this.state.vendor.useZatcaSandbox ? 'orange' : 'green' }}></Badge></b>
                    <hr/>
                    <div className="p-field">
                            <label htmlFor="invoiceHeader">{strings.certificateStatus}</label>
                            <br />
                            <Badge value={"Public Key"} size="large" style={{ background: this.state.certificateStatus.hasPublicKey ? 'green' : 'red', margin: '5px' }}></Badge>
                            <Badge value={"Private Key"} size="large" style={{ background: this.state.certificateStatus.hasPrivateKey ? 'green' : 'red', margin: '5px' }}></Badge>
                            <Badge value={"CSR"} size="large" style={{ background: this.state.certificateStatus.hasCSR ? 'green' : 'red', margin: '5px' }}></Badge>
                            <Badge value={"CSID"} size="large" style={{ background: this.state.certificateStatus.hasCSID ? 'green' : 'red', margin: '5px' }}></Badge>
                            <Badge value={"CSID Secret"} size="large" style={{ background: this.state.certificateStatus.hasCSIDSecret ? 'green' : 'red', margin: '5px' }}></Badge>
                            <Badge value={"CCSID"} size="large" style={{ background: this.state.certificateStatus.hasCCSID ? 'green' : 'red', margin: '5px' }}></Badge>
                            <Badge value={"CCSID Secret"} size="large" style={{ background: this.state.certificateStatus.hasCCSIDSecret ? 'green' : 'red', margin: '5px' }}></Badge>
                            <hr />
                            </div>

                    {/* //
                    1) Generate normal certificates.
                    2) do compliance checks
                    3) show compliance checks progress
                    4) once they are done show otp form
                    5) generate CSID
                    */}

                    {["pending_pk_pair_generation"].includes(this.state.certificateStatus.status) ? <div className="p-field">
                        <label htmlFor="zatcaOtp">{strings.Otp}</label>
                        <InputText type={"password"} id="zatcaOtp" placeholder={strings.Otp} onChange={(e) => this.setState({otp: e.target.value})} required autoFocus />
                    </div>:""}

                    <Button label={strings.GenerateCertificates} loading={this.state.syncCertLoading} onClick={() => this.setState({ confirmOtpOpen: true })}>
                                <i className="pi pi-refresh"></i>
                    </Button>

                    <Dialog visible={this.state.confirmOtpOpen} style={{ width: '450px' }} header={strings.Confirm} modal footer={this.syncCertDialogFooter} onHide={() => {
                        this.setState({ confirmOtpOpen: false, syncCertLoading: false, purge: false })
                    }}>
                        <div className="confirmation-content">

                            {<Message content={strings.ConfirmOtpText} severity={"error"}></Message>}
                            <hr />
                            <Checkbox name='purge' checked={this.state.purge} onChange={(e=>this.setState({purge:e.checked}))}></Checkbox>

                            <label style={{color:'red', marginLeft:'5px'}} htmlFor='purge'>
                                {strings.purgeCerts}
                            </label>
                            {this.state.purge ? <><hr/><span style={{color:'red', fontStyle:"italic"}}><b>{strings.purgeWarning}</b></span></> : ''}

                        </div>
                    </Dialog>
                    {this.state.syncError ? <Message style={{ marginTop: '10px' }} severity={"error"} content={`Generation error: ${this.state.syncError}`}></Message> : ''}
                </div>

            </div>
        }



        if(["pending_pk_pair_generation"].includes(this.state.certificateStatus.status) && !this.state.initializePreCertifcates)
        {
            return <div className="p-grid crud-demo" style={{ direction: isRTL }}>

                <ControlPlane/>

                <div className="p-col-12">
                    <Message severity={"error"} content={strings.certsNotInitialized} size="large"/>
                    <b>Environment: <Badge value={this.state.vendor.useZatcaSandbox?'Sandbox':'Live'} size="large" style={{ background: this.state.vendor.useZatcaSandbox ? 'orange' : 'green' }}></Badge></b>
                    <hr/>

                    <hr/>
                    <Button loading={this.state.syncCertLoading} onClick={()=>this.setState({initializePreCertifcates:true})}>{strings.StartOnboarding}</Button>
                </div>
            </div>
        }

        return (
            <div className="p-grid crud-demo" style={{ direction: isRTL }}>
                <ControlPlane/>
                <div className="p-col-12">
                    <div className="card">
                    <b>Environment: <Badge value={this.state.vendor.useZatcaSandbox?'Sandbox':'Live'} size="large" style={{ background: this.state.vendor.useZatcaSandbox ? 'orange' : 'green' }}></Badge></b>
                    <hr/>

                        <div className="p-field">
                            <label htmlFor="invoiceHeader">{strings.certificateStatus}</label>
                            <br />
                            <Badge value={"Public Key"} size="large" style={{ background: this.state.certificateStatus.hasPublicKey ? 'green' : 'red', margin: '5px' }}></Badge>
                            <Badge value={"Private Key"} size="large" style={{ background: this.state.certificateStatus.hasPrivateKey ? 'green' : 'red', margin: '5px' }}></Badge>
                            <Badge value={"CSR"} size="large" style={{ background: this.state.certificateStatus.hasCSR ? 'green' : 'red', margin: '5px' }}></Badge>
                            <Badge value={"CSID"} size="large" style={{ background: this.state.certificateStatus.hasCSID ? 'green' : 'red', margin: '5px' }}></Badge>
                            <Badge value={"CSID Secret"} size="large" style={{ background: this.state.certificateStatus.hasCSIDSecret ? 'green' : 'red', margin: '5px' }}></Badge>
                            <Badge value={"CCSID"} size="large" style={{ background: this.state.certificateStatus.hasCCSID ? 'green' : 'red', margin: '5px' }}></Badge>
                            <Badge value={"CCSID Secret"} size="large" style={{ background: this.state.certificateStatus.hasCCSIDSecret ? 'green' : 'red', margin: '5px' }}></Badge>
                            <hr />
                            {this.state.certificateStatus.status == "pending_compliance_checks" && <div className="p-grid crud-demo" style={{ direction: isRTL }}>
                                <div className="p-col-12">
                                    <Message severity={"info"} content={strings.performComplianceChecksText} size="large" />
                                    <hr />
                                    <Button label={strings.performComplianceChecks} loading={this.state.syncCertLoading} onClick={() => {
                                        this.setState({ syncCertLoading: true })
                                        var types = ["Tax Invoice", "Tax Credit Note", "Tax Debit Note", "Simplified Invoice", "Simplified Credit Note", "Simplified Debit Note"]
                                        const errorMessages = [];
                                        const infoMessages = [];


                                        new InvoiceService().CheckCompliance(this.state.vendor.uuid).then((data) => {
                                            let i = 0;
                                            for (const complianceApiResult of data.complianceApiResults) {
                                                const validationResults = complianceApiResult.validationResults;
                                                if (validationResults) {
                                                    const errors = validationResults.errorMessages;
                                                    if (errors) {
                                                        for (const error of errors) {
                                                            errorMessages.push({ type: types[i], error: error.messageMessage });
                                                        }
                                                    }
                                                    const infos = validationResults.infoMessages;
                                                    if (infos) {
                                                        for (const info of infos) {
                                                            infoMessages.push(info.messageMessage);
                                                        }
                                                    }
                                                }
                                                ++i;
                                            }
                                            this.setState({ certificateStatus: data.complianceCertificateStatus, complianceErrors: errorMessages, syncCertLoading: false, purge: false });
                                        }).catch((e) => {
                                            this.setState({ syncError: e.response.data?.message ?? strings.internalServerError, syncCertLoading: false, purge: false })
                                        });
                                    }}>
                                        <i className="pi pi-check"></i>
                                    </Button>
                                </div>
                            </div>}


                            {this.state.certificateStatus.status == "pending_csid_generation" && <div className="p-grid crud-demo" style={{ direction: isRTL }}>
                                <div className="p-col-12">
                                    <Message severity={"info"} content={strings.generateCSIDText} size="large" />
                                    <hr />
                                    <Button label={strings.generateCSID} loading={this.state.syncCertLoading} onClick={() => {
                                        this.setState({syncCertLoading: true})
                                        new CertificateService().SyncCertificates({ vendorId: this.state.vendor.uuid, purge: this.state.purge }).then((data) => {
                                            this.setState({ certificateStatus: data, syncCertLoading: false, purge: false, syncSuccess: true, performComplianceChecks: true });
                                        }).catch((e) => {
                                            this.setState({ syncError: e.response.data?.message ?? strings.internalServerError, syncCertLoading: false, purge: false })
                                        });
                                    }}/>
                                </div>
                            </div>}
                            {this.state.complianceErrors ? this.state.complianceErrors.map(complianceError => <Message style={{ marginTop: '10px' }} severity={"error"} content={`For ${complianceError.type} ZATCA said: ${complianceError.error}`}></Message>) : ''}
                            {this.state.syncError ? <Message style={{ marginTop: '10px' }} severity={"error"} content={`Sync error: ${this.state.syncError}`}></Message> : ''}
                            {this.state.syncSuccess ? <Message style={{ marginTop: '10px' }} severity={"success"} content={strings.syncSuccess}></Message> : ''}
                        </div>

                    </div>

                </div>
                <Dialog visible={this.state.syncCertOpen} style={{ width: '450px' }} header={strings.Confirm} modal footer={this.syncCertDialogFooter} onHide={()=>{
                    this.setState({syncCertOpen:false, syncCertLoading: false, purge: false})
                }}>
                <div className="confirmation-content">

                    {<Message content={strings.SyncCertificatesText} severity={"error"}></Message>}
                    <hr/>

                    <Checkbox name='purge' checked={this.state.purge} onChange={(e=>this.setState({purge:e.checked}))}></Checkbox>

                    <label style={{color:'red', marginLeft:'5px'}} htmlFor='purge'>
                        {strings.purgeCerts}
                    </label>
                    {this.state.purge ? <><hr/><span style={{color:'red', fontStyle:"italic"}}><b>{strings.purgeWarning}</b></span></> : ''}
                </div>
            </Dialog>


            </div>

        );
    }
}


