import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import VendorService from '../service/VendorService';
import TaxCategoryService from '../service/TaxCategoryService';
import { Dropdown } from 'primereact/dropdown';


export const TaxCategories = () => {

    let emptyTaxCategory = {
        "percent": 15,
        "taxCategoryCode": "",
        "taxScheme": "VAT",
        "name":"",
    };

    const [taxCategoryDialog, setTaxCategoryDialog] = useState(false);
    const [deleteTaxCategoryDialog, setDeleteTaxCategoryDialog] = useState(false);
    const [deleteTaxCategoriesDialog, setDeleteTaxCategoriesDialog] = useState(false);
    const [taxCategory, setTaxCategory] = useState(emptyTaxCategory);
    const [selectedTaxCategories, setSelectedTaxCategories] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [taxCategories, setTaxCategories] = useState([]);
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        // const taxCategoryService = new TaxCategoryService();
        // taxCategoryService.getTaxCategories().then(data => setTaxCategories(data));
        onPage({ rows, page: 0 })
    }, []);

    const formatCurrency = (value) => {
        if (!value) return ""
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setTaxCategory(emptyTaxCategory);
        setSubmitted(false);
        setTaxCategoryDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setTaxCategoryDialog(false);
    }

    const hideDeleteTaxCategoryDialog = () => {
        setDeleteTaxCategoryDialog(false);
    }

    const hideDeleteTaxCategoriesDialog = () => {
        setDeleteTaxCategoriesDialog(false);
    }

    const taxCategoyCodes = [
        { code: 'S', name: '[S] Standard rate' },
        { code: 'Z', name: '[Z] Zero rated goods' },
        { code: 'E', name: '[E] Exempt from Tax' },
        { code: 'O', name: '[O] Services outside scope of tax' },
    ]

    const saveTaxCategory = async () => {
        setSubmitted(true);

        var taxCategoryService = new TaxCategoryService();
        taxCategory.taxCategoryCode = taxCategory.taxCategoryCode.code;
        if (taxCategory.uuid) {
            await taxCategoryService.UpdateTaxCategory(taxCategory);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'TaxCategory Updated', life: 3000 });
        }
        else {
            await taxCategoryService.CreateTaxCategory(taxCategory);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'TaxCategory Created', life: 3000 });
        }
        onPage({ rows, page })
        // setTaxCategories(_taxCategories);
        setTaxCategoryDialog(false);
        setTaxCategory(emptyTaxCategory);
    }

    const editTaxCategory = (taxCategory) => {
        setTaxCategory({ ...taxCategory });
        setTaxCategoryDialog(true);
    }

    const confirmDeleteTaxCategory = (taxCategory) => {
        setTaxCategory(taxCategory);
        setDeleteTaxCategoryDialog(true);
    }

    const deleteTaxCategory = () => {
        let _taxCategories = taxCategories.filter(val => val.id !== taxCategory.id);
        setTaxCategories(_taxCategories);
        setDeleteTaxCategoryDialog(false);
        setTaxCategory(emptyTaxCategory);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'TaxCategory Deleted', life: 3000 });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < taxCategories.length; i++) {
            if (taxCategories[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const confirmDeleteSelected = () => {
        setDeleteTaxCategoriesDialog(true);
    }

    const deleteSelectedTaxCategories = () => {
        let _taxCategories = taxCategories.filter(val => !selectedTaxCategories.includes(val));
        setTaxCategories(_taxCategories);
        setDeleteTaxCategoriesDialog(false);
        setSelectedTaxCategories(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'TaxCategories Deleted', life: 3000 });
    }

    const onCategoryChange = (e) => {
        let _taxCategory = { ...taxCategory };
        _taxCategory['accessRole'] = e.value;
        setTaxCategory(_taxCategory);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _taxCategory = { ...taxCategory };
        _taxCategory[`${name}`] = val;

        setTaxCategory(_taxCategory);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
                <Button label="Delete" icon="pi pi-trash" className="p-button-danger p-mb-2" onClick={confirmDeleteSelected} disabled={!selectedTaxCategories || !selectedTaxCategories.length} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        // return (
        //     <React.Fragment>
        //         <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" />
        //         <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
        //     </React.Fragment>
        // )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.code}
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.email}
            </>
        );
    }

    const arabicLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Label [AR]</span>
                {rowData.name}
            </>
        );
    }

    const englishLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.name}
            </>
        );
    }

    const percentLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Percent</span>
                {rowData.percent}
            </>
        );
    }

    const taxCategoryCodeTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tax Category Code</span>
                {rowData.taxCategoryCode}
            </>
        );
    }
    const taxSchemeLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tax Scheme</span>
                {rowData.taxScheme}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editTaxCategory(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteTaxCategory(rowData)} />
            </div>
        );
    }

    const onPage = ({ first,
        rows,
        page,
        pageCount }) => {
        const taxCategoryService = new TaxCategoryService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        taxCategoryService.GetTaxCategories(rows, (page) * rows).then(({ data, count }) => {
            setLoading(false);
            setTaxCategories(data);
            setTotalRecords(count);
        });

    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage TaxCategories</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const taxCategoryDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveTaxCategory} />
        </>
    );
    const deleteTaxCategoryDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTaxCategoryDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteTaxCategory} />
        </>
    );
    const deleteTaxCategoriesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTaxCategoriesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedTaxCategories} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt}
                        value={taxCategories}
                        selection={selectedTaxCategories}
                        dataKey="id"
                        totalRecords={totalRecords}
                        lazy
                        first={first}
                        loading={loading}
                        autoLayout={true}
                        onPage={onPage}
                        paginator rows={rows}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} taxCategories"
                        globalFilter={globalFilter}
                        emptyMessage="No taxCategories found."
                        header={header}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="taxScheme" header="Tax Scheme" style={{ width: "250px" }}body={taxSchemeLabelTemplate}></Column>
                        {/* <Column field="namear" header="Name [AR]" style={{ width: "250px" }} body={arabicLabelTemplate}></Column> */}
                        <Column field="name" header="Name"body={englishLabelTemplate}></Column>
                        <Column field="percent" header="Percent"body={percentLabelTemplate}></Column>
                        <Column field="percent" header="taxCategoryCode"body={taxCategoryCodeTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={taxCategoryDialog} style={{ width: '450px' }} header="Tax Category Details" modal className="p-fluid" footer={taxCategoryDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="name">Tax Category Code</label>
                            <Dropdown id="type" value={taxCategory.taxCategoryCode} onChange={(e) => onInputChange(e, 'taxCategoryCode')} options={taxCategoyCodes} optionLabel="name" placeholder="Select One"></Dropdown>
                            {submitted && !taxCategory.name && <small className="p-invalid">Tax Category Code is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">Tax Scheme</label>
                            <InputText id="name" value={taxCategory.taxScheme} onChange={(e) => onInputChange(e, 'taxScheme')} required autoFocus className={classNames({ 'p-invalid': submitted && !taxCategory.taxScheme })} />
                            {submitted && !taxCategory.name && <small className="p-invalid">Tax Scheme is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">Percent</label>
                            <InputText id="name" value={taxCategory.percent} onChange={(e) => onInputChange(e, 'percent')} required autoFocus className={classNames({ 'p-invalid': submitted && !taxCategory.percent })} />
                            {submitted && !taxCategory.name && <small className="p-invalid">Percent is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={taxCategory.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !taxCategory.name })} />
                            {submitted && !taxCategory.name && <small className="p-invalid">Label [AR] is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteTaxCategoryDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteTaxCategoryDialogFooter} onHide={hideDeleteTaxCategoryDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {taxCategory && <span>Are you sure you want to delete <b>{taxCategory.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteTaxCategoriesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteTaxCategoriesDialogFooter} onHide={hideDeleteTaxCategoriesDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {taxCategory && <span>Are you sure you want to delete the selected taxCategories?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
