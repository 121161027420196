import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { MegaMenu } from 'primereact/megamenu';
import { useHistory, useLocation } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { CSSTransition } from 'react-transition-group';
import { RTLContext } from './Profile';
import { Subscription } from './pages/Subscription';
import SubscriptionService from './service/SubscriptionService';
import { Dropdown } from 'primereact/dropdown';
import strings from './localization';
import { Dialog } from 'primereact/dialog';
import { SupportTicketForm } from './pages/SupportTicketForm';
import AuthService from './service/AuthService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ScopeConfiguration } from './ScopeConfiguration';
import UserService from './service/UserService';
import { Toast } from 'primereact/toast';
import { Message } from 'primereact/message';
import "react-phone-input-2/lib/style.css";
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css';
import querystring from 'query-string';
import { getCountry } from './service/helpers';
import { ReferralForm } from './pages/ReferralForm';
import PlatformService from './service/PlatformService';
import WalletService from './service/WalletService';
import { Badge } from 'primereact/badge';


const AppTopbar = (props) => {

    const isRTL = useContext(RTLContext);
    const history = useHistory();
    let location = useLocation();

    const [subscription, setSubscription] = useState(localStorage.getItem('subscription'));
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('user')));
    const [errors, setErrors] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [userUpdateForm, setUserUpdateForm] = useState(false);
    const [walletForm, setWalletForm] = useState(false);
    const [supportDialog, setSupportDialog] = useState(false);
    const [referralDialog, setReferralDialog] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);

    const toast = useRef(null);


    const onTopbarItemClick = (event) => {
        // topbarItemClick = true;
        if (activeTopbarItem === event.item)
            setActiveTopbarItem(null)
        else {
            setActiveTopbarItem(event.item)
        }

        event.originalEvent.preventDefault();
    }

    useEffect(() => {
        if (subscription != null) {
            localStorage.setItem('subscription', subscription);
            let params = querystring.parse(location.search);
            params['s'] = subscription?.substr(0,3);
            history.push(location.pathname+`?${querystring.stringify(params)}`)
        }
        else
        {
            history.push(location.pathname)
            localStorage.removeItem('subscription')
        }
    }, [subscription,history,location.pathname])

    const hideDialog = () => {
        setSubmitted(false);
        setUserUpdateForm(false);
        setErrors([]);
        setUserData(JSON.parse(localStorage.getItem('user')));
    }


    const saveUser = async () => {
        setSubmitted(true);
        setLoading(true);
        var userService = new UserService();

        if (userData.id) {
            let res = await userService.UpdateUser({uuid: userData.id, accessRole: userData.actualRole, ...userData});
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                setErrors(errors);
            } else if (res.errorCode != null) {
                setErrors([res.message]);
            }
            else {
                hideDialog();
                localStorage.setItem('user',JSON.stringify(userData));
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User Updated', life: 3000 });
            }
        }
        setLoading(false);
    }

    const userDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button loading={loading} label="Save" icon="pi pi-check" className="p-button-text" onClick={saveUser} />
        </>
    );
    const walletDialogFooter = (
        <>
            <Button label="Ok" icon="pi pi-check" className="p-button-text" onClick={()=>setWalletForm(false)} />
            {/* <Button loading={loading} label="Save" icon="pi pi-check" className="p-button-text" onClick={saveUser} /> */}
        </>
    );

    let user = JSON.parse(localStorage.getItem('user'));

    let logo = "/assets/layout/images/logo-light.svg";

    let displayName = [];
    let subscriptionService = new SubscriptionService();
    let activeCompany = subscriptionService.GetActiveCompany();

    if(activeCompany != null)
    {
        displayName.push(activeCompany.name)

        let activeBranch = subscriptionService.GetActiveBranch();

        if(activeBranch != null)
        {
            displayName.push(activeBranch.name)
        }
    }
    else
    {
        displayName.push(subscriptionService.GetActiveSubscription()?.name)
    }

    const onInputChange = (e, name) => {
        let val = (e.target && e.target.value) || '';
        if(name=='phone')
        {
            val = e
        }
        let _user = { ...userData };
        _user[`${name}`] = val;

        setUserData(_user);
    }

    displayName = displayName.join('/');

    return (
        <div className="layout-topbar p-shadow-4">
            <Toast ref={toast} />
            <div className="layout-topbar-left">
                <button type="button" style={{ cursor: 'pointer' }} className="layout-topbar-logo p-link" onClick={() => history.push('/')}>
                    <img id="app-logo" src={logo} alt="ultima-layout" style={{ height: '4.25rem' }} />
                </button>
                <button type="button" className="layout-menu-button p-shadow-6 p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-chevron-right"></i>
                </button>
                <button type="button" className="layout-topbar-mobile-button p-link">
                    <i className="pi pi-ellipsis-v fs-large" onClick={props.onMobileTopbarButtonClick}></i>
                </button>
            </div>
            <div className={classNames('layout-topbar-right', { 'layout-topbar-mobile-active': props.mobileTopbarActive })}>
                <div className="layout-topbar-actions-left">
                    <span>
                        {displayName}
                    </span>
                    {/* {!loading? <MegaMenu model={model} className="layout-megamenu" />:<span>Loading...</span>} */}
                </div>
                <div className="layout-topbar-actions-right">
                    <ul className="layout-topbar-items">
                        <li className="layout-topbar-item">
                            {(window.location.hostname.includes("einvtest") || window.location.hostname.includes("localhost")) &&
                                <button className="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2  p-link" onClick={(event) => {
                                    window.location.href = 'https://e-inv.armsit.com/#pricing'
                                }}>
                                    <i className="pi pi-check-circle p-px-2"></i>
                                    {strings.JoinNow}
                                </button>}

                            {props?.context?.platformSettings ? props?.context?.platformSettings["ReferralFeatureEnabled"]?.value == '1' ? <button style={{ backgroundColor: 'white', color: 'green', fontWeight: 'bold' }} className="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2  p-link" onClick={(event) => {
                                setReferralDialog(true)
                            }}>
                                <i className="pi pi-check-circle p-px-2"></i>
                                {strings.referrAFriend}
                            </button> : <></> :''}


                            <button className="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2  p-link" onClick={(event) => {
                                setSupportDialog(true)
                            }}>
                                <i className="pi pi-phone p-px-2"></i>

                                {strings.openSupportRequest}
                            </button>

                            <button className="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2  p-link" onClick={(event) => {
                                props.onRTLChange();
                                window.location.reload()
                            }}>
                                <i className="pi pi-globe p-px-2"></i>
                                {isRTL ? 'English' : 'العربية'}
                            </button>

                            <button className="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2  p-link" onClick={(event) => onTopbarItemClick({ originalEvent: event, item: 'profile' })}>
                                Logged in as {user?.name ?? user?.email}
                            </button>

                            <CSSTransition classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={activeTopbarItem === 'profile'} unmountOnExit>
                                <ul className="layout-topbar-action-panel p-shadow-6">
                                    <li onClick={() => {
                                        setUserUpdateForm(true);
                                    }} className="layout-topbar-action-item">
                                        <button className="p-d-flex p-flex-row p-ai-center p-link">
                                            <i className={classNames('pi pi-pencil', { 'p-mr-2': !isRTL, 'p-ml-2': isRTL })}></i>
                                            <span>{strings.updateaccount}</span>
                                        </button>
                                    </li>

                                    {props?.context?.platformSettings ? props?.context?.platformSettings["WalletEnabled"]?.value == '1' ? <li onClick={() => {
                                        setWalletForm(true);
                                    }} className="layout-topbar-action-item">
                                        <button className="p-d-flex p-flex-row p-ai-center p-link">
                                            <i className={classNames('pi pi-wallet', { 'p-mr-2': !isRTL, 'p-ml-2': isRTL })}></i>
                                            <span>{strings.wallet} <b style={{ color: props.context?.wallet?.balance > 0 ? 'green' : 'black' }}>({props.context?.wallet?.balance} SAR)</b></span>
                                        </button>
                                    </li>:'':''}

                                    <li onClick={() => {
                                        new AuthService().Logout();
                                    }} className="layout-topbar-action-item">
                                        <button className="p-d-flex p-flex-row p-ai-center p-link">
                                            <i className={classNames('pi pi-power-off', { 'p-mr-2': !isRTL, 'p-ml-2': isRTL })}></i>
                                            <span>Logout</span>
                                        </button>
                                    </li>

                                </ul>
                            </CSSTransition>
                        </li>

                    </ul>
                </div>
            </div>
            <Dialog visible={supportDialog} style={{ width: '450px' }} header={strings.Confirm} modal onHide={() => setSupportDialog(false)}>
                <SupportTicketForm close={() => setSupportDialog(false)} />
            </Dialog>

            <Dialog visible={referralDialog} style={{ width: '450px' }} header={strings.referrAFriend} modal onHide={() => setReferralDialog(false)}>
                <ReferralForm context={props.context} close={() => setReferralDialog(false)} />
            </Dialog>

            <Dialog visible={userUpdateForm} style={{ width: '450px' }} className="p-fluid" header={strings.Confirm} footer={userDialogFooter} modal onHide={() => setUserUpdateForm(false)}>

                {errors?.length > 0 && <Message text={errors.map((m) => <li>{m}</li>)} severity="error" />}

                <div className="p-field">
                    <label htmlFor="name">Email</label>
                    <h6>{user?.email}</h6>
                </div>

                <div className="p-field">
                    <label htmlFor="name">{strings.Name}</label>
                    <InputText id="name" value={userData?.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !userData?.name })} />
                    {submitted && !userData?.name && <small className="p-invalid">Name is required.</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="name">{strings.Phone}</label>

                    <ReactPhoneInput
                        defaultCountry={getCountry()}
                        excludeCountries={'il'}
                        autocompleteSearch={true}
                        value={userData?.phone}
                        onChange={(e) => onInputChange(e, 'phone')}
                    />
                    {submitted && !userData?.phone && <small className="p-invalid">Phone is required.</small>}
                    {/* <InputText id="name" value={userData?.phone} onChange={(e) => onInputChange(e, 'phone')} required autoFocus className={classNames({ 'p-invalid': submitted && !userData?.phone })} /> */}
                </div>

            </Dialog>


            <Dialog visible={walletForm} style={{ width: '450px' }} className="p-fluid" header={strings.walletBalance} footer={walletDialogFooter} modal onHide={() => setWalletForm(false)}>
                <div className="p-field" style={{ textAlign: 'center' }}>
                    <Badge size='xlarge' value={`${props.context.wallet?.balance} SAR`} severity={props.context.wallet?.balance > 0 ? 'success' : 'info'}></Badge>
                </div>
                <div className="p-field">
                    <hr />
                    <Message severity='info' text={strings.walletDescription}></Message>
                    <hr />
                </div>
            </Dialog>
            {/* <Dialog visible={scopeConfiguration} style={{ width: '450px' }} header={strings.changeScope} modal onHide={() => setScopeConfiguration(false)}>
                <ScopeConfiguration close={()=>setScopeConfiguration(false)}/>
            </Dialog> */}

        </div >
    );

}

export default AppTopbar;
