import ApiService from './ApiService';

export default class SupplierProductService extends ApiService {

    constructor() {
        super();
        this.path = '/supplierProduct';
    }

    async CreatesupplierProduct(data) {
        try {
            let op = await this.Create(this.path, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }


    async UpdatesupplierProduct(data) {

        try {
            let op = await this.Update(this.path+"/"+data.uuid, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async DeleteSupplierProduct(id) {
        try {
            let op = await this.Delete(this.path+"/"+id);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }


    async GetsupplierProduct(limit=10,offset=0,supplierId,filters="") {
        try {
            let link = `${this.path}/list?limit=${limit}&offset=${offset}&${filters}`
            let op = await this.Read(link);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }

    async GetBulkProducts(limit = 10, offset = 0, supplierId, filters = "") {
        try {

            let data = {
                limit,
                offset,
                supplierId: supplierId,
                ...filters
            }

            let link = `${this.path}/list`
            let op = await this.Create(link, data);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }

    async GetCount() {
        try {
            let link = `${this.path}/list?countOnly=true`
            let op = await this.Read(link);
            return op.data.count;
        } catch(e) {
            return e.response.data;
        }
    }
    async getProduct (payload) {
        try {
            let link = `${this.path}/${payload}`
            let op = await this.Read(link);
            return op.data
        } catch (e) {
            return e.response.data;
        }
    }
}


export const _ProductService = new SupplierProductService()
