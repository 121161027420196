import { createSlice } from "@reduxjs/toolkit";

const payments = createSlice({
    name: 'payments',
    initialState: { error: '',payments: {count: 0, data: []},  payment: {},paymentTypes:{count: 0, data: []} },
    reducers: {
        addData(state, action) {
            return { ...state, ...action.payload}
        },
        addError(state, action) {
            return { ...state, error: action.payload}
        }

    }

})

export default payments.reducer

export const {addData, addError} = payments.actions