import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import {SupplierForm} from '../SupplierForm';
import SupplierService from '../../service/SupplierService';
import { Customers } from '../Customers';
import { Product } from '../Products';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';
import strings from '../../localization';
import { SplitButton } from 'primereact/splitbutton';
import UserService from '../../service/UserService';
import Can, { check } from '../../Can';
import rules from '../../rbac-rules';
import { useSelector, connect } from 'react-redux';
import { deleteSuppliersHandler } from '../../store/suppliers'
import { TableFilters } from '../TableFilters';

const Suppliers = ({ deleteSuppliersHandler, context }) => {
    // let { pSuppliers } = useSelector(state => state.suppliers)
    const { role } = useSelector(state => state.user)
    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [product2Dialog, setProduct2Dialog] = useState(false);

    const [customerDialog, setCustomerDialog] = useState(false);

    const [deleteSupplierDialog, setDeleteSupplierDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [suppliers, setSuppliers] = useState(null);
    const [newUser, setNewUser] = useState({});
    const [supplier, setSupplier] = useState({});
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);

    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(25);
    const [loading, setLoading] = useState(false);
    const [preFilters, setPreFilters] = useState({});
    const [filters, setFilters] = useState({});

    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);



    const onFiltersChange = (e) => {
        let filters = { ...preFilters };
        filters[e.target.id] = e.target.value;
        setPreFilters(filters);
    }

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            onPage({ rows, page: 0, filters })
        }
    }, [filters]);

    useEffect(() => {
        onPage({ rows, page: 0 })
    }, [localStorage.getItem('subscription')]);

    const openNew = () => {

        setSubmitted(false);
        setProductDialog(true);
    }


    const openCustomer = (obj) => {

        // console.log("OpenCustomer:"+JSON.stringify(obj))

        setSupplier({
            ...obj,
            nameen: obj.translations[0]?.name,
            namear: obj.translations[1]?.name,
            uuid: obj.uuid,
            vat: obj.vatNumber
        })


        setCustomerDialog(true);
    }


    const openProduct = (obj) => {

        // console.log("OpenCustomer:"+JSON.stringify(obj))

        setSupplier({
            ...obj,
            nameen: obj.translations[0]?.name,
            namear: obj.translations[1]?.name,
            street1: obj.address.street,
            email: obj.email,
            phone: obj.phone,
            street2: obj.address.additionalStreet,
            buildNo: obj.address.buildingNumber,
            pdfFontColor: obj.pdfFontColor,
            headerBgColor: obj.headerBgColor,
            headerFontColor: obj.headerFontColor,
            additionalNumber: obj.address.additionalNumber,
            city: obj.address.city,
            uuid: obj.uuid,
            postalCode: obj.address.postalCode,
            state: obj.address.state,
            neighborhood: obj.address.neighborhood,
            vat: obj.vatNumber,
            vatGroup: obj.groupVatNumber,
            rows: obj.identities?.map((row) => {
                return {
                    "identityId": row.uuid,
                    "name": row.identityType.uuid,
                    "value": row.value
                };
            }),


        })


        setProduct2Dialog(true);
    }



    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);

        setSupplier(null);
    }


    const hideCustomerDialog = () => {
        setSubmitted(false);
        setCustomerDialog(false);

        //setUser(null);
    }

    const hideProduct2Dialog = () => {

        setProduct2Dialog(false);

        //setUser(null);
    }

    const hideDeleteSupplierDialog = () => {
        setDeleteSupplierDialog(false);
        setSupplier(null);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }


    const confirmDeleteSupplier = (supplier) => {

        setDeleteSupplierDialog(true);
        setSupplier(supplier);
    }


    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }


    const edit = (obj) => {

        setSupplier({
            ...obj,
            nameen: obj.translations[0]?.name,
            namear: obj.translations[1]?.name,
            vendor: obj.vendor,
            phone: obj.phone,
            uuid: obj.uuid,
            vat: obj.vatNumber,
            vendorId: obj.vendorId,
            street1: obj.address.street,
            street2: obj.address.additionalStreet,
            buildNo: obj.address.buildingNumber,
            additionalNumber: obj.address.additionalNumber,
            city: obj.address.city,
            postalCode: obj.address.postalCode,
            state: obj.address.state,
            neighborhood: obj.address.neighborhood,
            rows: obj.identities?.map((row) => {
                return {
                    "identityId":row.uuid,
                    "name": row.identityType.uuid,
                    "value": row.value
                };
            }),
        })

        setProductDialog(true);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Can yes={() => <Button label={strings.New} icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />}
                    perform={'suppliers:create'}
                    silent={true}
                    role={new UserService().GetRole()} />

                {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger p-mb-2" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
            </React.Fragment>
        )
    }



    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{strings.ID}</span>
                {rowData.address.supplierId}
            </>
        );
    }

    const nameenBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                {/* <img src={`${rowData.imageUrl}`} alt={rowData.imageUrl} className="product-image" /> */}
                {rowData.translations[0]?.name}
            </>
        )
    }

    const namearBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name ar</span>
                {/* <img src={`${rowData.imageUrl}`} alt={rowData.imageUrl} className="product-image" /> */}
                {rowData.translations[1]?.name}
            </>
        )
    }

    const cityBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">City</span>
                {rowData.address.city}
            </>
        );
    }

    const buildingNoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Vat Number</span>
                {rowData.vat ?? rowData.vatNumber}
            </>
        );
    }


    const creationDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Creation Date</span>
                {moment(rowData.createdAt).format('DD/MM/YYYY, h:mm:ss a')}
            </>
        );
    }



    const customersBodyTemplate = (rowData) => {
        return (
            <div className="actions" style={{ textAlign: "center" }}>
                <Button icon="pi pi-users" onClick={() => openCustomer(rowData)} className="p-button-rounded p-button-success p-mr-2" />
            </div>
        );
    }


    const ProductBodyTemplate = (rowData) => {
        return (
            <div className="actions" style={{ textAlign: "center" }}>
                <Button icon="pi pi-tags" onClick={() => openProduct(rowData)} className="p-button-rounded p-button-success p-mr-2" />

            </div>
        );
    }

    const actionBodyTemplate = (rowData) => {
        const items = []

        // var userService = new UserService();
        // var role = userService.GetRole();

        if (check(rules, role, 'suppliers:update')) {
            items.push({ label: strings.edit, icon: 'pi pi-pencil', command: () => edit(rowData) });
        }

        if (check(rules, role, 'customers:read')) {
            items.push({ label: strings.customers, icon: 'pi pi-users', command: (e) => openCustomer(rowData) });
        }

        if (check(rules, role, 'products:read')) {
            items.push({ label: strings.products, icon: 'pi pi-tags', command: (e) => openProduct(rowData) });
        }

        if (check(rules, role, 'suppliers:delete')) {
            items.push({ label: strings.DeleteSupplier, icon: 'pi pi-trash', command: (e) => confirmDeleteSupplier(rowData) });
        }

        items.push({ label: strings.copyId, icon: 'pi pi-copy', command: (e) => navigator.clipboard.writeText(rowData.uuid) });
        if(role=='super_admin') {
            items.push({ label: "Copy SID", icon: 'pi pi-copy', command:(e)=>  navigator.clipboard.writeText(rowData.sid) });
        }

        let first = items.shift();

        return first ? <SplitButton label={first.label} icon={first.icon} onClick={() => first.command()} model={items}></SplitButton> : ''
    }

    const onPage = ({ first,
        rows,
        page,
        filters,
        pageCount }) => {
        const supplierService = new SupplierService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setLoading(false);
        setTotalRecords(1);
        setPage(page);

        supplierService.GetSuppliers(rows, (page) * rows,'', filters).then(({ data, count }) => {
            setSuppliers(data);
        });

        setSupplier(null);

    }
    const deleteProduct = () => {
        // deleteSuppliersHandler(supplier)
        const supplierService = new SupplierService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        supplierService.DeleteSupplier(supplier.uuid).then((data) => {
            if(data.errorCode) {
                toast.current.show({ severity: 'error', summary: 'Cannot Delete Supplier', detail: data.message, life: 3000 });
                setLoading(false);
                hideDeleteSupplierDialog();
                return;
            }
            onPage({ rows, page: 0 })
        }).finally(()=>{
        }).catch(()=>setLoading(false));

        toast.current.show({ severity: 'success', summary: 'Delete Supplier', detail: `Deleted ${supplier.name} successfully`, life: 3000 });
        setLoading(false);
        supplierService.GetSuppliers(rows, (page) * rows).then(({ data, count }) => {
            setSuppliers(data);
            setTotalRecords(count);
        });
        hideDeleteSupplierDialog();
        setSupplier(null);

    }
    // useEffect(() => {
    //     setSuppliers(x => pSuppliers)
    // }, [pSuppliers])

    const header = (
        <>
            <div className="p-col">
                <div className="table-header">
                    <h5 className="p-m-0">{strings.ManageSuppliers}</h5>
                </div>
            </div>
            <div className="p-col-12">

                <TableFilters>

                    <div className="p-fluid p-grid">

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>

                            <label htmlFor="inputtext">{strings.Name}</label>

                            <InputText id="name" value={preFilters.name} onChange={onFiltersChange} />

                        </div>

                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.VatNumber}</label>

                            <InputText id="vatNumber" value={preFilters.vatNumber} onChange={onFiltersChange} />

                        </div>
                        <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.GroupVatNumber}</label>

                            <InputText id="groupVatNumber" value={preFilters.groupVatNumber} onChange={onFiltersChange} />

                        </div>

                        {new UserService().GetRole() == 'super_admin' && <div className="p-field p-col-5" style={{ margin: 'auto' }}>
                            <label htmlFor="inputtext">{strings.SubscriptionID}</label>
                            <InputText id="sid" value={preFilters.sid} onChange={onFiltersChange} />
                        </div>}

                        <div className="p-field p-col-1" style={{ margin: 'auto' }}>
                            <hr />

                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Filter} icon="pi pi-search" className="p-button-success p-mr-1 p-mb-1" onClick={() => {
                                let f = { ...preFilters }
                                setSupplier([])
                                // 2021-09-21T21:50:37.000Z
                                setFilters({
                                    name: f.name,
                                    vatNumber: f.vatNumber?.trim(),
                                    sid: f.sid?.trim(),
                                    groupVatNumber: f.groupVatNumber?.trim(),
                                });
                            }} />
                            <Button tooltipOptions={{
                                position: "top"
                            }} tooltip={strings.Clear} icon="pi pi-times" className="p-button-warning p-mr-1 p-mb-1" onClick={() => {
                                setPreFilters({});
                            }} />
                        </div>
                        <div className="p-field p-col-5" style={{ margin: 'auto' }}>
                        </div>
                    </div>
                </TableFilters>


            </div></>

    );

    const productDialogFooter = (
        <>
            <Button label={strings.Cancel} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );


    const customerDialogFooter = (
        <>
            <Button label={strings.Cancel} icon="pi pi-times" className="p-button-text" onClick={hideCustomerDialog} />
        </>
    );


    const product2DialogFooter = (
        <>
            <Button label={strings.Cancel} icon="pi pi-times" className="p-button-text" onClick={hideProduct2Dialog} />
        </>
    );

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSupplierDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} ></Toolbar>

                    <DataTable ref={dt}
                        value={suppliers}
                        selection={selectedProducts}
                        dataKey="id"
                        totalRecords={totalRecords}
                        lazy
                        autoLayout={true}
                        first={first}
                        loading={loading}
                        onPage={onPage}
                        paginator rows={rows}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        //    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Suppliers"
                        globalFilter={globalFilter}
                        emptyMessage={strings.NoSuppliersFound}
                        header={header}>
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                        <Column field="nameen" header={strings.NameEnglish} body={nameenBodyTemplate} ></Column>
                        <Column field="namear" header={strings.NameArabic} body={namearBodyTemplate} ></Column>
                        {/* <Column field="city" header="City"body={cityBodyTemplate}></Column> */}
                        <Column field="vat" header={strings.VatNumber} body={buildingNoBodyTemplate}></Column>
                        <Column field="createdAt" header={strings.CreationDate} body={creationDateBodyTemplate}></Column>
                        {/* <Column header={strings.Customers} body={customersBodyTemplate}></Column>
                        <Column header={strings.Products} body={ProductBodyTemplate}></Column> */}

                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: '700px' }} header={strings.SuppliersDetails} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <SupplierForm context={context} type='purchase' OnHideDialog={hideDialog} supplier={supplier} FillData={() => onPage({ rows, page: 0 })} />
                    </Dialog>

                    <Dialog visible={customerDialog} style={{ width: '1400px' }} header={strings.Customers} modal className="p-fluid" footer={customerDialogFooter} onHide={hideCustomerDialog}>
                        <Customers supplier={supplier} />
                    </Dialog>


                    <Dialog visible={product2Dialog} style={{ width: '1400px' }} header={strings.Products} modal className="p-fluid" footer={customerDialogFooter} onHide={hideProduct2Dialog}>
                        <Product supplier={supplier} />
                    </Dialog>

                    <Dialog visible={deleteSupplierDialog} style={{ width: '450px' }} header={strings.Confirm} modal footer={deleteProductDialogFooter} onHide={hideDeleteSupplierDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {<span>Are you sure you want to delete <b>{supplier?.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header={strings.Confirm} modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {/* {user && <span>Are you sure you want to delete the selected products?</span>} */}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default connect(null, { deleteSuppliersHandler })(Suppliers)

