import { config } from 'react-transition-group';
import ApiService from './ApiService';
let api = process.env.REACT_APP_API_URL

export default class PlatformService extends ApiService {

    constructor() {
        super();
        this.path = '/management';
    }

    async GetPlatformSettings() {
        try {
            let {data} = await this.Read(`${this.path}/platformSettings`);
            return data;
        } catch(e) {
            return e.response.data;
        }
    }
    async GetPlatformSetting(name) {
        try {
            let {data} = await this.Read(`${this.path}/platformSettings/${name}`);
            return data;
        } catch(e) {
            return e.response.data;
        }
    }
    async UpdateOrCreatePlatformSetting(body) {
        try {
            let {data} = await this.Create(`${this.path}/platformSettings`, body);
            return data;
        } catch(e) {
            return e.response.data;
        }
    }
    async DeletePlatformSetting(name) {
        try {
            let {data} = await this.Delete(`${this.path}/platformSettings/${name}`);
            return data;
        } catch(e) {
            return e.response.data;
        }
    }
}
