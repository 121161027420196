import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import CurrencyRateService from '../service/CurrencyRateService';
import { Dropdown } from 'primereact/dropdown';
import strings from '../localization';
import { Message } from 'primereact/message';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { TableFilters } from './TableFilters';
import {getCurrency} from '../service/helpers'

export const CurrencyRates = () => {

    const defaultBaseCurrency = { code: getCurrency(), name: strings[getCurrency()] };


    const currencyCodes = [
        { code: getCurrency(), name: getCurrency() },
        { code: 'USD', name: 'USD' },
        { code: 'EUR', name: 'EUR' },
        { code: 'AED', name: 'AED' },
        { code: 'KWD', name: 'KWD' },
    ]
    let emptyCurrencyRate = {
        "rate": 0.0,
        "sourceCurrency": defaultBaseCurrency,
        "targetCurrency": "",
    };

    const [currencyRateDialog, setCurrencyRateDialog] = useState(false);
    const [currencyRate, setCurrencyRate] = useState(emptyCurrencyRate);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currencyRates, setCurrencyRates] = useState([]);
    const [preFilters, setPreFilters] = useState({});
    const [filters, setFilters] = useState({});
    const [first, setFirst] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        onPage({ rows, page: 0 })
    }, []);

    const openNew = () => {
        setCurrencyRate(emptyCurrencyRate);
        setSubmitted(false);
        setErrors([]);
        setCurrencyRateDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setCurrencyRateDialog(false);
        setErrors([]);
    }



    const currencies = [
        { code: getCurrency(), name: strings[getCurrency()] },
        { code: 'USD', name: strings.USD },
        { code: 'EUR', name: strings.EUR },
        { code: 'AED', name: 'AED' },
        { code: 'KWD', name: 'KWD' },
    ]

    const handleErrors = (res)=>{
        let errs = [];
        Object.keys(res.errors).forEach(key=>{
            errs.push(res.errors[key]);
        })

        setErrors(errs);
    }

    const saveCurrencyRate = async () => {
        setSubmitted(true);

        var currencyRateService = new CurrencyRateService();

        currencyRate.sourceCurrency = currencyRate.sourceCurrency?.code ?? currencyRate.sourceCurrency;
        currencyRate.targetCurrency = currencyRate.targetCurrency?.code ?? currencyRate.targetCurrency;

        if (currencyRate.uuid) {
            var res = await currencyRateService.UpdateCurrencyRate(currencyRate);
            if (res.errors) {
                handleErrors(res)
            }
            else {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Currency Rate Updated', life: 3000 });
                onPage({ rows, page })
                // setCurrencyRates(_currencyRates);
                setCurrencyRateDialog(false);
                setCurrencyRate(emptyCurrencyRate);
            }
        }
        else {
            var res = await currencyRateService.CreateCurrencyRate(currencyRate);
            if (res.errors) {
                handleErrors(res)
            }
            else {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Currency Rate Created', life: 3000 });
                onPage({ rows, page })
                // setCurrencyRates(_currencyRates);
                setCurrencyRateDialog(false);
                setCurrencyRate(emptyCurrencyRate);
            }
        }
    }

    const editCurrencyRate = (currencyRate) => {
        setCurrencyRate({ ...currencyRate });
        setCurrencyRateDialog(true);
    }


    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            setLoading(true);
            onPage({rows,page:0})
        }
    }, [filters]);


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _currencyRate = { ...currencyRate };
        _currencyRate[`${name}`] = val;

        setCurrencyRate(_currencyRate);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        // return (
        //     <React.Fragment>
        //         <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" />
        //         <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
        //     </React.Fragment>
        // )
    }





    const baseCurrencyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Source</span>
                {rowData.sourceCurrency}
            </>
        );
    }

    const quoteCurrencyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Target</span>
                {rowData.targetCurrency}
            </>
        );
    }

    const rateTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Rate</span>
                {rowData.rate}
            </>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {rowData.sourceCurrency != rowData.targetCurrency && <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editCurrencyRate(rowData)} />}
            </div>
        );
    }

    const onPage = ({ first,
        rows,
        page }) => {
        const currencyRateService = new CurrencyRateService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        currencyRateService.GetCurrencyRates(rows, (page) * rows, filters).then(({ data, count }) => {
            setLoading(false);
            setCurrencyRates(data);
            setTotalRecords(count);
        });

    }

    const onFiltersChange = (e) => {
        let filters = { ...preFilters };
        filters[e.target.id] = e.target.value;
        setPreFilters(filters);
    }


    const header = (
        <>
            <div className="p-col">

                <div className="table-header">

                    <h5 className="p-m-0">{strings.currencyRates}</h5>
                </div>

            </div>

            <div className="p-col-12">

                <TableFilters>

                        <div className="p-fluid p-grid">


                            <div className="p-field p-col-5" style={{ margin: 'auto' }}>

                                <label htmlFor="inputtext">{strings.baseCurrency}</label>
                                <Dropdown style={{ width: '100%' }} id="sourceCurrency" value={preFilters.sourceCurrency} onChange={onFiltersChange} options={currencyCodes} optionLabel="name" ></Dropdown>
                            </div>

                            <div className="p-field p-col-5" style={{ margin: 'auto' }}>
                                <label htmlFor="inputtext">{strings.quoteCurrency}</label>
                                <Dropdown style={{ width: '100%' }} id="targetCurrency" value={preFilters.targetCurrency} onChange={onFiltersChange} options={currencyCodes} optionLabel="name" ></Dropdown>
                            </div>

                            <div className="p-field p-col-2" style={{ margin: 'auto' }}>
                                <hr />

                                <Button tooltipOptions={{
                                    position: "top"
                                }} tooltip={strings.Filter} icon="pi pi-search" className="p-button-success p-mr-1 p-mb-1" onClick={() => {
                                    let f = { ...preFilters }
                                    // 2021-09-21T21:50:37.000Z
                                    setFilters({
                                        ...f,
                                        targetCurrency: f.targetCurrency?.code,
                                        baseCurrency: f.targetCurrency?.code
                                    });
                                }} />
                                <Button tooltipOptions={{
                                    position: "top"
                                }} tooltip={strings.Clear} icon="pi pi-times" className="p-button-warning p-mr-1 p-mb-1" onClick={() => {
                                    setPreFilters({});
                                }} />
                            </div>
                        </div>
                        </TableFilters>
            </div>
        </>
    );

    const currencyRateDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveCurrencyRate} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt}
                        value={currencyRates}
                        dataKey="id"
                        totalRecords={totalRecords}
                        lazy
                        first={first}
                        loading={loading}
                        autoLayout={true}
                        onPage={onPage}
                        paginator rows={rows}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        globalFilter={globalFilter}
                        emptyMessage={strings.Norecordsfound}
                        header={header}>
                        <Column field="source" header={strings.baseCurrency} body={baseCurrencyTemplate}></Column>
                        <Column field="target" header={strings.quoteCurrency} body={quoteCurrencyTemplate}></Column>
                        <Column field="rate" header={strings.rate} body={rateTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={currencyRateDialog} style={{ width: '450px' }} header="" modal className="p-fluid" footer={currencyRateDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            {errors?.length > 0 && <Message style={{marginBottom:'10px'}} text={errors.map((m) => <li>{m}</li>)} severity="error" />}
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">{strings.baseCurrency}</label>
                            <Dropdown disabled id="sourceCurrency" value={defaultBaseCurrency} onChange={(e) => onInputChange(e, 'sourceCurrency')} options={currencies.filter(c=>c.code!=currencyRate.targetCurrency?.code)} optionLabel="name" placeholder="Select One"></Dropdown>
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">{strings.quoteCurrency}</label>
                            <Dropdown id="targetCurrency" value={currencies.filter(c=>((currencyRate.targetCurrency?.code)??currencyRate.targetCurrency)==c.code)[0]} onChange={(e) => onInputChange(e, 'targetCurrency')} options={currencies.filter(c=>c.code!=currencyRate.sourceCurrency?.code&&c.code!=defaultBaseCurrency.code)} optionLabel="name" placeholder="Select One"></Dropdown>
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">{strings.rate}</label>
                            <InputText id="name" value={currencyRate.rate} onChange={(e) => onInputChange(e, 'rate')} required autoFocus className={classNames({ 'p-invalid': submitted && !currencyRate.percent })} />
                        </div>
                        <div className="p-field">
                            {getCurrency()=='SAR' ? <b>* 1 SAR = 0.266$</b> : <b>* 1 BHD = 2.65$</b>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
