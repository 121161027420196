import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import CurrencyRateService from '../service/CurrencyRateService';
import { Dropdown } from 'primereact/dropdown';
import strings from '../localization';
import { Message } from 'primereact/message';
import { Accordion, AccordionTab } from 'primereact/accordion';

export const TableFilters = (props) => {

    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <AccordionTab header={<React.Fragment><i className="pi pi-search"></i>&nbsp;<span>{strings.filters}</span></React.Fragment>}>
                {props.children}
            </AccordionTab>
        </Accordion>
    );
}
