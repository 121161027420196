import ApiService from './ApiService';
import querystring from 'query-string';

export default class SupplierService extends ApiService {

    constructor() {
        super();
        this.path = '/supplier';
    }

    async CreateSupplier(data) {
        try {
            let op = await this.Create(this.path, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }


    async UpdateSupplier(data) {

        try {

            let op = await this.Update(this.path+"/"+data.uuid, data);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }
    async DeleteSupplier(uuid) {

        try {

            let op = await this.Delete(this.path+"/"+uuid);
            return op.data;
        } catch(e) {
            return e.response.data;
        }
    }


    async GetSuppliers(limit = 500, offset = 0, filters = {}) {
        try {
            let link = `${this.path}/list?limit=${limit}&offset=${offset}&${querystring.stringify(filters)}`
            let op = await this.Read(link);
            return op.data;
        } catch (e) {
            return e.response.data;
        }
    }

    async GetCount() {
        try {
            let link = `${this.path}/list?countOnly=true`
            let op = await this.Read(link);
            return op.data.count;
        } catch(e) {
            return e.response.data;
        }
    }
}

export const _SupplierService =  new SupplierService()
