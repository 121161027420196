import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { SplitButton } from 'primereact/splitbutton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useEffect, useRef, useState } from 'react';
import Can, { check } from '../Can';
import strings from '../localization';
import rules from '../rbac-rules';
import QuotationService from '../service/QuotationService';
import UserService from '../service/UserService';
import VendorService from '../service/VendorService';
import { formatDateStringLocal } from './InvoiceDates';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import { AutoComplete } from 'primereact/autocomplete';
import { useHistory } from 'react-router-dom';
import CustomerService from '../service/CustomerService';
import { TableFilters } from './TableFilters';
import { defaultTableConfiguration } from './TableConfiguration';

export const Quotations = ({context}) => {
    const [deleteQuotationDialog, setDeleteQuotationDialog] = useState(false);
    const [quotations, setQuotations] = useState(null);
    const [quotation, setQuotation] = useState(null);
    const history = useHistory();
    const [preFilters, setPreFilters] = useState({});
    const [filters, setFilters] = useState({});
    const [totalRecords, setTotalRecords] = useState(0);
    const [vendors, setVendors] = useState(null);
    const [typingTimeout2, setTypingTimeout2] = useState(null);

    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [importStatus, setImportStatus] = useState(null);
    const [importError, setImportError] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    var {vendor, showCompany: full,role} = context;


    useEffect(() => {
        onPage({ rows, page: 0,vendorID:vendor?.uuid})
    }, [localStorage.getItem('subscription')]);

    const hideDeleteQuotationDialog = () => {
        setDeleteQuotationDialog(false);
        setQuotation(null);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Can yes={() => <Button label={strings.New} icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={() => history.push('/profile/quotations/new')} />}
                    perform={'quotations:create'}
                    silent={true}
                    role={role} />
                {/* <Can yes={() => <Button disabled={importStatus != null && importStatus != 4} label={strings.ImportFromExcel} icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openImportModal} />}
                    perform={'quotations:importBulk'}
                    silent={true}
                    role={role} /> */}
                {/* { importStatus != null && <> {importStatus != 4 && <ProgressSpinner />} <Message severity={importStatus == 4 ? "success" : "info"} content={importStatus == 1 ? strings.QuotationsBulkUploadInit : (importStatus == 2 ? strings.QuotationsBulkUploadInProgress : strings.QuotationsBulkUploadSuccessfull)} /></>}
                {importError && <Message severity="error" content={importError}></Message>} */}
            </React.Fragment>
        )
    }



    const quotationNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{strings.quotationNumber}</span>
                {rowData.quotationNumber}
            </>
        );
    }
    const referenceNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{strings.referenceNumber}</span>
                {rowData.referenceNumber}
            </>
        );
    }




    const vendorBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Vendor</span>
                {rowData.vendor?.name}
            </>
        );
    }
    const customerBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{strings.customer}</span>
                {rowData.customer?.registrationName}
            </>
        );
    }

    const barcodeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {rowData.taxInclusiveAmount} {rowData.currency}
            </>
        );
    }

    const deleteQuotation = () => {
        const quotationService = new QuotationService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        quotationService.DeleteQuotation(quotation.uuid).then((data) => {
            if (data.errorCode) {
                toast.current.show({ severity: 'error', summary: 'Cannot Delete Quotation', detail: data.message, life: 3000 });
                setLoading(false);
                hideDeleteQuotationDialog();
                return;
            }
            toast.current.show({ severity: 'success', summary: 'Delete Quotation', detail: `Deleted ${quotation.name} successfully`, life: 3000 });
            quotationService.GetQuotations(rows, (page) * rows).then(({ data, count }) => {
                setLoading(false);
                setQuotations(data);
                setTotalRecords(count);
            });
        }).finally(() => {
            hideDeleteQuotationDialog();
        }).catch(() => setLoading(false));
        setQuotation(null);
    }
    const deleteQuotationDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteQuotationDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteQuotation} />
        </>
    );

    const creationDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date</span>
                {formatDateStringLocal(rowData.quotationDate)}
            </>
        );
    }


    const confirmDeleteQuotation = (quotation) => {

        setDeleteQuotationDialog(true);
        setQuotation(quotation);
    }

    const actionBodyTemplate = (rowData) => {
        const items = []

        var userService = new UserService();
        var role = userService.GetRole();

        if (check(rules, role, 'quotations:update')) {
            items.push({ label: strings.details, icon: 'pi pi-pencil', command:()=>history.push(`/profile/quotations/${rowData.uuid}`) });
        }

        if (check(rules, role, 'quotations:delete')) {
            items.push({ label: strings.deleteQuotation, icon: 'pi pi-trash', command:()=>confirmDeleteQuotation(rowData) });
        }
        if (check(rules, role, 'invoices:route') && rowData.linkedInvoiceId) {
            items.push({ label: strings.checkDraftInvoice, icon: 'pi pi-info', command:()=>history.push(`/profile/invoices/${rowData.linkedInvoiceId}`) });
        }

        items.push({ label: strings.copyId, icon: 'pi pi-copy', command:()=>  navigator.clipboard.writeText(rowData.uuid) });
        if(role=='super_admin') {
            items.push({ label: "Copy SID", icon: 'pi pi-copy', command:(e)=>  navigator.clipboard.writeText(rowData.sid) });
        }

        let first = items.shift();

        return first ? <SplitButton label={first.label} icon={first.icon} onClick={()=>first.command()} model={items}></SplitButton> : ''

        // return (
        //     <div className="actions">
        //         <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => edit(rowData)} />
        //         <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteQuotation(rowData)} />
        //         {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteQuotation(rowData)} /> */}
        //     </div>
        // );
    }

    function changeVendorSearchBar (e) {
        if (typingTimeout2) {
            clearTimeout(typingTimeout2);
        }

        setTypingTimeout2(setTimeout(()=> {

            var vendorService = new VendorService();
            vendorService.GetVendors(100, 0, {simple:true, name:e.query}).then((data) => {
                if (!data.data) return [];
                return data.data.map(v => {
                    return { code: v.uuid, name: v.name, ...v }
                });
            }).then(data => {
                setVendors(
                    [{ code: null, name: '-' }, ...data]);
            });
        }, 200))

    }
    function changeCustomerSearchBar (e) {
        if (typingTimeout2) {
            clearTimeout(typingTimeout2);
        }

        setTypingTimeout2(setTimeout(()=> {

            var customerService = new CustomerService();
            customerService.GetCustomers(100, 0, preFilters.vendor?.code, {simple:true, name:e.query}).then((data) => {
                if (!data.data) return [];
                return data.data.map(v => {
                    return { code: v.uuid, name: v.name, ...v }
                });
            }).then(data => {
                setCustomers(
                    [{ code: null, name: '-' }, ...data]);
            });
        }, 200))

    }

    const onPage = ({ first,
        rows,
        page }) => {
        const quotationService = new QuotationService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        quotationService.GetQuotations(rows, (page) * rows, vendor?.uuid??'').then(({ data, count }) => {
            setLoading(false);
            setQuotations(data);
            setTotalRecords(count);
        });

        setQuotation(null);

    }

    const onFiltersChange = (e) => {
        let filters = { ...preFilters };
        filters[e.target.id] = e.target.value;
        setPreFilters(filters);
    }

    function formatDate(date) {
        if (!date) return null;
        return new Date(moment(date).format('yyyy-MM-DD HH:mm:ss'))
    }

    useEffect(() => {
        if(vendors == null)
        {
            var vendorService = new VendorService();
            if (full) {
                vendorService.GetVendors(500).then((data) => {
                    if (!data.data) {
                        return [{ code: null, name: '-' }]
                    }
                    return data.data.map(v => {
                        return { code: v.uuid, name: v.name, ...v }
                    });
                }).then(data => {
                    setVendors(
                        [{ code: null, name: '-' }, ...data]);
                });
            }
        }
    }, []);

    useEffect(() => {
        if (customers == null) {
            var customerService = new CustomerService();
            customerService.GetCustomers(500, 0, quotation.vendor?.code, {simple:true}).then((data) => {
                if (!data.data) return [];
                return data.data.map(v => {
                    return { code: v.uuid, name: v.name, ...v }
                });
            }).then(data => {
                setCustomers(
                    [{ code: null, name: '-' }, ...data]);
            });
        }
 }, []);

    useEffect(() => {
        if (Object.keys(filters).length > 0) {
            const quotationService = new QuotationService();
            setLoading(true);
            quotationService.GetQuotations(rows, 0, filters).then(({ data, count }) => {
                setTotalRecords(count);
                setLoading(false);
                setQuotations(data)
            });
        }
    }, [filters]);

    const header = (

        <div className="p-grid">

            <div className="p-col">

                <div className="table-header">

                    <h5 className="p-m-0">{strings.ManageQuotations}</h5>
                </div>

            </div>

            <div className="p-col-12">

                <div className="p-fluid p-grid">

                    <div className="p-field p-col-12" style={{ margin: 'auto' }}>
                        <TableFilters>
                                <div className="p-fluid p-grid">
                                    {full && <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                                        <label htmlFor="calendar">{strings.Company}</label>
                                        <AutoComplete dropdown placeholder={strings.filterSearchPlaceHolder} style={{ width: "100%" }} id="vendor" value={preFilters.vendor} suggestions={vendors} field="name" completeMethod={changeVendorSearchBar} onChange={(onFiltersChange)} />
                                        {/* <InputText id="companyName" value={preFilters.companyName} onChange={onFiltersChange} /> */}
                                    </div>}
                                    {full && <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                                        <label htmlFor="calendar">{strings.Customer}</label>
                                        <AutoComplete dropdown placeholder={strings.filterSearchPlaceHolder} style={{ width: "100%" }} id="customer" value={preFilters.customer} suggestions={customers} field="name" completeMethod={changeCustomerSearchBar} onChange={(onFiltersChange)} />
                                        {/* <InputText id="companyName" value={preFilters.companyName} onChange={onFiltersChange} /> */}
                                    </div>}


                                    <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                                        <label htmlFor="inputtext">{strings.quotationNumber}</label>

                                        <InputText id="quotationNumber" value={preFilters.quotationNumber} onChange={onFiltersChange} />

                                    </div>

                                    <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                                        <label htmlFor="inputtext">{strings.referenceNumber}</label>
                                        <InputText id="referenceNumber" value={preFilters.referenceNumber} onChange={onFiltersChange} />
                                    </div>
                                    <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                                        <label htmlFor="calendar">{strings.amountFrom}</label>
                                        <InputText type="number" id="amountGreaterThan" value={preFilters.amountGreaterThan} onChange={onFiltersChange} />
                                    </div>

                                    <div className="p-field p-col-3" style={{ margin: 'auto' }}>
                                        <label htmlFor="calendar">{strings.amountTo}</label>
                                        <InputText type="number" id="amountLessThan" value={preFilters.amountLessThan} onChange={onFiltersChange} />
                                    </div>



                                    <div className={`p-field p-col-2`} style={{ margin: 'auto' }}>
                                        <label htmlFor="calendar">{strings.dateFrom}</label>
                                        <Calendar id="quotationDateAfter" showTime={true} showSeconds={true} showIcon showButtonBar value={formatDate(preFilters.quotationDateAfter)} onChange={onFiltersChange} />
                                    </div>

                                    <div className={`p-field p-col-2`} style={{ margin: 'auto' }}>
                                        <label htmlFor="calendar">{strings.dateTo}</label>
                                        <Calendar id="quotationDateBefore" showTime={true} showSeconds={true} showIcon showButtonBar value={formatDate(preFilters.quotationDateBefore)} onChange={onFiltersChange} />
                                    </div>
                                    <div className="p-field p-col" style={{ margin: 'auto' }}>
                                        <hr />

                                        <Button tooltipOptions={{
                                            position: "top"
                                        }} tooltip={strings.Filter} icon="pi pi-search" className="p-button-success p-mr-1 p-mb-1" onClick={() => {
                                            let f = { ...preFilters }
                                            // 2021-09-21T21:50:37.000Z
                                            setFilters({
                                                ...f,
                                                quotationDateAfter: f.quotationDateAfter ? moment(f.quotationDateAfter).utc().format() : null,
                                                quotationDateBefore: f.quotationDateBefore ? moment(f.quotationDateBefore).utc().format() : null,
                                                vendorId: f.vendor?.code,
                                                customerId: f.customer?.code
                                            });
                                        }} />
                                        <Button tooltipOptions={{
                                            position: "top"
                                        }} tooltip={strings.Clear} icon="pi pi-times" className="p-button-warning p-mr-1 p-mb-1" onClick={() => {
                                            setPreFilters({});
                                        }} />
                                    </div>
                                </div>
                            </TableFilters>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} ></Toolbar>
                    {importStatus != null && <> {importStatus != 4 && <ProgressSpinner />} <Message severity={importStatus == 4 ? "success" : "info"} content={importStatus == 1 ? strings.QuotationsBulkUploadInit : (importStatus == 2 ? strings.QuotationsBulkUploadInProgress : strings.QuotationsBulkUploadSuccessfull)} /></>}
                    {importError && <Message severity="error" content={importError}></Message>}

                    <DataTable
                        {...defaultTableConfiguration}
                        // ref={dt}
                        value={quotations}
                        totalRecords={totalRecords}
                        first={first}
                        loading={loading}
                        onPage={onPage}
                        rows={rows}
                        header={header}>
                        <Column field="quotationNumber" header={strings.quotationNumber} body={quotationNumberBodyTemplate}></Column>
                        <Column field="referenceNumber" header={strings.referenceNumber} body={referenceNumberBodyTemplate}></Column>

                        {/* <Column field="peppol" header={strings.PEPPOL} body={skuBodyTemplate}></Column> */}
                        {full && <Column field="vendor" header={strings.Vendor} body={vendorBodyTemplate}></Column>}
                        {full && <Column field="customer" header={strings.Customer} body={customerBodyTemplate}></Column>}
                        <Column field="amount" header={strings.amount} body={barcodeBodyTemplate}></Column>
                        <Column field="quotationDate" header={strings.date} body={creationDateBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={deleteQuotationDialog} style={{ width: '450px' }} header={strings.deleteQuotation} modal footer={deleteQuotationDialogFooter} onHide={hideDeleteQuotationDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {<span>Are you sure you want to delete <b>{quotation?.name}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
