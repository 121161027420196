import React, { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import InvoiceService from '../../service/InvoiceService';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import strings from '../../localization'
import { deletePaymentHandler } from '../../store/payments';
import RefundDialog from '../refunds/RefundDialog';
import { getInvoiceHandler } from '../../store/invoices';
import {getPaymentsHandler} from '../../store/payments'
import {deletePayment, getPayments} from '../../store/actions/SalesPayment'
import {createRefund, updateRefund} from '../../store/actions/refundAction'

const Payment = ({ deletePaymentHandler,getInvoiceHandler,getPayments,deletePayment,createRefund, updateRefund }) => {
    const {invoice} = useSelector(state => state.invoices)
    const toast = useRef(null);
    const dialog = useRef(null);
    const { payments:{data: payments, count} } = useSelector(state => state.payments)
    const { invoices } = useSelector(state => state.invoices)
    const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');
    const [loading, setLoading] = useState(true);
    const [forRefund, setForRefund] = useState({show: false, item: {}})
    // console.log("🚀 ~ file: payments.jsx ~ line 12 ~ invoices", invoices[0].originalInvoiceNumber)
    const history = useHistory()
    const [selectedProducts7, setSelectedProducts7] = useState(null);
    const [paymentsDetails, setPaymentsDetails] = useState([])
    const [pageParameters,setPageParameters] = useState({first: 0, rows: 10, page: 0, pageCount: Math.ceil(count/10)})
const {first, rows, page, pageCount} = pageParameters


    const dateFilterTemplate = (options) => {

        return <Calendar value={options.value} onChange={e => console.log(e)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />
    }
    const forRefundHandler = (rawData)=>{
        setForRefund({show: true, item: rawData})
    }

    useEffect(()=>{
         Promise.all([getPayments()]).then(()=> setLoading(false))
    },[])
 
    const accept = (rowData) => {
        toast.current?.show({ severity: 'info', summary: strings.Confirm, detail: strings.deletedSuccessfully, life: 3000 });
        deletePayment(rowData.uuid)
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }
    const confirm = (rowData) => {
        confirmDialog({
            message: strings.confirmDelete,
            header: strings.deleteConfirmation,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => accept(rowData),
            acceptLabel: strings.Yes,
            rejectLabel: strings.No
        });

    };

    const actionBody = (rowData) => {

        return (

            <React.Fragment>
                {/* <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} /> */}
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning p-button-text" title={strings.deletePayment} onClick={() => confirm(rowData)} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-secondary p-button-text" title={strings.edit} onClick={() => history.push(`/profile/payments/payment/${rowData.uuid}`)} />
                {/* <Button icon="pi pi-credit-card" className="p-button-rounded p-button-success p-button-text" title='payment' /> */}
                <Button icon="pi pi-undo" className="p-button-rounded p-button-success p-button-text" title={strings.refund} onClick={() => forRefundHandler(rowData)} />
            </React.Fragment>



        )
    }
    const dateBody = (date) =>{
        return (
            <span>{new Date(date.createdAt).toLocaleString()}</span>
        )
    }

    const onPageHandler = (e) => {
      setPageParameters({...e, pageCount: Math.ceil(count/e.rows)})
        
    }
    const template2 = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    {/* <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </span> */}
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            )
        }
    };
    return (
        <>
            <Toast ref={toast} />
            <RefundDialog visible={forRefund.show} item={forRefund.item} onHide={() => setForRefund({...forRefund, show: false})} createRefund={createRefund}  updateRefund={updateRefund} />
            <Button label={strings.recordPayment} icon="pi pi-plus" onClick={() => history.push('/profile/payments/new')} />
            <h3 style={{ margin: '2rem auto' }}>{strings.payments}</h3>
            <DataTable value={payments} paginator rows={rows} rowsPerPageOptions={[5,10,20,50]} selection={selectedProducts7} loading={loading} onSelectionChange={e => setSelectedProducts7(e.value)} dataKey="uuid" responsiveLayout="scroll" globalFilterFields={['name', 'country.name', 'representative.name', 'status']} onPage={onPageHandler} paginatorTemplate={template2} first={first}>
                <Column selectionMode="multiple" headerStyle={{ width: '3em' }} ></Column>
                <Column field="createdAt" dataType="date" header={strings.date} body={dateBody}></Column>
                {/* <Column field="uuid" header={strings.ID} showFilterMenu={false}></Column> */}
                {/* <Column field="invoiceNumber" header={strings.InvoiceNumber} ></Column> */}
                <Column field="vendor.name" header={strings.Vendor}  ></Column>
                <Column field="amount" header={strings.amount}></Column>
                <Column field="paymentType.name" header={strings.paymentType}> </Column>
                <Column header={strings.Action} body={actionBody}></Column>
            </DataTable>
        </>
    )
}

export default connect(null, { deletePaymentHandler,getInvoiceHandler ,getPayments,deletePayment,createRefund, updateRefund})(Payment)