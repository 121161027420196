import Refund from './index'
import React, {useState, useEffect} from 'react'
import { connect, useSelector } from 'react-redux'
import RefundDialog from './RefundDialog'
import { getRefunds, deleteRefund, updateRefund } from '../../store/actions/supplierRefundAction'

export const ExpenseRefund = ({getRefunds,deleteRefund,updateRefund}) => {
  const {refunds:{ data: refunds, count}} = useSelector(state => state.supplierRefunds)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
      Promise.all([getRefunds()]).finally(() => setLoading(false))
  }, [])
  return (
    <>
    
      <Refund type="expense" data={refunds} loading={loading} onDelete={deleteRefund} onUpdate={updateRefund} count={count} dialogType='purchase'/>
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {getRefunds,deleteRefund,updateRefund}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseRefund)
