const ar = {
    signinMessage: "يرجى استخدام النموذج لتسجيل الدخول إلى منصة ARMS للفواتير الإلكترونية",
    welcome: "مرحبا",
    email: "البريد الالكتروني",
    fillOtp: "الرجاء تعبئة كلمة المرور لمرة واحدة من 6 خانات:",
    password: "كلمة المرور",
    Account: "الحساب",
    complianceStatus: "حالة الزكاة",
    TotalAmountsInWords: "المجموع تفقيطا",
    subscriptionUsers: 'مستخدمين الاشتراك',
    pickInvoices: 'اختر الفواتير',
    draft: 'مسودة',
    subscriptions: 'الاشتراكات',
    cleared: "مصفاة",
    reported: "مبلغ عنها",
    sales: 'المبيعات',
    clearInvoice: "تخليص الفاتورة",
    reportInvoice: "اقرار الفاتورة",
    StartOnboarding: "البدأ بالربط مع الزكاة",
    GenerateCertificates: "انشاء شهادات الربط",
    signInvoiceInProgress: "جاري توقيع الفاتورة الكترونيا",
    signInvoice: "توقيع الفاتورة الكترونيا",
    ZatcaVatReports: "الاقرارات الضريبية",
    zatcaEffectiveDate: "تاريخ تكامل المرحلة الثانية",
    realizedMessage: "صافي الدخل = مجموع المدفوعات - مجموع المدفوعات للموردين - المصاريف",
    nonRealizedMessage: "صافي الدخل = مجموع الفواتير - مجموع طلبات الشراء - المصاريف",
    pnlReports: "تقارير الربح والخسارة",
    isRealized: "ارقام محققة",
    netIncome: 'صافي الدخل',
    documentDiscount: "خصم / عقوبة على مستوى الفاتورة",
    ConfirmOtpText: "هل أنت متأكد من إنشاء شهادات الربط الخاصة بالزكاة؟",
    purgeWarning: "تحذير: بتحديد هذا الخيار ، ستتم إعادة تهيئة جميع الشهادات مرة أخرى",
    purgeCerts: "قم بإزالة الشهادات الموجودة",
    ManageExpenses: "ادارة المصاريف",
    charges: 'الدفعات',
    PreComplianceCertificates: "Generate Pre-Compliance Certificates",
    PreComplianceCertificatesText: "Step 1: Generate CSR, Private and Public Key Pair, and Compliance CSID.",
    automaticZatcaIntegration: "دفع الفواتير إلى ZATCA تلقائيًا.",
    SyncCertificatesText: "هل أنت متأكد من مزامنة شهادات ZATCA؟ إذا كان كل شيء يعمل بشكل صحيح بالنسبة لك ، فيرجى تجنب عملية المزامنة.",
    certsNotInitialized: "لم يتم اصدار الشهادات الرقمية",
    certificateStatus: "حالة الشهادات الرقمية",
    totalInSar: "المبلغ (ريال)",
    syncCerts: "مزامنة الشهادات",
    syncSuccess: "تمت مزامنة الشهادات بنجاح!",
    correctionsInSar: "التعديلات (ريال)",
    totalVatInSar: "قيمة ضريبة القيمة المضافة (ريال)",
    zatcaPoint1: "المبيعات الخاضعة للنسبة الاساسية",
    zatcaPoint2: "مبيعات للعملاء في دول مجلس التعاون الخليجي التي تطبق ضريبة القيمة المضافة",
    zatcaPoint3: "المبيعات المحلية الخاضعة للنسبة الصفرية",
    zatcaPoint4: "الصادرات",
    zatcaPoint5: "المبيعات المعفاة",
    zatcaPoint6: "اجمالي المبيعات",
    zatcaPoint7: "المشتريات الخاضعة للنسبة الاساسية",
    zatcaPoint8: "الاستيرادات الخاضعة لضريبة القيمة المضافة التي تدفع في الجمارك",
    zatcaPoint9: "الاستيرادات الخاضعة لضريبة القيمة المضافة التي تطيق عليها الية الاحتساب العكسي",
    zatcaPoint10: "المشتريات الخاضعة للنسبة الصفرية",
    zatcaPoint11: "المشتريات المعفاة",
    zatcaPoint12: "اجمالي المشتريات",
    zatcaPoint13: "اجمالي ضريبة القيمة المضافة المستحقة عن الفترة الضريبية الحالية",
    zatcaPoint14: "تصحيحات من الفترات السابقة (بين 5000 +- ريال)",

    downloadPDFPurchaseOrder: "عرض الPDF",
    reconcilePayment: "تسوية الدفعة",
    reconciledAmount: "مجموع التسوية",
    reconciliationStatus: "Reconciliation Status",
    convertPurchaseOrderToInvoice: "انشاء فاتورة",
    ManageSuppliers: "ادارة الموردين",
    relatedInvoices: "الفواتير المرتبطة",
    automaticReconciliationWarning: "بناءً على اعدادات شركتك ، سيتم تسوية هذه الدفعة تلقائيًا.",
    manualReconciliationWarning: "بناءً على اعدادات شركتك ، يجب تسوية هذه الدفعة يدويًا.",
    OTP_NOTFOUND: "رمز غير صالح، يرجى المحاولة مرة أخرى.",
    chargeId: "رقم الدفع",
    quotation: "عرض سعر",
    user: 'المستخدم',
    paymentTypes: "انواع المدفوعات",
    ManagePayments: "ادارة المدفوعات",
    ManageQuotations:"ادارة عروض السعر",
    quotationNumber: "رقم عرض السعر",
    PaymentStatus: "حالة السداد",
    active: "فعال",
    inactive: "غير فعال",
    expenseCreatedSuccessfully: "تم انشاء المصروف بنجاح",
    amountFrom: "المبلغ من",
    termsAndConditions: "الشروط و الاحكام",
    purchaseOrderNumber: "رقم طلب الشراء",
    amountTo: "المبلغ الى",
    EXPIRED_OTP: "انتهت صلاحية كلمة المرور لمرة واحدة ، يرجى المحاولة مرة أخرى.",
    sendOtpTo: "{otpTo} أرسل كلمة المرور لمرة واحدة إلى",
    fileIsReady: "الملف جاهز ",
    purchaseOrder: "طلب شراء",
    expenseTypes: "انواع المصاريف",
    ExpenseType: "نوع المصروف",
    automaticReconciliation: "تسوية المدفوعات اوتوماتيكيا",
    downloadPDFQuotation: "تحميل الPDF",
    checkDraftInvoice: "عرض الفاتورة",
    convertQuotationToInvoice: "اصدار مسودة فاتورة",
    quotaSuccess: "تم إرسال طلب الحصة بنجاح.",
    quotaSuccessMessage: "سيقوم فريقنا بالتواصل معك في أقرب وقت ممكن",
    ConfirmQuotation: "اصدار عرض السعر",
    downloadHere: "حمل هنا",
    errorWhileExportingToExcel: "حمل هنا",
    paymentFailureMessage: "لم نتمكن من إكمال طلب الشراء الخاص بك, يرجى المحاولة مرة أخرى والتأكد من صحة معلومات بطاقتك الائتمانية.",
    paymentSuccessMessage: "سيتم تفعيل باقتك قريبا!",
    paymentSuccess: "تم الدفع بنجاح",
    paymentFailure: "فشل عملية الدفع",
    users: 'المستخدمين',
    supplier: "مورد",
    suppliers: "الموردين",
    exportMonthlyReport: "تصدير التقرير الشهري المفصل",
    NO_RESULTS_FOUND: "لم يتم العثور على نتائج ، حاول شهر آخر",
    startDate: "تاريخ البداية",
    invoices: 'الفواتير',
    pickPlan: 'اختر الباقة',
    errorWhileExportingToExcel: "خطأ أثناء التصدير إلى Excel ، يرجى المحاولة مرة أخرى ..",
    planInvoices: 'فاتورة',
    export: "تصدير",
    signinMessage: "يرجى استخدام النموذج لتسجيل الدخول إلى منصة ARMS للفواتير الإلكترونية",
    welcome: "مرحبا",
    email: "البريد الالكتروني",
    ip: "عنوان IP ",
    fillOtp: "الرجاء تعبئة كلمة المرور لمرة واحدة من 4 خانات:",
    password: "كلمة المرور",
    subscriptionUsers: 'مستخدمين الاشتراك',
    subscriptions: 'الاشتراكات',
    charges: 'الدفعات',
    Otp: "كلمة المرور للمرة الواحدة لهيئة الزكاة",
    companyStart: "لنبدأ بإنشاء ملف تعريف شركتك!",
    customerStart: "لنقم بإنشاء أول عميل لك!",
    productStart: "لنقم بإنشاء أول منتج لك!",
    demoEnd: "أنت على استعداد للذهاب! يمكنك الآن إنشاء فواتير متوافقة بسهولة!",
    end: "انهاء",
    starthere: "ابدأ هنا",
    Compliance: "اعدادات هيئة الزكاة",
    Setup: "عملية إنشاء الشركة",
    OTP_NOTFOUND: "رمز غير صالح، يرجى المحاولة مرة أخرى.",
    user: 'المستخدم',
    EXPIRED_OTP: "انتهت صلاحية كلمة المرور لمرة واحدة ، يرجى المحاولة مرة أخرى.",
    sendOtpTo: "{otpTo} أرسل كلمة المرور لمرة واحدة إلى",
    fileIsReady: "الملف جاهز ",
    includedInTax: "مدرجة",
    includeInTax: "ادراج في الضريبة",
    lastLogin: "آخر تسجيل دخول",
    downloadHere: "حمل هنا",
    errorWhileExportingToExcel: "حمل هنا",
    users: 'المستخدمين',
    exportMonthlyReport: "تصدير التقرير الشهري المفصل",
    NO_RESULTS_FOUND: "لم يتم العثور على نتائج ، حاول شهر آخر",
    amount: "المقدار",
    percentage: "النسبة %",
    goToOtherAmounts: "إظهار المبالغ المدفوعة مقدمًا والاحتفاظ والاسترداد",
    startDate: "تاريخ البداية",
    invoices: 'الفواتير',
    pickPlan: 'اختر الباقة',
    invoiceTotalAmount: "الاجمالي شامل ضريبة القيمة المضافة",
    errorWhileExportingToExcel: "خطأ أثناء التصدير إلى Excel ، يرجى المحاولة مرة أخرى ..",
    planInvoices: 'فاتورة',
    export: "تصدير",
    updateaccount: "تحديث الحساب",
    startingFrom: 'ابتدائا من {annualPrice} {currency}',
    isLetterStyle: "استعمال شكل رسالة",
    ReachedMaxVendorCapacity: "لا يمكن إنشاء شركة ، تم الوصول إلى الحد الأقصى لعدد الشركات الاقصى",
    BillingReferenceId: "معرف مرجع الفواتير",
    pickMonth: "اختر الشهر",
    pdfHasBorders: "اعرض خطوط الجداول",
    DeleteVendor: "حذف الشركة",
    deleteProduct: "حذف المنتج",
    currencyRates: "أسعار العملات",
    activate: "تفعيل",
    filters: "المرشحات",
    deactivate: "تعطيل",
    baseCurrency: "العملة الأساسية",
    invoiceNumberPrefix: "بادئة رقم الفاتورة",
    taxInclAmount: "المبلغ شامل الضريبة",
    quoteCurrency: "عملة التسعير",
    ProductNameRequired: "الرجاء إدخال اسم المنتج باللغة العربية أو الإنجليزية",
    rate: "السعر",
    Retention: "المحجوزات",
    subscriptionInactive: "انتهى الاشتراك أو تم إلغاؤه.",
    filterSearchPlaceHolder: "الاسم أو رقم ضريبة القيمة المضافة أو الهاتف أو البريد الإلكتروني",
    DeleteCustomer: "حذف العميل",
    customization: "تخصيص الPDF",
    details: 'التفاصيل',
    newVersionIsRealeased: "تم إصدار إصدار نسخة جديدة للتطبيق!",
    attention: "انتباه",
    reason1: "الغاء او تعديل في تزويد المنتج او الخدمة كليا أو جزئيأ",
    reason2: "تغيير في كمية المنتج او الخدمة",
    reason3: "تغيير في قيمة المنتج أو الخدمة المتفق عليها مسبقا",
    reason4: "ارجاع المنتجات أو الخدمات",
    invoiceHeader1Content: 'السطر الاول',
    invoiceHeader2Content: 'السطر الثاني',
    updatedBy: 'عدلت من قبل',
    updatedAt: "عدلت بتاريخ",
    "generation-1": 'Generated',
    "generation-2": 'Not Started',
    RetentionPercentage: "نسبة الخصومات",
    "generation-3": 'Failed',
    "generation-4": 'Not Generated',
    addLine: 'سطر جديد',
    calculatedWhenConfirmed: 'تحسب عند الاصدار',
    FileHistory: 'تاريخ الملفات',
    createdAt: "انشأت بتاريخ",
    invoiceHeader3Content: 'السطر الثالث',
    calculated: "مدرجة في الحسابات",
    customProperties: "معلومات اضافية",
    calculate: "ادراج في الحسابات",
    key: 'المعرف',
    showOnPdf: 'اظهار على الفاتورة',
    notCalculated: "غير مدرجة في الحسابات",
    invoiceHeader: 'رأس الفاتورة',
    updateApp: "تحديث التطبيق",
    internalServerError: 'حدث خطأ تقني!',
    InternalServerError: 'حدث خطأ تقني!',
    from: 'من',
    to: 'الى',
    CustomerNotFound: "العميل غير موجود",
    phone: 'الهاتف',
    vatReport: "تقرير الضريبة",
    Send: "ارسال",
    reports: "التقارير",
    remaining: "المبتقي",
    used: "المستهلك",
    SendToEmail: "ارسال الى البريد الالكتروني",
    vendors: 'ملف الشركة',
    emailValidationError: "خطأ في التحقق من صحة البريد الإلكتروني",
    lifetime: 'مدة الاشتراك',
    Vendors: 'ملف الشركة',
    downloadPdf: "تحميل PDF",
    here: "هنا",
    summary: 'موجزة',
    thirdParty: 'فاتورة لطرف الثالث نيابة عن المورد',
    selfBilled: 'فاتورة ذاتية',
    pickSubscription: "اختر الاشتراك",
    instrumentNotDefined: '[1] الصك غير محدد',
    headerBgColor: 'لون خلفية الجداول',
    headerFontColor: 'لون خط الجداول',
    pdfFontColor: 'لون خلفية الPDF',
    invoiceFooterContent: 'محتوى ذيل الفاتورة ',
    bankTransfer: '[42] دفع مصرفي',
    resetPassword: 'إعادة تعيين كلمة المرور',
    userIsAlreadyActive: 'المستخدم فعال من قبل او غير موجود، إذا لم تتمكن من تسجيل الدخول إلى حسابك ، فحاول إعادة تعيين كلمة المرور الخاصة بك عن طريق النقر فوق',
    creditTransfer: '[30] تحويل رصيد',
    inCash: '[10] نقدا',
    creditCard: '[48] بطاقة ائتمان',
    exports: 'تصدير',
    nominal: 'اسمية',
    downloadXml: "تحميل XML",
    saveDraft: "حفط المسودة",
    priceFrom: 'السعر من',
    priceTo: 'السعر الى',
    DownloadSelected: 'تنزيل المحدد',
    simplified: 'فاتورة مبسطة',
    evat: 'فاتورة ضريبية',
    taxCategories: 'الضرائب',
    subscriptionPlans: 'باقات الاشتراك',
    sendActivationEmail: 'ارسال ايميل التفعيل',
    identityTypes: 'المعرفات الشخصية',
    customers: 'العملاء',
    products: 'المنتجات',
    dateFrom: 'تاريخ من',
    dateTo: 'تاريخ الى',
    feedbacks: 'ردود الافعال',
    dashboard: 'لوحة القيادة',
    InvoiceNote: "الملاحظات",
    New: "اضافة",
    CreationDate: "تاريخ الاضافة",
    Phone: "رقم الهاتف",
    removeFromSubscription: 'ازالة من الاشتراك',
    branchSerial: "تسلسل الفرع",
    Name: "الاسم",
    Value: "القيمة",
    Action: "العمليات",
    ExternalInvoiceNumberAlreadyExist: "رقم الفاتورة الخارجي مكرر..",
    CreditNoteViolation: "يجب أن تكون قيمة اشعار الدائن أكبر من الفاتورة الأصلية أو مساوية لها.",
    SubscriptionNotFoundException: "الاشتراك غير موجود..",
    OutOfInvoiceBalance: "عذرا, لقد انتهى رصيد الفواتير الخاص بهذا الاشتراك",
    InvoiceValidationError: "خطأ في التحقق من صحة الفاتورة",
    ViolatedInvoiceBalance: "خرق لرصيد الفاتورة ، رصيد الاشتراك أقل من صفر",
    copyId: "نسخ المعرف",
    isSimpleAddress: "عنوان مختصر",
    Norecordsfound: "لا يوجد حركات",
    Save: "حفظ",
    Cancel: "تجاهل",
    advanced: "خيارات متقدمة",
    dates: "التواريخ",
    ClickorDropheretouploadvendorlogo: "اضغط او اسحب لأضافة صورة",
    ID: "ترميز",
    problemDescription: "وصف المشكلة",
    Confirm: "تأكيد",
    help: "المساعدة",
    title: 'العنوان',
    issueTitle: 'عنوان المشكلة',
    submit: "ارسال",
    Attachments: 'المرفقات',
    Select: "اختار",
    subscription_user: 'المسؤول العادي (إنشاء الفواتير وإدارة المنتجات وإدارة العملاء)',
    subscription_data_entry: 'مسؤول إدخال البيانات (مسودة الفواتير ، إدارة المنتجات ، إدارة العملاء)',
    subscription_admin_ro: 'مشرف قارىْ',
    subscription_admin: 'مسؤول الاشتراك (كامل)',
    accessRole: 'دور المستخدم',
    NameEnglish: "الاسم بالانجليزي",
    NameArabic: "الاسم بالعربي",
    City: "المدينة",
    VatNumber: "الرقم الضريبي ",
    GroupVatNumber: "الرقم الضريبي للمجموعة",
    description: 'الوصف',
    actionStatus1: 'عملية ناجحة',
    actionStatus2: 'عملية فاشلة',
    actionStatus3: 'تم رفض الإذن',
    actionStatus4: 'غير مصرح له',
    ipAddress: 'عنوان IP',
    activityLogs: 'سجلات التدقيق',
    NoVendorsFound: "لا يوجد حركات",
    ManageVendors: "ادارة الشركات",
    totalAmountDue: 'اجمالي المبلغ المستحق',
    totalVat: 'اجمالي ضريبة القيمة المضافة',
    taxableAmount: 'اجمالي المبلغ الخاضع للضريبة (باستثناء ضريبة القيمة المضافة)',
    discount: 'الخصم',
    invoiceTotal: 'الاجمالي (باستثناء ضريبة الفيمة المضافة)',
    emailSent: "تم إرسال البريد الإلكتروني بنجاح!",
    VendorsDetails: "معلومات الشركة",
    CustomerType: 'نوع العميل',
    Street1: "شارع 1",
    AdditionalStreet: "شارع اضافي",
    sendResetPasswordRequest: "إعادة تعيين كلمة المرور",
    forgotpassword: "نسيت كلمة السر؟",
    BuildingNo: "رقم المبنى",
    AdditionalNumber: "الرقم الاضافي",
    PostalCode: "رقم البريد",
    SaveDraftToShowTotals: "احفظ المسودة لحساب الإجماليات",
    TotalCalcNote: "سيتم احتساب الإجماليات فقط عند حفظ الفاتورة.",
    State: "المحافظة",
    notifications: "الاشعارات",
    productStep1: "تنزيل عينة من",
    productStep2: "املأ ورقة إكسل بمنتجاتك.",
    productStep3: "اختر الشركة",
    productStep4: "قم بتحميل الملف.",
    productStep5: "سيتم تحميل المنتجات في الخلفية.",
    customerStep1: "تنزيل عينة من",
    customerStep2: "املأ ورقة إكسل بعملائك.",
    customerStep3: "اختر الشركة",
    customerStep4: "قم بتحميل الملف.",
    customerStep5: "سيتم تحميل العملاء في الخلفية.",
    subscription_admin_ro: 'مشرف قارىْ',
    subscription_admin: 'مسؤول الاشتراك (كامل)',
    accessRole: 'دور المستخدم',
    NameEnglish: "الاسم بالانجليزي",
    NameArabic: "الاسم بالعربي",
    City: "المدينة",
    VatNumber: "الرقم الضريبي ",
    GroupVatNumber: "الرقم الضريبي للمجموعة",
    description: 'الوصف',
    actionStatus1: 'عملية ناجحة',
    actionStatus2: 'عملية فاشلة',
    actionStatus3: 'تم رفض الإذن',
    actionStatus4: 'غير مصرح له',
    actionStatus5: 'غير معروف',
    ipAddress: 'عنوان IP',
    activityLogs: 'سجلات التدقيق',
    NoVendorsFound: "لا يوجد حركات",
    ManageVendors: "ادارة الشركات",
    totalAmountDue: 'اجمالي المبلغ المستحق',
    totalVat: 'اجمالي ضريبة القيمة المضافة',
    taxableAmount: 'اجمالي المبلغ الخاضع للضريبة (باستثناء ضريبة القيمة المضافة)',
    discount: 'الخصم',
    invoiceTotal: 'الاجمالي (باستثناء ضريبة الفيمة المضافة)',
    emailSent: "تم إرسال البريد الإلكتروني بنجاح!",
    VendorsDetails: "معلومات الشركة",
    CustomerType: 'نوع العميل',
    Street1: "شارع 1",
    AdditionalStreet: "شارع اضافي",
    sendResetPasswordRequest: "إعادة تعيين كلمة المرور",
    forgotpassword: "نسيت كلمة السر؟",
    BuildingNo: "رقم المبنى",
    AdditionalNumber: "الرقم الاضافي",
    PostalCode: "رقم البريد",
    SaveDraftToShowTotals: "احفظ المسودة لحساب الإجماليات",
    TotalCalcNote: "سيتم احتساب الإجماليات فقط عند حفظ الفاتورة.",
    State: "المحافظة",
    notifications: "الاشعارات",
    productStep1: "تنزيل عينة من",
    productStep2: "املأ ورقة إكسل بمنتجاتك.",
    productStep3: "اختر الشركة",
    productStep4: "قم بتحميل الملف.",
    productStep5: "سيتم تحميل المنتجات في الخلفية.",
    customerStep1: "تنزيل عينة من",
    customerStep2: "املأ ورقة إكسل بعملائك.",
    customerStep3: "اختر الشركة",
    customerStep4: "قم بتحميل الملف.",
    customerStep5: "سيتم تحميل العملاء في الخلفية.",
    invoiceStep1: "تنزيل عينة من",
    invoiceStep2: "املأ ورقة إكسل بفواتيرك.",
    advancedAmount: "الدفعة المقدمة",
    invoiceStep3: "اختر الشركة",
    invoiceStep4: "قم بتحميل الملف.",
    invoiceStep5: "سيتم تحميل الفواتير في الخلفية.",
    taxExclusive: "*السعر غير شامل ضريبة المبيعات 15% . ",
    VendorVAT: "الرقم الضريبي للبائع",
    Serial: 'التسلسل',
    productsImportSteps: "خطوات الاستيراد",
    Country: "الدولة",
    taxInvoices: "الفواتير الضريبية",
    selectFile: "اختر الملف",
    downloadSample: "تحميل نموذج",
    ImportFromExcel: "الاستيراد من Excel",
    dropFileHere: 'قم بسحب وإسقاط الملف هنا للتحميل.',
    productCategory: 'فئة المنتج',
    selectCompanies: "اختر شركات المستخدم",
    unlimitedUsers: "عدد غير محدود من المستخدمين",
    dashboardAccess: "24/7 الوصول عبر الإنترنت",
    Branches: 'الافرع',
    Subject: "الموضوع",
    invoicing: 'الفوترة الالكترونية',
    unlimitedCompanies: 'عدد لا محدود من الشركات',
    unlimitedCustomers: 'عدد لا محدود من العملاء',
    unlimitedProducts: 'عدد لا محدود من المنتجات',
    productCategories: 'فئات المنتجات',
    image: 'الصورة',
    subscription_pos_user: 'مستخدم نقطة البيع - يستخدم لنظام نقاط البيع فقط',
    seperateSequencePerType: "تسلسل منفصل للفاتورة لكل نوع (فاتورة ضريبية ، اشعار دائن ، اشعار مدين)",
    AttentionText: "عناية",
    GovEntity: "هيئة حكومية",
    Individual: "فرد",
    Company: "شركة",
    Branch: "الفرع",
    UserInvoiceNumber: "الرقم الخارجي",
    JoinNow: "اشترك الان",
    Neighborhood: "الحي",
    Logo: "شعار الشركة",
    Identities: "معلومات اضافية",
    Customers: "العميل",
    ManageCustomers: "ادارة العملاء",
    CustomerDetails: "معلومات العميل ",
    SelectCompany: 'إختر شركة',
    recordPayment: 'تسجيل دفعة',
    Price: "السعر",
    DiscountPercentage: "نسبة الخصم ",
    ProductNumber: "رقم الصنف",
    PEPPOL: "PEPPOL",
    UnitCode: "الوحدة",
    TaxCategories: "مجموعة الضريبة",
    ManageProducts: "ادارة المنتجات",
    date: 'التاريح',
    SubscriptionID: "رقم الاشتراك",
    Invoicesbalance: "عدد الفواتير الاجمالية",
    CurrentSubscriptionPlan: "الاشتراك الحالي",
    Enddate: "تاريخ الانتهاء",
    prepaidAmount: "المبلغ المدفوع",
    CustomersCount: "عدد العملاء",
    Comingsoon: "قريبا",
    VendorsCount: "عدد الشركات",
    walletBalance: "رصيد المحفظة",
    prepaidAmountDate: "تاريخ المبلغ المدفوع",
    Gotoinvoices: "الذهاب الى الفواتير>  ",
    InvoicesTotal: "مجموع الفواتير ",
    wallet: "المحفظة",
    walletDescription: "You can redeem your wallet credit on your subscription renewal.",
    BHD: "دينار بحريني",
    SAR: "ريال سعودي",
    USD: "دولار امريكي",
    EUR: "يورو",
    ConfirmCreditNote: 'اصدار اشعار الدائن',
    ConfirmDebitNote: 'اصدار اشعار المدين',
    productType: 'نوع المنتج',
    service: 'خدمة',
    consumable: 'مستهلك',
    Confirmed: 'مأكدة',
    Stash: 'مسودة',
    createCreditNote: 'انشاء اشعار دائن',
    createDebitNote: 'انشاء اشعار مدين',
    Filter: 'بحث',
    ManageInvoices: "إدارة الفواتير",
    Number: "عدد",
    Customer: "عميل",
    IssueDate: "تاريخ الإصدار",
    Company: "الشركة",
    TaxExcluded: "غير شامل الضرائب",
    Total: "المجموع",
    selectVendorsToShowCustomers: "حدد البائع لاختيار العملاء",
    Status: "الحالة",
    InvoiceType: "نوع الفاتورة",
    TransactionType: "نوع المعاملة",
    invoice: 'فاتورة',
    createNew: 'انشاء',
    edit: 'تعديل',
    Specialbillingagreement: "اتفاقية الفواتير الخاصة",
    PaymentMean: "طريقة الدفع",
    Currency: "العملة",
    SupplyDate: "تاريخ التوريد",
    PaymentConditions: "شروط الدفع",
    SupplyEndDate: "تاريخ انتهاء التوريد",
    PurchaseNumber: "رقم الشراء",
    DueDate: "تاريخ الاستحقاق",
    CustomerVATNumber: "رقم ضريبة القيمة المضافة للعميل",
    GroupVATRegistrationNumber: "رقم ضريبة القيمة المضافة للمجموعة",
    Createnewinvoice: "إنشاء فاتورة جديدة",
    SaveDraft: "حفظ المسودة",
    Lines: "البنود",
    Product: "الصنف",
    Label: "المسمى",
    Quantity: "الكمية",
    DISC: "الخصم",
    Taxes: "الضرائب",
    TaxAmount: "قيمة الضريبة",
    Subtotal: "المجموع الفرعي",
    Generalinfo: "معلومات عامة",
    openSupportRequest: "الدعم الفني",
    QRCode: "رمز الاستجابة السريعة",
    Otherinfo: "معلومات اخرى",
    ClickOrDropHereToUploadProductImage: 'انقر أو أفلت هنا لتحميل صورة المنتج',
    youCanChangeDropdownContent: 'يمكنك كتابة الأسماء / العناوين الخاصة بك عن طريق الكتابة في القوائم المنسدلة.',
    InvoiceNumber: "رقم الفاتورة",
    expenseNumber: "رقم النفقة",
    searchByProductNumber: "البحث من خلال رقم المنتج",
    InvoiceTypeCode: "رمز نوع الفاتورة",
    productFilterSearchPlaceHolder: "الاسم أو السعر أو Peppol أو رقم المنتج ...",
    InvoiceTransactionCode: "رمز معاملة الفاتورة",
    InvoicePaymentType: "نوع الفاتورة",
    InvoiceCounterValue: "قيمة عداد الفاتورة",
    UUID: "UUID",
    PreviousInvoiceHash: "تجزئة الفاتورة السابقة",
    ReferenceHashInvoiceNumber: "مرجع رقم فاتورة التجزئة",
    Areyousureyouwanttodeletedraft: "هل أنت متأكد أنك تريد حذف المسودة",
    Areyousureyouwanttoproceed: "هل انت متأكد انك تريد المتابعة",
    success: "تم",
    error: "خطأ",
    CannotDeleteInvoice: "لا يمكن حذف الفاتورة",
    DeleteDraftInvoice: "حذف مسودة الفاتورة",
    Deleteddraftsuccessfully: "تم حذف المسودة بنجاح",
    No: "لا",
    Yes: "نعم",
    ConfirmInvoice: "اصدار الفاتورة",
    subscriptionName: 'اسم الاشتراك',
    PDFisnotreadyyetrefreshandtryagain: "PDF ليس جاهزًا بعد ، قم بالتحديث وحاول مرة أخرى",
    Refresh: "تحديث",
    DeleteDraft: "حذف المسودة",
    InstructionNote: "سبب الاصدار",
    Base64Value: "قيمة Base64",
    subscriptionPlan: 'خطة الاشتراك',
    UserName: 'اسم الحساب',
    UserEmail: 'البريد الالكتروني للحساب',
    UserPhone: 'رقم هاتف الحساب',
    Vendor: "البائع",
    Selectvendortoaddinvoicelines: "حدد البائع لإضافة سطور الفاتورة",
    DebitNote: "اشعار مدين",
    Reason: "السبب",
    CreditNote: "اشعار دائن",
    comments: 'تعليق',
    payments: 'المدفوعات',
    payment: 'الدفع',
    pay: 'دفع',
    makePayment: 'قم بالدفع',
    quotations: 'عروض الأسعار',
    amount: 'القيمة',
    close: 'إعلاق',
    userManual: 'دليل المستخدم',
    Recovery: 'الإسترداد',
    RecoveryPercentage: 'نسبة الإسترداد',
    selectPaymentType: 'إختيار طريقة الدفع',
    noAccount: 'لا يوجد حساب, اضف حساب',
    selectAccount: 'إختيار حساب',
    selectIssuer: 'إختر مصدر',
    noIssuer: 'لا يوجد مصدرين, اضف مصدر',
    addIssuer: 'أضف مصدر',
    addAccount: 'أضف حساب',
    referenceNumber: 'الرقم المرجعي',
    paymentType: 'طريقة الدفع',
    confirmDelete: 'هل أنت متأكد أنك تريد حذف؟',
    deleteConfirmation: 'تأكيد الحذف',
    refund: 'استرداد',
    deletePayment: 'حذف الدفعة',
    deletedSuccessfully: 'تم الحذف بنجاح',
    updatePayment: 'تعديل الدفعة',
    paymentUpdated: 'تم تعديل الدفعة بنجاح',
    addPayment: 'إضافة دفعة',
    paymentAdded: 'تم إضافة دفعة بنجاح',
    delete: 'حذف',
    category: 'الفئة',
    selectSupplier: 'اختر مزود',
    addQuotation: 'إضافة عرض سعر',
    refunds: 'المبالغ المستردة',
    purchasedOrders: 'الطلبات المشتراة',
    expenses: 'نفقات',
    purchases: 'المشتريات',
    addOrder: 'إضافة طلب',
    orderNumber: 'رقم الطلب',
    autoGenerated: 'يصدر تقائيا',
    businessExpense: 'نفقات العمل',
    proOrSerExpense: 'شراء المنتج أو الخدمة',
    officeEquipment: 'شراء المعدات المكتبية',
    selectSupplierText: 'حدد المورد لاختيار المنتجات',
    successfullyUpdated: 'تم التحديث بنجاح',
    successfullyAdded: 'أضيف بنجاح',
    adding: 'إضافة',
    updating: 'التحديث',
    subscriptionManage: 'إدارة الإشتراك',
    renew: '  تجديد الإشتراك',
    plans: 'الباقات',
    startedDate: 'تاريح الاشتراك',
    expiry: 'تاريح الإنتهاء',
    invoicesLeft: 'الفواتير المتبقية',
    cancelSub: 'إلغاء الاشتراك',
    invoicePerYear: 'فاتورة / سنة',
    forOneYear: 'لمدة سنة',
    forThreeYears: 'لمدة 3 سنوات',
    checkoutBody: () => <p style={{direction: 'rtl'}}>
        للتحويل المصرفي ، يرجى إجراء الدفع إلى:
        <br />
        المصرف: مصرف الإنماء
        <br />
        - رقم الحساب: 68208959595000
        <br />
        - رقم الآيبان: SA4505000068208959595000
        <br />
        - صاحب الحساب: ARMS For Information Technologies - مؤسسة ارمس لتقنية المعلومات.
        <br />
        يرجى ذكر اسم عملك ورقم ضريبة القيمة المضافة وعنوان البريد الإلكتروني الذي تريد التسجيل كمسؤول. تواصل معنا عبر البريد الإلكتروني: sales@armsit.com
    </p>,
    checkoutHead: 'الدفع',
    checkout: 'دفع',
    confirmSubCancel: 'هل أنت متأكد أنك تريد إلغاء الاشتراك؟',
    confirmSubCancelHead: 'إلغاء الإشتراك',
    validSubMsg: 'لا يزال اشتراكك ساريًا ، ومع ذلك يمكنك تجديده أو ترقيته في أي وقت.',
    onlinePayment: 'دفع إلكتروني',
    bankTransfer: 'تحويل بنكي',
    selectAnother: 'إختيار باقة اخرى',
    bankTransferInfo: 'معلومات التحويل البنكي',
    direction: 'rtl',
    renewal: 'تجديد',
    successRenewal: 'تم تجديد اشتراكك بنجاح'

}

export default ar;
