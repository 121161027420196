import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import PaymentTypeService from '../service/PaymentTypeService';
import { defaultTableConfiguration } from './TableConfiguration';
import { Checkbox } from 'primereact/checkbox';


export const PaymentTypes = () => {

    let emptyPaymentType = {
        "translations": [
            {
                name:'',
                locale:'1'
            },
            {
                name:'',
                locale:'2'
            }
        ],
    };

    const [paymentTypeDialog, setPaymentTypeDialog] = useState(false);
    const [deletePaymentTypeDialog, setDeletePaymentTypeDialog] = useState(false);
    const [deletePaymentTypesDialog, setDeletePaymentTypesDialog] = useState(false);
    const [paymentType, setPaymentType] = useState(emptyPaymentType);
    const [selectedPaymentTypes, setSelectedPaymentTypes] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        // const paymentTypeService = new PaymentTypeService();
        // paymentTypeService.getPaymentTypes().then(data => setPaymentTypes(data));
        onPage({rows,page:0})
    }, []);


    const openNew = () => {
        setPaymentType(emptyPaymentType);
        setSubmitted(false);
        setPaymentTypeDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setPaymentTypeDialog(false);
    }

    const hideDeletePaymentTypeDialog = () => {
        setDeletePaymentTypeDialog(false);
    }

    const hideDeletePaymentTypesDialog = () => {
        setDeletePaymentTypesDialog(false);
    }

    const savePaymentType = async () => {
        setSubmitted(true);

        var paymentTypeService = new PaymentTypeService();
        if (paymentType.uuid) {
            paymentType.translations.map(t=>{
                t.locale = t.cultureName == 'En' ? '1' : '2'
                return t;
            });
            await paymentTypeService.UpdatePaymentType(paymentType);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Payment Type Updated', life: 3000 });
        }
        else {
            await paymentTypeService.CreatePaymentType(paymentType);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Payment Type Created', life: 3000 });
        }
        onPage({ rows, page })
        // setPaymentTypes(_paymentTypes);
        setPaymentTypeDialog(false);
        setPaymentType(emptyPaymentType);
    }

    const editPaymentType = (paymentType) => {
        setPaymentType({ ...paymentType });
        setPaymentTypeDialog(true);
    }

    const confirmDeletePaymentType = (paymentType) => {
        setPaymentType(paymentType);
        setDeletePaymentTypeDialog(true);
    }

    const deletePaymentType = async () => {
        await (new PaymentTypeService().DeletePaymentType(paymentType))
        setDeletePaymentTypeDialog(false);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'PaymentType Deleted', life: 3000 });
        onPage({ rows, page })
    }


    const deleteSelectedPaymentTypes = () => {
        let _paymentTypes = paymentTypes.filter(val => !selectedPaymentTypes.includes(val));
        setPaymentTypes(_paymentTypes);
        setDeletePaymentTypesDialog(false);
        setSelectedPaymentTypes(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'PaymentTypes Deleted', life: 3000 });
    }

    const onTranslationChange = (e, locale) => {
        const val = (e.target && e.target.value) || '';
        let _paymentType = { ...paymentType };
        _paymentType.translations[locale-1].name = val;
        setPaymentType(_paymentType);
    }

    const onCheckBoxChange = (e) => {
        const val = e.checked;
        let _paymentType = { ...paymentType };
        _paymentType[e.target.id] = val;
        setPaymentType(_paymentType);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const arabicLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Label [AR]</span>
                {rowData.translations[1]?.name}
            </>
        );
    }

    const englishLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.translations[0]?.name}
            </>
        );
    }

    const showAllOptionsTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Link to account</span>
                {rowData.showAllOptions ? 'Yes' : 'No'}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editPaymentType(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeletePaymentType(rowData)} />
            </div>
        );
    }

    const onPage = ({ first,
        rows,
        page }) => {
        const paymentTypeService = new PaymentTypeService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        paymentTypeService.GetPaymentTypes(rows, (page) * rows).then(({ data, count }) => {
            setLoading(false);
            setPaymentTypes(data);
            setTotalRecords(count);
        });

    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Payment Types</h5>
            <span className="p-input-icon-left">
                {/* <i className="pi pi-search" /> */}
                {/* <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." /> */}
            </span>
        </div>
    );

    const paymentTypeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={savePaymentType} />
        </>
    );
    const deletePaymentTypeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePaymentTypeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deletePaymentType} />
        </>
    );
    const deletePaymentTypesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePaymentTypesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedPaymentTypes} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate}></Toolbar>

                    <DataTable
                        {...defaultTableConfiguration}
                        value={paymentTypes}
                        selection={selectedPaymentTypes}
                        totalRecords={totalRecords}
                        first={first}
                        loading={loading}
                        onPage={onPage}
                        rows={rows}
                        header={header}>
                        <Column field="email" header="Label [AR]"  style={{width: "250px"}} body={arabicLabelTemplate}></Column>
                        <Column field="phone" header="Label [EN]"body={englishLabelTemplate}></Column>
                        <Column field="showAllOptions" header="Link to account"body={showAllOptionsTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={paymentTypeDialog} style={{ width: '450px' }} header="Payment Type Details" modal className="p-fluid" footer={paymentTypeDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="name">Label [AR]</label>
                            <InputText id="name" value={paymentType.translations[1]?.name} onChange={(e) => onTranslationChange(e, '2')} required autoFocus className={classNames({ 'p-invalid': submitted && !paymentType.name })} />
                            {submitted && !paymentType.name && <small className="p-invalid">Label [AR] is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">Label [EN]</label>
                            <InputText id="name" value={paymentType.translations[0]?.name} onChange={(e) => onTranslationChange(e, '1')} required autoFocus className={classNames({ 'p-invalid': submitted && !paymentType.name })} />
                            {submitted && !paymentType.name && <small className="p-invalid">Label [EN] is required.</small>}
                        </div>
                        <div className="">
                            <Checkbox inputId="cb1" id="showAllOptions" checked={paymentType.showAllOptions} value={paymentType.showAllOptions} onChange={onCheckBoxChange} required autoFocus />
                            <label htmlFor="cb1" className="p-checkbox-label" style={{marginLeft:'2px'}}>Link to account</label>
                        </div>
                    </Dialog>

                    <Dialog visible={deletePaymentTypeDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePaymentTypeDialogFooter} onHide={hideDeletePaymentTypeDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {paymentType && <span>Are you sure you want to delete <b>{paymentType.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deletePaymentTypesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePaymentTypesDialogFooter} onHide={hideDeletePaymentTypesDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {paymentType && <span>Are you sure you want to delete the selected payment type?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
