import { createSlice } from "@reduxjs/toolkit";
import ExpenseService from "../service/ExpenseService";

const expense = createSlice({
    name: 'expense',
    initialState: {error: '', expenses: {data:[], count:0}, expense:{}},
    reducers: {
        addData(state, action){
            return { ...state, ...action.payload}
        },
        addError(state, action) {
            return { ...state, error:action.payload}
        }
    }
})

export default expense.reducer
export const {addData, addError} = expense.actions
