import PurchaseOrderService from '../../service/PurchaseOrderService'
import {addDifferentOrder, addMsg} from '../order'

export const createOrder = (payload) => async (dispatch,state) => {
    try {
        let result = await PurchaseOrderService.createOrder(payload)
        let {data, count} = state().orders.orders
        dispatch(addDifferentOrder({orders: [...data, result], count: count+1}))
    } catch (error) {
        dispatch(addMsg(error));
    }
}

export const getOrders = (payload) => async (dispatch) => {
    try {
        let result = await PurchaseOrderService.getOrders(payload)
        dispatch(addDifferentOrder({orders: result}))
    } catch (error) {
        dispatch(addMsg(error));
    }
}

export const getOrder = (payload) => async (dispatch) => {
    try {
        let result = await PurchaseOrderService.getOrder(payload)
        dispatch(addDifferentOrder({order: result}))
    } catch (error) {
        dispatch(addMsg(error));
    }
}

export const updateOrder = (payload) => async (dispatch, state) => {
    try {
        let result = await PurchaseOrderService.updateOrder(payload);
        if (result.uuid) {
            let {orders} = state().orders
            let newOrders = orders.data.map(order => order.uuid === result.uuid ? result: order)

            dispatch(addDifferentOrder({orders: {...orders, data: newOrders}}))
        }else {
            dispatch(addMsg(result.statusText))
           
        }
    } catch (error) {
        dispatch(addMsg(error.statusText))
        throw error
    }
}

export const deleteOrder = (payload) => async (dispatch, state) => {
    try {
        let result = await PurchaseOrderService.deleteOrder(payload);
        let {data, count} = state().orders.orders
        let newOrders = data.filter(order => order.uuid !== result.uuid)
        dispatch(addDifferentOrder({orders:{data: newOrders, count: count -1}}))
    } catch (error) {
        dispatch(addMsg(error))
    }
}