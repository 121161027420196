import React, { useEffect, useState, useContext, useRef } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import { AutoComplete } from 'primereact/autocomplete';
import strings from '../../localization'
import SubscriptionService from '../../service/SubscriptionService';
import UserService from '../../service/UserService';
import VendorService from '../../service/VendorService'
import InvoiceService from '../../service/InvoiceService'
import Can, { check } from '../../Can'
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import { VendorForm } from '../VendorForm';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import AddAccount from './components/AddAccountDialog';
import { useParams } from 'react-router-dom'
import { classNames } from 'primereact/utils';
import { RTLContext } from '../../Profile';
import { useSelector, useDispatch, connect } from 'react-redux'
import { updatePaymentHandler, addPaymentHandler } from '../../store/payments'
import { Toast } from 'primereact/toast';
import { useHistory } from 'react-router-dom'
// import { addPayment, updatePayment } from '../../store/actions/SalesPayment'
import { getAccountsHandler, deleteAccountHandler } from '../../store/actions/accountAction'
import { addPayment, getPaymentTypes, updatePayment } from '../../store/actions/SalesPayment'
import CustomerSelectorAndAdd from '../../components/CustomerSelectorAndAdd';
import { ConfirmDialog } from 'primereact/confirmdialog';

const PaymentForm = ({ mode, iId, paymentForEdit, updatePaymentHandler, addPaymentHandler, getAccountsHandler, deleteAccountHandler, addPayment, getPaymentTypes, updatePayment }) => {
    const history = useHistory()
    const toast = useRef(null);

    const initialState = { paymentDate: '', amount: null, paymentTypeId: '', comments: '', customer: {}, invoices: [], companyAccountId: '', referenceNumber: '', customerId: '', vendorId: '' }
    const { paymentTypes: { data: paymentTypes } } = useSelector(state => state.payments)
    const { vendors: vns, activeVendor } = useSelector(state => state.vendors)
    const { activeCompany } = useSelector(state => state.user)
    const { data: accounts } = useSelector(state => state.bankAccounts.accounts)
    // const id = useParams().id
    const [invoice, setInvoice] = useState({})

    const [selectedVendor, setSelectedVendor] = useState({})
    const [customer, setCustomer] = useState({})

    const [addVendor, setAddVendor] = useState(false)
    const [payment, setPayment] = useState({})
    const [value1, setValue1] = useState('');
    const [value, setValue] = useState(null);
    const [account, setAccount] = useState(null);
    const [addAccount, setAddAccount] = useState(false)
    const [paymentDetails, setPaymentDetails] = useState(initialState)
    const [vendorId, setVendorId] = useState('')
    const [loading, setLoading] = useState(true)
    const [visible, setVisible] = useState('');
    const [progressLoading, setProgressLoading] = useState(false)
    let role = new UserService().GetRole();
    let companyPopulted = new SubscriptionService().GetActiveCompany() !== null;
    let branchPopulated = new SubscriptionService().GetActiveBranch() !== null;

    // console.log("🚀 ~ file: PaymentForm.jsx ~ line 41 ~ PaymentForm ~ invoices", invoices)
    // let canEdit = (!invoice.confirmed && invoice.invoiceTypeCode == '388')
    let canEdit = true
    const paymentsArr = [{ name: 'cash', code: 'cash' }, { name: 'credit card', code: 'credit card' }, { name: 'debit card', code: 'debit card' }, { name: 'cheque', code: 'cheque' }, { name: 'online transfer', code: 'online transfer' }]
    // const accounts = [{ name: 'emran', code: 'emran' }, { name: 'notEmran', code: 'notEmran' }]
    // useEffect(() => {
    //     console.log("🚀 ~ file: PaymentForm.jsx ~ line 32 ~ PaymentForm ~ selectedVendor", selectedVendor)

    // }, [selectedVendor])
    const getInvoice = async () => {

        let { invoice: inv } = await new InvoiceService().GetInvoice(iId)

        setInvoice(inv)
        setVendorId(inv.vendor.uuid)
        setValue(inv.totalAmount)
        setSelectedVendor(inv.vendor)
        setCustomer({ ...inv.customer, name: inv.customer.translations?.find(x => x.cultureName.toLowerCase() === strings.getLanguage())?.name })
        setPaymentDetails(i => { return { ...i, invoices: [...i.invoices, inv.uuid], currency: inv.currencyCode, customerId: inv.customer.uuid, vendorId: inv.vendor.uuid } })


    }
    useEffect(() => {
        // new VendorService().GetVendors(500, 0, { simple: true }).then(({ data }) => {
        //     setVendors(data.map(v => {
        //         return { code: v.uuid, name: v.name, ...v }
        //     }))
        // })
        // const invoices = new InvoiceService().GetInvoices().then((data)=> console.log(data))
        console.log(mode === 'edit')
        Promise.all([iId && getInvoice(), paymentForEdit && setPaymentDetails(paymentForEdit), getPaymentTypes(), mode === 'edit' && setCustomer(paymentForEdit.customer)]).then(() => {
            return setLoading(false)
        })
    }, [])
    // useEffect(() => {

    //     console.log("🚀 ~ file: PaymentForm.jsx ~ line 104 ~ PaymentForm ~ customer", customer)
    //     console.log("🚀 ~ file: PaymentForm.jsx ~ line 106 ~ PaymentForm ~ paymentDetails", paymentDetails)
    // },[customer, paymentDetails])
    const isRTL = useContext(RTLContext)
    const addPaymentFormHandler = (e) => {
        e.preventDefault()
        let obj = { ...paymentDetails }
        delete obj.vendor
        delete obj.customer
        delete obj.paymentType
        delete obj.companyAccount
        setProgressLoading(true)
        mode === 'edit' ? updatePayment({ ...obj, invoices: [] }).then(() => { showToast({ title: strings.updatePayment, message: strings.paymentUpdated }); setProgressLoading(false) }) : addPayment({ ...paymentDetails, amount: paymentDetails.amount ?? invoice.totalAmount, customerId: customer.uuid ?? invoice.customer.uuid }).then(() => { showToast({ title: strings.addPayment, message: strings.paymentAdded }); setProgressLoading(false) })
        e.target.reset()
        setPaymentDetails(initialState)
        setValue(null)
    }
    useEffect(() => {
        if (!iId && !vendorId) {
            setVendorId(activeCompany?.uuid)
            setSelectedVendor(activeCompany)
        }
    }, [vendorId])
    // useEffect(() => {

    //     console.log("🚀 ~ file: PaymentForm.jsx ~ line 65 ~ PaymentForm ~ invoice", invoice)
    // }, [invoice])
    const vendorDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { setAddVendor(false) }} />
        </>
    );
    const reload = () => {
        console.log('hello')
    }
    const showToast = ({ title, message }) => {
        toast.current.show({ severity: 'info', summary: title, detail: message, life: 3000 });
        // deletePaymentHandler(rowData)
    }

    useEffect(() => {
        vendorId && Promise.all([getAccountsHandler({ vendorId: vendorId })])
    }, [vendorId])
    useEffect(() => {

        console.log("🚀 ~ file: PaymentForm.jsx ~ line 127 ~ PaymentForm ~ paymentDetails", paymentDetails)

    }, [paymentDetails])
    const accountTemplate = (option) => {
        return (
            <React.Fragment >
                <Button className="p-button-sm" icon='pi pi-trash' onClick={() => deleteAccountHandler(option.uuid)} style={{ zIndex: 20000 }} /> {' '}
                <span style={{ lineHeight: '200%' }} >{option.name}</span>
                <ConfirmDialog visible={visible === option.uuid} onHide={() => setVisible(false)} message={`Are you sure you want to delete ${option.name}?`}
                    header="Confirmation" icon="pi pi-exclamation-triangle" accept={() => deleteAccountHandler(option.uuid)} />
            </React.Fragment>

        )
    }

    const paymentTypeValidator = () => {
        return paymentTypes.find(x => x.uuid === paymentDetails.paymentTypeId)?.translations.find(x => x.name === 'Cash')
    }
    return (
        <>
            <h3>{strings.recordPayment}</h3>

            <AddAccount visible={addAccount} onHide={() => { setAddAccount(false) }} vendorId={vendorId??selectedVendor?.uuid} />
            <Dialog visible={addVendor} style={{ width: '75%' }} header={strings.VendorsDetails} modal className="p-fluid" footer={vendorDialogFooter} onHide={() => { setAddVendor(false) }}>

                <VendorForm OnHideDialog={() => { setAddVendor(false) }} full={true} FillData={() => reload()} />
            </Dialog>
            <Toast ref={toast} />
            {loading ? <ProgressSpinner /> :
                <div className="p-field p-col-12 p-md-12">
                    {invoice.uuid && <div className=" p-col-12" style={{ display: 'flex' }}>
                        <div className=" p-col-4 p-md-4">
                            <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                <div className="p-d-flex p-ai-center muted-text">

                                    <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >invoice number</h6>
                                    <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                    </div>
                                </div>
                                <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                    <div className="p-d-flex p-flex-column">
                                        <span className="p-mb-1 fs-large">{invoice?.originalInvoiceNumber}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" p-col-4 p-md-4">
                            <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                <div className="p-d-flex p-ai-center muted-text">

                                    <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >{strings.Vendor}</h6>
                                    <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                    </div>
                                </div>
                                <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                    <div className="p-d-flex p-flex-column">
                                        <span className="p-mb-1 fs-large">{invoice?.vendor?.name}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" p-col-4 p-md-4">
                            <div className="card overview-box p-d-flex p-flex-column p-pt-2">
                                <div className="p-d-flex p-ai-center muted-text">

                                    <h6 className={classNames('p-m-0', { 'p-pl-2': !isRTL, 'p-pr-2': isRTL })} >invoice amount</h6>
                                    <div className={classNames({ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}>
                                    </div>
                                </div>
                                <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                    <div className="p-d-flex p-flex-column">
                                        <span className="p-mb-1 fs-large">{invoice?.totalAmount}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {/* <hr style={{borderColor:'#039b92'}}/> */}
                    <form action="" onSubmit={addPaymentFormHandler}>
                        <div className="p-field p-col-12 p-md-12">
                            <div className="p-fluid p-formgrid p-grid p-justify-center">
                                {!invoice.uuid && <div className={`p-field p-col-12 p-md-${invoice?.vendor?.branches?.length > 0 ? '6' : '12'}`} style={{ textAlign: 'center' }}>
                                    <b>{strings.Vendor}:</b>
                                    {canEdit && !vendorId ? <>
                                        {/* {invoice.agreementType?.code != 'selfBilled' && <b htmlFor="state">Select Vendor:</b>} */}

                                        {<div style={{ display: 'flex', width: '100%', paddingTop: '10px' }}>
                                            <Dropdown filter={true} style={{ width: '100%' }} id="vendor" filterPlaceholder={strings.filterSearchPlaceHolder} filterBy="name,vatNumber,phone,email" value={selectedVendor} onChange={(e) => setSelectedVendor(e.target.value)} options={vns} optionLabel="name" placeholder={strings.Select}></Dropdown>

                                            <Can yes={() => <Button icon="pi pi-plus" tooltipOptions={{
                                                position: 'top'
                                            }} tooltip="Create Vendor" onClick={() => { setAddVendor(true) }} type="button"></Button>}
                                                perform={'vendors:create'}
                                                silent={true}
                                                role={role} />
                                        </div>
                                        }
                                    </> : <p>{selectedVendor.name}</p>}

                                    <span>{strings.VatNumber}: <b>{selectedVendor?.vatNumber ?? '-'}:</b></span>

                                </div>}
                                {mode === 'edit' ? <><b>{strings.Customer} :</b>{' '}<p>{paymentDetails?.customer.registrationName}<br /><span style={{ textAlign: 'center' }}> {strings.VatNumber}: <b>{paymentDetails?.customer.vatNumber ?? '-'}</b></span></p>  </> : <CustomerSelectorAndAdd vendor={selectedVendor} onChange={(e) => setCustomer(e)} value={(customer.uuid && customer)} />}
                                {canEdit && invoice.vendor?.branches?.length > 0 && !branchPopulated ? <div className="p-field p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                    <b>{strings.Branch}:</b>
                                    <div style={{ display: 'flex', paddingTop: '10px' }}><Dropdown style={{ width: '100%' }} filter={true} id="branchSerial" value={invoice.branchSerial} onChange={this.changeValue} options={invoice.vendor?.branches?.map((i) => {
                                        return {
                                            code: i?.serial,
                                            name: `${i?.name} (${i?.serial})`
                                        }
                                    })} optionLabel="name" placeholder={strings.Select}></Dropdown></div>
                                </div> : (invoice.branchSerial && invoice.vendor?.branches?.length > 0 ? <div className="p-field p-col-12 p-md-6" style={{ textAlign: 'center' }}>
                                    <h5>{strings.Branch}</h5>
                                    <p>{invoice.branchSerial?.name}</p>
                                </div> : '')}
                                <div className="p-field p-col-12 p-md-12">

                                </div>
                                {/* <form action=""></form> */}
                                <div className="p-field p-col-12 p-md-4">
                                    <label htmlFor="icon">{strings.date}</label>
                                    <Calendar id="Date" value={paymentDetails.paymentDate && new Date(paymentDetails.paymentDate)} onChange={(e) => setPaymentDetails({ ...paymentDetails, paymentDate: e.value })} showIcon required />
                                </div>
                                <div className="p-col-4" style={{ margin: 'auto' }}>
                                    <label htmlFor="withoutgrouping">{strings.amount}</label>
                                    <InputNumber inputId="withoutgrouping" value={paymentDetails.amount ?? value} onChange={e => setPaymentDetails({ ...paymentDetails, amount: e.value })} mode="decimal" useGrouping={false} max={invoice.totalAmount} />
                                </div>
                                <div className="p-col-4 " style={{ margin: 'auto 0' }}>
                                    <Dropdown value={paymentTypes.find(x => x.uuid === paymentDetails.paymentTypeId)} options={paymentTypes} onChange={e => setPaymentDetails({ ...paymentDetails, paymentTypeId: e.value.uuid })} optionLabel="name" placeholder={strings.selectPaymentType} required />
                                </div>
                                {/* <div className="p-col-12" style={{ margin: '1rem' }}></div> */}
                                {paymentDetails.paymentTypeId && !paymentTypeValidator() && <div className="p-grid p-col-12 p-align-center vertical-container p-card" style={{ padding: '2rem', margin: '1rem' }}>
                                    <div className="p-col-3" >
                                        <Dropdown value={accounts?.find(val => val.uuid === paymentDetails.companyAccountId)} options={accounts} onChange={e => { setPaymentDetails({ ...paymentDetails, companyAccountId: e.value.uuid }) }} filter={true} filterBy='name' optionLabel="name" editable emptyMessage={strings.noAccount} placeholder={strings.selectAccount} itemTemplate={accountTemplate} required={paymentDetails.paymentTypeId && !paymentTypeValidator()} />

                                    </div>

                                    <div className="p-col-1" >
                                        <Button icon="pi pi-plus" title={strings.addAccount} onClick={() => setAddAccount(true)} type="button" />

                                    </div>
                                    <div className="p-col-3" >
                                        <InputText type="text" className="block mb-2" placeholder={strings.referenceNumber} value={paymentDetails.referenceNumber} onChange={e => setPaymentDetails({ ...paymentDetails, referenceNumber: e.target.value })} required={paymentDetails.paymentTypeId && !paymentTypeValidator()} />

                                    </div>
                                </div>}
                                {/* <div className="p-col-12">
                                    <div className=" p-col-2">

                                        <div className=" p-col-8 p-md-4">
                                        </div>
                                    </div>
                                    <div className="p-col-4"></div>
                                </div> */}
                                <div className="p-col-12" style={{ margin: '1rem' }}></div>

                                <div className="p-col-8">
                                    <h5>{strings.comments}</h5>
                                    <InputTextarea value={paymentDetails.comments} onChange={(e) => setPaymentDetails({ ...paymentDetails, comments: e.target.value })} rows={5} cols={30} />
                                </div>


                            </div>
                            <div className="p-grid p-justify-center" style={{ margin: '2rem auto' }}>
                                {progressLoading ? <ProgressSpinner style={{ width: '50px', height: '50px' }} /> :
                                    <>
                                        <div className="p-col-2">

                                            {mode === 'edit' ? <Button icon='pi pi-pencil ' type="submit" label={strings.edit} /> : <Button icon='pi pi-plus' type="submit" label={strings.submit} />}{` `}
                                        </div>
                                        <div className="p-col-2">
                                            {<Button icon='pi pi-times' className='p-button-secondary ' label={strings.close} type="button" onClick={() => history.push('/profile/payments')} />}

                                        </div>
                                    </>
                                }
                            </div>

                        </div>
                    </form>
                </div>}
        </>
    )
}

export default connect(null, { updatePaymentHandler, addPaymentHandler, getAccountsHandler, deleteAccountHandler, addPayment, getPaymentTypes, updatePayment })(PaymentForm)
