import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import IdentityTypeService from '../service/IdentityTypeService';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Badge } from 'primereact/badge';

import VendorService from '../service/VendorService';
import { useTheme } from 'styled-components';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import strings from '../localization';
import ImageUploading from "react-images-uploading";
import { InputTextarea } from 'primereact/inputtextarea';
import { ColorPicker } from 'primereact/colorpicker';
import ApiService from '../service/ApiService';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { formatDate } from './InvoiceDates';
import CertificateService from '../service/CertificateService';
import { Dialog } from 'primereact/dialog';
import { getCountry } from '../service/helpers';

export class VendorForm extends Component {


    constructor(props) {
        super(props);


        let emptyVendor = {

            namear: "",
            nameen: "",
            street1: "",
            street2: "",
            buildNo: "",
            invoiceFooter: "",
            additionalNumber: "",
            isLetterStyle: false,
            pdfHasBorders: true,
            seperateSequencePerType: false,
            automaticReconciliation: false,
            city: "",
            postalCode: "",
            state: "",
            countryCode: getCountry(),
            neighborhood: "",
            vat: "",
            vatGroup: "",
            rows: []

        }


        this.state = {
            vendor: { ...emptyVendor },
            identityTypes: [],
            loading: false,
            pageLoading: false,
            purge: false,
            syncError: null,
            certificateStatus:{},
            errors: [],
        };
        this.toast = React.createRef();



    }


    componentDidMount() {

        this.FillIdentityType();
        // console.log(this.props.user)

        if (this.props.user != null) {
            // Fill Data
            new CertificateService().GetCertificateStatus({uuid: this.props.user.uuid}).then(data=>{
                this.setState({certificateStatus: data});
            });

            this.ReturnData()
        }
    }


    ReturnData() {
        this.setState({ vendor: this.props.user })
    }

    onEditorValueChange(productKey, props, value) {
        let updatedRow = [...props.value];
        console.log(value)
        updatedRow[props.rowIndex][props.field] = value;
        this.setState({ [`${productKey}`]: updatedRow });
    }

    syncCertDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={()=>{
                this.setState({syncCertOpen: false, syncCertLoading: false, purge: false});
            }} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={()=>{
                this.setState({ syncCertLoading: true, syncCertOpen: false }, () => {
                    new CertificateService().SyncCertificates({ vendorId: this.state.vendor.uuid, purge: this.state.purge }).then((data) => {
                        this.setState({ certificateStatus: data, syncCertLoading: false, purge: false, syncSuccess: true });
                    }).catch((e)=>{
                        this.setState({syncError:e.response.data?.message??strings.internalServerError, syncCertLoading: false, purge: false})
                    });
                });

            }} />
        </>
    );


    onInputChange = (e, name) => {
        let val =  (e.target && e.target.value) || '';

        let _vendor = { ...this.state.vendor };

        if(name == 'pdfHasBorders')
        {
            val= !_vendor.pdfHasBorders;
        }
        if(name == 'automaticZatcaIntegration')
        {
            val= !_vendor.automaticZatcaIntegration;
        }
        if(name == 'seperateSequencePerType')
        {
            val= !_vendor.seperateSequencePerType;
        }
        if(name == 'automaticReconciliation')
        {
            val= !_vendor.automaticReconciliation;
        }

        if(name == 'isSimpleAddress')
        {
            val= !_vendor.isSimpleAddress;
        }

        if(name == 'isLetterStyle')
        {
            val= !_vendor.isLetterStyle;
        }

        _vendor[`${name}`] = val;


        this.setState({ vendor: _vendor });
    }


    FillIdentityType = async () => {
        let identityTypeService = new IdentityTypeService();
        this.setState({ pageLoading: true })
        var identityTypes = await identityTypeService.GetIdentityTypes();
        this.setState({ identityTypes: identityTypes.data, pageLoading: false })

    }


    AddRow = () => {
        let rows = [...this.state.vendor.rows ?? [], { identityId: null, name: "", value: "" }];
        let _vendor = { ...this.state.vendor };
        _vendor.rows = rows;
        this.setState({ vendor: _vendor });
    }

    AddBranchRow = () => {
        let branches = [...this.state.vendor.branches ?? [], { branchId: null, name: "", serial: "" }];
        let _vendor = { ...this.state.vendor };
        _vendor.branches = branches;
        this.setState({ vendor: _vendor });
    }


    GetData = () => {


        // console.log(this.state.user);

    }


    delete = (rowData) => {
        let _vendor = { ...this.state.vendor };

        _vendor.rows = _vendor.rows.filter(val => val.identityId == rowData.identityId);
        this.setState({ vendor: _vendor });

        this.toast.current?.show({ severity: 'success', summary: 'Successful', detail: 'Identity Deleted', life: 3000 });
    }


    nameBodyTemplate = (rowData, props) => {
        return (
            <>


                {/* <span className="p-column-title">Name</span> */}

                {/* {console.log(rowData)} */}


                <Dropdown value={props.rowData['name']} onChange={(e) => this.onEditorValueChange('name', props, e.value)} optionLabel="name" options={this.state.identityTypes?.map(o => {
                    return {
                        name: o.label,
                        value: o.uuid
                    }
                })} style={{ width: '100%' }} placeholder={strings.Select} />
                {/* {rowData.name} */}

            </>
        );
    }



    valueBodyTemplate = (rowData, props) => {
        return (
            <>

                {/* <span className="p-column-title">Value</span> */}
                {/* {rowData.value} */}
                <InputText id='value' type="text" value={props.rowData['value']} onChange={(e) => this.onEditorValueChange('value', props, e.target.value)} required autoFocus />

            </>
        );
    }
    branchNameTemplate = (rowData, props) => {
        return (
            <>

                {/* <span className="p-column-title">Value</span> */}
                {/* {rowData.value} */}
                <InputText id='name' disabled={rowData.branchId} type="text" value={props.rowData['name']} onChange={(e) => this.onEditorValueChange('name', props, e.target.value)} required autoFocus />

            </>
        );
    }
    branchSerialTemplate = (rowData, props) => {
        return (
            <>
                {/* <span className="p-column-title">Value</span> */}
                <InputText disabled={rowData.branchId} id='serial' type="text" value={props.rowData['serial']} onChange={(e) => this.onEditorValueChange('serial', props, e.target.value)} required autoFocus />

            </>
        );
    }


    actionBodyTemplate = (rowData) => {

        return (
            <div className="actions">
                <Button icon="pi pi-trash" onClick={() => this.delete(rowData)} className="p-button-rounded p-button-warning" />
            </div>
        );
    }

    save = async () => {


        this.setState({ loading: true });


        let { vendor } = this.state;

        let data = {
            ...vendor,
            "address": {
                "translations": [
                    {
                        "street": vendor.street1,
                        "additionalStreet": vendor.street2,
                        "buildingNumber": vendor.buildNo,
                        "additionalNumber": vendor.additionalNumber,
                        "city": vendor.city,
                        "postalCode": vendor.postalCode,
                        "state": vendor.state,
                        "neighborhood": vendor.neighborhood,
                        "countryCode": getCountry(),
                        "locale": 1
                    },
                    {
                        "street": vendor.street1,
                        "additionalStreet": vendor.street2,
                        "buildingNumber": vendor.buildNo,
                        "additionalNumber": vendor.additionalNumber,
                        "city": vendor.city,
                        "postalCode": vendor.postalCode,
                        "state": vendor.state,
                        "neighborhood": vendor.neighborhood,
                        "countryCode": getCountry(),
                        "locale": 2
                    }
                ]
            },
            "vat": vendor.vat,
            "vatGroupNumber": vendor.vatGroup,
            "headerBgColor": vendor.headerBgColor??'#f0ffff',
            "pdfFontColor": vendor.pdfFontColor??'#039b92',
            "headerFontColor": vendor.headerFontColor??'#000',
            "email": vendor.email,
            "phone": vendor.phone,
            "invoiceFooter": vendor.invoiceFooter,
            "image": vendor.image,
            "taxScheme": "VAT",
            "translations": [
                {
                    "name": vendor.nameen,
                    "locale": 1
                },
                {
                    "name": vendor.namear,
                    "locale": 2
                }
            ],
            "branches": vendor.branches?.map((row) => {
                return {
                    "branchId": row.branchId,
                    "name": row.name,
                    "serial": row.serial
                };
            }),
            "identities": vendor.rows?.map((row) => {
                return {
                    "identityId": row.identityId,
                    "identityTypeId": row.name,
                    "value": row.value
                };
            }),
            "isActive": true
        }

        var vendorService = new VendorService();

        if (!data.uuid) {
            let res = await vendorService.CreateVendor(data);
            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors });
            }
            else if(res.errorCode)
            {
                let errors = [];

                errors.push(strings[res.errorCode]);
                this.setState({ errors });
            }
            else
            {
                this.props.OnHideDialog();
                this.toast.current?.show({ severity: 'success', summary: 'Successful', detail: 'Vendor Created', life: 3000 });
            }
        }
        else {
            let res = await vendorService.UpdateVendor(data);

            if (res.errors) {
                let errors = [];
                Object.keys(res.errors).forEach(key => {
                    errors.push(res.errors[key]);
                })
                this.setState({ errors });
            } else {
                this.props.OnHideDialog();
                this.toast.current?.show({ severity: 'success', summary: 'Successful', detail: 'Vendor Updated', life: 3000 });
            }
        }
        this.props.FillData();

        this.setState({ loading: false });
    }


    render() {

        if (this.state.pageLoading) {
            return <div style={{ textAlign: 'center' }}><ProgressSpinner /></div>
        }

        const isRTL = localStorage.getItem('lang') == 'ar' ? 'rtl' : 'ltr';

        let image = this.state.vendor.image;

        if(image?.startsWith(this.state.vendor.uuid))
        {
            image = new ApiService().AddHostName("","/download/vendor/logo/")+"?path="+image;
        }

        return (

            <div className="p-grid crud-demo" style={{ direction: isRTL }}>
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={this.toast} />


                        <div className="p-field">
                            <label htmlFor="name">{strings.NameEnglish}*</label>
                            <InputText id="nameen" value={this.state.vendor.nameen} onChange={(e) => this.onInputChange(e, 'nameen')} required autoFocus />
                            {/* <small className="p-invalid">{JSON.stringify(this.state.user)}.</small> */}
                        </div>

                        <div className="p-field">
                            <label htmlFor="name">{strings.NameArabic}*</label>
                            <InputText id="namear" value={this.state.vendor.namear} onChange={(e) => this.onInputChange(e, 'namear')} required autoFocus />

                        </div>

                        <div className="p-grid">

                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.VatNumber}*</label>
                                    <InputText id="vat" value={this.state.vendor.vat} onChange={(e) => this.onInputChange(e, 'vat')} required autoFocus />
                                </div>
                            </div>

                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.GroupVatNumber}</label>
                                    <InputText id="vatGroup" value={this.state.vendor.vatGroup} onChange={(e) => this.onInputChange(e, 'vatGroup')} required autoFocus />

                                </div>
                            </div>

                            <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="invoiceNumberPrefix">{strings.invoiceNumberPrefix}</label>
                                    <InputText id="invoiceNumberPrefix" value={this.state.vendor.invoiceNumberPrefix} onChange={(e) => this.onInputChange(e, 'invoiceNumberPrefix')} required autoFocus />
                                </div>
                            </div>

                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="email">{strings.email}</label>
                                    <InputText id="email" value={this.state.vendor.email} onChange={(e) => this.onInputChange(e, 'email')} required autoFocus />
                                </div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-field">
                                    <label htmlFor="phone">{strings.phone}</label>
                                    <InputText id="phone" value={this.state.vendor.phone} onChange={(e) => this.onInputChange(e, 'phone')} required autoFocus />
                                </div>
                            </div>
                            <div className="p-col-4">

                                <div className="p-field">
                                    <label htmlFor="name" className="required">{strings.Street1}</label>
                                    <InputText id="street1" value={this.state.vendor.street1} onChange={(e) => this.onInputChange(e, 'street1')} required autoFocus />

                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.AdditionalStreet}</label>
                                    <InputText id="street2" value={this.state.vendor.street2} onChange={(e) => this.onInputChange(e, 'street2')} required autoFocus />

                                </div>
                            </div>


                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name" className="required">{strings.BuildingNo}</label>
                                    <InputText id="buildNo" value={this.state.vendor.buildNo} onChange={(e) => this.onInputChange(e, 'buildNo')} required autoFocus />

                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name">{strings.AdditionalNumber}</label>
                                    <InputText id="additionalNumber" value={this.state.vendor.additionalNumber} onChange={(e) => this.onInputChange(e, 'additionalNumber')} required autoFocus />

                                </div>
                            </div>




                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name" className="required">{strings.Neighborhood}</label>
                                    <InputText id="neighborhood" value={this.state.vendor.neighborhood} onChange={(e) => this.onInputChange(e, 'neighborhood')} required autoFocus />
                                </div>
                            </div>
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name" className="required">{strings.City}</label>
                                    <InputText id="city" value={this.state.vendor.city} onChange={(e) => this.onInputChange(e, 'city')} required autoFocus />

                                </div>
                            </div>

                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name" className="required">{strings.State}</label>
                                    <InputText id="state" value={this.state.vendor.state} onChange={(e) => this.onInputChange(e, 'state')} required autoFocus />
                                </div>
                            </div>
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name" className="required">{strings.Country}</label>
                                    <InputText id="state" value={this.state.vendor.countryCode} defaultValue={getCountry()} disabled required autoFocus />
                                </div>
                            </div>
                            <div className="p-col-4">
                                <div className="p-field">
                                    <label htmlFor="name" className="required">{strings.PostalCode}</label>
                                    <InputText id="postalCode" value={this.state.vendor.postalCode} onChange={(e) => this.onInputChange(e, 'postalCode')} required autoFocus />
                                </div>
                            </div>

                            <div className="p-col-12">

                                <div >
                                    <Checkbox id="seperateSequencePerType" name="seperateSequencePerType" value={this.state.vendor.seperateSequencePerType} checked={this.state.vendor.seperateSequencePerType} onChange={(e) => this.onInputChange(e, 'seperateSequencePerType')} />
                                    <span style={{ marginLeft: '5px' }} htmlFor="title"><b>{strings.seperateSequencePerType}</b></span>
                                </div>
                                <br/>
                                <div >
                                    <Checkbox id="automaticReconciliation" name="automaticReconciliation" value={this.state.vendor.automaticReconciliation} checked={this.state.vendor.automaticReconciliation} onChange={(e) => this.onInputChange(e, 'automaticReconciliation')} />
                                    <span style={{ marginLeft: '5px' }} htmlFor="title"><b>{strings.automaticReconciliation}</b></span>
                                </div>

                            </div>

                            <div className="p-col-12">
                            </div>

                            <div className="p-col-12">
                                <Accordion>
                                    <AccordionTab header={<React.Fragment><i className="pi pi-palette"></i><span>&nbsp;{strings.customization}</span></React.Fragment>}>
                                        <div className="p-grid">
                                            <div className="p-col-12">
                                                <div className="p-field">
                                                    <label htmlFor="invoiceFooter">{strings.invoiceFooterContent}</label>
                                                    <InputTextarea id="invoiceFooter" value={this.state.vendor.invoiceFooter} onChange={(e) => this.onInputChange(e, 'invoiceFooter')} required autoFocus />

                                                </div>
                                            </div>

                                                <div className="p-col-3">
                                                    <div className="p-field">
                                                        <Checkbox id="isLetterStyle" name="IsLetterStyle" value={this.state.vendor.isLetterStyle} checked={this.state.vendor.isLetterStyle} onChange={(e) => this.onInputChange(e, 'isLetterStyle')} />
                                                        <label style={{ padding: '5px', marginTop: '10px' }} htmlFor="title">{strings.isLetterStyle}</label>
                                                        {/* <InputText id="title" value={subscriptionPlan.homepageEnabled} onChange={(e) => onInputChange(e, 'homepageEnabled')} autoFocus className={classNames({ 'p-invalid': submitted && !subscriptionPlan.name })} /> */}
                                                        {/* {submitted && !subscriptionPlan.name && <small className="p-invalid">Label [EN] is required.</small>} */}
                                                    </div>
                                                </div>
                                                <div className="p-col-3">
                                                    <div className="p-field">
                                                        <Checkbox id="isSimpleAddress" name="IsSimpleAddress" value={this.state.vendor.isSimpleAddress} checked={this.state.vendor.isSimpleAddress} onChange={(e) => this.onInputChange(e, 'isSimpleAddress')} />
                                                        <label style={{ padding: '5px', marginTop: '10px' }} htmlFor="title">{strings.isSimpleAddress}</label>
                                                        {/* <InputText id="title" value={subscriptionPlan.homepageEnabled} onChange={(e) => onInputChange(e, 'homepageEnabled')} autoFocus className={classNames({ 'p-invalid': submitted && !subscriptionPlan.name })} /> */}
                                                        {/* {submitted && !subscriptionPlan.name && <small className="p-invalid">Label [EN] is required.</small>} */}
                                                    </div>
                                                </div>

                                                <div className="p-col-3">
                                                    <div className="p-field">
                                                        <Checkbox id="pdfHasBorders" name="pdfHasBorders" value={this.state.vendor.pdfHasBorders} checked={this.state.vendor.pdfHasBorders} onChange={(e) => this.onInputChange(e, 'pdfHasBorders')} />
                                                        <label style={{ padding: '5px', marginTop: '10px' }} htmlFor="title">{strings.pdfHasBorders}</label>
                                                        {/* <InputText id="title" value={subscriptionPlan.homepageEnabled} onChange={(e) => onInputChange(e, 'homepageEnabled')} autoFocus className={classNames({ 'p-invalid': submitted && !subscriptionPlan.name })} /> */}
                                                        {/* {submitted && !subscriptionPlan.name && <small className="p-invalid">Label [EN] is required.</small>} */}
                                                    </div>
                                                </div>
                                            <div className="p-col-12">
                                                <div className="p-field">
                                                    <h6>{strings.invoiceHeader}</h6>
                                                </div>
                                            </div>



                                            <div className="p-col-4">
                                                <div className="p-field">
                                                    <label htmlFor="invoiceHeader">{strings.invoiceHeader1Content}</label>
                                                    <InputText id="invoiceHeader1" value={this.state.vendor.invoiceHeader1} onChange={(e) => this.onInputChange(e, 'invoiceHeader1')} required autoFocus />
                                                </div>
                                            </div>
                                            <div className="p-col-4">
                                                <div className="p-field">
                                                    <label htmlFor="invoiceHeader">{strings.invoiceHeader2Content}</label>
                                                    <InputText id="invoiceHeader2" value={this.state.vendor.invoiceHeader2} onChange={(e) => this.onInputChange(e, 'invoiceHeader2')} required autoFocus />
                                                </div>
                                            </div>
                                            <div className="p-col-4">
                                                <div className="p-field">
                                                    <label htmlFor="invoiceHeader">{strings.invoiceHeader3Content}</label>
                                                    <InputText id="invoiceHeader3" value={this.state.vendor.invoiceHeader3} onChange={(e) => this.onInputChange(e, 'invoiceHeader3')} required autoFocus />
                                                </div>
                                            </div>
                                            <div className="p-col-4">
                                                <div className="p-field">
                                                    <label htmlFor="invoiceHeader">{strings.pdfFontColor}</label>
                                                    <br />
                                                    <ColorPicker defaultColor="#039b92" value={this.state.vendor.pdfFontColor} onChange={(e) => this.onInputChange(e, 'pdfFontColor')}></ColorPicker>
                                                </div>
                                            </div>
                                            <div className="p-col-4">
                                                <div className="p-field">
                                                    <label htmlFor="invoiceHeader">{strings.headerFontColor}</label>
                                                    <br />
                                                    <ColorPicker defaultColor="#000" value={this.state.vendor.headerFontColor} onChange={(e) => this.onInputChange(e, 'headerFontColor')}></ColorPicker>
                                                </div>
                                            </div>
                                            <div className="p-col-4">
                                                <div className="p-field">
                                                    <label htmlFor="invoiceHeader">{strings.headerBgColor}</label>
                                                    <br />
                                                    <ColorPicker defaultColor="#f0ffff" value={this.state.vendor.headerBgColor} onChange={(e) => this.onInputChange(e, 'headerBgColor')}></ColorPicker>
                                                </div>
                                            </div>
                                            <div className="p-col-12">
                                                <div className="p-field">
                                                    <label htmlFor="name">{strings.Logo}</label>
                                                    <ImageUploading
                                                        value={this.state.vendor.image}
                                                        onError={(errors, imageList) => {
                                                            if (errors.maxNumber) {
                                                                let image = imageList[0]?.data_url;
                                                                let _vendor = { ...this.state.vendor };
                                                                _vendor['image'] = image;
                                                                this.setState({ vendor: _vendor });
                                                            }

                                                        }}
                                                        onChange={(imageList) => {
                                                            let image = imageList[0]?.data_url;
                                                            let _vendor = { ...this.state.vendor };
                                                            _vendor['image'] = image;
                                                            this.setState({ vendor: _vendor });
                                                        }}
                                                        // maxFileSize={1048576}
                                                        // resolutionHeight="150"
                                                        // resolutionWidth="150"
                                                        // acceptType={["png","jpg","svg","jpeg"]}
                                                        dataURLKey="data_url"
                                                    >
                                                        {({
                                                            imageList,
                                                            onImageUpload,
                                                            onImageRemoveAll,
                                                            onImageUpdate,
                                                            onImageRemove,
                                                            isDragging,
                                                            errors,
                                                            dragProps
                                                        }) => {
                                                            let style = { width: "100%", height: "100px", borderStyle: "dotted", textAlign: 'center', margin: "auto" };
                                                            return (
                                                                // write your building UI
                                                                <div className="upload__image-wrapper">
                                                                    {errors?.maxFileSize && <Message severity="error" text="Selected file size exceed 1 MB" />}
                                                                    {errors?.acceptType && <Message severity="error" text="Selected file type is invalid, it should be one of: png, jpg, jpeg, svg" />}
                                                                    {!this.state.vendor.image ? <div
                                                                        style={isDragging ? { color: "red", ...style } : style}
                                                                        onClick={onImageUpload}
                                                                        {...dragProps}
                                                                    >
                                                                        <span style={{ top: '20%' }}>{strings.ClickorDropheretouploadvendorlogo}</span>
                                                                    </div> :
                                                                        <div className="image-item" style={{ textAlign: "center" }}>
                                                                            <img src={image} onError={(e)=>{e.onError=null;e.target.src="/icon-256x256.png"}} alt="" width="150" height="150" />
                                                                            <div className="image-item__btn-wrapper">
                                                                                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={onImageUpload} />
                                                                                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => onImageRemove(0)} />
                                                                            </div>
                                                                        </div>}
                                                                </div>
                                                            )
                                                        }}
                                                    </ImageUploading>
                                                </div>
                                            </div>
                                        </div>

                                    </AccordionTab>

                                    <AccordionTab header={<React.Fragment><i className="pi pi-globe"></i><span>&nbsp;{strings.Identities}</span></React.Fragment>}>
                                        <div className="card">

                                            <div style={{ display: 'flex' }}>
                                                <h4>{strings.Identities}</h4>
                                                <Button icon="pi pi-plus" style={{ marginLeft: '10px', marginTop: '-7px' }} onClick={this.AddRow} className="p-button-rounded p-button-success p-mr-2" />
                                                {/* <Button icon="pi pi-" style={{ marginLeft: '10px', marginTop: '-7px' }} onClick={this.GetData} className="p-button-rounded p-button-success p-mr-2" /> */}
                                            </div>

                                            <DataTable value={this.state.vendor.rows}
                                                stripedRows
                                                emptyMessage={strings.NoVendorsFound}>
                                                <Column field="name" header={strings.Name} body={this.nameBodyTemplate} ></Column>
                                                <Column field="value" header={strings.Value} body={this.valueBodyTemplate}></Column>
                                                <Column header={strings.Action} body={this.actionBodyTemplate}></Column>
                                            </DataTable>
                                        </div>

                                    </AccordionTab>
                                    <AccordionTab header={<React.Fragment><i className="pi pi-sitemap"></i><span>&nbsp;{strings.Branches}</span></React.Fragment>}>
                                        <div className="card">

                                            <div style={{ display: 'flex' }}>
                                                <h4 >{strings.Branches}</h4>
                                                <Button icon="pi pi-plus" style={{ marginLeft: '10px', marginTop: '-7px' }} onClick={this.AddBranchRow} className="p-button-rounded p-button-success p-mr-2" />
                                                {/* <Button icon="pi pi-" style={{ marginLeft: '10px', marginTop: '-7px' }} onClick={this.GetData} className="p-button-rounded p-button-success p-mr-2" /> */}
                                            </div>

                                            <DataTable value={this.state.vendor.branches}
                                                stripedRows
                                                emptyMessage={strings.Norecordsfound}>
                                                <Column field="name" header={strings.Name} body={this.branchNameTemplate} ></Column>
                                                <Column field="serial" header={strings.Serial} body={this.branchSerialTemplate}></Column>
                                                {/* <Column header={strings.Action} body={this.actionBodyTemplate}></Column> */}
                                            </DataTable>
                                        </div>

                                    </AccordionTab>
                                    {getCountry() == 'SA' ? 
                                    <AccordionTab header={<React.Fragment><i className="pi"></i><span>&nbsp;{strings.Compliance}</span></React.Fragment>}>
                                        <div className="card">

                                            <div className="p-field">
                                                <label htmlFor="invoiceHeader">{strings.zatcaEffectiveDate}</label>
                                                <Calendar id="zatcaEffectiveDate" minDate={new Date("2023-01-01")} showTime={false} showSeconds={false} showIcon showButtonBar value={formatDate(this.state.vendor.zatcaEffectiveDate)} onChange={(e) => this.onInputChange(e, 'zatcaEffectiveDate')}>
                                                </Calendar>
                                            </div>
                                            <hr/>

                                            <div style={{ width: '100%' }}>
                                                <Checkbox name='purge' checked={this.state.vendor.automaticZatcaIntegration} onChange={(e) => this.onInputChange(e, 'automaticZatcaIntegration')}></Checkbox>
                                                <span htmlFor='purge' style={{ marginLeft:'10px' }}>
                                                    <b>{strings.automaticZatcaIntegration}</b>
                                                </span>
                                            </div>
                                            <hr/>
                                        </div>

                                    </AccordionTab>:''}
                                </Accordion>
                            </div>



                        </div>
                        <hr/>

                        <div className="p-field">
                            {this.state.errors?.length > 0 && <Message text={this.state.errors.map((m) => <li>{m}</li>)} severity="error" />}
                            <Button label={strings.Save} loading={this.state.loading} onClick={this.save} loadingOptions={{ position: 'right' }} />
                        </div>

                    </div>
                </div>
                <Dialog visible={this.state.syncCertOpen} style={{ width: '450px' }} header={strings.Confirm} modal footer={this.syncCertDialogFooter} onHide={()=>{
                    this.setState({syncCertOpen:false, syncCertLoading: false, purge: false})
                }}>
                <div className="confirmation-content">

                    {<Message content={strings.SyncCertificatesText} severity={"error"}></Message>}
                    <hr/>

                    <Checkbox name='purge' checked={this.state.purge} onChange={(e=>this.setState({purge:e.checked}))}></Checkbox>

                    <label style={{color:'red', marginLeft:'5px'}} htmlFor='purge'>
                        {strings.purgeCerts}
                    </label>
                    {this.state.purge ? <><hr/><span style={{color:'red', fontStyle:"italic"}}><b>{strings.purgeWarning}</b></span></> : ''}
                </div>
            </Dialog>
            </div>

        );
    }
}


