import Axios from "axios";
import React, { Component } from "react";

import { AuthProvider } from "../context/auth-context";
// import strings from "../localization";
import querystring from 'querystring';
import { v4 as uuidv4 } from 'uuid';
const { REACT_APP_API } = process.env;

class Auth extends Component {
  state = {
    authenticated: false,
    refreshToken: "",
    user: {
      role: "visitor"
    },
    accessToken: ""
  };

  constructor(props) {
    super(props);
    this.setInterceptors();
  }

  setInterceptors = async () => {
    Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
    if(!window.location.pathname.includes("/login")) {
      Axios.interceptors.response.use(response => {
        return response;
      }, err => {
        return new Promise((resolve, reject) => {
          const originalReq = err.config;
          if (err.response && err.response.status === 401 && err.config && !originalReq._retry) {
            originalReq._retry = true;

            let res = fetch(`${REACT_APP_API}/auth/refresh`, {
              method: 'POST',
              mode: 'cors',
              cache: 'no-cache',
              headers: {
                'Content-Type': 'application/json'
              },
              redirect: 'follow',
              referrer: 'no-referrer',
              body: JSON.stringify({
                accessToken: this.state.accessToken,
                deviceID: localStorage.getItem('deviceID'),
                refreshToken: this.state.refreshToken
              }),
            }).then(res => res.json()).then(res => {
              if (res.code == 200) {
                this.setSession(res);
              } else {
                this.logout();
              }
              originalReq.headers['Authorization'] = `Bearer ${res.token}`
              return Axios(originalReq);
            });
            resolve(res);
          } else if (err.response && err.response.status === 401) {
            this.logout(true);
          }
          return reject(err);
        });
      });

      if(process.env.REACT_APP_MULTI_TENANT == 'true')
      {
        Axios.interceptors.request.use(req => {
          console.log(`${req.method} ${req.url}`);
          if (!req.url.includes("/api/tenant")) {
            if (req.method != 'GET' && req.data) {
              req.data['tenantId'] = localStorage.getItem('tenantId');
            } else {
              let url = req.url;
              let query = querystring.parse(url.substr(url.indexOf('?') + 1));
              if (url.indexOf('?') != -1) {
                url = url.substr(0, url.indexOf('?'))
              }
              query.tenantId = localStorage.getItem('tenantId');
              req.url = url + "?" + querystring.stringify(query);
            }
          }
          // Important: request interceptors **must** return the request.
          return req;
        });
      }
    }


  }

  initiateLogin = async (username, password) => {
    return new Promise((resolve, reject) => {
      Axios.post(`${REACT_APP_API}/auth/basic`, {
        username,
        password,
        deviceID: localStorage.getItem('deviceID')
      }).then(response => {
        this.setSession(response.data);
        resolve(response.data)
      }).catch(error => {
        resolve(error.response?error.response.data : {'status':'internal_server_error'});
      });
    });
  };

    logout = (showError = false) => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        localStorage.removeItem('subscription');
        window.location.href = `/login${showError ? '?authError=true' : ''}`
    };

  handleAuthentication = () => {
  };

  redirectOnNotAuth = () => {
    if (!this.state.authenticated) this.props.router.push('/login')
  };

  goToLogin = () => {
    if (!this.state.authenticated) this.props.router.push('/login')
  };



  setSession(authResult) {
    this.setState({
      authenticated: true,
      accessToken: authResult.token,
      refreshToken: authResult.refreshToken,
      user: authResult.user,
    })
    localStorage.setItem('authenticated', true);
    localStorage.setItem('accessToken', authResult.token);
    localStorage.setItem('refreshToken', authResult.refreshToken);
    localStorage.setItem('user', JSON.stringify(authResult.user));
  }

  componentDidMount() {
    this.setState({
      authenticated: localStorage.getItem('authenticated') || false,
      refreshToken: localStorage.getItem('refreshToken'),
      user: JSON.parse(localStorage.getItem('user') != 'undefined' ? localStorage.getItem('user') : "{}"),
      accessToken: localStorage.getItem('accessToken')
    }, () => {
      const {authenticated} = this.state;
    //   const locale = strings.getLanguage();


      if (localStorage.getItem('deviceID') == null) {
        localStorage.setItem('deviceID', uuidv4())
      }
    })
  }

  render() {
    const authProviderValue = {
      ...this.state,
      initiateLogin: this.initiateLogin,
      handleAuthentication: this.handleAuthentication,
      logout: this.logout,
      redirectOnNotAuth: this.redirectOnNotAuth
    };

    return (
      <AuthProvider value={authProviderValue}>
        {this.props.children}
      </AuthProvider>
    );
  }
}

export default Auth;
