import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import VendorService from '../service/VendorService';
import IdentityTypeService from '../service/IdentityTypeService';


export const IdentityTypes = () => {

    let emptyIdentityType = {
        "name": "",
        "translations": [
            {
                label:'',
                locale:'1'
            },
            {
                label:'',
                locale:'2'
            }
        ],
    };

    const [identityTypeDialog, setIdentityTypeDialog] = useState(false);
    const [deleteIdentityTypeDialog, setDeleteIdentityTypeDialog] = useState(false);
    const [deleteIdentityTypesDialog, setDeleteIdentityTypesDialog] = useState(false);
    const [identityType, setIdentityType] = useState(emptyIdentityType);
    const [selectedIdentityTypes, setSelectedIdentityTypes] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [identityTypes, setIdentityTypes] = useState([]);
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        // const identityTypeService = new IdentityTypeService();
        // identityTypeService.getIdentityTypes().then(data => setIdentityTypes(data));
        onPage({rows,page:0})
    }, []);

    const formatCurrency = (value) => {
        if(!value) return ""
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setIdentityType(emptyIdentityType);
        setSubmitted(false);
        setIdentityTypeDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setIdentityTypeDialog(false);
    }

    const hideDeleteIdentityTypeDialog = () => {
        setDeleteIdentityTypeDialog(false);
    }

    const hideDeleteIdentityTypesDialog = () => {
        setDeleteIdentityTypesDialog(false);
    }

    const saveIdentityType = async () => {
        setSubmitted(true);

        if (identityType.name.trim()) {
            var identityTypeService = new IdentityTypeService();
            if (identityType.uuid) {
                identityType.translations.map(t=>{
                    t.locale = t.cultureName == 'En' ? '1' : '2'
                    return t;
                });

                await identityTypeService.UpdateIdentityType(identityType);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'IdentityType Updated', life: 3000 });
            }
            else {
                await identityTypeService.CreateIdentityType(identityType);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'IdentityType Created', life: 3000 });
            }
            onPage({rows,page})
            // setIdentityTypes(_identityTypes);
            setIdentityTypeDialog(false);
            setIdentityType(emptyIdentityType);
        }
    }

    const editIdentityType = (identityType) => {
        setIdentityType({ ...identityType });
        setIdentityTypeDialog(true);
    }

    const confirmDeleteIdentityType = (identityType) => {
        setIdentityType(identityType);
        setDeleteIdentityTypeDialog(true);
    }

    const deleteIdentityType = async () => {
        await (new IdentityTypeService().DeleteIdentityType(identityType))
        setDeleteIdentityTypeDialog(false);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'IdentityType Deleted', life: 3000 });
        onPage({ rows, page })
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < identityTypes.length; i++) {
            if (identityTypes[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const confirmDeleteSelected = () => {
        setDeleteIdentityTypesDialog(true);
    }

    const deleteSelectedIdentityTypes = () => {
        let _identityTypes = identityTypes.filter(val => !selectedIdentityTypes.includes(val));
        setIdentityTypes(_identityTypes);
        setDeleteIdentityTypesDialog(false);
        setSelectedIdentityTypes(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'IdentityTypes Deleted', life: 3000 });
    }

    const onCategoryChange = (e) => {
        let _identityType = { ...identityType };
        _identityType['accessRole'] = e.value;
        setIdentityType(_identityType);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _identityType = { ...identityType };
        _identityType[`${name}`] = val;

        setIdentityType(_identityType);
    }

    const onTranslationChange = (e, locale) => {
        const val = (e.target && e.target.value) || '';
        let _identityType = { ...identityType };
        _identityType.translations[locale-1].label = val;
        setIdentityType(_identityType);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={openNew} />
                <Button label="Delete" icon="pi pi-trash" className="p-button-danger p-mb-2" onClick={confirmDeleteSelected} disabled={!selectedIdentityTypes || !selectedIdentityTypes.length} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        // return (
        //     <React.Fragment>
        //         <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" />
        //         <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
        //     </React.Fragment>
        // )
    }

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Code</span>
                {rowData.code}
            </>
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.email}
            </>
        );
    }

    const arabicLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Label [AR]</span>
                {rowData.translations[1]?.label}
            </>
        );
    }

    const englishLabelTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.translations[0]?.label}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editIdentityType(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteIdentityType(rowData)} />
            </div>
        );
    }

    const onPage = ({ first,
        rows,
        page,
        pageCount }) => {
        const identityTypeService = new IdentityTypeService();
        setLoading(true);
        setRows(rows);
        setFirst(first)
        setPage(page);

        identityTypeService.GetIdentityTypes(rows, (page) * rows).then(({ data, count }) => {
            setLoading(false);
            setIdentityTypes(data);
            setTotalRecords(count);
        });

    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Identity Types</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const identityTypeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveIdentityType} />
        </>
    );
    const deleteIdentityTypeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteIdentityTypeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteIdentityType} />
        </>
    );
    const deleteIdentityTypesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteIdentityTypesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedIdentityTypes} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt}
                               value={identityTypes}
                               selection={selectedIdentityTypes}
                               dataKey="id"
                               totalRecords={totalRecords}
                               lazy
                               first={first}
                               loading={loading}
                               autoLayout={true}
                               onPage={onPage}
                               paginator rows={rows}
                               rowsPerPageOptions={[5, 10, 25]}
                               className="datatable-responsive"
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} identityTypes"
                               globalFilter={globalFilter}
                               emptyMessage="No identityTypes found."
                               header={header}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="name" header="Name"  style={{width: "250px"}}body={nameBodyTemplate}></Column>
                        <Column field="email" header="Label [AR]"  style={{width: "250px"}} body={arabicLabelTemplate}></Column>
                        <Column field="phone" header="Label [EN]"body={englishLabelTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={identityTypeDialog} style={{ width: '450px' }} header="identityType Details" modal className="p-fluid" footer={identityTypeDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={identityType.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !identityType.name })} />
                            {submitted && !identityType.name && <small className="p-invalid">Name is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">Label [AR]</label>
                            <InputText id="name" value={identityType.translations[1]?.label} onChange={(e) => onTranslationChange(e, '2')} required autoFocus className={classNames({ 'p-invalid': submitted && !identityType.name })} />
                            {submitted && !identityType.name && <small className="p-invalid">Label [AR] is required.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="name">Label [EN]</label>
                            <InputText id="name" value={identityType.translations[0]?.label} onChange={(e) => onTranslationChange(e, '1')} required autoFocus className={classNames({ 'p-invalid': submitted && !identityType.name })} />
                            {submitted && !identityType.name && <small className="p-invalid">Label [EN] is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteIdentityTypeDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteIdentityTypeDialogFooter} onHide={hideDeleteIdentityTypeDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {identityType && <span>Are you sure you want to delete <b>{identityType.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteIdentityTypesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteIdentityTypesDialogFooter} onHide={hideDeleteIdentityTypesDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {identityType && <span>Are you sure you want to delete the selected identityTypes?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
